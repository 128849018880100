export const API_FETCHING_ERROR =
    "Oops! We couldn't fetch the delivery information for your pincode. Please try again.";
export const ICON_SIZE = 16;

export const ANALYTICS_COPIES = {
    DEL_INFO: "del-info",
    DEL_INFO_ADDRESS: "del-info-address",
    CLICK_ADDRESS: "click-address",
};

export const COPIES = {
    TITLE: "Delivery Information",
    DELIVER_TO: "Deliver to ",
    GET_DEL_INFO: "Get delivery information",
    DIRECT_DELIVERED: "Order delivered by",
    DIRECT_DELIVERY: "Direct delivery by",
};
