export const FALLBACK_IMAGE_ID = "minis/no_brand_background_fallback";

/** TODO: add to DLS */
export const GRADIENT_COLORS = ["rgba(0, 0, 0, 0)", "rgba(56,56,56, 0.73)"];
export const GRADIENT_COLORS_2 = ["rgba(22, 26, 31, 0.93)", "rgba(22, 26, 31, 0)"];
/** -- */

export const GRADIENT_LOCATIONS = [0, 1];
export const GRADIENT_LOCATIONS_START = { x: 1, y: 1 };
export const GRADIENT_LOCATIONS_END = { x: 0.2, y: 1 };
export const STORE_DELETED_BG_IMAGE = "minis/storefront_store_deleted";
export const STORE_DELETED_BG_IMAGE_WIDTH = 144;
export const STORE_DELETED_BG_IMAGE_HEIGHT = 90;
export const POWERED_BY = "Made with";
export const SWIGGY_MINIS = "Swiggy Minis";
export const ALERT_HEIGHT = 14;
