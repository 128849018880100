import React from "react";
import { StyleSheet } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";

import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";

import { usePaymentLayoutUpdates } from "../../hooks/use-payment-layout";

type Props = {
    children?: React.ReactNode;
    nativeChildren?: React.ReactNode;
};

/**
 * Component to wrap a React component to perform Web-related logic, Native Elements are returned as is.
 *
 * @param {*} props , {children having Web-only code, nativeChildren for Native-only code}
 * @return {*}
 */
const DWebWrapperComponent: React.FC<Props> = (props) => {
    const isDWeb = useIsDesktopWeb();
    const mountedRef = React.useRef(null);
    const { layout } = usePaymentLayoutUpdates();

    React.useEffect(() => {
        if (!isDWeb) {
            return;
        }
    });

    const webStyles = React.useCallback(() => {
        if (!isDWeb || !mountedRef.current) {
            return {};
        }
        if (!layout.width && !layout.left) {
            return;
        }
        return { width: layout.width, left: layout.left, marginHorizontal: 0 };
    }, [isDWeb, layout.left, layout.width]);
    if (!isDWeb) {
        return <>{props.nativeChildren}</>;
    }

    return (
        <Box ref={mountedRef} style={[styles.DWebWrapper, webStyles()]}>
            {props?.children}
        </Box>
    );
};

export const DWebWrapper = React.memo(DWebWrapperComponent);

const styles = StyleSheet.create({
    DWebWrapper: {
        width: "70%",
        marginHorizontal: "15%",
    },
});
