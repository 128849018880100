import addHours from "date-fns/addHours";

export const LABELS = {
    HEADER: "Pick your preferred slot",
    PICK_YOUR_PREFERRED_DATE: "Select your preferred date",
    SELECT_YOUR_PREFERRED_TIME: "Select your preferred time",
    PROCEED: "Proceed",
    AVAILABILITY_MESSAGE_PREFIX: "Working hours of",
    PAST_SLOT_ERROR_MESSAGE: "Please select a valid date",
    FUTURE_SLOT_ERROR_MESSAGE: "Please select a date till 15 days ahead fom the current date",
    DATE_PLACEHOLDER: "DD/MM/YYYY",
    TIME_PLACEHOLDER: "hh:mm",
    ALL_SLOTS_BOOKED: "All slots are booked",
};

const today = new Date();
const totalHoursToAddForMaxLimit = 24 * 15;
export const DEFAULT_DATES = {
    MIN_DATE: new Date(),
    MAX_DATE: addHours(today, totalHoursToAddForMaxLimit),
    MIN_INTERVAL: 5,
};

export const TEST_IDS = {
    DATE_INPUT: "appointment-date",
    TIME_INPUT: "appointment-time",
    DATE_TIME_PICKER: "datetimepicker",
};

export const DEFAULT_DESKTOP_SIZE = 500;
export const DEFAULT_LIST_SIZE = 4;
