import React from "react";
import { formatNumberWithIndianSystem } from "@minis-consumer//helpers/number";

import { CartBill } from "@minis-consumer/interfaces/cart";
import { ChargesTooltipContent } from "../../../common/charges-tooltip-content";

interface Props {
    charges: NonNullable<CartBill["charges"]>[0]["subCharges"];
}

const AdditionalChargeTooltipComponent: React.FC<Props> = ({ charges }) => {
    const tooltipCharges = React.useMemo(
        () =>
            (charges || []).map((charge) => ({
                left: charge.name,
                right: formatNumberWithIndianSystem(charge.finalValue, {
                    showRupeeSymbol: true,
                }),
            })),
        [charges],
    );

    if (!charges) {
        return null;
    }

    return <ChargesTooltipContent title="Additional charges breakup" charges={tooltipCharges} />;
};

export const AdditionalChargeTooltip = React.memo(AdditionalChargeTooltipComponent);
