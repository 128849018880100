import * as React from "react";
import { StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import MinisLogoSvg from "@minis-consumer/svgs/minis-logo.svg";
import { SwiggyRNModule } from "@minis-consumer/includes/swiggy-rn-module";
import { Analytics } from "@minis-consumer/analytics";

type HeaderProps = {
    showLogo?: boolean;
    size?: number;
};

export const HeaderLeft: React.FC<HeaderProps> = ({ showLogo, size = 20 }) => {
    const backPressHandler = (): void => {
        Analytics.clickEvent({
            category: "back-btn",
        });
        SwiggyRNModule.getInstance().exit();
    };

    return (
        <Stack direction="row" spacing={SpacingValue["space-medium"]}>
            <Box style={styles.iconContainer}>
                <SvgIcon
                    icon="ArrowLeft"
                    color="color-basic-75"
                    width={size}
                    height={size}
                    hitSlop={{ top: 16, bottom: 16, left: 16, right: 16 }}
                    style={{ marginTop: 3 * SpacingValue["space-xxx-small"] }}
                    onPress={backPressHandler}
                />
            </Box>
            {showLogo ? (
                <Box style={styles.iconContainer}>
                    <MinisLogoSvg />
                </Box>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    iconContainer: {
        height: 36,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
    },
});
