import { Order } from "@minis-consumer/interfaces/order";
import { getSelectedVariants } from "@minis-consumer/helpers/variants";
import { PRODUCT_TYPE } from "@minis-consumer/interfaces/catalog";
import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";

export const getItemGist = (items: Order["items"]): string => {
    const gistArr: string[] = [];
    items.slice(0, 2).forEach((item, idx) => {
        const selectedVariants = getSelectedVariants(item.item.variantOptionValues);
        const variantsText = selectedVariants ? ` (${selectedVariants})` : "";

        gistArr.push(
            `${item.item.name}${variantsText} x ${item.quantity}${
                idx === 0 && items.length > 1 ? "," : ""
            }`,
        );
    });

    if (items.length > 2) {
        gistArr.push(`and ${items.length - 2} more`);
    }

    return gistArr.join(" ");
};

interface IReturn {
    title: string;
    subtitle?: string;
}

export const useItemsGist = (items: Order["items"], productType?: PRODUCT_TYPE): IReturn => {
    if (productType === "APPOINTMENT" || productType === "PLAN") {
        const item = items[0];
        const slot = item.slot?.start ? convertToReadableDateAndTime(item.slot.start) : null;
        const duration = item.item.productDuration
            ? item.item.productDuration.value + " " + item.item.productDuration.unit
            : "";

        if (productType === "APPOINTMENT") {
            return { title: getItemGist(items), subtitle: `Booked for ${slot} , ${duration}` };
        }
        if (productType === "PLAN") {
            return { title: getItemGist(items), subtitle: duration };
        }
    }

    return { title: getItemGist(items) };
};
