import React, { FC, memo } from "react";
import { StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { CLOSE_ICON_DIMENSION, INFO_BANNER_DISPLAY_COPIES } from "../constants";
import { INFO_BANNER_TEST_IDS } from "../constants/test-ids";

interface InfoBannerProps {
    itemsChanged: number;
    totalItems: number;
    onClosePress: VoidFunction;

    infoText?: string;
}

const InfoBannerComponent: FC<InfoBannerProps> = ({
    itemsChanged = 0,
    totalItems = 0,
    onClosePress,
    infoText = INFO_BANNER_DISPLAY_COPIES.infoText,
}) => {
    const { value: theme } = useTheme();

    if (!itemsChanged || !totalItems) {
        return null;
    }

    const titleText = `${itemsChanged} ${INFO_BANNER_DISPLAY_COPIES.outOf} ${totalItems} ${INFO_BANNER_DISPLAY_COPIES.itemsRemoved}`;

    return (
        <Stack
            style={[styles.root, { backgroundColor: theme["color-surface-warning"] }]}
            direction="row"
            spacing={SpacingValue["space-medium"]}>
            <Stack flex={1} spacing={SpacingValue["space-xxx-small"]}>
                <Text category="b2" weight="bold" color="color-warning-600">
                    {titleText}
                </Text>

                <Text category="b3" weight="medium" color="color-basic-alpha-60">
                    {infoText}
                </Text>
            </Stack>

            <SvgIcon
                icon="Close"
                color="color-basic-alpha-60"
                height={CLOSE_ICON_DIMENSION}
                width={CLOSE_ICON_DIMENSION}
                onPress={onClosePress}
                testID={INFO_BANNER_TEST_IDS.closeIcon}
            />
        </Stack>
    );
};

export const InfoBanner = memo(InfoBannerComponent);

const styles = StyleSheet.create({
    root: {
        paddingVertical: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
});
