import React from "react";

import { Dialog, DialogContent } from "@swiggy-private/rn-dls";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { useCartView, useLocalCartItemDelete } from "@minis-consumer/hooks/use-cart";
import { Analytics } from "@minis-consumer/analytics";
import { useUserInfo } from "@minis-consumer/hooks/use-user";

import { ModalBody } from "./modal-body";

interface Props {
    showModal: boolean;
    closeModal: () => void;
    storeId: string;
    itemId: string;
}

const DeleteProductConfirmationDialogComponent: React.FC<Props> = ({
    showModal,
    closeModal,
    storeId,
    itemId,
}) => {
    const cart = useCartView(storeId);
    const userInfo = useUserInfo();

    const modalStyle = useSelectScreen({
        lg: {
            maxWidth: 340,
            borderRadius: 20,
        },
        md: {
            maxWidth: 340,
            borderRadius: 20,
        },
        default: {
            maxWidth: "90%",
            borderRadius: 20,
        },
    });

    const removeItem = useLocalCartItemDelete({
        storeId,
        itemId,
        custom: true,
    });

    const onPressRemove = React.useCallback(() => {
        Analytics.clickEvent({
            category: "remove-item-btn",
            label: userInfo?.id ? cart?.cartViewData?.id : "",
            context: itemId,
        });

        removeItem();
        closeModal();
    }, [removeItem, closeModal, cart?.cartViewData?.id, itemId, userInfo?.id]);

    if (!showModal) {
        return null;
    }

    return (
        <Dialog disableBackdropClose open={showModal} onClose={closeModal} style={modalStyle}>
            <DialogContent>
                <ModalBody onPressCancel={closeModal} onPressRemove={onPressRemove} />
            </DialogContent>
        </Dialog>
    );
};

export const DeleteProductConfirmationDialog = React.memo(DeleteProductConfirmationDialogComponent);
