import React from "react";

import { MethodCard } from "../../method-card";
import { MethodList } from "../../method-list";

export const NewPaymentGroup: React.FC = () => {
    return (
        <MethodCard hasBGCard={false} title="Choose a payment method">
            <MethodList />
        </MethodCard>
    );
};
