import React, { useContext, useMemo } from "react";
import { LayoutChangeEvent, Platform, ScrollView, StyleSheet } from "react-native";

import { useIsFocused } from "@react-navigation/core";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { useMount, useMountedRef } from "@swiggy-private/react-hooks";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Layout, SpacingValue } from "@swiggy-private/rn-dls";
import { IOScrollView } from "@swiggy-private/react-native-ui";

import { Analytics } from "@minis-consumer/analytics";
import { CouponErrorSnackbar } from "@minis-consumer/components/coupon-error-snackbar";
import { OverlayLoader } from "@minis-consumer/components/overlay-loader";
import { ADDRESS_LAT_LNG_ID } from "@minis-consumer/constants";
import { SelectedAddressInfoContext } from "@minis-consumer/contexts/selected-address-context";
import {
    useCartStatus,
    useCartView,
    useCelebrationModal,
    useSelectedCartAddress,
    useUpdateCartAddress,
    useUpdateCartAppliedCoupon,
    useUpdateItemInLocalCart,
} from "@minis-consumer/hooks/use-cart";
import { useChatBlockedErrorSnackbarHandler } from "@minis-consumer/hooks/use-chat-blocked-snack-handler";
import { useCouponInvalidHandler } from "@minis-consumer/hooks/use-coupon-invalid-handler";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { Logger } from "@minis-consumer/includes/logger";
import { PaymentProgress } from "@minis-consumer/includes/payment-progress";
import { UserAddress } from "@minis-consumer/interfaces/cart";
import { HomeRouteList } from "@minis-consumer/interfaces/route";
import { getCartErrorStatus, getChangedItems } from "@minis-consumer/routes/cart/helpers";

import {
    AddressBottomSheetMeta,
    useAddressBottomSheetMeta,
} from "@minis-consumer/routes/cart/hooks/use-address-bottom-sheet-meta";
import { useIsEmailToBeAdded } from "@minis-consumer/routes/cart/hooks/use-is-email-added";
import { getCancellationPolicies } from "@minis-consumer/routes/cart/helpers";
import { useInvalidAppointmentHandler } from "@minis-consumer/hooks/use-invalid-appointment-handler";
import { AppointmentErrorSnackbar } from "@minis-consumer/components/appointment-error-snackbar";

import { AddressList } from "../../address-list";
import { Bill } from "../../bill";
import { CancellationPolicy } from "../../cancellation-policy";
import { CartAddressBottomSheet } from "../../cart-address-bottomsheet";
import { CartAlert } from "../../cart-alert";
import { CartError } from "../../cart-error";
import { CouponCard } from "../../coupon";
import { GuestCheckoutBottomSheet } from "../../guest-checkout-bottomsheet";
import { CartItemList } from "../../item-list";
import { PreCheckoutAddEmailDialog } from "../../pre-checkout-add-email-dialog";
import { PreCheckoutDialog } from "../../pre-checkout-dialog";
import { ChangedItems } from "../../changed-items";
import { CartInstructions } from "../../item-list/components/instructions";
import { CartProceedBottomSheet } from "../../cart-proceed-bottomsheet";
import { CART_V2_ERRORS_STATUS } from "../../item-list/constants";

interface SmallScreenLayoutProps {
    storeId: string;
    canShowPreCheckoutDialog: boolean;

    showSwirlingArrow?: boolean;
    cartSyncing?: boolean;
    errorMessage?: string;
}

const BOTTOM_COMPONENT_HEIGHT = 300;
const isWeb = Platform.OS === "web";
const PROCEED_CTA_EVENT = "proceed-to-pay-btn";

export const SmallScreenLayout: React.FC<SmallScreenLayoutProps> = ({
    storeId,
    cartSyncing,
    errorMessage,
    canShowPreCheckoutDialog,
}) => {
    const navigation = useNavigation<NativeStackNavigationProp<HomeRouteList, "Cart">>();
    const cart = useCartView(storeId);
    const selectedAddressCtx = useContext(SelectedAddressInfoContext);
    const selectedAddressId = selectedAddressCtx?.selectedAddressInfo?.addressId;
    const updateItemInCart = useUpdateItemInLocalCart(storeId);

    // TODO: Check if this logic exists in a hook or create one
    const addressList: UserAddress[] = useMemo(() => {
        return (
            cart?.cartViewData?.addresses.filter(
                (address: UserAddress) => address.id !== ADDRESS_LAT_LNG_ID,
            ) || []
        );
    }, [cart?.cartViewData?.addresses]);

    const addressListLength = cart?.cartViewData?.addresses.length || 0;
    const localCartAddress = useSelectedCartAddress(storeId);
    const updateAddress = useUpdateCartAddress(storeId);
    const mountedRef = useMountedRef();
    const cartStatus = useCartStatus(storeId);
    const [showToast] = useToast();
    const { activateOrDeactivateCelebrationModal } = useCelebrationModal(storeId);
    const applyCouponHook = useUpdateCartAppliedCoupon(storeId);
    const isEmailToBeAdded = useIsEmailToBeAdded(cart?.cartViewData);
    const { canShowSnackbar, dismissSnackbar, errorText } = useCouponInvalidHandler({
        checkoutErrorMsg: errorMessage,
        cart: cart?.cartViewData,
    });
    const {
        canShowSnackbar: canShowSnackbarForAppointment,
        dismissSnackbar: dismissSnackbarForAppointment,
        errorText: appointmentErrorText,
    } = useInvalidAppointmentHandler({
        checkoutErrorMsg: errorMessage,
        cart: cart?.cartViewData,
    });

    const isPhysicalCart = useMemo(
        () => cart?.cartViewData?.cartType === "PHYSICAL" || !cart?.cartViewData?.cartType,
        [cart],
    );

    const { dataHandler } = useChatBlockedErrorSnackbarHandler();

    const scrollRef = React.useRef<ScrollView>(null);

    const [showPreCheckoutDialog, setShowPreCheckoutDialog] = React.useState(false);
    const [showPreCheckoutAddEmailDialog, setShowPreCheckoutAddEmailDialog] = React.useState(false);
    const [showAddressListModal, setShowAddressListModal] = React.useState(false);

    const coordinate = React.useRef<{ x: number; y: number } | null>(null);

    const [userSelectedAddress, setUserSelectedAddress] = React.useState<UserAddress | null>(
        localCartAddress,
    );

    const [addressMeta, setAddressMeta] = React.useState<AddressBottomSheetMeta | null>(null);
    const [bottomComponentHeight, setBottomComponentHeight] =
        React.useState(BOTTOM_COMPONENT_HEIGHT);

    const changedItems = useMemo(
        () => (cart?.cartViewData?.cartItems ? getChangedItems(cart.cartViewData.cartItems) : []),
        [cart?.cartViewData?.cartItems],
    );

    const addressBottomSheetMeta = useAddressBottomSheetMeta({
        addressListLength,
        userSelectedAddress,
    });

    const onSelectAddress = React.useCallback(
        (address: UserAddress | null) => {
            if (address) {
                setUserSelectedAddress(address);
                updateAddress(address.id);
                selectedAddressCtx?.updateSelectedAddressInfo?.({
                    ...selectedAddressCtx.selectedAddressInfo,
                    lng: address?.lng,
                    lat: address?.lat,
                    postalCode: address?.postalCode,
                    addressId: address?.id,
                    addressLabel: address?.displayName,
                });
            } else {
                setUserSelectedAddress(null);
            }
        },
        [selectedAddressCtx, updateAddress],
    );

    // TODO: Check if hook already exist for this
    const getAddressById = React.useCallback(
        (addresses: UserAddress[], addressId?: string): UserAddress | null => {
            if (!addressId || !addresses?.length) {
                return null;
            }

            const addressById = addresses?.find((address: UserAddress) => address.id === addressId);
            return addressById ?? null;
        },
        [],
    );

    const hidePreCheckoutDialog = React.useCallback(() => {
        setShowPreCheckoutDialog(false);
    }, []);

    const hidePreCheckoutAddEmailDialog = React.useCallback(() => {
        setShowPreCheckoutAddEmailDialog(false);
    }, []);

    const removeChangedItems = React.useCallback(() => {
        changedItems.forEach(({ item, metadata }) => {
            updateItemInCart({
                itemId: item.id,
                quantity: 0,
                custom: metadata.custom,
                variantId: item.variantId,
            });
        });
    }, [changedItems, updateItemInCart]);

    const onPressProceed = React.useCallback(() => {
        removeChangedItems();

        hidePreCheckoutDialog();

        navigation.getParent()?.navigate("Payment", {
            screen: "PaymentHome",
            params: { cartId: cart?.cartViewData?.id },
        });
    }, [hidePreCheckoutDialog, navigation, removeChangedItems, cart?.cartViewData?.id]);

    const onChangeLocation = React.useCallback(() => {
        setShowAddressListModal(true);
    }, []);

    const getBillComponentLayout = React.useCallback((layout: Layout) => {
        coordinate.current = {
            x: layout.width,
            y: layout.height,
        };
    }, []);

    const showDetailedBill = React.useCallback(() => {
        if (scrollRef.current && mountedRef.current) {
            scrollRef.current.scrollTo({ x: coordinate.current?.x, y: coordinate.current?.y });
        }
    }, [mountedRef]);

    const onPressAddressBottomSheetCta = React.useCallback(async () => {
        if (
            !userSelectedAddress ||
            userSelectedAddress.serviceabilityType === "UNSERVICEABLE" ||
            !addressMeta?.IS_VALID_ADDRESS
        ) {
            Analytics.clickEvent({
                category: addressListLength
                    ? !addressMeta?.IS_VALID_ADDRESS
                        ? "change-address-btn"
                        : "select-address-btn"
                    : "add-address-btn",
                label: cart?.cartViewData?.id,
                context: addressMeta?.IS_VALID_ADDRESS && addressListLength ? "unserviceable" : "",
            });

            onChangeLocation();
        } else {
            Analytics.clickEvent({
                category: PROCEED_CTA_EVENT,
                label: cart?.cartViewData?.id,
                context: "cart-bottom-sheet",
            });

            if (isEmailToBeAdded) {
                setShowPreCheckoutAddEmailDialog(true);
            } else if (canShowPreCheckoutDialog && isPhysicalCart) {
                setShowPreCheckoutDialog(true);
            } else {
                await PaymentProgress.clear();
                navigation.getParent()?.navigate("Payment", {
                    screen: "PaymentHome",
                    params: { cartId: cart?.cartViewData?.id },
                });
            }
        }
    }, [
        userSelectedAddress,
        addressMeta?.IS_VALID_ADDRESS,
        addressListLength,
        cart?.cartViewData?.id,
        onChangeLocation,
        isEmailToBeAdded,
        canShowPreCheckoutDialog,
        isPhysicalCart,
        navigation,
    ]);

    const onPressProceedBottomSheetCta = React.useCallback(async () => {
        Analytics.impressionEvent({
            category: PROCEED_CTA_EVENT,
            label: cart?.cartViewData?.id,
            context: JSON.stringify({
                cartId: cart?.cartViewData?.id,
                billAmount: cart?.cartViewData?.bill.billTotal,
                cartType: cart?.cartViewData?.cartType,
            }),
        });

        if (isEmailToBeAdded) {
            setShowPreCheckoutAddEmailDialog(true);
        } else if (canShowPreCheckoutDialog && isPhysicalCart) {
            setShowPreCheckoutDialog(true);
        } else {
            await PaymentProgress.clear();
            navigation.getParent()?.navigate("Payment", {
                screen: "PaymentHome",
                params: { cartId: cart?.cartViewData?.id },
            });
        }
    }, [isEmailToBeAdded, canShowPreCheckoutDialog, navigation, cart, isPhysicalCart]);

    const cartErrorStatus = React.useMemo(
        () => getCartErrorStatus(cart?.cartViewData?.cartItems, cartStatus),
        [cart?.cartViewData?.cartItems, cartStatus],
    );

    const contentContainerStyle = {
        paddingBottom: bottomComponentHeight + 2 * SpacingValue["space-medium"],
    };

    const scrollPaddingHorizontal = useSelectScreen({
        default: {
            paddingHorizontal: 0,
        },
        md: {
            paddingHorizontal: SpacingValue["space-x-large"],
        },
        sm: {
            paddingHorizontal: SpacingValue["space-medium"],
        },
        xs: {
            paddingHorizontal: SpacingValue["space-medium"],
        },
    });

    const onBottomComponentLayout = React.useCallback((e: LayoutChangeEvent) => {
        if (e.nativeEvent.layout.height > BOTTOM_COMPONENT_HEIGHT) {
            setBottomComponentHeight(e.nativeEvent.layout.height);
        }
    }, []);

    const getAddressMeta = React.useCallback(async () => {
        try {
            const result = await addressBottomSheetMeta();
            if (mountedRef.current && result) {
                setAddressMeta(result);
            }
        } catch (err) {
            Logger.recordError(err);
        }
    }, [addressBottomSheetMeta, mountedRef]);

    React.useEffect(() => {
        if (cart?.cartViewData?.cartType !== "PHYSICAL") {
            return;
        }

        getAddressMeta();
    }, [getAddressMeta, cart?.cartViewData?.cartType]);

    useMount(() => {
        const address = getAddressById(addressList, selectedAddressId);

        if (cartStatus === "SELECTED_ADDRESS_UNSERVICEABLE") {
            showToast("Your new address is unserviceable");
        }

        onSelectAddress(address);
    });

    const isFocused = useIsFocused();

    const fireCouponEvent = React.useCallback(
        (eventName: string) => {
            Analytics.clickEvent({
                category: eventName,
                label: `cartId: ${cart?.cartViewData?.id}`,
                context: `couponCode: ${cart?.cartViewData?.bestCoupon?.coupon.code}`,
            });
        },
        [cart?.cartViewData?.id, cart?.cartViewData?.bestCoupon?.coupon.code],
    );

    const onApply = React.useCallback(() => {
        fireCouponEvent("apply-coupon-btn");

        activateOrDeactivateCelebrationModal(true);
        applyCouponHook(cart?.cartViewData?.bestCoupon?.coupon.code);
    }, [
        cart?.cartViewData?.bestCoupon?.coupon.code,
        activateOrDeactivateCelebrationModal,
        applyCouponHook,
        fireCouponEvent,
    ]);

    const onRemove = React.useCallback(() => {
        fireCouponEvent("remove-coupon-btn");

        applyCouponHook();
    }, [applyCouponHook, fireCouponEvent]);

    const onViewAllCoupons = React.useCallback(() => {
        fireCouponEvent("view-coupons-btn");

        // navigate to coupons screen
        navigation.getParent()?.navigate("Coupons", {
            cartId: cart?.cartViewData?.id,
        });
    }, [cart?.cartViewData?.id, navigation, fireCouponEvent]);

    /** Memoised to prevent it from updating onPressChatToConfirm on every render */
    const snackbarStyle = React.useMemo(() => {
        return {
            marginBottom: bottomComponentHeight - 2 * SpacingValue["space-large"],
        };
    }, [bottomComponentHeight]);

    const checkIsChatBlocked = React.useCallback(
        (blockedInfo: SdkConversation["blockedInfo"]): boolean => {
            if (blockedInfo?.blockedBy?.length) {
                dataHandler(true, blockedInfo);
                return false;
            }

            return true;
        },
        [dataHandler],
    );

    const cancellationPolicy = useMemo(() => {
        return getCancellationPolicies(cart?.cartViewData?.cartType ?? "PHYSICAL");
    }, [cart?.cartViewData?.cartType]);

    // if there are no billable items in the cart, disable the proceed button
    // billable items are the ones that have not been changed while it was in the cart
    // const isProceedDisabled = !cart?.cartViewData?.bill?.items?.length;

    const isProceedDisabled = React.useMemo(
        () =>
            !cart?.cartViewData?.bill?.items?.length ||
            (cartStatus && CART_V2_ERRORS_STATUS.indexOf(cartStatus) > -1),
        [cart?.cartViewData?.bill?.items?.length, cartStatus],
    );

    const hideCartInstructions = React.useMemo(() => {
        return cart?.cartStatus && CART_V2_ERRORS_STATUS.indexOf(cart?.cartStatus) > -1;
    }, [cart?.cartStatus]);

    return (
        <Box style={styles.container}>
            <IOScrollView
                style={[styles.scrollStyle, scrollPaddingHorizontal]}
                ref={scrollRef}
                bounces={false}
                contentContainerStyle={contentContainerStyle}>
                <ChangedItems changedItems={changedItems} removeChangedItems={removeChangedItems} />

                {cartStatus === "STORE_BLOCKED" ? (
                    <Stack spacing={SpacingValue["space-medium"]}>
                        <CartItemList />
                        <CartAlert
                            cartStatus={cartStatus}
                            storeId={storeId}
                            checkIsChatBlocked={checkIsChatBlocked}
                        />
                    </Stack>
                ) : (
                    <Stack spacing={SpacingValue["space-medium"]}>
                        <CartItemList />

                        {hideCartInstructions ? (
                            <CartAlert
                                cartStatus={cartStatus}
                                storeId={storeId}
                                checkIsChatBlocked={checkIsChatBlocked}
                            />
                        ) : (
                            <CartInstructions />
                        )}

                        {cart && cart?.cartViewData?.couponsAvailable ? (
                            <CouponCard
                                cartId={cart.cartViewData?.id}
                                storeId={storeId}
                                cartSyncing={cartSyncing}
                                couponsAvailable={!cart?.cartViewData?.couponsAvailable}
                                bestCoupon={cart?.cartViewData?.bestCoupon}
                                appliedCoupon={cart?.cartViewData?.appliedCoupon}
                                onApply={onApply}
                                onRemove={onRemove}
                                onViewAllCoupons={onViewAllCoupons}
                            />
                        ) : null}

                        <Bill storeId={storeId} getBillComponentLayout={getBillComponentLayout} />
                        <CancellationPolicy policy={cancellationPolicy} />
                    </Stack>
                )}
            </IOScrollView>

            {cartErrorStatus ? (
                cartErrorStatus === "GUEST_CART" && isWeb ? (
                    <GuestCheckoutBottomSheet
                        storeId={storeId}
                        open={isFocused}
                        onLayout={onBottomComponentLayout}
                    />
                ) : (
                    <CartError
                        storeId={storeId}
                        onLayout={onBottomComponentLayout}
                        open={isFocused}
                    />
                )
            ) : (
                <>
                    {addressMeta ? (
                        <CartAddressBottomSheet
                            title={addressMeta.TITLE || ""}
                            description={addressMeta.DESCRIPTION || ""}
                            ctaText={addressMeta.CTA_TEXT || ""}
                            onPressCta={onPressAddressBottomSheetCta}
                            showErrorMessage={addressMeta.SHOW_ERROR_MESSAGE}
                            hideCheveronIcon={addressMeta.HIDE_CHEVRON_ICON}
                            addressListLength={addressListLength}
                            onChangeLocation={onChangeLocation}
                            storeId={storeId}
                            showDetailedBill={showDetailedBill}
                            onLayout={onBottomComponentLayout}
                            show={isFocused && showPreCheckoutDialog === false}
                            cartId={cart?.cartViewData?.id}
                            isPaymentDown={!cart?.payments?.active}
                            paymentDowntimeMessage={cart?.payments?.message}
                            addressIcon={addressMeta.ADDRESS_ICON}
                            isProceedDisabled={isProceedDisabled}
                        />
                    ) : null}

                    {isPhysicalCart ? null : (
                        <CartProceedBottomSheet
                            cartStatus={cart?.cartStatus}
                            ctaText={"Proceed"}
                            onPressCta={onPressProceedBottomSheetCta}
                            storeId={storeId}
                            showDetailedBill={showDetailedBill}
                            cartType={cart?.cartViewData?.cartType}
                        />
                    )}

                    {showAddressListModal ? (
                        <AddressList
                            storeId={storeId}
                            open={showAddressListModal}
                            setOpen={setShowAddressListModal}
                            setAddressSelected={onSelectAddress}
                        />
                    ) : null}

                    {canShowPreCheckoutDialog ? (
                        <PreCheckoutDialog
                            showDialog={showPreCheckoutDialog}
                            closeDialog={hidePreCheckoutDialog}
                            onPressContinue={onPressProceed}
                            cartId={cart?.cartViewData?.id || ""}
                            cartType={cart?.cartViewData?.cartType}
                        />
                    ) : null}

                    <PreCheckoutAddEmailDialog
                        canShowDialog={showPreCheckoutAddEmailDialog}
                        onCloseDialog={hidePreCheckoutAddEmailDialog}
                        onPressContinue={onPressProceed}
                    />
                    <CouponErrorSnackbar
                        errorText={errorText}
                        canShowSnackbar={canShowSnackbar}
                        dismissSnackbar={dismissSnackbar}
                        extraStyle={snackbarStyle}
                    />
                    <AppointmentErrorSnackbar
                        errorText={appointmentErrorText}
                        canShowSnackbar={canShowSnackbarForAppointment}
                        dismissSnackbar={dismissSnackbarForAppointment}
                        ctaText="Choose"
                        cart={cart?.cartViewData}
                    />
                </>
            )}

            <OverlayLoader visible={!!cartSyncing} style={styles.overlay} />
        </Box>
    );
};

const styles = StyleSheet.create({
    scrollStyle: {
        paddingVertical: SpacingValue["space-medium"],
    },
    overlay: {
        zIndex: 9999,
    },
    container: {
        height: "100%",
        overflow: "hidden",
    },
});
