/* eslint-disable react-native/no-inline-styles */
import * as React from "react";
import { Pressable, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";
import { StarSellerBadge } from "@swiggy-private/connect-business-commons";

import { ShopLogo } from "@minis-consumer/components/shop-logo";
import { getStoreBrandImageUrl } from "@minis-consumer/helpers/store";
import { Divider } from "@minis-consumer/components/divider";
import { MinisRouteList } from "@minis-consumer/interfaces/route";
import { OrderStore } from "@minis-consumer/interfaces/order";
import { Analytics } from "@minis-consumer/analytics";
import { useShopNavigation } from "@minis-consumer/hooks/use-shop-navigation";

type StoreGistProps = {
    store: OrderStore;
    location: string;
    orderId: string;
    isStarSeller?: boolean;
    showChatIcon?: boolean;
};

const StoreGist: React.FC<StoreGistProps> = ({
    store,
    location,
    orderId,
    showChatIcon = false,
    isStarSeller,
}) => {
    const minisNavigation = useNavigation<NativeStackNavigationProp<MinisRouteList>>();

    const goToStore = useShopNavigation();

    const isStoreEnabled = store.enabled !== false;

    const { value: theme } = useTheme();

    const storeBrandImageId = React.useMemo(
        () => store.settings.brandImageId,
        [store.settings.brandImageId],
    );

    const logoBorderSize = useSelectScreen({
        lg: 16,
        default: 10,
    });

    const iconSize = useSelectScreen({
        lg: 60,
        default: 40,
    });

    const chatSize = useSelectScreen({
        lg: 24,
        default: 20,
    });

    const storeCategory: TextProps["category"] = useSelectScreen({
        lg: "h4",
        default: "h5",
    });

    const locationCategory: TextProps["category"] = useSelectScreen({
        lg: "s3",
        default: "b2",
    });

    const onChatPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: "track-order-card-chat-btn",
            label: orderId,
        });

        minisNavigation?.navigate("ChatConversation", { storeId: store.id });
    }, [minisNavigation, orderId, store.id]);

    useMount(() => {
        if (showChatIcon && isStoreEnabled) {
            Analytics.impressionEvent({
                category: "track-order-card-chat-btn",
                label: orderId,
            });
        }
    });

    return (
        <Stack
            spacing={{
                lg: SpacingValue["space-x-large"],
                default: SpacingValue["space-medium"],
            }}>
            <Stack direction="row" justifyContent="space-between">
                <Pressable onPress={() => goToStore(store?.slug)}>
                    <Stack direction="row" flex={1} style={{ opacity: isStoreEnabled ? 1 : 0.5 }}>
                        <ShopLogo
                            isImageKitEnabled
                            size={iconSize}
                            url={
                                storeBrandImageId
                                    ? getStoreBrandImageUrl(
                                          storeBrandImageId,
                                          {
                                              height: iconSize,
                                          },
                                          true,
                                      )
                                    : null
                            }
                            borderRadius={logoBorderSize}
                        />
                        <Stack style={styles.storeDetails}>
                            <Box direction="row" alignItems="center">
                                <Text
                                    category={storeCategory}
                                    color="color-basic-100"
                                    numberOfLines={1}
                                    style={styles.storeName}>
                                    {store.name}
                                </Text>
                                {isStarSeller ? (
                                    <StarSellerBadge
                                        outerGradientStyle={styles.badgeGradient}
                                        rootContainerStyle={{
                                            padding: SpacingValue["space-xxx-small"],
                                        }}
                                    />
                                ) : null}
                            </Box>

                            <Text
                                category={locationCategory}
                                weight="regular"
                                color="color-basic-60">
                                {location}
                            </Text>
                        </Stack>
                    </Stack>
                </Pressable>

                {showChatIcon && isStoreEnabled ? (
                    <Pressable
                        onPress={onChatPress}
                        style={[
                            styles.svgContainer,
                            {
                                height: iconSize,
                                width: iconSize,
                                borderWidth: 1,
                                borderColor: theme["color-primary"],
                                borderRadius: iconSize / 2,
                            },
                        ]}>
                        <SvgIcon
                            icon="Chat"
                            width={chatSize}
                            height={chatSize}
                            color="color-primary"
                        />
                    </Pressable>
                ) : null}
            </Stack>
            <Divider />
        </Stack>
    );
};

const styles = StyleSheet.create({
    svgContainer: {
        borderRadius: 50,
        alignItems: "center",
        justifyContent: "center",
        shadowColor: "transparent",
    },
    storeDetails: {
        marginLeft: SpacingValue["space-medium"],
        maxWidth: "75%",
    },
    badgeGradient: {
        padding: 0,
        marginLeft: SpacingValue["space-xxx-small"],
    },
    storeName: {
        flexShrink: 1,
    },
});

export default React.memo(StoreGist);
