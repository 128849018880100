import * as React from "react";
import { StyleSheet, useWindowDimensions, Platform } from "react-native";

import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Button, Text, SpacingValue, Input, CheckBox } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { ApiError } from "@swiggy-private/connect-api-core";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Analytics } from "@minis-consumer/analytics";
import { saveMinisUserEmail } from "@minis-consumer/api/user";
import { Logger } from "@minis-consumer/includes/logger";
import { isValidEmail } from "@minis-consumer/helpers/email";

import { EMAIL_DETAILS, EMAIL_USAGE_DESCRIPTION, ORDER_DELIVERY, SAVE } from "../constants";

type DialogBodyProps = {
    onPressContinue: VoidFunction;
};

const DialogBodyComponent: React.FC<DialogBodyProps> = ({ onPressContinue }) => {
    const store = useStoreInfo();
    const { width: screenWidth } = useWindowDimensions();

    const imgWidth = Math.min(140, screenWidth * 0.37);
    const imgHeight = imgWidth * 0.8;

    const imgMarginOffset = useSelectScreen({
        default: 0,
        lg: SpacingValue["space-large"],
    });

    const imgTopMargin = React.useMemo(
        () => imgHeight / 2 + imgMarginOffset,
        [imgHeight, imgMarginOffset],
    );

    const [email, setEmail] = React.useState("");
    const [emailError, setEmailError] = React.useState("");
    const [saveMethod, setSaveMethod] = React.useState(true);

    const validateInputs = React.useCallback(() => {
        if (isValidEmail(email)) {
            return true;
        } else {
            setEmailError("Oops! This email ID is invalid");
            return false;
        }
    }, [email]);

    const onPressCta = React.useCallback(async () => {
        try {
            const emailAdded = await saveMinisUserEmail({ email: email });
            if (emailAdded) {
                onPressContinue();
            }
        } catch (err) {
            setEmailError((err as ApiError).message);
            Logger.error(err);
        }
    }, [email, onPressContinue]);

    const clearEmailError = React.useCallback(
        () => emailError?.length && setEmailError(""),
        [emailError],
    );

    const onChangeTextEmail = React.useCallback((newEmail: string) => {
        setEmail(newEmail.replace(/\s/g, ""));
    }, []);

    const accessoryRightEmailError = React.useCallback(
        () => <>{emailError ? <TextInputAccessoryRightError /> : null}</>,
        [emailError],
    );

    const toggleSaveMethod = React.useCallback(() => {
        Analytics.clickEvent({
            category: "save-add-email-check-box",
            context: JSON.stringify({ checked: !saveMethod }),
        });

        setSaveMethod(!saveMethod);
    }, [saveMethod]);

    const onBlur = React.useCallback(() => {
        if (validateInputs()) {
            setEmailError("");
        }
    }, [validateInputs]);

    const activateCta = React.useMemo(() => {
        return email && saveMethod && isValidEmail(email);
    }, [email, saveMethod]);

    return (
        <>
            <CdnImage
                isImageKitEnabled
                id="minis/shopping_bag_and_letter"
                width={imgWidth}
                height={imgHeight}
                style={[
                    styles.img,
                    {
                        marginTop: -imgTopMargin,
                    },
                ]}
            />

            <Stack spacing={SpacingValue["space-x-large"]}>
                <Stack spacing={SpacingValue["space-x-small"]}>
                    <Text style={styles.txt} category="h4" weight="bold">
                        {ORDER_DELIVERY}
                        <Text style={styles.txt} category="h4" color="primary" weight="bold">
                            {`\n${store.name}`}
                        </Text>
                    </Text>

                    <Text category="b2" style={styles.txt} color="color-basic-60">
                        {EMAIL_DETAILS}
                        <Text
                            style={styles.txt}
                            category="b2"
                            color="color-basic-75"
                            weight="medium">
                            {" order updates!"}
                        </Text>
                    </Text>
                </Stack>
                <Stack spacing={SpacingValue["space-medium"]}>
                    <Input
                        label="Enter Email address"
                        keyboardType="email-address"
                        autoCorrect={false}
                        autoFocus={!(Platform.OS === "web")}
                        autoCapitalize={"none"}
                        spellCheck={false}
                        value={email}
                        error={!!emailError?.length}
                        hint={emailError}
                        onFocus={clearEmailError}
                        onChangeText={onChangeTextEmail}
                        onBlur={onBlur}
                        accessoryRight={accessoryRightEmailError}
                        style={{ marginTop: SpacingValue["space-x-small"] }}
                    />

                    <Stack
                        direction="row"
                        spacing={SpacingValue["space-x-small"]}
                        alignItems="center">
                        <CheckBox
                            onChange={toggleSaveMethod}
                            checked={saveMethod}
                            hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
                        />
                        <Text category="b2" color="color-basic-60">
                            {EMAIL_USAGE_DESCRIPTION}
                        </Text>
                    </Stack>
                </Stack>

                <Button style={styles.btn} onPress={onPressCta} disabled={!activateCta}>
                    {SAVE}
                </Button>
            </Stack>
        </>
    );
};

export const TextInputAccessoryRightError: React.FC = () => (
    <SvgIcon icon="AlertCircle" width={18} height={18} color="color-negative" />
);

const styles = StyleSheet.create({
    txt: {
        textAlign: "center",
    },
    btn: {
        width: "100%",
    },
    img: {
        alignSelf: "center",
        marginBottom: SpacingValue["space-x-large"],
    },
});

export const DialogBody = React.memo(DialogBodyComponent);
