import React from "react";
import { StyleSheet } from "react-native";

import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import { useNavigation } from "@react-navigation/native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { useIsMobileWeb } from "@minis-consumer/hooks/use-mweb";
import { HomeRouteList } from "@minis-consumer/interfaces/route";

const HeaderLeftComponent: React.FC = () => {
    const navigation = useNavigation<BottomTabNavigationProp<HomeRouteList, "Cart">>();
    const isMweb = useIsMobileWeb();

    const goBack = React.useCallback(() => {
        if (isMweb) {
            navigation.reset({
                index: 0,
                routes: [{ name: "Shop" }],
            });
            return;
        }

        if (navigation.getParent()?.canGoBack()) {
            navigation.getParent()?.goBack();
        } else if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.jumpTo("Shop");
        }
    }, [isMweb, navigation]);

    return (
        <Box
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            style={styles.container}>
            <SvgIcon
                icon="ArrowLeft"
                onPress={goBack}
                width={20}
                height={20}
                hitSlop={{ top: 16, left: 16, right: 16, bottom: 16 }}
            />
        </Box>
    );
};

export const HeaderLeft = React.memo(HeaderLeftComponent);

const styles = StyleSheet.create({
    container: {
        paddingLeft: SpacingValue["space-medium"],
    },
});
