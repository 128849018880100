import React from "react";
import { StyleSheet } from "react-native";

import { Box, Stack, StackProps, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, FONT_BOLD } from "@swiggy-private/rn-dls";
import { Divider } from "@swiggy-private/react-native-ui";

import { ReadMoreTextProps } from "@minis-consumer/components/read-more-text";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { DeliveryInfoActions } from "@minis-consumer/routes/shop/ux2.0/components/delivery-info-actions";
import { useHasPhysicalProducts } from "@minis-consumer/hooks/use-get-products";

import { ShopActions } from "../shop-actions";
import { PerfMetrics } from "../perf-metrics";
import { SocialMetrics } from "../social-metrics";

interface Props {
    setDeliveryInfoOffset?: (offset: number) => void;
}

const ShopHeaderComponent: React.FC<Props> = ({ setDeliveryInfoOffset }) => {
    const storeInfo = useStoreInfo();

    const isStoreEnabled = storeInfo.enabled !== false;
    const isDeliveryInfoViewEnabled = storeInfo.deliveryInfoViewEnabled !== false;

    const hasPhysicalProducts = useHasPhysicalProducts();
    const canShowDeliveryInfo =
        isStoreEnabled &&
        isDeliveryInfoViewEnabled &&
        storeInfo.address &&
        hasPhysicalProducts &&
        (storeInfo.interDeliveryConfig || storeInfo.intraDeliveryConfig);

    const hasSocialMetrics = !!storeInfo.socialMetrics?.length;

    const canShowPerfMetrics =
        storeInfo.settings.showPerformanceMetrics === true && storeInfo.performanceMetrics;

    const storeCategories = React.useMemo(
        () => storeInfo.categories?.join(", "),
        [storeInfo.categories],
    );

    const shopHeaderStyle = {
        paddingTop: isStoreEnabled ? SpacingValue["space-small"] : 0,
    };

    const stackDirection: StackProps["direction"] = { default: "column", md: "row", lg: "row" };

    const descriptionTextProps: ReadMoreTextProps["textProps"] = {
        category: "b2",
        weight: "regular",
        color: "color-basic-60",
    };

    const storeNameStyle = useSelectScreen({
        lg: { fontFamily: FONT_BOLD },
        md: { fontFamily: FONT_BOLD },
        default: undefined,
    });

    return (
        <Stack direction={stackDirection} style={shopHeaderStyle}>
            <Stack flex={1} spacing={SpacingValue["space-medium"]}>
                <Stack divider={<Divider style={styles.divider} color="border_Secondary" />}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        flexShrink={1}
                        spacing={SpacingValue["space-xx-small"]}>
                        <Stack flex={3}>
                            <Text
                                category="h2"
                                color="text_High_Emphasis"
                                style={[styles.storeName, storeNameStyle]}>
                                {storeInfo.name}
                            </Text>

                            {storeCategories && isStoreEnabled ? (
                                <Text category="b2" color="text_Med_Emphasis" numberOfLines={1}>
                                    {storeCategories}
                                </Text>
                            ) : null}
                        </Stack>

                        <ShopActions isStoreEnabled={isStoreEnabled} style={styles.shopActions} />
                    </Stack>

                    {canShowPerfMetrics ? (
                        <PerfMetrics performanceMetrics={storeInfo.performanceMetrics} />
                    ) : null}

                    {canShowDeliveryInfo ? (
                        <DeliveryInfoActions setDeliveryInfoOffset={setDeliveryInfoOffset} />
                    ) : null}
                </Stack>

                {storeInfo?.settings?.storeBio || hasSocialMetrics ? (
                    <Divider color="border_Secondary" />
                ) : null}

                {storeInfo?.settings?.storeBio ? (
                    <Box mb={SpacingValue["space-xx-small"]}>
                        <Text {...descriptionTextProps}>{storeInfo.settings.storeBio}</Text>
                    </Box>
                ) : null}

                {hasSocialMetrics ? (
                    <SocialMetrics socialMetrics={storeInfo.socialMetrics} />
                ) : null}
            </Stack>
        </Stack>
    );
};

if (__DEV__) {
    ShopHeaderComponent.displayName = "ShopHeaderComponent";
}

export const ShopHeader = React.memo(ShopHeaderComponent);

const styles = StyleSheet.create({
    divider: {
        marginVertical: SpacingValue["space-medium"],
    },
    shopActions: {
        marginTop: 0,
        flex: 1,
    },
    storeName: {
        marginLeft: -SpacingValue["space-xxx-small"] / 2,
    },
});
