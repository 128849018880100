import * as React from "react";
import { StyleSheet } from "react-native";

import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Text, TextProps, SpacingValue } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";
import { ProductVariantValuesLabel } from "@minis-consumer/components/variants";

type BillItemProps = {
    name: string;
    price: number;
    discountedPrice?: number;
    quantity?: number;
    description?: string;
    weight?: number;
    finalTotal?: boolean;
    showInfoIcon?: boolean;
    variants?: string[];
};

const BillItem: React.FC<BillItemProps> = ({
    name,
    price,
    discountedPrice,
    quantity,
    description,
    weight,
    finalTotal,
    showInfoIcon,
    variants,
}) => {
    const wasDiscountGiven = React.useMemo(
        () => Boolean(typeof discountedPrice === "number" && discountedPrice < price),
        [discountedPrice, price],
    );

    const formattedPriceValue = React.useMemo(
        () => formatNumberWithIndianSystem(price, { showRupeeSymbol: true }),
        [price],
    );

    const formattedDiscountPriceValue = React.useMemo(
        () => formatNumberWithIndianSystem(discountedPrice || 0, { showRupeeSymbol: true }),
        [discountedPrice],
    );

    const category: TextProps["category"] = useSelectScreen({
        lg: finalTotal ? "h5" : "b1",
        default: finalTotal ? "b1" : "b2",
    });

    const weightInKg = weight ? `${weight} kg(s)` : "";
    const quantityWithPrefix = quantity ? ` x ${quantity}` : "";

    const item =
        [name, description, weightInKg].filter(Boolean).join(" - ") + (quantityWithPrefix ?? "");

    return (
        <Stack direction="row" justifyContent="space-between">
            <Stack flex={1} spacing={SpacingValue["space-xx-small"]}>
                <Stack
                    flex={1}
                    direction="row"
                    spacing={SpacingValue["space-x-small"]}
                    style={styles.wd80}>
                    <Text
                        category={category}
                        weight={finalTotal ? "bold" : "medium"}
                        color="high"
                        numberOfLines={3}>
                        {item}
                    </Text>
                    {showInfoIcon ? (
                        // @To Do: on press behavior
                        <SvgIcon icon="Info" width={16} height={16} color="color-basic-45" />
                    ) : null}
                </Stack>

                <ProductVariantValuesLabel values={variants} />
            </Stack>
            <Stack direction="row" spacing={SpacingValue["space-xx-small"]}>
                {wasDiscountGiven ? (
                    <Text
                        category={category}
                        weight={finalTotal ? "bold" : "medium"}
                        color="color-basic-45"
                        style={styles.strikethrough}>
                        {formattedPriceValue}
                    </Text>
                ) : (
                    ""
                )}
                <Text category={category} weight={finalTotal ? "bold" : "medium"} color="high">
                    {wasDiscountGiven ? formattedDiscountPriceValue : formattedPriceValue}
                </Text>
            </Stack>
        </Stack>
    );
};

const styles = StyleSheet.create({
    wd80: {
        width: "80%",
    },
    strikethrough: {
        textDecorationStyle: "solid",
        textDecorationLine: "line-through",
    },
});

export default React.memo(BillItem);
