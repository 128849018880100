import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { getMediaUrl } from "@minis-consumer/helpers/media";
import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";
import { OrderAddress } from "@minis-consumer/interfaces/order";

export interface Props {
    storeName: string;
    storeAddress: OrderAddress;
    numberOfItems: number;
    amount: number;
    storeLogo?: string;
}

const LOGO_CONTAINER_SIZE = 38;

const CardHeaderComponent: React.FC<Props> = ({
    storeName,
    storeAddress,
    numberOfItems,
    amount,
    storeLogo,
}) => {
    const { value: theme } = useTheme();

    const displayAmount = formatNumberWithIndianSystem(amount, { showRupeeSymbol: true });

    const brandLogo = React.useMemo(
        () =>
            storeLogo
                ? getMediaUrl(
                      storeLogo,
                      {
                          height: LOGO_CONTAINER_SIZE,
                          width: LOGO_CONTAINER_SIZE,
                      },
                      true,
                  )
                : null,
        [storeLogo],
    );

    const storeLocation = React.useMemo(
        () => [storeAddress?.locality, storeAddress?.city].filter(Boolean).join(", "),
        [storeAddress],
    );

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={SpacingValue["space-medium"]}>
            <Stack direction="row" spacing={SpacingValue["space-x-small"]} flex={1}>
                <Box style={[styles.logoContainer, { borderColor: theme["color-basic-15"] }]}>
                    {brandLogo ? (
                        <CdnImage
                            isImageKitEnabled
                            id={brandLogo}
                            height={LOGO_CONTAINER_SIZE}
                            width={LOGO_CONTAINER_SIZE}
                            resizeMode="cover"
                        />
                    ) : (
                        <SvgIcon icon="Store" height={24} width={24} color="color-basic-60" />
                    )}
                </Box>

                <Box flex={1}>
                    <Text category="b1" weight="bold" color="high" numberOfLines={1}>
                        {storeName}
                    </Text>
                    <Text category="b2" color="medium" numberOfLines={1}>
                        {storeLocation}
                    </Text>
                </Box>
            </Stack>

            <Stack direction="row" spacing={SpacingValue["space-x-small"]} alignItems="center">
                <Stack alignItems="flex-end">
                    <Text category="b2" color="high">
                        {`${numberOfItems} item${numberOfItems === 1 ? "" : "s"}`}
                    </Text>
                    <Text category="b2" color="high" weight="bold">
                        {displayAmount}
                    </Text>
                </Stack>

                <SvgIcon icon="ChevronRight" color="color-basic-75" />
            </Stack>
        </Stack>
    );
};

export const CardHeader = React.memo(CardHeaderComponent);

const styles = StyleSheet.create({
    logoContainer: {
        borderWidth: 1,
        borderRadius: 10,
        overflow: "hidden",
        height: LOGO_CONTAINER_SIZE,
        width: LOGO_CONTAINER_SIZE,
        justifyContent: "center",
        alignItems: "center",
    },
});
