import React, { FC, PropsWithChildren, useCallback } from "react";
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { useReturnOrder } from "@minis-consumer/routes/order-track/hooks/use-return-order";
import { CommonRouteList } from "@minis-consumer/interfaces/route";

import { DISPLAY_COPIES } from "./constants";
import { HeaderTitle } from "./components/header-title";
import { SelectReturnReasonCard } from "./components/select-reason-card";

const { DISCLAIMER } = DISPLAY_COPIES;

type Props = NativeStackScreenProps<CommonRouteList, "SelectReturnReason">;

export const SelectReturnReasonScreen: FC<PropsWithChildren<Props>> = ({ navigation, route }) => {
    const { value: theme } = useTheme();
    const { order, selectedItems = [] } = route?.params || {};

    const { orderId } = useReturnOrder(order, selectedItems);

    const onHelpPress = useCallback(() => {
        navigation.navigate("Account", {
            screen: "Help",
            params: { screen: "HelpDetailsLevelOne", params: { level1: "orders" } },
        });
    }, [navigation]);

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerShadowVisible: true,
            headerTitle: () => <HeaderTitle orderId={orderId} />,
            headerRight: () => (
                <SvgIcon
                    icon={"Question"}
                    height={20}
                    width={20}
                    color="color-basic-60"
                    onPress={onHelpPress}
                    style={styles.svg}
                />
            ),
        });
    }, [navigation, onHelpPress, orderId]);

    return (
        <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS == "ios" ? "padding" : undefined}>
            <ScrollView
                style={[styles.root, { backgroundColor: theme["color-background-secondary"] }]}
                automaticallyAdjustKeyboardInsets>
                <SelectReturnReasonCard
                    order={order}
                    selectedItems={selectedItems}
                    navigation={navigation as any}
                />

                <Box mt={SpacingValue["space-medium"]} pb={SpacingValue["space-x-large"] * 4}>
                    <Text category="b3" color="color-basic-60">
                        {DISCLAIMER}
                    </Text>
                </Box>
            </ScrollView>
        </KeyboardAvoidingView>
    );
};

const styles = StyleSheet.create({
    root: {
        flexGrow: 1,
        padding: SpacingValue["space-medium"],
    },
    svg: {
        marginRight: SpacingValue["space-medium"],
    },
    itemsInfo: {
        paddingVertical: SpacingValue["space-medium"],
    },
    divider: {
        marginVertical: SpacingValue["space-x-small"],
    },
    commentBox: {
        marginTop: SpacingValue["space-small"],
        minHeight: 84,
        overflow: "hidden",
    },
    inputText: {
        paddingTop: 0,
    },
    phoneInput: {
        marginVertical: SpacingValue["space-x-small"],
    },
});
