import React, { memo, useMemo } from "react";
import { StyleSheet } from "react-native";

import { Card } from "@minis-consumer/components/card";
import { Divider, ItemImages } from "@swiggy-private/react-native-ui";
import { Button, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { OrderEntity } from "@minis-consumer/resources/order";
import { OrderItem as IOrderItem } from "@minis-consumer/interfaces/order";
import { getOrderItemsTitle } from "@minis-consumer/helpers/order";

import { DISPLAY_COPIES } from "../constants";

interface Props {
    order: OrderEntity;
    selectedItems: IOrderItem[];
    onSubmit: VoidFunction;
}

const ReturnConfirmationComp: React.FC<Props> = ({ order, selectedItems, onSubmit }) => {
    const { items: orderItems } = order;

    const itemsToConsider = useMemo(
        () => (selectedItems?.length ? selectedItems : orderItems || []),
        [orderItems, selectedItems],
    );

    const selectedItemsImageIds = useMemo(
        () =>
            itemsToConsider.reduce((acc, item) => {
                const { imageIds } = item?.item || {};
                const firstImageId = imageIds?.[0];
                return firstImageId ? [...acc, firstImageId] : acc;
            }, [] as string[]),
        [itemsToConsider],
    );

    const itemsTitle = getOrderItemsTitle(itemsToConsider);

    return (
        <Card style={styles.card}>
            <Stack direction="row" alignItems="center" style={styles.itemsInfo}>
                <ItemImages imageIds={selectedItemsImageIds} />

                <Box flex={1} ml={SpacingValue["space-x-large"]}>
                    <Text
                        category="b1"
                        weight="medium"
                        color="color-basic-75"
                        numberOfLines={2}
                        ellipsizeMode="middle">
                        {itemsTitle}
                    </Text>
                </Box>
            </Stack>
            <Divider style={styles.divider} />
            <Stack>
                <Text category="b1" color="color-basic-75">
                    {DISPLAY_COPIES.SUBMIT_RETURN_TEXT}
                </Text>
            </Stack>

            <Button style={styles.button} onPress={onSubmit}>
                {DISPLAY_COPIES.DONE}
            </Button>
        </Card>
    );
};

const styles = StyleSheet.create({
    card: {
        maxWidth: 460,
    },
    itemsInfo: {
        paddingBottom: SpacingValue["space-medium"],
    },
    divider: {
        marginVertical: SpacingValue["space-small"],
    },
    button: {
        marginTop: SpacingValue["space-medium"],
    },
});

export const ReturnConfirmation = memo(ReturnConfirmationComp);
