import React from "react";
import { ViewStyle } from "react-native";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";
import { useNavigationBack } from "@minis-consumer/hooks/use-navigation-back";

type HeaderLeftComponentProps = {
    orderId: string;
    orderDate: string;
    numberOfItems: number;
    orderValue: number;
    containerStyle?: ViewStyle;
    isGuestOrder: boolean;
};

const HeaderLeftComponent: React.FC<HeaderLeftComponentProps> = ({
    orderId,
    orderDate,
    numberOfItems,
    orderValue,
    containerStyle,
    isGuestOrder,
}) => {
    const goBack = useNavigationBack();

    const formattedOrderValue = React.useMemo(
        () => formatNumberWithIndianSystem(orderValue, { showRupeeSymbol: true }),
        [orderValue],
    );

    return (
        <Stack
            style={containerStyle}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={SpacingValue["space-medium"]}>
            <SvgIcon
                icon="ArrowLeft"
                onPress={goBack}
                height={20}
                width={20}
                color="color-secondary-400"
                hitSlop={{
                    left: 16,
                    right: 16,
                    top: 16,
                    bottom: 16,
                }}
            />
            <Stack spacing={SpacingValue["space-xxx-small"]}>
                <Text category="b1" weight="bold" color="highest" selectable>
                    {`Order #${orderId}`}
                </Text>
                <Text category="b3" weight="regular" color="medium">
                    {isGuestOrder
                        ? `Placed on ${orderDate}`
                        : `${orderDate}, ${numberOfItems} items, ${formattedOrderValue}`}
                </Text>
            </Stack>
        </Stack>
    );
};

export default React.memo(HeaderLeftComponent);
