import * as React from "react";
import { StyleSheet } from "react-native";

import { useRoute } from "@react-navigation/native";

import { useMount } from "@swiggy-private/react-hooks";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { withResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { META_PIXEL_EVENT_NAMES } from "@minis-consumer/constants/meta-pixel-analytics";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { useDeliveryETAContextStatus } from "@minis-consumer/hooks/use-delivery-eta";
import { PaymentProceedRouteProp } from "@minis-consumer/interfaces/route";
import { ORDER_SUCCESS_COPY } from "@minis-consumer/routes/payment/constants";
import { withPaymentMainContainer } from "@minis-consumer/routes/payment/containers/main-container";
import { useNavigateToOrderWithReset } from "@minis-consumer/routes/payment/hooks/use-navigate-order";

const ProceedSuccessComponent: React.FC = () => {
    const route = useRoute<PaymentProceedRouteProp<"PaymentSuccess">>();

    const { orderId, storeName, orderType } = route.params;
    const navigateToOrder = useNavigateToOrderWithReset();

    const { selectedAddress, isDeliveryETAAvailable } = useDeliveryETAContextStatus();

    useMount(() => {
        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.PURCHASE, { content_name: orderId });
        const timer = setTimeout(() => navigateToOrder(orderId), 2_000);
        return () => clearTimeout(timer);
    });

    return (
        <Box
            flex={1}
            ph={SpacingValue["space-medium"]}
            pv={SpacingValue["space-medium"]}
            alignItems="center">
            <Box style={styles.container} flex={1} justifyContent="center">
                <Stack spacing={48} alignItems="center">
                    <CdnImage
                        isImageKitEnabled
                        id="minis/payment_success"
                        height={146}
                        width={188}
                    />

                    <Stack spacing={SpacingValue["space-medium"]}>
                        <Text category="h3" color="highest" style={styles.textAlignCenter}>
                            {ORDER_SUCCESS_COPY.YAAY_ORDER_PLACED}
                        </Text>
                        {isDeliveryETAAvailable && orderType === "PHYSICAL" ? (
                            <Text
                                category="b2"
                                weight="medium"
                                color="low"
                                style={styles.textAlignCenter}>
                                {`${storeName ?? "Store"} ${
                                    ORDER_SUCCESS_COPY.DELIVERY_IN_DAYS +
                                    selectedAddress?.deliveryEta
                                }`}
                            </Text>
                        ) : null}
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
};

export const ProceedSuccess = React.memo(
    withResetErrorBoundary(
        withPaymentMainContainer(ProceedSuccessComponent, {
            backgroundColor: "color-background-primary",
        }),
    ),
);

const styles = StyleSheet.create({
    container: {
        maxWidth: 220,
    },
    textAlignCenter: {
        textAlign: "center",
    },
});
