import React from "react";
import { Platform, Pressable, StyleProp, StyleSheet, ViewStyle } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { InView, ThreeDotsLoader } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { format } from "@swiggy-private/common-helpers";

import { Analytics } from "@minis-consumer/analytics";
import { useShare } from "@minis-consumer/hooks/use-share";
import { useFollowUnfollowStore } from "@minis-consumer/hooks/use-store-follow";
import { IconContainer } from "@minis-consumer/routes/shop/components/icon-container";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

type ShopActionsParams = {
    isStoreEnabled: boolean;
    style?: StyleProp<ViewStyle>;
};

const DEFAULT_ICON_SIZE = 18;

export const ShopActions: React.FC<ShopActionsParams> = React.memo(({ isStoreEnabled, style }) => {
    const { value: theme } = useTheme();

    const {
        following: followingStore,
        trigger: followTrigger,
        loading: followLoading,
        canFollow,
    } = useFollowUnfollowStore();

    const store = useStoreInfo();

    const getAnalyticsData = React.useCallback(
        (category: string) => {
            return {
                category,
                context: `isFollowed: ${followingStore}`,
            };
        },
        [followingStore],
    );

    const onFollowHandle = React.useCallback(() => {
        Analytics.clickEvent(getAnalyticsData(SF_ANALYTICS_EVENT_NAMES.LOVE_STORE_CTA));

        followTrigger();
    }, [followTrigger, getAnalyticsData]);

    const share = useShare();

    const handleSharePress = React.useCallback((): void => {
        Analytics.clickEvent(getAnalyticsData(SF_ANALYTICS_EVENT_NAMES.SHARE_STORE_CTA));

        if (Platform.OS === "web") {
            return share();
        }
        share({ description: format("Explore {0}'s offerings here:", store.name) });
    }, [getAnalyticsData, share, store.name]);

    const followCtaContainerStyle = {
        ...styles.actionCtaContainer,
        backgroundColor: followingStore ? theme["color-primary-25"] : theme["color-basic-5"],
    };

    const shareCtaContainerStyle = {
        ...styles.actionCtaContainer,
        backgroundColor: theme["color-primary-25"],
    };

    const onViewImpression = React.useCallback(
        (isVisible: boolean) => {
            if (!isVisible || !isStoreEnabled) {
                return;
            }

            Analytics.impressionEvent(getAnalyticsData(SF_ANALYTICS_EVENT_NAMES.SHARE_STORE_CTA));

            !followLoading &&
                canFollow &&
                Analytics.impressionEvent(
                    getAnalyticsData(SF_ANALYTICS_EVENT_NAMES.LOVE_STORE_CTA),
                );
        },
        [canFollow, isStoreEnabled, followLoading, getAnalyticsData],
    );

    if (isStoreEnabled) {
        return (
            <InView onChange={onViewImpression}>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    style={style}
                    spacing={SpacingValue["space-xx-small"]}>
                    {canFollow ? (
                        <Pressable disabled={followLoading} style={styles.actionBtn}>
                            {followLoading ? (
                                <ThreeDotsLoader color="color-primary" />
                            ) : (
                                <IconContainer
                                    accessibilityLabel="HeartFilled"
                                    onPress={onFollowHandle}
                                    style={followCtaContainerStyle}
                                    testID={
                                        followingStore
                                            ? TEST_IDS_SF.FOLLOWED
                                            : TEST_IDS_SF.FOLLOW_CTA
                                    }>
                                    <SvgIcon
                                        icon={followingStore ? "HeartFilled" : "Heart"}
                                        accessible={false}
                                        height={DEFAULT_ICON_SIZE}
                                        width={DEFAULT_ICON_SIZE}
                                        color={followingStore ? "color-primary" : "color-basic-45"}
                                    />
                                </IconContainer>
                            )}
                        </Pressable>
                    ) : null}

                    <Pressable style={styles.actionBtn}>
                        <IconContainer
                            accessibilityLabel="Share"
                            onPress={handleSharePress}
                            style={shareCtaContainerStyle}
                            testID={TEST_IDS_SF.SHARE_CTA}>
                            <Box>
                                <SvgIcon
                                    icon="Share"
                                    color="color-primary"
                                    width={DEFAULT_ICON_SIZE}
                                    height={DEFAULT_ICON_SIZE}
                                />
                            </Box>
                        </IconContainer>
                    </Pressable>
                </Stack>
            </InView>
        );
    }

    return null;
});

if (__DEV__) {
    ShopActions.displayName = "ShopActions";
}

const styles = StyleSheet.create({
    actionBtn: {
        borderColor: "none",
    },
    actionCtaContainer: {
        borderRadius: 40,
        height: 40,
        width: 40,
    },
});
