import * as React from "react";
import { ImageStyle, Pressable, StyleSheet, ViewStyle } from "react-native";
import LinearGradient from "react-native-linear-gradient";

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useMount } from "@swiggy-private/react-hooks";
import { StoreMedia } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";

import { Analytics, Event } from "@minis-consumer/analytics";
import { ProductVariantValuesLabel } from "@minis-consumer/components/variants";
import { getStoreBrandImageUrl } from "@minis-consumer/helpers/store";
import { MinisRouteList } from "@minis-consumer/interfaces/route";

import { ANALYTICS_COPIES } from "../../../../constants";
import { DEFAULTS } from "../../constants";
import { ProductPrice } from "../product-price";

const variantsTextProps: TextProps = { weight: "regular" };

export interface AbandonedCartProduct {
    id: string;
    name: string;
    quantity: number;
    price: number;
    slug: string;
    cartId: string;

    isAvailable?: boolean;
    discountedPrice?: number;
    discountPercent?: number;
    // old ways to store image-id
    imgId?: string;
    // new ways to store media-id
    mediaId?: string;
    isCustom?: boolean;
    variants?: string[];
}

const ProductInfo: React.FC<AbandonedCartProduct> = ({
    id,
    name,
    quantity,
    price,
    isAvailable,
    discountedPrice,
    discountPercent,
    imgId,
    mediaId,
    slug,
    isCustom,
    cartId,
    variants,
}) => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NativeStackNavigationProp<MinisRouteList>>();

    const quantityStyles: ViewStyle = {
        backgroundColor: theme["color-basic-5"],
        borderColor: theme["color-basic-0"],
    };

    const finalPriceTextProps = {
        color: isAvailable ? "color-basic-75" : "color-basic-45",
    } as TextProps;

    const discountPercentTextProps = {
        color: isAvailable ? "color-warning-700" : "color-basic-45",
    } as TextProps;

    const productImgExtraStyles: ImageStyle = {
        borderColor: theme["color-basic-5"],
    };

    const bgBoxStyle = {
        backgroundColor: theme["color-basic-5"],
    };

    const mediaAsset = imgId || mediaId;

    const url = React.useMemo(
        () =>
            mediaAsset
                ? getStoreBrandImageUrl(
                      mediaAsset,
                      {
                          height: DEFAULTS.PRODUCT_IMAGE_SIZE,
                          width: DEFAULTS.PRODUCT_IMAGE_SIZE,
                          crop: "thumb",
                      },
                      true,
                  )
                : undefined,
        [mediaAsset],
    );

    const productAnalyticsPayload = React.useMemo(() => {
        return {
            category: ANALYTICS_COPIES.PRODUCT_DETAILS_CATEGORY,
            label: cartId,
            context: id,
        };
    }, [cartId, id]) as Event;

    const productName = React.useMemo(() => name.trim(), [name]);

    const goToProductPage = React.useCallback(() => {
        Analytics.clickEvent(productAnalyticsPayload);
        navigation.navigate("Product", {
            slug,
            id,
            storeImage: mediaAsset,
        });
    }, [productAnalyticsPayload, id, navigation, slug, mediaAsset]);

    const transformedQuantityValue = React.useMemo(
        () => (quantity > 99 ? "99+" : quantity),
        [quantity],
    );

    const hitSlop = {
        top: 8,
        bottom: 8,
    };

    const productStyle = {
        width: DEFAULTS.PRODUCT_IMAGE_SIZE,
        height: DEFAULTS.PRODUCT_IMAGE_SIZE,
    };

    useMount(() => {
        Analytics.impressionEvent(productAnalyticsPayload);
    });

    return (
        <Pressable
            disabled={isCustom}
            hitSlop={hitSlop}
            onPress={goToProductPage}
            style={styles.pressable}>
            <Stack direction="row">
                <Stack style={styles.imageWrapper}>
                    <Box style={[styles.img, productImgExtraStyles]}>
                        <StoreMedia
                            isImageKitEnabled
                            testID="product-image"
                            mediaId={url ?? DEFAULTS.PRODUCT_FALLBACK_IMG}
                            resizeMode="contain"
                            disableVideoPlay
                            onPress={goToProductPage}
                            {...productStyle}
                            style={productStyle}
                        />
                    </Box>
                    {!isAvailable ? (
                        <Box style={[styles.bgBox, productImgExtraStyles, bgBoxStyle]} />
                    ) : null}

                    <LinearGradient
                        colors={DEFAULTS.QUANTITY_GRADIENT_COLORS}
                        start={DEFAULTS.QUANTITY_GRADIENT_COLORS_START_POINT}
                        locations={DEFAULTS.QUANTITY_GRADIENT_COLORS_LOCATIONS}
                        style={[quantityStyles, styles.quantityWrapper]}>
                        <Text weight="medium" color="color-basic-60" style={styles.quantityText}>
                            {transformedQuantityValue}
                        </Text>
                    </LinearGradient>
                </Stack>

                <Stack
                    flex={1}
                    justifyContent="center"
                    style={styles.brandInfoWrapper}
                    spacing={SpacingValue["space-x-small"]}>
                    <Stack spacing={SpacingValue["space-x-small"]}>
                        <Text
                            category="b2"
                            weight="medium"
                            numberOfLines={!isCustom ? 2 : undefined}
                            color={isAvailable ? "color-basic-75" : "color-basic-45"}>
                            {productName}
                        </Text>
                        <ProductVariantValuesLabel
                            values={variants}
                            textProps={variantsTextProps}
                        />
                    </Stack>

                    <ProductPrice
                        isAvailable={isAvailable}
                        price={price}
                        discountedPrice={discountedPrice}
                        discountPercent={discountPercent}
                        finalPriceTextProps={finalPriceTextProps}
                        discountPercentTextProps={discountPercentTextProps}
                    />
                </Stack>
            </Stack>
        </Pressable>
    );
};

const StoreProductComponent: React.FC<AbandonedCartProduct> = (props) => {
    return (
        <Stack direction="row" alignItems="center" style={styles.wrapper}>
            {!props.isAvailable ? (
                <LinearGradient
                    testID="gradient"
                    colors={DEFAULTS.GRADIENT_COLORS}
                    start={DEFAULTS.GRADIENT_START_POINT}
                    end={DEFAULTS.GRADIENT_END_POINT}
                    locations={DEFAULTS.GRADIENT_LOCATIONS}
                    style={styles.root}>
                    <ProductInfo {...props} />
                </LinearGradient>
            ) : (
                <ProductInfo {...props} />
            )}
        </Stack>
    );
};

const styles = StyleSheet.create({
    imageWrapper: {
        paddingLeft: SpacingValue["space-medium"],
    },
    quantityWrapper: {
        position: "absolute",
        right: -10,
        top: -10,
        minHeight: DEFAULTS.QUANTITY_WRAPPER_SIZE,
        minWidth: DEFAULTS.QUANTITY_WRAPPER_SIZE,
        borderRadius: DEFAULTS.QUANTITY_WRAPPER_SIZE / 2,
        borderWidth: 2,
        justifyContent: "center",
        alignItems: "center",
    },
    wrapper: {
        marginVertical: SpacingValue["space-x-small"],
    },
    brandInfoWrapper: {
        marginLeft: SpacingValue["space-medium"],
        paddingRight: SpacingValue["space-medium"],
    },
    root: {
        flexDirection: "row",
        alignItems: "center",
        overflow: "visible",
    },
    img: {
        borderRadius: 8,
        borderWidth: 1,
        overflow: "hidden",
    },
    bgBox: {
        height: DEFAULTS.PRODUCT_IMAGE_SIZE,
        width: DEFAULTS.PRODUCT_IMAGE_SIZE,
        position: "absolute",
        opacity: 0.5,
        borderRadius: 8,
    },
    quantityText: {
        lineHeight: 14,
        fontSize: 11,
    },
    pressable: {
        flex: 1,
    },
});

export const StoreProduct = React.memo(StoreProductComponent);
