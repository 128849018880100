import { PaymentAction } from "./action-types";
import { PaymentState } from "./types";

export function paymentReducer(
    state: Readonly<PaymentState>,
    action: Readonly<PaymentAction>,
): Readonly<PaymentState> {
    switch (action.type) {
        case "UPDATE_PAYMENT_AMOUNT":
            return {
                ...state,
                paymentAmount: action.payload,
            };

        case "UPDATE_PAYMENT_METHODS":
            return {
                ...state,
                paymentMethods: action.payload,
            };

        case "UPDATE_PAYMENT_ORDER":
            return {
                ...state,
                order: action.payload.order,
            };

        case "UPDATE_PAYMENT_PROGRESS":
            return {
                ...state,
                paymentInProgress: action.payload,
            };

        case "UPDATE_PAYMENT_SERVICE_STATUS":
            return {
                ...state,
                payments: action.payload,
            };

        case "UPDATE_SELECTED_PAYMENT_METHOD":
            return {
                ...state,
                selectedPaymentMethod: action.payload,
            };
    }
}
