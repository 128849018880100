import React from "react";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartView } from "@minis-consumer/hooks/use-cart";

const HEADER_TITLE = "All coupons";

const HeaderTitleComponent: React.FC = () => {
    const store = useStoreInfo();
    const cartView = useCartView(store.storeId);

    const itemCount =
        cartView?.cartViewData?.cartItems.reduce((sum, item) => sum + item.quantity, 0) || 0;
    const totalAmount = cartView?.cartViewData?.bill?.discountedItemTotal ?? 0;

    const billAmount = React.useMemo(
        () => formatNumberWithIndianSystem(totalAmount, { showRupeeSymbol: true }),
        [totalAmount],
    );

    const headerTitleCategory = useSelectScreen({
        lg: "h4" as const,
        default: "b1" as const,
    });

    return (
        <Box flex={1} direction="row" justifyContent="flex-start">
            <Box justifyContent="center">
                <Text category={headerTitleCategory} weight="bold" color="highest">
                    {HEADER_TITLE}
                </Text>

                {itemCount ? (
                    <Box direction="row" mt={SpacingValue["space-xxx-small"]}>
                        <Text category="b3" weight="regular" color="medium">
                            {`${itemCount} item${itemCount > 1 ? "s" : ""}  |  ${billAmount}`}
                        </Text>
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};

export const HeaderTitle = React.memo(HeaderTitleComponent);
