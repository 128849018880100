import React from "react";

import { CardPaymentMethod } from "@minis-consumer/interfaces/payment";
import { CARD_LOGO_MAP } from "@minis-consumer/routes/payment/constants";

import { SavedMethodWrapper } from "../method-wrapper";

interface Props {
    onPress: (T: "CARD" | "UPI", P?: string) => void;
    hasError?: boolean;
    errorMessage?: string;
}

const SavedCardComponent: React.FC<CardPaymentMethod & Props> = ({
    name,
    onPress,
    hasError,
    cardId,
    cardScheme,
    errorMessage,
}) => {
    const lastFourDigits = React.useMemo(() => name.slice(-4), [name]);
    const maskedCardNumber = React.useMemo(() => `· · · ·   ${lastFourDigits}`, [lastFourDigits]);

    return (
        <SavedMethodWrapper
            type="CARD"
            title={maskedCardNumber}
            onPressCard={onPress}
            logoUrl={CARD_LOGO_MAP[cardScheme]}
            error={hasError}
            paymentId={cardId}
            errorMessage={errorMessage}
        />
    );
};

export const SavedCard = React.memo(SavedCardComponent);
