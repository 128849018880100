import * as React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FocusAwareStatusBar } from "@swiggy-private/react-native-ui";
import { useTheme } from "@swiggy-private/rn-dls";

import { AuthModal } from "@minis-consumer/components/auth-modal";
import { ResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { MinisNavigatorId } from "@minis-consumer/constants/navigation";
import { StoreRouteList, MinisRouteList } from "@minis-consumer/interfaces/route";

type AuthScreenProps = NativeStackScreenProps<MinisRouteList & StoreRouteList, "Auth">;

export const AuthScreen: React.FC<AuthScreenProps> = ({ navigation, route }) => {
    const { value: theme } = useTheme();
    const { context } = route.params;

    return (
        <ResetErrorBoundary>
            <AuthModal
                fullscreen
                onClose={() => {
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                        return;
                    }

                    const homeRouteName =
                        navigation.getId() === MinisNavigatorId ? "MinisHome" : "Home";

                    navigation.reset({
                        index: 0,
                        routes: [{ name: homeRouteName, params: { screen: context } }],
                    });
                }}
            />
            <FocusAwareStatusBar
                translucent={true}
                backgroundColor={theme["color-primary-light"]}
                barStyle="dark-content"
            />
        </ResetErrorBoundary>
    );
};
