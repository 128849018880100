import React from "react";
import { StyleProp, ViewStyle, StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Snackbar, SpacingValue } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

import { getFeedbackToastMsg } from "@minis-consumer/components/generic-feedback-card/helpers";
import { Analytics } from "@minis-consumer/analytics";

import { ANALYTICS_COPIES } from "../../constants";

interface FeedbackSnackbarProps {
    canShowSnackbar: boolean;
    onControlSnackbar: (b: boolean) => void;

    storeName?: string;
    style?: StyleProp<ViewStyle>;
}

const SNACKBAR_TIMEOUT = 3_000;

const FeedbackSnackbarComponent: React.FC<FeedbackSnackbarProps> = ({
    canShowSnackbar,
    storeName,
    style,
    onControlSnackbar,
}) => {
    const insets = useSafeAreaInsets();

    const snackbarMessage = React.useMemo(() => getFeedbackToastMsg(storeName), [storeName]);

    const snackbarStyle = {
        bottom: Math.max(insets.bottom, SpacingValue["space-x-large"]),
    };

    useMount(() => {
        if (!canShowSnackbar) {
            return;
        }

        Analytics.impressionEvent({
            category: ANALYTICS_COPIES.CARTS_CLEARED,
            context: ANALYTICS_COPIES.ALL_CARTS,
        });
    });

    React.useEffect(() => {
        let t: NodeJS.Timer;

        if (canShowSnackbar) {
            t = setTimeout(() => onControlSnackbar(false), SNACKBAR_TIMEOUT);
        }

        return () => clearTimeout(t as unknown as number);
    }, [canShowSnackbar, onControlSnackbar]);

    if (!canShowSnackbar) {
        return null;
    }

    return (
        <Snackbar
            style={[styles.snackbar, snackbarStyle, style]}
            childrenStyle={styles.snackbarText}
            isActive={canShowSnackbar}>
            {snackbarMessage}
        </Snackbar>
    );
};

const styles = StyleSheet.create({
    snackbar: {
        zIndex: 1,
        elevation: 1,
        borderRadius: 12,
        marginHorizontal: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-x-small"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    snackbarText: {
        textAlign: "center",
    },
});

export const FeedbackSnackbar = React.memo(FeedbackSnackbarComponent);
