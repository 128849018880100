import * as React from "react";
import { StyleSheet } from "react-native";

import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { Shimmer } from "@minis-consumer/components/shimmer";

const InstagramWidgetLoaderComponent: React.FC = () => {
    const { value: theme } = useTheme();

    const margin = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    return (
        <Stack
            spacing={SpacingValue["space-medium"]}
            style={[
                styles.container,
                {
                    marginHorizontal: margin,
                    marginBottom: margin,
                    borderColor: theme["color-basic-15"],
                },
            ]}>
            <Shimmer height={16} width="40%" style={styles.br8} />
            <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                <Shimmer height={80} width={80} style={styles.br8} />
                <Shimmer height={80} width={80} style={styles.br8} />
                <Shimmer height={80} width={80} style={styles.br8} />
            </Stack>
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: SpacingValue["space-medium"],
        borderWidth: 1,
        borderRadius: 20,
    },
    br8: {
        borderRadius: 8,
    },
});

export const InstagramWidgetLoader = React.memo(InstagramWidgetLoaderComponent);
