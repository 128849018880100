import { OrderBill, OrderItem } from "../../../../interfaces/order";

type OrderItemsCategoryListProps = {
    itemsBill: OrderBill["itemsBill"];
    items: OrderItem[];
};

type OrderItemsCategoryListResponse = {
    normalItems: OrderItem[];
    customItems: OrderItem[];
};

/**
 *
 * The Order details API gives data related to items in different objects. One objects provide bill details while other provides meta details. This helper is needed to merge and extract bill data segregated in two different categories
 *
 * @param itemsBill - object array having item bill details
 * @param items - object array having item meta details
 *
 * @returns custom and normal items having value as {@link OrderItemsCategoryListResponse}
 */
export const getOrderItemsCategoryList = ({
    itemsBill,
    items,
}: OrderItemsCategoryListProps): OrderItemsCategoryListResponse => {
    const hashTable: { [key: string]: OrderItem } = {};

    items.forEach((itemDetail) => {
        // item now is a combination of item id and variant id.
        const _id = `${itemDetail.item.id}_${itemDetail.item.variantId}`;
        hashTable[_id] = itemDetail;
    });

    itemsBill.forEach((item) => {
        // item now is a combination of item id and variant id.
        const _id = `${item.id}_${item.variantId}`;
        hashTable[_id].item.price = item.totalPrice;
        hashTable[_id].item.discountedPrice = item.totalDiscountedPrice;
        hashTable[_id].quantity = item.quantity;
    });

    const data = Object.values(hashTable);

    const normalItems: OrderItem[] = [];

    const customItems = data.filter((item) => {
        if (item.metadata?.custom) {
            return true;
        }

        normalItems.push(item);

        return false;
    });

    return { normalItems, customItems };
};
