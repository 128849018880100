import React, { useMemo } from "react";

import { ImageProps, Platform, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SpacingValue, Text, Button } from "@swiggy-private/rn-dls";
import { Stack, Box, Display, ScreenSize, useScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon, Icon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";

import { MinisRouteList } from "@minis-consumer/interfaces/route";
import { Divider } from "@minis-consumer/components/divider";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Analytics } from "@minis-consumer/analytics";
import { Product, Variant } from "@minis-consumer/interfaces/catalog";
import { useEnquireActionHandler } from "@minis-consumer/components/product-cta/hooks/use-get-cta-actions";

interface ProductChatProps {
    helperTextTitle: string;
    helperTextSubtitle: string;
    ctaText: string;
    productId: string;
    productType: Product["productType"];
    selectedVariant?: Variant | null;
    isEnquireProduct?: boolean;
}

const CHAT_WITH_US_EVENT = "chat-with-us-pdp";

const ENQUIRY_FORM = [
    {
        id: "question-id-1",
        question: "Whatsapp Number",
        answerType: "TEXT",
        ansPlaceHolder: "Input your whatsapp number here",
        required: false,
    },
    {
        id: "question-id-2",
        question: "What is your enquiry?",
        answerType: "TEXT",
        ansPlaceHolder: "Input your enquiry here",
        required: true,
    },
];

export const ProductChat: React.FC<ProductChatProps> = ({
    helperTextTitle,
    helperTextSubtitle,
    ctaText,
    productId,
    productType,
    selectedVariant,
    isEnquireProduct,
}) => {
    const screenSize = useScreenSize();

    const navigation = useNavigation<NativeStackNavigationProp<MinisRouteList>>();
    const store = useStoreInfo();

    const enquireAction = useEnquireActionHandler({ storeId: store.storeId, productId });

    const dividerStyle = {
        marginTop: SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-large"],
    };

    const renderIcon = (icon: Icon, style?: ImageProps["style"]): JSX.Element => {
        const _style = StyleSheet.flatten(style);

        return (
            <SvgIcon
                icon={icon}
                width={_style.width as number}
                height={_style.height as number}
                color={_style.tintColor?.toString()}
                style={{
                    marginRight: _style.marginRight,
                }}
                accessible={false}
            />
        );
    };

    const analyticsData = useMemo(() => {
        return {
            category: CHAT_WITH_US_EVENT,
            label: productId,
            context: JSON.stringify({
                productId,
                productType,
                selectedVariant: selectedVariant?.id,
            }),
        };
    }, [productId, productType, selectedVariant]);

    const onChatPress = React.useCallback(() => {
        Analytics.clickEvent(analyticsData);

        if (!isEnquireProduct) {
            navigation?.navigate("ProductForms", {
                id: productId,
                slug: store.slug,
                storeId: store.storeId,
                isEnquireMode: true,
                variantId: selectedVariant?.id,
                formData: JSON.stringify([ENQUIRY_FORM]),
            });
            return;
        }

        enquireAction();
    }, [
        analyticsData,
        enquireAction,
        isEnquireProduct,
        navigation,
        productId,
        selectedVariant?.id,
        store.slug,
        store.storeId,
    ]);

    useMount(() => {
        if (Platform.OS === "web" && screenSize === ScreenSize.Large) {
            return;
        }
    });

    return (
        <Display lt={ScreenSize.Medium}>
            <Box flex={1}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={{
                        default: SpacingValue["space-medium"],
                    }}>
                    <Box flex={1}>
                        <Text category="b2" weight="bold">
                            {helperTextTitle}
                        </Text>
                        <Text category="b3">
                            {helperTextSubtitle} {store.name}
                        </Text>
                    </Box>
                    <Button
                        onPress={onChatPress}
                        elevation={2}
                        color="tertiary"
                        style={styles.actionBtn}
                        accessoryLeft={(props) => renderIcon("Chat", props.style)}>
                        {ctaText}
                    </Button>
                </Stack>
                <Divider style={dividerStyle} />
            </Box>
        </Display>
    );
};

const styles = StyleSheet.create({
    actionBtn: {
        minHeight: 40,
        maxWidth: 128,
        borderRadius: 8,
        flex: 1,
    },
});
