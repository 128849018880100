import React from "react";
import { LayoutChangeEvent, Platform, Pressable, StyleSheet, View, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { useMount } from "@swiggy-private/react-hooks";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Surface, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { PaymentDowntimeNudge } from "@minis-consumer/components/payment-downtime-nudge";
import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { Cart } from "@minis-consumer/interfaces/cart";
import { PRODUCT_TYPE } from "@minis-consumer/interfaces/catalog";

import { CART_V2_ERRORS_STATUS } from "../item-list/constants";
import { DISPLAY_STRINGS, EVENT_NAMES } from "./constants";

interface BottomSheetComponentProps {
    ctaText: string;
    storeId: string;
    showDetailedBill: () => void;
    onPressCta: () => void;
    cartStatus?: Cart["cartStatus"];
    cartType?: PRODUCT_TYPE;
    onLayout?: (e: LayoutChangeEvent) => void;
    show?: boolean;
    cartId?: string;
    isPaymentDown?: boolean;
    paymentDowntimeMessage?: string;
}

const BottomSheetComponent: React.FC<BottomSheetComponentProps> = ({
    ctaText,
    storeId,
    showDetailedBill,
    onPressCta,
    cartStatus,
    cartType,
    onLayout,
    show,
    cartId,
    isPaymentDown = false,
    paymentDowntimeMessage,
}) => {
    const insets = useSafeAreaInsets();
    const cart = useCartViewData(storeId);
    const { value: theme } = useTheme();

    const containerStyle = useSelectScreen({
        default: {
            paddingTop: SpacingValue["space-x-small"],
            paddingBottom: Math.max(
                insets.bottom + SpacingValue["space-xx-small"],
                Platform.OS === "android"
                    ? SpacingValue["space-xx-large"]
                    : SpacingValue["space-medium"],
            ),
            paddingHorizontal: SpacingValue["space-medium"],
        },
        md: {
            paddingTop: SpacingValue["space-x-large"],
            paddingBottom: Math.max(
                insets.bottom + SpacingValue["space-xx-small"],
                SpacingValue["space-x-large"],
            ),
            paddingHorizontal: SpacingValue["space-x-large"],
        },
    });

    const surfaceStyle: ViewStyle = {
        elevation: Platform.select({
            android: 14,
            ios: 4,
            web: 22,
        }),
        shadowOpacity: Platform.select({
            web: 1,
            ios: 0.2,
        }),
    };

    const billTotal = React.useMemo(() => cart?.bill?.billTotal ?? 0, [cart]);

    const getFormattedNumber = React.useCallback(
        (num: number) => formatNumberWithIndianSystem(num, { showRupeeSymbol: true }),
        [],
    );

    const formattedBillTotal = React.useMemo(
        () => getFormattedNumber(billTotal),
        [billTotal, getFormattedNumber],
    );

    const btnDisabled = React.useMemo(
        () => isPaymentDown || (cartStatus && CART_V2_ERRORS_STATUS.indexOf(cartStatus) > -1),
        [isPaymentDown, cartStatus],
    );

    useMount(() => {
        if (show) {
            Analytics.impressionEvent({
                category: EVENT_NAMES.PROCEED,
                label: cartId,
                context: JSON.stringify({
                    cartId,
                    cartType,
                    billAmount: billTotal,
                }),
            });
        }
    });

    const divider = <DashBorderLine style={styles.divider} borderColor={theme["color-basic-15"]} />;

    return (
        <Surface style={[styles.surface, surfaceStyle]}>
            <View
                pointerEvents="box-none"
                collapsable={false}
                onLayout={onLayout}
                style={[styles.dialog, { backgroundColor: theme["color-background-primary"] }]}>
                <PaymentDowntimeNudge
                    style={styles.downtime}
                    showNudge={isPaymentDown}
                    message={paymentDowntimeMessage}
                />
                <Stack
                    divider={divider}
                    pointerEvents="box-none"
                    style={containerStyle}
                    onLayout={onLayout}
                    collapsable={false}>
                    <Box
                        pointerEvents="box-none"
                        mt={SpacingValue["space-x-small"]}
                        direction="row"
                        justifyContent="space-between">
                        <Box justifyContent="center" flex={2}>
                            <Text category="h5" weight="bold" color="high" style={styles.billTotal}>
                                {formattedBillTotal}
                            </Text>
                            <Pressable onPress={showDetailedBill} hitSlop={{ top: 12, bottom: 12 }}>
                                <Text
                                    category="btn5"
                                    weight="bold"
                                    color="color-primary-400"
                                    suppressHighlighting>
                                    {DISPLAY_STRINGS.VIEW_DETAILED_BILL}
                                </Text>
                            </Pressable>
                        </Box>
                        <Box flex={3}>
                            <Button onPress={onPressCta} disabled={btnDisabled}>
                                {ctaText}
                            </Button>
                        </Box>
                    </Box>
                </Stack>
            </View>
        </Surface>
    );
};

export const CartProceedBottomSheet = React.memo(BottomSheetComponent);

const styles = StyleSheet.create({
    surface: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
    },
    dialog: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    downtime: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    divider: {
        marginTop: SpacingValue["space-medium"],
    },
    billTotal: {
        marginBottom: SpacingValue["space-xx-small"],
    },
});
