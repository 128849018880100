import * as React from "react";
import {
    Pressable,
    PressableProps,
    StyleProp,
    StyleSheet,
    ViewStyle,
    ScrollView,
} from "react-native";
import { RouteProp, useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { useController, useSuspense } from "@rest-hooks/react";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Box, Stack, Container, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import {
    FocusAwareStatusBar,
    IOScrollView,
    useIsomorphicEffect,
} from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";
import { useStarSeller } from "@swiggy-private/connect-business-commons";

import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { CommonRouteList } from "@minis-consumer/interfaces/route";
import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";
import { OrderDetail, OrderEntity } from "@minis-consumer/resources/order";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { Order } from "@minis-consumer/interfaces/order";
import { PaymentFailedDialog } from "@minis-consumer/components/payment-failed-dialog";
import { ResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { Analytics } from "@minis-consumer/analytics";
import { Logger } from "@minis-consumer/includes/logger";
import { useShare } from "@minis-consumer/hooks/use-share";
import { FloatingChat } from "@minis-consumer/components/floating-chat";
import { useGetConfigInfo } from "@minis-consumer/hooks/use-get-config-info";

import { AsyncStorageService } from "@swiggy-private/rn-services";

import OrderAddressCard from "./components/order-address";
import OrderTrackingGuestCheckout from "./components/order-tracking-guest-checkout";
import BillDetails from "./components/bill-details";
import OrderDetailsLoader from "./components/loader";
import HeaderLeftComponent from "./components/header-left";
import { RefundCard } from "./components/refund";
import OrderTrackingGist from "./components/order-tracking-gist";
import { UserDetails } from "./components/user-details";
import { InitiateReturn } from "./components/initiate-return";
import { ReturnDetails } from "./components/return-details";
import { ARD_EVENTS_MAPPING } from "./constants";
import { RETURN_SUBMITTED_STORAGE_KEY } from "../order-track/constants";
import { OrderDetails } from "./components/order-details";
import { SellerMsgNudge } from "./components/seller-msg-nudge";

const ICON_SIZE = 40;

export type IconContainerProps = Omit<PressableProps, "style"> & {
    style?: StyleProp<ViewStyle>;
};

export const IconContainer: React.FC<React.PropsWithChildren<IconContainerProps>> = (props) => {
    const {
        accessible = true,
        accessibilityRole = "button",
        style,
        children,
        ...restProps
    } = props;

    return (
        <Pressable accessible={accessible} accessibilityRole={accessibilityRole} {...restProps}>
            <Box style={[styles.iconContainer, style]}>{children}</Box>
        </Pressable>
    );
};

type OrderComponentProps = {
    order: OrderEntity;
    orderId: string;
    paymentFailed: boolean;
    scrollToOffset: (pageY: number) => void;

    orderKey?: string;
};

interface GuestCheckoutHeaderRightProps {
    order: Order;
}

const GuestCheckoutHeaderRight: React.FC<GuestCheckoutHeaderRightProps> = ({ order }) => {
    const { value: theme } = useTheme();
    const share = useShare();

    const orderData = React.useMemo(
        () => ({ orderId: order.id, orderStatus: order.status }),
        [order],
    );

    useMount(() => {
        Analytics.impressionEvent({
            category: ARD_EVENTS_MAPPING.SHARE_BTN,
            context: JSON.stringify(orderData),
        });
    });

    const onPressShare = React.useCallback(() => {
        Analytics.clickEvent({
            category: ARD_EVENTS_MAPPING.SHARE_BTN,
            context: JSON.stringify(orderData),
        });

        share({ order: { id: order.id, key: order.orderKey } });
    }, [order.id, order.orderKey, orderData, share]);

    return (
        <IconContainer
            accessibilityLabel="Search"
            onPress={onPressShare}
            style={{
                backgroundColor: theme["color-positive-25"],
                marginRight: SpacingValue["space-medium"],
            }}>
            <SvgIcon icon="Share" height={20} width={20} color="color-basic-60" />
        </IconContainer>
    );
};

const OrderComponent: React.FC<OrderComponentProps> = ({
    order,
    orderId,
    paymentFailed,
    orderKey,
    scrollToOffset,
}) => {
    const { invalidate } = useController();

    const sellerNudgeRef = React.useRef();

    const [showPaymentFailedDialog, setShowPaymentFailedDialog] = React.useState(false);
    const navigation = useNavigation<NativeStackNavigationProp<CommonRouteList, "Order">>();

    useOrderHeader({ order });

    const isStarSeller = useStarSeller(order?.storeDetails?.badges);

    const onRetryPaymentTimeout = React.useCallback(
        () => invalidate(OrderDetail, orderId).catch(Logger.recordError),
        [invalidate, orderId],
    );

    useMount(() => {
        const timer = setTimeout(() => setShowPaymentFailedDialog(paymentFailed), 100);

        return () => {
            clearTimeout(timer);
        };
    });

    const refreshOrder = React.useCallback(() => {
        invalidate(OrderDetail, orderId, orderKey).catch(Logger.recordError);
    }, [invalidate, orderId, orderKey]);

    const refreshOrderOnReturnSubmit = React.useCallback(() => {
        AsyncStorageService.getItem(RETURN_SUBMITTED_STORAGE_KEY, async (error, result) => {
            if (error) {
                return null;
            }
            if (result && result === String(true)) {
                refreshOrder();
                await AsyncStorageService.setItem(RETURN_SUBMITTED_STORAGE_KEY, String(false));
            }

            return null;
        });
    }, [refreshOrder]);

    useFocusEffect(refreshOrderOnReturnSubmit);

    const onPaymentFailedDismiss = React.useCallback(() => {
        setShowPaymentFailedDialog(false);
        navigation.setParams({
            id: orderId,
            paymentFailed: undefined,
        });
    }, [navigation, orderId]);

    const chatAnalyticsEvent = React.useCallback(() => {
        Analytics.clickEvent({
            category: "header-chat-btn",
            label: order.id,
            context: order.status,
        });
    }, [order.id, order.status]);

    const onSellerMsgNudgePress = (): void => {
        if (!sellerNudgeRef.current) {
            return;
        }
        scrollToOffset(sellerNudgeRef.current);
    };

    const showSellerNudge = order.items.some((item) => !!item.item.confirmationMessage);

    return (
        <Stack
            direction="row"
            flex={1}
            spacing={{
                lg: SpacingValue["space-medium"],
                default: 0,
            }}>
            <Stack
                flex={1}
                /* The md value was not rolling up for lg */
                spacing={{
                    lg: SpacingValue["space-x-large"],
                    md: SpacingValue["space-x-large"],
                    default: SpacingValue["space-medium"],
                }}>
                {showSellerNudge ? (
                    <SellerMsgNudge
                        onPress={onSellerMsgNudgePress}
                        storeLogo={order.storeDetails.settings?.brandImageId}
                        storeName={order.storeDetails.name}
                    />
                ) : null}
                {!order.guestOrder && order.orderType === "PHYSICAL" ? (
                    <OrderAddressCard
                        slug={order.storeDetails.slug}
                        storeName={order.storeDetails.name}
                        pickupAddress={order.pickupAddress}
                        dropAddress={order.dropAddress}
                        storeId={order.storeDetails.id}
                        storeBrandImageId={order.storeDetails.settings?.brandImageId}
                        chatAnalyticsEvent={chatAnalyticsEvent}
                        isStoreEnabled={order.storeDetails.enabled !== false}
                        isStarSeller={isStarSeller}
                    />
                ) : null}

                <ReturnDetails order={order} />

                <Stack style={{ marginTop: -SpacingValue["space-x-small"] }}>
                    {order.guestOrder ? (
                        <OrderTrackingGuestCheckout
                            order={order}
                            onRetryPaymentTimeout={onRetryPaymentTimeout}
                        />
                    ) : (
                        <OrderTrackingGist
                            order={order}
                            onRetryPaymentTimeout={onRetryPaymentTimeout}
                        />
                    )}
                </Stack>

                {order.refundDetails ? <RefundCard refundDetails={order.refundDetails} /> : null}
                <OrderDetails order={order} ref={sellerNudgeRef} />
                <BillDetails
                    items={order.items}
                    bill={order.bill}
                    paymentMethod={order.paymentMethod}
                    appliedCoupon={order.metadata?.couponDetails?.code}
                />

                <UserDetails order={order} />

                {order?.orderType === "PHYSICAL" ? (
                    <InitiateReturn order={order} refreshPage={refreshOrder} />
                ) : null}

                <FocusAwareStatusBar
                    translucent={true}
                    backgroundColor="transparent"
                    barStyle="dark-content"
                />
            </Stack>

            <PaymentFailedDialog
                show={showPaymentFailedDialog}
                order={order}
                onDismiss={onPaymentFailedDismiss}
            />
        </Stack>
    );
};

const OrderDetailWrapper: React.FC = () => {
    const route = useRoute<RouteProp<CommonRouteList, "Order">>();
    const orderId = route.params.id;
    const orderKey = route.params.orderKey;
    const paymentFailed = !!(route.params.paymentFailed ?? false);
    const order = useSuspense(OrderDetail, orderId, orderKey);

    const { value: theme } = useTheme();
    const { storeDetails } = order;

    const scrollRef = React.useRef<ScrollView>(null);
    const scrollStyle = { backgroundColor: theme["color-background-secondary"] };
    const isNewServicesEnabled = useGetConfigInfo()?.isNewServicesEnabled;

    /* The md value was not rolling up for lg */
    const containerStyle: ViewStyle = useSelectScreen({
        lg: {
            paddingVertical: SpacingValue["space-x-large"],
        },
        md: {
            paddingVertical: SpacingValue["space-large"],
        },
        default: {
            paddingVertical: SpacingValue["space-medium"],
        },
    });

    const scrollToOffset = React.useCallback(
        (pageY: number) => {
            scrollRef.current?.scrollTo({
                y: pageY,
                animated: true,
            });
        },
        [scrollRef],
    );
    return (
        <>
            <IOScrollView style={scrollStyle} ref={scrollRef}>
                <Container style={containerStyle} fluid={false}>
                    <OrderComponent
                        key={orderId}
                        orderId={orderId}
                        paymentFailed={paymentFailed}
                        orderKey={orderKey}
                        scrollToOffset={scrollToOffset}
                        order={order}
                    />
                </Container>
            </IOScrollView>
            {isNewServicesEnabled ? (
                <FloatingChat
                    storeId={storeDetails.id}
                    storeImage={storeDetails.settings?.backgroundImageId ?? ""}
                    disableSnackBarOffset
                    disableCartBarOffset
                />
            ) : null}
        </>
    );
};

export const OrderDetailScreen: React.FC = () => {
    const route = useRoute<RouteProp<CommonRouteList, "Order">>();
    const orderId = route.params.id;
    const orderKey = route.params.orderKey;

    const { invalidate } = useController();

    invalidate(OrderDetail, orderId, orderKey).catch(Logger.recordError);

    return (
        <StateContextContainer>
            <ResetErrorBoundary>
                <AsyncComponent fallback={<OrderDetailsLoader />}>
                    <OrderDetailWrapper />
                </AsyncComponent>
            </ResetErrorBoundary>
        </StateContextContainer>
    );
};

const useOrderHeader = ({ order }: { order: Order }): void => {
    const navigation = useNavigation<NativeStackNavigationProp<CommonRouteList, "Order">>();
    const { value: theme } = useTheme();

    const onHelpPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: "header-help-btn",
            label: order.id,
            context: order.status,
        });

        navigation.navigate("Account", {
            screen: "Help",
            params: { screen: "HelpDetailsLevelOne", params: { level1: "orders" } },
        });
    }, [navigation, order.id, order.status]);

    useIsomorphicEffect(() => {
        navigation.setOptions({
            title: "Order " + order.id,
            headerTitle: () => <></>,
            headerShadowVisible: true,
            headerLeft: () => (
                <HeaderLeftComponent
                    orderId={order.id}
                    orderDate={convertToReadableDateAndTime(order.createdAt)}
                    numberOfItems={order.items.length}
                    orderValue={order.bill.billTotal}
                    containerStyle={styles.container}
                    isGuestOrder={order?.guestOrder ?? false}
                />
            ),
            headerRight: () =>
                order.guestOrder ? (
                    <GuestCheckoutHeaderRight order={order} />
                ) : (
                    <SvgIcon
                        icon={"Question"}
                        height={20}
                        width={20}
                        color="color-basic-60"
                        onPress={onHelpPress}
                        style={styles.svg}
                    />
                ),
        });
    }, [
        order.bill.billTotal,
        order.createdAt,
        order.id,
        order.items.length,
        navigation,
        onHelpPress,
        order.guestOrder,
        theme,
        order,
    ]);
};

const styles = StyleSheet.create({
    svg: {
        marginRight: SpacingValue["space-medium"],
    },
    container: {
        marginLeft: SpacingValue["space-medium"],
    },

    iconContainer: {
        width: ICON_SIZE,
        height: ICON_SIZE,
        borderRadius: ICON_SIZE / 2,
        justifyContent: "center",
        alignItems: "center",
    },
});
