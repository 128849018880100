import { CartBill } from "@minis-consumer/interfaces/cart";
import { formatNumberWithIndianSystem } from "@minis-consumer//helpers/number";

export const getDeliveryFeeTooltipCharge = (
    deliveryFee: NonNullable<CartBill["deliveryFee"]>,
): Array<{ left: string; right: string }> => {
    const formatMoney = (rupees: number): string =>
        formatNumberWithIndianSystem(rupees, {
            showRupeeSymbol: true,
        });

    switch (deliveryFee.type) {
        case "FREE_DELIVERY_TYPE":
            return [
                {
                    left: "Free delivery",
                    right: formatMoney(deliveryFee.finalValue),
                },
            ];

        case "FLAT_RATE_DELIVERY_TYPE":
            return [
                {
                    left: "Fixed delivery fee",
                    right: formatMoney(deliveryFee.finalValue),
                },
            ];

        case "FREE_DELIVERY_ABOVE_BASE_AMOUNT_TYPE":
            return [
                {
                    left: `Delivery charges below ${formatMoney(deliveryFee.baseAmount)}`,
                    right: formatMoney(deliveryFee.baseCharges),
                },
                {
                    left: `Delivery charges above ${formatMoney(deliveryFee.baseAmount)}`,
                    right: formatMoney(0),
                },
            ];

        case "PER_KM_DELIVERY_TYPE":
            return deliveryFee.baseChargesApplicable
                ? [
                      {
                          left: `Base fare(first ${deliveryFee.baseKm}kms)`,
                          right: formatMoney(deliveryFee.baseCharges),
                      },
                      {
                          left: "Fee per km",
                          right: formatMoney(deliveryFee.perKmCharges),
                      },
                  ]
                : [
                      {
                          left: "Delivery charge per km",
                          right: formatMoney(deliveryFee.perKmCharges),
                      },
                  ];
    }
};
