import React from "react";
import { StyleSheet, useWindowDimensions } from "react-native";

import { Button, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";

type Props = {
    navigationReset: () => void;
};

/** @TODO - Place item at correct position once @Ankita256 's PR is merged */
const EmptyCartComponent: React.FC<Props> = (props) => {
    const { navigationReset } = props;
    const { width: screenWidth } = useWindowDimensions();

    const imgWidth = Math.min(250, screenWidth * 0.66);
    const imgHeight = imgWidth;

    const onShopNowPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: "explore-mini-btn",
        });
        // * Fix for Double-back
        navigationReset();
    }, [navigationReset]);

    useMount(() => {
        Analytics.impressionEvent({
            category: "explore-mini-btn",
        });
    });

    return (
        <Box flex={1} justifyContent="center" alignItems="center" style={styles.container}>
            <CdnImage
                isImageKitEnabled
                id="minis/consumer_empty_cart"
                height={imgHeight}
                width={imgWidth}
            />

            <Stack spacing={SpacingValue["space-xx-large"]} style={styles.content}>
                <Stack spacing={SpacingValue["space-x-small"]} alignItems="center">
                    <Text category="h4" color="high" style={styles.textStyles}>
                        It&apos;s lonely in here...
                    </Text>

                    <Text category="s3" color="medium" style={styles.textStyles}>
                        Why don&apos;t you check some products on our Mini?
                    </Text>
                </Stack>

                <Button onPress={onShopNowPress}>Explore Mini</Button>
            </Stack>
        </Box>
    );
};

export const EmptyCart = React.memo(EmptyCartComponent);

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
    content: {
        marginTop: SpacingValue["space-xx-large"],
    },
    textStyles: {
        textAlign: "center",
        paddingHorizontal: SpacingValue["space-medium"],
    },
});
