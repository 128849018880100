import * as React from "react";
import { StyleSheet, ScrollView, SafeAreaView, View, TextInput } from "react-native";
import { useLoading } from "@rest-hooks/hooks";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { Input, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { ActionButton } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { TERMS_AND_CONDITIONS_URL } from "@minis-consumer/constants";
import { AuthService } from "@minis-consumer/api/auth";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { Analytics } from "@minis-consumer/analytics";
import { isValidEmail } from "@minis-consumer/helpers/email";

import { AuthScreenContainer } from "../components/screen-container";
import { AuthHeader } from "../components/header";
import type { AuthRouteList } from "../types";

type Props = NativeStackScreenProps<AuthRouteList, "Signup">;

export const Signup: React.FC<Props> = ({ navigation, route }) => {
    const { phoneNumber } = route?.params;
    const { value: theme } = useTheme();
    const openUrl = useLinking();

    const emailInputRef = React.useRef<TextInput>(null);
    const nameInputRef = React.useRef<TextInput>(null);

    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [nameError, setNameError] = React.useState("");
    const [emailError, setEmailError] = React.useState("");

    const [clickHandler, loading, error] = useLoading(async () => {
        const response = await AuthService.signup({
            mobile: phoneNumber,
            email,
            name,
        });

        Analytics.clickEvent({
            category: "signup-continue-btn",
        });

        if (response) {
            navigation.navigate("VerifyOtp", {
                phoneNumber,
                ardEventCategory: "signup-verifyotp-btn",
            });
        }
    }, [phoneNumber, email, name, navigation]);

    const isDisabledSignupButton = React.useMemo(
        () => !email?.length || !name?.trim()?.length || loading,
        [email?.length, loading, name],
    );

    const validateInputs = React.useCallback(() => {
        if (!isValidEmail(email)) {
            setEmailError("This email is invalid");
            return false;
        } else {
            return true;
        }
    }, [email]);

    const onPressCTA = React.useCallback(() => {
        if (validateInputs()) {
            clickHandler();
        }
    }, [clickHandler, validateInputs]);

    const clearEmailError = React.useCallback(
        () => emailError?.length && setEmailError(""),
        [emailError],
    );

    const clearNameError = React.useCallback(
        () => nameError?.length && setNameError(""),
        [nameError],
    );

    const onChangeTextEmail = React.useCallback(
        (newEmail: string) => setEmail(newEmail.replace(/\s/g, "")),
        [],
    );

    const onChangeTextName = React.useCallback((newName: string) => setName(newName), []);

    const onSubmitEditingEmail = React.useCallback(
        (): void => nameInputRef?.current?.focus(),
        [nameInputRef],
    );

    const onTnCPress = React.useCallback(() => {
        openUrl(TERMS_AND_CONDITIONS_URL);
    }, [openUrl]);

    const accessoryRightEmailError = React.useCallback(
        () => <>{emailError ? <TextInputAccessoryRightError /> : null}</>,
        [emailError],
    );

    const accessoryRightNameError = React.useCallback(
        () => <>{nameError ? <TextInputAccessoryRightError /> : null}</>,
        [nameError],
    );

    const ph = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    return (
        <AuthScreenContainer style={styles.container}>
            <AuthHeader
                title="Create Account"
                description="Sign up to continue on Minis by Swiggy"
                onBack={navigation.goBack}
            />
            <ScrollView>
                <Box
                    ph={ph}
                    pt={SpacingValue["space-x-large"]}
                    style={{ backgroundColor: theme["color-background-primary"] }}>
                    <Stack spacing={SpacingValue["space-x-large"]}>
                        <Input label="Phone number" value={phoneNumber} disabled />
                        <Input
                            ref={emailInputRef}
                            label="Email address"
                            keyboardType="email-address"
                            returnKeyType="next"
                            autoCorrect={false}
                            autoCapitalize={"none"}
                            spellCheck={false}
                            value={email}
                            error={!!emailError?.length}
                            hint={emailError}
                            onFocus={clearEmailError}
                            onChangeText={onChangeTextEmail}
                            onSubmitEditing={onSubmitEditingEmail}
                            accessoryRight={accessoryRightEmailError}
                        />
                        <Input
                            ref={nameInputRef}
                            spellCheck={false}
                            label="Name"
                            value={name}
                            error={!!nameError?.length}
                            hint={nameError}
                            onFocus={clearNameError}
                            onChangeText={onChangeTextName}
                            accessoryRight={accessoryRightNameError}
                        />
                    </Stack>
                    <Box mt={SpacingValue["space-xx-large"]}>
                        <Text category="b2" color="color-basic-60">
                            By creating an account, I agree to accept the
                            <Text category="b2" color="color-highlight-200" onPress={onTnCPress}>
                                {" Terms & Conditions"}
                            </Text>
                        </Text>
                    </Box>
                </Box>
            </ScrollView>
            <SafeAreaView>
                <Box
                    ph={SpacingValue["space-x-large"]}
                    pb={SpacingValue["space-x-large"]}
                    pt={SpacingValue["space-medium"]}
                    justifyContent="flex-end">
                    {error ? (
                        <Text color="critical" weight="medium" style={styles.error}>
                            {error.message}
                        </Text>
                    ) : null}
                    <ActionButton
                        loading={loading}
                        disabled={isDisabledSignupButton}
                        onPress={onPressCTA}>
                        Verify details
                    </ActionButton>
                </Box>
            </SafeAreaView>
        </AuthScreenContainer>
    );
};

export const TextInputAccessoryRightError: React.FC = () => (
    <View style={styles.fixedIcon}>
        <SvgIcon icon="AlertCircle" width={18} height={18} color="color-negative" />
    </View>
);

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        overflow: "hidden",
    },
    fixedIcon: {
        position: "absolute",
        right: SpacingValue["space-medium"],
    },
    error: {
        marginBottom: SpacingValue["space-x-small"],
    },
});
