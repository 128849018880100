import React from "react";
import { Pressable, StyleProp, StyleSheet, ViewStyle } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { InView } from "@swiggy-private/react-native-ui";
import { replaceSpacesInArrayMap } from "@swiggy-private/common-helpers";

import { Divider } from "@minis-consumer/components/divider";
import { Analytics } from "@minis-consumer/analytics";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { ARD_EVENTS } from "@minis-consumer/routes/product/constants";
import { Product } from "@minis-consumer/interfaces/catalog";

import { BORDER_RADIUS_VALUE, TEST_IDS, TITLE } from "./constants";

type ReturnPolicyParam = {
    productId: string;
    keyDetails?: Product["keyDetails"];
};

const KeyHighlightsComponent: React.FC<ReturnPolicyParam> = ({ keyDetails, productId }) => {
    const { value: theme } = useTheme();

    const { storeId } = useStoreInfo();

    const dividerStyle = {
        marginTop: SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-large"],
    };

    const [showDetails, setShowDetails] = React.useState(true);

    const analyticsContextData = React.useMemo(
        () =>
            JSON.stringify({
                storeId,
                productId,
            }),
        [storeId, productId],
    );

    const togglePolicyView = React.useCallback(() => {
        Analytics.clickEvent({
            category: ARD_EVENTS.DETAILS,
            context: analyticsContextData,
        });

        setShowDetails(!showDetails);
    }, [showDetails, analyticsContextData]);

    const omImpression = React.useCallback(
        (isVisible: boolean) => {
            if (!isVisible || !showDetails) {
                return;
            }

            Analytics.impressionEvent({
                category: ARD_EVENTS.DETAILS,
                context: analyticsContextData,
            });
        },
        [showDetails, analyticsContextData],
    );

    const keyContainerStyles = {
        backgroundColor: theme["color-basic-5"],
    };

    const transformedKeyDetails = React.useMemo(
        () =>
            keyDetails
                ? replaceSpacesInArrayMap(keyDetails as { key: string; value: string }[])
                : [],
        [keyDetails],
    );

    const keyDetailsRowStyle: StyleProp<ViewStyle> = React.useMemo(() => {
        return {
            borderWidth: 1,
            borderColor: theme["color-basic-15"],
            borderTopWidth: 0,
            overflow: "hidden",
        };
    }, [theme]);

    const firstRowStyle: StyleProp<ViewStyle> = React.useMemo(() => {
        return {
            borderTopWidth: 1,
            borderTopColor: theme["color-basic-15"],
            borderTopLeftRadius: BORDER_RADIUS_VALUE,
            borderTopRightRadius: BORDER_RADIUS_VALUE,
        };
    }, [theme]);

    /**
     * To adjust mainly the border styling of each row
     * please check figma:
     * https://www.figma.com/file/zddWxx78Q4FAt64rMii5eY/PDP-Improvements?node-id=313%3A10386&mode=dev
     *  */
    const getRowStyle = React.useCallback(
        (index: number) => {
            if (transformedKeyDetails.length === 1) {
                return [keyDetailsRowStyle, firstRowStyle, styles.lastRowStyle];
            }

            if (index === 0 || transformedKeyDetails.length === 1) {
                return [keyDetailsRowStyle, firstRowStyle];
            } else if (index === transformedKeyDetails.length - 1) {
                return [keyDetailsRowStyle, styles.lastRowStyle];
            }

            return [keyDetailsRowStyle];
        },
        [transformedKeyDetails, firstRowStyle, keyDetailsRowStyle],
    );

    const getKeyStyle = React.useCallback(
        (index: number) =>
            index === 0
                ? {
                      borderTopLeftRadius: BORDER_RADIUS_VALUE,
                  }
                : index === transformedKeyDetails.length - 1
                ? {
                      borderBottomLeftRadius: BORDER_RADIUS_VALUE,
                  }
                : undefined,
        [transformedKeyDetails.length],
    );

    if (!keyDetails?.length) {
        return null;
    }

    return (
        <>
            <Pressable onPress={togglePolicyView} hitSlop={16} testID={TEST_IDS.PRESSABLE}>
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                    style={styles.headerContainer}>
                    <Text category="b1" weight="bold" color="high" testID={TEST_IDS.TITLE}>
                        {TITLE}
                    </Text>

                    <Box style={styles.caret} testID={TEST_IDS.CHEVRON}>
                        <SvgIcon
                            icon={showDetails ? "ChevronUp" : "ChevronDown"}
                            color="color-basic-75"
                            width={20}
                        />
                    </Box>
                </Stack>
            </Pressable>

            {showDetails ? (
                <InView onChange={omImpression}>
                    <Box style={styles.details} testID={TEST_IDS.TABLE}>
                        {transformedKeyDetails.map((data, index) => (
                            <Stack
                                direction="row"
                                key={index}
                                style={getRowStyle(index)}
                                testID={TEST_IDS.ROW}>
                                <Box flex={1} style={[keyContainerStyles, getKeyStyle(index)]}>
                                    <Text
                                        category="b3"
                                        weight="bold"
                                        color="medium"
                                        style={styles.detailsText}>
                                        {data.key}
                                    </Text>
                                </Box>
                                <Box flex={2}>
                                    <Text category="b3" color="high" style={styles.detailsText}>
                                        {data.value}
                                    </Text>
                                </Box>
                            </Stack>
                        ))}
                    </Box>
                </InView>
            ) : null}

            <Divider style={dividerStyle} />
        </>
    );
};

export const KeyHighlights = React.memo(KeyHighlightsComponent);

const styles = StyleSheet.create({
    caret: {
        width: 24,
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    headerContainer: {
        flex: 1,
    },
    details: {
        marginTop: SpacingValue["space-medium"],
    },
    detailsText: {
        marginHorizontal: SpacingValue["space-small"],
        marginVertical: SpacingValue["space-medium"],
    },
    lastRowStyle: {
        borderBottomLeftRadius: BORDER_RADIUS_VALUE,
        borderBottomRightRadius: BORDER_RADIUS_VALUE,
    },
});
