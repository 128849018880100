import React from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";

interface Props {
    onChatPress: () => void;
    eventLabel?: string;
}

const HeaderRightComponent: React.FC<Props> = ({ onChatPress, eventLabel }) => {
    const { value: theme } = useTheme();

    const chatBackground: ViewStyle = {
        backgroundColor: theme["color-background-primary"],
    };

    const hitSlop = { top: 10, bottom: 10, left: 10, right: 10 };

    useMount(() => {
        Analytics.impressionEvent({
            category: "chat-btn",
            label: eventLabel,
        });
    });

    return (
        <Box
            style={[styles.chatContainer, chatBackground]}
            direction="row"
            justifyContent="center"
            alignItems="center"
            mr={SpacingValue["space-small"]}>
            <SvgIcon
                icon="Chat"
                height={16}
                width={16}
                color="color-basic-60"
                onPress={onChatPress}
                hitSlop={hitSlop}
            />
        </Box>
    );
};

export const HeaderRight = React.memo(HeaderRightComponent);

const styles = StyleSheet.create({
    chatContainer: {
        height: 36,
        width: 36,
        borderRadius: 18,
    },
});
