import { ReturnSettingsUnit } from "@minis-consumer/interfaces/store";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { STORE_POLICY, UNIT_OPTIONS_MAP } from "../constants";

/** Get card width for storefront coupon carousel */
export const getStorefrontCouponCardWidth = (
    containerWidth: number,
    margin: number,
    paginationBoxSize: number,
): number => {
    const cardWidth =
        containerWidth -
        2 * margin - // container margin
        SpacingValue["space-medium"] - // container padding
        paginationBoxSize -
        SpacingValue["space-x-small"] - // spacing between image and text
        SpacingValue["space-x-small"]; // spacing between list and pagination

    return cardWidth;
};

/** Get page index for storefront coupon card carousel */
export const getCouponPage = (slideIndex: number, couponsLength: number): number => {
    if (slideIndex === 0) {
        return 0;
    } else if (slideIndex === couponsLength - 1) {
        return Math.min(2, couponsLength - 1);
    }

    return 1;
};

export const getReturnSettingInfoText: (
    enabled: boolean,
    duration: string | number,
    unit: ReturnSettingsUnit,
) => string = (enabled: boolean, duration: string | number, unit: ReturnSettingsUnit) => {
    if (!enabled) {
        return STORE_POLICY.NOT_ALLOWED;
    }

    return `Accepting ${duration} ${UNIT_OPTIONS_MAP[unit]} from delivery time`;
};

export const hasHttpUrlScheme = (link: string): boolean => {
    if (!link?.trim()) {
        return false;
    }

    const hasHttp = link.indexOf("http://") > -1;
    const hasHttps = link.indexOf("https://") > -1;

    if (!hasHttp && !hasHttps) {
        return false;
    }

    return true;
};
