import React, { memo } from "react";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { ReturnOrder, ReturnOrderSubStatusesEnum } from "@minis-consumer/interfaces/return-order";
import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";

import { DATE_FORMAT, DISPLAY_COPIES } from "../constants";
import { ListWithIcon } from "../../list-with-icon";

interface Props {
    returnOrder: ReturnOrder;
}

const getPointText = (text: string): JSX.Element => (
    <Text key={text} category="b2" color="color-basic-75">
        {text}
    </Text>
);

const ReturnTimelineComponent: React.FC<Props> = ({ returnOrder }) => {
    const timelineTexts: JSX.Element[] = [];
    const { status, statusUpdatedAt, createdAt } = returnOrder;

    const isClosed =
        status !== ReturnOrderSubStatusesEnum.PENDING &&
        status !== ReturnOrderSubStatusesEnum.IN_PROGRESS;

    if (status === ReturnOrderSubStatusesEnum.PENDING) {
        timelineTexts.push(getPointText(DISPLAY_COPIES.UNDER_REVIEW));
    } else {
        timelineTexts.push(
            getPointText(
                `Accepted return: ${convertToReadableDateAndTime(createdAt, DATE_FORMAT)}`,
            ),
        );
    }

    if (isClosed) {
        timelineTexts.push(
            getPointText(
                `Closed at: ${convertToReadableDateAndTime(statusUpdatedAt, DATE_FORMAT)}`,
            ),
        );
    }

    return (
        <Box mh={SpacingValue["space-small"]}>
            <ListWithIcon list={timelineTexts} />
        </Box>
    );
};

export const ReturnTimeline = memo(ReturnTimelineComponent);
