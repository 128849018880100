import React from "react";

import { BillItem } from "@minis-consumer/components/bill-item";
import { CartBill } from "@minis-consumer/interfaces/cart";

import { AdditionalChargeTooltip } from "./components/tooltip";

interface Props {
    charges: CartBill["charges"];
}

const AdditionalChargesComponent: React.FC<Props> = ({ charges }) => {
    if (!charges) {
        return null;
    }

    return (
        <>
            {charges.map((charge) => (
                <BillItem
                    key={charge.id}
                    title={charge.name}
                    amount={charge.value}
                    finalAmount={charge.finalValue}
                    tooltip={<AdditionalChargeTooltip charges={charge.subCharges} />}
                />
            ))}
        </>
    );
};

export const AdditionalCharges = React.memo(AdditionalChargesComponent);
