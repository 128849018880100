import React from "react";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Container, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

interface Props {
    title: string;
    container?: boolean;
}

const SectionTitleComponent: React.FC<Props> = ({ title, container = true }) => {
    const [containerFluid, disableContainerGutter] = useSelectScreen({
        default: [true, true],
        lg: [false, false],
    });

    if (container) {
        return (
            <Container fluid={containerFluid} disableGutters={disableContainerGutter}>
                <Component title={title} />
            </Container>
        );
    }

    return <Component title={title} />;
};

const Component: React.FC<{ title: string }> = ({ title }) => (
    <Box mb={SpacingValue["space-medium"]} mt={SpacingValue["space-xx-large"]}>
        <Text category="b1" weight="bold" color="high">
            {title}
        </Text>
    </Box>
);

export const SectionTitle = React.memo(SectionTitleComponent);
