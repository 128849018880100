import React, { useCallback, useEffect, useState } from "react";
import { Platform, ScrollViewProps, StyleSheet, Animated, Easing } from "react-native";

import { useNavigation } from "@react-navigation/native";
import { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { Text, useTheme, useAnimatedValue, SpacingValue, shadow } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { StorefrontNavBar } from "@minis-consumer/components/store-front-navbar";
import { useCollapsibleHeader } from "@minis-consumer/hooks/use-collapsible-header";
import { HomeRouteList } from "@minis-consumer/interfaces/route";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useMinisNavigationBack } from "@minis-consumer/hooks/use-minis-navigation";
import { compactTitle } from "@minis-consumer/helpers/title";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";
import { Analytics } from "@minis-consumer/analytics";
import { useSignedIn } from "@minis-consumer/hooks/use-user";
import { useGetHslFromRgb } from "@minis-consumer/hooks/use-get-hsl-from-hex";

import { useGetProductCategories } from "../../../components/catalog/hooks/use-get-categories";
import { ShopBrowse } from "../../../components/floating-action-button";
import { HeaderRight, IconContainer, IconContainerProps } from "../../../components/header-right";
import { DELIVERY_ACROSS_INDIA } from "../../../components/delivery/constants";
import { HEADER_HEIGHT, SF_ANALYTICS_EVENT_NAMES } from "../../../constants";

interface HeaderLeftProps {
    navigation: Navigation;
    color?: string;
    containerStyle?: IconContainerProps["style"];
}

const ICON_SIZE = 20;

const HeaderLeft: React.FC<HeaderLeftProps> = ({ navigation, color, containerStyle }) => {
    const [canGoBack, minisNavigationBackHandler] = useMinisNavigationBack();
    const showBackIcon = canGoBack || navigation.canGoBack();
    const isSignedIn = useSignedIn();

    const onPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: SF_ANALYTICS_EVENT_NAMES.BACK,
        });

        if (!isSignedIn) {
            return;
        }

        if (canGoBack) {
            minisNavigationBackHandler();
        } else {
            navigation.goBack();
        }
    }, [canGoBack, isSignedIn, minisNavigationBackHandler, navigation]);

    useEffect(() => {
        if (showBackIcon) {
            Analytics.impressionEvent({
                category: SF_ANALYTICS_EVENT_NAMES.BACK,
            });
        }
    }, [showBackIcon]);

    if (showBackIcon) {
        return (
            <IconContainer
                accessibilityLabel="Back"
                onPress={onPress}
                style={containerStyle}
                testID={TEST_IDS_SF.BACK}>
                <SvgIcon icon="ArrowLeft" color={color} width={ICON_SIZE} height={ICON_SIZE} />
            </IconContainer>
        );
    }

    return null;
};

type Navigation = BottomTabScreenProps<HomeRouteList, "Shop">["navigation"];

interface IHeaderProps {
    navBarPos?: { height: number; pageY: number };
    deliveryInfoOffset: number;
    isTooltipVisible?: boolean;
    onCategoryPress: (categoryId: string) => void;
    isCatalogView?: boolean;
}

const MIN_CAT_FAB_OFFSET = 300;

const HeaderComponent = React.forwardRef<{ onScroll: ScrollViewProps["onScroll"] }, IHeaderProps>(
    ({ navBarPos, deliveryInfoOffset, onCategoryPress, isTooltipVisible, isCatalogView }, ref) => {
        const { value: theme } = useTheme();
        const { storeId, enabled, name, address, interDeliveryConfig, settings } = useStoreInfo();
        const isStoreEnabled = enabled !== false;
        const categories = useGetProductCategories();

        const navigation = useNavigation() as Navigation;
        const [headerShown, setHeaderShown] = useState(false);
        const [navBarShown, setNavBarShown] = useState(false);
        const [catFabShown, setCatFabShown] = useState(false);
        const [deliveryInfoShown, setDeliveryInfoShown] = useState(false);

        const insets = useSafeAreaInsets();

        const headerAnimValue = useAnimatedValue(0);

        const themeHslValue = useGetHslFromRgb();

        const hasBackgroundImage = settings.backgroundImageId;

        const getIconBackgroundColor = useCallback(() => {
            if (hasBackgroundImage) {
                return theme.background_Overlay_Light.toString();
            }

            return `hsla(${themeHslValue[0] * 360}, 63%, 33%, 0.13)`;
        }, [themeHslValue, hasBackgroundImage, theme]);

        const iconContainerStyle = {
            backgroundColor: headerAnimValue.interpolate({
                inputRange: [0, HEADER_HEIGHT],
                outputRange: [getIconBackgroundColor(), "transparent"],
            }),
            borderRadius: 20,
        };

        const headerTitleStyle = {
            fontFamily: theme["text-body-1-bold-font-family"],
            color: theme["color-basic-100"].toString(),
            fontSize: theme["text-body-1-bold-font-size"],
            lineHeight: theme["text-body-1-bold-line-height"],
            letterSpacing: theme["text-body-1-bold-letter-spacing"],
            paddingHorizontal: SpacingValue["space-medium"],
            marginRight: SpacingValue["space-medium"],
            flex: 1,
        };

        const { onScroll } = useCollapsibleHeader({
            Component: (props) => {
                const isNavBarShown = props.headerShown && !props.scrolledUp;
                if (isNavBarShown !== navBarShown) {
                    setNavBarShown(isNavBarShown);
                }
                return (
                    <StorefrontNavBar
                        {...props}
                        customStyles={styles.headerContainerStyle}
                        shownAsHeader
                    />
                );
            },
            height: navBarPos?.height ?? 0 + 36,
            initialThreshold: navBarPos?.pageY ?? 0,
        });

        React.useImperativeHandle(
            ref,
            () => ({
                onScroll: (e) => {
                    const offset = e.nativeEvent.contentOffset;
                    const newValue = Math.min(HEADER_HEIGHT, offset.y);

                    if (newValue !== (headerAnimValue as unknown as number)) {
                        headerAnimValue.setValue(newValue);
                    }
                    const toshowHeader = offset.y > HEADER_HEIGHT;
                    toshowHeader !== headerShown && setHeaderShown(toshowHeader);
                    setCatFabShown(offset.y > MIN_CAT_FAB_OFFSET);
                    setDeliveryInfoShown(offset.y > deliveryInfoOffset);
                    onScroll(e, { opacity: 1 });
                },
            }),
            [onScroll, deliveryInfoOffset, headerAnimValue, headerShown],
        );

        const animValue = useAnimatedValue(0);
        const devliveryInfoAnimValue = useAnimatedValue(0);
        const textInfoAnimValue = useAnimatedValue(10);

        const deliveryInfoAnimation = React.useMemo(
            () =>
                Animated.timing(devliveryInfoAnimValue, {
                    toValue: deliveryInfoShown ? 1 : 0,
                    duration: 100,
                    easing: Easing.bezier(0.25, 0.1, 0.25, 1),
                    useNativeDriver: Platform.OS !== "web",
                }),
            [devliveryInfoAnimValue, deliveryInfoShown],
        );

        const textInfoAnimation = React.useMemo(
            () =>
                Animated.timing(textInfoAnimValue, {
                    toValue: deliveryInfoShown ? 0 : 10,
                    duration: 100,
                    easing: Easing.bezier(0.25, 0.1, 0.25, 1),
                    useNativeDriver: Platform.OS !== "web",
                }),
            [deliveryInfoShown, textInfoAnimValue],
        );

        const getFABStyles = { transform: [{ translateY: animValue }] };

        const getTextInfoStyles = { transform: [{ translateY: textInfoAnimValue }] };

        useEffect(() => {
            deliveryInfoAnimation.start();
            textInfoAnimation.start();
        }, [deliveryInfoAnimation, textInfoAnimation]);

        const shadowStyles = shadow(4);
        const shadowStyle = headerShown && !navBarShown ? shadowStyles : { shadowOpacity: 0 };

        return (
            <>
                <Box direction="column" style={styles.headerBackgroundContainerStyle}>
                    <Box style={shadowStyle}>
                        <Animated.View
                            style={[
                                {
                                    backgroundColor: headerAnimValue.interpolate({
                                        inputRange: [0, HEADER_HEIGHT / 2],
                                        outputRange: [
                                            "transparent",
                                            theme["color-background-primary"].toString(),
                                        ],
                                    }),

                                    paddingTop: insets.top,
                                },
                                Platform.OS === "android" ? shadowStyle : null,
                            ]}>
                            <Box direction="row" alignItems="center" style={[styles.header]}>
                                <HeaderLeft
                                    color={
                                        headerShown
                                            ? theme["color-basic-100"].toString()
                                            : theme["color-basic-0"].toString()
                                    }
                                    navigation={navigation}
                                    containerStyle={iconContainerStyle}
                                />
                                <Animated.View
                                    style={StyleSheet.flatten([
                                        {
                                            opacity: headerAnimValue.interpolate({
                                                inputRange: [0, HEADER_HEIGHT],
                                                outputRange: [0, 1],
                                            }),
                                        },
                                        headerTitleStyle,
                                    ])}>
                                    <Animated.View style={[getTextInfoStyles]}>
                                        <Text category="btn3" numberOfLines={1}>
                                            {compactTitle(name)}
                                        </Text>
                                        <Animated.View
                                            style={{
                                                opacity: devliveryInfoAnimValue,
                                            }}>
                                            <Text
                                                category="b3"
                                                weight="regular"
                                                color="color-basic-60"
                                                numberOfLines={1}>
                                                {interDeliveryConfig?.enabled
                                                    ? DELIVERY_ACROSS_INDIA
                                                    : address?.city
                                                    ? `Delivery only in ${address?.city}`
                                                    : ""}
                                            </Text>
                                        </Animated.View>
                                    </Animated.View>
                                </Animated.View>
                                <HeaderRight
                                    color={
                                        headerShown
                                            ? theme["color-basic-100"].toString()
                                            : theme["color-basic-0"].toString()
                                    }
                                    storeId={storeId}
                                    isStoreEnabled={isStoreEnabled}
                                    containerStyle={iconContainerStyle}
                                    canShowChatIconText={!headerShown}
                                    canShowShareIcon={false}
                                    canShowChatIcon={false}
                                    isTooltipVisible={isTooltipVisible}
                                    canShowAccountIcon
                                />
                            </Box>
                        </Animated.View>
                    </Box>
                </Box>
                <Animated.View
                    style={[
                        getFABStyles,
                        styles.category,
                        { top: 64 + SpacingValue["space-small"] + insets.top },
                    ]}>
                    {catFabShown && isCatalogView && categories.length > 1 && (
                        <ShopBrowse
                            onPressItem={onCategoryPress}
                            navBarShown={navBarShown}
                            offset={navBarPos?.height ?? 0}
                        />
                    )}
                </Animated.View>
            </>
        );
    },
);

if (__DEV__) {
    HeaderComponent.displayName = "HeaderComponent";
}

export const Header = React.memo(HeaderComponent);

const styles = StyleSheet.create({
    surface: {
        elevation: 5,
    },
    headerBackgroundContainerStyle: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        zIndex: 1,
        height: HEADER_HEIGHT,
    },

    headerContainerStyle: {
        zIndex: -1,
    },

    header: {
        height: HEADER_HEIGHT,
    },

    category: {
        alignSelf: "center",
        zIndex: 1,
    },
});
