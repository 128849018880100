export const BRAND_IMAGE_FALLBACK = "minis/brand_image_fallback";

export const CART_COUPONS_SECTION_DATA = {
    bestCoupons: {
        title: "Best coupon",
        isEligible: true,
    },
    eligibleCoupons: {
        title: "Available coupons",
        isEligible: true,
    },
    ineligibleCoupons: {
        title: "More offers",
        isEligible: false,
    },
};
