import * as React from "react";
import { Platform, StyleSheet, ViewProps } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { Button, SpacingValue, Surface, Text } from "@swiggy-private/rn-dls";
import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { BottomSheet } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { useCartView } from "@minis-consumer/hooks/use-cart";
import { Card } from "@minis-consumer/components/card";
import { RouteList } from "@minis-consumer/interfaces/route";

interface CheckoutBottomSheetProps extends ViewProps {
    open?: boolean;
    storeId: string;
}

const TOTAL = "TOTAL";
const CHECKOUT = "Checkout";

const CheckoutBottomSheetComponent: React.FC<CheckoutBottomSheetProps> = ({
    storeId,
    open,
    onLayout,
}) => {
    const cart = useCartView(storeId);
    const insets = useSafeAreaInsets();
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();

    const surfaceStyle = React.useMemo(() => {
        return Platform.OS === "ios" ? { elevation: 4 } : { elevation: 14 };
    }, []);

    const containerStyle = useSelectScreen({
        lg: {
            borderBottomRightRadius: 16,
            borderBottomLeftRadius: 16,
        },
        default: {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            paddingTop: SpacingValue["space-medium"],
        },
    });

    const cardStyle = useSelectScreen({
        default: {
            padding: SpacingValue["space-x-large"],
        },
        md: {
            padding: SpacingValue["space-medium"],
            paddingBottom: insets.bottom + SpacingValue["space-medium"],
        },
        sm: {
            padding: SpacingValue["space-medium"],
            paddingBottom: insets.bottom + SpacingValue["space-medium"],
        },
        xs: {
            padding: SpacingValue["space-medium"],
            paddingBottom: insets.bottom + SpacingValue["space-medium"],
        },
    });

    const onPress = React.useCallback(
        () => navigation.getParent()?.navigate("GuestCheckout", {}),
        [navigation],
    );

    return open ? (
        <BottomSheet
            open={open}
            usePortal={false}
            showBackdrop={false}
            animate={false}
            disableHardwareBackClose
            style={styles.dialog}>
            <Surface style={[styles.surface, surfaceStyle]}>
                <Card style={[containerStyle, cardStyle, styles.fullWidth]} onLayout={onLayout}>
                    <Button
                        onPress={onPress}
                        color="primary"
                        textContainerStyle={styles.btnContainer}
                        textStyle={styles.fullWidth}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            flex={1}>
                            <Stack direction="column" spacing={SpacingValue["space-xxx-small"]}>
                                <Text color="color-basic-0" style={styles.totalPrice}>
                                    {TOTAL}
                                </Text>
                                <Text color="color-basic-0" category="b1" weight="bold">
                                    ₹{cart?.cartViewData?.bill.finalBillTotal ?? "-"}
                                </Text>
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={SpacingValue["space-x-small"]}
                                alignItems="center"
                                justifyContent="space-between">
                                <Text color="color-basic-0" category="s1" weight="bold">
                                    {CHECKOUT}
                                </Text>
                                <SvgIcon icon="ArrowRight" color="color-basic-0" />
                            </Stack>
                        </Stack>
                    </Button>
                </Card>
            </Surface>
        </BottomSheet>
    ) : null;
};

export const GuestCheckoutBottomSheet = React.memo(CheckoutBottomSheetComponent);

const styles = StyleSheet.create({
    surface: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    dialog: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    fullWidth: {
        width: "100%",
    },
    btnContainer: {
        flex: 1,
    },
    totalPrice: { letterSpacing: 2, fontSize: 12 },
});
