import React from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

interface Props {
    text: string;
}

const BulletPointComponent: React.FC<Props> = ({ text }) => {
    const { value: theme } = useTheme();

    const bulletBackground: ViewStyle = {
        backgroundColor: theme["color-primary"],
    };

    return (
        <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
            <Box style={[styles.bullet, bulletBackground]} />
            <Text category="b2" weight="medium" color="medium" style={styles.bulletText}>
                {text}
            </Text>
        </Stack>
    );
};

export const BulletPoint = React.memo(BulletPointComponent);

const styles = StyleSheet.create({
    bullet: {
        height: 8,
        width: 8,
        borderRadius: 4,
        marginTop: SpacingValue["space-xx-small"],
    },
    bulletText: {
        flexShrink: 1,
    },
});
