import { ServiceabilityType } from "@swiggy-private/connect-address-ui";
import { TextProps } from "@swiggy-private/rn-dls";

import { COPIES } from "./constants";

interface props {
    storeName: string;
    addressId?: string;
    postalCode?: number;
    deliveryEta?: string | null;
    hasError?: boolean;
    serviceabilityType?: ServiceabilityType;
}

export const getDisplayTextAndStyles = ({
    storeName,
    addressId,
    postalCode,
    deliveryEta,
    hasError,
    serviceabilityType,
}: props): {
    text: string;
    color: TextProps["color"];
} => {
    if (hasError) {
        return {
            text: `${COPIES.DIRECT_DELIVERY} ${storeName}`,
            color: "color-basic-75",
        };
    }

    if (deliveryEta) {
        return {
            text: `${storeName} delivers in ${deliveryEta}`,
            color: "color-primary",
        };
    }

    if (!addressId && !postalCode) {
        return {
            text: `${COPIES.DIRECT_DELIVERY} ${storeName}`,
            color: "color-basic-75",
        };
    }

    if (serviceabilityType && serviceabilityType !== "UNSERVICEABLE") {
        return {
            text: `Order delivered by ${storeName}`,
            color: "color-primary",
        };
    }

    return {
        text: `${storeName} does not deliver`,
        color: "color-critical-400",
    };
};
