import * as React from "react";
import { ScrollView } from "react-native";

import { Container } from "@swiggy-private/rn-adaptive-layout";
import { useTheme } from "@swiggy-private/rn-dls";

import { StateContextContainer } from "@minis-consumer/containers/state-context-container";

import { HelpDetailsLevelOneContent } from "./components/content";

export const HelpDetailsLevelOne: React.FC = () => {
    const { value: theme } = useTheme();
    const bgSecondaryColor = theme["color-background-secondary"];

    const scrollStyle = {
        backgroundColor: bgSecondaryColor,
    };

    return (
        <StateContextContainer>
            <ScrollView style={scrollStyle}>
                <Container fluid={false}>
                    <HelpDetailsLevelOneContent />
                </Container>
            </ScrollView>
        </StateContextContainer>
    );
};
