export const formatDate = (timestamp: number | undefined): string => {
    if (!timestamp) {
        return "";
    }

    const date = new Date(timestamp * 1000); // Convert epoch timestamp to milliseconds
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes.toString().padStart(2, "0"); // Add leading zero if needed
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // Get short month name

    return `${formattedHours}:${formattedMinutes} ${ampm}, ${day}${getOrdinalSuffix(day)} ${month}`;
};

// Function to get ordinal suffix for day (e.g., 1st, 2nd, 3rd)
const getOrdinalSuffix = (day: number): string => {
    if (day >= 11 && day <= 13) {
        return "th";
    }
    switch (day % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
};
