import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { useDebounce } from "@swiggy-private/react-hooks";

import { Card } from "@minis-consumer/components/card";
import { CartItem as ICartItem } from "@minis-consumer/interfaces/cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartInstructions, useCartView } from "@minis-consumer/hooks/use-cart";
import { useShopNavigation } from "@minis-consumer/hooks/use-shop-navigation";
import { Analytics } from "@minis-consumer/analytics";

import { getNonCustomItems, getCustomItems } from "../../helpers";
import { StoreInfo } from "./components/store-info";
import { CartItem } from "./components/item";
import { CONST } from "./constants";
import { CartErrorsV2 } from "./components/cart-errors-v2";

const CartItemListComponent: React.FC = () => {
    const storeInfo = useStoreInfo();
    const cart = useCartView(storeInfo.storeId);
    const [stateInstructions, setStateInstructions] = useCartInstructions(storeInfo.storeId);
    const goToStore = useShopNavigation();

    const [instructions] = React.useState(stateInstructions || "");
    const debouncedInstructions = useDebounce(instructions, { wait: 300 });

    React.useEffect(() => {
        setStateInstructions(debouncedInstructions);
    }, [debouncedInstructions, setStateInstructions]);

    const contentPadding = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        md: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const cardStyle = {
        paddingBottom: 0,
        paddingTop: contentPadding,
    };

    const contentStyle = {
        paddingHorizontal: contentPadding,
    };

    const customProductHeader = {
        paddingHorizontal: contentPadding,
        paddingTop: SpacingValue["space-medium"],
    };

    const nonCustomItems = React.useMemo(
        () => (cart?.cartViewData?.cartItems ? getNonCustomItems(cart.cartViewData.cartItems) : []),
        [cart],
    );

    const customItems = React.useMemo(
        () => (cart?.cartViewData ? getCustomItems(cart.cartViewData.cartItems) : []),
        [cart],
    );

    const onPressStore = React.useCallback(() => {
        Analytics.clickEvent({
            category: CONST.STORE_ANALYTICS_CATEGORY,
            label: cart?.cartViewData?.id,
            context: storeInfo.storeId,
        });

        goToStore(storeInfo.slug);
    }, [cart?.cartViewData?.id, goToStore, storeInfo.slug, storeInfo.storeId]);

    return (
        <Card style={styles.p0}>
            <Box style={cardStyle}>
                <Pressable onPress={onPressStore} style={contentStyle} hitSlop={{ top: 16 }}>
                    <StoreInfo />
                </Pressable>

                {nonCustomItems.length ? (
                    <Stack
                        style={styles.mt0}
                        spacing={{
                            default: 1,
                        }}>
                        {nonCustomItems.map((data: ICartItem) => (
                            <CartItem
                                style={contentStyle}
                                id={data.item.id}
                                name={data.item.name}
                                price={data.item.price}
                                variantId={data.item.variantId}
                                discountedPrice={data.item.discountedPrice}
                                isCustom={data.metadata.custom}
                                inStock={data.metadata.custom ? true : data.item.inStock}
                                key={`${data.item.id}-${data.item.variantId ?? ""}`}
                                storeId={storeInfo.storeId}
                                quantity={data.quantity}
                                cartId={cart?.cartViewData?.id}
                                productImageId={data.item.imageIds?.[0]}
                                storeSlug={storeInfo.slug}
                                variants={data.item.variantOptionValues}
                                type={data.item.productType}
                                itemForm={data.itemForm}
                                slot={data.slot}
                                productDuration={data.item.productDuration}
                                itemStatus={data.status}
                            />
                        ))}
                    </Stack>
                ) : null}

                {customItems.length ? (
                    <Stack>
                        <Box
                            mb={SpacingValue["space-small"]}
                            mt={nonCustomItems.length ? SpacingValue["space-large"] : undefined}>
                            <Text
                                category="btn5"
                                color="color-basic-45"
                                weight="bold"
                                style={customProductHeader}>
                                {CONST.CUSTOM_PRODUCT_SECTION_TITLE}
                            </Text>
                        </Box>
                        {customItems.map((data: ICartItem, index: number) => (
                            <CartItem
                                style={contentStyle}
                                id={data.item.id}
                                name={data.item.name}
                                price={data.item.price}
                                discountedPrice={data.item.discountedPrice}
                                isCustom={data.metadata.custom}
                                inStock={data.metadata.custom ? true : data.item.inStock}
                                // index added as custom items can be duplicated
                                key={data.item.id + index.toString()}
                                storeId={storeInfo.storeId}
                                quantity={data.quantity}
                                cartId={cart?.cartViewData?.id}
                                productImageId={data.item.imageIds?.[0]}
                                storeSlug={storeInfo.slug}
                                type={data.item.productType}
                                itemForm={data.itemForm}
                                slot={data.slot}
                                productDuration={data.item.productDuration}
                                itemStatus={data.status}
                            />
                        ))}
                    </Stack>
                ) : null}

                <CartErrorsV2 />
            </Box>
        </Card>
    );
};

const styles = StyleSheet.create({
    mt0: {
        marginTop: 0,
    },
    p0: {
        padding: 0,
    },
});

export const CartItemList = CartItemListComponent;
