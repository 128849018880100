/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from "react";

import { useFocusEffect } from "@react-navigation/core";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { useMount } from "@swiggy-private/react-hooks";
import { useTheme } from "@swiggy-private/rn-dls";

import { OverlayLoader } from "@minis-consumer/components/overlay-loader";
import { StoreDeleteErrorSnackbarProvider } from "@minis-consumer/containers/store-deleted-error-snack-provider";
import { createNavigator } from "@minis-consumer/helpers/navigation";
import { useIsNavbarVisible } from "@minis-consumer/hooks/use-is-navbar-visible";
import { useScreenOptions } from "@minis-consumer/hooks/use-screen-options";
import { AppEvent, AppEventTypes } from "@minis-consumer/includes/app-event";
import { PaymentRouteList, RouteList } from "@minis-consumer/interfaces/route";
import {
    META_PIXEL_CONTENT_NAME,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";

import { PaymentContextProvider } from "./contexts/payment-context";
import { usePaymentSelector } from "./hooks/use-selector";

const Stack = createNavigator<PaymentRouteList>();
type PaymentScreenProps = NativeStackScreenProps<RouteList, "Payment">;

export const PaymentScreen: React.FC<PaymentScreenProps> = () => {
    const showNavbar = useIsNavbarVisible();
    const { value: theme } = useTheme();

    const screenOptions = useScreenOptions<PaymentRouteList>({
        headerShown: !showNavbar,
        headerTitleStyle: {
            fontFamily: theme["text-body-1-bold-font-family"],
            color: theme["color-basic-100"],
            fontSize: theme["text-body-1-bold-font-size"],
            lineHeight: theme["text-body-1-bold-line-height"],
            letterSpacing: theme["text-body-1-bold-letter-spacing"],
            fontWeight: "bold",
        },
        animationEnabled: true,
    });

    useFocusEffect(
        React.useCallback(
            () =>
                AppEvent.listen(AppEventTypes.NEW_CHAT_MESSAGE, (event) => {
                    if (!event.handled) {
                        event.handled = true;
                    }
                }),
            [],
        ),
    );

    useMount(() => {
        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.VIEW_CONTENT, {
            content_name: META_PIXEL_CONTENT_NAME.PAYMENT_VIEW,
        });
    });

    return (
        <PaymentContextProvider>
            <Stack.Navigator initialRouteName="PaymentHome" screenOptions={screenOptions}>
                <Stack.Screen
                    name="PaymentHome"
                    getComponent={() => require("./routes/home").PaymentHome}
                    options={{ title: "" }}
                />

                <Stack.Screen
                    name="PaymentProceed"
                    getComponent={() => require("./routes/proceed").PaymentProceed}
                    options={{ title: "", headerShown: false }}
                />
            </Stack.Navigator>
            <PaymentProgressLoader />
            <StoreDeleteErrorSnackbarProvider addBottomMargin addBottomPadding />
        </PaymentContextProvider>
    );
};

const PaymentProgressLoader: React.FC = () => {
    const visible = usePaymentSelector((state) => state.paymentInProgress ?? false);
    return <OverlayLoader visible={visible} />;
};
