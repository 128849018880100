import React from "react";
import { Platform, StyleSheet, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Box, ScreenSize, Stack, useScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { Card } from "@minis-consumer/components/card";
import { Shimmer } from "@minis-consumer/components/shimmer";

import { ItemDivider } from "../../divider";

const CARD_ITEMS_SAVED = Array(2).fill(0);
const CARD_ITEMS_NEW = Array(4).fill(0);

const AccordionShimmer: React.FC = () => {
    const screenSize = useScreenSize();
    return (
        <Box ph={screenSize === ScreenSize.Large ? 0 : SpacingValue["space-medium"]}>
            <Stack spacing={SpacingValue["space-medium"]}>
                <Card>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={SpacingValue["space-medium"]}>
                        <Shimmer height={36} width={36} style={styles.shimmer} />

                        <Stack flex={1} spacing={SpacingValue["space-xx-small"]}>
                            <Shimmer height={16} width={80} style={styles.shimmer} />
                            <Shimmer height={8} width={180} style={styles.shimmer} />
                        </Stack>

                        <Shimmer height={16} width={16} style={styles.shimmer} />
                    </Stack>
                </Card>
            </Stack>
        </Box>
    );
};

const TitleShimmer: React.FC = () => {
    const { value: theme } = useTheme();
    return (
        <Box
            pv={SpacingValue["space-x-small"]}
            mh={SpacingValue["space-medium"]}
            style={[styles.darkShimmer, { backgroundColor: theme["color-basic-15"] }]}
        />
    );
};

const PaymentSectionShimmer: React.FC<{ rowArray: number[] }> = ({ rowArray }) => {
    const screenSize = useScreenSize();

    return (
        <Box ph={screenSize === ScreenSize.Large ? 0 : SpacingValue["space-medium"]}>
            <Stack spacing={SpacingValue["space-medium"]}>
                <Card>
                    {rowArray.map((_, index) => (
                        <React.Fragment key={index}>
                            {index !== 0 ? (
                                <Box mv={SpacingValue["space-medium"]}>
                                    <ItemDivider />
                                </Box>
                            ) : null}

                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={SpacingValue["space-medium"]}>
                                <Shimmer height={36} width={36} style={styles.shimmer} />

                                <Stack flex={1} spacing={SpacingValue["space-xx-small"]}>
                                    <Shimmer height={16} width={80} style={styles.shimmer} />
                                    <Shimmer height={8} width={180} style={styles.shimmer} />
                                </Stack>

                                <Shimmer height={16} width={16} style={styles.shimmer} />
                            </Stack>
                        </React.Fragment>
                    ))}
                </Card>
            </Stack>
        </Box>
    );
};

const LeftShimmerComponent: React.FC = () => {
    const screenSize = useScreenSize();
    const inset = useSafeAreaInsets();

    const paddingTopInsets = Platform.select({
        ios: inset.top - SpacingValue["space-large"],
        web: inset.top + SpacingValue["space-large"] * 2,
        android: inset.top,
    });

    const contentStyle: ViewStyle = {
        paddingTop: paddingTopInsets,
    };

    return (
        <Stack spacing={SpacingValue["space-x-large"]}>
            <Card
                style={[
                    styles.addressCard,
                    screenSize === ScreenSize.Large ? null : styles.addressCardMobile,
                    contentStyle,
                ]}>
                <Stack spacing={SpacingValue["space-medium"]}>
                    <Box ph={SpacingValue["space-medium"]}>
                        <Stack spacing={SpacingValue["space-xx-small"]}>
                            <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                                <Shimmer height={16} width={16} style={styles.shimmer} />
                                <Box flex={1}>
                                    <Shimmer height={16} width="55%" style={styles.shimmer} />
                                </Box>
                            </Stack>

                            <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                                <Shimmer height={16} width={16} style={styles.shimmer} />
                                <Box flex={1}>
                                    <Shimmer height={16} style={styles.shimmer} />
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Card>

            <TitleShimmer />

            <PaymentSectionShimmer rowArray={CARD_ITEMS_SAVED} />

            <TitleShimmer />

            <PaymentSectionShimmer rowArray={CARD_ITEMS_NEW} />

            <AccordionShimmer />

            <AccordionShimmer />
        </Stack>
    );
};

export const LeftShimmer = React.memo(LeftShimmerComponent);

const styles = StyleSheet.create({
    addressCard: {
        paddingHorizontal: 0,
    },
    addressCardMobile: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    darkShimmer: {
        borderRadius: 10,
        width: 250,
    },
    shimmer: {
        borderRadius: 10,
    },
});
