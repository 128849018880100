import * as React from "react";
import { StyleSheet } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { useTheme, Text, SpacingValue } from "@swiggy-private/rn-dls";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

const SectionHeadingComponent: React.FC<{ title: string }> = ({ title }) => {
    const { value: theme } = useTheme();

    return (
        <Box
            direction="row"
            alignItems="center"
            pv={SpacingValue["space-medium"]}
            ph={SpacingValue["space-medium"]}
            style={styles.container}>
            <Text category="b4" weight="medium" style={styles.title}>
                {title}
            </Text>
            <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.line} />
        </Box>
    );
};

export const SectionHeading = React.memo(SectionHeadingComponent);

const styles = StyleSheet.create({
    container: {
        width: "100%",
    },
    title: {
        flexShrink: 0,
        flexGrow: 0,
        flexBasis: "auto",
        paddingRight: SpacingValue["space-medium"],
        letterSpacing: 1.6,
        textTransform: "uppercase",
    },
    line: { marginTop: 0, flex: 1 },
});
