import React from "react";
import { StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { OrderEntity } from "@minis-consumer/resources/order";

import { UserDetail } from "../user-detail";

interface Props {
    order: OrderEntity;
}

const DISPLAY_COPIES = {
    CONTACT_INFORMATION: "Contact Information",
    EMAIL_ADDRESS: "Email Address",
    SHIPPING_ADDRESS: "Shipping Address",
    PAYMENT_METHOD: "Payment Method:",
};

const UserDetailsComponent: React.FC<Props> = ({ order }) => {
    const { value: theme } = useTheme();

    const { paymentMethod, userDetails } = order;

    const containerStyles = {
        backgroundColor: theme["color-basic-0"],
        borderRadius: SpacingValue["space-medium"],
        padding: SpacingValue["space-medium"],
    };

    return (
        <Stack style={containerStyles}>
            <UserDetail
                title={DISPLAY_COPIES.CONTACT_INFORMATION}
                subtitle={userDetails.mobile}
                icon="AccountFilled"
            />

            {userDetails?.email ? (
                <>
                    <DashBorderLine
                        borderColor={theme["color-secondary-400"]}
                        style={styles.borderLine}
                        type="dotted"
                    />
                    <UserDetail
                        title={DISPLAY_COPIES.EMAIL_ADDRESS}
                        subtitle={userDetails.email}
                        icon="AccountFilled"
                    />
                </>
            ) : null}

            {order.dropAddress ? (
                <>
                    <DashBorderLine
                        borderColor={theme["color-secondary-400"]}
                        style={styles.borderLine}
                        type="dotted"
                    />
                    <UserDetail
                        title={DISPLAY_COPIES.SHIPPING_ADDRESS}
                        subtitle={order.dropAddress.formattedAddress}
                        icon="LocationPinOutlineFilled"
                    />
                </>
            ) : null}

            <DashBorderLine
                borderColor={theme["color-secondary-400"]}
                style={styles.borderLine}
                type="dotted"
            />
            <UserDetail
                title={DISPLAY_COPIES.PAYMENT_METHOD}
                subtitle={paymentMethod}
                icon="Payment"
            />
        </Stack>
    );
};

export const UserDetails = React.memo(UserDetailsComponent);

const styles = StyleSheet.create({
    borderLine: {
        marginVertical: SpacingValue["space-medium"],
        opacity: 0.15,
    },
});
