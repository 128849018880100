import React from "react";
import { Pressable, ViewStyle } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/native";

import { shadow, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { RouteList } from "@minis-consumer/interfaces/route";

const EditCTAComponent: React.FC = () => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NavigationProp<RouteList>>();

    const onPress = React.useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
    }, [navigation]);

    const ctaStyles: ViewStyle = {
        borderRadius: 12,
        borderWidth: 1,
        borderColor: theme["color-primary-300"],
        backgroundColor: theme["color-primary-25"],
    };

    const shadowStyles = shadow(1);

    return (
        <Pressable onPress={onPress}>
            <Box
                flex={1}
                style={[ctaStyles, shadowStyles]}
                ph={SpacingValue["space-medium"]}
                pv={SpacingValue["space-medium"]}
                alignItems="center">
                <Text color="color-primary-400" category="btn2" weight="bold">
                    Edit cart
                </Text>
            </Box>
        </Pressable>
    );
};

export const EditCTA = React.memo(EditCTAComponent);
