import React from "react";

import { Text, SpacingValue } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

interface Props {
    progressNumber: number;
    totalProgressNumber: number;
}

const HeaderRightComponent: React.FC<Props> = ({ progressNumber, totalProgressNumber }) => {
    return (
        <Box direction="row" alignItems="center" mr={SpacingValue["space-medium"]}>
            <Text category="h4" color="color-basic-75" weight="bold">
                {progressNumber}
            </Text>
            <Text category="b3" color="color-basic-45" weight="regular">
                {`/${totalProgressNumber}`}
            </Text>
        </Box>
    );
};

export const HeaderRight = React.memo(HeaderRightComponent);
