import React from "react";
import { ViewStyle } from "react-native";
import { useController } from "@rest-hooks/react";

import { Modal } from "@swiggy-private/rn-dls";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { unfollowStore } from "@minis-consumer/api/store";
import { FollowedStores, StoreFollowStatus } from "@minis-consumer/resources/store";
import { ModalBody } from "./modal-body";
import { Logger } from "@minis-consumer/includes/logger";
import { useFollowersCountDispatch } from "@minis-consumer/hooks/use-followers-count-dispatch";

interface Props {
    showModal: boolean;
    closeModal: () => void;
    storeId: string;
    storeName: string;
}

const UnfollowStoreDialogComponent: React.FC<Props> = ({
    showModal,
    closeModal,
    storeId,
    storeName,
}) => {
    const modalMaxWidth = useSelectScreen({
        lg: 340 as ViewStyle["width"],
        default: "90%",
    });

    const { invalidate, receive } = useController();

    const followesCountDispatch = useFollowersCountDispatch();

    const onPressUnfollow = React.useCallback(() => {
        unfollowStore({ storeId })
            .then(({ storeFollowersCount }) => {
                followesCountDispatch({
                    payload: {
                        storeId: storeId,
                        followersCount: storeFollowersCount,
                    },
                    type: "UPDATE_FOLLOWERS_COUNT_ACTION",
                });

                receive(StoreFollowStatus, { storeId }, { follow: false });

                invalidate(FollowedStores).catch((err) => {
                    Logger.recordError(err);
                });
            })
            .catch((err) => {
                Logger.recordError(err);
            });

        closeModal();
    }, [closeModal, followesCountDispatch, invalidate, receive, storeId]);

    return (
        <Modal
            showModal={showModal}
            setShowModal={closeModal}
            modalStyles={{
                modalBodyStyle: {
                    maxWidth: modalMaxWidth,
                },
            }}
            component={
                <ModalBody
                    onPressCancel={closeModal}
                    onPressRemove={onPressUnfollow}
                    storeName={storeName}
                />
            }
        />
    );
};

export const UnfollowStoreDialog = React.memo(UnfollowStoreDialogComponent);
