import React from "react";
import { StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";

const ShopOnlineStatusComponent: React.FC = () => {
    const storeInfo = useStoreInfo();
    const { value: theme } = useTheme();

    const shopOnline = React.useMemo(() => storeInfo.settings.online, [storeInfo.settings.online]);

    if (shopOnline) {
        return null;
    }

    return (
        <Stack
            style={[
                styles.storeOnlineContainer,
                {
                    borderColor: theme["color-critical-400"],
                    backgroundColor: theme["color-critical-25"],
                },
            ]}
            alignItems="center"
            justifyContent="center"
            direction="row"
            spacing={SpacingValue["space-x-small"]}>
            <SvgIcon icon="AlertCircle" color="color-critical-400" width={14} height={14} />
            <Text category="b3" color="color-critical-400" weight="medium">
                Currently not taking orders
            </Text>
        </Stack>
    );
};

if (__DEV__) {
    ShopOnlineStatusComponent.displayName = "ShopOnlineStatusComponent";
}

export const ShopOnlineStatus = React.memo(ShopOnlineStatusComponent);

const styles = StyleSheet.create({
    storeOnlineContainer: {
        marginTop: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-x-small"],
        borderWidth: 1,
        borderRadius: 24,
    },
});
