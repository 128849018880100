/** Minor comments will be taken up in the final PRs with identifiers and analytics */
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Shimmer } from "@swiggy-private/react-native-ui";
import { useTheme } from "@swiggy-private/rn-dls";

import { PincodeForm } from "@minis-consumer/components/pincode-form";
import {
    SelectedAddressInfo,
    SelectedAddressInfoContext,
} from "@minis-consumer/contexts/selected-address-context";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useSignedIn } from "@minis-consumer/hooks/use-user";
import { UserAddress } from "@minis-consumer/interfaces/cart";
import { useDeliveryEta } from "@minis-consumer/hooks/use-delivery-eta";
import { AddressList } from "@minis-consumer/routes/cart/components/address-list";
import { Analytics } from "@minis-consumer/analytics";
import { getDeliveryEtaApiParams } from "@minis-consumer/routes/cart/helpers";
import { ShopDeliveryDetails } from "@minis-consumer/routes/shop/components/delivery";
import { ShopOnlineStatus } from "@minis-consumer/routes/shop/ux2.0/components/shop-online-status";
import { useStoreDeliveryInfoSnackbarHandler } from "@minis-consumer/hooks/use-store-delivery-info-snack-handler";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";

import { COPIES, ICON_SIZE, API_FETCHING_ERROR } from "./constants";
import { AddressLabelPrefix } from "./address-label-prefix";
import { getDisplayTextAndStyles } from "./helpers";

const ANALYTICS_COPIES = {
    SELECT_SAVED_ADDRESS: "select-saved-address",
    CLICK_ADDRESS: "click-address",
};

type IProps = { setDeliveryInfoOffset?: (offset: number) => void };

export const DeliveryInfoActions: React.FC<IProps> = ({ setDeliveryInfoOffset }) => {
    const isSignedIn = useSignedIn();
    const storeInfo = useStoreInfo();
    const selectedAddressCtx = useContext(SelectedAddressInfoContext);
    const { fetchDeliveryEta, deliveryInfo, isLoading } = useDeliveryEta();

    const { setDeliveryInfo } = useStoreDeliveryInfoSnackbarHandler();
    const { value: theme } = useTheme();

    const [showAddressListModal, setShowAddressListModal] = React.useState(false);
    const brandColor = theme["color-primary"];

    const addressLabelText = useMemo(() => {
        const selectedAddress = selectedAddressCtx?.selectedAddressInfo;
        const selectedAddressPostalCode = selectedAddress?.postalCode;
        const addressLabel = selectedAddress?.addressLabel;

        return selectedAddressPostalCode
            ? `${addressLabel ? `to ${addressLabel} - ` : "to - "}${selectedAddressPostalCode}`
            : COPIES.GET_DEL_INFO;
    }, [selectedAddressCtx?.selectedAddressInfo]);

    const handlePincodeSubmit = useCallback(
        async (pincode?: string) => {
            const params = getDeliveryEtaApiParams({
                storeId: storeInfo?.storeId,
                dropPincode: pincode,
                pickupAddress: storeInfo?.address,
            });

            fetchDeliveryEta(params);

            setShowAddressListModal(false);
        },
        [fetchDeliveryEta, storeInfo?.address, storeInfo?.storeId],
    );

    const onSelectAddress = useCallback(
        (address: UserAddress | null) => {
            Analytics.clickEvent({
                category: ANALYTICS_COPIES.SELECT_SAVED_ADDRESS,
                context: JSON.stringify({
                    store_id: storeInfo?.storeId,
                }),
            });

            if (address) {
                selectedAddressCtx?.updateSelectedAddressInfo?.({
                    ...selectedAddressCtx.selectedAddressInfo,
                    lng: address?.lng,
                    lat: address?.lat,
                    postalCode: address?.postalCode,
                    addressId: address?.id,
                    addressLabel: address?.displayName,
                });
            }
        },
        [selectedAddressCtx, storeInfo?.storeId],
    );
    const footerRef = React.useRef<View>(null);

    const onLayout = (): void => {
        if (!footerRef.current) {
            return;
        }
        // eslint-disable-next-line max-params
        footerRef.current.measure((_x, _y, _width, _height, _pageX, pageY) => {
            setDeliveryInfoOffset?.(pageY ?? 0);
        });
    };

    const fetchDeliveryEtaInfo = useCallback(
        async (selectedAddr?: SelectedAddressInfo) => {
            const params = getDeliveryEtaApiParams({
                storeId: storeInfo?.storeId,
                selectedAddress: selectedAddr,
                pickupAddress: storeInfo?.address,
            });

            fetchDeliveryEta(params);
        },
        [fetchDeliveryEta, storeInfo?.address, storeInfo?.storeId],
    );

    const addressId = selectedAddressCtx?.selectedAddressInfo?.addressId;
    const deliveryEta = selectedAddressCtx?.selectedAddressInfo?.deliveryEta;
    const pincode = selectedAddressCtx?.selectedAddressInfo?.postalCode;

    const deliveryText: string | null = React.useMemo(
        () =>
            getDisplayTextAndStyles({
                addressId,
                postalCode: pincode,
                deliveryEta,
                storeName: storeInfo?.name,
                hasError: Boolean(deliveryInfo?.error),
                serviceabilityType: deliveryInfo?.serviceabilityType,
            }).text,
        [
            addressId,
            deliveryEta,
            storeInfo?.name,
            deliveryInfo?.error,
            pincode,
            deliveryInfo?.serviceabilityType,
        ],
    );

    const shopOnline = React.useMemo(() => storeInfo.settings.online, [storeInfo.settings.online]);

    const anlayticsData = useMemo(() => {
        return {
            category: SF_ANALYTICS_EVENT_NAMES.DELIVER_INFO,
            context: JSON.stringify({
                addressId,
                pincode,
                deliveryText,
                isDelivering: deliveryEta,
                isTakingOrders: shopOnline,
            }),
        };
    }, [addressId, pincode, deliveryText, shopOnline, deliveryEta]);

    useEffect(() => {
        const selectedAddr = selectedAddressCtx?.selectedAddressInfo;

        if (selectedAddr?.postalCode) {
            fetchDeliveryEtaInfo(selectedAddr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddressCtx?.selectedAddressInfo?.postalCode]);

    useEffect(() => {
        setDeliveryInfo?.(deliveryInfo);
    }, [deliveryInfo, setDeliveryInfo]);

    const handleAddressPickerClick = useCallback(() => {
        Analytics.clickEvent(anlayticsData);

        setShowAddressListModal(true);
    }, [anlayticsData]);

    useEffect(() => {
        anlayticsData && Analytics.impressionEvent(anlayticsData);
    }, [anlayticsData]);

    return (
        <>
            {shopOnline && storeInfo.address ? (
                <>
                    {storeInfo?.deliveryInfoViewEnabled ? (
                        <View
                            onLayout={onLayout}
                            ref={footerRef}
                            collapsable={false}
                            testID={TEST_IDS_SF.DELIVERY_INFO_VIEW}>
                            {isLoading ? (
                                <Stack style={styles.loadingWrapper}>
                                    <Shimmer
                                        style={[styles.headerSlate, styles.shimmer]}
                                        width="100%"
                                        height={16}
                                    />
                                    <Shimmer
                                        style={[styles.headerSlate, styles.shimmer]}
                                        width="60%"
                                        height={16}
                                    />
                                </Stack>
                            ) : (
                                <Pressable onPress={handleAddressPickerClick}>
                                    <Stack direction="row" spacing={SpacingValue["space-xx-small"]}>
                                        <Box mt={SpacingValue["space-xxx-small"]}>
                                            <SvgIcon
                                                icon="LocationPinOutlineFilled"
                                                height={ICON_SIZE}
                                                width={ICON_SIZE}
                                                color={brandColor}
                                            />
                                        </Box>

                                        <Stack
                                            spacing={SpacingValue["space-xx-small"]}
                                            style={styles.textContainer}>
                                            <AddressLabelPrefix
                                                hasError={!!deliveryInfo?.error}
                                                serviceabilityType={
                                                    deliveryInfo?.serviceabilityType
                                                }
                                            />

                                            <Stack
                                                spacing={SpacingValue["space-xxx-small"]}
                                                direction="row">
                                                <Text
                                                    category="b2"
                                                    weight="bold"
                                                    color="color-primary"
                                                    style={styles.addressLabel}>
                                                    {addressLabelText}
                                                </Text>

                                                <Box style={styles.caret}>
                                                    <SvgIcon
                                                        icon="ChevronRight"
                                                        color={brandColor}
                                                        height={ICON_SIZE}
                                                        width={ICON_SIZE}
                                                    />
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </Stack>

                                    {deliveryInfo?.error ? (
                                        <Text
                                            style={styles.apiError}
                                            category="b3"
                                            color="color-critical-300">
                                            {API_FETCHING_ERROR}
                                        </Text>
                                    ) : null}
                                </Pressable>
                            )}
                        </View>
                    ) : (
                        <ShopDeliveryDetails showTitle={false} />
                    )}
                </>
            ) : null}

            <ShopOnlineStatus />

            {storeInfo?.deliveryInfoViewEnabled ? (
                <>
                    {showAddressListModal && !isSignedIn ? (
                        <PincodeForm
                            canOpen={showAddressListModal}
                            setOpen={setShowAddressListModal}
                            handlePincodeSubmit={handlePincodeSubmit}
                        />
                    ) : null}

                    {showAddressListModal && isSignedIn ? (
                        <AddressList
                            storeId={storeInfo.storeId}
                            open={showAddressListModal}
                            setOpen={setShowAddressListModal}
                            setAddressSelected={onSelectAddress}
                            shouldShowBanner={false}
                            fetchAddressesFromAddressApi={true}
                            showAddressInModal
                            shouldShowUnserviceable={false}
                        />
                    ) : null}
                </>
            ) : null}
        </>
    );
};

const styles = StyleSheet.create({
    headerSlate: {
        borderRadius: 4,
    },
    loadingWrapper: {
        marginTop: SpacingValue["space-xx-small"],
    },
    shimmer: {
        marginTop: SpacingValue["space-xxx-small"],
    },
    textContainer: {
        flexShrink: 1,
    },
    addressLabel: {
        display: "flex",
        alignItems: "center",
    },
    apiError: {
        marginTop: SpacingValue["space-xx-small"],
    },
    caret: {
        alignItems: "center",
        justifyContent: "center",
    },
});
