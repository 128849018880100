import * as React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";

export const SavedAddressesEmptyState: React.FC = () => {
    return (
        <Box alignItems="center" justifyContent="center" flex={1}>
            <CdnImage
                isImageKitEnabled
                id="minis/saved_addresses_empty_state"
                height={246}
                width={246}
            />
            <Stack spacing={SpacingValue["space-x-small"]} alignItems="center">
                <Text category="h4" color="color-basic-75">
                    No saved addresses
                </Text>
                <Text category="s3" color="color-basic-60" style={styles.text}>
                    Your saved addresses will appear here and you can access it when you place an
                    order.
                </Text>
            </Stack>
        </Box>
    );
};

const styles = StyleSheet.create({
    text: { textAlign: "center", alignSelf: "stretch" },
});
