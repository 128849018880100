import React from "react";
import { StyleSheet } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { Card } from "@minis-consumer/components/card";
import { Shimmer } from "@minis-consumer/components/shimmer";
import { ItemDivider } from "../../divider";

const RightShimmerComponent: React.FC = () => {
    return (
        <Stack spacing={SpacingValue["space-medium"]}>
            <Card>
                <Stack spacing={SpacingValue["space-medium"]}>
                    <Stack spacing={SpacingValue["space-x-small"]}>
                        <Shimmer height={24} width="50%" style={styles.shimmer} />
                        <Box direction="row" justifyContent="space-between">
                            <Shimmer height={16} width="50%" style={styles.shimmer} />
                            <Shimmer height={16} width="20%" style={styles.shimmer} />
                        </Box>
                    </Stack>
                    <ItemDivider />
                    <Box direction="row" justifyContent="space-between">
                        <Shimmer height={16} width="40%" style={styles.shimmer} />
                        <Shimmer height={16} width="20%" style={styles.shimmer} />
                    </Box>
                    <ItemDivider />
                    <Box direction="row" justifyContent="space-between">
                        <Shimmer height={16} width="60%" style={styles.shimmer} />
                        <Shimmer height={16} width="20%" style={styles.shimmer} />
                    </Box>
                </Stack>
            </Card>

            <Card>
                <Stack spacing={SpacingValue["space-medium"]}>
                    <Shimmer height={24} width="50%" style={styles.shimmer} />

                    <Stack spacing={SpacingValue["space-x-small"]}>
                        <Stack spacing={SpacingValue["space-x-small"]} direction="row">
                            <Shimmer height={10} width={10} style={styles.shimmer} />
                            <Box flex={1}>
                                <Shimmer height={10} width="95%" style={styles.shimmer} />
                            </Box>
                        </Stack>

                        <Stack spacing={SpacingValue["space-x-small"]} direction="row">
                            <Shimmer height={10} width={10} style={styles.shimmer} />
                            <Box flex={1}>
                                <Shimmer height={10} width="90%" style={styles.shimmer} />
                            </Box>
                        </Stack>

                        <Stack spacing={SpacingValue["space-x-small"]} direction="row">
                            <Shimmer height={10} width={10} style={styles.shimmer} />
                            <Box flex={1}>
                                <Shimmer height={10} width="92%" style={styles.shimmer} />
                            </Box>
                        </Stack>

                        <Stack spacing={SpacingValue["space-x-small"]} direction="row">
                            <Shimmer height={10} width={10} style={styles.shimmer} />
                            <Box flex={1}>
                                <Shimmer height={10} width="85%" style={styles.shimmer} />
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
            </Card>
        </Stack>
    );
};

export const RightShimmer = React.memo(RightShimmerComponent);

const styles = StyleSheet.create({
    shimmer: {
        borderRadius: 10,
    },
});
