export const ORDER_TRACKING_CTA_TEXT = {
    Track: "Tracking details",
    RetryPayment: "Retry",
    Reorder: "Reorder",
    Cancel: "Cancel order",
};

export const CANCEL_ORDER = {
    CTA_TITLE: "Cancel order",
    MODAL: {
        TITLE: "Cancel this order?",
        DESCRIPTION: "You will receive a full refund since the seller is yet to confirm this order",
        PRIMARY_CTA: "Cancel order",
        SECONDARY_CTA: "Dismiss",
    },
};
