import React from "react";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import { Order } from "@minis-consumer/interfaces/order";
import { useRatingAndReviewHook } from "@minis-consumer/hooks/use-rating-and-review";

interface Props {
    orderId: Order["id"];
    orderStatus: Order["status"];
    rating: number;
    storeName: Order["storeDetails"]["name"];
    storeId: Order["storeDetails"]["id"];
}

const RatingContainerComponent: React.FC<Props> = ({
    orderId,
    orderStatus,
    rating: ratingProp,
    storeName,
    storeId,
}) => {
    const rated = Math.min(ratingProp, 5);
    const unrated = 5 - rated;

    const ratedArray = new Array(rated).fill(null);
    const unratedArray = new Array(unrated).fill(null);

    const { ratingAndReviewNavigtaionHandler } = useRatingAndReviewHook({
        orderId,
        storeId,
    });

    useMount(() => {
        Analytics.impressionEvent({
            category: "past-order-rating-bar",
            label: orderId,
            context: JSON.stringify({ rating: ratingProp, status: orderStatus }),
        });
    });

    const onAddRating = React.useCallback(
        async (ratingGiven: number) => {
            Analytics.clickEvent({
                category: "past-order-rating-bar",
                label: orderId,
                context: JSON.stringify({ rating: ratingGiven, status: orderStatus }),
            });

            ratingAndReviewNavigtaionHandler(ratingGiven);
        },
        [orderId, orderStatus, ratingAndReviewNavigtaionHandler],
    );

    if (rated) {
        return (
            <Stack direction="row" spacing={SpacingValue["space-x-small"]} alignItems="center">
                <Text category="b2" color="color-basic-60">
                    You rated:
                </Text>

                <Stack spacing={6} direction="row">
                    {ratedArray.map((_, index) => (
                        <SvgIcon key={index} icon="StarFilled" color="color-warning-400" />
                    ))}

                    {unratedArray.map((_, index) => (
                        <SvgIcon key={index} icon="Star" color="color-basic-15" />
                    ))}
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack spacing={SpacingValue["space-small"]} alignItems="center">
            <Text category="b2" color="color-basic-60">
                How was the order from {storeName}?
            </Text>

            <Stack direction="row" spacing={SpacingValue["space-small"]}>
                {ratedArray.map((_, index) => (
                    <SvgIcon
                        key={index}
                        icon="StarFilled"
                        height={32}
                        width={32}
                        color="color-warning-25"
                    />
                ))}

                {unratedArray.map((_, index) => (
                    <SvgIcon
                        key={index}
                        icon="StarFilled"
                        height={32}
                        width={32}
                        color="color-basic-15"
                        onPress={() => onAddRating(index + 1)}
                    />
                ))}
            </Stack>
        </Stack>
    );
};

export const RatingContainer = React.memo(RatingContainerComponent);
