import * as React from "react";
import { Pressable, StyleSheet } from "react-native";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { findAndScrollToElement } from "@minis-consumer/helpers/dom";
import { ScrollContext } from "@minis-consumer/contexts/scroll-context";
import { NAVBAR_HEIGHT } from "@minis-consumer/constants";

interface SidebarCatalogIndexProps {
    index: Array<{ id: string; name: string }>;
}

export const SidebarCatalogIndex: React.FC<SidebarCatalogIndexProps> = ({ index }) => {
    const scrollContext = React.useContext(ScrollContext);
    const [selectedIndexId, setSelectedIndexId] = React.useState<string | undefined>(index[0].id);

    const onIndexPress = React.useCallback(
        (id: string) => {
            setSelectedIndexId(id);
            findAndScrollToElement({
                id,
                scrollView: scrollContext?.current || null,
                headerOffset: NAVBAR_HEIGHT,
            });
        },
        [scrollContext],
    );

    return (
        <Stack
            style={styles.container}
            spacing={SpacingValue["space-medium"]}
            accessibilityRole="menubar">
            {index.map((i) => (
                <Pressable
                    key={i.id}
                    accessible
                    accessibilityRole="menuitem"
                    accessibilityLabel={i.name}
                    onPress={() => onIndexPress(i.id)}
                    style={styles.textContainer}>
                    <Text
                        category={selectedIndexId === i.id ? "b2" : "b3"}
                        weight={selectedIndexId === i.id ? "bold" : "regular"}
                        color={selectedIndexId === i.id ? "primary" : "highest"}
                        numberOfLines={1}>
                        {i.name}
                    </Text>
                </Pressable>
            ))}
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: {
        marginLeft: SpacingValue["space-large"],
        marginRight: SpacingValue["space-large"],
        width: 140,
    },
    textContainer: {
        width: "100%",
        overflow: "hidden",
        alignItems: "flex-end",
    },
});
