import * as React from "react";
import { StyleSheet } from "react-native";

import { Box, Display, ScreenSize, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

import { ShopBackground } from "@minis-consumer/routes/shop/components/background";
import { ShopDetails } from "@minis-consumer/routes/shop/components/details";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { BLAZE_EVENTS, recordAppLoadTime } from "@minis-consumer/includes/blaze";

import { HEADER_HEIGHT } from "../../constants";

type IProps = { setDeliveryInfoOffset: (offset: number) => void };

const ShopFrontComponent: React.FC<IProps> = (props) => {
    const storeInfo = useStoreInfo();

    const isStoreEnabled = storeInfo.enabled !== false;

    const marginTop = isStoreEnabled ? HEADER_HEIGHT + 92 : SpacingValue["space-x-small"];
    const imageId = storeInfo.settings.backgroundImageId;

    useMount(() => {
        recordAppLoadTime(BLAZE_EVENTS.LOAD_TIME.STORE_CARD);
    });

    return (
        <>
            <Display gt={ScreenSize.Medium}>
                <>
                    <ShopBackground imageId={imageId} isStoreEnabled={isStoreEnabled} />
                    <ShopDetails />
                </>
            </Display>

            <Display lt={ScreenSize.Normal}>
                <Box>
                    <ShopBackground isStoreEnabled={isStoreEnabled} imageId={imageId}>
                        <Stack style={[styles.detailsContainer, { marginTop }]}>
                            <ShopDetails setDeliveryInfoOffset={props.setDeliveryInfoOffset} />
                        </Stack>
                    </ShopBackground>
                </Box>
            </Display>
        </>
    );
};

const styles = StyleSheet.create({
    detailsContainer: {
        marginBottom: SpacingValue["space-medium"],
    },
});

export const ShopFront = React.memo(ShopFrontComponent);
