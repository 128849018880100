import React from "react";
import { StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { Shimmer } from "@minis-consumer/components/shimmer";

export const DeliveryInfoPlaceholder: React.FC = () => {
    return (
        <Stack style={styles.loadingWrapper} testID="delivery-info-placeholder">
            <Shimmer style={styles.shimmer} width="90%" height={20} />
            <Shimmer style={styles.shimmer} width="80%" height={20} />
            <Shimmer style={styles.shimmer} width="90%" height={20} />
        </Stack>
    );
};

const styles = StyleSheet.create({
    loadingWrapper: {
        marginTop: SpacingValue["space-xx-small"],
    },
    shimmer: {
        marginTop: SpacingValue["space-x-small"] + SpacingValue["space-xxx-small"],
        borderRadius: 4,
    },
});
