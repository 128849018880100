/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from "react";
import { Platform } from "react-native";
import { NavigationContainer, useNavigationContainerRef } from "@react-navigation/native";

import { useBackListener } from "@swiggy-private/react-native-ui";

import { Navbar } from "@minis-consumer/components/navbar";
import { useIsNavbarVisible } from "@minis-consumer/hooks/use-is-navbar-visible";
import { useNavigationTheme } from "@minis-consumer/hooks/use-navigation-theme";
import { useScreenOptions } from "@minis-consumer/hooks/use-screen-options";
import { useSignedIn } from "@minis-consumer/hooks/use-user";
import { createNavigator } from "@minis-consumer/helpers/navigation";
import { Analytics } from "@minis-consumer/analytics";
import { SwiggyRNModule } from "@minis-consumer/includes/swiggy-rn-module";
import { StoreNavigatorId } from "@minis-consumer/constants/navigation";
import { AuthProvider } from "@minis-consumer/containers/auth-provider";
import { useMinisNavigationBack } from "@minis-consumer/hooks/use-minis-navigation";
import type { RouteList, MinisRouteList } from "@minis-consumer/interfaces/route";
import { useStorePageTitle } from "@minis-consumer/hooks/use-store";
import { BLAZE_EVENTS, recordAppLoadTime, startCustomEvent } from "@minis-consumer/includes/blaze";
import { ProductEnquiryProvider } from "@minis-consumer/containers/product-enquiry-provider";
import { RatingAndReviewProvider } from "@minis-consumer/containers/rating-and-review-provider";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";
import { ChatBlockProvider } from "@minis-consumer/containers/chat-block-provider";
import { ChatBlockedErrorSnackbarProvider } from "@minis-consumer/containers/chat-blocked-snack-provider";
import { StoryEnquiryProvider } from "@minis-consumer/containers/view-story-provider";
import { ClearCartProvider } from "@minis-consumer/containers/clear-cart-provider";

import { storeRouteLinking } from "./store-linking";
import { CommonRoutes, CommonStackNavigator } from "./common-routes";

const Stack = createNavigator<RouteList>();

const isWeb = Platform.OS === "web";

interface StoreNavigationRoutesProps {
    onReady?: () => void;
    initialPath?: string;
    independent?: boolean;
}

interface IRouteParams extends Object {
    storeId?: string;
}

export const StoreNavigationRoutes: React.FC<
    React.PropsWithChildren<StoreNavigationRoutesProps>
> = ({ onReady, independent, initialPath, children }) => {
    const showNavbar = useIsNavbarVisible();
    const isDWeb = useIsDesktopWeb();

    const navigationContainerRef = useNavigationContainerRef<
        RouteList & MinisRouteList["MiniStore"]
    >();

    const swiggyRNModule = SwiggyRNModule.getInstance();

    const theme = useNavigationTheme();
    const screenOptions = useScreenOptions(
        React.useMemo(
            () => ({
                headerShown: showNavbar === false,
            }),
            [showNavbar],
        ),
    );

    const signedIn = useSignedIn();
    const pageTitle = useStorePageTitle();
    const [, minisNavigationBackHandler] = useMinisNavigationBack();

    const [screenName, setScreenName] = React.useState<string | undefined>(undefined);

    const onScreenChange = React.useCallback(
        (updateScreenOnly = false): void => {
            const currentRouteName = navigationContainerRef.getCurrentRoute()?.name;
            setScreenName(currentRouteName);
            const currentRouteParams: IRouteParams =
                navigationContainerRef.getCurrentRoute()?.params ?? "";

            if (updateScreenOnly) {
                Analytics.updateScreenNameOnly(currentRouteName);
                return;
            }

            Analytics.setCurrentScreen({
                screen: currentRouteName,
                context: JSON.stringify(currentRouteParams),
            });

            swiggyRNModule.setScreenNameForPerf(currentRouteName);
        },
        [navigationContainerRef, swiggyRNModule],
    );

    const linking = React.useMemo(() => storeRouteLinking(initialPath), [initialPath]);
    const state = initialPath ? linking.getStateFromPath?.(initialPath, linking.config) : undefined;

    // * Fix : Hardware-Back issue, if lands directly on Store-pages
    const checkHardWarePress = React.useCallback(() => {
        const currentRouteName = navigationContainerRef.getCurrentRoute()?.name;
        setScreenName(currentRouteName);
        if (currentRouteName === "Shop") {
            minisNavigationBackHandler();
            return true;
        } else if (!navigationContainerRef?.canGoBack()) {
            navigationContainerRef.reset({
                index: 0,
                routes: [{ name: "Home" }],
            });

            return true;
        }

        return false;
    }, [navigationContainerRef, minisNavigationBackHandler]);

    useBackListener(checkHardWarePress);

    return (
        <NavigationContainer
            linking={independent ? undefined : linking}
            ref={navigationContainerRef}
            theme={theme}
            initialState={state}
            onReady={() => {
                onScreenChange(true);
                onReady?.();
                recordAppLoadTime(BLAZE_EVENTS.LOAD_TIME.APP);
                startCustomEvent(BLAZE_EVENTS.LOAD_TIME.STORE_FRONT);
            }}
            documentTitle={{
                formatter: (_, route) => pageTitle(route?.name),
            }}
            onStateChange={() => {
                onScreenChange();
            }}
            independent={independent}>
            <>
                {isDWeb ? <RatingAndReviewProvider /> : null}
                {showNavbar ? <Navbar position="absolute" navRef={navigationContainerRef} /> : null}
                <AuthProvider />
                {isWeb ? <ProductEnquiryProvider /> : null}
                {isWeb ? <ChatBlockProvider /> : null}
                {isWeb ? <ClearCartProvider /> : null}
                {isWeb ? <ChatBlockedErrorSnackbarProvider /> : null}
                {isWeb ? <StoryEnquiryProvider /> : null}
                {children}
            </>

            <Stack.Navigator screenOptions={screenOptions} id={StoreNavigatorId}>
                <Stack.Group>
                    <Stack.Screen
                        name="Home"
                        getComponent={() => require("./home").HomeTabView}
                        options={{
                            headerShown: false,
                        }}
                        initialParams={{ path: initialPath }}
                    />

                    <Stack.Screen
                        name="StoreCatalogSearch"
                        getComponent={() =>
                            require("./store-catalog-search").StoreCatalogSearchScreen
                        }
                        options={{
                            headerShown: true,
                        }}
                    />

                    <Stack.Screen
                        name="UpdateAddress"
                        getComponent={() => require("./update-address").UpdateAddressScreen}
                        options={{
                            presentation: "fullScreenModal",
                            animation: "slide_from_bottom",
                        }}
                    />
                </Stack.Group>

                {CommonRoutes({
                    Stack: Stack as CommonStackNavigator,
                    showNavbar,
                    signedIn,
                    context: screenName,
                })}
            </Stack.Navigator>
        </NavigationContainer>
    );
};
