import React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { Box, Container, Display, ScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { Modal, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";

import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { AddressRouteList } from "@minis-consumer/interfaces/route";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import { AddMapPointer } from "./components/add-map-pointer";
import { HeaderRight } from "../../components/header-right";
import { HeaderLeft } from "../../components/header-left";

type Props = NativeStackScreenProps<AddressRouteList, "Map">;

export const AddNewAddressMap: React.FC<Props> = ({ route, navigation }) => {
    const { params } = route;
    const storeInfo = useStoreInfo(false);

    const [showModal, setShowModal] = React.useState(true);

    const goBack = React.useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
    }, [navigation]);

    const navigateToAddressFormScreen = React.useCallback(() => {
        navigation.navigate("AddressForm", {
            storeId: params?.storeId || storeInfo?.storeId,
        });
    }, [navigation, params?.storeId, storeInfo?.storeId]);

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerTitle: "",
            title: "",
            headerLeft: () => <HeaderLeft onPressBack={goBack} title="Add your delivery address" />,
            headerRight: () => <HeaderRight progressNumber={2} totalProgressNumber={3} />,
        });
    }, [goBack, navigation]);

    return (
        <StateContextContainer>
            <Display gt={ScreenSize.Large}>
                <Container fluid={false} disableGutters>
                    <Modal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        dismissOnPressingBackdrop={false}
                        modalStyles={{
                            modalBodyStyle: {
                                maxWidth: 600,
                                width: 600,
                                height: "66%",
                                paddingBottom: SpacingValue["space-x-large"],
                            },
                            backdropStyle: {
                                backgroundColor: "transparent",
                            },
                        }}
                        component={
                            <Box flex={1}>
                                <Box
                                    direction="row"
                                    ml={0}
                                    style={{
                                        marginLeft: SpacingValue["space-medium"],
                                        paddingVertical: SpacingValue["space-xx-small"],
                                    }}>
                                    <SvgIcon icon="ArrowLeft" onPress={goBack} />
                                    <Text
                                        category="h5"
                                        weight="bold"
                                        color="high"
                                        style={{
                                            marginBottom: SpacingValue["space-x-large"],
                                            marginLeft: SpacingValue["space-medium"],
                                        }}>
                                        Add your delivery address
                                    </Text>
                                </Box>
                                <Box flex={1} ph={SpacingValue["space-xx-large"]}>
                                    <AddMapPointer
                                        onPressCTA={navigateToAddressFormScreen}
                                        onSearchPress={goBack}
                                    />
                                </Box>
                            </Box>
                        }
                    />
                </Container>
            </Display>

            <Display lt={ScreenSize.Medium}>
                <Container fluid={false} disableGutters>
                    <AddMapPointer
                        onPressCTA={navigateToAddressFormScreen}
                        onSearchPress={goBack}
                    />
                </Container>
            </Display>
        </StateContextContainer>
    );
};
