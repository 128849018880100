/**
 * ToDo:
 * 1. Break this file into modualar components
 * 2. Keep check driven props at one place
 *
 * Can't be taken today due to old code and time constraint whose overhaul will require bandwidth from dev & QA
 */
import React from "react";
import {
    ImageStyle,
    Platform,
    Pressable,
    StyleSheet,
    useWindowDimensions,
    ViewStyle,
} from "react-native";
import LinearGradient from "react-native-linear-gradient";

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";
import { StoreMedia } from "@swiggy-private/react-native-ui";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { ProductVariantValuesLabel } from "@minis-consumer/components/variants";
import {
    META_PIXEL_CONTENT_CATEGORY,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";
import { useLocalCartItem, useLocalCartItemDelete } from "@minis-consumer/hooks/use-cart";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";
import { useUserInfo } from "@minis-consumer/hooks/use-user";
import { CommonRouteList } from "@minis-consumer/interfaces/route";
import { useGetProductCtaProps } from "@minis-consumer/components/product-cta/hooks/use-get-cta-actions";
import { ProductCta } from "@minis-consumer/components/product-cta";
import { Product } from "@minis-consumer/interfaces/catalog";
import { CartStatuses, LocalCartItem } from "@minis-consumer/interfaces/cart";
import { getMediaUrl } from "@minis-consumer/helpers/media";
import { getProductFallbackImages } from "@minis-consumer/helpers/product-fallback-images";

import { DeleteProductConfirmationDialog } from "../../../delete-product-confirmation";
import { DEFAULTS } from "./constants";
import { SelectedSlotData } from "../selected-slot";
import { CART_V2_ERRORS_STATUS, CTA_ICON_SIZE } from "../../constants";

const isWeb = Platform.OS === "web";

interface ItemsProps {
    storeId: string;
    id: string;
    name: string;
    price: number;
    type: Product["productType"];
    isCustom: boolean;
    quantity: number;
    storeSlug: string;
    cartId?: string;
    variantId?: string;
    variants?: string[];
    inStock?: boolean;
    active?: boolean;
    style?: ViewStyle;
    discountedPrice?: number;
    productImageId?: string;
    isChanged?: boolean;
    itemForm?: LocalCartItem["itemForm"];
    slot?: LocalCartItem["slot"];
    productDuration?: Product["productDuration"];
    itemStatus?: CartStatuses;
}

const ItemComponent: React.FC<ItemsProps> = ({
    storeId,
    id,
    name,
    price,
    type,
    isCustom,
    discountedPrice,
    quantity,
    inStock,
    style,
    cartId,
    productImageId,
    storeSlug,
    variants,
    variantId,
    isChanged,
    itemForm,
    slot,
    productDuration,
    itemStatus,
}) => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NativeStackNavigationProp<CommonRouteList>>();
    const userInfo = useUserInfo();
    const isDesktop = useIsDesktop();

    const { width: windowWidth } = useWindowDimensions();

    const [showDeleteProductConfirmationModal, setDeleteConfirmationModal] = React.useState(false);

    const [itemToDelete, setItemToDelete] = React.useState("");

    const itemFormExist = !!itemForm && itemForm.length > 0;

    const hideDeleteDialog = React.useCallback(() => {
        setDeleteConfirmationModal(false);
    }, []);

    const strikeOffPriceStyle = {
        color: theme["color-basic-45"],
    };

    const productImgSize: number = useSelectScreen({
        default: 0.3 * windowWidth,
        lg: DEFAULTS.DESKTOP_IMG_SIZE,
    });

    const productImgExtraStyles: ImageStyle = {
        borderColor: theme["color-basic-15"],
        borderRadius: isDesktop ? 8 : 12,
        width: productImgSize,
        height: productImgSize,
    };

    const formattedPriceValue = React.useMemo(
        () => formatNumberWithIndianSystem(price * quantity, { showRupeeSymbol: true }),
        [price, quantity],
    );

    const isUnavailableOrExpiredAppointment = React.useMemo(
        () =>
            type === "APPOINTMENT" &&
            (itemStatus === "ITEM_SLOT_UNAVAILABLE" || itemStatus === "ITEM_SLOT_EXPIRED"),
        [itemStatus, type],
    );

    const isUnavailableNonCustomItem = React.useMemo(
        () => (!isChanged && !inStock && !isCustom) || isUnavailableOrExpiredAppointment,
        [inStock, isChanged, isCustom, isUnavailableOrExpiredAppointment],
    );

    const addBtnStyle: ViewStyle = {
        minHeight: isDesktop ? 40 : 32,
        height: isDesktop ? 40 : 32,
        minWidth: isUnavailableNonCustomItem
            ? DEFAULTS.UNAVAILABLE_COUNTER_WIDTH
            : DEFAULTS.COUNTER_WIDTH,
        width: isUnavailableNonCustomItem
            ? DEFAULTS.UNAVAILABLE_COUNTER_WIDTH
            : DEFAULTS.COUNTER_WIDTH,
        paddingVertical: !isDesktop ? 0 : undefined,
    };

    const rootExtraStyle: ViewStyle | undefined = React.useMemo(() => {
        if (!isWeb || !isUnavailableNonCustomItem) {
            return;
        }

        return { backgroundColor: theme["color-critical-25"] };
    }, [isUnavailableNonCustomItem, theme]);

    const priceContainerExtraStyle: ViewStyle = {
        width: isDesktop ? DEFAULTS.DESKTOP_PRICE_CONTAINER_WIDTH : undefined,
    };

    const productNameStyle: ViewStyle = {
        maxWidth: isDesktop ? DEFAULTS.MAX_DESKTOP_PRODUCT_TITLE_LENGTH : undefined,
        flexShrink: isDesktop ? 1 : undefined,
    };

    const formattedDiscountPriceValue = React.useMemo(
        () =>
            discountedPrice &&
            formatNumberWithIndianSystem(discountedPrice * quantity, { showRupeeSymbol: true }),
        [discountedPrice, quantity],
    );

    const [, increment, decrement] = useLocalCartItem({
        storeId,
        itemId: id,
        productType: type ?? "PHYSICAL",
    });

    const removeItem = useLocalCartItemDelete({
        storeId,
        itemId: id,
    });

    const incrementWithAnalytics = React.useCallback(() => {
        if (!inStock) {
            return;
        }

        Analytics.clickEvent({
            category: "item-quantity-selector",
            label: userInfo?.id ? cartId : "",
            context: `${id}, increase-btn`,
        });

        increment(variantId, { itemForm });

        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.ADD_TO_CART, {
            content_name: cartId,
            value: JSON.stringify(price),
            content_category: META_PIXEL_CONTENT_CATEGORY.CART,
        });
    }, [cartId, id, inStock, increment, price, userInfo?.id, variantId, itemForm]);

    const decrementWithAnalytics = React.useCallback(() => {
        if (!inStock) {
            return;
        }

        Analytics.clickEvent({
            category: "item-quantity-selector",
            label: userInfo?.id ? cartId : "",
            context: `${id}, decrease-btn`,
        });

        decrement(variantId, { itemForm });
    }, [inStock, userInfo?.id, cartId, id, decrement, variantId, itemForm]);

    const removeItemWithAnalytics = React.useCallback(() => {
        Analytics.clickEvent({
            category: DEFAULTS.TRASH_ICON_ANALYTICS_CATEGORY,
            label: cartId,
            context: id,
        });

        removeItem(variantId);
    }, [cartId, id, removeItem, variantId]);

    const removeCustomProduct = React.useCallback((itemId: string) => {
        setItemToDelete(itemId);
        setDeleteConfirmationModal(true);
    }, []);

    const onPressButton = React.useCallback(() => {
        if (!isCustom) {
            return;
        }

        removeCustomProduct(id);
    }, [id, isCustom, removeCustomProduct]);

    const goToPdp = React.useCallback(() => {
        navigation.navigate("Product", {
            slug: storeSlug,
            id,
        });
    }, [id, navigation, storeSlug]);

    const onPressImage = React.useCallback(() => {
        Analytics.clickEvent({
            category: DEFAULTS.PRODUCT_IMAGE_ANALYTICS_CATEGORY,
            label: cartId,
            context: JSON.stringify({
                product_id: id, // Analytics team wants in this nomencalture
                is_available: inStock ? 1 : 0,
            }),
        });

        goToPdp();
    }, [cartId, goToPdp, id, inStock]);

    useMount(() => {
        if (!inStock || isCustom) {
            Analytics.impressionEvent({
                category: "remove-item-btn",
                label: userInfo?.id ? cartId : "",
                context: id,
            });
        }

        if (inStock && !isCustom && quantity) {
            Analytics.impressionEvent({
                category: "item-quantity-selector",
                label: userInfo?.id ? cartId : "",
                context: id,
            });
        }
    });

    const priceContainerMarginTopValue: number =
        !discountedPrice && isCustom ? 5 * SpacingValue["space-xxx-small"] : 0;

    const renderPrice = (): React.ReactNode => {
        return (
            <Pressable
                onPress={goToPdp}
                disabled={isCustom || isDesktop}
                hitSlop={{ left: 16 }}
                style={[styles.priceContainer, priceContainerExtraStyle]}>
                <Stack
                    direction={isDesktop ? "column" : "row"}
                    spacing={SpacingValue["space-xx-small"]}>
                    {discountedPrice ? (
                        <Text
                            weight="bold"
                            category="b2"
                            color="high"
                            style={isDesktop ? styles.text : undefined}>
                            {formattedDiscountPriceValue}
                        </Text>
                    ) : null}
                    <Text
                        weight={discountedPrice ? "regular" : "bold"}
                        style={[
                            discountedPrice
                                ? [styles.strikeOff, strikeOffPriceStyle]
                                : styles.price,
                            isDesktop ? styles.text : undefined,
                        ]}>
                        {formattedPriceValue}
                    </Text>
                </Stack>
            </Pressable>
        );
    };

    const productMediaStyle = { width: productImgSize, height: productImgSize };

    const productCtaActionsAndStyles = useGetProductCtaProps({
        productId: id,
        storeId,
        buttonProps: {
            style: [styles.itemCta, addBtnStyle],
            elevation: 0,
        },
        selectedVariant: variantId,
        isCustomCartItem: isCustom,
        customProductDetails: isCustom ? { productType: type } : undefined,
        productActionCtaName: "cart-product-action-cta",
        itemStatus,
        onAdd: incrementWithAnalytics,
        onRemoveOrDecrement: isCustom ? onPressButton : decrementWithAnalytics,
    });

    const doesItemHasFormOrSlotError = React.useMemo(() => {
        return itemStatus ? CART_V2_ERRORS_STATUS.indexOf(itemStatus) > -1 : false;
    }, [itemStatus]);

    const editFormCtaActionsAndStyles = useGetProductCtaProps({
        productId: id,
        storeId,
        buttonProps: {
            style: [addBtnStyle, styles.editCta],
            elevation: 0,
        },
        itemStatus,
        isCustomCartItem: isCustom,
        customProductDetails: isCustom ? { productType: type } : undefined,
        isEditForm: true,
        variantIdForEdit: variantId,
        productActionCtaName: "cart-product-action-cta",
    });

    const productImgFallback = getProductFallbackImages(type);

    const imageUrl = getMediaUrl(
        productImageId ?? productImgFallback,
        { width: productImgSize, height: productImgSize },
        true,
    );

    const canDisplayEditCta = React.useMemo(() => {
        return (doesItemHasFormOrSlotError || itemFormExist || type === "APPOINTMENT") && inStock;
    }, [doesItemHasFormOrSlotError, itemFormExist, inStock, type]);

    return (
        <LinearGradient
            colors={
                isUnavailableNonCustomItem
                    ? DEFAULTS.GRADIENT_COLORS
                    : DEFAULTS.TRANSPARENT_GRADIENT_COLORS
            }
            start={DEFAULTS.GRADIENT_START_POINT}
            end={DEFAULTS.GRADIENT_END_POINT}
            locations={DEFAULTS.GRADIENT_LOCATIONS}
            style={[styles.root, rootExtraStyle, style]}>
            <Stack
                style={styles.containerPadding}
                direction="row"
                flex={1}
                spacing={SpacingValue["space-medium"]}>
                <Pressable
                    onPress={onPressImage}
                    disabled={isCustom}
                    style={[styles.img, productImgExtraStyles]}>
                    <StoreMedia
                        mediaId={imageUrl}
                        style={productMediaStyle}
                        disableVideoPlay
                        resizeMode="cover"
                        onPress={onPressImage}
                        {...productMediaStyle}
                    />
                    {(isUnavailableNonCustomItem || isChanged) && productImageId ? (
                        <Box
                            style={[
                                styles.img,
                                styles.greyBox,
                                { backgroundColor: theme["color-basic-15"] },
                                productImgExtraStyles,
                            ]}
                        />
                    ) : null}
                </Pressable>
                <Stack
                    alignItems={isDesktop && !isCustom ? "center" : undefined}
                    flex={1}
                    direction={isDesktop ? "row" : "column"}
                    justifyContent={isDesktop ? "space-between" : undefined}
                    spacing={
                        isDesktop ? SpacingValue["space-xx-large"] : SpacingValue["space-x-small"]
                    }>
                    <Pressable
                        onPress={goToPdp}
                        disabled={isCustom}
                        hitSlop={{ left: 16 }}
                        style={productNameStyle}>
                        <Stack spacing={SpacingValue["space-xx-small"]}>
                            <Text
                                category="b2"
                                weight="bold"
                                color="high"
                                numberOfLines={!isCustom ? 2 : undefined}>
                                {name}
                            </Text>
                            <ProductVariantValuesLabel
                                values={variants}
                                textProps={{ color: "low" }}
                            />

                            <SelectedSlotData slot={slot} productDuration={productDuration} />
                        </Stack>
                    </Pressable>
                    {!isDesktop ? renderPrice() : null}

                    <Stack
                        direction="row"
                        alignItems={!isCustom ? "center" : undefined}
                        spacing={
                            isDesktop ? SpacingValue["space-small"] : SpacingValue["space-x-small"]
                        }>
                        {inStock && !isChanged ? (
                            <ProductCta
                                {...productCtaActionsAndStyles}
                                productId={id}
                                storeId={storeId}
                                price={price}
                                type={type}
                                ctaTextStyle={styles.ctaTextStyle}
                                ctaStyle={[
                                    productCtaActionsAndStyles.ctaState === "COUNTER"
                                        ? productCtaActionsAndStyles.ctaStyle
                                        : editFormCtaActionsAndStyles.ctaStyle,
                                    styles.ctaStyle,
                                ]}
                            />
                        ) : null}

                        {canDisplayEditCta ? (
                            <ProductCta
                                {...editFormCtaActionsAndStyles}
                                productId={id}
                                storeId={storeId}
                                type={type}
                                itemStatus={itemStatus}
                                ctaText={doesItemHasFormOrSlotError ? "Fill form" : "Edit"}
                                ctaIcon={!doesItemHasFormOrSlotError ? "Edit" : undefined}
                                ctaIconSize={CTA_ICON_SIZE}
                                ctaTextStyle={{
                                    ...styles.ctaTextStyle,
                                    color: doesItemHasFormOrSlotError
                                        ? theme.negative
                                        : theme["color-primary"],
                                }}
                                ctaStyle={[editFormCtaActionsAndStyles.ctaStyle, styles.ctaStyle]}
                            />
                        ) : null}

                        {!inStock && !isChanged ? (
                            <Pressable
                                hitSlop={{ top: 6, right: 6, bottom: 6, left: 6 }}
                                accessible
                                accessibilityLabel="Delete item"
                                onPress={removeItemWithAnalytics}>
                                <SvgIcon icon="Bin" color="color-critical-400" />
                            </Pressable>
                        ) : null}

                        {isDesktop ? (
                            <Box
                                flex={1}
                                ml={SpacingValue["space-xx-large"]}
                                alignItems={discountedPrice ? "flex-start" : "center"}
                                mt={priceContainerMarginTopValue}>
                                {renderPrice()}
                            </Box>
                        ) : null}
                    </Stack>
                </Stack>
            </Stack>

            <DeleteProductConfirmationDialog
                showModal={showDeleteProductConfirmationModal}
                closeModal={hideDeleteDialog}
                storeId={storeId}
                itemId={itemToDelete}
            />
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    containerPadding: {
        paddingTop: SpacingValue["space-medium"],
        paddingBottom: SpacingValue["space-large"],
    },
    strikeOff: {
        textDecorationLine: "line-through",
        fontSize: 11,
        maxWidth: "100%",
    },
    price: {
        fontSize: 14,
        lineHeight: 20,
        maxWidth: "100%",
    },
    itemCta: {
        minWidth: DEFAULTS.COUNTER_WIDTH,
        width: DEFAULTS.COUNTER_WIDTH,
    },
    editCta: {
        flex: 1,
        paddingHorizontal: 8,
        width: "auto",
        minWidth: 68,
    },
    img: {
        borderWidth: 1,
        overflow: "hidden",
    },
    priceContainer: {
        marginBottom: SpacingValue["space-xx-small"],
    },
    root: {
        flexDirection: "row",
    },
    greyBox: {
        position: "absolute",
        opacity: 0.55,
    },
    text: { textAlign: "right" },
    ctaStyle: {
        paddingHorizontal: SpacingValue["space-small"],
        borderRadius: 8,
        maxHeight: 32,
    },
    ctaTextStyle: {
        fontSize: 14,
    },
});

export const CartItem = React.memo(ItemComponent);
