import React, { useMemo, useState } from "react";

import { useMountedRef } from "@swiggy-private/react-hooks";

export type TLayoutProps = { left: number; width: number };
export type TPaymentLayout = { layout: TLayoutProps; updateLayout: (T: TLayoutProps) => void };
const initialValue: TPaymentLayout = {
    layout: {
        left: 0,
        width: 0,
    },
    updateLayout: () => null,
};

export const PaymentLayoutStateContext = React.createContext<TPaymentLayout>(initialValue);

export const PaymentLayoutContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [layout, setLayout] = useState({
        left: 0,
        width: 0,
    });
    const mounted = useMountedRef();

    const updateLayout = React.useCallback(
        ({ left, width }: TLayoutProps) => {
            if (mounted.current && left !== undefined && width !== undefined) {
                setLayout({ left, width });
            }
        },
        [mounted],
    );

    const state: TPaymentLayout = useMemo(
        () => ({
            layout,
            updateLayout,
        }),
        [layout, updateLayout],
    );

    return (
        <PaymentLayoutStateContext.Provider value={state}>
            {children}
        </PaymentLayoutStateContext.Provider>
    );
};
