import React from "react";
import { Pressable, PressableProps, StyleSheet } from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { useShare } from "@minis-consumer/hooks/use-share";

interface Props {
    onChatPress: () => void;
}

const ICON_SIZE = 20;

const HeaderRightComponent: React.FC<Props> = ({ onChatPress }) => {
    const share = useShare();

    return (
        <Box
            direction="row"
            mh={SpacingValue["space-medium"]}
            flex={1}
            alignItems="center"
            pointerEvents="box-none">
            <IconContainer
                accessible
                accessibilityLabel="Chat"
                onPress={onChatPress}
                hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}>
                <SvgIcon icon="Chat" height={ICON_SIZE} width={ICON_SIZE} />
            </IconContainer>
            <IconContainer
                accessible
                accessibilityLabel="Share"
                hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
                onPress={() => share()}>
                <SvgIcon icon="Share" height={ICON_SIZE} width={ICON_SIZE} />
            </IconContainer>
        </Box>
    );
};

export const HeaderRight = React.memo(HeaderRightComponent);

type IconContainerProps = Omit<PressableProps, "style">;

const IconContainer: React.FC<IconContainerProps> = (props) => {
    const { accessible = true, accessibilityRole = "button", children, ...restProps } = props;

    return (
        <Pressable
            accessible={accessible}
            accessibilityRole={accessibilityRole}
            style={styles.iconContainer}
            {...restProps}>
            {children}
        </Pressable>
    );
};

const styles = StyleSheet.create({
    iconContainer: {
        height: 36,
        width: 36,
        borderRadius: 18,
        justifyContent: "center",
        alignItems: "center",
    },
});
