import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Icon, SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

interface Props {
    title: string;
    subtitle: string;
    icon: Icon;
}

const USER_IMAGE_CONTAINER_SIZE = 36;

const UserDetailComponent: React.FC<Props> = ({ title, subtitle, icon }) => {
    const { value: theme } = useTheme();

    const userImageContainerStyles = {
        width: USER_IMAGE_CONTAINER_SIZE,
        height: USER_IMAGE_CONTAINER_SIZE,
        borderRadius: USER_IMAGE_CONTAINER_SIZE / 2,
        backgroundColor: theme["color-basic-5"],
        marginRight: SpacingValue["space-small"],
    };

    return (
        <Stack direction="row" justifyContent="space-between" style={styles.w100}>
            <Stack direction="row" style={styles.w100}>
                <Box justifyContent="center" alignItems="center" style={userImageContainerStyles}>
                    <SvgIcon icon={icon} height={16} width={16} color="color-basic-75" />
                </Box>

                <Stack spacing={SpacingValue["space-xxx-small"]} style={styles.w100}>
                    <Text category="b2" weight="bold" color="high">
                        {title}
                    </Text>
                    <Text category="b3" color="low" style={styles.subtitle}>
                        {subtitle}
                    </Text>
                </Stack>
            </Stack>
        </Stack>
    );
};

export const UserDetail = React.memo(UserDetailComponent);

const styles = StyleSheet.create({
    w100: {
        width: "100%",
    },
    subtitle: {
        width: "62%",
    },
});
