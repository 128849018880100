import * as React from "react";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";

import { ThemeProvider, ThemeRegistry, useTheme } from "@swiggy-private/rn-dls";
import {
    ChatConversationScreen,
    Navigation,
    ScreenLoader,
    useChatState,
} from "@swiggy-private/connect-chat-ui";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";

import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { useCreateChatConversation } from "@minis-consumer/hooks/use-start-chat-conversation";
import { RouteList } from "@minis-consumer/interfaces/route";
import { RenderContextProvider } from "@minis-consumer/components/chat/contexts/render-context";
import { ResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { addTheme } from "@minis-consumer/includes/theme";
import { AppEvent, AppEventTypes } from "@minis-consumer/includes/app-event";
import { checkColor } from "@minis-consumer/helpers/color";

type ChatScreenProps = NativeStackScreenProps<RouteList, "ChatConversation">;

const Chat: React.FC<ChatScreenProps> = ({ navigation, route }) => {
    const { value: theme } = useTheme();

    const storeInfo = useStoreInfo(false);

    const params = {
        ...route.params,
        storeId: route.params.storeId || storeInfo?.storeId,
    };

    const { conversationId } = useCreateChatConversation(params);
    const brandColor = useChatState().conversations[conversationId || "0"]?.meta?.storeBrandColor;

    useIsomorphicEffect(() => {
        navigation.setOptions({
            title: "",
            headerTitle: "",
            headerShadowVisible: true,
            gestureEnabled: navigation.canGoBack(),
        });
    }, [navigation]);

    if (conversationId && brandColor) {
        const chatTheme = ThemeRegistry.get(conversationId);
        if (!chatTheme) {
            addTheme({ primaryColor: checkColor(brandColor) }, conversationId);
        }
    }

    React.useEffect(() => {
        return AppEvent.listen<{ conversationId: string }>(
            AppEventTypes.NEW_CHAT_MESSAGE,
            (event) => {
                if (
                    !event.handled &&
                    event.value &&
                    event.value.conversationId === conversationId
                ) {
                    event.handled = true;
                }
            },
        );
    }, [conversationId]);

    const content = (
        <RenderContextProvider>
            {conversationId ? (
                <ChatConversationScreen
                    conversationId={conversationId}
                    navigation={navigation as Navigation}
                />
            ) : (
                <ScreenLoader style={[{ backgroundColor: theme["color-basic-5"] }]} />
            )}
        </RenderContextProvider>
    );

    if (brandColor && conversationId) {
        return <ThemeProvider theme={conversationId}>{content}</ThemeProvider>;
    }

    return content;
};

export const ChatScreen: React.FC<ChatScreenProps> = (props) => {
    return (
        <ResetErrorBoundary>
            <StateContextContainer>
                <Chat {...props} />
            </StateContextContainer>
        </ResetErrorBoundary>
    );
};
