import * as React from "react";
import { StyleSheet, View } from "react-native";

import {
    Dialog,
    DialogContent,
    SpacingValue,
    useTheme,
    Text,
    Input,
    Button,
} from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { Card } from "@minis-consumer/components/card";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

type DialogComponentProps = {
    open: boolean;
    setOpen: (b: boolean) => void;
    streetAddress: string;
    annotation: string;
    showPincode: boolean;
    showCity: boolean;
    showState: boolean;
    onCtaPress: ({
        pincode,
        city,
        state,
    }: {
        pincode: string;
        city: string;
        state: string;
    }) => void;
};

const DialogComponent: React.FC<DialogComponentProps> = ({
    open,
    setOpen,
    showCity,
    showPincode,
    showState,
    streetAddress,
    annotation,
    onCtaPress,
}) => {
    const { value: theme } = useTheme();
    const store = useStoreInfo();

    const [pincode, setPincodeValue] = React.useState("");
    const [city, setCityValue] = React.useState("");
    const [state, setStateValue] = React.useState("");

    const btnDisabled = React.useMemo(() => {
        if (showPincode && pincode.trim().length < 6) {
            return true;
        }

        if (showCity && !city.trim().length) {
            return true;
        }

        if (showState && !state.trim().length) {
            return true;
        }

        return false;
    }, [city, pincode, showCity, showPincode, showState, state]);

    const onPress = React.useCallback(
        () => onCtaPress({ pincode, city, state }),
        [city, onCtaPress, pincode, state],
    );

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} style={styles.dialog}>
            <DialogContent>
                <Card>
                    <Stack flex={1} spacing={SpacingValue["space-x-large"]}>
                        <View
                            style={[
                                styles.svgContainer,
                                {
                                    borderColor: theme["color-basic-15"],
                                },
                            ]}>
                            <SvgIcon
                                icon={annotation === "Home" ? "Home" : "Location"}
                                height={20}
                                width={20}
                                color="color-primary"
                            />
                        </View>
                        <Stack spacing={SpacingValue["space-x-small"]}>
                            <Text category="h3">{annotation}</Text>
                            <Text category="s3" color="color-basic-60">
                                {streetAddress}
                            </Text>
                        </Stack>
                        <Stack spacing={SpacingValue["space-x-small"]}>
                            <Text category="b1" weight="medium" color="color-primary">
                                For a hassle free delivery,
                                <Text category="b1" weight="bold" color="color-primary">
                                    {` ${store.name} `}
                                </Text>
                                has requested the following info:
                            </Text>

                            <Stack spacing={SpacingValue["space-x-large"]}>
                                {showPincode ? (
                                    <Input
                                        placeholder="Confirm pincode"
                                        testID="input-pincode"
                                        keyboardType="phone-pad"
                                        maxLength={6}
                                        value={pincode}
                                        onChangeText={setPincodeValue}
                                        autoFocus
                                    />
                                ) : null}

                                {showCity ? (
                                    <Input
                                        placeholder="Confirm city"
                                        testID="input-city"
                                        value={city}
                                        onChangeText={setCityValue}
                                        autoFocus
                                    />
                                ) : null}

                                {showState ? (
                                    <Input
                                        placeholder="Confirm state"
                                        testID="input-state"
                                        value={state}
                                        onChangeText={setStateValue}
                                        autoFocus
                                    />
                                ) : null}

                                <Button onPress={onPress} disabled={btnDisabled}>
                                    Continue
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
            </DialogContent>
        </Dialog>
    );
};

export const AddressMissingFieldDialog = React.memo(DialogComponent);

const styles = StyleSheet.create({
    dialog: {
        backgroundColor: "transparent",
        width: 600,
    },
    svgContainer: {
        height: 40,
        width: 40,
        borderRadius: 8,
        borderWidth: 1,
        alignItems: "center",
        justifyContent: "center",
    },
});
