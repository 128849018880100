import * as React from "react";
import { Platform, StyleSheet, ViewStyle, View } from "react-native";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import {
    ScreenSize,
    Stack,
    useScreenSize,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { BottomSheet, useKeyboardHeight, CdnImage } from "@swiggy-private/react-native-ui";
import {
    DialogContent,
    SpacingValue,
    Text,
    Button,
    Input,
    useLayout,
} from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { useEnquiryChat } from "@minis-consumer/hooks/use-image-enquiry-chat";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { RouteList } from "@minis-consumer/interfaces/route";
import { useChatBlockedErrorSnackbarHandler } from "@minis-consumer/hooks/use-chat-blocked-snack-handler";

type SendEnquiryBottomSheetProps = {
    imgUrl: string;
    show: boolean;
    onShow: (b: boolean) => void;
};

export const SendEnquiryBottomSheet: React.FC<SendEnquiryBottomSheetProps> = ({
    show,
    onShow,
    imgUrl,
}) => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();

    const { dataHandler } = useChatBlockedErrorSnackbarHandler();

    const [enquiry, setEnquiry] = React.useState("");
    const [sending, setSending] = React.useState(false);

    const screenSize = useScreenSize();

    const storeInfo = useStoreInfo();

    const { sendEnquiryMessage, blockedInfo } = useEnquiryChat(storeInfo.storeId);

    const style = useSelectScreen<ViewStyle>({
        default: styles.topBorderRadius,
        lg: styles.fullBorderRadius,
    });

    const contentWidth = React.useMemo(
        () => (screenSize === ScreenSize.Large ? 360 : undefined),
        [screenSize],
    );

    const isBtnDisabled = React.useMemo(
        () => !enquiry.trim().length || sending,
        [enquiry, sending],
    );

    const onDismiss = React.useCallback(() => {
        setEnquiry("");
        onShow(false);
    }, [onShow]);

    const onSendEnquiry = React.useCallback(() => {
        setSending(true);

        if (blockedInfo?.blockedBy?.length) {
            setSending(false);
            dataHandler(true, blockedInfo);
            onDismiss();
            return;
        }

        sendEnquiryMessage({
            text: enquiry.trim(),
            imageUrl: imgUrl,
        });

        setEnquiry("");
        setSending(false);

        if (screenSize !== ScreenSize.Large) {
            navigation.navigate("ChatConversation", { storeId: storeInfo.storeId });
        }

        onDismiss();
    }, [
        sendEnquiryMessage,
        enquiry,
        imgUrl,
        screenSize,
        onDismiss,
        navigation,
        storeInfo.storeId,
        blockedInfo,
        dataHandler,
    ]);

    const [layout, onLayout] = useLayout();
    const keyboardHeight = useKeyboardHeight();

    const contentStyle = {
        opacity: layout.measured ? 1 : 0,
    };

    if (!show) {
        return null;
    }

    return (
        <BottomSheet open={show} onClose={onDismiss} style={style} disableBackdropClose>
            <DialogContent style={style} onLayout={onLayout} collapsable={false}>
                <Stack
                    spacing={SpacingValue["space-medium"]}
                    style={[{ width: contentWidth }, contentStyle]}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack spacing={SpacingValue["space-medium"]} direction="row">
                            <CdnImage
                                isImageKitEnabled
                                height={40}
                                width={40}
                                id={imgUrl}
                                style={styles.img}
                            />
                            <Stack>
                                <Text category="h4" color="highest">
                                    Enquiry for:
                                </Text>
                                <Text category="b2" color="high">
                                    Instagram photo
                                </Text>
                            </Stack>
                        </Stack>

                        <SvgIcon
                            icon="Close"
                            color="color-basic-60"
                            height={16}
                            width={16}
                            onPress={onDismiss}
                            hitSlop={{ top: 16, right: 16, bottom: 16, left: 16 }}
                        />
                    </Stack>

                    <Input
                        autoCorrect={false}
                        autoCapitalize={"none"}
                        spellCheck={false}
                        placeholder="Please write your enquiry here"
                        onChangeText={setEnquiry}
                        multiline
                        maxLength={250}
                        showCounter
                        style={styles.input}
                        autoFocus
                    />

                    <Button disabled={isBtnDisabled} onPress={onSendEnquiry}>
                        Send enquiry
                    </Button>
                </Stack>
                {Platform.OS === "ios" ? <View style={[{ height: keyboardHeight }]} /> : null}
            </DialogContent>
        </BottomSheet>
    );
};

const styles = StyleSheet.create({
    topBorderRadius: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    fullBorderRadius: {
        borderRadius: 16,
    },
    img: {
        borderRadius: 8,
    },
    input: {
        height: 140,
    },
});
