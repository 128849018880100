import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SpacingValue, Text, useTheme, TextCategory } from "@swiggy-private/rn-dls";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Product } from "@minis-consumer/interfaces/catalog";

interface ProductDetailsMainProps {
    name: Product["name"];
    description: Product["description"];
    badges: Product["badges"];
    goToShop: VoidFunction;
}

const RECOMMENDED = "Recommended";

export const ProductDetailsMain: React.FC<ProductDetailsMainProps> = ({
    name,
    badges,
    goToShop,
}) => {
    const { value: theme } = useTheme();

    const store = useStoreInfo();

    const storeNameStyle = useSelectScreen({
        lg: {
            marginTop: 0,
        },
        md: {
            marginTop: 0,
        },
        default: {
            marginTop: SpacingValue["space-medium"],
            marginBottom: SpacingValue["space-xx-small"],
        },
    });

    const productFontCategory: TextCategory = useSelectScreen({
        lg: "header_H5",
        md: "header_H5",
        default: "h3",
    });

    const badgeStyle = {
        backgroundColor: theme["color-critical-300"],
    };

    const badgeText = {
        color: theme["color-basic-0"],
    };

    const badgeList = React.useMemo(() => {
        return badges.filter((badge) => badge !== RECOMMENDED);
    }, [badges]);

    return (
        <>
            <Pressable onPress={goToShop}>
                <Text
                    category="btn3"
                    color="color-primary"
                    weight="bold"
                    style={storeNameStyle}
                    numberOfLines={1}>
                    {store.name}
                </Text>
            </Pressable>

            {badgeList.length > 0 ? (
                <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                    {badgeList.map((badge, index) => {
                        return (
                            <Box
                                key={index}
                                mt={SpacingValue["space-x-small"]}
                                style={[styles.badgeStyle, badgeStyle]}>
                                <Text
                                    category="b2"
                                    style={[styles.badgeText, badgeText]}
                                    weight="bold">
                                    {badge}
                                </Text>
                            </Box>
                        );
                    })}
                </Stack>
            ) : null}

            <Text
                category={productFontCategory}
                color="high"
                style={styles.productNameStyle}
                numberOfLines={3}>
                {name}
            </Text>
        </>
    );
};

const styles = StyleSheet.create({
    productNameStyle: {
        marginTop: SpacingValue["space-xx-small"],
        fontSize: 24,
        lineHeight: 28,
        letterSpacing: -0.02,
    },
    badgeStyle: {
        paddingVertical: SpacingValue["space-xx-small"],
        paddingHorizontal: SpacingValue["space-x-small"],
        borderBottomRightRadius: 2,
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 8,
        borderTopRightRadius: 8,
    },
    badgeText: {
        textTransform: "uppercase",
        fontSize: 11,
    },
});

export default React.memo(ProductDetailsMain);
