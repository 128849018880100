import React, { PropsWithChildren } from "react";
import { useSuspense } from "@rest-hooks/react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { useGetProduct } from "@minis-consumer/routes/product/hooks/use-get-product";
import { useGetCartItem } from "@minis-consumer/hooks/use-cart";
import { CatalogView } from "@minis-consumer/resources/catalog";
import { IDynamicForm } from "@minis-consumer/interfaces/dynamic-forms";
import { CommonRouteList } from "@minis-consumer/interfaces/route";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { FAQHelpPlaceholder } from "@minis-consumer/routes/account/components/faq-help-placeholder";

import { ProductForm } from "./index";

type Props = NativeStackScreenProps<CommonRouteList, "ProductForms">;

const useGetData = (productId: string, route: Props["route"]): IDynamicForm[] | null => {
    const { formData, variantId, storeId = "", isEnquireMode } = route.params;

    const productData = useGetProduct(productId);
    const cartItem = useGetCartItem(storeId, productId, variantId);

    const currentRouteParams = route.params;

    if (currentRouteParams.isEditMode) {
        return cartItem?.itemForm ? [cartItem?.itemForm as IDynamicForm] : [];
    }

    if (formData) {
        try {
            return JSON.parse(formData);
        } catch (e) {
            return null;
        }
    }

    let form: IDynamicForm[] = [];
    if (productData?.enquiryForm && isEnquireMode) {
        form = [productData.enquiryForm];
    }
    if (productData?.form) {
        form = [...form, productData.form];
    }
    return form;
};

const FormComponent: React.FC<PropsWithChildren<Props>> = ({ navigation, route }) => {
    const { id: productId, isEditMode, variantId, isEnquireMode } = route.params;
    const productData = useGetProduct(productId);
    const formData = useGetData(productId, route);

    if (!formData) {
        return null;
    }

    return (
        <ProductForm
            navigation={navigation}
            route={route}
            data={formData}
            product={productData}
            isEnquiryForm={!!isEnquireMode}
            readOnly={isEditMode === false}
            variantId={variantId}
        />
    );
};

const WithCatalogForm: React.FC<React.PropsWithChildren<Props>> = (props) => {
    useSuspense(CatalogView, { storeSlug: props.route.params.slug });
    return <FormComponent {...props} />;
};
const ProductFormContainer: React.FC<React.PropsWithChildren<Props>> = (props) => {
    return (
        <AsyncComponent fallback={FAQHelpPlaceholder}>
            <WithCatalogForm {...props} />
        </AsyncComponent>
    );
};

export const ProductFormWrapper = React.memo(ProductFormContainer);
