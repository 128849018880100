import * as React from "react";
import { Keyboard, StyleSheet } from "react-native";

import { Box, Display, ScreenSize, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { ActivityIndicator, useTheme } from "@swiggy-private/rn-dls";

import {
    useApplyCouponIfAlreadyInvalid,
    useCartView,
    useCelebrationModal,
    useLocalCart,
    useSyncCartWithServer,
    useUpdateCartAppliedCoupon,
} from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useLoadCoupons } from "@minis-consumer/hooks/use-coupon";
import { Analytics } from "@minis-consumer/analytics";
import { CouponErrorSnackbar } from "@minis-consumer/components/coupon-error-snackbar";
import { useCouponInvalidHandler } from "@minis-consumer/hooks/use-coupon-invalid-handler";

import { ModalHeader } from "../coupons-modal/header";
import { CouponListPlaceholder } from "../placeholder/list";
import { CouponsList } from "../list";
import { CouponInput } from "../input";

const AllCouponsComponent: React.FC<{
    closeModal: () => void;
}> = ({ closeModal }) => {
    const { value: theme } = useTheme();

    const [applyingCoupon, setApplyingCoupon] = React.useState(false);

    const store = useStoreInfo();
    const localCart = useLocalCart(store.storeId);

    const { loading, coupons } = useLoadCoupons(store.storeId, localCart.addressId);
    const cartView = useCartView(store.storeId);
    const { canShowSnackbar, triggerError, dismissSnackbar, errorText } =
        useCouponInvalidHandler(null);

    const syncing = useSyncCartWithServer(store.storeId);

    // @TODO: refactor, suggestions are welcome
    const { reapplyingInvalidCoupon, handleCartUpdate } = useApplyCouponIfAlreadyInvalid(
        store.storeId,
    );
    const applyCouponHook = useUpdateCartAppliedCoupon(store.storeId);
    const { activateOrDeactivateCelebrationModal } = useCelebrationModal(store.storeId);

    const onApplyCoupon = React.useCallback(
        async (code: string, eventName: string, pos?: number) => {
            Keyboard.dismiss();

            Analytics.clickEvent({
                category: eventName,
                label: cartView?.cartViewData?.id,
                context: code,
                value: pos,
            });

            /**
             * Check if coupon is already invalid and trigger update API call since
             * useSyncCartWithServer won't get triggered as there is no change in the local cart
             */
            if (code === cartView?.cartViewData?.appliedCoupon?.code) {
                await handleCartUpdate();
            } else {
                await applyCouponHook(code);
            }

            setApplyingCoupon(true);
        },
        [
            applyCouponHook,
            cartView?.cartViewData?.id,
            cartView?.cartViewData?.appliedCoupon?.code,
            handleCartUpdate,
        ],
    );

    const contentBorder = useSelectScreen({
        lg: {
            borderRadius: 24,
        },
        default: {
            borderRadius: 0,
        },
    });

    const contentContainerStyle = {
        flexGrow: 1,
        backgroundColor: theme["color-background-secondary"],
    };

    /**
     * better to create a separate hook for this to know that
     * the API call and cart update has been done
     */
    React.useEffect(() => {
        if (
            !syncing &&
            cartView?.cartViewData?.appliedCoupon &&
            applyingCoupon &&
            !reapplyingInvalidCoupon
        ) {
            /** after API call is finished, check if coupon was applied
             * if coupon is applied, update context to show the
             * celebration modal navigate to cart screen
             */
            if (cartView?.cartViewData?.appliedCoupon.applied) {
                setApplyingCoupon(false);
                activateOrDeactivateCelebrationModal(true);
                closeModal();
            } else {
                /**
                 * else show a toast with applied coupon message or a fallback error
                 */
                Analytics.impressionEvent({
                    category: "invalid-coupon-okay-btn",
                    label: `cartId: ${cartView?.cartViewData?.id}`,
                    context: `couponCode: ${cartView?.cartViewData?.appliedCoupon.code}`,
                });
                activateOrDeactivateCelebrationModal(false);
                setApplyingCoupon(false);
                triggerError(cartView?.cartViewData?.appliedCoupon?.message);
            }
        }
    }, [
        activateOrDeactivateCelebrationModal,
        applyingCoupon,
        cartView?.cartViewData?.appliedCoupon,
        cartView?.cartViewData?.id,
        closeModal,
        reapplyingInvalidCoupon,
        syncing,
        triggerError,
    ]);

    return (
        <>
            <Box flex={1} style={[contentContainerStyle, contentBorder]}>
                <Box>
                    <Display gt={ScreenSize.Large}>
                        <ModalHeader closeModal={closeModal} />
                    </Display>

                    <CouponInput
                        loading={loading}
                        cartId={cartView?.cartViewData?.id}
                        onApplyCoupon={onApplyCoupon}
                    />
                </Box>

                <Box flex={1}>
                    {loading ? (
                        <CouponListPlaceholder />
                    ) : (
                        <CouponsList
                            onApply={onApplyCoupon}
                            coupons={coupons}
                            cartId={cartView?.cartViewData?.id}
                        />
                    )}
                </Box>
            </Box>

            {syncing || applyingCoupon || reapplyingInvalidCoupon ? (
                <ActivityIndicator style={styles.activityIndicator} size="large" />
            ) : null}

            <CouponErrorSnackbar
                errorText={errorText}
                canShowSnackbar={canShowSnackbar}
                dismissSnackbar={dismissSnackbar}
                isLgScreenModal
            />
        </>
    );
};

const styles = StyleSheet.create({
    activityIndicator: {
        position: "absolute",
        width: "100%",
        height: "100%",
        // @TODO: get color added in DLS
        backgroundColor: "rgba(255, 255, 255, .5)",
    },
});

export const AllCoupons = React.memo(AllCouponsComponent);
