import { Keyboard } from "react-native";

import { SEARCH_QUERY_MIN_CHAR_LIMIT } from "../constants";

export const isValidQueryForSearch = (query: string): boolean => {
    const q = query;
    return q.trim().length >= SEARCH_QUERY_MIN_CHAR_LIMIT;
};

export const dismissKeyboard = (): void => {
    Keyboard.dismiss();
};
