import React from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon, SvgIconType } from "@swiggy-private/connect-svg-icons";

import { CartAddressState } from "@minis-consumer/interfaces/cart";

interface Props {
    state: CartAddressState;
    addressLength: number;
}

const CardHeaderComponent: React.FC<Props> = ({ state, addressLength }) => {
    const { value: theme } = useTheme();

    const iconContainerStyles = React.useMemo<ViewStyle>(() => {
        return {
            backgroundColor:
                state === "UNSELECTED"
                    ? theme["color-primary"]
                    : state === "SELECTED_SUCCESS"
                    ? theme["color-positive-25"]
                    : theme["color-critical-25"],
        };
    }, [state, theme]);

    const iconColor = React.useMemo<SvgIconType["color"]>(() => {
        return state === "UNSELECTED"
            ? "color-basic-0"
            : state === "SELECTED_SUCCESS"
            ? "color-positive-500"
            : "color-critical-500";
    }, [state]);

    return (
        <Stack spacing={SpacingValue["space-medium"]} direction="row" alignItems="center">
            <Box style={[styles.iconContainer, iconContainerStyles]}>
                <SvgIcon icon="Location" color={iconColor} />
            </Box>

            <Stack>
                <Text category="b1" weight="bold" color="high">
                    {state === "UNSELECTED" ? "Select an address" : "Delivery address"}
                </Text>

                <Text category="b2" color="medium">
                    {state === "UNSELECTED"
                        ? `You have ${addressLength} saved ${
                              addressLength > 1 ? "addresses" : "address"
                          }`
                        : "Your order will be shipped to:"}
                </Text>
            </Stack>
        </Stack>
    );
};

export const CardHeader = React.memo(CardHeaderComponent);

const styles = StyleSheet.create({
    iconContainer: {
        height: 48,
        width: 48,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 12,
        padding: SpacingValue["space-small"],
    },
});
