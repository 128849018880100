import React from "react";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { Navigation } from "../header";
import { IconContainer, IconContainerProps } from "../header/icon-container";

const ICON_SIZE = 22;
export interface HeaderLeftProps {
    navigation?: Navigation["navigation"];
    color?: string;
    containerStyle?: IconContainerProps["style"];
}

const HeaderLeftComponent: React.FC<HeaderLeftProps> = ({ navigation, color, containerStyle }) => {
    const onPressBack = React.useCallback(() => {
        navigation?.goBack();
    }, [navigation]);

    if (!navigation?.canGoBack()) {
        return null;
    }

    return (
        <IconContainer
            accessibilityLabel="Back"
            onPress={onPressBack}
            style={[containerStyle, { marginLeft: -SpacingValue["space-x-small"] }]}>
            <SvgIcon icon="ArrowLeft" color={color} width={ICON_SIZE} height={ICON_SIZE} />
        </IconContainer>
    );
};

export const HeaderLeft = React.memo(HeaderLeftComponent);
