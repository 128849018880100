import { Pressable, StyleSheet } from "react-native";
import React, { Dispatch, FC, memo, SetStateAction, useCallback } from "react";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { UPLOAD_IMAGE_DISPLAY_SIZE } from "../constants";

interface UploadedImageProps {
    media: string;
    setSelectedMedia: Dispatch<SetStateAction<string[] | undefined>>;
}

const UploadedImageComponent: FC<UploadedImageProps> = ({ media, setSelectedMedia }) => {
    const { value: theme } = useTheme();

    const removeMedia = useCallback((): void => {
        setSelectedMedia((prev) => {
            if (!Array.isArray(prev) || !prev.length) {
                return [];
            }
            const newMedias = prev.filter((m) => media !== m);
            return newMedias;
        });
    }, [media, setSelectedMedia]);

    return (
        <Box mr={SpacingValue["space-medium"]}>
            <CdnImage
                id={media}
                height={UPLOAD_IMAGE_DISPLAY_SIZE}
                width={UPLOAD_IMAGE_DISPLAY_SIZE}
                style={styles.image}
            />
            <Pressable
                onPress={removeMedia}
                style={[
                    styles.imageRemoveContainer,
                    {
                        backgroundColor: theme["color-background-primary"],
                        borderColor: theme["color-basic-15"],
                    },
                ]}>
                <SvgIcon width={8} height={8} icon="Close" color="color-basic-45" />
            </Pressable>
        </Box>
    );
};

export const UploadedImage = memo(UploadedImageComponent);

const styles = StyleSheet.create({
    image: {
        borderRadius: 8,
        marginVertical: SpacingValue["space-x-small"],
    },
    imageRemoveContainer: {
        justifyContent: "center",
        alignItems: "center",
        width: 16,
        height: 16,
        borderRadius: 10,
        borderWidth: 1,
        position: "absolute",
        left: UPLOAD_IMAGE_DISPLAY_SIZE - 8,
        top: 4,
    },
});
