import * as React from "react";
import { StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { Shimmer } from "@minis-consumer/components/shimmer";
import { Divider } from "@minis-consumer/components/divider";

export const CatalogLoaderMobileView: React.FC = () => {
    return (
        <Stack
            direction="row"
            spacing={SpacingValue["space-medium"]}
            style={styles.alignment}
            flex={1}>
            <Stack spacing={SpacingValue["space-x-small"]}>
                <Shimmer width={136} height={136} style={styles.br12} />
                <Shimmer width={104} height={12} style={styles.br4} />
                <Shimmer width={128} height={12} style={styles.br4} />
                <Shimmer width={33} height={16} style={styles.br4} />
            </Stack>

            <Stack spacing={SpacingValue["space-x-small"]}>
                <Shimmer width={136} height={136} style={styles.br12} />
                <Shimmer width={104} height={12} style={styles.br4} />
                <Shimmer width={128} height={12} style={styles.br4} />
                <Shimmer width={33} height={16} style={styles.br4} />
            </Stack>

            <Stack spacing={SpacingValue["space-x-small"]}>
                <Shimmer width={136} height={136} style={styles.br12} />
                <Shimmer width={104} height={12} style={styles.br4} />
                <Shimmer width={128} height={12} style={styles.br4} />
                <Shimmer width={33} height={16} style={styles.br4} />
            </Stack>
        </Stack>
    );
};

export const ShopLoaderMobileView: React.FC = () => {
    const { value: theme } = useTheme();
    const insets = useSafeAreaInsets();

    return (
        <Stack
            flex={1}
            flexGrow={1}
            alignItems="center"
            style={{
                padding: SpacingValue["space-large"],
                backgroundColor: theme["color-basic-0"],
                marginTop: insets.top + 0,
            }}>
            <Shimmer height={342} width="100%" style={[styles.br20, styles.mb20]} />
            <Stack spacing={SpacingValue["space-x-small"]} style={styles.alignment}>
                <Shimmer height={16} width="50%" style={styles.br4} />
                <Shimmer height={16} width="70%" style={styles.br4} />
                <Shimmer height={16} width="30%" style={styles.br4} />
            </Stack>

            <Box
                direction="row"
                style={[
                    styles.coupon,
                    {
                        borderColor: theme["color-basic-15"],
                    },
                ]}>
                <Box direction="row" alignItems="center">
                    <Box>
                        <Shimmer height={48} width={48} style={styles.br50} />
                    </Box>
                    <Box ml={SpacingValue["space-x-small"]}>
                        <Shimmer height={16} width={72} style={styles.br4} />
                        <Shimmer height={16} width={132} style={[styles.br4, styles.mt4]} />
                    </Box>
                </Box>
            </Box>

            <Divider style={[styles.divider, styles.alignment]} />

            <CatalogLoaderMobileView />
        </Stack>
    );
};

const styles = StyleSheet.create({
    br4: {
        borderRadius: 4,
    },
    br20: {
        borderRadius: 20,
    },
    br50: {
        borderRadius: 50,
    },
    mb20: {
        marginBottom: SpacingValue["space-large"],
    },
    mr16: {
        marginRight: SpacingValue["space-medium"],
    },
    ml16: {
        marginLeft: SpacingValue["space-medium"],
    },
    mt4: {
        marginTop: SpacingValue["space-xx-small"],
    },
    divider: {
        marginTop: SpacingValue["space-medium"],
        marginBottom: SpacingValue["space-x-large"],
    },
    br12: {
        borderRadius: 12,
    },
    alignment: {
        alignSelf: "flex-start",
        width: "100%",
    },
    coupon: {
        padding: SpacingValue["space-medium"],
        borderWidth: 1,
        borderRadius: 20,
        width: "100%",
        margin: SpacingValue["space-medium"],
    },
});
