import React from "react";
import { StyleSheet } from "react-native";

import { SvgIcon, SvgIconType } from "@swiggy-private/connect-svg-icons";
import { Box, ScreenSizeProp, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextColor } from "@swiggy-private/rn-dls";

interface IconTextComponentProps {
    text: string;
    iconName: SvgIconType["icon"];
    iconSize: number;
    textColor?: TextColor;
    spacing?: ScreenSizeProp<number>;
    highligtedText?: string | null;
    testID?: string;
}

export const IconTextComponent: React.FC<IconTextComponentProps> = React.memo(
    ({ text, iconName, iconSize, textColor, spacing, highligtedText, testID }) => {
        return (
            <Stack
                direction="row"
                spacing={spacing ?? SpacingValue["space-x-small"]}
                alignItems="flex-start"
                style={styles.deliveryInfoItem}
                testID={testID}>
                <Box>
                    <SvgIcon
                        icon={iconName}
                        color="color-basic-60"
                        width={iconSize}
                        height={iconSize}
                        style={styles.icon}
                    />
                </Box>
                <Text category="b2" color={textColor ?? "color-basic-60"}>
                    {text}

                    <Text category="b2" weight="medium" color="color-basic-60">
                        {highligtedText ?? ""}
                    </Text>
                </Text>
            </Stack>
        );
    },
);

if (__DEV__) {
    IconTextComponent.displayName = "IconTextComponent";
}

const styles = StyleSheet.create({
    icon: {
        marginTop: SpacingValue["space-xx-small"],
    },
    deliveryInfoItem: {
        marginTop: SpacingValue["space-x-small"] + SpacingValue["space-xxx-small"],
    },
});
