export const DISPLAY_COPIES = {
    HEADER: {
        TITLE: "Return or Exchange",
        ORDER: "Order #",
    },
    CARD: {
        REASON_TITLE: "Reason for return*",
        COMMENT_PLACEHOLDER: "Comments",
        ADD_PHOTO: "Add a photo",
        ADD_PHOTO_SUBTEXT: " to help us better understand your concerns.",
        PHONE_TITLE: "Please provide phone number(UPI linked) for a smooth refund process*",
        PHONE_PLACEHOLDER: "Enter UPI linked phone number",
        REFUND_INFO: "* Refund is subject to approval",
        SEND_MESSAGE: "Send message",
    },
    DISCLAIMER:
        "Once we receive all information, we will inspect the same internally and notify you of the status of your refund/ exchange. If a refund is approved, we will process it to the UPI phone number you've shared, typically within 5-7 business days. For exchange, we aim to dispatch the new items within 2-3 business days.",
};

export const RETURN_REASONS = [
    "Received damaged product",
    "Product missing",
    "Wrong product received",
    "Size or fit issues",
    "Other issues",
];

export const CAMERA_ICON_COLOR = "#B52A6B"; // Add to dls
export const UPLOAD_IMAGE_DISPLAY_SIZE = 64;
export const UPLOAD_LIMIT = 6;
