import React, { useEffect, useMemo } from "react";
import { Platform } from "react-native";

import { useMountedRef } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import { MakePaymentUpiMode } from "@minis-consumer/api/payment";
import {
    META_PIXEL_CONTENT_NAME,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { PaymentProgress } from "@minis-consumer/includes/payment-progress";
import { Cart as ICart } from "@minis-consumer/interfaces/cart";
import { Order } from "@minis-consumer/interfaces/order";

import {
    PAYMENT_GROUP_CATEGORY,
    PAYMENT_GROUP_METHOD,
    PAYMENT_WARNING_DELAY,
    PAYMENT_WARNING_TEXT,
    PAYNOW_METHOD_ANALYTICS,
    UPI_APPS,
} from "../constants";
import { isCardDetailsValid } from "../helpers";
import {
    useMakePaymentUsingCard,
    useMakePaymentUsingNetBanking,
    useMakePaymentUsingUpi,
} from "./use-make-payment";
import { usePaymentSelector } from "./use-selector";

export const useFooterPayment = (
    cart: ICart["cartViewData"],
    order?: Order | null,
): { payNow: VoidFunction; isPaymentValid: boolean } => {
    const [paymentInProgress, makePaymentViaUpi] = useMakePaymentUsingUpi();
    const [, makePaymentViaCard] = useMakePaymentUsingCard();
    const [, makePaymentViaBank] = useMakePaymentUsingNetBanking();
    const selectedPaymentMethod = usePaymentSelector((state) => state?.selectedPaymentMethod);
    const [isPaymentValid, setPaymentValidation] = React.useState(false);
    const paymentWithWarningDelay = useDelayWithWarningToast();
    const mountedRef = useMountedRef();

    const oldCardDetails = selectedPaymentMethod?.cardDetails?.oldCard;
    const newCardDetails = selectedPaymentMethod?.cardDetails?.newCard;

    const analyticsContext = useMemo(() => {
        return {
            orderId: order?.id || "-",
            cartId: cart?.id || "-",
            productType: cart?.cartType || order?.orderType,
            paymentAmount: cart?.bill?.finalBillTotal || order?.bill?.finalBillTotal,
            transactionId: order?.txnId,
        };
    }, [order, cart]);

    useEffect(() => {
        if (!mountedRef.current) {
            return;
        }
        Analytics.impressionEvent({
            category: "proceed-to-pay-btn",
            label: cart?.id,
            context: JSON.stringify(analyticsContext),
        });
    }, [cart?.id, analyticsContext, mountedRef]);

    const analyticsClickEvent = React.useCallback(
        (context: Record<string, string>) => {
            Analytics.clickEvent({
                category: "proceed-to-pay-btn",
                label: cart?.id,
                context: JSON.stringify({ ...context, ...analyticsContext }),
            });
        },
        [cart?.id, analyticsContext],
    );

    const performPaymentValidation = React.useCallback(() => {
        switch (selectedPaymentMethod?.category) {
            case PAYMENT_GROUP_CATEGORY.SAVED:
                // * for saved payment method
                switch (selectedPaymentMethod?.type) {
                    case PAYMENT_GROUP_METHOD.CARD:
                        if (!isCardDetailsValid(oldCardDetails, true)) {
                            return;
                        }
                        setPaymentValidation(true);

                        break;
                    case PAYMENT_GROUP_METHOD.UPI:
                        setPaymentValidation(true);
                        break;
                }
                break;

            case PAYMENT_GROUP_CATEGORY.NEW:
                // * for new payment method
                switch (selectedPaymentMethod?.type) {
                    case PAYMENT_GROUP_METHOD.CARD:
                        if (!newCardDetails) {
                            return;
                        }
                        const canPay = isCardDetailsValid(newCardDetails);

                        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.ADD_PAYMENT_INFO, {
                            content_name: META_PIXEL_CONTENT_NAME.CARD,
                        });

                        setPaymentValidation(!!canPay);
                        break;
                    case PAYMENT_GROUP_METHOD.UPI:
                        const isCustomUPI = selectedPaymentMethod?.value === "CUSTOM_UPI";

                        setPaymentValidation(
                            isCustomUPI ? !!selectedPaymentMethod?.extraData : true,
                        );
                        break;

                    case PAYMENT_GROUP_METHOD.NETBANKING:
                        setPaymentValidation(true);
                        break;
                }
                break;
        }
    }, [
        newCardDetails,
        oldCardDetails,
        selectedPaymentMethod?.category,
        selectedPaymentMethod?.extraData,
        selectedPaymentMethod?.type,
        selectedPaymentMethod?.value,
    ]);

    useEffect(() => {
        // * perform validation
        performPaymentValidation();
    }, [performPaymentValidation]);

    const payNow = React.useCallback(() => {
        switch (selectedPaymentMethod?.category) {
            case PAYMENT_GROUP_CATEGORY.SAVED:
                // * for saved payment method

                switch (selectedPaymentMethod?.type) {
                    case PAYMENT_GROUP_METHOD.CARD:
                        if (!oldCardDetails) {
                            return;
                        }

                        analyticsClickEvent({ type: PAYNOW_METHOD_ANALYTICS.SAVED_CARD });
                        const canPay = isCardDetailsValid(oldCardDetails, true);

                        if (canPay) {
                            paymentWithWarningDelay(() =>
                                makePaymentViaCard({
                                    type: "SAVED",
                                    cvv: oldCardDetails?.cvv,
                                    cardId: oldCardDetails?.cardId,
                                }),
                            );
                        }
                        break;
                    case PAYMENT_GROUP_METHOD.UPI:
                        if (paymentInProgress) {
                            return;
                        }

                        analyticsClickEvent({ type: PAYNOW_METHOD_ANALYTICS.SAVED_UPI });
                        makePaymentViaUpi(
                            "CUSTOM",
                            selectedPaymentMethod?.value,
                            undefined,
                            selectedPaymentMethod.packageName,
                        );
                        break;
                }
                break;

            case PAYMENT_GROUP_CATEGORY.NEW:
                // * for new payment method

                switch (selectedPaymentMethod?.type) {
                    case PAYMENT_GROUP_METHOD.CARD:
                        if (!newCardDetails) {
                            return;
                        }

                        analyticsClickEvent({ type: PAYNOW_METHOD_ANALYTICS.NEW_CARD });
                        const canPay = isCardDetailsValid(newCardDetails);

                        if (canPay) {
                            paymentWithWarningDelay(() =>
                                makePaymentViaCard(
                                    {
                                        type: "NEW",
                                        cardHolderName: newCardDetails?.cardHolderName.trim(),
                                        cardNumber: newCardDetails?.cardNumber.replace(/\s+/g, ""),
                                        cvv: newCardDetails?.cvv.trim(),
                                        cardExpiryMM: newCardDetails?.cardExpiryMM ?? "",
                                        cardExpiryYY: newCardDetails?.cardExpiryYY ?? "",
                                    },
                                    true,
                                ),
                            );
                        }
                        break;
                    case PAYMENT_GROUP_METHOD.UPI:
                        let UPIApp;
                        // need to send App info here.
                        switch (selectedPaymentMethod?.value) {
                            case UPI_APPS.BHIM_UPI.name:
                                UPIApp = "BHIM";
                                break;
                            case UPI_APPS.GOOGLE_PAY.name:
                                UPIApp = "GPAY";
                                break;
                            case UPI_APPS.PAYTM.name:
                                UPIApp = "PAYTM";
                                break;
                            case UPI_APPS.PHONEPE.name:
                                UPIApp = "PHONEPE";
                                break;
                            case "CUSTOM_UPI":
                                UPIApp = "CUSTOM";
                                break;
                            default:
                                UPIApp = "OTHERS";
                        }

                        analyticsClickEvent({
                            ...(selectedPaymentMethod || {}),
                            type: PAYNOW_METHOD_ANALYTICS.NEW_UPI,
                            params: UPIApp,
                            cardDetails: "",
                        });

                        if (UPIApp) {
                            makePaymentViaUpi(
                                UPIApp as MakePaymentUpiMode,
                                selectedPaymentMethod?.extraData,
                                true,
                                selectedPaymentMethod?.packageName,
                            );
                        }
                        break;

                    case PAYMENT_GROUP_METHOD.NETBANKING:
                        if (!selectedPaymentMethod?.value && !selectedPaymentMethod?.extraData) {
                            return;
                        }

                        analyticsClickEvent({
                            type: PAYNOW_METHOD_ANALYTICS.NEW_NETBANKING,
                            params: selectedPaymentMethod?.extraData ?? "",
                        });

                        paymentWithWarningDelay(() =>
                            makePaymentViaBank(
                                selectedPaymentMethod?.extraData as string,
                                selectedPaymentMethod?.value as string,
                            ),
                        );

                        break;
                }
                break;
        }
    }, [
        analyticsClickEvent,
        makePaymentViaBank,
        makePaymentViaCard,
        makePaymentViaUpi,
        newCardDetails,
        oldCardDetails,
        paymentInProgress,
        paymentWithWarningDelay,
        selectedPaymentMethod,
    ]);

    return { payNow, isPaymentValid };
};

// * Show warning before redirecting to external payment gateway
// * Primarily for Netbanking and Card payment on Web
export const useDelayWithWarningToast = (): ((cb?: VoidFunction) => void) => {
    const [toast] = useToast();
    const isWeb = Platform.OS === "web";

    const payWithDelayedWarning = React.useCallback(
        async (cb?: VoidFunction) => {
            if (!cb) {
                return;
            }
            if (!isWeb) {
                cb();
                return;
            }
            await PaymentProgress.save();
            toast(PAYMENT_WARNING_TEXT);
            setTimeout(cb, PAYMENT_WARNING_DELAY);
        },
        [isWeb, toast],
    );

    return payWithDelayedWarning;
};
