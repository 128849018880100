import * as React from "react";
import { StyleSheet } from "react-native";

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, TooltipPlacement } from "@swiggy-private/rn-dls";
import { format } from "@swiggy-private/common-helpers";

import { Analytics } from "@minis-consumer/analytics";
import { Badge } from "@minis-consumer/components/badge";
import { TimeBasedTooltipComponent } from "@minis-consumer/components/time-based-tooltip";
import { useChatStoreUnreadCount } from "@minis-consumer/hooks/use-chat-unread-count";
import { useShare } from "@minis-consumer/hooks/use-share";
import { Product } from "@minis-consumer/interfaces/catalog";
import { RouteList } from "@minis-consumer/interfaces/route";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useGetConfigInfo } from "@minis-consumer/hooks/use-get-config-info";
import { PRODUCT_SHARE_TEXT } from "@minis-consumer/constants/share";

import { IconContainer } from "../header/icon-container";
import { ANALYTICS_COPIES, COPIES } from "./constant";
import { HeaderLeftProps } from "../header-left";

const ICON_SIZE = 20;

export interface HeaderRightProps extends HeaderLeftProps {
    storeId: string;
    product: Product;
    isTooltipVisible?: boolean;
}

export const HeaderRight: React.FC<HeaderRightProps> = ({
    storeId,
    product,
    isTooltipVisible,
    color,
    containerStyle,
}) => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const share = useShare();
    const chatUnreadCount = useChatStoreUnreadCount(storeId) ?? 0;
    const store = useStoreInfo();

    const isNewServicesEnabled = useGetConfigInfo()?.isNewServicesEnabled;

    const onPressShare = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.HEADER_SHARE_STORE_BTN_CATEGORY,
            label: product.id,
        });

        share({
            product,
            description: format(PRODUCT_SHARE_TEXT, product.name, store.name),
        });
    }, [product, share, store.name]);

    const onPressChat = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.HEADER_CHAT_BTN_CATEGORY,
            label: product.id,
        });

        navigation.navigate("ProductForms", {
            id: product.id,
            slug: store.slug,
            storeId,
            isEnquireMode: true,
        });
    }, [navigation, storeId, product.id, store.slug]);

    const onPressSearch = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.HEADER_SEARCH_BTN_CATEGORY,
        });

        navigation.navigate("StoreCatalogSearch");
    }, [navigation]);

    const analyticsData = {
        category: ANALYTICS_COPIES.HEADER_CHAT_POPUP_CATEGORY,
        label: storeId,
        context: JSON.stringify({
            item_id: product.id,
        }),
    };

    return (
        <Box direction="row">
            <IconContainer
                accessibilityLabel="Search"
                style={containerStyle}
                onPress={onPressSearch}>
                <SvgIcon icon="Magnifier" color={color} width={ICON_SIZE} height={ICON_SIZE} />
            </IconContainer>

            {isNewServicesEnabled ? null : (
                <TimeBasedTooltipComponent
                    analytics={analyticsData}
                    isTooltipVisible={isTooltipVisible}
                    tooltipContentTitle={COPIES.TOOLTIP_TITLE}
                    tooltipContentDescription={COPIES.TOOLTIP_DESCRIPTION}
                    icon="Chat"
                    tooltipStyle={styles.toolTip}
                    placement={TooltipPlacement.BOTTOM_END}
                    tooltipContentClickHandler={onPressChat}>
                    <IconContainer
                        accessibilityLabel="Chat"
                        style={containerStyle}
                        onPress={onPressChat}>
                        <SvgIcon icon="Chat" color={color} width={ICON_SIZE} height={ICON_SIZE} />
                        <Badge count={chatUnreadCount} style={styles.badgeCount} />
                    </IconContainer>
                </TimeBasedTooltipComponent>
            )}

            <IconContainer accessibilityLabel="Share" style={containerStyle} onPress={onPressShare}>
                <SvgIcon icon="Share" color={color} width={ICON_SIZE} height={ICON_SIZE} />
            </IconContainer>
        </Box>
    );
};

const styles = StyleSheet.create({
    toolTip: {
        width: 250,
    },
    badgeCount: {
        top: -3 * SpacingValue["space-xxx-small"],
        right: -SpacingValue["space-xxx-small"],
    },
});
