import React from "react";
import { LayoutChangeEvent } from "react-native";

import { NavigationProp } from "@react-navigation/core";
import { useHeaderHeight } from "@react-navigation/elements";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useSuspense } from "@rest-hooks/react";

import { useMount } from "@swiggy-private/react-hooks";
import { useAppStateListener, useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { AsyncComponent } from "@minis-consumer/components/async-component";
import { HeaderLeft } from "@minis-consumer/components/guest-checkout/header";
import { withResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { getCancellationPolicies } from "@minis-consumer/routes/cart/helpers";
import { useOrderBasedDeliveryETA } from "@minis-consumer/hooks/use-delivery-eta";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useUserInfo } from "@minis-consumer/hooks/use-user";
import { PaymentProgress } from "@minis-consumer/includes/payment-progress";
import { PaymentRouteList } from "@minis-consumer/interfaces/route";
import { OrderDetail } from "@minis-consumer/resources/order";
import { CancellationPolicy } from "@minis-consumer/routes/cart/components/cancellation-policy";
import { useCartView } from "@minis-consumer/hooks/use-cart";

import { PaymentLayout } from "../../components/layout";
import { PaymentShimmer } from "../../components/shimmer";
import { withPaymentMainContainer } from "../../containers/main-container";
import { TLayoutProps } from "../../contexts/payment-layout-context";
import { usePaymentDispatch } from "../../hooks/use-dispatch";
import { useFetchPaymentMethods } from "../../hooks/use-fetch-payment-methods";
import { useNavigateToCart } from "../../hooks/use-navigate-cart";
import {
    usePaymentDropAddressId,
    usePaymentDropAddressLocation,
} from "../../hooks/use-payment-address";
import { usePaymentAmount } from "../../hooks/use-payment-amount";
import { usePaymentLayoutUpdates } from "../../hooks/use-payment-layout";
import { usePaymentStoreId } from "../../hooks/use-payment-store";
import { usePaymentSelector } from "../../hooks/use-selector";
import { PaymentAddress } from "./components/address";
import { PaymentMethodList } from "./components/payment-method-list";
import { PaymentHeader } from "../../components/header/mobile/header";

type PaymentHomeScreenProps = NativeStackScreenProps<PaymentRouteList, "PaymentHome">;

interface PaymentHomeContentProps {
    isGuestOrder?: boolean;
}

const PaymentHomeContent: React.FC<PaymentHomeContentProps> = () => {
    const navigation = useNavigation<NavigationProp<PaymentRouteList, "PaymentHome">>();

    const { value: theme } = useTheme();

    const userInfo = useUserInfo();
    const storeInfo = useStoreInfo();
    const headerHeight = useHeaderHeight();
    const cart = useCartView(storeInfo.storeId);
    const isPhysicalCart =
        cart?.cartViewData?.cartType === "PHYSICAL" || !cart?.cartViewData?.cartType;

    const dropAddressId = usePaymentDropAddressId();
    const dropAddressLocation = usePaymentDropAddressLocation();
    const hasDropAddress = isPhysicalCart ? Boolean(dropAddressId || dropAddressLocation) : true;
    const { fetchOrderETA } = useOrderBasedDeliveryETA();

    const fetchPaymentMethods = useFetchPaymentMethods();
    const order = usePaymentSelector((state) => state.order);
    const orderId = order?.id;
    const { updateLayout } = usePaymentLayoutUpdates();

    const navigateToCart = useNavigateToCart();
    const storeId = usePaymentStoreId();

    const isSignedInuser = !!userInfo;

    useMount(() => {
        if (!hasDropAddress && isPhysicalCart) {
            navigateToCart();
            return;
        }

        //* Fetches delivery ETA based on order
        order && fetchOrderETA?.(order);
        fetchPaymentMethods({ addressId: dropAddressId, orderId, location: dropAddressLocation });
    });

    const onLayout = React.useCallback(
        (e?: LayoutChangeEvent) => {
            const layout = e?.nativeEvent?.layout as unknown as TLayoutProps;
            if (!(layout?.width && layout?.left)) {
                return;
            }

            updateLayout({ left: layout?.left, width: layout?.width });
        },
        [updateLayout],
    );

    const paymentAmount = usePaymentAmount();

    useIsomorphicEffect(() => {
        isSignedInuser
            ? navigation.setOptions({
                  headerLeft: () => <PaymentHeader showTitle />,
                  headerTitle: () => <></>,
                  headerBackVisible: false,
                  headerStyle: {
                      backgroundColor: theme["color-background-primary"].toString(),
                  },
              })
            : navigation.setOptions({
                  headerLeft: () => <PaymentHeader />,
                  headerTitle: () => <HeaderLeft hasFullStoreName storeInfo={storeInfo} />,
                  headerBackVisible: false,
                  headerStyle: {
                      backgroundColor: theme["color-background-primary"].toString(),
                  },
              });
    }, [headerHeight, isSignedInuser, navigation, paymentAmount, storeInfo, theme]);

    // * redirect user to cart-page, if an existing payment was in progress
    useAppStateListener(
        React.useCallback(
            async (isAppActive) => {
                if (!isAppActive) {
                    return;
                }
                const wasPaymentInProgress = await PaymentProgress.load();
                if (wasPaymentInProgress) {
                    navigateToCart();
                    return;
                }
            },
            [navigateToCart],
        ),
    );

    const cancellationPolicy = React.useMemo(() => {
        return getCancellationPolicies(cart?.cartViewData?.cartType ?? "PHYSICAL");
    }, [cart?.cartViewData?.cartType]);

    if ((!hasDropAddress && isPhysicalCart) || !storeId) {
        return null;
    }

    if (!paymentAmount) {
        return <PaymentShimmer />;
    }

    const left = (
        <Stack
            onLayout={onLayout}
            nativeID="lhs-payment-container"
            spacing={{
                default: SpacingValue["space-x-large"],
                md: SpacingValue["space-xx-large"],
                lg: SpacingValue["space-xx-large"],
            }}>
            <PaymentAddress />
            <PaymentMethodList />
        </Stack>
    );

    const right = (
        <Stack spacing={SpacingValue["space-medium"]}>
            <CancellationPolicy policy={cancellationPolicy} />
        </Stack>
    );

    return <PaymentLayout left={left} right={right} />;
};

const PaymentHomeWithOrder: React.FC<{ orderId: string }> = ({ orderId }) => {
    const Content: React.FC = () => {
        const order = useSuspense(OrderDetail, orderId);
        const dispatch = usePaymentDispatch();

        useMount(() => {
            dispatch({ type: "UPDATE_PAYMENT_ORDER", payload: { order } });
        });

        return null;
    };

    return (
        <AsyncComponent fallback={<PaymentShimmer />}>
            <Content />
        </AsyncComponent>
    );
};

const PaymentHomeContainer: React.FC<PaymentHomeScreenProps> = ({ route }) => {
    const { orderId } = route.params ?? {};

    const order = usePaymentSelector((state) => state.order);

    if (orderId && (!order || order.id !== orderId)) {
        return <PaymentHomeWithOrder orderId={orderId} />;
    }

    return <PaymentHomeContent />;
};

export const PaymentHome = React.memo(
    withResetErrorBoundary(
        withPaymentMainContainer(PaymentHomeContainer, {
            backgroundColor: "color-background-secondary",
            canShowGuestCheckoutDeliveryFee: true,
            shouldShowPaymentFooter: true,
        }),
    ),
);
