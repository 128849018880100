import React from "react";
import { Pressable, StyleSheet, View, ViewStyle } from "react-native";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SimpleMenu } from "@swiggy-private/react-native-ui";

import { ClearCartConfirmationDialog } from "../../clear-cart-confirmation";

interface Props {
    storeId: string;
    style?: ViewStyle;
}

const KebabMenuComponent: React.FC<Props> = ({ storeId, style }) => {
    const { value: theme } = useTheme();
    const anchorEl = React.useRef<View>(null);

    const [showDropdown, setShowDropdown] = React.useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);

    const openConfirmation = React.useCallback(() => setShowDeleteConfirmation(true), []);
    const openDropdown = React.useCallback(() => setShowDropdown(true), []);
    const closeDropdown = React.useCallback(() => setShowDropdown(false), []);

    const hideConfirmation = React.useCallback(() => {
        closeDropdown();
        setShowDeleteConfirmation(false);
    }, [closeDropdown]);

    const moreBackgroundStyle: ViewStyle = {
        backgroundColor: showDropdown
            ? theme["color-background-secondary"]
            : theme["color-background-primary"],
    };

    return (
        <Box>
            <Pressable
                ref={anchorEl}
                style={[styles.container, moreBackgroundStyle, style]}
                onPress={openDropdown}>
                <SvgIcon icon="More" height={16} width={16} color="color-basic-60" />
            </Pressable>

            {showDropdown && anchorEl.current ? (
                <SimpleMenu
                    onClose={closeDropdown}
                    anchorEl={anchorEl.current}
                    style={styles.dropdownContainer}>
                    <Pressable style={styles.dropdownItem} onPress={openConfirmation}>
                        <Text category="b2" weight="medium" color="critical">
                            Clear cart
                        </Text>
                    </Pressable>
                </SimpleMenu>
            ) : null}

            <ClearCartConfirmationDialog
                showModal={showDeleteConfirmation}
                closeModal={hideConfirmation}
                storeId={storeId}
            />
        </Box>
    );
};

export const KebabMenu = React.memo(KebabMenuComponent);

const styles = StyleSheet.create({
    container: {
        height: 36,
        width: 36,
        borderRadius: 18,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    dropdownContainer: {
        paddingTop: 0,
        paddingBottom: 0,
        minWidth: 120,
        marginTop: 40,
    },
    dropdownItem: {
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-medium"],
    },
});
