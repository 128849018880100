export const BANK_LOGO_MAP: Record<string, string> = {
    /** Axis */
    "3003": "minis/axis",

    /** HDFC */
    "3021": "minis/hdfc",

    /** ICICI */
    "3022": "minis/icici",

    /** Kotak Mahindra */
    "3032": "minis/kotak",

    /** SBI */
    "3044": "minis/sbi",
};
