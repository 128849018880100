import { LinkingOptions } from "@react-navigation/native";
import { getStateFromPath } from "@react-navigation/core";
import { Linking, Platform } from "react-native";

import { CommonRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { MINIS_DOMAIN, MINIS_SITE_DOMAIN } from "@minis-consumer/constants";

export const storeRouteLinking = (
    initialUrl?: string | null,
): LinkingOptions<RouteList & CommonRouteList> => {
    const config: LinkingOptions<RouteList & CommonRouteList> = {
        prefixes: [`https://*.${MINIS_DOMAIN}`, `https://*.${MINIS_SITE_DOMAIN}`],

        getInitialURL() {
            if (initialUrl) {
                return initialUrl;
            }

            return Linking.getInitialURL();
        },

        getStateFromPath(path, options) {
            return getStateFromPath(path, options);
        },

        // Custom function to subscribe to incoming links
        subscribe(listener) {
            // Listen to incoming links from deep linking
            const fn = ({ url }: { url: string }): void => {
                listener(url);
            };

            const linkingSubscription = Linking.addEventListener("url", fn);

            return () => {
                if (linkingSubscription != null) {
                    linkingSubscription.remove();
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    Linking.removeEventListener("url", fn);
                }
            };
        },

        config: {
            initialRouteName: "Home",
            screens: {
                Home: {
                    path: "",
                    screens: {
                        Shop: {
                            path: "",
                        },
                        About: {
                            path: "about",
                        },
                        Cart: {
                            path: "cart",
                        },
                        MyAccount: {
                            path: "my-account",
                        },
                    },
                },

                Product: {
                    //For mobile we need to pass slug as well as id
                    path: Platform.OS === "web" ? "/products/:id" : "/products/:id/:slug",
                    parse: {
                        id: String,
                        slug: String,
                    },
                },

                Address: {
                    path: "address",
                    initialRouteName: "SearchLocation",
                    screens: {
                        SearchLocation: "",
                        Map: { path: "map" },
                        AddressForm: { path: "form" },
                    },
                },

                Account: {
                    path: "account",
                    screens: {
                        FollowedStores: { path: "followed-stores" },
                        FAQ: { path: "faq" },
                        Help: {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            initialRouteName: "HelpHome",
                            path: "help",
                            screens: {
                                HelpHome: { path: "" },
                                HelpDetailsLevelOne: {
                                    path: "/:level1",
                                    parse: { level1: String },
                                },
                                HelpDetailsLevelTwo: {
                                    path: "/:level1/:level2",
                                    parse: { level1: String, level2: String },
                                },
                            },
                        },
                        ProfileSettings: { path: "settings" },
                        SavedAddresses: { path: "saved-address" },
                        TermsAndConditions: { path: "terms-and-conditions" },
                    },
                },

                Payment: {
                    path: "payment",
                    screens: {
                        PaymentHome: "",
                        PaymentProceed: {
                            path: "proceed",
                            screens: {
                                PaymentTimer: "",
                                PaymentVerify: { path: "verify" },
                                PaymentSuccess: { path: "success" },
                            },
                        },
                    },
                },

                ChatConversation: {
                    path: "chat/:storeId",
                    parse: {
                        storeId: String,
                    },
                },

                RatingAndReview: {
                    path: "rating-and-review/:orderId/:storeId",
                    parse: {
                        orderId: String,
                        storeId: String,
                    },
                },

                GuestCheckout: {
                    path: "guest-checkout",
                },

                Auth: {
                    path: "auth",
                    screens: {
                        Login: "",
                        Signup: "signup",
                        VerifyOtp: "otp-verify",
                    },
                },

                Coupons: {
                    path: "coupons",
                    parse: {
                        cartId: String,
                    },
                },

                Tracking: {
                    path: "order-track/:id",
                    parse: {
                        id: String,
                    },
                },

                Order: {
                    path: "order/:id",
                    parse: {
                        id: String,
                    },
                },

                UpdateAddress: {
                    path: "update-address/:address",
                    parse: {
                        address: String,
                    },
                },

                SlotSelection: {
                    path: "slot-selection/:id/:slug",
                    parse: {
                        id: String,
                        slug: String,
                    },
                },

                NotFound: {
                    path: "*",
                },
            },
        },
    };

    return config;
};
