import { useMemo } from "react";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { StoreSettings } from "@minis-consumer/interfaces/store";
import { usePaymentOrder } from "./use-payment-order";

type PaymentStore = {
    storeId: string;
    name: string;
    slug: string;
    settings?: StoreSettings;
};

export const usePaymentStore = (): PaymentStore | null => {
    const order = usePaymentOrder();
    const storeInfo = useStoreInfo(false);

    return useMemo(
        () =>
            order
                ? {
                      storeId: order.storeDetails.id,
                      name: order.storeDetails.name,
                      slug: order.storeDetails.slug,
                      settings: {
                          ...order.storeDetails,
                      },
                  }
                : storeInfo
                ? storeInfo
                : null,
        [order, storeInfo],
    );
};

export const usePaymentStoreId = (): string | null => {
    const store = usePaymentStore();
    return store?.storeId ?? null;
};
