import React from "react";
import { Platform, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/core";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { useMount } from "@swiggy-private/react-hooks";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { Box, Container, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { Card } from "@minis-consumer/components/card";
import { Shimmer } from "@minis-consumer/components/shimmer";
import { Divider } from "@minis-consumer/components/divider";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import { BackgroundTheme } from "../background-theme";

const CardItems = new Array(Platform.OS === "web" ? 2 : 4).fill(false);

const LoadingPlaceholderComponent: React.FC = () => {
    const { value: theme } = useTheme();
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();

    const storeInfo = useStoreInfo(false);

    useMount(() => {
        navigation.setOptions({
            headerShown: false,
        });
    });

    const boxPaddingTop = React.useMemo(
        () => insets.top + (Platform.OS === "web" ? 80 : storeInfo ? 40 : 56),
        [insets.top, storeInfo],
    );

    const [containerFluid, disableContainerGutter] = useSelectScreen({
        default: [true, true],
        lg: [false, false],
    });

    return (
        <Box
            pt={boxPaddingTop}
            ph={SpacingValue["space-medium"]}
            flex={1}
            style={{ backgroundColor: theme["color-background-secondary"] }}>
            <BackgroundTheme loading />

            <Container fluid={containerFluid} disableGutters={disableContainerGutter}>
                <Box mb={SpacingValue["space-medium"]}>
                    <Stack spacing={SpacingValue["space-x-small"]} direction="row">
                        <Stack spacing={SpacingValue["space-x-small"]} flex={1}>
                            <Shimmer height={24} style={styles.shimmerBorderRadius} />
                            <Shimmer height={12} style={styles.shimmerBorderRadius} />
                        </Stack>

                        <Box flex={1} direction="row" alignItems="flex-end">
                            <Shimmer
                                height={12}
                                style={[styles.flex2, styles.shimmerBorderRadius]}
                            />
                            <Box flex={1} />
                        </Box>
                    </Stack>
                </Box>

                <Box mb={SpacingValue["space-xx-large"]}>
                    <Card style={styles.resetPaddingAndMargin}>
                        {CardItems.map((_, index) => (
                            <React.Fragment key={index}>
                                {index !== 0 ? (
                                    <Divider style={styles.resetPaddingAndMargin} />
                                ) : null}
                                <CardItemPlaceholder />
                            </React.Fragment>
                        ))}
                    </Card>
                </Box>

                <Stack spacing={SpacingValue["space-medium"]}>
                    <Box direction="row">
                        <Shimmer height={16} style={[styles.flex2, styles.shimmerBorderRadius]} />
                        <Box flex={3} />
                    </Box>

                    <Card>
                        <Stack spacing={SpacingValue["space-medium"]}>
                            <Stack spacing={SpacingValue["space-medium"]} direction="row">
                                <Shimmer
                                    height={48}
                                    width={48}
                                    style={styles.shimmerBorderRadius}
                                />

                                <Box flex={1} direction="row">
                                    <Stack spacing={SpacingValue["space-x-small"]} flex={2}>
                                        <Shimmer height={16} style={styles.shimmerBorderRadius} />
                                        <Shimmer height={8} style={styles.shimmerBorderRadius} />
                                    </Stack>

                                    <Box flex={1} />
                                </Box>

                                <Box alignItems="center" justifyContent="center">
                                    <Shimmer
                                        height={16}
                                        width={34}
                                        style={styles.shimmerBorderRadius}
                                    />
                                </Box>
                            </Stack>

                            <DashBorderLine
                                borderColor={theme["color-basic-30"]}
                                style={styles.resetPaddingAndMargin}
                            />

                            <Stack spacing={SpacingValue["space-xx-small"]}>
                                <Shimmer
                                    height={16}
                                    width="33%"
                                    style={styles.shimmerBorderRadius}
                                />
                                <Shimmer
                                    height={8}
                                    width="90%"
                                    style={styles.shimmerBorderRadius}
                                />
                                <Shimmer
                                    height={8}
                                    width="70%"
                                    style={styles.shimmerBorderRadius}
                                />
                            </Stack>

                            <DashBorderLine
                                borderColor={theme["color-basic-30"]}
                                style={styles.resetPaddingAndMargin}
                            />

                            <Shimmer height={40} style={styles.shimmerBorderRadius} />
                        </Stack>
                    </Card>
                </Stack>
            </Container>
        </Box>
    );
};

const CardItemPlaceholder: React.FC = () => (
    <Box ph={SpacingValue["space-medium"]} pv={SpacingValue["space-large"]}>
        <Stack direction="row" spacing={SpacingValue["space-small"]}>
            <Shimmer height={16} width={16} style={styles.shimmerBorderRadius} />
            <Shimmer height={16} style={[styles.flex, styles.shimmerBorderRadius]} />
        </Stack>
    </Box>
);

export const LoadingPlaceholder = React.memo(LoadingPlaceholderComponent);

const styles = StyleSheet.create({
    flex: {
        flex: 1,
    },
    flex2: {
        flex: 2,
    },
    resetPaddingAndMargin: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginTop: 0,
        marginBottom: 0,
    },
    shimmerBorderRadius: {
        borderRadius: 10,
    },
});
