import * as React from "react";
import { StyleSheet } from "react-native";
import { useSuspense } from "@rest-hooks/react";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme, Button } from "@swiggy-private/rn-dls";

import { ExpansionTile } from "@minis-consumer/routes/account/components/expansion-tile";
import { FAQHelpPlaceholder } from "@minis-consumer/routes/account/components/faq-help-placeholder";
import { Faq } from "@minis-consumer/resources/faq-help";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { useLinking } from "@minis-consumer/hooks/use-linking";

export type IFaqItem = {
    title: string;
    description: string;

    ctaText?: string;
    minisSupportEmailID?: string;
};

const FAQContentComponent: React.FC = () => {
    const data = useSuspense(Faq) as Record<string, IFaqItem[]>;
    const openUrl = useLinking();

    const onPress = React.useCallback(
        (faq: IFaqItem) => {
            faq.minisSupportEmailID && openUrl(`mailto:${faq.minisSupportEmailID}`);
        },
        [openUrl],
    );

    return (
        <>
            {Array.isArray(data.faqs)
                ? data.faqs.map((faq, index) => {
                      return (
                          <ExpansionTile
                              key={faq.title + index}
                              title={faq.title}
                              description={faq.description}
                              isDividerPresent={index !== data.faqs.length - 1}>
                              {faq.ctaText ? (
                                  <Button onPress={() => onPress(faq)}>{faq.ctaText}</Button>
                              ) : null}
                          </ExpansionTile>
                      );
                  })
                : null}
        </>
    );
};

export const FAQContent: React.FC = () => {
    const { value: theme } = useTheme();
    const bgPrimaryColor = theme["color-background-primary"];

    const containerColor = { backgroundColor: bgPrimaryColor };
    const containerStyle = useSelectScreen({
        lg: {
            marginVertical: SpacingValue["space-xx-large"],
        },
        default: {
            marginVertical: SpacingValue["space-medium"],
        },
    });

    return (
        <AsyncComponent fallback={FAQHelpPlaceholder}>
            <Box style={[containerStyle, styles.contentBorderRadius, containerColor]}>
                <FAQContentComponent />
            </Box>
        </AsyncComponent>
    );
};

const styles = StyleSheet.create({
    contentBorderRadius: { borderRadius: 16 },
    shimmer: { marginHorizontal: SpacingValue["space-medium"] },
});
