import * as React from "react";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

import { ShopBackground } from "@minis-consumer/routes/shop/ux2.0/components/background";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { BLAZE_EVENTS, recordAppLoadTime } from "@minis-consumer/includes/blaze";

import { ShopDetails } from "../details";

type IProps = {
    setDeliveryInfoOffset: (offset: number) => void;
    poweredMinisPressHandler: VoidFunction;

    storyId?: string;
};

const ShopFrontComponent: React.FC<IProps> = (props) => {
    const storeInfo = useStoreInfo();

    const isStoreEnabled = storeInfo.enabled !== false;

    const imageId = storeInfo.settings.backgroundImageId;

    useMount(() => {
        recordAppLoadTime(BLAZE_EVENTS.LOAD_TIME.STORE_CARD);
    });

    return (
        <Box>
            <ShopBackground
                isStoreEnabled={isStoreEnabled}
                imageId={imageId}
                poweredMinisPressHandler={props.poweredMinisPressHandler}
            />

            <ShopDetails
                setDeliveryInfoOffset={props.setDeliveryInfoOffset}
                storyId={props.storyId}
            />
        </Box>
    );
};

export const ShopFront = React.memo(ShopFrontComponent);
