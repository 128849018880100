import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

import { Card } from "@minis-consumer/components/card";
import { Slate } from "@minis-consumer/components/slate";

const CancellationPolicyLoadingComponent: React.FC = () => {
    return (
        <Card>
            <Slate height={28} width={148} borderRadius={8} />
            <Stack style={styles.mt16} spacing={SpacingValue["space-x-small"]}>
                <Policy />
                <Policy />
                <Policy />
                <Policy />
            </Stack>
        </Card>
    );
};

const Policy: React.FC = () => {
    return (
        <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
            <Slate height={10} width={10} borderRadius={8} />
            <Slate height={10} width={230} borderRadius={8} />
        </Stack>
    );
};

const styles = StyleSheet.create({
    mt16: {
        marginTop: SpacingValue["space-medium"],
    },
});

export const CancellationPolicyLoader = React.memo(CancellationPolicyLoadingComponent);
