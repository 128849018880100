import React from "react";
import { StyleSheet } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { useTheme } from "@swiggy-private/rn-dls";

const IndicatorLineComponent: React.FC = () => {
    const { value: theme } = useTheme();

    return (
        <Box style={styles.container}>
            <Box style={[styles.line, { backgroundColor: theme["color-primary"] }]} />
        </Box>
    );
};

export const IndicatorLine = React.memo(IndicatorLineComponent);

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    line: {
        flex: 1,
        width: 3,
    },
});
