import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, Text, Surface, useTheme } from "@swiggy-private/rn-dls";
import { Stack, Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";

import { Divider } from "@minis-consumer/components/divider";
import { ShareParams } from "@minis-consumer/hooks/use-share";
import { Analytics } from "@minis-consumer/analytics";

const ICON_HEIGHT = 16;
const ICON_WIDTH = 16;

interface ProductShareProps {
    helperText: string;
    onShare: (social?: ShareParams["social"]) => void;
    productId: string;
}

export const ProductShare: React.FC<ProductShareProps> = ({ helperText, onShare, productId }) => {
    const { value: theme } = useTheme();

    const dividerStyle = {
        marginTop: SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-large"],
    };

    const shareButtonBorder = {
        borderColor: theme["color-basic-15"],
    };

    const onPressWhatsapp = React.useCallback(() => {
        Analytics.clickEvent({
            category: "share-product-whatsapp-btn",
            label: productId,
        });

        onShare("whatsapp");
    }, [productId, onShare]);

    const onPressShareMenu = React.useCallback(() => {
        Analytics.clickEvent({
            category: "share-product-meatballs-menu-btn",
            label: productId,
        });

        onShare();
    }, [productId, onShare]);

    useMount(() => {
        Analytics.impressionEvent({
            category: "share-product-whatsapp-btn",
            label: productId,
        });

        Analytics.impressionEvent({
            category: "share-product-meatballs-menu-btn",
            label: productId,
        });
    });

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={{
                    default: SpacingValue["space-medium"],
                }}>
                <Box flex={2}>
                    <Text category="b3">{helperText}</Text>
                </Box>
                <Stack
                    direction="row"
                    alignItems="flex-end"
                    spacing={{
                        default: SpacingValue["space-medium"],
                    }}>
                    <Surface style={[styles.actionBtn, shareButtonBorder]}>
                        <SvgIcon
                            icon="Whatsapp"
                            color="color-primary"
                            height={ICON_HEIGHT}
                            width={ICON_WIDTH}
                            onPress={onPressWhatsapp}
                            hitSlop={{
                                left: 16,
                                right: 16,
                                top: 16,
                                bottom: 16,
                            }}
                        />
                    </Surface>
                    <Surface style={[styles.actionBtn, shareButtonBorder]}>
                        <Box style={styles.moreBtn}>
                            <SvgIcon
                                icon="More"
                                color="color-primary"
                                height={ICON_HEIGHT}
                                width={ICON_WIDTH}
                                onPress={onPressShareMenu}
                                hitSlop={{
                                    left: 16,
                                    right: 16,
                                    top: 16,
                                    bottom: 16,
                                }}
                            />
                        </Box>
                    </Surface>
                </Stack>
            </Stack>
            <Divider style={dividerStyle} />
        </Box>
    );
};

const styles = StyleSheet.create({
    actionBtn: {
        height: 36,
        width: 36,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: SpacingValue["space-x-small"],
        borderWidth: 1,
    },
    moreBtn: {
        transform: [{ rotate: "90deg" }],
    },
});
