import React from "react";
import {
    ImageStyle,
    Platform,
    StyleProp,
    StyleSheet,
    useWindowDimensions,
    ViewStyle,
} from "react-native";
import LinearGradient from "react-native-linear-gradient";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useHeaderHeight } from "@react-navigation/elements";

import {
    CdnImageBackgroundProps,
    CdnImageBackground,
    CdnImage,
} from "@swiggy-private/react-native-ui";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { getStoreBackgroundImageUrl } from "@minis-consumer/helpers/store";

interface ShopBackgroundProps {
    isStoreEnabled: boolean;
    imageId?: string | null;
    style?: StyleProp<ImageStyle>;
    imageStyle?: StyleProp<ImageStyle>;
    children?: React.ReactNode;
}

const FALLBACK_IMAGE_ID = "minis/no_brand_background_fallback";
const GRADIENT_COLORS = ["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 0.3)"];
const STORE_DELETED_BG_IMAGE = "minis/storefront_store_deleted";
const STORE_DELETED_BG_IMAGE_WIDTH = 144;
const STORE_DELETED_BG_IMAGE_HEIGHT = 90;

const ShopBackgroundComponent: React.FC<ShopBackgroundProps> = ({
    isStoreEnabled,
    imageId,
    style: propStyle,
    imageStyle: propImageStyle,
    children,
}) => {
    const { value: theme } = useTheme();
    const { width: windowWidth } = useWindowDimensions();

    const insets = useSafeAreaInsets();
    const headerHeight = useHeaderHeight();

    const storeDeletedImageMarginTop = useSelectScreen({
        lg: 0,
        md: 0,
        default: Platform.OS === "web" ? headerHeight / 2 : (insets.top + headerHeight) / 2,
    });

    /** @TODO: Write RFC for a proper solve since images are getting pixelated in tab and desktop views */
    const [height, width, imageStyle] = useSelectScreen({
        lg: [160, undefined, styles.br],
        md: [200, undefined, null],
        default: [undefined, windowWidth, null],
    });

    const bgColor = isStoreEnabled ? theme["color-background-secondary"] : theme["color-basic-30"];
    const imageUrl = React.useMemo(
        () =>
            imageId
                ? getStoreBackgroundImageUrl(
                      imageId ?? FALLBACK_IMAGE_ID,
                      {
                          height,
                          width,
                          qualityAuto: "eco",
                      },
                      true,
                  )
                : null,
        [height, width, imageId],
    );

    return isStoreEnabled ? (
        <StoreBgImageWrapper
            imageUrl={imageUrl}
            height={height}
            width={width}
            imageStyle={[
                imageStyle,
                {
                    backgroundColor: imageUrl
                        ? theme["color-secondary-25"]
                        : theme["color-primary"],
                },
                propImageStyle,
            ]}
            style={[{ backgroundColor: bgColor, height }, propStyle]}>
            <LinearGradient
                colors={GRADIENT_COLORS}
                locations={[0, 1]}
                style={[styles.backgroundContainer, propStyle]}>
                {children}
            </LinearGradient>
        </StoreBgImageWrapper>
    ) : (
        <StoreDeleteDetailWrapper style={{ backgroundColor: bgColor, height }}>
            <CdnImage
                isImageKitEnabled
                id={STORE_DELETED_BG_IMAGE}
                height={STORE_DELETED_BG_IMAGE_HEIGHT}
                width={STORE_DELETED_BG_IMAGE_WIDTH}
                style={[styles.storeDeletedBgImage, { marginTop: storeDeletedImageMarginTop }]}
            />
            <Box style={[styles.backgroundContainer, propStyle]}>{children}</Box>
        </StoreDeleteDetailWrapper>
    );
};

interface StoreBgImageWrapper {
    imageUrl: string | null;
    height?: number;
    width?: number;
    style?: StyleProp<ImageStyle>;
    imageStyle?: StyleProp<ImageStyle>;
    children?: CdnImageBackgroundProps["children"];
}

const StoreBgImageWrapper: React.FC<StoreBgImageWrapper> = ({
    imageUrl,
    height,
    width,
    style: propStyle,
    imageStyle: propImageStyle,
    children,
}) => {
    return (
        <CdnImageBackground
            isImageKitEnabled
            resizeMode="cover"
            id={imageUrl ?? FALLBACK_IMAGE_ID}
            height={height}
            width={width}
            imageStyle={propImageStyle}
            style={propStyle}>
            {children}
        </CdnImageBackground>
    );
};

interface StoreDeleteDetailWrapperProps {
    style?: StyleProp<ImageStyle>;
    children?: React.ReactNode;
}

const StoreDeleteDetailWrapper: React.FC<
    React.PropsWithChildren<StoreDeleteDetailWrapperProps>
> = ({ style, children }) => {
    const [borderBottomHorizontalRadius, borderTopHorizontalRadius, justifyContent] =
        useSelectScreen({
            lg: [0, SpacingValue["space-medium"], "center"],
            md: [0, 0, "center"],
            default: [SpacingValue["space-xx-large"], 0, "flex-start"],
        });

    const storeDeletedBgStyle = {
        borderBottomLeftRadius: borderBottomHorizontalRadius,
        borderBottomRightRadius: borderBottomHorizontalRadius,
        borderTopLeftRadius: borderTopHorizontalRadius,
        borderTopRightRadius: borderTopHorizontalRadius,
        justifyContent,
    } as ViewStyle;

    return <Box style={[storeDeletedBgStyle, style]}>{children}</Box>;
};

const styles = StyleSheet.create({
    br: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        width: "100%",
    },
    backgroundContainer: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
    storeDeletedBgImage: {
        alignSelf: "center",
    },
});

export const ShopBackground = React.memo(ShopBackgroundComponent);
