import React from "react";
import {
    FlatList,
    ListRenderItem,
    StyleSheet,
    KeyboardAvoidingView,
    Platform,
    Keyboard,
    NativeSyntheticEvent,
    NativeScrollEvent,
} from "react-native";
import { useCache, useController, useFetch, useSuspense } from "@rest-hooks/react";
import { useLoading } from "@rest-hooks/hooks";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { NavigationProp, useFocusEffect, useNavigation } from "@react-navigation/core";
import { useHeaderHeight } from "@react-navigation/elements";
import { useRoute } from "@react-navigation/native";

import { Portal, SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

import {
    CartList,
    FetchClearCartFeedbackOptions,
    SoftClearCart,
} from "@minis-consumer/resources/cart";
import { ICartsListItem } from "@minis-consumer/interfaces/cart";
import { Logger } from "@minis-consumer/includes/logger";
import { usePaginator } from "@minis-consumer/hooks/use-paginator";
import { MinisRouteList } from "@minis-consumer/interfaces/route";
import { CART_API_ERRORS } from "@minis-consumer/api/cart";
import { EmptyContent } from "@minis-consumer/components/empty-content-screen";
import { useMinisNavigation } from "@minis-consumer/hooks/use-minis-navigation";
import { GenericFeedbackCard } from "@minis-consumer/components/generic-feedback-card";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { CartDispatchContext } from "@minis-consumer/contexts/cart-context";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { useBottomBarScrollContext } from "@minis-consumer/contexts/bottom-bar-scroll-context";
import { useEmptyAbandonedCartContent } from "../../hooks/use-empty-abandoned-cart-content";
import { COPIES } from "../../constants";
import { FeedbackSnackbar } from "../feedback-snackbar";
import { AbandonedCartCard } from "../abandoned-cart-card";
import { ListFooter } from "../list-footer";
import { AbandonedCartsLoader } from "../loader";

const RESULT_TYPE = "ABANDONED_CARTS_LIST";

const CartsListComponent: React.FC = React.memo(() => {
    const dispatch = React.useContext(CartDispatchContext);
    const flatlistRef = React.useRef<FlatList>(null);

    const { value: theme } = useTheme();
    const inset = useSafeAreaInsets();
    const headerHeight = useHeaderHeight();
    const [showToast] = useToast();
    const navigation = useNavigation<NavigationProp<MinisRouteList>>();
    const minisNavigation = useMinisNavigation();
    const { setScrollPos, resetPageScroll, setScreenContext } = useBottomBarScrollContext();

    const cartListData = useSuspense(CartList, { type: RESULT_TYPE });
    const { invalidate, fetch } = useController();
    const getNextCartsPage = usePaginator(CartList, { type: RESULT_TYPE });
    const [fetchNextPage, isFetching] = useLoading(
        async (cursor: string) => getNextCartsPage?.(cursor),
        [getNextCartsPage],
    );

    useFetch(FetchClearCartFeedbackOptions)?.catch(Logger.recordError); //Prefetch feedback content

    const { content } = useEmptyAbandonedCartContent();
    const feedbackDataContent = useCache(FetchClearCartFeedbackOptions);
    const route = useRoute();

    const [hasBulkDeletionHappened, setBulkDeletionStatus] = React.useState(false);
    const [canShowSnackbar, setSnackbar] = React.useState(false);
    const [storeForFeedback, setStoreFeedbackName] = React.useState<string>();
    const [areAllIndividualCartsCleared, setIndividualClearStatus] = React.useState(false);
    const [deletedCartIds, setDeletedCartIds] = React.useState<string[]>([]);
    const [isBulkDeletionFeedbackSubmitted, setBulkDeletionFeedbackStatus] = React.useState(false);

    const carts = React.useMemo(
        () =>
            hasBulkDeletionHappened
                ? []
                : cartListData?.carts?.filter((cart) => !deletedCartIds.includes(cart.cartId)),
        [cartListData?.carts, deletedCartIds, hasBulkDeletionHappened],
    );

    const [onBulkDelete, isBulkDeletionInProgress] = useLoading(async () => {
        try {
            await fetch(SoftClearCart, { bulk: true });
            setBulkDeletionStatus(true);
            dispatch({ type: "BULK_CLEAR_CART_ACTION" });
        } catch (err) {
            showToast(CART_API_ERRORS.BULK_DELETE_CARTS);
        }
    }, [dispatch, fetch, showToast]);

    const onKeyboardDismiss = React.useCallback(() => Keyboard.dismiss(), []);

    const onStoreFeedbackSubmit = React.useCallback(
        (storeName?: string) => {
            setStoreFeedbackName(storeName);
            setSnackbar(true);
            onKeyboardDismiss();
        },
        [onKeyboardDismiss],
    );

    const onClearCartCb = React.useCallback(
        (cartId: string) => {
            if (carts.length === 1) {
                setIndividualClearStatus(true);
            }

            setDeletedCartIds((old) => [...old, cartId]);
        },
        [carts.length],
    );

    const onEndReached = React.useCallback(() => {
        if (!cartListData?.nextPageCursor || isFetching) {
            return;
        }

        fetchNextPage(cartListData.nextPageCursor);
    }, [cartListData.nextPageCursor, fetchNextPage, isFetching]);

    const onPressExploreMinis = React.useCallback(() => {
        if (minisNavigation !== null) {
            minisNavigation?.navigate("MinisHome", { screen: "Explore" });
        } else {
            navigation?.navigate("MinisHome", { screen: "Explore" });
        }
    }, [navigation, minisNavigation]);

    const onBulkFeedbackPressCb = React.useCallback(() => {
        setBulkDeletionFeedbackStatus(true);
        onKeyboardDismiss();
    }, [onKeyboardDismiss]);

    const onBulkFeedbackSubmit = React.useCallback(() => {
        setStoreFeedbackName(undefined);
        onBulkFeedbackPressCb();
        setSnackbar(true);
    }, [onBulkFeedbackPressCb]);

    const onScrollToInput = React.useCallback(
        (index: number) =>
            flatlistRef?.current?.scrollToIndex({
                animated: true,
                index,
            }),
        [],
    );

    const renderItem: ListRenderItem<ICartsListItem> = React.useCallback(
        ({ item, index }) => {
            return (
                <AbandonedCartCard
                    frontContent={item}
                    backContent={feedbackDataContent?.individualFeedbackOptions}
                    style={styles.abandonedCard}
                    cartOrderSequence={index}
                    onSubmitFeedback={onStoreFeedbackSubmit}
                    onClearCartCb={onClearCartCb}
                    onScrollToIndex={() => onScrollToInput(index)}
                />
            );
        },
        [
            feedbackDataContent?.individualFeedbackOptions,
            onClearCartCb,
            onScrollToInput,
            onStoreFeedbackSubmit,
        ],
    );

    const renderEmptyContent = (): React.ReactElement => {
        return (
            <>
                <EmptyContent
                    title={content?.title ?? ""}
                    description={content?.description}
                    ctaText={COPIES.EMPTY_CART_CTA_TEXT}
                    imageId={COPIES.EMPTY_IMAGE_ID}
                    onPressCta={onPressExploreMinis}
                    style={[
                        styles.emptyContent,
                        { backgroundColor: theme["color-background-secondary"] },
                    ]}
                />

                {canShowSnackbar ? (
                    <Portal>
                        <FeedbackSnackbar
                            canShowSnackbar={canShowSnackbar}
                            onControlSnackbar={setSnackbar}
                        />
                    </Portal>
                ) : null}
            </>
        );
    };

    const renderFooter = (): React.ReactElement => {
        return (
            <ListFooter
                isFetching={isFetching}
                showClearAllCartsCta={carts?.length >= 2}
                onBulkDelete={onBulkDelete}
                isLoading={isBulkDeletionInProgress}
            />
        );
    };

    const extraFlatlistStyles = {
        backgroundColor: theme["color-background-secondary"],
    };

    const contentExtraStyles = {
        paddingBottom: Math.max(inset.bottom, SpacingValue["space-x-large"]),
    };

    useFocusEffect(
        React.useCallback(() => {
            invalidate(CartList, { type: RESULT_TYPE }).catch((err) => Logger.recordError(err));
            setScreenContext("Carts");
            return () => {
                resetPageScroll();
            };
        }, [resetPageScroll, setScreenContext, invalidate]),
    );

    const onContentScroll = React.useCallback(
        (event: NativeSyntheticEvent<NativeScrollEvent>) => {
            const offset = event.nativeEvent.contentOffset;
            setScrollPos(offset.y, route.name);
        },
        [setScrollPos, route],
    );

    if (areAllIndividualCartsCleared || isBulkDeletionFeedbackSubmitted) {
        return renderEmptyContent();
    }

    if (hasBulkDeletionHappened) {
        return (
            <GenericFeedbackCard
                {...feedbackDataContent?.bulkFeedbackOptions}
                onPressClose={onBulkFeedbackPressCb}
                onPressCta={onBulkFeedbackSubmit}
                containerStyle={[styles.feedbackContainer, extraFlatlistStyles]}
                isBulk
                canEnableFeedbackCta
            />
        );
    }

    return (
        <Stack flex={1} flexGrow={1}>
            <KeyboardAvoidingView
                behavior={Platform.OS === "ios" ? "padding" : "height"}
                keyboardVerticalOffset={headerHeight}
                style={styles.keyboard}>
                <FlatList
                    ref={flatlistRef}
                    keyboardShouldPersistTaps="handled"
                    showsVerticalScrollIndicator={false}
                    ListEmptyComponent={renderEmptyContent}
                    testID="cart-list"
                    data={carts}
                    contentContainerStyle={[styles.flatlistContent, contentExtraStyles]}
                    renderItem={renderItem}
                    keyExtractor={(item) => item.cartId}
                    style={[styles.flatlist, extraFlatlistStyles]}
                    onEndReached={onEndReached}
                    onEndReachedThreshold={1}
                    onScroll={onContentScroll}
                    refreshing={isFetching}
                    ListFooterComponent={renderFooter}
                    ListFooterComponentStyle={styles.footerContentStyle}
                    removeClippedSubviews={false}
                />
            </KeyboardAvoidingView>

            {canShowSnackbar ? (
                <Portal>
                    <FeedbackSnackbar
                        canShowSnackbar={canShowSnackbar}
                        storeName={storeForFeedback}
                        onControlSnackbar={setSnackbar}
                    />
                </Portal>
            ) : null}
        </Stack>
    );
});

if (__DEV__) {
    CartsListComponent.displayName = "CartsListComponent";
}

const CartsListMain: React.FC = () => {
    return (
        <AsyncComponent fallback={AbandonedCartsLoader}>
            <CartsListComponent />
        </AsyncComponent>
    );
};

const styles = StyleSheet.create({
    emptyContent: {
        flex: 1,
        flexGrow: 1,
    },
    flatlist: {
        flex: 1,
        flexGrow: 1,
        paddingHorizontal: SpacingValue["space-medium"],
        paddingTop: SpacingValue["space-large"],
    },
    flatlistContent: {
        flexGrow: 1,
    },
    abandonedCard: {
        marginBottom: SpacingValue["space-large"],
    },
    feedbackContainer: {
        flex: 1,
        paddingTop: SpacingValue["space-large"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    keyboard: {
        flexGrow: 1,
        height: "100%",
    },
    footerContentStyle: {
        flex: 1,
        justifyContent: "center",
    },
});

export const CartsList = React.memo(CartsListMain);
