import * as React from "react";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { Card } from "@minis-consumer/components/card";
import { DashBorderLineWithText } from "@minis-consumer/components/guest-checkout/dash-border-line-with-text";
import { GuestCheckoutDeliveryForm } from "@minis-consumer/components/guest-checkout/delivery-form";
import { LoginWithSwiggy } from "@minis-consumer/components/guest-checkout/login-with-swiggy";

interface GuestCheckoutDesktopProps {
    storeId: string;
}

const DeliveryFormWrapper: React.FC<{ footer: React.ReactNode; content: React.ReactNode }> = ({
    content,
    footer,
}) => {
    return (
        <Stack spacing={SpacingValue["space-large"]}>
            <Card>{content}</Card>
            {footer}
        </Stack>
    );
};

export const GuestCheckoutDesktop: React.FC<GuestCheckoutDesktopProps> = () => {
    const { value: theme } = useTheme();

    return (
        <Stack spacing={SpacingValue["space-x-large"]}>
            <LoginWithSwiggy />
            <Box alignItems="center" justifyContent="center">
                <DashBorderLineWithText
                    text="OR CONTINUE AS GUEST"
                    borderColor={theme["color-secondary-400"]}
                    type="solid"
                />
            </Box>

            <GuestCheckoutDeliveryForm canCollapse={false} Wrapper={DeliveryFormWrapper} />
        </Stack>
    );
};
