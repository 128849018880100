import React from "react";
import { StyleSheet } from "react-native";

import { useMount } from "@swiggy-private/react-hooks";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { Analytics } from "@minis-consumer/analytics";
import { Divider } from "@minis-consumer/components/divider";
import type { Product, Variant } from "@minis-consumer/interfaces/catalog";
import {
    META_PIXEL_CONTENT_CATEGORY,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { useLocalCartItem, useLocalCartVariantForm } from "@minis-consumer/hooks/use-cart";
import { useGetProductCtaProps } from "@minis-consumer/components/product-cta/hooks/use-get-cta-actions";
import { ProductCta } from "@minis-consumer/components/product-cta";

interface ProductPriceProps {
    id: Product["id"];
    name: Product["name"];
    storeId: Product["storeId"];
    inStock: Product["inStock"];

    selectedVariant?: Variant | null;
    price?: Product["price"];
    type?: Product["productType"];
    discountedPrice?: Product["discountedPrice"];
    discountPercent?: Product["discountPercent"];
}

export const ProductPrice: React.FC<ProductPriceProps> = ({
    id,
    name,
    price,
    type,
    storeId,
    inStock,
    selectedVariant,
    discountedPrice,
    discountPercent,
}) => {
    const { value: theme } = useTheme();
    const variantId = selectedVariant?.id;

    const containerStyle = useSelectScreen({
        lg: {
            marginTop: SpacingValue["space-large"],
        },
        default: {
            marginTop: variantId ? SpacingValue["space-x-large"] : SpacingValue["space-medium"],
        },
    });

    const [, increment, decrement] = useLocalCartItem({
        storeId: storeId,
        itemId: id,
        productType: type ?? "PHYSICAL",
    });

    const dividerStyle = {
        marginTop: SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-large"],
    };

    const buttonStyle = React.useMemo(() => {
        return {
            borderColor: inStock ? "transparent" : theme["color-basic-15"],
        };
    }, [inStock, theme]);

    const { getVariantItemFormInCart } = useLocalCartVariantForm({ storeId });

    const incrementProduct = React.useCallback(() => {
        const itemForm = getVariantItemFormInCart(variantId ?? "");

        increment(variantId, { itemForm });
        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.ADD_TO_CART, {
            content_name: name,
            value: String(discountedPrice ?? price),
            content_category: META_PIXEL_CONTENT_CATEGORY.PRODUCT_DETAILS,
        });
    }, [increment, variantId, name, discountedPrice, price, getVariantItemFormInCart]);

    const decrementProduct = React.useCallback(() => {
        decrement(variantId);
    }, [variantId, decrement]);

    useMount(() => {
        if (inStock && price) {
            Analytics.impressionEvent({
                category: "add-to-cart-btn",
                label: id,
            });
        }

        if (inStock && !price) {
            Analytics.impressionEvent({
                category: "enquire-price-btn",
                label: id,
            });
        }
    });

    const productCtaActionsAndStyles = useGetProductCtaProps({
        productId: id,
        storeId,
        isPdp: true,
        buttonProps: {
            style: [styles.actionBtn, buttonStyle],
            elevation: 0,
        },
        selectedVariant: selectedVariant?.id,
        productActionCtaName: "pdp-product-action-cta",
        onAdd: incrementProduct,
        onRemoveOrDecrement: decrementProduct,
    });

    return (
        <Box style={containerStyle}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={{
                    default: SpacingValue["space-medium"],
                }}>
                <Stack justifyContent="center" flex={1} style={styles.priceStack}>
                    {price ? (
                        <Stack spacing={SpacingValue["space-xxx-small"]}>
                            <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                                <Text category="header_H4_Black" weight="regular">
                                    ₹{discountedPrice ? discountedPrice : price}
                                </Text>

                                {discountedPrice && discountPercent ? (
                                    <Box
                                        style={[
                                            styles.discountPercentLabel,
                                            {
                                                backgroundColor: theme["color-warning-50"],
                                                borderColor: theme["color-warning-600"],
                                            },
                                        ]}
                                        justifyContent="center"
                                        alignItems="center">
                                        <Text
                                            category="b3"
                                            weight="regular"
                                            color="color-warning-800">
                                            ({discountPercent}% Off)
                                        </Text>
                                    </Box>
                                ) : null}
                            </Stack>

                            {discountedPrice ? (
                                <Text
                                    category="b3"
                                    weight="regular"
                                    style={styles.strikeOff}
                                    color="color-basic-60">
                                    ₹{price}
                                </Text>
                            ) : null}
                        </Stack>
                    ) : (
                        <Text category="b1" weight="medium">
                            *Price on request
                        </Text>
                    )}
                </Stack>

                <ProductCta
                    {...productCtaActionsAndStyles}
                    productId={id}
                    storeId={storeId}
                    price={price}
                    type={type}
                    selectedVariant={selectedVariant}
                />
            </Stack>

            <Divider style={dividerStyle} />
        </Box>
    );
};

const styles = StyleSheet.create({
    productDescription: {
        marginTop: SpacingValue["space-x-small"],
    },
    actionBtn: {
        height: 40,
        minHeight: 40,
        minWidth: 136,
        maxWidth: 160,
        borderRadius: 8,
    },
    strikeOff: {
        textDecorationLine: "line-through",
    },
    priceStack: {
        flexWrap: "wrap",
    },
    discountPercentLabel: {
        paddingHorizontal: 6,
        paddingVertical: SpacingValue["space-xxx-small"],
        borderRadius: 4,
        borderBottomWidth: 1,
    },
    button: {
        fontSize: 16,
    },
});
