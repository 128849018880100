import React from "react";
import { StyleSheet } from "react-native";

import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { PaymentDowntimeNudge } from "@minis-consumer/components/payment-downtime-nudge";
import { usePaymentSelector } from "@minis-consumer/routes/payment/hooks/use-selector";

import { NewPaymentGroup } from "./components/group/new-payment";
import { OtherPaymentGroup } from "./components/group/other-payment";
import { SavedPaymentGroup } from "./components/group/saved-payment";

const PaymentMethodListComponent: React.FC = () => {
    const savedPaymentMethod = usePaymentSelector(
        (state) => state.paymentMethods,
    ).paymentGroup.find((group) => group.groupName === "SAVED_OPTIONS");

    const paymentStatus = usePaymentSelector((state) => state.payments);

    const showSavedPaymentMethod = React.useMemo(
        () =>
            savedPaymentMethod &&
            !savedPaymentMethod.disabled &&
            savedPaymentMethod.paymentMethods.length > 0,
        [savedPaymentMethod],
    );

    const isPaymentDown = React.useMemo(
        () => paymentStatus && !paymentStatus?.active,
        [paymentStatus],
    );

    const paddingHorizontal = useSelectScreen({
        default: SpacingValue["space-medium"],
        lg: 0,
    });

    return (
        <Box ph={paddingHorizontal} mb={SpacingValue["space-small"]}>
            <PaymentDowntimeNudge
                style={styles.downtime}
                showNudge={isPaymentDown}
                message={isPaymentDown ? paymentStatus?.message : undefined}>
                {!showSavedPaymentMethod ? (
                    <NewPaymentGroup />
                ) : (
                    <Stack spacing={SpacingValue["space-xx-large"]}>
                        <SavedPaymentGroup methods={savedPaymentMethod?.paymentMethods} />
                        <OtherPaymentGroup />
                    </Stack>
                )}
            </PaymentDowntimeNudge>
        </Box>
    );
};

export const PaymentMethodList = React.memo(PaymentMethodListComponent);

const styles = StyleSheet.create({
    downtime: {
        borderRadius: 12,
        marginBottom: SpacingValue["space-x-large"],
    },
});
