import React from "react";
import { Pressable, StyleSheet } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/core";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { DashBorderLine, InView } from "@swiggy-private/react-native-ui";
import { Container, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { Analytics } from "@minis-consumer/analytics";
import { Card } from "@minis-consumer/components/card";
import { Order } from "@minis-consumer/interfaces/order";
import { RouteList } from "@minis-consumer/interfaces/route";

import { CardHeader } from "./card-header";
import { getStatusProgress } from "../../helper";
import { StatusProgress } from "../status-progress";
import { OrderInformation } from "./order-information";
import { ANALYTICS_COPIES } from "../constants";

interface Props {
    order: Order;
    isActive?: boolean;
}

const OrderCardComponent: React.FC<Props> = ({ order, isActive }) => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NavigationProp<RouteList>>();
    const transactionFailed = order.status === "TRANSACTION_FAILED";

    const navigateToOrder = React.useCallback(() => {
        navigation.navigate("Order", { id: order.id });
    }, [navigation, order.id]);

    const analyticsData = React.useMemo(() => {
        return {
            category: isActive
                ? ANALYTICS_COPIES.ACTIVE_ORDERS_DETAILS_CARD
                : ANALYTICS_COPIES.PAST_ORDERS_DETAILS_CARD,
            label: order.id,
            context: JSON.stringify({ status: order.status }),
        };
    }, [order.status, order.id, isActive]);

    const goToOrder = React.useCallback(() => {
        Analytics.clickEvent(analyticsData);

        navigateToOrder();
    }, [navigateToOrder, analyticsData]);

    const statusProgress = React.useMemo(
        () => getStatusProgress(order.status, order.orderType ?? "PHYSICAL"),
        [order.status],
    );

    const [containerFluid, disableContainerGutter] = useSelectScreen({
        default: [true, true],
        lg: [false, false],
    });

    const onImpression = React.useCallback(
        (isVisbile: boolean) => {
            if (!isVisbile) {
                return;
            }

            Analytics.impressionEvent(analyticsData);
        },
        [analyticsData],
    );

    if (!order) {
        return null;
    }

    return (
        <InView onChange={onImpression}>
            <Container fluid={containerFluid} disableGutters={disableContainerGutter}>
                <Pressable onPress={goToOrder}>
                    <Card
                        style={[
                            styles.card,
                            {
                                borderColor: transactionFailed
                                    ? theme["color-critical-100"]
                                    : theme["color-basic-15"],
                            },
                        ]}>
                        <Stack spacing={SpacingValue["space-medium"]}>
                            <CardHeader
                                storeLogo={order.storeDetails.settings?.brandImageId}
                                storeName={order.storeDetails.name}
                                storeAddress={order.pickupAddress}
                                numberOfItems={order.items.length}
                                amount={order.bill.billTotal}
                            />

                            <Stack spacing={SpacingValue["space-x-small"]}>
                                {isActive && statusProgress ? (
                                    <StatusProgress
                                        fillWidth={statusProgress.fillWidth}
                                        type={statusProgress.type}
                                    />
                                ) : (
                                    <DashBorderLine
                                        style={styles.dashedSeparator}
                                        borderColor={theme["color-basic-15"]}
                                    />
                                )}

                                <OrderInformation
                                    order={order}
                                    isActive={isActive}
                                    goToOrder={navigateToOrder}
                                />
                            </Stack>
                        </Stack>
                    </Card>
                </Pressable>
            </Container>
        </InView>
    );
};

export const OrderCard = React.memo(OrderCardComponent);

const styles = StyleSheet.create({
    card: {
        borderWidth: 1,
        elevation: 0,
    },
    dashedSeparator: {
        marginTop: 0,
        marginBottom: 0,
    },
});
