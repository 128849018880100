import { PaymentType } from "@minis-consumer/interfaces/order";

export const SECTION_TITLE = "ITEM AND BILL DETAILS";
export const CUSTOM_PRODUCTS = "CUSTOM PRODUCTS";

export const ORDER_PAYMENT_TYPE_MAP: Record<PaymentType, string> = {
    CARD: "Card",
    NETBANKING: "Netbanking",
    UPI: "UPI",
};
