import React, { useCallback } from "react";
import {
    FlatList,
    ListRenderItem,
    Platform,
    Pressable,
    StyleProp,
    StyleSheet,
    useWindowDimensions,
    ViewStyle,
} from "react-native";

import { Layout, SpacingValue, Text, useLayout, useTheme } from "@swiggy-private/rn-dls";
import {
    Box,
    ScreenSize,
    Stack,
    useScreenSize,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { InstagramMedia } from "@minis-consumer/interfaces/store";
import { INSTAGRAM_EMPTY } from "@minis-consumer/constants/images";
import { getNumberInShorthandFormat } from "@minis-consumer/helpers/number";

import { EnquireDialog } from "./components/enquire-dialog";
import { SendEnquiryBottomSheet } from "./components/send-enquiry-bottomsheet";

type ShopImagesProps = {
    photos?: InstagramMedia[];
    handleName?: string;
    followersCount?: number;
};

export const ShopImages: React.FC<ShopImagesProps> = ({ photos, handleName, followersCount }) => {
    const storeInfo = useStoreInfo();
    const [layout, onLayout] = useLayout();

    const formattedFollowersCountString = React.useMemo(
        () =>
            `${getNumberInShorthandFormat(followersCount ?? 0)} ${
                followersCount === 1 ? "follower" : "followers"
            }`,
        [followersCount],
    );

    const containerStyle = useSelectScreen({
        lg: {
            paddingHorizontal: SpacingValue["space-x-large"],
            paddingBottom: SpacingValue["space-x-large"],
        },
        default: {
            paddingHorizontal: SpacingValue["space-medium"],
            paddingBottom: SpacingValue["space-x-large"],
        },
    });
    const filteredPhotos = (photos || [])?.filter((val) => val.media_url || val?.thumbnail_url);

    const isEmpty = React.useMemo(() => !filteredPhotos?.length, [filteredPhotos?.length]);
    const isInstagramConnected = React.useMemo(() => Boolean(handleName), [handleName]);

    const instagramEmptyTitle = React.useMemo(() => {
        if (isInstagramConnected) {
            return `@${handleName} has no photos uploaded (${formattedFollowersCountString})`;
        }

        return `${storeInfo.name} has no photos uploaded`;
    }, [formattedFollowersCountString, handleName, isInstagramConnected, storeInfo.name]);

    return (
        <Box style={containerStyle} onLayout={onLayout}>
            <Stack
                spacing={{
                    default: SpacingValue["space-medium"],
                }}>
                <Text category="b3" color="color-basic-45">
                    {isEmpty
                        ? instagramEmptyTitle
                        : `@${handleName} Instagram photos (${formattedFollowersCountString})`}
                </Text>
                <ImageGrid photos={filteredPhotos} parentLayout={layout} />
            </Stack>
        </Box>
    );
};

const ImageGrid: React.FC<{ photos: ShopImagesProps["photos"]; parentLayout: Layout }> = ({
    photos,
    parentLayout,
}) => {
    const [open, setOpen] = React.useState(false);
    const [showSendEnquiry, setShowSendEnquiry] = React.useState(false);
    const [selectedPhotoUrl, setSelectedPhotoUrl] = React.useState<string>();

    const screenSize = useScreenSize();
    const { width: screenWidth } = useWindowDimensions();

    const numColumns = React.useMemo(() => (screenSize === ScreenSize.Large ? 5 : 3), [screenSize]);

    const parentWidth = React.useMemo(
        () => (parentLayout.measured ? parentLayout.width : null),
        [parentLayout.measured, parentLayout.width],
    );

    const itemSize = React.useMemo(() => {
        if (screenSize !== ScreenSize.Large) {
            return (screenWidth - 48) / numColumns;
        }

        return parentWidth ? Math.min(115, (parentWidth - 80) / numColumns) : 115;
    }, [numColumns, parentWidth, screenSize, screenWidth]);

    const columnStyle: StyleProp<ViewStyle> = {
        width: "100%",
        flexDirection: "row",
    };

    const onMakeAnEnquiry = React.useCallback(() => {
        setOpen(false);
        setShowSendEnquiry(true);
    }, []);

    const onPressPhoto = React.useCallback((url: string) => {
        setSelectedPhotoUrl(url);
        setOpen(true);
    }, []);

    const { value: theme } = useTheme();

    const renderItem: ListRenderItem<InstagramMedia> = useCallback(
        ({ item, index }) => {
            return (
                <Pressable key={index + item?.id} onPress={() => onPressPhoto(item.media_url)}>
                    <CdnImage
                        isImageKitEnabled
                        width={itemSize}
                        height={itemSize}
                        id={item.media_url}
                        style={[
                            styles.image,
                            {
                                width: itemSize,
                                height: itemSize,
                                backgroundColor: theme["color-basic-5"],
                            },
                        ]}
                    />
                </Pressable>
            );
        },
        [itemSize, onPressPhoto, theme],
    );

    return (
        <>
            <FlatList
                bounces={false}
                numColumns={numColumns}
                columnWrapperStyle={columnStyle}
                data={photos}
                renderItem={renderItem}
                keyExtractor={(item: InstagramMedia) => item.id}
                contentContainerStyle={styles.flatList}
                ListEmptyComponent={<EmptyScreen />}
            />

            <EnquireDialog
                imgUrl={selectedPhotoUrl || ""}
                open={open}
                setOpen={setOpen}
                onMakeAnEnquiry={onMakeAnEnquiry}
            />

            <SendEnquiryBottomSheet
                imgUrl={selectedPhotoUrl || ""}
                show={showSendEnquiry}
                onShow={setShowSendEnquiry}
            />
        </>
    );
};

const EmptyScreen: React.FC = () => {
    const storeInfo = useStoreInfo();

    const { width: screenWidth } = useWindowDimensions();

    const imgWidth = Math.min(screenWidth * 0.87, 326);
    const imgHeight = imgWidth * 0.9;

    return (
        <Stack flex={1} alignItems="center" justifyContent="center">
            <CdnImage
                isImageKitEnabled
                id={INSTAGRAM_EMPTY}
                style={[styles.emptyImage, { width: imgWidth, height: imgHeight }]}
            />
            <Stack spacing={SpacingValue["space-x-small"]} alignItems="center">
                <Text category="h4" color="high" style={styles.text}>
                    We couldn&apos;t find any photos
                </Text>
                <Text category="b2" color="color-basic-45" style={styles.text}>
                    {`Looks like ${storeInfo.name} is yet to upload photos to their Instagram. You should check back later`}
                </Text>
            </Stack>
        </Stack>
    );
};

const styles = StyleSheet.create({
    flatList: {
        flex: 1,
        justifyContent: "space-evenly",
    },
    image: {
        borderRadius: SpacingValue["space-x-small"],
        marginBottom: SpacingValue["space-x-small"],
        marginRight: SpacingValue["space-x-small"],
    },
    text: {
        textAlign: "center",
        marginBottom:
            Platform.OS === "web" ? SpacingValue["space-large"] : SpacingValue["space-medium"],
    },
    emptyImage: {
        marginBottom: 2 * SpacingValue["space-xx-large"],
    },
});
