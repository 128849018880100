import { Linking } from "react-native";

import { FetchPaymentMethodsResponse } from "@minis-consumer/api/payment";
import { MINIS_DOMAIN } from "@minis-consumer/constants";
import { formatNumberWithIndianSystem, getNumber } from "@minis-consumer/helpers/number";
import { CardInfoResponse } from "@minis-consumer/includes/juspay";
import { PaymentMethodType } from "@minis-consumer/interfaces/payment";
import { IUPIAppsList } from "@minis-consumer/includes/swiggy-rn-module";

import {
    CARD_LOGO_MAP,
    CUSTOM_UPI_HANDLER,
    DEFAULT_UPI_HANDLER,
    MASTER_UPI_APPS_LIST,
    UPI_APPS,
    UPI_APPS_PRIORITY,
    UPI_HANDLER_MAP,
} from "../constants";
import { TNewCard, TOldCard } from "../hooks/use-make-payment";
import { UPIApps } from "../types";

export const getPaymentAmount = (amount: FetchPaymentMethodsResponse["paymentAmount"]): string => {
    const num = getNumber(amount);
    if (num === false) {
        return "";
    }

    return formatNumberWithIndianSystem(num, { showRupeeSymbol: true });
};

export const getPaymentProceedTitle = (paymentMethod: PaymentMethodType): string => {
    switch (paymentMethod.toLowerCase()) {
        case "card":
            return "Pay with Credit/Debit card";

        case "upi":
            return "Pay with UPI";
    }

    return "";
};

/** New stores payments working on this link */
export const APP_REDIRECT_URI = `https://${MINIS_DOMAIN}/redirect`;

export const getPaymentRedirectUrl = (): string => {
    let baseUrl = APP_REDIRECT_URI;

    if (typeof window !== "undefined" && window.location) {
        baseUrl = `${location.origin}/payment/proceed/verify`;
    }

    return `${baseUrl}?order_id={order_id}&order_token={order_token}`;
};

export const getCardLogoFromJuspayCardBrand = (cardType: CardInfoResponse["brand"]): string => {
    if (CARD_LOGO_MAP[cardType.toLowerCase()]) {
        return CARD_LOGO_MAP[cardType.toLowerCase()];
    }

    /**
     * Could be Diner's
     * Juspay gives "DINERS" whereas minis use "diner"
     */
    if (cardType === "DINERS") {
        return CARD_LOGO_MAP.diner;
    }

    return "";
};

const getUpiLogoFromHandler = (
    handler: string,
    type: "collectLogoUrl" | "intentLogoUrl",
): string => {
    const app = UPI_HANDLER_MAP[handler] || DEFAULT_UPI_HANDLER;
    return UPI_APPS[app][type];
};

export const getUpiCollectLogoFromHandler = (handler: string): string =>
    getUpiLogoFromHandler(handler, "collectLogoUrl");

export const getUpiIntentLogoFromHandler = (handler: string): string =>
    getUpiLogoFromHandler(handler, "intentLogoUrl");

const availableApps: Record<UPIApps, boolean> = {
    GOOGLE_PAY: false,
    PHONEPE: false,
    PAYTM: false,
    BHIM_UPI: false,
    AMAZON_PAY: false,
    CUSTOM_UPI: false,
};

export const getAvailableUpiApps = async (): Promise<Record<UPIApps, boolean>> => {
    const uri =
        "upi/pay?pa=cfminis@yesbank&pn=Minis&tr=680393042&am=5.00&cu=INR&mode=00&purpose=00&mc=5814&tn=680393042";

    await Linking.canOpenURL(`phonepe://${uri}`)
        .then((r) => {
            availableApps.PHONEPE = r;
        })
        .catch((_) => false);

    await Linking.canOpenURL(`paytm://${uri}`)
        .then((r) => {
            availableApps.PAYTM = r;
        })
        .catch((_) => false);

    // secondary urlschema for paytm
    if (!availableApps.PAYTM) {
        await Linking.canOpenURL(`paytmmp://${uri.substring(4)}`)
            .then((r) => {
                availableApps.PAYTM = r;
            })
            .catch((_) => false);
    }

    await Linking.canOpenURL(`tez://${uri}`)
        .then((r) => {
            availableApps.GOOGLE_PAY = r;
        })
        .catch((_) => false);

    availableApps.CUSTOM_UPI = true;

    return availableApps;
};

export const isCardDetailsValid = (
    cardDetails?: TNewCard | TOldCard,
    isSavedCard?: boolean,
): boolean => {
    const savedCard = cardDetails as TOldCard;
    const newCard = cardDetails as TNewCard;

    if (!cardDetails) {
        return false;
    }

    if (isSavedCard) {
        return !!savedCard?.cvv && !!savedCard?.cardId && savedCard?.cvv.length >= 3;
    }

    const doesCardFieldsAreFilled =
        !!newCard?.cardNumber &&
        !!newCard?.cvv &&
        !!newCard?.cardExpiryMM &&
        !!newCard?.cardExpiryYY &&
        !!newCard?.cardHolderName;

    const doesCardsHaveValidFields =
        doesCardFieldsAreFilled &&
        newCard?.cardNumber.length >= 13 &&
        newCard?.cvv.length >= 3 &&
        newCard?.cardExpiryMM.length === 2 &&
        newCard?.cardExpiryYY.length === 2 &&
        newCard?.cardHolderName.length > 0;

    return doesCardsHaveValidFields;
};

/**
 * TODO - Check for optimisations.
 * @param apps
 * @returns
 */
export const sortUpiAppsByPriority = (apps: IUPIAppsList[]): IUPIAppsList[] => {
    if (!apps.length) {
        return apps;
    }

    let index = 0;
    const updatedList = [...apps];

    UPI_APPS_PRIORITY.forEach((app) => {
        const priorityAppIdx = updatedList.findIndex((availableApp) => {
            const { label = "" } = availableApp;
            if (!label) {
                return false;
            }
            const formattedAppName = label.replace(/[_\s]/g, "").toLowerCase();
            return app.includes(formattedAppName);
        });

        if (priorityAppIdx >= 0) {
            if (priorityAppIdx !== index) {
                // get the element on current priority pos.
                const tempEle = updatedList[index];

                // replace the app with the priority app.
                const priorityApp = updatedList.splice(priorityAppIdx, 1);
                updatedList[index] = priorityApp?.[0];
                // push the removed ele to last.
                updatedList.push(tempEle);
            }
            index++;
        }
    });

    // push custom upi at last.
    updatedList.push(CUSTOM_UPI_HANDLER);
    return updatedList;
};

const masterUpiAppsSet = new Set(
    MASTER_UPI_APPS_LIST.map((item) => item.packageName.toLowerCase()),
);

export const filterUPIApps = (apps: IUPIAppsList[]): IUPIAppsList[] =>
    apps.filter((app) => masterUpiAppsSet.has(app?.packageName?.toLowerCase?.() ?? ""));

export const getFormattedTitle = (title: string): string => {
    const wordsInTitle = title.split(" ");
    const formattedWordsInTitle = wordsInTitle.map((word) => {
        if (word.toLowerCase() === "upi") {
            return "UPI";
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    const formattedTitle = formattedWordsInTitle.join(" ");
    return formattedTitle;
};
