import * as React from "react";
import { Linking, ListRenderItem, StyleSheet } from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SocialMetricsWidget } from "@swiggy-private/connect-business-commons";
import { InView, IOFlatList } from "@swiggy-private/react-native-ui";

import { ISocialMetrics, Store } from "@minis-consumer/interfaces/store";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";
import { Analytics } from "@minis-consumer/analytics";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";
import { Logger } from "@minis-consumer/includes/logger";
import { hasHttpUrlScheme } from "@minis-consumer/routes/shop/helpers";

import { transformMetricsData } from "./helpers";

const SocialMetricsComponent: React.FC<{
    socialMetrics: Store["socialMetrics"];
}> = ({ socialMetrics }) => {
    const transformedData = React.useMemo(
        () => transformMetricsData(socialMetrics),
        [socialMetrics],
    );

    const openUrl = React.useCallback(async (url: string) => {
        await Linking.canOpenURL(url)
            .then((supported) => {
                if (supported) {
                    return Linking.openURL(url);
                }

                return Promise.resolve();
            })
            .catch(Logger.recordError);
    }, []);

    if (!socialMetrics?.length) {
        return null;
    }

    const renderItem: ListRenderItem<ISocialMetrics> = ({ item }) => {
        const { header, subtext, url: redirectionLink, type: metricType } = item;

        const analyticsData = {
            category: SF_ANALYTICS_EVENT_NAMES.SOCIAL_METRICS,
            context: JSON.stringify({
                headerValue: header,
                subtext,
                redirectionLink,
                type: metricType,
            }),
        };

        const onImpression = (isVisible: boolean): void => {
            isVisible && Analytics.impressionEvent(analyticsData);
        };

        const onPress = (): void => {
            if (!item.url) {
                return;
            }
            Analytics.clickEvent(analyticsData);

            const hasUrlScheme = hasHttpUrlScheme(item.url);

            openUrl(hasUrlScheme ? item.url : `http://${item.url}`);
        };

        return (
            <InView onChange={onImpression}>
                <SocialMetricsWidget
                    {...item}
                    cardStyle={styles.cardStyle}
                    onPress={onPress}
                    testID={TEST_IDS_SF.SOCIAL_METRIC}
                    textContainerStyle={styles.card}
                />
            </InView>
        );
    };

    return (
        <IOFlatList
            horizontal
            data={transformedData}
            scrollEnabled
            renderItem={renderItem}
            contentContainerStyle={styles.containerStyle}
            showsHorizontalScrollIndicator={false}
            keyExtractor={(_item: ISocialMetrics, index) => `social-metric-${index}`}
            ItemSeparatorComponent={() => <Box ml={SpacingValue["space-small"]} />}
        />
    );
};

export const SocialMetrics = React.memo(SocialMetricsComponent);

SocialMetrics.displayName = "SocialMetrics";

const styles = StyleSheet.create({
    containerStyle: {
        paddingBottom: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-xxx-small"],
    },
    card: {
        paddingRight: SpacingValue["space-x-small"],
    },
    cardStyle: {
        borderWidth: 1,
        shadowOpacity: 0,
        elevation: 0,
    },
});
