import React, { memo } from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";

import { DATE_FORMAT, RETURN_ORDER_LABELS } from "../../constants";

interface IReturnOrderRejectDetailsComponent {
    reason: string;
    rejectedAt: number;

    additionalDetails?: string;
    showHeaderText?: boolean;
}

const SIZE = 16;

const ReturnOrderRejectDetailsComponent: React.FC<IReturnOrderRejectDetailsComponent> = ({
    reason = "",
    rejectedAt,
    additionalDetails,
    showHeaderText = true,
}) => {
    const { value: theme } = useTheme();

    if (!rejectedAt) {
        return null;
    }

    const innerContainerStyles: ViewStyle = {
        borderColor: theme["color-basic-15"],
        backgroundColor: theme["color-basic-5"],
    };

    const formattedRejectedTime = convertToReadableDateAndTime(rejectedAt, DATE_FORMAT);

    return (
        <Stack spacing={SpacingValue["space-small"]}>
            {showHeaderText ? (
                <Stack alignItems="center" spacing={SpacingValue["space-xx-small"]} direction="row">
                    <SvgIcon icon="CrossFilled" color="color-basic-60" width={SIZE} height={SIZE} />
                    <Text
                        category="b2"
                        color="high">{`${RETURN_ORDER_LABELS.REJECTED_REQUEST} ${formattedRejectedTime}`}</Text>
                </Stack>
            ) : null}
            {reason ? (
                <Stack
                    spacing={SpacingValue["space-small"]}
                    style={[styles.innerContainer, innerContainerStyles]}>
                    <Stack>
                        <Text category="b3" color="medium">
                            {RETURN_ORDER_LABELS.REASON_FOR_REJECTION}
                        </Text>
                        <Text category="b3" weight="medium" color="color-basic-75">
                            {reason}
                        </Text>
                    </Stack>
                    {additionalDetails ? (
                        <Stack>
                            <Text category="b3" color="medium">
                                {RETURN_ORDER_LABELS.ADDITIONAL_DETAILS}
                            </Text>
                            <Text
                                style={styles.textStyles}
                                category="b3"
                                weight="medium"
                                color="color-basic-75">
                                {`"${additionalDetails}"`}
                            </Text>
                        </Stack>
                    ) : null}
                </Stack>
            ) : null}
        </Stack>
    );
};

export const ReturnOrderRejectDetails = memo(ReturnOrderRejectDetailsComponent);

const styles = StyleSheet.create({
    textStyles: {
        fontStyle: "italic",
    },
    innerContainer: {
        borderWidth: 1,
        borderRadius: 8,
        padding: SpacingValue["space-small"],
    },
});
