import React from "react";

import { useCartStatus, useCartViewData } from "@minis-consumer/hooks/use-cart";
import { CartBill } from "@minis-consumer/interfaces/cart";
import { usePaymentSelector } from "@minis-consumer/routes/payment/hooks/use-selector";

import { Cart as ICart, CartStatuses } from "@minis-consumer/interfaces/cart";

interface IPaymentOrderData {
    cart: ICart["cartViewData"];
    cartStatus: CartStatuses;
    itemTotal: number;
    itemDiscount: number;
    couponDiscount: number;
    totalDiscount: number;
    discountedItemTotal: number;
    appliedCoupon: string;
    additionalCharges: CartBill["charges"];
    deliveryFee: CartBill["deliveryFee"];
    billTotal: number;
}

export const useOrderPaymentData = (storeId: string): IPaymentOrderData => {
    const cart = useCartViewData(storeId);
    const cartStatus = useCartStatus(storeId);
    const orderSelector = usePaymentSelector((state) => state.order);

    const itemTotal = cart?.bill?.itemTotal ?? orderSelector?.bill?.itemTotal ?? 0;
    const itemDiscount = cart?.bill?.itemDiscount ?? orderSelector?.bill?.itemDiscount ?? 0;
    const couponDiscount = cart?.bill?.couponDiscount ?? orderSelector?.bill?.couponDiscount ?? 0;
    const totalDiscount = React.useMemo(
        () => itemDiscount + couponDiscount,
        [itemDiscount, couponDiscount],
    );

    // sub total
    const discountedItemTotal =
        cart?.bill?.discountedItemTotal ?? orderSelector?.bill.finalBillTotal ?? 0;

    const appliedCoupon = cart?.appliedCoupon?.applied ? cart?.appliedCoupon?.code : "";
    const additionalCharges =
        cart?.bill?.charges ?? (orderSelector?.bill?.charges as CartBill["charges"]);
    const deliveryFee =
        cart?.bill?.deliveryFee ?? (orderSelector?.bill?.deliveryFee as CartBill["deliveryFee"]);
    const billTotal = cart?.bill?.billTotal ?? orderSelector?.bill?.billTotal ?? 0;

    return {
        cart,
        cartStatus,
        itemTotal,
        itemDiscount,
        couponDiscount,
        totalDiscount,
        discountedItemTotal,
        appliedCoupon,
        additionalCharges,
        deliveryFee,
        billTotal,
    };
};
