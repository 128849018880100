import * as React from "react";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/core";
import {
    NativeStackNavigationOptions,
    NativeStackNavigationProp,
} from "@react-navigation/native-stack";
import { useSuspense } from "@rest-hooks/react";

import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { ProductView } from "@minis-consumer/resources/catalog";
import { RouteList } from "@minis-consumer/interfaces/route";
import { Product } from "@minis-consumer/interfaces/catalog";
import { compactTitle } from "@minis-consumer/helpers/title";

import ProductFullView from "../view";

const ProductDataContentComponent: React.FC<{
    storeSlug: string;
    productId: string;
    storeId: string;
    isTooltipVisible?: boolean;
    variantId?: string;
    scrollToTop?: VoidFunction;
}> = ({ storeSlug, productId, storeId, isTooltipVisible, variantId, scrollToTop }) => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const { value: theme } = useTheme();

    const productView = useSuspense(ProductView, { storeSlug, productId });
    const product = productView.product as unknown as Product;
    const recommendedProductIds = productView.recommendedProductIds as unknown as Array<string>;

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerTitle: compactTitle(product.name),
            title: compactTitle(product.name),
            headerTitleStyle: [
                styles.headerTitleStyle,
                {
                    fontFamily: theme["text-body-1-bold-font-family"],
                    color: theme["color-basic-100"].toString(),
                    fontSize: theme["text-body-1-bold-font-size"],
                    lineHeight: theme["text-body-1-bold-line-height"],
                    letterSpacing: theme["text-body-1-bold-letter-spacing"],
                    marginTop: SpacingValue["space-xxx-small"],
                    fontWeight: "bold",
                },
            ] as NativeStackNavigationOptions["headerTitleStyle"],
            gestureEnabled: navigation.canGoBack(),
            headerBackVisible: true,
        });
    }, [navigation, product.name, theme]);

    return (
        <ProductFullView
            product={product}
            storeId={storeId}
            isTooltipVisible={isTooltipVisible}
            storeSlug={storeSlug}
            variantId={variantId}
            scrollToTop={scrollToTop}
            recommendedProductIds={recommendedProductIds}
        />
    );
};

export const ProductDataContent = React.memo(ProductDataContentComponent);

const styles = StyleSheet.create({
    headerTitleStyle: { alignItems: "center" },
    scrollContentStyle: { paddingBottom: SpacingValue["space-xx-large"] },
});
