import * as React from "react";
import { StyleSheet, Pressable, useWindowDimensions, FlatList, ListRenderItem } from "react-native";

import { SpacingValue, Text, useTheme, Theme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";

import { Divider } from "@minis-consumer/components/divider";
import { Cart, UserAddress } from "@minis-consumer/interfaces/cart";
import {
    AddressStorageType,
    displayAddressNameAndIcon,
    getAddressStorageMeta,
} from "@minis-consumer/helpers/address";
import { convertToProperCase } from "@minis-consumer/helpers/string";
import { Analytics } from "@minis-consumer/analytics";
import { useCompleteAddress } from "@minis-consumer/hooks/use-complete-address";

import { ANALYTICS_COPIES, MODAL_BODY_COPIES } from "../constants";

type AddressListComponentProps = {
    onAddNewAddressPress: () => void;
    addresses: NonNullable<Cart["cartViewData"]>["addresses"];
    setShowAddressSelected: (address: UserAddress) => void;
    closeDialog: () => void;
    onAddressMissingFields: (address: UserAddress) => void;
    calloutHeight: number;
    cartId?: string;
    shouldShowUnserviceable?: boolean;
};

type AddressItemProps = {
    item: UserAddress;
    index: number;
    onAddNewAddressPress: AddressListComponentProps["onAddNewAddressPress"];
    onAddressPress: (a: UserAddress) => void;
    theme: Theme;
    cartId?: string;
    shouldShowUnserviceable?: boolean;
};

const AdressItemComponent: React.FC<AddressItemProps> = (props) => {
    const {
        item,
        index,
        onAddNewAddressPress,
        onAddressPress,
        theme,
        cartId,
        shouldShowUnserviceable,
    } = props;
    const completeAddress = useCompleteAddress(item);

    const isUnserviceable = React.useMemo(
        () => shouldShowUnserviceable && item.serviceabilityType === "UNSERVICEABLE",
        [item.serviceabilityType, shouldShowUnserviceable],
    );

    const { addressTag, icon } = displayAddressNameAndIcon(item);

    const addressName = React.useMemo(() => convertToProperCase(addressTag), [addressTag]);

    return (
        <>
            {index === 0 ? (
                <AddNewAddress onAddNewAddressPress={onAddNewAddressPress} cartId={cartId} />
            ) : null}
            <Pressable onPress={() => onAddressPress(item)} disabled={isUnserviceable}>
                <Box flex={1} pv={SpacingValue["space-medium"]}>
                    <Stack spacing={SpacingValue["space-medium"]} flex={1} direction="row">
                        <Box
                            alignItems="center"
                            justifyContent="center"
                            style={[
                                styles.svgContainer,
                                {
                                    borderColor: theme["color-basic-15"],
                                },
                            ]}>
                            <SvgIcon
                                icon={icon}
                                height={20}
                                width={20}
                                color={isUnserviceable ? "color-basic-45" : "color-primary"}
                            />
                        </Box>

                        <Box flex={1}>
                            <Text
                                category="b1"
                                weight="bold"
                                color={isUnserviceable ? "color-basic-45" : "highest"}
                                style={styles.addressName}>
                                {addressName}
                            </Text>

                            <Box flex={1}>
                                <Text
                                    category="b3"
                                    color={isUnserviceable ? "color-basic-45" : "color-basic-60"}>
                                    {completeAddress}
                                </Text>
                            </Box>
                            {isUnserviceable ? (
                                <Box flex={1} mt={SpacingValue["space-xx-small"]}>
                                    <Text category="b3" weight="medium" color="color-critical-200">
                                        {MODAL_BODY_COPIES.unserviceable}
                                    </Text>
                                </Box>
                            ) : null}
                        </Box>
                    </Stack>
                </Box>
            </Pressable>
        </>
    );
};

const AddressListComponent: React.FC<AddressListComponentProps> = ({
    addresses,
    onAddNewAddressPress,
    setShowAddressSelected,
    closeDialog,
    onAddressMissingFields,
    cartId,
    calloutHeight,
    shouldShowUnserviceable = true,
}) => {
    const { value: theme } = useTheme();
    const { height: windowHeight } = useWindowDimensions();

    const setAddressFromStorage = React.useCallback(
        (selectedAddress: UserAddress, storage: { addresses: AddressStorageType } | null) => {
            const addressinStorage = storage?.addresses[Number(selectedAddress.id)];

            if (addressinStorage) {
                setShowAddressSelected({
                    ...selectedAddress,
                    ...addressinStorage,
                    postalCode: Number(addressinStorage.postalCode),
                });

                return true;
            }

            return false;
        },
        [setShowAddressSelected],
    );

    const onAddressPress = React.useCallback(
        async (address: UserAddress) => {
            const storageAddressDetails = await getAddressStorageMeta();

            const addressSet = setAddressFromStorage(address, storageAddressDetails);

            if (!addressSet && (!address.city || !address.postalCode || !address.state)) {
                onAddressMissingFields(address);
            } else {
                !addressSet && setShowAddressSelected(address);
                closeDialog();
            }
        },
        [closeDialog, onAddressMissingFields, setAddressFromStorage, setShowAddressSelected],
    );

    const renderItem: ListRenderItem<UserAddress> = ({ item, index }) => {
        return (
            <AdressItemComponent
                item={item}
                index={index}
                onAddNewAddressPress={onAddNewAddressPress}
                onAddressPress={onAddressPress}
                theme={theme}
                cartId={cartId}
                shouldShowUnserviceable={shouldShowUnserviceable}
            />
        );
    };

    const maxHeight = React.useMemo(
        () => windowHeight * 0.85 - calloutHeight,
        [calloutHeight, windowHeight],
    );

    return (
        <Stack style={[styles.mainContainer, { maxHeight }]} spacing={SpacingValue["space-medium"]}>
            <Text category="h4" weight="bold" color="highest">
                {MODAL_BODY_COPIES.chooseAddress}
            </Text>

            <FlatList
                ListEmptyComponent={<AddNewAddress onAddNewAddressPress={onAddNewAddressPress} />}
                showsVerticalScrollIndicator={false}
                data={addresses}
                renderItem={renderItem}
                keyExtractor={(address: UserAddress) => address.id}
                ItemSeparatorComponent={() => <Divider color="color-basic-15" />}
            />
        </Stack>
    );
};

type AddNewAddressProps = {
    onAddNewAddressPress: () => void;
    cartId?: string;
};

const AddNewAddress: React.FC<AddNewAddressProps> = ({ onAddNewAddressPress, cartId }) => {
    const { value: theme } = useTheme();

    useMount(() => {
        Analytics.impressionEvent({
            category: ANALYTICS_COPIES.ADD_ADDRESS_BTN,
            label: cartId,
        });
        Analytics.impressionEvent({
            category: ANALYTICS_COPIES.CHOOSE_ADDRESS_CARD,
            label: cartId,
        });
    });

    return (
        <Pressable style={styles.newAddress} onPress={onAddNewAddressPress}>
            <Box
                alignItems="center"
                justifyContent="center"
                style={[
                    styles.plusIconBox,
                    {
                        borderColor: theme["color-primary"],
                    },
                ]}>
                <SvgIcon icon="Plus" height={16} width={16} color="color-primary-400" />
            </Box>
            <Box flex={1} ml={SpacingValue["space-medium"]}>
                <Text category="btn3" weight="bold" color="color-primary">
                    {MODAL_BODY_COPIES.addNewAddress}
                </Text>
            </Box>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        padding: SpacingValue["space-medium"],
        elevation: 0,
    },
    svgContainer: {
        height: 40,
        width: 40,
        borderRadius: 8,
        borderWidth: 1,
    },
    plusIconBox: {
        height: 28,
        width: 40,
        borderRadius: 8,
        borderWidth: 1,
    },
    newAddress: {
        flexDirection: "row",
        alignItems: "center",
        marginVertical: SpacingValue["space-medium"],
    },
    addressName: {
        marginBottom: SpacingValue["space-xx-small"],
    },
});

export const AddressListModalBody = React.memo(AddressListComponent);
