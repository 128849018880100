import * as React from "react";

import { useTheme } from "@swiggy-private/rn-dls";
import { FocusAwareStatusBar, useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { ScreenSize, useScreenSize } from "@swiggy-private/rn-adaptive-layout";

import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { useCartView } from "@minis-consumer/hooks/use-cart";
import { CouponScreenNavigationProp } from "@minis-consumer/interfaces/route";
import { ResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Analytics } from "@minis-consumer/analytics";
import { useSignedIn, useUserInfo } from "@minis-consumer/hooks/use-user";

import { HeaderLeft } from "./components/header/left";
import { HeaderTitle } from "./components/header/title";
import { HeaderRight } from "./components/header/right";
import { AllCoupons } from "./components/main";
import { AllCouponsModal } from "./components/coupons-modal";

const CouponScreenComponent: React.FC<CouponScreenNavigationProp> = (props) => {
    const { navigation } = props;
    const isSignedIn = useSignedIn();

    const { value: theme } = useTheme();

    const store = useStoreInfo();
    const cartView = useCartView(store.storeId);
    const userInfo = useUserInfo();

    const onChatPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: "chat-btn",
            label: userInfo?.id ? `cardId: ${cartView?.cartViewData?.id}` : "",
        });

        navigation.navigate("ChatConversation", { storeId: store.storeId });
    }, [navigation, store, cartView, userInfo]);

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerLeft: () => <HeaderLeft />,
            headerTitle: () => <HeaderTitle />,
            headerBackVisible: false,
            headerRight: () => (
                <HeaderRight
                    onChatPress={onChatPress}
                    eventLabel={userInfo?.id ? `cartId: ${cartView?.cartViewData?.id}` : ""}
                />
            ),
        });
    }, [navigation, onChatPress, userInfo?.id, cartView?.cartViewData?.id]);

    const hideCouponsDialog = React.useCallback(() => {
        navigation.canGoBack() ? navigation.goBack() : navigation.getParent()?.navigate("Cart");
    }, [navigation]);

    const screenSize = useScreenSize();
    const isDesktop = React.useMemo(() => screenSize === ScreenSize.Large, [screenSize]);

    React.useEffect(() => {
        if (isSignedIn) {
            return;
        }

        navigation.canGoBack()
            ? navigation.goBack()
            : navigation.replace("Home", { screen: "Shop" });
    }, [navigation, isSignedIn]);

    if (!isSignedIn) {
        return null;
    }

    if (isDesktop) {
        return <AllCouponsModal showModal={true} closeModal={hideCouponsDialog} />;
    }

    return (
        <>
            <AllCoupons closeModal={hideCouponsDialog} />

            <FocusAwareStatusBar
                translucent={true}
                backgroundColor={theme["color-background-primary"]}
                barStyle="dark-content"
            />
        </>
    );
};

export const CouponScreen: React.FC<CouponScreenNavigationProp> = (props) => {
    return (
        <StateContextContainer>
            <ResetErrorBoundary>
                <CouponScreenComponent {...props} />
            </ResetErrorBoundary>
        </StateContextContainer>
    );
};
