import * as React from "react";
import { StyleSheet, TextInput } from "react-native";

import { useMount, useMountedRef, useDebounceFn } from "@swiggy-private/react-hooks";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { CheckBox, Input, SpacingValue, Text } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import {
    PAYMENT_GROUP_CATEGORY,
    PAYMENT_GROUP_METHOD,
    RBI_GUIDELINE,
    SAVE_CARD,
} from "../../constants";
import { usePaymentDispatch } from "../../hooks/use-dispatch";
import { useJuspayApi } from "../../hooks/use-juspay-api";
import { TNewCard } from "../../hooks/use-make-payment";
import { usePaymentOrder } from "../../hooks/use-payment-order";
import { usePaymentSelector } from "../../hooks/use-selector";
import { RbiTncDialog } from "../rbi-tnc-modal";

export const NewPaymentCard: React.FC = () => {
    const mountedRef = useMountedRef();
    const order = usePaymentOrder();
    const storeInfo = useStoreInfo();
    const cart = useCartViewData(storeInfo.storeId);
    const paymentDispatch = usePaymentDispatch();
    const selectedPaymentMethod = usePaymentSelector((state) => state.selectedPaymentMethod);

    const cardNumberRef = React.useRef<TextInput | null>(null);
    const validThroughRef = React.useRef<TextInput | null>(null);
    const cvvRef = React.useRef<TextInput | null>(null);
    const cardHolderNameRef = React.useRef<TextInput | null>(null);

    const [cardNumber, setCardNumber] = React.useState("");
    const [validThrough, setValidThrough] = React.useState("");
    const [cvv, setCvv] = React.useState("");
    const [cardHolderName, setCardHolderName] = React.useState("");
    const [showRbiTncDialog, setShowRbiTncDialog] = React.useState(false);

    const [cardNumberError, setCardNumberError] = React.useState("");
    const [cardLogo, setCardLogo] = React.useState("");

    const { getCardLogoFromBin } = useJuspayApi();

    const [saveMethod, setSaveMethod] = React.useState(true);

    useMount(() => {
        Analytics.impressionEvent({
            category: "save-card-check-box",
            label: order?.id,
            context: JSON.stringify({ checked: saveMethod }),
        });
    });

    const onTnCPress = React.useCallback(() => {
        setShowRbiTncDialog(true);
    }, []);

    const hideRbiTncDialog = React.useCallback(() => {
        setShowRbiTncDialog(false);
    }, []);

    const updateDispatcher = React.useCallback(
        (cardData: Partial<TNewCard & { saveMethod: boolean }>) => {
            paymentDispatch({
                type: "UPDATE_SELECTED_PAYMENT_METHOD",
                payload: {
                    category: PAYMENT_GROUP_CATEGORY.NEW,
                    type: PAYMENT_GROUP_METHOD.CARD,
                    value: "CARD_NEW",
                    cardDetails: {
                        newCard: {
                            ...selectedPaymentMethod?.cardDetails?.newCard,
                            ...cardData,
                        } as NonNullable<TNewCard>,
                    },
                },
            });
        },
        [paymentDispatch, selectedPaymentMethod?.cardDetails],
    );

    const toggleSaveMethod = React.useCallback(() => {
        Analytics.clickEvent({
            category: "save-card-check-box",
            label: order?.id,
            context: JSON.stringify({ checked: !saveMethod }),
        });

        updateDispatcher({
            saveMethod: !saveMethod,
        });
        setSaveMethod(!saveMethod);
    }, [order?.id, saveMethod, updateDispatcher]);

    const fireAnalyticsEvent = React.useCallback(() => {
        Analytics.clickEvent({
            category: "enter-card-details",
            context: JSON.stringify({
                cartId: cart?.id,
                orderId: order?.id,
                productType: cart?.cartType ?? order?.orderType,
                transactionId: order?.txnId,
                paymentAmount: cart?.bill?.billTotal ?? order?.bill?.billTotal,
            }),
        });
    }, [order, cart]);

    const debouceEvent = useDebounceFn(fireAnalyticsEvent, { wait: 2000 });

    const onCardNumberChange = React.useCallback(
        (text: string) => {
            const newCardNumber = text.replace(/\s+/g, "");

            debouceEvent();

            setCardNumber(newCardNumber);
            updateDispatcher({ cardNumber: newCardNumber });
            if (newCardNumber.length < 6) {
                setCardNumberError("");
            }
        },
        [updateDispatcher, debouceEvent],
    );

    const onValidThroughChange = React.useCallback(
        (text: string) => {
            const isBackspace = text.length < validThrough.length;
            if (isBackspace) {
                setValidThrough(text);
                return;
            }

            text = text.replace(/[^0-9]+/g, "");
            const validThroughSliced =
                text.length >= 2 ? text.slice(0, 2) + "/" + text.slice(2, 4) : text;
            const [MM, YY] = validThroughSliced.split("/");
            updateDispatcher({ cardExpiryMM: MM, cardExpiryYY: YY });
            setValidThrough(validThroughSliced);

            /** Auto focus CVV if valid-thru is filled */
            if (text.length === 4) {
                cvvRef.current?.focus();
            }
        },
        [updateDispatcher, validThrough.length],
    );

    const onCVVChange = React.useCallback(
        (txt: string) => {
            updateDispatcher({ cvv: txt });
            setCvv(txt);
        },
        [updateDispatcher],
    );

    const onCardHolderNameChange = React.useCallback(
        (txt: string) => {
            updateDispatcher({ cardHolderName: txt });
            setCardHolderName(txt);
        },
        [updateDispatcher],
    );

    React.useEffect(() => {
        (async () => {
            const cardBinLength = 6;

            if (cardNumber.length < cardBinLength) {
                setCardLogo("");
                return;
            }

            if (cardNumber.length > cardBinLength) {
                return;
            }

            if (cardNumber.length === cardBinLength) {
                try {
                    const imageId = await getCardLogoFromBin(cardNumber);
                    mountedRef.current && setCardLogo(imageId);
                } catch (e) {
                    mountedRef.current && setCardNumberError((e as Error).message);
                }
            }
        })();
    }, [cardNumber, getCardLogoFromBin, mountedRef]);

    return (
        <Stack
            spacing={SpacingValue["space-x-large"]}
            style={{
                paddingTop: SpacingValue["space-small"],
            }}>
            <Stack spacing={SpacingValue["space-medium"]}>
                <Input
                    label="Card number"
                    keyboardType="number-pad"
                    ref={cardNumberRef}
                    value={cardNumber}
                    onChangeText={onCardNumberChange}
                    returnKeyType="next"
                    onSubmitEditing={() => validThroughRef.current?.focus()}
                    textContentType="creditCardNumber"
                    maxLength={19}
                    spellCheck={false}
                    hint={cardNumberError}
                    error={!!cardNumberError}
                    accessoryRight={() => <CardNumberAccessoryRight cardLogo={cardLogo} />}
                />

                <Stack spacing={SpacingValue["space-medium"]} direction="row">
                    <Box flex={1}>
                        <Input
                            label="Valid through"
                            ref={validThroughRef}
                            value={validThrough}
                            onChangeText={onValidThroughChange}
                            returnKeyType="next"
                            onSubmitEditing={() => cvvRef.current?.focus()}
                            placeholder="MM/YY"
                            autoComplete="off"
                            spellCheck={false}
                            keyboardType="number-pad"
                            maxLength={5}
                        />
                    </Box>

                    <Box flex={1}>
                        <Input
                            label="CVV"
                            ref={cvvRef}
                            value={cvv}
                            onChangeText={onCVVChange}
                            returnKeyType="next"
                            onSubmitEditing={() => cardHolderNameRef.current?.focus()}
                            secureTextEntry
                            keyboardType="number-pad"
                            maxLength={4}
                            autoComplete="off"
                        />
                    </Box>
                </Stack>

                <Stack>
                    <Input
                        label="Name on card"
                        ref={cardHolderNameRef}
                        value={cardHolderName}
                        onChangeText={onCardHolderNameChange}
                        returnKeyType="go"
                        textContentType="name"
                        spellCheck={false}
                    />
                </Stack>
            </Stack>

            <CheckBox
                onChange={toggleSaveMethod}
                checked={saveMethod}
                hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}>
                <Text category="b2" weight="medium" color="high" onPress={toggleSaveMethod}>
                    {SAVE_CARD}
                    <Text
                        category="b2"
                        color="positive"
                        weight="medium"
                        style={styles.underlinedText}
                        onPress={onTnCPress}>
                        {RBI_GUIDELINE}
                    </Text>
                </Text>
            </CheckBox>

            <RbiTncDialog canShowDialog={showRbiTncDialog} onCloseDialog={hideRbiTncDialog} />
        </Stack>
    );
};

const CardNumberAccessoryRight: React.FC<{ cardLogo: string }> = ({ cardLogo }) => {
    if (!cardLogo) {
        return null;
    }

    return (
        <Box style={styles.accessoryRight}>
            <CdnImage isImageKitEnabled id={cardLogo} height={32} width={44} />
        </Box>
    );
};

const styles = StyleSheet.create({
    accessoryRight: {
        height: 20,
        width: 44,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        overflow: "hidden",
    },
    underlinedText: {
        textDecorationLine: "underline",
    },
});
