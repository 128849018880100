import React, { ReactNode, useCallback, useState } from "react";
import { ViewProps, Pressable, StyleSheet, ViewStyle } from "react-native";

import { SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

interface Props extends ViewProps {
    title: string;
    isDividerPresent: boolean;
    description?: string;
    titleTextColor?: TextProps["color"];
    children?: ReactNode;
    isExpanded?: boolean;
}

export const ExpansionTile: React.FC<Props> = ({
    title,
    description,
    children,
    titleTextColor = "color-basic-75",
    isDividerPresent,
    isExpanded = false,
}) => {
    const { value: theme } = useTheme();

    const [expanded, setExpanded] = useState(isExpanded);
    const toggleExpand = useCallback(() => setExpanded(!expanded), [expanded]);

    const tileContainer = {
        borderBottomColor: isDividerPresent ? theme["color-basic-15"] : "transparent",
        borderBottomWidth: 1,
    };

    const containerPadding = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const boxDirection = useSelectScreen({
        lg: "row",
        default: "column",
    }) as ViewStyle["flexDirection"];

    const textStyle = useSelectScreen({
        lg: { width: "80%", marginRight: SpacingValue["space-medium"] },
        default: { width: "100%", marginBottom: SpacingValue["space-medium"] },
    });

    return (
        <Box style={[{ padding: containerPadding }, tileContainer]}>
            <Pressable onPress={toggleExpand}>
                <Box direction="row" alignItems="flex-start" justifyContent="space-between">
                    <Text category="b1" color={titleTextColor} style={styles.arrow} weight="medium">
                        {title}
                    </Text>

                    <SvgIcon
                        width={SpacingValue["space-x-large"]}
                        height={SpacingValue["space-x-large"]}
                        icon={expanded ? "ChevronUp" : "ChevronDown"}
                        color="color-basic-60"
                    />
                </Box>
            </Pressable>
            {expanded ? (
                <Box style={styles.gap} direction={boxDirection}>
                    {description ? (
                        <Text category="b2" color="color-basic-60" style={textStyle}>
                            {description}
                        </Text>
                    ) : null}
                    {children}
                </Box>
            ) : null}
        </Box>
    );
};

const styles = StyleSheet.create({
    gap: { marginTop: SpacingValue["space-medium"] },
    arrow: { marginRight: SpacingValue["space-medium"], flex: 1 },
});
