import { OrderStatus } from "@minis-consumer/interfaces/order";

export const isRTOdOrder = (status: OrderStatus): boolean => {
    if (!status) {
        return false;
    }

    return (
        ["CANCELLED_RTO", "CANCELLED_RTO_DELIVERED", "CANCELLED_DISPATCH"] as OrderStatus[]
    ).includes(status);
};
