import * as React from "react";
import { Pressable, StyleProp, StyleSheet, ViewStyle } from "react-native";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useMount } from "@swiggy-private/react-hooks";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Card } from "@minis-consumer/components/card";
import { getTimeElapsed } from "@minis-consumer/helpers/date";
import { MinisRouteList } from "@minis-consumer/interfaces/route";
import { useShowClearCartTooltip } from "@minis-consumer/routes/account/routes/abandoned-cart/hooks/use-show-tooltip";
import { ICartsListItem } from "@minis-consumer/interfaces/cart";
import { Analytics } from "@minis-consumer/analytics";

import { StoreProduct } from "../store-product";
import { DEFAULTS } from "../../constants";
import { StoreInfo } from "../store-info";
import { ANALYTICS_COPIES } from "../../../../constants";

interface AbandonedCartContentProps extends ICartsListItem {
    cartOrderSequence?: number;
    style?: StyleProp<ViewStyle>;
    onClearCart?: VoidFunction;
}

const AbandonedCartContentComponent: React.FC<AbandonedCartContentProps> = ({
    store,
    products,
    updatedAt,
    cartOrderSequence,
    style,
    cartId,
    onClearCart,
}) => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NativeStackNavigationProp<MinisRouteList>>();
    const { isTooltipVisible, onDismissTooltip, onMountTooltip } =
        useShowClearCartTooltip(cartOrderSequence);

    const cartUpdatedAt = getTimeElapsed(updatedAt);

    const proceedCheckoutAnalyticsData = React.useMemo(() => {
        return { category: ANALYTICS_COPIES.PROCEED_TO_CHECKOUT_BTN, label: cartId };
    }, [cartId]);

    const onPressCta = React.useCallback((): void => {
        Analytics.clickEvent(proceedCheckoutAnalyticsData);

        navigation.navigate("MiniStore", { slug: store.slug, initialPath: "cart" });
    }, [navigation, store?.slug, proceedCheckoutAnalyticsData]);

    const extraCtaStyles = {
        backgroundColor: theme["positive_Sub"],
        borderColor: theme["positive_Sub"],
    };

    useMount(() => {
        Analytics.impressionEvent(proceedCheckoutAnalyticsData);

        onMountTooltip();
    });

    return (
        <Card style={[styles.card, style]}>
            <StoreInfo
                id={store.id}
                name={store.name}
                slug={store.slug}
                badges={store.badges}
                imgId={store.imgId}
                cartUpdatedAt={cartUpdatedAt}
                isTooltipVisible={isTooltipVisible}
                cartId={cartId}
                onDismissTooltip={onDismissTooltip}
                onDeleteCart={onClearCart}
            />
            {products
                ? products.map((product, index) => (
                      <StoreProduct
                          key={index}
                          id={product.id}
                          name={product.name}
                          quantity={product.quantity}
                          price={product.price}
                          isAvailable={product.isAvailable}
                          discountedPrice={product.discountedPrice}
                          discountPercent={product.discountPercent}
                          imgId={product.imgId || product?.mediaId}
                          slug={store.slug}
                          isCustom={product.isCustom}
                          cartId={cartId}
                          variants={product.variantOptionValues}
                      />
                  ))
                : null}

            <Pressable onPress={onPressCta} style={[styles.cta, extraCtaStyles]}>
                <Text category="s1" weight="bold" color="positive">
                    {DEFAULTS.CTA_TEXT}
                </Text>
            </Pressable>
        </Card>
    );
};

const styles = StyleSheet.create({
    card: {
        paddingVertical: SpacingValue["space-large"],
        paddingHorizontal: 0,
        borderRadius: 20,
    },
    cta: {
        alignItems: "center",
        height: DEFAULTS.CTA_HEIGHT,
        justifyContent: "center",
        borderRadius: 12,
        marginTop: SpacingValue["space-small"],
        marginHorizontal: SpacingValue["space-medium"],
        borderWidth: 1,
    },
});

export const AbandonedCartContent = React.memo(AbandonedCartContentComponent);
