/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";

import {
    AddressContextProvider,
    MapEndpointContextProvider,
} from "@swiggy-private/connect-address-ui";

import { AddressRouteList } from "@minis-consumer/interfaces/route";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { useScreenOptions } from "@minis-consumer/hooks/use-screen-options";
import { createNavigator } from "@minis-consumer/helpers/navigation";
import { ResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";

const StackNavigator = createNavigator<AddressRouteList>();

export const AddNewAddressScreen: React.FC = () => {
    const showHeaderContent = useSelectScreen({
        lg: false,
        default: true,
    });

    const screenOptions = useScreenOptions<AddressRouteList>({
        headerShown: showHeaderContent,
        animationEnabled: true,
    });

    return (
        <ResetErrorBoundary>
            <AddressContextProvider>
                <MapEndpointContextProvider>
                    <StackNavigator.Navigator
                        initialRouteName="SearchLocation"
                        screenOptions={screenOptions}>
                        <StackNavigator.Screen
                            name="SearchLocation"
                            getComponent={() => require("./routes/search-location").SearchAddress}
                        />

                        <StackNavigator.Screen
                            name="Map"
                            getComponent={() => require("./routes/map").AddNewAddressMap}
                        />

                        <StackNavigator.Screen
                            name="AddressForm"
                            getComponent={() =>
                                require("./routes/pickup-address-form").PickupAddressForm
                            }
                        />
                    </StackNavigator.Navigator>
                </MapEndpointContextProvider>
            </AddressContextProvider>
        </ResetErrorBoundary>
    );
};
