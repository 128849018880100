import * as React from "react";
import { Pressable, ScrollView, StyleSheet } from "react-native";

import { Box, useContainerStyle } from "@swiggy-private/rn-adaptive-layout";
import { ActivityIndicator, useTheme, Text, SpacingValue } from "@swiggy-private/rn-dls";

import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { ProfileSettingsContent } from "./components/content";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { useIsMobileWeb } from "@minis-consumer/hooks/use-mweb";
import { useLogout } from "@minis-consumer/hooks/use-logout";

export const ProfileSettings: React.FC = () => {
    const { value: theme } = useTheme();
    const bgSecondaryColor = theme["color-background-secondary"];

    const scrollStyle = {
        backgroundColor: bgSecondaryColor,
    };

    const containerStyle = useContainerStyle({ fluid: false });

    const isMWeb = useIsMobileWeb();
    const logout = useLogout();

    return (
        <StateContextContainer>
            <AsyncComponent
                fallback={
                    <Box
                        flex={1}
                        justifyContent="center"
                        alignItems="center"
                        style={containerStyle}>
                        <ActivityIndicator />
                    </Box>
                }>
                <Box flex={1} style={scrollStyle}>
                    <ScrollView contentContainerStyle={containerStyle}>
                        <ProfileSettingsContent />
                    </ScrollView>

                    {isMWeb ? (
                        <Pressable style={styles.logout} onPress={logout}>
                            <Text category="b1" color="color-critical-400">
                                Logout
                            </Text>
                        </Pressable>
                    ) : null}
                </Box>
            </AsyncComponent>
        </StateContextContainer>
    );
};

const styles = StyleSheet.create({
    logout: {
        marginTop: SpacingValue["space-xx-large"],
        marginBottom: SpacingValue["space-xx-large"],
        alignItems: "center",
        justifyContent: "center",
    },
});
