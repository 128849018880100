import { useShowCounter } from "@minis-consumer/hooks/use-show-counter";

const COUNTER_KEY_NAME = "clear_cart_tooltip";
const MAX_COUNTER = 1;

interface ReturnValue {
    isTooltipViewCountLegal: boolean;
    incrementCounter: VoidFunction;
}

export const useClearCartTooltipCounter = (): ReturnValue => {
    const [isTooltipViewCountLegal, incrementCounter] = useShowCounter(
        COUNTER_KEY_NAME,
        MAX_COUNTER,
    );

    return { isTooltipViewCountLegal, incrementCounter };
};
