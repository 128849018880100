import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

import { Card } from "@minis-consumer/components/card";
import { Divider } from "@minis-consumer/components/divider";
import { Slate } from "@minis-consumer/components/slate";

const ItemsLoadingComponent: React.FC = () => {
    const { value: theme } = useTheme();

    return (
        <Card>
            <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                <Slate height={48} width={48} borderRadius={10} />
                <Stack spacing={SpacingValue["space-x-small"]}>
                    <Slate height={24} width={160} borderRadius={8} />
                    <Slate height={16} width={80} borderRadius={8} />
                </Stack>
            </Stack>
            <Divider style={styles.mt16} />
            <Stack style={styles.mt16} spacing={SpacingValue["space-medium"]}>
                <Item />
                <Item />
            </Stack>
            <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.mt24} />
            <Slate
                height={8}
                width={184}
                marginTop={SpacingValue["space-x-large"]}
                borderRadius={8}
            />
        </Card>
    );
};

const Item: React.FC = () => {
    return (
        <Box justifyContent="space-between" direction="row">
            <Stack spacing={SpacingValue["space-x-small"]}>
                <Slate height={8} width={120} borderRadius={8} />
                <Slate height={8} width={120} borderRadius={8} />
            </Stack>
            <Box>
                <Slate height={8} width={120} borderRadius={8} />
            </Box>
        </Box>
    );
};

const styles = StyleSheet.create({
    mt16: {
        marginTop: SpacingValue["space-medium"],
    },
    mt24: {
        marginTop: SpacingValue["space-x-large"],
    },
});

export const ItemsLoader = React.memo(ItemsLoadingComponent);
