import React from "react";

import { Display, ScreenSize } from "@swiggy-private/rn-adaptive-layout";

import { AddressLSLoader } from "./address-large-screen";
import { AddressSSLoader } from "./address-small-screen";

const AddressLoadingComponent: React.FC = () => {
    return (
        <>
            <Display gt={ScreenSize.Large}>
                <AddressLSLoader />
            </Display>
            <Display lt={ScreenSize.Medium}>
                <AddressSSLoader />
            </Display>
        </>
    );
};

export const AddressLoader = React.memo(AddressLoadingComponent);
