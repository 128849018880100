import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Pressable, StyleSheet } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { IUPIAppsList } from "@minis-consumer/includes/swiggy-rn-module";
import { ItemDivider } from "@minis-consumer/routes/payment/components/divider";
import { NewUpiSection } from "@minis-consumer/routes/payment/components/new-upi-method/dialog";
import {
    PAYMENT_GROUP_CATEGORY,
    PAYMENT_GROUP_METHOD,
    PAYNOW_METHOD_ANALYTICS,
    UPI_APPS,
} from "@minis-consumer/routes/payment/constants";
import { usePaymentDispatch } from "@minis-consumer/routes/payment/hooks/use-dispatch";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { usePaymentOrder } from "@minis-consumer/routes/payment/hooks/use-payment-order";
import { Analytics } from "@minis-consumer/analytics";

import { PaymentApp } from "./app";

const BUTTON_TEXT = "View more UPI options";

interface PaymentAppsProps {
    apps: IUPIAppsList[];
}

interface PaymentAppContainerProps {
    app: IUPIAppsList;
    onPress: (label: string, packageName?: string) => void;
}

const PaymentAppContainer: React.FC<PaymentAppContainerProps> = ({ app, onPress }) => {
    const { label = "", icon = UPI_APPS.CUSTOM_UPI.intentLogoUrl, packageName = "" } = app;

    if (!packageName || !label) {
        return null;
    }

    return (
        <React.Fragment>
            <Box pb={SpacingValue["space-medium"]}>
                <ItemDivider />
            </Box>

            <PaymentApp
                key={packageName}
                appSlug={label}
                title={label}
                logo={icon}
                onPress={() => onPress(label, packageName)}
            />
        </React.Fragment>
    );
};

const PaymentAppsComponent: React.FC<PaymentAppsProps> = ({ apps }) => {
    const storeInfo = useStoreInfo();
    const cart = useCartViewData(storeInfo?.storeId);
    const order = usePaymentOrder();
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [appsToShow, setAppsToShow] = useState<IUPIAppsList[]>([]);

    const analyticsContext = useMemo(() => {
        return {
            orderId: order?.id || "-",
            cartId: cart?.id || "-",
            productType: cart?.cartType || order?.orderType,
            paymentAmount: cart?.bill?.finalBillTotal || order?.bill?.finalBillTotal,
            transactionId: order?.txnId,
        };
    }, [order, cart]);

    const paymentDispatch = usePaymentDispatch();

    const onAppPress = useCallback(
        (app: string, packageName?: string) => {
            Analytics.clickEvent({
                category: PAYNOW_METHOD_ANALYTICS.SELECT_UPI_APP,
                context: JSON.stringify({
                    ...analyticsContext,
                    selectedUpiMethod: app,
                }),
            });

            paymentDispatch({
                type: "UPDATE_SELECTED_PAYMENT_METHOD",
                payload: {
                    category: PAYMENT_GROUP_CATEGORY.NEW,
                    type: PAYMENT_GROUP_METHOD.UPI,
                    value: app,
                    packageName: packageName || "",
                },
            });
        },
        [paymentDispatch, analyticsContext],
    );

    const viewAllApps = useCallback(() => {
        setAppsToShow(apps);
        setShowMoreButton(false);
    }, [apps]);

    useEffect(() => {
        const totalApps = apps.length;

        // if visible apps are greater than 4 show view more button.
        if (totalApps > 4) {
            const initalVisibleApps = apps.slice(0, 3);
            setAppsToShow(initalVisibleApps);
            setShowMoreButton(true);
            return;
        }
        setAppsToShow(apps);
    }, [apps]);

    return (
        <>
            <Stack
                flex={1}
                direction="column"
                justifyContent="center"
                spacing={SpacingValue["space-medium"]}>
                {appsToShow.map((app) => (
                    <PaymentAppContainer app={app} onPress={onAppPress} key={app.label} />
                ))}
                {showMoreButton ? (
                    <Stack spacing={SpacingValue["space-medium"]}>
                        <ItemDivider />
                        <Pressable style={styles.buttonContainer} onPress={viewAllApps}>
                            <Text
                                style={styles.button}
                                category="b1"
                                weight="medium"
                                color="color-warning">
                                {BUTTON_TEXT}
                            </Text>
                        </Pressable>
                    </Stack>
                ) : null}
            </Stack>
            <NewUpiSection />
        </>
    );
};

const styles = StyleSheet.create({
    buttonContainer: {
        alignItems: "center",
        padding: SpacingValue["space-xx-small"],
    },
    button: {
        // need to add in DLS
        color: "#FF5200",
    },
});

export const PaymentApps = React.memo(PaymentAppsComponent);
