import * as React from "react";
import { RouteProp, useRoute } from "@react-navigation/native";

import { Button } from "@swiggy-private/rn-dls";

import { ExpansionTile } from "@minis-consumer/routes/account/components/expansion-tile";
import { HelpRouteList } from "@minis-consumer/interfaces/route";
import { useHelpData } from "@minis-consumer/hooks/use-help-data";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { Analytics } from "@minis-consumer/analytics";
import { IConsumerHelpItem } from "@minis-consumer/interfaces/help";

import { HelpContentFetcher } from "../../../components/help-fetcher";

export const HelpDetailsLevelTwoContentFetcher: React.FC = () => {
    const data = useHelpData();
    const openUrl = useLinking();

    const route = useRoute<RouteProp<HelpRouteList, "HelpDetailsLevelTwo">>();
    const { level1, level2 } = route.params;

    const list =
        data && data[level1]?.details?.find((details) => details.topic === level2)?.details;

    const onPress = React.useCallback(
        (item: IConsumerHelpItem, index: number) => {
            Analytics.clickEvent({
                category: `account-help-and-support-${level1}`,
                value: index,
            });

            openUrl(`mailto:${item.minisSupportEmailID}`);
        },
        [level1, openUrl],
    );

    return (
        <>
            {list?.map((item, index) => {
                return (
                    <ExpansionTile
                        key={item.title + index}
                        title={item.title}
                        description={item.description}
                        isDividerPresent={index !== list.length - 1}
                        isExpanded={list.length === 1}>
                        {item.ctaText ? (
                            <Button onPress={() => onPress(item, index)}>{item.ctaText}</Button>
                        ) : null}
                    </ExpansionTile>
                );
            })}
        </>
    );
};

export const HelpDetailsLevelTwoContent: React.FC = () => {
    return (
        <HelpContentFetcher>
            <HelpDetailsLevelTwoContentFetcher />
        </HelpContentFetcher>
    );
};
