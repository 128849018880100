import React from "react";
import { SafeAreaView, StyleSheet, ViewStyle } from "react-native";

import { Display, ScreenSize, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

interface Props {
    left?: React.ReactNode;
    right?: React.ReactNode;
}

const PaymentLayoutComponent: React.FC<Props> = ({ left, right }) => {
    const containerPadding = useSelectScreen<ViewStyle>({
        default: {
            paddingTop: SpacingValue["space-medium"],
            paddingHorizontal: 0,
        },
        lg: {
            paddingTop: SpacingValue["space-x-large"],
            paddingHorizontal: SpacingValue["space-medium"],
        },
    });

    return (
        <SafeAreaView style={[styles.safeAreaView, containerPadding]}>
            <Stack
                direction="row"
                style={styles.safeAreaView}
                spacing={{ lg: SpacingValue["space-medium"] }}>
                <Stack flex={2}>{left ?? <></>}</Stack>
                <Display gt={ScreenSize.Large}>
                    <Stack flex={1}>{right ?? <></>}</Stack>
                </Display>
            </Stack>
        </SafeAreaView>
    );
};

export const PaymentLayout = React.memo(PaymentLayoutComponent);

const styles = StyleSheet.create({
    safeAreaView: { flex: 1 },
});
