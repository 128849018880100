import * as React from "react";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";

import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";

import { ResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { RouteList } from "@minis-consumer/interfaces/route";
import { RatingAndReviewForm } from "@minis-consumer/components/rating-and-review-form";
import { useGetPreviousRoute } from "@minis-consumer/hooks/use-get-previous-route";

type RatingAndReviewProps = NativeStackScreenProps<RouteList, "RatingAndReview">;

export const RatingAndReviewScreen: React.FC<RatingAndReviewProps> = React.memo(
    ({
        navigation,
        route: {
            params: { defaultRating, orderId, storeId },
        },
    }) => {
        const previousRoute = useGetPreviousRoute();

        useIsomorphicEffect(() => {
            navigation.setOptions({
                title: "",
                headerTitle: "",
                headerShadowVisible: true,
                gestureEnabled: navigation.canGoBack(),
            });
        }, [navigation]);

        const formSubmitHandler = React.useCallback(() => {
            const isParentChatScreen = previousRoute?.name === "ChatConversation";

            if (!isParentChatScreen) {
                navigation.replace("ChatConversation", {
                    storeId,
                });

                return;
            }

            navigation.goBack();
        }, [navigation, previousRoute?.name, storeId]);

        return (
            <ResetErrorBoundary>
                <StateContextContainer>
                    <RatingAndReviewForm
                        defaultRating={defaultRating}
                        orderId={orderId}
                        storeId={storeId}
                        formSubmit={formSubmitHandler}
                    />
                </StateContextContainer>
            </ResetErrorBoundary>
        );
    },
);

if (__DEV__) {
    RatingAndReviewScreen.displayName = "RatingAndReviewScreen";
}
