import { StyleSheet } from "react-native";
import React, { FC, memo } from "react";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { CAMERA_ICON_COLOR, UPLOAD_IMAGE_DISPLAY_SIZE } from "../constants";

const CameraIconComponent: FC = () => {
    const { value: theme } = useTheme();
    return (
        <Box
            justifyContent="center"
            alignItems="center"
            mr={SpacingValue["space-medium"]}
            style={[styles.iconBox, { backgroundColor: theme["color-basic-0"] }]}>
            <SvgIcon icon="CameraPlus" height={24} width={24} color={CAMERA_ICON_COLOR} />
        </Box>
    );
};

export const CameraIcon = memo(CameraIconComponent);

const styles = StyleSheet.create({
    iconBox: {
        height: UPLOAD_IMAGE_DISPLAY_SIZE,
        width: UPLOAD_IMAGE_DISPLAY_SIZE,
        borderRadius: 8,
    },
});
