import React from "react";

import { useFirstTimeEmptyAbandonedCartCounter } from "./use-empty-abandoned-cart-count";
import { COPIES } from "../constants";

interface EmptyAbandonedCartContent {
    title?: string;
    description?: string;
}

type ReturnType = {
    content: EmptyAbandonedCartContent | null;
    onMountAbandonedCart: VoidFunction;
};

export const useEmptyAbandonedCartContent = (): ReturnType => {
    const { isFirstTime, onIncrementFirstTimeCounter } = useFirstTimeEmptyAbandonedCartCounter();

    const content: EmptyAbandonedCartContent | null = React.useMemo(
        () =>
            isFirstTime
                ? {
                      description: COPIES.FIRST_TIME_EMPTY_CART_DESCRIPTION,
                      title: COPIES.FIRST_TIME_EMPTY_CART_TITLE,
                  }
                : {
                      description: COPIES.EMPTY_CART_DESCRIPTION,
                      title: COPIES.EMPTY_CART_TITLE,
                  },
        [isFirstTime],
    );

    const onMount = React.useCallback(() => {
        if (isFirstTime) {
            onIncrementFirstTimeCounter();
        }
    }, [isFirstTime, onIncrementFirstTimeCounter]);

    return {
        onMountAbandonedCart: onMount,
        content: content,
    };
};
