import * as React from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { useMount } from "@swiggy-private/react-hooks";
import { Stack, StackProps, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { OrderRatingCard } from "@minis-consumer/components/order-rating";
import { useOrderBasedDeliveryETA } from "@minis-consumer/hooks/use-delivery-eta";
import { useGuestCheckoutOrderTracking } from "@minis-consumer/hooks/use-guest-checkout-order-tracking";
import { Order, OrderStatus } from "@minis-consumer/interfaces/order";

import { OrderStatusDescription } from "./components/order-status";

type OrderTrackingGuestCheckoutProps = {
    order: Order;
    onRetryPaymentTimeout: () => void;
};

const OrderTrackingGuestCheckout: React.FC<OrderTrackingGuestCheckoutProps> = ({
    order,
    onRetryPaymentTimeout,
}) => {
    const { value: theme } = useTheme();
    const { fetchOrderETA, deliveryETAinDays } = useOrderBasedDeliveryETA();

    const status: OrderStatus = order.status;
    const rating = order.rating;
    const isStoreEnabled = order.storeDetails.enabled !== false;
    const storeId = order.storeDetails.id;

    const trackingMeta = useGuestCheckoutOrderTracking({
        orderStatus: status,
        shippingDetails: order.shippingDetails,
        cancellationDetails: order.cancellationDetails,
        consumer: "OrderDetailsScreen",
        updatedAt: order.updatedAt,
        createdAt: order.createdAt,
        orderTimeout: order.orderTimeout,
        orderType: order.orderType,
        statusUpdatedAt: order.statusUpdatedAt,
        storeDetails: order.storeDetails,
        onTimeout: onRetryPaymentTimeout,
        orderDeliveryETA: deliveryETAinDays,
    });

    const orderRating = React.useMemo(
        () =>
            rating && rating.value > 0
                ? { isOrderRated: true, value: rating.value }
                : { isOrderRated: false, value: 0 },
        [rating],
    );

    const direction: StackProps["direction"] = useSelectScreen({
        default: "column",
    });

    const ratingStyle: ViewStyle = useSelectScreen({
        default: {
            backgroundColor: theme["color-warning-light"],
            width: "100%",
            padding: SpacingValue["space-medium"],
            marginTop: SpacingValue["space-large"],
        },
    });

    useMount(() => {
        if (order.status === "DELIVERED") {
            Analytics.impressionEvent({
                category: "rating-bar",
                label: order.id,
            });
        }
        fetchOrderETA?.(order);
    });

    const containerStyles = {
        backgroundColor: theme["color-basic-0"],
        borderRadius: SpacingValue["space-medium"],
        paddingHorizontal: SpacingValue["space-x-large"],
        paddingVertical: SpacingValue["space-large"],
    };

    return (
        <>
            <Stack style={containerStyles}>
                <Stack spacing={SpacingValue["space-medium"]} flexGrow={1}>
                    <Stack
                        flexGrow={1}
                        alignItems="center"
                        justifyContent="space-between"
                        direction={direction}>
                        <Stack direction="row" alignItems="center" style={styles.stackStyles}>
                            <Stack flex={1}>
                                <OrderStatusDescription
                                    header={trackingMeta?.header}
                                    body={trackingMeta?.body}
                                    status={status}
                                />
                            </Stack>
                        </Stack>
                        <>
                            {status === "DELIVERED" ? (
                                <OrderRatingCard
                                    style={ratingStyle}
                                    rated={orderRating.isOrderRated}
                                    value={orderRating.value}
                                    orderId={order.id}
                                    lgDirection="column"
                                    isStoreEnabled={isStoreEnabled}
                                    storeId={storeId}
                                />
                            ) : null}
                        </>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

const styles = StyleSheet.create({
    firstCard: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    secondCard: {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        paddingVertical: SpacingValue["space-medium"],
    },
    line: {
        marginTop: 0,
        marginBottom: SpacingValue["space-medium"],
        width: "100%",
    },
    line2: {
        marginVertical: SpacingValue["space-small"],
        width: "100%",
    },
    btn: {
        shadowColor: "transparent",
    },
    stackStyles: {
        width: "100%",
    },
});

export default React.memo(OrderTrackingGuestCheckout);
