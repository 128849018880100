import React from "react";

import { Box } from "@swiggy-private/rn-adaptive-layout";

import { HeaderTitle } from "../../header/title";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

const ModalHeaderComponent: React.FC<{
    closeModal?: () => void;
}> = ({ closeModal }) => {
    const { value: theme } = useTheme();

    const hitSlop = { top: 12, bottom: 12, left: 12, right: 12 };

    const container = {
        backgroundColor: theme["color-background-primary"],
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        padding: SpacingValue["space-large"],
        paddingBottom: 0,
    };

    return (
        <Box
            flex={1}
            style={container}
            justifyContent="space-between"
            direction="row"
            alignItems="center">
            <HeaderTitle />
            <SvgIcon icon="Close" height={16} width={16} hitSlop={hitSlop} onPress={closeModal} />
        </Box>
    );
};

export const ModalHeader = React.memo(ModalHeaderComponent);
