import React from "react";

import { NetbankingPaymentMethod } from "@minis-consumer/interfaces/payment";

import { PAYMENT_GROUP_METHOD } from "../constants";
import { usePaymentSelector } from "./use-selector";

interface UseNetBankingBanksReturnValue {
    popularBanks?: NetbankingPaymentMethod[];
    allBanks?: NetbankingPaymentMethod[];
}

export const useNetBankingBanks = (): UseNetBankingBanksReturnValue => {
    const availableBanks = usePaymentSelector((state) => state.paymentMethods).paymentGroup.find(
        (group) => group.groupName === PAYMENT_GROUP_METHOD.NETBANKING,
    )?.paymentMethods;

    const [popularBanks, allBanks] = React.useMemo(() => {
        const popular: NetbankingPaymentMethod[] = [],
            all: NetbankingPaymentMethod[] = [];

        availableBanks?.forEach((bank) => {
            // * Need only three top banks as popular for now
            if (bank.popular && popular.length < 3) {
                popular.push(bank as NetbankingPaymentMethod);
            } else {
                all.push(bank as NetbankingPaymentMethod);
            }
        });

        return [popular, all];
    }, [availableBanks]);

    return { popularBanks, allBanks };
};
