import * as React from "react";
import { Platform, StyleSheet } from "react-native";

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useLoading } from "@rest-hooks/hooks";

import { useMount, useMountedRef } from "@swiggy-private/react-hooks";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { ConfirmDialog } from "@minis-consumer/components/confirm-dialog";
import { Divider } from "@minis-consumer/components/divider";
import { TrackingIndicator } from "@minis-consumer/components/tracking-indicator";
import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";
import { useOrderBasedDeliveryETA } from "@minis-consumer/hooks/use-delivery-eta";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { useOrderTracking } from "@minis-consumer/hooks/use-order-tracking";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { Order } from "@minis-consumer/interfaces/order";
import { CommonRouteList } from "@minis-consumer/interfaces/route";

import { CANCEL_ORDER, ORDER_JOURNEY, STORE_DELETED_TEXT } from "../constants";
import { useCancelOrder } from "../hooks/use-cancel-order";

type OrderTrackingProps = {
    order: Order;
    validStatus: string[];
    showChatIcon: boolean;
};

const OrderTracking: React.FC<OrderTrackingProps> = ({ order, validStatus, showChatIcon }) => {
    const orderStatus = order.status;
    const isStoreEnabled = order.storeDetails.enabled !== false;

    const orderCancel = useCancelOrder(order.id);
    const mountedRef = useMountedRef();
    const [showToast] = useToast();
    const { value: theme } = useTheme();
    const navigation = useNavigation<NativeStackNavigationProp<CommonRouteList>>();
    const { fetchOrderETA, deliveryETAinDays } = useOrderBasedDeliveryETA();
    const openUrl = useLinking();

    const [showModal, setShowModal] = React.useState(false);

    const trackingMeta = useOrderTracking({
        orderStatus,
        shippingDetails: order.shippingDetails,
        consumer: "TrackingScreen",
        updatedAt: order.updatedAt,
        createdAt: order.createdAt,
        statusUpdatedAt: order.statusUpdatedAt,
        storeDetails: order.storeDetails,
        orderDeliveryETA: deliveryETAinDays,
    });

    const header = React.useMemo(() => trackingMeta?.header, [trackingMeta?.header]);
    const body = React.useMemo(() => trackingMeta?.body, [trackingMeta?.body]);

    const activeStatusIndex = React.useMemo(
        () => validStatus.indexOf(orderStatus),
        [orderStatus, validStatus],
    );

    const lastUpdateTime = React.useMemo(
        () => convertToReadableDateAndTime(order.statusUpdatedAt),
        [order.statusUpdatedAt],
    );

    const navigateToOrderListScreen = React.useCallback(() => {
        navigation.navigate("Order", { id: order.id });
    }, [navigation, order.id]);

    const [onCancelPress, loading] = useLoading(async () => {
        if (mountedRef.current) {
            try {
                Analytics.clickEvent({
                    category: "cancel-order-popup-cancel-order-btn",
                    label: order.id,
                });

                await orderCancel();

                setShowModal(false);
                navigateToOrderListScreen();
            } catch (err) {
                setShowModal(false);
                showToast("Failed to cancel the order");
                // navigate the user to order-list page after a delay, instead of keeping it on the same screen
                setTimeout(navigateToOrderListScreen, 800);
            }
        }
    }, [mountedRef, order.id, orderCancel, navigateToOrderListScreen, showToast]);

    const descriptionCategory: TextProps["category"] = useSelectScreen({
        lg: "s3",
        default: "b2",
    });

    const dividerWidth = useSelectScreen({
        lg: "35%",
        md: "50%",
        default: "100%",
    });

    const onChatPress = React.useCallback(
        () => navigation.navigate("ChatConversation", { storeId: order.storeDetails.id }),
        [order.storeDetails.id, navigation],
    );

    const showCancelOrderDialog = React.useCallback(() => {
        Analytics.clickEvent({
            category: "track-order-card-cancel-order-btn",
            label: order.id,
        });

        setShowModal(true);
    }, [order.id]);

    const onPressTrackingLink = React.useCallback(
        (link: string) => {
            Analytics.clickEvent({
                category: "track-order-card-track-link",
                label: order.id,
                context: order.shippingDetails?.partner,
            });

            openUrl(link || "");
        },
        [openUrl, order.id, order.shippingDetails?.partner],
    );

    const closeDialog = React.useCallback(() => {
        Analytics.clickEvent({
            category: "cancel-order-popup-dismiss-btn",
            label: order.id,
        });

        setShowModal(false);
    }, [order.id]);

    const dialogImpressionEvent = React.useCallback(() => {
        Analytics.impressionEvent({
            category: "cancel-order-popup",
            label: order.id,
        });
    }, [order.id]);

    useMount(() => {
        if (orderStatus === "PENDING") {
            Analytics.impressionEvent({
                category: "track-order-card-cancel-order-btn",
                label: order.id,
            });
        }

        if (orderStatus === "IN_TRANSIT") {
            Analytics.impressionEvent({
                category: "track-order-card-track-link",
                label: order.id,
                context: order.shippingDetails?.partner,
            });
        }

        //* Fetches delivery ETA based on order
        fetchOrderETA?.(order);
    });

    return (
        <Stack>
            {ORDER_JOURNEY.map((orderJourneyStep, index) => (
                <Stack
                    flex={Platform.OS !== "web" ? 1 : undefined}
                    flexGrow={Platform.OS === "web" ? 1 : undefined}
                    key={index}
                    direction="row"
                    spacing={{
                        lg: SpacingValue["space-x-large"],
                        default: SpacingValue["space-medium"],
                    }}>
                    <Stack alignItems="center">
                        {index < activeStatusIndex || orderStatus === "DELIVERED" ? (
                            <TrackingIndicator trackStatus="DELIVERED" />
                        ) : (
                            <TrackingIndicator
                                trackStatus={index === activeStatusIndex ? orderStatus : "DORMANT"}
                                innerBoxStyle={
                                    index === activeStatusIndex ? undefined : styles.indicator
                                }
                            />
                        )}

                        {ORDER_JOURNEY.length > index + 1 ? (
                            <Divider
                                horizontal={false}
                                color={
                                    index < activeStatusIndex
                                        ? "color-positive-400"
                                        : "color-basic-15"
                                }
                            />
                        ) : null}
                    </Stack>

                    <Stack spacing={SpacingValue["space-xx-small"]} flex={1} style={styles.mb24}>
                        <Text
                            category="b1"
                            color="highest"
                            weight={activeStatusIndex === index ? "bold" : "regular"}>
                            {activeStatusIndex > index
                                ? orderJourneyStep.completedState
                                : orderJourneyStep.activeState}
                        </Text>
                        {activeStatusIndex === index ? (
                            <Stack>
                                <Stack>
                                    {header?.subTitle ? (
                                        <Text category={descriptionCategory} color="color-basic-60">
                                            {header.subTitle}
                                        </Text>
                                    ) : null}

                                    {body && header?.subTitle ? (
                                        <DashBorderLine
                                            borderColor={theme["color-basic-15"]}
                                            style={[styles.dashedLine, { width: dividerWidth }]}
                                        />
                                    ) : null}
                                    {body ? (
                                        <Stack>
                                            <Text
                                                category={descriptionCategory}
                                                color="color-basic-60">
                                                {body.description || ""}
                                            </Text>

                                            {body.trackUrl ? (
                                                <Text
                                                    suppressHighlighting
                                                    category={descriptionCategory}
                                                    color="color-highlight-400"
                                                    onPress={() =>
                                                        onPressTrackingLink(body.trackUrl || "")
                                                    }>
                                                    {body.trackUrl}
                                                </Text>
                                            ) : null}

                                            {body.awb ? (
                                                <Text
                                                    category={descriptionCategory}
                                                    color="color-basic-60"
                                                    selectable
                                                    weight="medium">
                                                    {body.awb}
                                                </Text>
                                            ) : null}

                                            {body.mobile ? (
                                                <Stack direction={"row"}>
                                                    <Text
                                                        suppressHighlighting
                                                        category={descriptionCategory}
                                                        color="color-highlight-400"
                                                        onPress={() =>
                                                            onPressTrackingLink(
                                                                `tel:${body.mobile}`,
                                                            )
                                                        }>
                                                        {body.mobile}
                                                    </Text>
                                                    <Text
                                                        category={descriptionCategory}
                                                        selectable
                                                        color="color-basic-60">
                                                        {`${
                                                            body.agentName
                                                                ? ` (${body.agentName})`
                                                                : ""
                                                        }`}
                                                    </Text>
                                                </Stack>
                                            ) : null}
                                        </Stack>
                                    ) : null}
                                </Stack>
                                {orderStatus === "PENDING" && trackingMeta?.ctaType === "Cancel" ? (
                                    <Text
                                        suppressHighlighting
                                        onPress={showCancelOrderDialog}
                                        category="btn4"
                                        color="color-primary-400"
                                        style={styles.mt12}>
                                        {CANCEL_ORDER.CTA_TITLE}
                                    </Text>
                                ) : null}
                            </Stack>
                        ) : null}
                    </Stack>
                </Stack>
            ))}
            <Divider color={"color-basic-15"} style={styles.divider} />
            <Stack spacing={SpacingValue["space-xxx-small"]}>
                <Text category="b3" color="color-basic-60" weight="regular">
                    {`Last update: ${lastUpdateTime}`}
                </Text>

                {showChatIcon && isStoreEnabled ? (
                    <Stack direction={"row"}>
                        <Text category="b3" color="color-basic-60" weight="regular">
                            {"You can "}
                        </Text>

                        <Text
                            style={styles.chatLink}
                            category="b3"
                            color="color-basic-60"
                            weight="regular"
                            suppressHighlighting
                            onPress={onChatPress}>
                            {"chat with the seller"}
                        </Text>

                        <Text category="b3" color="color-basic-60" weight="regular">
                            {" for any queries"}
                        </Text>
                    </Stack>
                ) : null}

                {!isStoreEnabled ? (
                    <Text category="b3" color="color-critical-400">
                        {STORE_DELETED_TEXT}
                    </Text>
                ) : null}
            </Stack>

            <ConfirmDialog
                open={showModal}
                onClose={closeDialog}
                title={CANCEL_ORDER.MODAL.TITLE}
                description={CANCEL_ORDER.MODAL.DESCRIPTION}
                onPressCancel={closeDialog}
                onPressContinue={onCancelPress}
                cancelBtnText={CANCEL_ORDER.MODAL.SECONDARY_CTA}
                continueBtnText={CANCEL_ORDER.MODAL.PRIMARY_CTA}
                loading={loading}
                onMount={dialogImpressionEvent}
            />
        </Stack>
    );
};

const styles = StyleSheet.create({
    indicator: {
        height: 20,
        width: 20,
        borderRadius: 50,
    },
    mb24: {
        marginBottom: SpacingValue["space-x-large"],
    },
    mt12: {
        marginTop: SpacingValue["space-small"],
    },
    chatLink: {
        textDecorationLine: "underline",
    },
    divider: {
        marginVertical: SpacingValue["space-medium"],
    },
    dashedLine: {
        marginTop: 10,
        marginBottom: SpacingValue["space-small"],
    },
});

export default React.memo(OrderTracking);
