import * as React from "react";
import { FlatList, ListRenderItem, Pressable, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { CdnImage, InView } from "@swiggy-private/react-native-ui";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import {
    Box,
    Stack,
    useSelectScreen,
    Display,
    ScreenSize,
} from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { RouteList } from "@minis-consumer/interfaces/route";
import { InstagramMedia } from "@minis-consumer/interfaces/store";
import { useStoreInstagram } from "@minis-consumer/hooks/use-store-instagram";
import { useRenderClientOnly } from "@minis-consumer/hooks/use-render-client-only";
import { Analytics } from "@minis-consumer/analytics";
import { getNumberInShorthandFormat } from "@minis-consumer/helpers/number";
import { RENDER_ON_SERVER_ERROR_MSG } from "@minis-consumer/constants/error";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";

import { InstagramWidgetLoader } from "./instagram-loader";
import { SF_ANALYTICS_EVENT_NAMES } from "../../constants";
import { SectionHeading } from "../../components/section-separator";

const MinPhotos = 5;

const ItemSeparator: React.FC = () => <Box mr={SpacingValue["space-x-small"]} />;

export const InstagramScrollWidgetComponent: React.FC = () => {
    useRenderClientOnly(RENDER_ON_SERVER_ERROR_MSG);
    const { instagramData } = useStoreInstagram();

    const { value: theme } = useTheme();
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();

    const margin = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const photos = React.useMemo(
        () =>
            (instagramData?.media?.data || []).filter((val) => val.media_url || val.thumbnail_url),
        [instagramData?.media],
    );

    const navigateToPhotos = React.useCallback(
        (index: number) => {
            Analytics.clickEvent({
                category: SF_ANALYTICS_EVENT_NAMES.INSTAGRAM_WIDGET,
                value: index,
            });

            if (photos && photos.length > MinPhotos) {
                Analytics.clickEvent({
                    category: SF_ANALYTICS_EVENT_NAMES.INSTAGRAM_WIDGET_MORE_PHOTOS,
                });
            }

            navigation.navigate("Home", { screen: "About", params: { defaultTab: "photos" } });
        },
        [navigation, photos],
    );

    const renderItem: ListRenderItem<InstagramMedia> = React.useCallback(
        ({ item, index }) => {
            return (
                <Pressable onPress={() => navigateToPhotos(index)}>
                    <CdnImage
                        isImageKitEnabled
                        id={item.thumbnail_url || item.media_url}
                        height={80}
                        width={80}
                        style={[styles.img, { backgroundColor: theme["color-basic-5"] }]}
                    />
                </Pressable>
            );
        },
        [navigateToPhotos, theme],
    );

    const canShowWidget = instagramData != null && (photos?.length || 0) > 0;

    const onImpression = React.useCallback(
        (isVisible: boolean) => {
            if (!isVisible || !canShowWidget) {
                return;
            }

            Analytics.impressionEvent({
                category: SF_ANALYTICS_EVENT_NAMES.INSTAGRAM_WIDGET,
            });
        },
        [canShowWidget],
    );

    if (!canShowWidget || !photos?.length || !instagramData.user) {
        return null;
    }

    return (
        <Display lt={ScreenSize.Medium}>
            <Box
                direction="column"
                style={{ backgroundColor: theme["color-basic-0"] }}
                pb={SpacingValue["space-xx-large"]}>
                <Box
                    direction="row"
                    alignItems="center"
                    pt={SpacingValue["space-medium"]}
                    pb={SpacingValue["space-x-small"]}
                    justifyContent="space-between">
                    <SectionHeading title={"checkout my latest content"} />
                </Box>
                <InView onChange={onImpression}>
                    <Box
                        style={[
                            styles.container,
                            {
                                marginHorizontal: margin,
                                borderColor: theme["color-basic-15"],
                            },
                        ]}
                        testID={TEST_IDS_SF.INSTAGRAM_WIDGET}>
                        <ListHeader
                            followersCount={instagramData.user.followersCount}
                            handleName={instagramData.user.userName}
                            onPress={
                                photos.length > MinPhotos ? () => navigateToPhotos(0) : undefined
                            }
                        />
                        <FlatList
                            horizontal={true}
                            bounces={false}
                            data={photos.slice(0, MinPhotos)}
                            renderItem={renderItem}
                            keyExtractor={(item: InstagramMedia) => item.id}
                            contentContainerStyle={{ marginTop: margin }}
                            ItemSeparatorComponent={ItemSeparator}
                            showsHorizontalScrollIndicator={false}
                        />
                    </Box>
                </InView>
            </Box>
        </Display>
    );
};

export const InstagramScrollWidget = React.memo(() => {
    return (
        <AsyncComponent fallback={InstagramWidgetLoader}>
            <InstagramScrollWidgetComponent />
        </AsyncComponent>
    );
});

InstagramScrollWidget.displayName = "InstagramScrollWidget";

type ListHeaderProps = {
    followersCount: number;
    handleName: string;
    onPress?: () => void;
};

const ListHeader: React.FC<ListHeaderProps> = ({ followersCount, handleName, onPress }) => {
    const formattedFollowersCount = React.useMemo(
        () =>
            `${getNumberInShorthandFormat(followersCount)} ${
                followersCount === 1 ? "follower" : "followers"
            }`,
        [followersCount],
    );

    return (
        <Pressable onPress={onPress}>
            <Stack flex={1} direction="row" alignItems="center" justifyContent="space-between">
                <Stack spacing={SpacingValue["space-x-small"]} direction="row" alignItems="center">
                    <SvgIcon icon="InstagramOutline" height={32} width={32} color="color-primary" />
                    <Stack>
                        <Text category="b1" weight="medium" color="high">
                            {handleName}
                        </Text>
                        <Text category="b3" color="color-basic-60">
                            {formattedFollowersCount}
                        </Text>
                    </Stack>
                </Stack>

                {onPress ? (
                    <Text category="btn5" weight="bold" color="color-primary">
                        More photos
                    </Text>
                ) : null}
            </Stack>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: SpacingValue["space-medium"],
        borderWidth: 1,
        borderRadius: 20,
        backgroundColor: "#fff",
    },
    img: {
        borderRadius: 8,
    },
});
