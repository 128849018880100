import * as React from "react";
import { Platform, StyleSheet } from "react-native";
import { useRoute } from "@react-navigation/native";
import { ActivityIndicator, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { useErrorHandler } from "react-error-boundary";
import { useMount } from "@swiggy-private/react-hooks";
import { useNavigation } from "@react-navigation/core";

import {
    PaymentProceedNavigationProp,
    PaymentProceedRouteProp,
} from "@minis-consumer/interfaces/route";
import { useConfirmTransaction } from "@minis-consumer/routes/payment/hooks/use-confirm-transaction";
import { withResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { useNavigationReset } from "@minis-consumer/hooks/use-navigation-reset";
import { useNavigateToOrderWithReset } from "@minis-consumer/routes/payment/hooks/use-navigate-order";
import { CartDispatchContext } from "@minis-consumer/contexts/cart-context";
import { usePaymentStoreId } from "@minis-consumer/routes/payment/hooks/use-payment-store";

import { PaymentWebRedirection, PaymentWebRedirectionProps } from "./web-redirection";

const ProceedVerifyComponent: React.FC = () => {
    const navigation = useNavigation<PaymentProceedNavigationProp<"PaymentTimer">>();
    const route = useRoute<PaymentProceedRouteProp<"PaymentVerify">>();
    const errorHandler = useErrorHandler();
    const storeId = usePaymentStoreId();
    const dispatch = React.useContext(CartDispatchContext);
    const webUrl = Platform.OS !== "web" ? route.params?.webUrl : null;
    const swgyOrderId = Platform.OS !== "web" ? route.params?.swgyOrderId : null;

    const [transactionId] = React.useState(
        route.params?.order_id ?? route.params?.swgyTransactionId,
    );

    const [orderToken] = React.useState(route.params?.order_token);

    const confirmTransaction = useConfirmTransaction();
    const navigationReset = useNavigationReset();
    const navigateToOrder = useNavigateToOrderWithReset();

    const confirmPollDuration = 15000;

    const onConfirmTransactionCompletion = React.useCallback(() => {
        //clear local cart
        dispatch({ type: "CLEAR_CART_ACTION", payload: { storeId: storeId ?? "" } });
    }, [dispatch, storeId]);

    const webRedirectionCallback: PaymentWebRedirectionProps["callback"] = React.useCallback(
        (_reason, params): void => {
            if (typeof params === "object" && params != null) {
                const { order_id, order_token } = params;
                if (order_id && order_token) {
                    navigation.replace("PaymentVerify", {
                        order_id,
                        order_token,
                        swgyTransactionId: transactionId,
                    });
                    return;
                }
            }

            if (transactionId) {
                navigation.replace("PaymentVerify", {
                    swgyTransactionId: transactionId,
                });
            } else if (swgyOrderId) {
                navigateToOrder(swgyOrderId);
            } else {
                navigationReset();
            }
        },
        [navigateToOrder, navigation, navigationReset, swgyOrderId, transactionId],
    );

    useMount(() => {
        if (webUrl) {
            return;
        }

        if (transactionId) {
            confirmTransaction({
                transactionId,
                pollDuration: confirmPollDuration,
                token: orderToken,
                onCompletion: onConfirmTransactionCompletion,
            }).catch(errorHandler);

            return;
        }

        if (swgyOrderId) {
            navigateToOrder(swgyOrderId);
        } else {
            navigationReset();
        }
    });

    if (webUrl) {
        return <PaymentWebRedirection url={webUrl} callback={webRedirectionCallback} />;
    }

    if (!transactionId) {
        return (
            <Box
                flex={1}
                ph={SpacingValue["space-medium"]}
                pv={SpacingValue["space-medium"]}
                alignItems="center">
                <ActivityIndicator color="color-primary" size="large" />
            </Box>
        );
    }

    return (
        <Box
            flex={1}
            ph={SpacingValue["space-medium"]}
            pv={SpacingValue["space-medium"]}
            alignItems="center">
            <Box style={styles.container} flex={1} justifyContent="center">
                <Stack spacing={48}>
                    <ActivityIndicator color="color-primary" size="large" />
                    <Stack spacing={SpacingValue["space-medium"]}>
                        <Text category="h3" color="highest" style={styles.textAlignCenter}>
                            Hold on...
                        </Text>
                        <Text
                            category="b2"
                            weight="medium"
                            color="low"
                            style={styles.textAlignCenter}>
                            We&#39;re verifying your payment status
                        </Text>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
};

export const ProceedVerify = React.memo(withResetErrorBoundary(ProceedVerifyComponent));

const styles = StyleSheet.create({
    container: {
        maxWidth: 140,
    },
    textAlignCenter: {
        textAlign: "center",
    },
});
