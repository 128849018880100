import { useShowCounter } from "@minis-consumer/hooks/use-show-counter";

const COUNTER_KEY_NAME = "recent_minis_migration_tooltip";
const MAX_COUNTER = 3;

interface ReturnValue {
    isNudgeViewCountLegal: boolean;
    incrementCounter: VoidFunction;
}

export const useRecentMinisMigrationTooltipCounter = (): ReturnValue => {
    const [isNudgeViewCountLegal, incrementCounter] = useShowCounter(COUNTER_KEY_NAME, MAX_COUNTER);

    return { isNudgeViewCountLegal, incrementCounter };
};
