import { Platform } from "react-native";
import { SpacingValue } from "@swiggy-private/rn-dls-theme";

export const LOGO_SIZE = Platform.OS === "web" ? 56 : 44;
export const SECTION_HEADER_TEXT = "my links";
export const MIN_LINKS = 3;
export const SEE_MORE_CTA = "See more";
export const SEE_LESS_CTA = "See less";
export const TEXT_MAX_COUNT = 36;
export const ICON_HEIGHT = 12;
export const ICON_WIDTH = 12;
export const ICON_FONT_WEIGHT = 900;
export const CONTAINER_MARGIN_TOP = Platform.OS === "web" ? 0 : SpacingValue["space-medium"];
