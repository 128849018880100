import * as React from "react";
import { Pressable, StyleSheet } from "react-native";

import {
    Text,
    useTheme,
    SpacingValue,
    TextProps,
    Tooltip,
    TooltipPlacement,
    TooltipTriggerMode,
} from "@swiggy-private/rn-dls";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

import { Card } from "@minis-consumer/components/card";
import { TrackingIndicator } from "@minis-consumer/components/tracking-indicator";
import { Divider } from "@minis-consumer/components/divider";
import { OrderRefund } from "@minis-consumer/interfaces/order";
import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";

type RefundCardComponentProps = {
    refundDetails: OrderRefund;
};

const RefundCardComponent: React.FC<RefundCardComponentProps> = ({ refundDetails }) => {
    const { value: theme } = useTheme();
    const { status: refundStatus } = refundDetails;

    const [showTooltip, setShowTooltip] = React.useState(false);

    const openTooltip = React.useCallback(() => setShowTooltip(true), []);
    const closeTooltip = React.useCallback(() => setShowTooltip(false), []);

    const titleCategory: TextProps["category"] = useSelectScreen({
        lg: "h4",
        default: "b1",
    });

    const subTitleCategory: TextProps["category"] = useSelectScreen({
        lg: "s3",
        default: "b2",
    });

    const updationDate = React.useMemo(
        () => convertToReadableDateAndTime(refundDetails.updatedAt),
        [refundDetails.updatedAt],
    );

    return (
        <Card>
            <Stack
                flex={1}
                spacing={SpacingValue["space-small"]}
                divider={
                    <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.dashLine} />
                }>
                <Stack alignItems="center" direction="row" spacing={SpacingValue["space-x-small"]}>
                    <SvgIcon icon="SentBackFilled" color="color-primary" height={20} width={20} />

                    <Stack spacing={SpacingValue["space-xx-small"]} flex={1}>
                        <Text category={titleCategory} weight="bold" color="high">
                            {`Refund ${
                                refundStatus !== "COMPLETED" ? "initiated" : "deposited"
                            } to source account`}
                        </Text>
                        <Text category={subTitleCategory} color="color-basic-60">
                            {`₹${refundDetails.refundAmount} | ${
                                refundStatus !== "COMPLETED" ? "Last updated on:" : "Completed on:"
                            } ${updationDate}`}
                        </Text>
                    </Stack>

                    <Tooltip
                        showOverlay={true}
                        visible={showTooltip}
                        onOpen={openTooltip}
                        onClose={closeTooltip}
                        showPointer={false}
                        style={styles.tooltip}
                        triggerMode={TooltipTriggerMode.TAP}
                        message={<ToolTipComponent />}
                        placement={TooltipPlacement.BOTTOM_END}>
                        <Pressable hitSlop={{ top: 12, left: 12, bottom: 12, right: 12 }}>
                            <SvgIcon icon="Info" color="color-basic-75" height={20} width={20} />
                        </Pressable>
                    </Tooltip>
                </Stack>

                <Stack spacing={SpacingValue["space-x-large"]}>
                    <Stack direction="row" spacing={SpacingValue["space-medium"]} flex={1}>
                        <Stack alignItems="center">
                            <TrackingIndicator
                                trackStatus={refundStatus === "INITIATED" ? "PENDING" : "DELIVERED"}
                            />
                            <Divider
                                horizontal={false}
                                color={
                                    refundStatus === "INITIATED"
                                        ? "color-basic-15"
                                        : "color-positive-400"
                                }
                            />
                        </Stack>
                        <Stack flex={1}>
                            <Text category="b1" color="highest">
                                Refund initiated
                            </Text>
                        </Stack>
                    </Stack>

                    <Stack direction="row" spacing={SpacingValue["space-medium"]} flex={1}>
                        <Stack alignItems="center">
                            <TrackingIndicator
                                trackStatus={
                                    refundStatus === "INITIATED"
                                        ? "DORMANT"
                                        : refundStatus === "COMPLETED"
                                        ? "DELIVERED"
                                        : "PENDING"
                                }
                            />
                            <Divider
                                horizontal={false}
                                color={
                                    refundStatus === "COMPLETED"
                                        ? "color-positive-400"
                                        : "color-basic-15"
                                }
                            />
                        </Stack>
                        <Stack flex={1}>
                            <Text category="b1" color="highest">
                                {refundStatus === "COMPLETED"
                                    ? "Refund processed by your bank"
                                    : "Refund under process by your bank"}
                            </Text>
                        </Stack>
                    </Stack>

                    <Stack direction="row" spacing={SpacingValue["space-medium"]} flex={1}>
                        <Stack alignItems="center">
                            <TrackingIndicator
                                trackStatus={refundStatus !== "COMPLETED" ? "DORMANT" : "DELIVERED"}
                            />
                        </Stack>

                        <Stack flex={1}>
                            <Text category="s3" color="highest">
                                Refund complete
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    );
};

const ToolTipComponent: React.FC = () => {
    const { value: theme } = useTheme();

    return (
        <Box
            style={[
                styles.tooltipBox,
                {
                    borderColor: theme["color-basic-15"],
                    backgroundColor: theme["color-background-primary"],
                },
            ]}>
            <Text category="b3" color="color-basic-60">
                Refund will be updated to the source account, within 5 - 7 business days
            </Text>
        </Box>
    );
};

const styles = StyleSheet.create({
    dashLine: {
        marginTop: 0,
        marginBottom: SpacingValue["space-medium"],
    },
    tooltip: {
        backgroundColor: "transparent",
    },
    tooltipBox: {
        paddingVertical: SpacingValue["space-medium"],
        paddingHorizontal: SpacingValue["space-small"],
        borderRadius: 8,
        maxWidth: 170,
        borderWidth: 1,
    },
});

export const RefundCard = React.memo(RefundCardComponent);
