/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from "react";
import { ScrollView, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { DashBorderLineWithText } from "@minis-consumer/components/guest-checkout/dash-border-line-with-text";
import { GuestCheckoutDeliveryForm } from "@minis-consumer/components/guest-checkout/delivery-form";
import { GuestCheckoutHeader } from "@minis-consumer/components/guest-checkout/header";
import { useGetConfigInfo } from "@minis-consumer/hooks/use-get-config-info";

const DeliveryFormWrapper: React.FC<{ footer: React.ReactNode; content: React.ReactNode }> = ({
    content,
    footer,
}) => {
    const { value: theme } = useTheme();
    const isNewServicesEnabled = useGetConfigInfo()?.isNewServicesEnabled;

    const LoginWithSwiggy = isNewServicesEnabled
        ? require("@minis-consumer/components/guest-checkout/login-with-swiggy/index-v2")
              .LoginWithSwiggy
        : require("@minis-consumer/components/guest-checkout/login-with-swiggy").LoginWithSwiggy;

    const containerBackgroundColor = isNewServicesEnabled
        ? theme["color-basic-0"]
        : theme["color-background-secondary"];

    const stackStyles = {
        backgroundColor: containerBackgroundColor,
        paddingHorizontal: SpacingValue["space-medium"],
        paddingBottom: SpacingValue["space-large"],
        paddingTop: isNewServicesEnabled ? 0 : SpacingValue["space-large"],
    };

    return (
        <>
            <ScrollView
                contentContainerStyle={styles.container}
                style={[{ backgroundColor: containerBackgroundColor }]}>
                <Stack style={stackStyles} flex={1} spacing={SpacingValue["space-large"]}>
                    <LoginWithSwiggy />
                    <DashBorderLineWithText
                        text="OR CONTINUE AS GUEST"
                        borderColor={theme["color-secondary-400"]}
                        type="solid"
                    />
                    {content}
                    <Stack style={styles.gapFix} />
                </Stack>
            </ScrollView>
            {footer}
        </>
    );
};

export const DeliveryInfoScreen: React.FC = () => {
    return (
        <SafeAreaView style={styles.container}>
            <GuestCheckoutHeader />
            <GuestCheckoutDeliveryForm Wrapper={DeliveryFormWrapper} />
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    gapFix: {
        height: 100,
    },
});
