import React from "react";
import { Platform, StyleSheet, ViewStyle } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import {
    Box,
    Display,
    Stack,
    useSelectScreen,
    ScreenSize,
} from "@swiggy-private/rn-adaptive-layout";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import { useUserInfo } from "@minis-consumer/hooks/use-user";

import { Subtitle } from "../subtitle";
import { Title } from "../title";
import { EmojiIcon } from "../emoji";

import { CART_ERROR } from "../../constants";
import { CartErrorStatus } from "../../types";

interface Props {
    orderStatus: CartErrorStatus;
    cartId?: string;
}

const DescriptionComponent: React.FC<Props> = ({ orderStatus, cartId }) => {
    const { value: theme } = useTheme();

    const userInfo = useUserInfo();

    const stackDirection = useSelectScreen<ViewStyle["flexDirection"]>({
        lg: "row-reverse",
        default: "row",
    });

    const stackContent = useSelectScreen<ViewStyle["justifyContent"]>({
        lg: "flex-start",
        default: "space-between",
    });

    useMount(() => {
        switch (orderStatus) {
            case "GUEST_CART":
                Analytics.impressionEvent({
                    category: "login-signup-btn",
                });
                break;

            case "STORE_OFFLINE":
                Analytics.impressionEvent({
                    category: "blocked-store-cart",
                    label: userInfo?.id ? cartId : "",
                });
                break;
        }
    });

    return (
        <Box style={styles.container}>
            <Stack
                direction={stackDirection}
                justifyContent={stackContent}
                spacing={SpacingValue["space-medium"]}>
                <Stack flex={2} spacing={SpacingValue["space-xxx-small"]}>
                    <Title title={CART_ERROR[orderStatus].title} />
                    <Subtitle subtitle={CART_ERROR[orderStatus].subtitle} />
                </Stack>
                {CART_ERROR[orderStatus].emoji ? <EmojiIcon /> : null}
            </Stack>
            <Display lt={ScreenSize.Medium}>
                <DashBorderLine borderColor={theme["color-basic-15"]} />
            </Display>
        </Box>
    );
};

export const Description = React.memo(DescriptionComponent);

const styles = StyleSheet.create({
    container: {
        flex: Platform.OS === "web" ? 1 : undefined,
    },
});
