import React from "react";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

import { Card } from "@minis-consumer/components/card";
import { Slate } from "@minis-consumer/components/slate";

const PaymentLoadingComponent: React.FC = () => {
    return (
        <Card>
            <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                <Slate height={48} width={48} borderRadius={10} />
                <Stack spacing={SpacingValue["space-x-small"]}>
                    <Slate height={24} width={110} borderRadius={8} />
                    <Slate height={16} width={270} borderRadius={8} />
                </Stack>
            </Stack>
        </Card>
    );
};

export const PaymentLoader = React.memo(PaymentLoadingComponent);
