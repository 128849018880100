import React, { memo } from "react";
import { StyleSheet, Platform } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import {
    ReturnResolutionDetails,
    ReturnResolutionType,
} from "@minis-consumer/interfaces/return-order";

import { ORDER_RETURN_CLOSE_RESOLUTIONS, RETURN_ORDER_LABELS } from "../../constants";
import { ImageGalleryList } from "@swiggy-private/rn-image-gallery";

interface IRefundDetailsComponent {
    type: ReturnResolutionType;

    details?: ReturnResolutionDetails["details"];
    images?: ReturnResolutionDetails["images"];
}

const IMAGE_BORDER_RADIUS = 4;
const RefundDetailsComponent: React.FC<IRefundDetailsComponent> = ({
    details,
    images = [],
    type,
}) => {
    return (
        <Stack spacing={SpacingValue["space-xx-small"]}>
            <Stack direction="row" spacing={SpacingValue["space-small"]}>
                {images ? (
                    <Stack style={styles.imageListContainer}>
                        <ImageGalleryList
                            images={images}
                            imagesVisible={Platform.select({ web: 10 })}
                        />
                    </Stack>
                ) : null}
                <Stack flex={1} spacing={SpacingValue["space-small"]}>
                    <Stack spacing={SpacingValue["space-xx-small"]}>
                        <Text category="b3" color="medium">
                            {RETURN_ORDER_LABELS.RESOLUTION}
                        </Text>
                        <Text category="b2" color="high" weight="medium">
                            {ORDER_RETURN_CLOSE_RESOLUTIONS[type]}
                        </Text>
                    </Stack>
                    {details ? (
                        <Stack flex={1} spacing={SpacingValue["space-xx-small"]}>
                            <Text category="b3" color="medium">
                                {RETURN_ORDER_LABELS.ADDITIONAL_DETAILS}
                            </Text>

                            <Text style={styles.textStyles} category="b2" color="high">
                                {details}
                            </Text>
                        </Stack>
                    ) : null}
                </Stack>
            </Stack>
        </Stack>
    );
};

export const RefundDetails = memo(RefundDetailsComponent);

const styles = StyleSheet.create({
    textStyles: {
        fontStyle: "italic",
    },
    image: {
        borderRadius: IMAGE_BORDER_RADIUS,
    },
    imageListContainer: { marginTop: SpacingValue["space-x-small"] },
});
