import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { CouponComponentData } from "../../types";

const CouponCardFooterComponent: React.FC<CouponComponentData> = (props) => {
    return (
        <Pressable onPress={props.onClickFooterCta}>
            <Box
                style={styles.footer}
                mt={SpacingValue["space-medium"]}
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Text category="b1" color={props.footerTextColor}>
                    {props.footerText}
                </Text>

                <SvgIcon
                    icon={props.headerCtaIcon ?? "ChevronRight"}
                    color={props.footerCtaColor}
                    height={20}
                    width={20}
                />
            </Box>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    footer: {
        paddingBottom: SpacingValue["space-medium"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
});

export const CouponCardFooter = React.memo(CouponCardFooterComponent);
