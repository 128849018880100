import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { ThreeDotsLoader } from "@swiggy-private/react-native-ui";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { useShare } from "@minis-consumer/hooks/use-share";
import { useFollowUnfollowStore } from "@minis-consumer/hooks/use-store-follow";

import { IconContainer } from "../icon-container";

type ShopActionsParams = {
    isStoreEnabled: boolean;
};

const DEFAULT_ICON_SIZE = 20;
const FOLLOW_BTN_ANALYTICS_CONSTANT = "store-details-card-follow-btn";

export const ShopActions: React.FC<ShopActionsParams> = React.memo(({ isStoreEnabled }) => {
    const {
        following: followingStore,
        trigger: followTrigger,
        loading: followLoading,
        canFollow,
    } = useFollowUnfollowStore();

    const onFollowHandle = React.useCallback(() => {
        Analytics.clickEvent({ category: FOLLOW_BTN_ANALYTICS_CONSTANT });
        followTrigger();
    }, [followTrigger]);

    const showShopAcions = React.useMemo(
        () => canFollow && (isStoreEnabled || (!isStoreEnabled && followingStore)),
        [canFollow, followingStore, isStoreEnabled],
    );
    const share = useShare();

    const handleSharePress = React.useCallback((): void => {
        Analytics.clickEvent({ category: "header-share-store-btn" });
        share();
    }, [share]);

    if (showShopAcions) {
        return (
            <Stack direction="row" justifyContent="flex-end" style={styles.actionsContainer}>
                {canFollow ? (
                    <Pressable disabled={followLoading} style={[styles.actionBtn]}>
                        {followLoading ? (
                            <ThreeDotsLoader color="color-primary" />
                        ) : (
                            <IconContainer
                                accessibilityLabel="HeartFilled"
                                onPress={onFollowHandle}>
                                <SvgIcon
                                    icon={followingStore ? "HeartFilled" : "Heart"}
                                    accessible={false}
                                    height={DEFAULT_ICON_SIZE}
                                    width={DEFAULT_ICON_SIZE}
                                    color={followingStore ? "color-primary-400" : "color-basic-60"}
                                />
                            </IconContainer>
                        )}
                    </Pressable>
                ) : null}

                <IconContainer accessibilityLabel="Share" onPress={handleSharePress}>
                    <SvgIcon icon="Share" color="color-basic-60" width={20} height={20} />
                </IconContainer>
            </Stack>
        );
    }

    return null;
});

if (__DEV__) {
    ShopActions.displayName = "ShopActions";
}

const styles = StyleSheet.create({
    actionsContainer: {
        marginTop: SpacingValue["space-medium"],
    },
    actionBtn: {
        height: 24,
        borderColor: "none",
    },
});
