import React from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { Shimmer } from "@minis-consumer/components/shimmer";
import { Card } from "@minis-consumer/components/card";
import { AnimatedTranslateWrapper } from "@minis-consumer/components/animated-translate-wrapper";

import { LOADING_ANIMATION } from "../../constants";

const GenericSkeleton: React.FC<{ productCount?: number; style?: StyleProp<ViewStyle> }> =
    React.memo(({ productCount = 1, style }) => {
        return (
            <Card style={style}>
                <Stack direction="row" spacing={SpacingValue["space-medium"]} style={styles.mb24}>
                    <Shimmer height={40} width={40} style={styles.br4} />
                    <Stack spacing={SpacingValue["space-xx-small"]}>
                        <Shimmer height={20} width={130} style={styles.br4} />
                        <Shimmer height={16} width={64} style={styles.br4} />
                    </Stack>
                </Stack>
                {Array(productCount)
                    .fill(1)
                    .map((_, idx) => (
                        <Stack
                            key={idx}
                            style={styles.mb20}
                            direction="row"
                            spacing={SpacingValue["space-medium"]}
                            alignItems="center">
                            <Shimmer height={68} width={68} style={styles.br2} />
                            <Stack spacing={5 * SpacingValue["space-xxx-small"]}>
                                <Shimmer height={20} width={146} style={styles.br4} />
                                <Shimmer height={20} width={90} style={styles.br4} />
                            </Stack>
                        </Stack>
                    ))}

                <Box>
                    <Shimmer height={52} style={styles.br12} />
                </Box>
            </Card>
        );
    });

if (__DEV__) {
    GenericSkeleton.displayName = "GenericSkeleton";
}

const AbandonedCartsLoaderComponent: React.FC<{ canAnimate?: boolean }> = ({
    canAnimate = true,
}) => {
    const { value: theme } = useTheme();

    const backgroundColor = theme["color-background-secondary"];

    return (
        <Box style={[styles.root, { backgroundColor }]}>
            <AnimatedTranslateWrapper
                canAnimate={canAnimate}
                yDirectionOutput={LOADING_ANIMATION.yDirectionOutput}
                duration={LOADING_ANIMATION.DURATION}
                opacityOutputRange={LOADING_ANIMATION.opacityOutputRange}
                delay={LOADING_ANIMATION.DELAY}>
                <Stack spacing={SpacingValue["space-x-large"]} style={{ backgroundColor }}>
                    <GenericSkeleton />
                    <GenericSkeleton productCount={2} />
                </Stack>
            </AnimatedTranslateWrapper>
        </Box>
    );
};

const styles = StyleSheet.create({
    root: {
        paddingHorizontal: SpacingValue["space-medium"],
        flexGrow: 1,
        paddingTop: SpacingValue["space-large"],
    },
    br4: {
        borderRadius: 4,
    },
    br2: {
        borderRadius: 2,
    },
    br12: {
        borderRadius: 12,
    },
    mb24: {
        marginBottom: SpacingValue["space-x-large"],
    },
    mb20: {
        marginBottom: SpacingValue["space-large"],
    },
});

export const AbandonedCartsLoader = React.memo(AbandonedCartsLoaderComponent);
