import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { PincodeForm } from "@minis-consumer/components/pincode-form";
import {
    SelectedAddressInfo,
    SelectedAddressInfoContext,
} from "@minis-consumer/contexts/selected-address-context";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useSignedIn } from "@minis-consumer/hooks/use-user";
import { UserAddress } from "@minis-consumer/interfaces/cart";
import { useDeliveryEta } from "@minis-consumer/hooks/use-delivery-eta";
import { AddressList } from "@minis-consumer/routes/cart/components/address-list";
import { Analytics } from "@minis-consumer/analytics";
import { useGetDeliveryCharges } from "@minis-consumer/hooks/use-shop";
import { getDeliveryEtaApiParams } from "@minis-consumer/routes/cart/helpers";

import { ShopDeliveryDetails } from "../delivery";
import { DeliveryInfoDetails } from "../delivery-info";
import { ShopOnlineStatus } from "../shop-online-status";
import { COPIES } from "../delivery-info/constants";

const ANALYTICS_COPIES = {
    SELECT_SAVED_ADDRESS: "select-saved-address",
    CLICK_ADDRESS: "click-address",
};

type IProps = { setDeliveryInfoOffset?: (offset: number) => void };

export const DeliveryInfoActions: React.FC<IProps> = ({ setDeliveryInfoOffset }) => {
    const isSignedIn = useSignedIn();
    const storeInfo = useStoreInfo();
    const selectedAddressCtx = useContext(SelectedAddressInfoContext);
    const { fetchDeliveryEta, deliveryInfo, isLoading } = useDeliveryEta();

    const [postalCode, setPostalCode] = React.useState<string | null>();
    const [showAddressListModal, setShowAddressListModal] = React.useState(false);
    const brandColor = storeInfo?.settings?.brandColor;
    const dropPincodeIsAvailable = !!(
        selectedAddressCtx?.selectedAddressInfo?.postalCode || postalCode
    );

    const deliveryChargesText = useGetDeliveryCharges(dropPincodeIsAvailable, deliveryInfo);
    const addressLabelText = useMemo(() => {
        const selectedAddress = selectedAddressCtx?.selectedAddressInfo;
        const selectedAddressPostalCode = selectedAddress?.postalCode;
        const addressLabel = selectedAddress?.addressLabel;

        return selectedAddressPostalCode
            ? `${addressLabel ? `${addressLabel} - ` : ""}${selectedAddressPostalCode}`
            : COPIES.GET_DEL_INFO;
    }, [selectedAddressCtx?.selectedAddressInfo]);

    const handlePincodeSubmit = useCallback(
        async (pincode?: string) => {
            setPostalCode(pincode);
            const params = getDeliveryEtaApiParams({
                storeId: storeInfo?.storeId,
                dropPincode: pincode,
                pickupAddress: storeInfo?.address,
            });

            fetchDeliveryEta(params);

            setShowAddressListModal(false);
        },
        [fetchDeliveryEta, storeInfo?.address, storeInfo?.storeId],
    );

    const onSelectAddress = useCallback(
        (address: UserAddress | null) => {
            Analytics.clickEvent({
                category: ANALYTICS_COPIES.SELECT_SAVED_ADDRESS,
                context: JSON.stringify({
                    store_id: storeInfo?.storeId,
                }),
            });

            if (address) {
                selectedAddressCtx?.updateSelectedAddressInfo?.({
                    ...selectedAddressCtx.selectedAddressInfo,
                    lng: address?.lng,
                    lat: address?.lat,
                    postalCode: address?.postalCode,
                    addressId: address?.id,
                    addressLabel: address?.displayName,
                });
            }
        },
        [selectedAddressCtx, storeInfo?.storeId],
    );
    const footerRef = React.useRef<View>(null);

    const onLayout = (): void => {
        if (!footerRef.current) {
            return;
        }
        // eslint-disable-next-line max-params
        footerRef.current.measure((_x, _y, _width, _height, _pageX, pageY) => {
            setDeliveryInfoOffset?.(pageY ?? 0);
        });
    };

    const fetchDeliveryEtaInfo = useCallback(
        async (selectedAddr?: SelectedAddressInfo) => {
            const params = getDeliveryEtaApiParams({
                storeId: storeInfo?.storeId,
                selectedAddress: selectedAddr,
                pickupAddress: storeInfo?.address,
            });

            fetchDeliveryEta(params);
        },
        [fetchDeliveryEta, storeInfo?.address, storeInfo?.storeId],
    );

    const anlayticsData = useMemo(() => {
        return {
            category: ANALYTICS_COPIES.CLICK_ADDRESS,
            context: JSON.stringify({
                address_label: selectedAddressCtx?.selectedAddressInfo?.addressLabel,
                pincode: selectedAddressCtx?.selectedAddressInfo?.postalCode,
                del_eta: deliveryInfo?.deliveryEta?.max
                    ? `${deliveryInfo.deliveryEta?.min}-${deliveryInfo.deliveryEta?.max} ${deliveryInfo.deliveryEta?.unit}`
                    : null,
                del_charge_msg: deliveryChargesText?.text,
                store_id: storeInfo?.storeId,
                del_time: deliveryInfo?.deliveryEta ? 1 : 0,
            }),
        };
    }, [
        deliveryChargesText?.text,
        deliveryInfo?.deliveryEta,
        selectedAddressCtx?.selectedAddressInfo?.addressLabel,
        selectedAddressCtx?.selectedAddressInfo?.postalCode,
        storeInfo?.storeId,
    ]);

    useEffect(() => {
        const selectedAddr = selectedAddressCtx?.selectedAddressInfo;

        if (selectedAddr?.postalCode) {
            fetchDeliveryEtaInfo(selectedAddr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddressCtx?.selectedAddressInfo?.postalCode]);

    const handleAddressPickerClick = useCallback(() => {
        Analytics.clickEvent(anlayticsData);
        setShowAddressListModal(true);
    }, [anlayticsData]);

    return (
        <>
            {storeInfo?.deliveryInfoViewEnabled ? (
                <View onLayout={onLayout} ref={footerRef} collapsable={false}>
                    <Pressable onPress={handleAddressPickerClick}>
                        <Stack
                            direction="row"
                            spacing={SpacingValue["space-xx-small"]}
                            alignItems="center"
                            style={styles.addressPicker}>
                            <SvgIcon
                                icon="LocationPinOutlineFilled"
                                height={16}
                                width={16}
                                color={brandColor}
                            />

                            <Text category="b2" weight="bold" color="color-basic-75">
                                {addressLabelText}
                            </Text>

                            <SvgIcon
                                icon="ChevronRight"
                                color={brandColor}
                                height={16}
                                width={16}
                            />
                        </Stack>

                        <DeliveryInfoDetails
                            loading={isLoading}
                            deliveryInfo={deliveryInfo}
                            dropPincodeIsAvailable={
                                !!(
                                    selectedAddressCtx?.selectedAddressInfo?.postalCode ||
                                    postalCode
                                )
                            }
                        />
                    </Pressable>
                </View>
            ) : (
                <ShopDeliveryDetails showTitle={false} />
            )}
            <ShopOnlineStatus />
            {storeInfo?.deliveryInfoViewEnabled ? (
                <>
                    {showAddressListModal && !isSignedIn ? (
                        <PincodeForm
                            canOpen={showAddressListModal}
                            setOpen={setShowAddressListModal}
                            handlePincodeSubmit={handlePincodeSubmit}
                        />
                    ) : null}

                    {showAddressListModal && isSignedIn ? (
                        <AddressList
                            storeId={storeInfo.storeId}
                            open={showAddressListModal}
                            setOpen={setShowAddressListModal}
                            setAddressSelected={onSelectAddress}
                            shouldShowBanner={false}
                            fetchAddressesFromAddressApi={true}
                            showAddressInModal
                            shouldShowUnserviceable={false}
                        />
                    ) : null}
                </>
            ) : null}
        </>
    );
};

const styles = StyleSheet.create({
    addressPicker: {
        marginBottom: SpacingValue["space-x-small"],
    },
});
