export const LOADING_ANIMATION = {
    DELAY: 0,
    DURATION: 800,
    yDirectionOutput: [20, 0],
    opacityOutputRange: [1, 1],
};

export const COPIES = {
    CLEAR_CART_TOOLTIP_TEXT: "Clear carts with ease!",
    CLEAR_ALL_CART_CONFIRMATION_TEXT: "Clear all carts",
    CART_CLEAR_CONFIRMATION_TITLE: "Are you sure?",
    CART_CLEAR_CONFIRMATION_DESCRIPTION:
        "Those are some good looking carts! We can still keep saving them here in case you change your mind later.",
    CART_CLEAR_CONFIRMATION_CANCEL_BTN_TEXT: "Cancel",
    FIRST_TIME_EMPTY_CART_TITLE: "Time for a fresh start!",
    FIRST_TIME_EMPTY_CART_DESCRIPTION: "Let's create a brand new cart.",
    EMPTY_CART_TITLE: "Let’s get cart-ed!",
    EMPTY_CART_DESCRIPTION: "Looking for some inspiration?",
    EMPTY_CART_CTA_TEXT: "Explore Minis",
    EMPTY_IMAGE_ID: "minis/empty_consumer_orders",
    CLEAR_CART_TEXT: " Ready for a fresh start? ",
    CLEAR_CART_BTN_TEXT: "Clear all carts",
};

export const ANALYTICS_COPIES = {
    CART_EMPTY_STATE: "carts-empty-state",
    EXPLORE_MINIS_BTN: "explore-minis-btn",
    SAVED_CART_WIDGET: "saved-cart-widget",
    CLEAR_CART_BTN: "clear-cart-btn",
    UNDO_DELETE_CART_BTN: "undo-delete-cart-btn",
    PROCEED_TO_CHECKOUT_BTN: "proceed-to-checkout-btn",
    CLEAR_ALL_CART_BTN: "clear-all-carts-btn",
    CLEAR_ALL_CART_CONFIRMATION: "clear-all-carts-confirmation-popup",
    CART_CLEARING_REASON: "cart-clearing-reason",
    CARTS_CLEARED: "carts-cleared",
    ALL_CARTS: "all carts",
    NO_VALUE: "No",
    YES_VALUE: "Yes",
    STORE_DETAILS_CATEGORY: "cart-store-details",
    PRODUCT_DETAILS_CATEGORY: "cart-product-details",
};
