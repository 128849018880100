import React, { FC, memo } from "react";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { DISPLAY_COPIES } from "../constants";

const { TITLE, ORDER } = DISPLAY_COPIES.HEADER;

interface HeaderLeftProps {
    orderId: string;
}

const HeaderTitleComponent: FC<HeaderLeftProps> = ({ orderId }) => {
    return (
        <Stack spacing={SpacingValue["space-xxx-small"]}>
            <Text category="b1" weight="bold" color="color-basic-75">
                {TITLE}
            </Text>
            <Text category="b3" color="color-basic-75" selectable>{`${ORDER}${orderId}`}</Text>
        </Stack>
    );
};

export const HeaderTitle = memo(HeaderTitleComponent);
