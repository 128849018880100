import React from "react";
import {
    Pressable,
    StyleProp,
    StyleSheet,
    TextStyle,
    useWindowDimensions,
    ViewStyle,
} from "react-native";

import { Snackbar, SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";

import { LINK_COPY_SNACKBAR_CONSTANTS } from "../constants";

type LinkCopySnackbarProps = {
    text: string;
    canShowSnackbar: boolean;
    dismissSnackbar: VoidFunction;
    ctaText?: string;
    extraStyle?: StyleProp<ViewStyle>;
};

const LinkCopySnackbarComponent: React.FC<LinkCopySnackbarProps> = ({
    text,
    extraStyle,
    canShowSnackbar,
    dismissSnackbar,
}) => {
    const isDWeb = useIsDesktopWeb();
    const { width: windowWidth } = useWindowDimensions();
    const { value: theme } = useTheme();

    const message = React.useMemo(() => {
        if (text.trim().length === 0) {
            return LINK_COPY_SNACKBAR_CONSTANTS.FALLBACK_MSSG;
        }

        return text;
    }, [text]);

    const snackbarStyle = useSelectScreen({
        lg: {
            marginBottom: SpacingValue["space-x-large"],
            maxWidth: LINK_COPY_SNACKBAR_CONSTANTS.MAX_SNACKBAR_WIDTH,
            left: 0,
            rigth: 0,
            marginLeft: isDWeb
                ? (windowWidth - LINK_COPY_SNACKBAR_CONSTANTS.MAX_SNACKBAR_WIDTH) / 2
                : undefined,
            backgroundColor: theme["color-basic-100"],
        },
        default: {
            marginBottom: 16 * SpacingValue["space-xx-small"] + SpacingValue["space-x-large"],
            marginHorizontal: SpacingValue["space-medium"],
            backgroundColor: theme["color-basic-100"],
        },
    });

    const msgStyle: TextStyle = useSelectScreen({
        lg: {
            fontSize: 16,
            lineHeight: 19,
            fontWeight: "600",
        },
        default: {
            fontSize: 13,
            lineHeight: 16,
            fontWeight: "500",
        },
    });

    const commonMsgStyle: TextStyle = {
        color: theme["color-basic-0"],
        letterSpacing: -0.3,
    };

    const backgroundStyles: ViewStyle = {
        backgroundColor: theme["color-basic-100"],
    };

    const childrenStyle = StyleSheet.flatten([msgStyle, commonMsgStyle]);

    const hitSlop = {
        top: 6,
        left: 6,
        bottom: 6,
        right: 6,
    };

    const onPressCta = React.useCallback(() => {
        dismissSnackbar();
    }, [dismissSnackbar]);

    if (!canShowSnackbar) {
        return null;
    }

    return (
        <Snackbar
            style={[styles.root, snackbarStyle, backgroundStyles, extraStyle]}
            isAutoDismiss={true}
            isActive={canShowSnackbar}
            childrenStyle={childrenStyle}
            touchableRight={
                <Pressable onPress={onPressCta} hitSlop={hitSlop}>
                    <SvgIcon icon="CrossFilled" color="color-basic-75" height={20} width={20} />
                </Pressable>
            }
            accessoryLeft={
                <SvgIcon icon="InfoFilled" color="color-basic-0" height={20} width={20} />
            }>
            {message}
        </Snackbar>
    );
};

const styles = StyleSheet.create({
    root: {
        borderRadius: 16,
        paddingVertical: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-small"],
    },
});

export const LinkCopySnackbar = React.memo(LinkCopySnackbarComponent);
