import React from "react";
import { Pressable, StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Snackbar, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Event } from "@minis-consumer/analytics";
import { useMount } from "@swiggy-private/react-hooks";
import { useWidgetAnalytics } from "@minis-consumer/hooks/use-widget-analytics";

const STATIC_VALUES = {
    CTA_TEXT: "UNDO",
    ICON_SIZE: 18,
    PREFIX_TEXT: "Your",
    SUFFIX_TEXT: "cart was removed",
};

interface ClearCartSnackBarProps {
    storeName: string;

    isOpen?: boolean;
    analytics?: Event;
    onUndo?: VoidFunction;
}

const ClearCartSnackBarComponent: React.FC<ClearCartSnackBarProps> = ({
    storeName,
    isOpen = false,
    analytics,
    onUndo,
}) => {
    const analyticsHandler = useWidgetAnalytics();
    const insets = useSafeAreaInsets();

    const snackbarStyle = {
        bottom: Math.max(insets.bottom, SpacingValue["space-x-large"]),
    };

    const hitSlop = {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
    };

    useMount(() => {
        if (analytics && storeName) {
            analyticsHandler.onMountAnalytics(analytics);
        }
    });

    if (!storeName) {
        return null;
    }

    return (
        <Snackbar
            style={[styles.snackbar, snackbarStyle]}
            isAutoDismiss
            isActive={isOpen}
            accessoryLeft={
                <SvgIcon
                    icon="InfoFilled"
                    color="color-positive-500"
                    height={STATIC_VALUES.ICON_SIZE}
                    width={STATIC_VALUES.ICON_SIZE}
                />
            }
            touchableRight={
                <Pressable onPress={onUndo} hitSlop={hitSlop}>
                    <Text
                        category="b3"
                        weight="bold"
                        color="color-positive-200"
                        style={styles.ctaText}>
                        {STATIC_VALUES.CTA_TEXT}
                    </Text>
                </Pressable>
            }>
            <Stack justifyContent="space-between" direction="row">
                <Text category="b3" weight="medium" color="color-basic-0">
                    {STATIC_VALUES.PREFIX_TEXT} &quot;{storeName}&quot; {STATIC_VALUES.SUFFIX_TEXT}
                </Text>
            </Stack>
        </Snackbar>
    );
};

const styles = StyleSheet.create({
    snackbar: {
        zIndex: 1,
        elevation: 1,
        borderRadius: 12,
        marginHorizontal: SpacingValue["space-x-large"],
    },
    ctaText: {
        fontSize: 11,
    },
});

export const ClearCartSnackBar = React.memo(ClearCartSnackBarComponent);
