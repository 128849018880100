import * as React from "react";
import { Pressable, StyleSheet, useWindowDimensions, View } from "react-native";
import { useNavigation, NavigationProp } from "@react-navigation/native";

import { SpacingValue, useTheme, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { DashBorderLine, InView, StoreMedia } from "@swiggy-private/react-native-ui";
import { format, variantsValueFormatter } from "@swiggy-private/common-helpers";

import { OrderEntity } from "@minis-consumer/resources/order";
import { Card } from "@minis-consumer/components/card";
import { RouteList } from "@minis-consumer/interfaces/route";
import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";
import { DigitalAssetInfo } from "@minis-consumer/interfaces/catalog";
import { useDownloadFile } from "@minis-consumer/hooks/use-download-file";
import { Analytics } from "@minis-consumer/analytics";
import { getMediaUrl } from "@minis-consumer/helpers/media";
import { getProductFallbackImages } from "@minis-consumer/helpers/product-fallback-images";
import { OrderItem } from "@minis-consumer/interfaces/order";
import { RenderHTMLDescription } from "@minis-consumer/routes/product/components/product-details/components/render-html-description";
import { STORE_BRAND_FALLBACK } from "@minis-consumer/components/widgets/constants";
import { GoogleMeetDetails } from "@minis-consumer/routes/order/components/order-details/components/google-meet-details";

import { SELLER_MSG_TEXT, ORDER_DETAILS_DEFAULT_COLOR } from "../../constants";

interface OrderAddressCardProps {
    order: OrderEntity;
}

const IMAGE_OPTS = { width: 34, height: 34 };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OrderDetailsComponent = (props: OrderAddressCardProps, ref: any): React.ReactElement => {
    const { value: theme } = useTheme();

    const navigation = useNavigation<NavigationProp<RouteList>>();

    const { width: windowWidth } = useWindowDimensions();

    const buttonRef = React.useRef<View>(null);

    const downloadFile = useDownloadFile();

    const {
        storeDetails,
        items,
        id: orderId,
        status: orderStatus,
        metadata,
        orderType,
    } = props.order;
    const appointmentLink = metadata?.appointmentLink;

    const onLayout = React.useCallback(() => {
        if (!buttonRef.current) {
            return;
        }

        // eslint-disable-next-line max-params
        buttonRef.current.measure((_x, _y, _width, _height, _pageX, pageY) => {
            ref.current = pageY;
        });
    }, [ref, buttonRef]);

    const analyticsData = React.useMemo(() => {
        return {
            label: orderId,
            context: JSON.stringify({
                orderId,
                orderStatus,
            }),
        };
    }, [orderId, orderStatus]);

    const onDownload = React.useCallback(
        async (asset?: DigitalAssetInfo) => {
            Analytics.clickEvent({
                category: "download-form",
                ...analyticsData,
            });

            downloadFile(asset);
        },
        [analyticsData, downloadFile],
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onViewFormPress = (productId: string, formData: any): void => {
        Analytics.clickEvent({
            category: "view-form",
            ...analyticsData,
        });

        navigation.navigate("ProductForms", {
            id: productId,
            slug: storeDetails.slug,
            storeId: storeDetails.id,
            formData: JSON.stringify([formData]),
            isEditMode: false,
        });
    };

    const sendImpressionEvent = React.useCallback(
        (isVisible: boolean, index: number) => {
            if (!isVisible) {
                return;
            }

            Analytics.impressionEvent({
                category: "post-order",
                screen: appointmentLink ? "order" : undefined,
                label: orderId,
                context: JSON.stringify({
                    position: index,
                    vendorId: storeDetails.name,
                    orderStatus: orderStatus,
                    orderId: orderId,
                    meetJoinPresent: !!appointmentLink,
                }),
            });
        },
        [appointmentLink, orderId, orderStatus, storeDetails.name],
    );

    const onImpression = React.useCallback(
        (inView: boolean, index: number) => {
            sendImpressionEvent?.(inView, index);
        },
        [sendImpressionEvent],
    );

    const renderConfirmationMessage = (confirmationMessage: string): React.ReactElement => {
        const imageUrl = getMediaUrl(
            storeDetails.settings?.backgroundImageId ?? STORE_BRAND_FALLBACK,
            IMAGE_OPTS,
            true,
        );

        return (
            <Box
                justifyContent="center"
                mt={16}
                pv={16}
                ph={16}
                style={[styles.msgContainer, { backgroundColor: theme["background_Secondary"] }]}>
                <Box direction="row" alignItems="center">
                    <StoreMedia
                        isImageKitEnabled
                        mediaId={imageUrl}
                        style={[styles.sellerImg, { borderColor: theme["primary"] }]}
                        width={40}
                        height={40}
                        showLoader
                    />
                    <Box direction={"column"} ml={12} style={styles.sellerNote}>
                        <Text numberOfLines={2} category="b1" weight="bold">
                            {format(SELLER_MSG_TEXT, storeDetails.name)}
                        </Text>
                    </Box>
                </Box>

                <RenderHTMLDescription
                    description={JSON.stringify(confirmationMessage)}
                    customBodyStyle={{ fontWeight: "500" }}
                />
            </Box>
        );
    };

    const buttonStyles =
        windowWidth < 380
            ? [{ paddingHorizontal: SpacingValue["space-xx-small"] }]
            : [{ paddingHorizontal: SpacingValue["space-small"] }];

    const getSlotText = React.useCallback((slot: string | null, duration?: string): string => {
        return `${slot ? slot : ""}${duration ? (slot ? " ," + duration : duration) : ""}`;
    }, []);

    const productImgFallback = getProductFallbackImages(orderType);

    const renderCard = (itemObject: OrderItem, index: number): React.ReactElement => {
        const { item } = itemObject;

        const slot = itemObject.slot?.start
            ? convertToReadableDateAndTime(itemObject.slot.start, "dd MMM, hh:mm a")
            : null;
        const duration = item.productDuration
            ? item.productDuration.value + " " + item.productDuration.unit
            : "";

        const imageUrl = getMediaUrl(
            item.imageIds?.[0] ?? productImgFallback,
            { width: 84, height: 84 },
            true,
        );
        const selectedVariants = variantsValueFormatter(item.variantOptionValues || []);
        const variantsText = selectedVariants ? ` (${selectedVariants})` : "";

        const onMount = (inView: boolean): void => {
            onImpression(inView, index);
        };

        return (
            <InView onChange={onMount}>
                <Box direction="row" mt={16} key={index}>
                    <StoreMedia
                        isImageKitEnabled
                        mediaId={imageUrl}
                        width={84}
                        height={84}
                        style={styles.image}
                        showLoader
                        hidePlay
                    />
                    <Box direction={"column"} ml={16} flex={1}>
                        <Text numberOfLines={2} category="b1" weight="bold">
                            {item.name}
                        </Text>
                        {slot || duration ? (
                            <Text
                                category="b3"
                                weight="medium"
                                color="color-basic-45"
                                style={styles.slot}>
                                {getSlotText(slot, duration)}
                            </Text>
                        ) : null}
                        {variantsText ? (
                            <Text
                                category="b3"
                                weight="medium"
                                color="color-basic-45"
                                style={styles.slot}>
                                {variantsText}
                            </Text>
                        ) : null}
                        <Box direction="row" alignItems="center">
                            {itemObject.form?.length ?? 0 > 0 ? (
                                <Pressable
                                    onPress={() => onViewFormPress(item.id, itemObject.form)}>
                                    <Box
                                        direction="row"
                                        style={[
                                            styles.viewForm,
                                            { borderColor: theme["background_Secondary_Alpha"] },
                                            buttonStyles,
                                        ]}
                                        alignItems="center">
                                        <SvgIcon
                                            icon={"Eye"}
                                            width={20}
                                            height={20}
                                            style={styles.svg}
                                        />
                                        <Text category="b3" weight="bold" style={styles.text}>
                                            View Form
                                        </Text>
                                    </Box>
                                </Pressable>
                            ) : null}
                            {item.digitalAssetInfo ? (
                                <Pressable onPress={() => onDownload(item.digitalAssetInfo)}>
                                    <Box
                                        direction="row"
                                        style={[
                                            styles.viewForm,
                                            styles.download,
                                            buttonStyles,
                                            { backgroundColor: theme["positive"] },
                                            { borderColor: theme["background_Secondary_Alpha"] },
                                        ]}
                                        alignItems="center">
                                        {item.digitalAssetInfo.type === "ASSET" ? (
                                            <SvgIcon
                                                icon={"Download"}
                                                width={18}
                                                height={18}
                                                color={"color-basic-0"}
                                                style={styles.downloadSvg}
                                            />
                                        ) : null}
                                        <Text category="b3" weight="bold" color="color-basic-0">
                                            {item.digitalAssetInfo.type === "ASSET"
                                                ? "Download"
                                                : "View Link"}
                                        </Text>
                                    </Box>
                                </Pressable>
                            ) : null}
                        </Box>
                    </Box>
                </Box>
                {appointmentLink && orderStatus === "ACCEPTED" ? (
                    <GoogleMeetDetails appointmentLink={appointmentLink} orderId={orderId} />
                ) : null}

                {!!item.confirmationMessage
                    ? renderConfirmationMessage(item.confirmationMessage)
                    : null}
            </InView>
        );
    };

    return (
        <Card>
            <Stack
                direction="column"
                spacing={SpacingValue["space-medium"]}
                divider={
                    <DashBorderLine
                        borderColor={theme["color-basic-15"]}
                        style={styles.line}
                        type="dotted"
                    />
                }>
                <Text category="b1" weight="bold">
                    Your Order Details
                </Text>

                <Stack justifyContent="space-between" flex={1} flexGrow={1}>
                    <Box ref={buttonRef} onLayout={onLayout}>
                        {items.map((itemObject, index) => {
                            return renderCard(itemObject, index);
                        })}
                    </Box>
                </Stack>
            </Stack>
        </Card>
    );
};

const styles = StyleSheet.create({
    viewForm: {
        borderRadius: 16,
        borderWidth: 1,
        paddingVertical: SpacingValue["space-xx-small"],
        marginTop: SpacingValue["space-small"],
    },
    svg: {
        color: ORDER_DETAILS_DEFAULT_COLOR,
        alignItems: "center",
        justifyContent: "center",
        shadowColor: "transparent",
    },
    text: {
        paddingLeft: SpacingValue["space-xx-small"],
        color: ORDER_DETAILS_DEFAULT_COLOR,
    },
    line: {
        marginTop: 0,
    },
    msgContainer: {
        borderRadius: 12,
    },
    sellerImg: {
        borderRadius: 34,
        borderWidth: 3,
    },
    linkContainer: {
        borderRadius: 8,
        backgroundColor: "#fff",
    },
    link: {
        color: ORDER_DETAILS_DEFAULT_COLOR,
    },
    image: {
        borderRadius: 10,
    },
    download: {
        height: 28,
        marginLeft: SpacingValue["space-xx-small"],
    },
    downloadSvg: {
        marginRight: SpacingValue["space-xx-small"],
    },
    sellerNote: {
        width: "60%",
    },
    slot: { marginTop: SpacingValue["space-x-small"] - 2 },
});

export const OrderDetails = React.memo(React.forwardRef(OrderDetailsComponent));
