import * as React from "react";
import { WebView, WebViewNavigation } from "react-native-webview";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { ActivityIndicator, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { StatusBar, StyleSheet, View } from "react-native";

import { APP_REDIRECT_URI } from "@minis-consumer/routes/payment/helpers";
import { getUrlSearchParams } from "@minis-consumer/helpers/uri";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { FocusAwareStatusBar } from "@swiggy-private/react-native-ui";

type CallbackReason = "back" | "redirect";

export interface PaymentWebRedirectionProps {
    url: string;
    callback: (reason: CallbackReason, params?: Record<string, string | null | undefined>) => void;
}

export const PaymentWebRedirection: React.FC<PaymentWebRedirectionProps> = ({
    url: sourceUrl,
    callback,
}) => {
    const ref = React.useRef<WebView>(null);

    const handleWebViewNavigationStateChange = React.useCallback(
        (e: WebViewNavigation) => {
            const { url } = e;
            if (!url) {
                return;
            }

            if (url.includes(APP_REDIRECT_URI)) {
                const params = getUrlSearchParams(url);
                callback("redirect", params);
                ref.current?.stopLoading();
            }
        },
        [callback],
    );

    return (
        <Stack flex={1} style={styles.container}>
            <PaymentWebHeader onBack={() => callback("back")} />
            <WebView
                ref={ref}
                source={{
                    uri: sourceUrl,
                }}
                renderLoading={() => <ActivityIndicator />}
                javaScriptEnabled={true}
                onNavigationStateChange={handleWebViewNavigationStateChange}
                style={styles.webview}
            />
        </Stack>
    );
};

interface PaymentWebHeaderProps {
    onBack: () => void;
}

const PaymentWebHeader: React.FC<PaymentWebHeaderProps> = ({ onBack }) => {
    const { value: theme } = useTheme();
    const insets = useSafeAreaInsets();
    const headerHeight = 64;

    const insetTop = (StatusBar.currentHeight ?? 0) + insets.top;

    const containerStyle = {
        backgroundColor: theme["color-background-primary"],
        paddingTop: insetTop,
        paddingHorizontal: Math.max(insets.left, insets.right) + SpacingValue["space-medium"],
        minHeight: insetTop + headerHeight,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: theme["color-basic-15"],
    };

    const contentStyle = {
        height: headerHeight,
    };

    return (
        <View style={containerStyle}>
            <Stack
                style={contentStyle}
                spacing={SpacingValue["space-large"]}
                direction="row"
                alignItems="center">
                <SvgIcon
                    icon="Close"
                    hitSlop={{ top: 16, bottom: 16, right: 16, left: 16 }}
                    onPress={onBack}
                    width={16}
                    height={16}
                />
                <Text category="b1" weight="bold" color="high">
                    Make Payment
                </Text>
            </Stack>
            <FocusAwareStatusBar
                translucent={true}
                backgroundColor={theme["color-background-primary"]}
                barStyle="dark-content"
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        overflow: "hidden",
    },
    webview: {
        flex: 1,
    },
});
