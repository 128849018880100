import React, { FC, PropsWithChildren } from "react";
import { StyleSheet, TextStyle } from "react-native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { Divider, ThreeDotsLoader, ItemImages } from "@swiggy-private/react-native-ui";
import { Button, Input, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { useReturnOrder } from "@minis-consumer/routes/order-track/hooks/use-return-order";
import { Card } from "@minis-consumer/components/card";
import { OrderEntity } from "@minis-consumer/resources/order";
import { OrderItem as IOrderItem } from "@minis-consumer/interfaces/order";
import { RouteList } from "@minis-consumer/interfaces/route";

import { DISPLAY_COPIES } from "../constants";
import { ReasonSelector } from "./reason-selector";
import { AddPhoto } from "./add-photo";

const {
    CARD: { COMMENT_PLACEHOLDER, PHONE_TITLE, PHONE_PLACEHOLDER, REFUND_INFO, SEND_MESSAGE },
} = DISPLAY_COPIES;

interface Props {
    order: OrderEntity;
    selectedItems: IOrderItem[];
    onSendSuccess?: VoidFunction;
    navigation?: NativeStackNavigationProp<RouteList>;
}

export const SelectReturnReasonCard: FC<PropsWithChildren<Props>> = ({
    order,
    selectedItems = [],
    onSendSuccess,
    navigation,
}) => {
    const { value: theme } = useTheme();

    const {
        selectedReasonIndex,
        setSelectedReasonIndex,

        comments,
        setComments,

        phoneNumber,
        setPhoneNumber,

        selectedMedia,
        setSelectedMedia,

        isBtnDisabled,
        selectedItemsImageIds,
        itemsTitle,

        onSendPress,
        isLoading,
    } = useReturnOrder(order, selectedItems, onSendSuccess, navigation);

    const inputStyle: TextStyle = {
        fontFamily: theme["text-subheader-2-font-family"],
        letterSpacing: theme["text-subheader-2-letter-spacing"],
        color: theme["color-basic-100"],
    };

    return (
        <Card>
            <Stack direction="row" alignItems="center" style={styles.itemsInfo}>
                <ItemImages imageIds={selectedItemsImageIds} />

                <Box flex={1} ml={SpacingValue["space-x-large"]}>
                    <Text
                        category="b1"
                        weight="medium"
                        color="color-basic-75"
                        numberOfLines={2}
                        ellipsizeMode="middle">
                        {itemsTitle}
                    </Text>
                </Box>
            </Stack>

            <Divider style={styles.divider} />

            <ReasonSelector {...{ selectedReasonIndex, setSelectedReasonIndex }} />

            <Divider style={styles.divider} />

            <Input
                value={comments}
                onChangeText={setComments}
                placeholder={COMMENT_PLACEHOLDER}
                style={styles.commentBox}
                textStyle={[styles.inputText, inputStyle]}
                placeholderTextColor={theme["color-basic-45"]}
                maxLength={200}
                showCounter
                multiline
            />

            <AddPhoto {...{ selectedMedia, setSelectedMedia }} />

            <Divider style={styles.divider} />

            <Box mt={SpacingValue["space-x-small"]} mb={SpacingValue["space-x-large"]}>
                <Text category="b1" weight="bold" color="color-basic-75">
                    {PHONE_TITLE}
                </Text>

                <Input
                    value={phoneNumber}
                    onChangeText={setPhoneNumber}
                    placeholder={PHONE_PLACEHOLDER}
                    style={styles.phoneInput}
                    textStyle={[styles.inputText, inputStyle]}
                    placeholderTextColor={theme["color-basic-45"]}
                    maxLength={10}
                    keyboardType="number-pad"
                />

                <Text category="b2" color="color-basic-60">
                    {REFUND_INFO}
                </Text>
            </Box>

            <Button disabled={isBtnDisabled || isLoading} onPress={onSendPress}>
                {isLoading ? <ThreeDotsLoader /> : SEND_MESSAGE}
            </Button>
        </Card>
    );
};

const styles = StyleSheet.create({
    root: {
        flex: 1,
        padding: SpacingValue["space-medium"],
    },
    svg: {
        marginRight: SpacingValue["space-medium"],
    },
    itemsInfo: {
        paddingVertical: SpacingValue["space-medium"],
    },
    divider: {
        marginVertical: SpacingValue["space-x-small"],
    },
    commentBox: {
        marginTop: SpacingValue["space-small"],
        minHeight: 84,
        overflow: "hidden",
    },
    inputText: {
        paddingTop: 0,
    },
    phoneInput: {
        marginVertical: SpacingValue["space-x-small"],
    },
});
