import React from "react";

import { UserAddress } from "@minis-consumer/interfaces/cart";
import { getAddressStorageMeta } from "@minis-consumer/helpers/address";

export const useValidateAddressMandatoryFields = (): ((
    address?: UserAddress | null,
) => Promise<boolean>) => {
    const checkValidAddress = React.useCallback(
        async (address?: UserAddress | null): Promise<boolean> => {
            if (!address) {
                return false;
            }

            if (address.city && address.state && address.postalCode) {
                return true;
            }

            const storage = await getAddressStorageMeta();

            const addressInStorage = storage?.addresses[Number(address.id)];

            if (addressInStorage) {
                return true;
            }

            return false;
        },
        [],
    );

    return checkValidAddress;
};
