import * as React from "react";
import { StyleSheet } from "react-native";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { Text } from "@swiggy-private/rn-dls";

import { CommonTile } from "@minis-consumer/routes/account/components/common-tile";
import { HelpRouteList } from "@minis-consumer/interfaces/route";
import { useHelpData } from "@minis-consumer/hooks/use-help-data";

import { HelpContentFetcher } from "../../../components/help-fetcher";

export const HelpDetailsLevelOneContentFetcher: React.FC = () => {
    const data = useHelpData();

    const navigation = useNavigation<NativeStackNavigationProp<HelpRouteList>>();
    const route = useRoute<RouteProp<HelpRouteList, "HelpDetailsLevelOne">>();

    const onPress = React.useCallback(
        (topic: string) => {
            navigation.navigate("HelpDetailsLevelTwo", {
                level1: route.params.level1,
                level2: topic,
            });
        },
        [navigation, route.params.level1],
    );

    return (
        <>
            {data &&
                data[route.params.level1].details.map((detail, index) => {
                    return (
                        <CommonTile
                            key={detail.topic + index}
                            style={
                                data[route.params.level1].details.length === 1
                                    ? { ...styles.firstBoxStyle, ...styles.lastBoxStyle }
                                    : index === 0
                                    ? styles.firstBoxStyle
                                    : index === data[route.params.level1].details.length - 1
                                    ? styles.lastBoxStyle
                                    : {}
                            }
                            onPress={() => onPress(detail.topic)}
                            isDividerPresent={
                                index !== data[route.params.level1].details.length - 1
                            }>
                            <Text category="b1" color="color-basic-75" weight="medium">
                                {detail.topic}
                            </Text>
                        </CommonTile>
                    );
                })}
        </>
    );
};

export const HelpDetailsLevelOneContent: React.FC = () => {
    return (
        <HelpContentFetcher>
            <HelpDetailsLevelOneContentFetcher />
        </HelpContentFetcher>
    );
};

const styles = StyleSheet.create({
    firstBoxStyle: { borderTopLeftRadius: 16, borderTopRightRadius: 16 },
    lastBoxStyle: { borderBottomLeftRadius: 16, borderBottomRightRadius: 16 },
});
