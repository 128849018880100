import React from "react";
import { StyleSheet } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { Order } from "@minis-consumer/interfaces/order";
import {
    ReturnOrderSubStatuses,
    ReturnOrderSubStatusesEnum,
} from "@minis-consumer/interfaces/return-order";
import { ReturnOrderCloseDetails } from "@minis-consumer/routes/order/components/close-return-details";
import { ReturnOrderRejectDetails } from "@minis-consumer/routes/order/components/rejection-details";

import { getOrderCancelledByText, getReturnOrderStatusesTextMap } from "../../../helper";

interface Props {
    order: Order;
}

const ICON_SIZE = 16;

const CLOSED_STATUS: Partial<ReturnOrderSubStatuses[]> = [
    "CLOSED",
    "REFUNDED",
    "EXCHANGED",
    "OTHER",
    "REJECTED",
];

const OrderStatusComponent: React.FC<Props> = ({ order }) => {
    const { value: theme } = useTheme();

    const orderDelivered = order.status === "DELIVERED";
    const orderCancelledText = getOrderCancelledByText(order.status);

    const returnDetails = order.returnDetails;

    const { items = [] } = order;
    const { order: returnOrder } = returnDetails || {};
    const { resolutionDetails, status } = returnOrder || {};
    const returnItems = returnDetails?.order?.items || [];
    const { reason = "", details } = resolutionDetails || {};
    const isPartialReturned = returnItems.length !== items.length;

    const bulletPoint = (): JSX.Element => {
        if (CLOSED_STATUS.indexOf(status) > -1) {
            return (
                <SvgIcon
                    icon={
                        status === ReturnOrderSubStatusesEnum.REJECTED
                            ? "CrossFilled"
                            : "TickFilled"
                    }
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                    color={
                        status === "REJECTED"
                            ? theme["color-basic-45"]
                            : theme["color-positive-400"]
                    }
                />
            );
        }

        const returnColor = returnOrder ? theme["color-critical-100"] : theme["color-positive-400"];
        const backgroundColor = orderCancelledText ? theme["color-basic-45"] : returnColor;

        return (
            <Box
                style={[
                    orderCancelledText ? styles.squareBullet : styles.bullet,
                    {
                        backgroundColor,
                    },
                ]}
            />
        );
    };

    const returnOrderStatus: JSX.Element = React.useMemo(() => {
        if (returnDetails && returnOrder && status) {
            const statusTextMap = getReturnOrderStatusesTextMap(order);

            return (
                <Text category="b2" weight="medium" color="color-basic-60">
                    {statusTextMap[status] || reason || "Order Returned"}
                </Text>
            );
        }

        return (
            <Text category="b2" weight="medium" color="color-basic-60">
                {orderCancelledText ?? "Order Delivered"}
            </Text>
        );
    }, [
        orderCancelledText,
        isPartialReturned,
        items,
        returnDetails,
        returnItems,
        returnOrder,
        status,
        reason,
        order,
    ]);

    const getFooterComponent = (): JSX.Element | null => {
        if (!returnOrder) {
            return null;
        }

        const { statusUpdatedAt } = returnOrder;

        if (status === ReturnOrderSubStatusesEnum.REJECTED) {
            return (
                <ReturnOrderRejectDetails
                    reason={reason}
                    additionalDetails={details}
                    rejectedAt={statusUpdatedAt}
                    showHeaderText={false}
                />
            );
        }

        if (status === ReturnOrderSubStatusesEnum.CLOSED) {
            return (
                <ReturnOrderCloseDetails
                    subStatus={status}
                    closedAtEpoch={statusUpdatedAt}
                    resolutionDetails={resolutionDetails}
                    showHeaderText={false}
                />
            );
        }

        return null;
    };

    if (!orderCancelledText && !orderDelivered) {
        return null;
    }

    return (
        <Stack style={{ gap: SpacingValue["space-small"] }}>
            <Stack direction="row" spacing={SpacingValue["space-x-small"]} alignItems="center">
                {bulletPoint()}
                {returnOrderStatus}
            </Stack>
            {getFooterComponent()}
        </Stack>
    );
};

const styles = StyleSheet.create({
    mv0: {
        marginTop: 0,
        marginBottom: 0,
    },
    bullet: {
        height: 8,
        width: 8,
        borderRadius: 4,
    },
    squareBullet: {
        height: 8,
        width: 8,
        borderRadius: 1,
    },
});

export const OrderStatus = React.memo(OrderStatusComponent);
