import * as React from "react";
import { StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

import { OrderBill, OrderItem } from "@minis-consumer/interfaces/order";
import { Card } from "@minis-consumer/components/card";
import { Divider } from "@minis-consumer/components/divider";
import { OfferDiscount } from "@minis-consumer/components/offer-discount";
import { Coupon } from "@minis-consumer/interfaces/coupon";

import BillItem from "./bill-item";
import { CUSTOM_PRODUCTS } from "../constants";

type AggregateDetails = Pick<
    OrderBill,
    "billTotal" | "itemTotal" | "finalItemTotal" | "itemDiscount" | "couponDiscount"
>;

type BillCardProps = {
    normalItems: OrderItem[];
    customItems: OrderItem[];
    deliveryFee: OrderBill["deliveryFee"];
    otherCharges: OrderBill["charges"];
    aggregateDetails: AggregateDetails;
    orderPaymentType: string | null;
    appliedCoupon?: Coupon["code"];
};

const BillCard: React.FC<BillCardProps> = ({
    normalItems,
    customItems,
    deliveryFee,
    otherCharges,
    aggregateDetails,
    orderPaymentType,
    appliedCoupon,
}) => {
    const { value: theme } = useTheme();

    const itemTotal = aggregateDetails.itemTotal ?? 0;
    const finalItemTotal = aggregateDetails.finalItemTotal ?? 0;
    const itemDiscount = aggregateDetails.itemDiscount ?? 0;
    const couponDiscount = aggregateDetails.couponDiscount ?? 0;
    const totalDiscount = React.useMemo(
        () => itemDiscount + couponDiscount,
        [itemDiscount, couponDiscount],
    );

    return (
        <Card>
            <Stack spacing={SpacingValue["space-medium"]}>
                {normalItems.map((itemDetail) => (
                    <BillItem
                        key={`${itemDetail.item.id}_${itemDetail.item.variantId}`}
                        name={itemDetail.item.name}
                        price={itemDetail.item.price}
                        quantity={itemDetail.quantity}
                        discountedPrice={itemDetail.item.discountedPrice}
                        description={itemDetail.item.description}
                        weight={itemDetail.item.weight}
                        variants={itemDetail.item.variantOptionValues}
                    />
                ))}

                {customItems.length ? (
                    <Stack spacing={SpacingValue["space-medium"]}>
                        <Text
                            category="btn5"
                            weight="bold"
                            color="color-basic-45"
                            style={styles.customItems}>
                            {CUSTOM_PRODUCTS}
                        </Text>

                        {customItems.map((itemDetail) => (
                            <BillItem
                                key={itemDetail.item.id}
                                name={itemDetail.item.name}
                                price={itemDetail.item.price}
                                quantity={itemDetail.quantity}
                                discountedPrice={itemDetail.item.discountedPrice}
                                description={itemDetail.item.description}
                                weight={itemDetail.item.weight}
                            />
                        ))}
                    </Stack>
                ) : null}

                <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.mt0} />

                <BillItem
                    name="Cart Total"
                    price={itemTotal}
                    // This is to show striked off item total with final item total for old orders
                    // as old orders will not have any discount params introduced for coupons
                    discountedPrice={totalDiscount ? undefined : finalItemTotal}
                />

                {/* To show for both case when we have only product and / or coupon discounts */}
                {totalDiscount ? (
                    <OfferDiscount
                        finalAmount={totalDiscount}
                        titleColor="high"
                        itemDiscount={itemDiscount}
                        couponDiscount={couponDiscount}
                        appliedCoupon={appliedCoupon}
                    />
                ) : null}

                <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.mt0} />

                {/* To show for both case when we have only product and / or coupon discounts */}
                {totalDiscount ? <BillItem name="Sub Total" price={finalItemTotal} /> : null}

                {otherCharges.length
                    ? otherCharges.map((charge, index) => (
                          <Stack spacing={SpacingValue["space-medium"]} key={index}>
                              <BillItem
                                  name={charge.name}
                                  price={charge.value}
                                  discountedPrice={charge.finalValue}
                              />
                          </Stack>
                      ))
                    : null}

                {deliveryFee ? (
                    <>
                        <BillItem
                            name={deliveryFee.name}
                            price={deliveryFee.value}
                            discountedPrice={deliveryFee.finalValue}
                        />

                        <Divider />
                    </>
                ) : null}

                <BillItem name="Bill total" price={aggregateDetails.billTotal} finalTotal />

                {orderPaymentType ? (
                    <Text category="b3" color="color-basic-45" style={styles.centerAlign}>
                        {`Paid via ${orderPaymentType}`}
                    </Text>
                ) : null}
            </Stack>
        </Card>
    );
};

const styles = StyleSheet.create({
    customItems: {
        marginTop: SpacingValue["space-medium"],
        marginBottom: SpacingValue["space-small"],
    },
    mt0: {
        marginTop: 0,
    },
    centerAlign: {
        textAlign: "center",
    },
});

export default React.memo(BillCard);
