import { TextColor } from "@swiggy-private/rn-dls";

import { Order, OrderStatus } from "@minis-consumer/interfaces/order";
import { ReturnOrderSubStatuses } from "@minis-consumer/interfaces/return-order";
import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";
import { OrderEntity } from "@minis-consumer/resources/order";

import { StatusProgressProps } from "./components/status-progress";

import { OrderSection } from "../my-account";
import { PRODUCT_TYPE } from "@minis-consumer/interfaces/catalog";

export const getOrderSections = (orders: OrderEntity[] = []): OrderSection[] => {
    const activeOrderStatuses: OrderStatus[] = [
        "PENDING",
        "ACCEPTED",
        "INITIATED",
        "IN_TRANSIT",
        "READY_FOR_PICKUP",
        "TRANSACTION_FAILED",
        "TRANSACTION_IN_PROGRESS",
    ];

    const activeOrders: OrderEntity[] = [];
    const pastOrders: OrderEntity[] = [];

    orders.forEach((order) => {
        if (activeOrderStatuses.includes(order.status)) {
            activeOrders.push(order);
        } else {
            pastOrders.push(order);
        }
    });

    return buildOrderSections(activeOrders, pastOrders);
};

const buildOrderSections = (
    activeOrders: OrderEntity[],
    pastOrders: OrderEntity[],
): OrderSection[] => {
    const sections: OrderSection[] = [];

    if (activeOrders.length) {
        sections.push({
            active: true,
            title: "Active orders",
            data: activeOrders,
        });
    }

    if (pastOrders.length) {
        sections.push({
            active: false,
            title: "Past orders",
            data: pastOrders,
        });
    }

    return sections;
};

export type OrderCardCtaType = "RETRY_PAYMENT" | "REORDER" | "CHECK_REFUND";

const getTextsByProductType = (
    storeName: string,
): Record<
    PRODUCT_TYPE,
    Record<string, { title: string; subTitle?: string; ctaType?: OrderCardCtaType }> | null
> => {
    return {
        APPOINTMENT: {
            PENDING: {
                title: "Waiting for Confirmation",
                subTitle: `${storeName} will review your preferred time and confirm your order shortly. Feel free to contact us via chat if you have any queries.`,
            },
            ACCEPTED: {
                title: "Order Confirmed",
                subTitle: `Your order has been confirmed by ${storeName}`,
            },
            DELIVERED: {
                title: "Order Completed",
                subTitle: `Your order has been marked as completed by ${storeName}. Hope you enjoyed the experience.`,
                ctaType: "REORDER",
            },
        },
        DIGITAL: {
            DELIVERED: {
                title: "Order Completed",
                subTitle: `Your order has been marked as completed by ${storeName}. Hope you enjoyed the experience.`,
                ctaType: "REORDER",
            },
        },
        PLAN: {
            PENDING: {
                title: "Waiting for Confirmation",
                subTitle: `Please allow ${storeName} some time to confirm your order. Feel free to contact us via chat if you have any queries.`,
            },
            ACCEPTED: {
                title: "Order Confirmed",
                subTitle: `Your order has been marked as completed by ${storeName}. Hope you enjoyed the experience.`,
            },
            DELIVERED: {
                title: "Order Completed",
                subTitle: `Your order has been marked as completed by ${storeName}. Hope you enjoyed the experience.`,
                ctaType: "REORDER",
            },
        },
        PHYSICAL: null,
    };
};

const getText = (
    storeName: string,
    status: OrderStatus,
    orderType: PRODUCT_TYPE,
):
    | {
          title: string;
          subTitle?: string;
          ctaType?: OrderCardCtaType;
      }
    | undefined => {
    const textsByProductType = getTextsByProductType(storeName);

    const productType = orderType ?? "PHYSICAL";

    const texts = textsByProductType[productType]?.[status];

    return texts;
};

export const getOrderListInfo = (
    orderStatus: OrderStatus,
    storeName: string,
    orderType: PRODUCT_TYPE,
    courierPartner?: string,
): {
    title: string;
    titleColor?: TextColor;
    subTitle?: string;
    ctaType?: OrderCardCtaType;
    // eslint-disable-next-line max-params
} | null => {
    const orderTexts = getText(storeName, orderStatus, orderType);

    if (orderTexts) {
        return orderTexts;
    }

    switch (orderStatus) {
        case "PENDING":
            return {
                title: "Order confirmation pending",
                subTitle: `${storeName} will review and confirm your order`,
            };

        case "ACCEPTED":
            return {
                title: "Order confirmed",
                subTitle: `${storeName} will dispatch your order soon`,
            };

        case "READY_FOR_PICKUP":
            return {
                title: "Order ready to dispatch",
                subTitle: `${storeName} will ship your order soon`,
            };

        case "IN_TRANSIT":
            return {
                title: "Order in transit",
                subTitle: `${storeName} has shipped your order${
                    courierPartner ? " via " + courierPartner : ""
                }`,
            };

        case "DELIVERED":
            return {
                title: "Order delivered",
                subTitle: courierPartner ? `via ${courierPartner} ` : "" + `by ${storeName}`,
                ctaType: "REORDER",
            };

        case "CANCELLED_USER":
        case "CANCELLED_SELLER":
        case "CANCELLED_CC":
        case "CANCELLED_AUTO":
            return {
                title: "Order cancelled",
                ctaType: "CHECK_REFUND",
                subTitle: "Unfortunately your order was cancelled. Refund initiated.",
            };

        case "PAYMENT_FAILED":
            return {
                title: "Payment failed",
                subTitle: "Your payment for this order could not be completed.",
                ctaType: "REORDER",
            };

        case "INITIATED":
        case "TRANSACTION_IN_PROGRESS":
            return {
                title: "Awaiting payment status",
                subTitle: "We are checking with the bank to confirm payment status",
            };

        case "TRANSACTION_FAILED":
            return {
                title: "Payment failed",
                titleColor: "critical",
                subTitle: "Due to a technical error, payment has failed. Please retry",
                ctaType: "RETRY_PAYMENT",
            };

        case "CANCELLED_RTO":
        case "CANCELLED_RTO_DELIVERED":
        case "CANCELLED_DISPATCH":
            return {
                title: "Order returned to seller",
                subTitle:
                    "This order wasn’t delivered and has been returned to the seller. Refund initiated.",
                ctaType: "CHECK_REFUND",
            };

        default:
            return null;
    }
};

export const getStatusProgress = (
    orderStatus: OrderStatus,
    orderType?: PRODUCT_TYPE,
): {
    fillWidth: number;
    type: StatusProgressProps["type"];
} | null => {
    orderType = orderType || "PHYSICAL";

    if (orderType !== "PHYSICAL") {
        switch (orderStatus) {
            case "TRANSACTION_FAILED":
                return {
                    fillWidth: 0.05,
                    type: "danger",
                };

            case "TRANSACTION_IN_PROGRESS":
            case "INITIATED":
                return {
                    fillWidth: 0.05,
                    type: "warning",
                };

            case "PENDING":
                return {
                    fillWidth: 0.5,
                    type: "warning",
                };

            case "ACCEPTED":
                return {
                    fillWidth: 0.75,
                    type: "success",
                };

            default:
                return null;
        }
    }

    switch (orderStatus) {
        case "TRANSACTION_FAILED":
            return {
                fillWidth: 0.05,
                type: "danger",
            };

        case "TRANSACTION_IN_PROGRESS":
        case "INITIATED":
            return {
                fillWidth: 0.05,
                type: "warning",
            };

        case "PENDING":
            return {
                fillWidth: 0.15,
                type: "warning",
            };

        case "ACCEPTED":
            return {
                fillWidth: 0.25,
                type: "success",
            };

        case "READY_FOR_PICKUP":
            return {
                fillWidth: 0.4,
                type: "success",
            };

        case "IN_TRANSIT":
            return {
                fillWidth: 0.8,
                type: "success",
            };

        default:
            return null;
    }
};

export const getOrderCancelledByText = (orderStatus: OrderStatus): string | null => {
    const orderCancelled = [
        "CANCELLED_CC",
        "CANCELLED_AUTO",
        "CANCELLED_SELLER",
        "CANCELLED_USER",
    ].includes(orderStatus);

    if (!orderCancelled) {
        return null;
    }

    switch (orderStatus) {
        case "CANCELLED_AUTO":
            return "Order auto cancelled";

        case "CANCELLED_CC":
            return "Order cancelled by customer care agent";

        case "CANCELLED_SELLER":
            return "Order cancelled by seller";

        case "CANCELLED_USER":
            return "Order cancelled by you";

        default:
            return null;
    }
};

export const getReturnOrderStatusesTextMap = (
    order: Order,
): { [key in ReturnOrderSubStatuses]: string } => {
    const returnDetails = order.returnDetails;

    const { items = [] } = order;
    const { order: returnOrder } = returnDetails || {};

    const { statusUpdatedAt = 0 } = returnOrder || {};
    const returnItems = returnDetails?.order?.items || [];
    const isPartialReturned = returnItems.length !== items.length;
    const partialText = isPartialReturned ? ` (${returnItems.length}/${items.length} items)` : "";

    const formattedStatusUpdatedAt = convertToReadableDateAndTime(statusUpdatedAt);
    const closedStatusText = `Closed request${partialText}: ${formattedStatusUpdatedAt}`;

    const statusTextMap: { [key in ReturnOrderSubStatuses]: string } = {
        PENDING: `Return Requested${partialText}`,
        IN_PROGRESS: `Return request accepted${partialText}`,
        REJECTED: `Rejected request${partialText}: ${formattedStatusUpdatedAt}`,
        REFUNDED: closedStatusText,
        CLOSED: closedStatusText,
        EXCHANGED: closedStatusText,
        OTHER: closedStatusText,
    };

    return statusTextMap;
};
