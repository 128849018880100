import React, { memo, useMemo } from "react";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

import { Product } from "@minis-consumer/interfaces/catalog";
import { getProductDurationUnitText } from "@minis-consumer/helpers/catalog";

/** To be used for Plan and Appointment type products */
const ProductDurationComponent: React.FC<Partial<Product>> = ({ productDuration, productType }) => {
    const { unit, value } = productDuration ?? {};

    const title = useMemo(
        () => (productType === "APPOINTMENT" || productType === "PLAN" ? "Duration" : ""),
        [productType],
    );

    if (!unit || !value || productType === "PHYSICAL") {
        return null;
    }

    const durationUnitText = getProductDurationUnitText({
        value: value,
        unit: unit,
    });

    return (
        <Stack spacing={SpacingValue["space-xx-small"]}>
            {title ? (
                <Text category="b2" color="low" weight="regular">
                    {title}
                </Text>
            ) : null}
            <Text
                category="b2"
                color={title ? "high" : "medium"}
                weight={title ? "bold" : "regular"}>
                {value} {durationUnitText}
            </Text>
        </Stack>
    );
};

export const ProductDuration = memo(ProductDurationComponent);
