import * as React from "react";
import {
    FlatList,
    ListRenderItem,
    Platform,
    Pressable,
    StyleProp,
    StyleSheet,
    useWindowDimensions,
    ViewStyle,
    View,
} from "react-native";
import { useIsFocused } from "@react-navigation/core";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { Text, useTheme, SpacingValue, Overlay, Portal } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { AnimatedView, useMeasure } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";

import {
    getBadgeCategoryNativeId,
    getProductCategoryNativeId,
} from "@minis-consumer/helpers/catalog";
import { findAndScrollToElement } from "@minis-consumer/helpers/dom";
import { ScrollContext } from "@minis-consumer/contexts/scroll-context";
import { Analytics } from "@minis-consumer/analytics";

import { useGetProductCategories } from "../catalog/hooks/use-get-categories";
import { useGetBadgesWithProducts } from "../catalog/hooks/use-get-badges-with-products";
import { HEADER_HEIGHT, NAV_BAR_HEIGHT_DEFAULT, SF_ANALYTICS_EVENT_NAMES } from "../../constants";

type ShopFloatingActionButtonProps = {
    onPressItem?: (itemId: string) => void;
    navBarShown?: boolean;
    offset: number;
};

type ListElementType = {
    id: string;
    name: string;
    productsCount: number;
    nativeId: string;
};

const ListHeader: React.FC = () => {
    return (
        <Text category="b3" weight="regular" color="color-basic-0" style={styles.listHeader}>
            Explore Categories
        </Text>
    );
};

const ListItemSeparator: React.FC = () => {
    const { value: theme } = useTheme();
    return (
        <Box
            mv={SpacingValue["space-medium"]}
            style={[styles.itemSeparator, { backgroundColor: theme["color-basic-100"] }]}
        />
    );
};

const useGetCategoryList = (): ListElementType[] => {
    const productCategories = useGetProductCategories();
    const productBadges = useGetBadgesWithProducts();

    const listData = React.useMemo((): ListElementType[] => {
        const data: ListElementType[] = [];

        if (productBadges) {
            const recommendedCategory = productBadges.find(
                (badge) => badge[0].name === "Recommended",
            );

            if (recommendedCategory) {
                data.push({
                    id: recommendedCategory[0].id,
                    name: recommendedCategory[0].name,
                    productsCount: recommendedCategory[1].length,
                    nativeId: getBadgeCategoryNativeId(recommendedCategory[0].id),
                });
            }
        }

        if (productCategories) {
            productCategories.forEach((category) => {
                data.push({
                    id: category.id,
                    name: category.name,
                    productsCount: category.products.length,
                    nativeId: getProductCategoryNativeId(category.id),
                });
            });
        }

        return data;
    }, [productBadges, productCategories]);

    return listData;
};

const ShopFAB: React.FC<ShopFloatingActionButtonProps> = ({ onPressItem, navBarShown, offset }) => {
    const { value: theme } = useTheme();

    const [showCategoryList, setShowCategoryList] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState<ListElementType>();

    const listData = useGetCategoryList();

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    const isFocused = useIsFocused();

    const menuBtnWidth = Math.min(144, windowWidth * 0.38);

    const scrollContext = React.useContext(ScrollContext);
    const buttonRef = React.useRef<View>(null);
    const buttonLayout = useMeasure(buttonRef.current);
    const initialMenuPos = React.useRef(buttonLayout?.pageY);

    const showMenu = React.useCallback(() => {
        Analytics.clickEvent({ category: "categories-floating-btn" });
        setShowCategoryList(true);
    }, []);

    const hideMenu = React.useCallback(() => {
        setShowCategoryList(false);
    }, []);

    const onMenuItemPress = React.useCallback(
        (item: ListElementType) => {
            hideMenu();
            setSelectedItem(item);

            Analytics.clickEvent({
                category: SF_ANALYTICS_EVENT_NAMES.CATEGORY_DROPDOWN_OPTION_CLICK,
                context: JSON.stringify({ selectedCategory: item.id }),
            });

            if (Platform.OS === "web") {
                findAndScrollToElement({
                    id: item.nativeId,
                    scrollView: scrollContext?.current ?? null,
                    headerOffset: HEADER_HEIGHT + offset,
                });
                return;
            }

            onPressItem?.(item.id);
        },
        [hideMenu, onPressItem, scrollContext, offset],
    );

    const extraData = React.useMemo(
        () => ({
            onMenuItemPress,
        }),
        [onMenuItemPress],
    );

    const renderItem: ListRenderItem<ListElementType> = React.useCallback(
        ({ item }) => {
            const textWeight = selectedItem?.id === item.id ? "bold" : "regular";
            return (
                <Pressable onPress={() => onMenuItemPress(item)} hitSlop={{ top: 16, bottom: 16 }}>
                    <Stack direction="row" alignItems="center" justifyContent="center" flex={1}>
                        <Text
                            category="b2"
                            weight={textWeight}
                            color={"color-basic-0"}
                            numberOfLines={1}
                            style={styles.itemName}>
                            {item.name}
                        </Text>
                    </Stack>
                </Pressable>
            );
        },
        [onMenuItemPress, selectedItem],
    );

    const categoryListStyle = {
        backgroundColor: theme["color-basic-75"],
        width: Math.min(300, windowWidth / 2),
        maxHeight: Math.floor(windowHeight * 0.7),
    };

    const menuBtnExtraStyles = {
        minWidth: menuBtnWidth,
    };

    useMount(() => {
        Analytics.impressionEvent({ category: "categories-floating-btn" });
    });

    React.useEffect(() => {
        if (initialMenuPos.current !== undefined && initialMenuPos.current !== 0) {
            return;
        }
        initialMenuPos.current = buttonLayout?.pageY;
    }, [buttonLayout?.pageY]);

    const insets = useSafeAreaInsets();
    const menuContainerPos =
        HEADER_HEIGHT +
        SpacingValue["space-small"] +
        (navBarShown ? NAV_BAR_HEIGHT_DEFAULT : 0) +
        insets.top;

    return (
        <>
            {showCategoryList && isFocused ? (
                <Portal>
                    <Overlay backgroundColor="primary" onPress={hideMenu} />
                </Portal>
            ) : null}

            {isFocused ? (
                <Box
                    style={[styles.container]}
                    pointerEvents={isFocused ? "box-none" : "none"}
                    ref={buttonRef}>
                    <Pressable
                        onPress={showMenu}
                        testID="categories-floating-btn"
                        style={[
                            styles.menuBtn,
                            menuBtnExtraStyles,
                            { backgroundColor: theme["color-basic-75"] },
                        ]}>
                        <Stack direction="row" alignItems="center">
                            <Text category="b1" color="color-basic-0">
                                Categories
                            </Text>
                            <SvgIcon
                                icon="ChevronDown"
                                color="color-basic-0"
                                height={SpacingValue["space-large"]}
                            />
                        </Stack>
                    </Pressable>

                    {showCategoryList ? (
                        <Portal>
                            <MenuContainer
                                style={[
                                    styles.categoryListContainer,
                                    categoryListStyle,
                                    {
                                        top: menuContainerPos,
                                    },
                                ]}
                                show={showCategoryList}
                                setShow={setShowCategoryList}>
                                <FlatList
                                    bounces={false}
                                    ListHeaderComponent={<ListHeader />}
                                    ItemSeparatorComponent={() => <ListItemSeparator />}
                                    data={listData}
                                    keyExtractor={(item: ListElementType) => item.id}
                                    renderItem={renderItem}
                                    contentContainerStyle={styles.categoryListContent}
                                    showsVerticalScrollIndicator={false}
                                    extraData={extraData}
                                />
                            </MenuContainer>
                        </Portal>
                    ) : null}
                </Box>
            ) : null}
        </>
    );
};

type MenuContainerProps = {
    children: React.ReactNode;
    style: StyleProp<ViewStyle>;
    show: boolean;
    setShow: (b: boolean) => void;
};

const MenuContainer: React.FC<MenuContainerProps> = (props) => {
    const { children, style, show, setShow } = props;

    if (Platform.OS === "web") {
        return (
            <Box testID="categories-list" style={style}>
                {children}
            </Box>
        );
    }

    return (
        <AnimatedView
            yOrigin="top"
            reverse={!show}
            onFinish={() => setShow(show)}
            duration={100}
            style={style}>
            {children}
        </AnimatedView>
    );
};

const styles = StyleSheet.create({
    container: {
        alignSelf: "center",
    },
    menuBtn: {
        borderWidth: 0,
        borderRadius: 30,
        paddingVertical: 5 * SpacingValue["space-xxx-small"],
        paddingHorizontal: SpacingValue["space-large"],
    },
    categoryListContainer: {
        position: "absolute",
        minWidth: 240,
        borderRadius: 24,
        alignSelf: "center",
    },
    categoryListContent: {
        padding: SpacingValue["space-medium"],
    },
    listHeader: {
        marginBottom: SpacingValue["space-xx-large"],
        textTransform: "uppercase",
        textAlign: "center",
    },
    itemName: {
        textAlign: "center",
    },
    itemSeparator: {
        height: 1,
        opacity: 0.3,
    },
});

export const ShopBrowse = React.memo(ShopFAB);
