import * as React from "react";
import { StyleSheet } from "react-native";

import { useSuspense } from "@rest-hooks/react";

import {
    Box,
    ScreenSize,
    useScreenSize,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { UserAddresses } from "@minis-consumer/resources/address";

import { CardItem } from "./card-item";
import { SavedAddressesEmptyState } from "./empty-state";

export const SavedAddressesContent: React.FC = () => {
    const addresses = useSuspense(UserAddresses);

    const screenSize = useScreenSize();
    const containerStyle = useSelectScreen({
        lg: {
            paddingVertical: SpacingValue["space-x-large"],

            height: "100%",
        },
        default: {
            paddingVertical: SpacingValue["space-medium"],
            height: "100%",
        },
    });

    const wrapperStyle = useSelectScreen({
        lg: { width: "50%", marginTop: SpacingValue["space-medium"] },
        default: { width: "100%", marginTop: SpacingValue["space-medium"] },
    });

    return (
        <Box direction="row" style={[styles.addressItemContainer, containerStyle]}>
            {Array.isArray(addresses) && addresses.length !== 0 ? (
                addresses.map((address, index) => (
                    <Box
                        key={address.id}
                        style={[
                            wrapperStyle,
                            index <= 1 && screenSize === ScreenSize.Large ? styles.mtO : null,
                            screenSize === ScreenSize.Large
                                ? index % 2 === 0
                                    ? styles.prXSmall
                                    : styles.plXSmall
                                : {},
                        ]}>
                        <CardItem address={address} />
                    </Box>
                ))
            ) : (
                <SavedAddressesEmptyState />
            )}
        </Box>
    );
};

const styles = StyleSheet.create({
    addressItemContainer: {
        flexWrap: "wrap",
    },
    addressItemWrapper: {
        width: "50%",
        marginTop: SpacingValue["space-medium"],
    },
    plXSmall: {
        paddingLeft: SpacingValue["space-x-small"],
    },
    prXSmall: {
        paddingRight: SpacingValue["space-x-small"],
    },
    mtO: {
        marginTop: 0,
    },
    addressEmptyContainer: {
        width: "100%",
        height: "100%",
    },
});
