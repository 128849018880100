import React from "react";
import { StyleSheet, TextStyle, ViewStyle } from "react-native";

import { Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { Cart } from "@minis-consumer/interfaces/cart";
import { CartAddressState } from "@minis-consumer/interfaces/cart";
import { useCompleteAddress } from "@minis-consumer/hooks/use-complete-address";
import { displayAddressNameAndIcon } from "@minis-consumer/helpers/address";

interface Props {
    address: NonNullable<Cart["cartViewData"]>["addresses"][0];
    state: CartAddressState;
    onChangeAddress: () => void;
}

const SelectedAddressComponent: React.FC<Props> = ({ address, state, onChangeAddress }) => {
    const { value: theme } = useTheme();
    const completeAddress = useCompleteAddress(address);

    const { addressTag, icon } = displayAddressNameAndIcon(address, true);

    const serviceable = React.useMemo(() => state === "SELECTED_SUCCESS", [state]);

    const [ctaStyle, ctaTextStyle] = React.useMemo<[ViewStyle, TextStyle]>(() => {
        return [
            {
                borderWidth: 0,
                backgroundColor: serviceable
                    ? theme["color-primary-25"]
                    : theme["color-primary-400"],
            },
            {
                color: serviceable ? theme["color-primary-400"] : theme["color-basic-0"],
            },
        ];
    }, [serviceable, theme]);

    return (
        <Box flex={1} direction="row" alignItems="flex-start">
            <Stack spacing={SpacingValue["space-x-small"]} flex={1}>
                <Stack spacing={SpacingValue["space-x-small"]} direction="row" alignItems="center">
                    <SvgIcon icon={icon} height={16} width={16} color="color-basic-100" />
                    <Text category="b1" weight="bold" color="high">
                        {addressTag}
                    </Text>
                </Stack>

                <Box flex={1} style={styles.formattedAddressContainer}>
                    <Text category="b2" color="medium">
                        {completeAddress}
                    </Text>
                </Box>
            </Stack>

            <Box direction="row">
                <Button
                    onPress={onChangeAddress}
                    color="primary"
                    style={ctaStyle}
                    textStyle={ctaTextStyle}
                    disabled={!serviceable}
                    size="small">
                    Change
                </Button>
            </Box>
        </Box>
    );
};

export const SelectedAddress = React.memo(SelectedAddressComponent);

const styles = StyleSheet.create({
    formattedAddressContainer: {
        maxWidth: 256,
    },
});
