import React from "react";
import {
    ActivityIndicator,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Platform,
    ViewStyle,
} from "react-native";
import { useSuspense } from "@rest-hooks/react";
import { useLoading } from "@rest-hooks/hooks";
import { useNavigation } from "@react-navigation/core";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useMount } from "@swiggy-private/react-hooks";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { FocusAwareStatusBar, IOSectionList } from "@swiggy-private/react-native-ui";

import { useSignedIn } from "@minis-consumer/hooks/use-user";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { usePaginator } from "@minis-consumer/hooks/use-paginator";
import { OrderEntity, OrderList } from "@minis-consumer/resources/order";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { useIsNavbarVisible } from "@minis-consumer/hooks/use-is-navbar-visible";
import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { RouteList } from "@minis-consumer/interfaces/route";

import { getOrderSections } from "./helper";
import { OrderCard } from "./components/order-card";
import { HeaderLeft } from "./components/header-left";
import { SectionTitle } from "./components/section-title";
import { ListEmptyComponent } from "./components/list-empty";
import { ListHeaderComponent } from "./components/section-header";
import { LoadingPlaceholder } from "./components/loading-placeholder";

export type OrderSection = {
    active: boolean;
    title: string;
    data: OrderEntity[];
};

const MyAccountScreenComponent: React.FC = () => {
    const { value: theme } = useTheme();
    const insets = useSafeAreaInsets();
    const isNavbarVisible = useIsNavbarVisible();
    const navigation = useNavigation();

    const sectionListRef = React.useRef();

    const storeInfo = useStoreInfo(false);
    const storeId = storeInfo?.storeId;

    const orderListResponse = useSuspense(OrderList, { storeId });
    const getNextPage = usePaginator(OrderList, { storeId });

    const [fetchNextPage, fetching] = useLoading(
        async (cursor: string) => getNextPage?.(cursor),
        [getNextPage],
    );

    const showHeader = React.useCallback(() => {
        if (storeInfo) {
            navigation.setOptions({
                headerShown: true,
                headerLeft: () => <HeaderLeft shown />,
                headerTitle: () => <></>,
                headerBackVisible: false,
                headerBackgroundContainerStyle: {
                    backgroundColor: theme["color-background-primary"],
                },
            });

            return;
        }

        navigation.setOptions({
            headerLeft: () => <HeaderLeft shown />,
            headerTitle: () => <></>,
            headerBackVisible: false,
            headerStyle: {
                backgroundColor: theme["color-background-primary"],
            },
        });
    }, [navigation, storeInfo, theme]);

    const hideHeader = React.useCallback(() => {
        if (storeInfo) {
            navigation.setOptions({
                headerTransparent: true,
                headerShown: false,
                headerBackgroundContainerStyle: {
                    backgroundColor: "transparent",
                },
            });

            return;
        }

        navigation.setOptions({
            header: undefined,
            headerShown: true,
            headerLeft: () => <HeaderLeft />,
            headerTitle: () => <></>,
            headerBackVisible: false,
            headerTransparent: true,
            headerStyle: {
                backgroundColor: "transparent",
            },
        });
    }, [navigation, storeInfo]);

    useMount(() => {
        hideHeader();
    });

    const onScroll = React.useCallback(
        ({
            nativeEvent: {
                contentOffset: { y: yOffset },
            },
        }: NativeSyntheticEvent<NativeScrollEvent>) => {
            if (isNavbarVisible) {
                return;
            }

            if (yOffset > 40) {
                showHeader();
            } else {
                hideHeader();
            }
        },
        [hideHeader, isNavbarVisible, showHeader],
    );

    const onEndReached = React.useCallback(() => {
        if ((orderListResponse.orders && !orderListResponse.orders.length) || fetching) {
            return;
        }

        fetchNextPage(orderListResponse.cursor);
    }, [fetchNextPage, fetching, orderListResponse.cursor, orderListResponse.orders]);

    const sections = React.useMemo(
        () => getOrderSections(orderListResponse.orders),
        [orderListResponse.orders],
    );

    const contentContainerStyle: ViewStyle = {
        flexGrow: 1,
        paddingHorizontal: SpacingValue["space-medium"],
        paddingBottom: Math.max(insets.bottom, SpacingValue["space-medium"]),
        backgroundColor: theme["color-background-secondary"],
    };

    return (
        <IOSectionList
            ref={sectionListRef}
            bounces={false}
            sections={sections}
            renderSectionHeader={({ section: { title } }) => <SectionTitle title={title} />}
            renderItem={({ item, section }) => <OrderCard isActive={section.active} order={item} />}
            keyExtractor={(item) => item.id}
            onScroll={onScroll}
            scrollEventThrottle={16}
            contentContainerStyle={contentContainerStyle}
            stickySectionHeadersEnabled={false}
            ItemSeparatorComponent={() => <Box mt={SpacingValue["space-small"]} />}
            onEndReached={onEndReached}
            onEndReachedThreshold={1}
            ListHeaderComponent={ListHeaderComponent}
            ListEmptyComponent={ListEmptyComponent}
            ListFooterComponent={() => (
                <Box mt={SpacingValue["space-xx-small"]}>
                    <ActivityIndicator animating={fetching} color={theme["color-primary"]} />
                </Box>
            )}
            disableVirtualization={Platform.OS === "web"}
        />
    );
};

const MyAccountScreenWrapper: React.FC = () => {
    const signedIn = useSignedIn();

    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();

    React.useEffect(() => {
        if (signedIn) {
            return;
        }

        navigation.canGoBack()
            ? navigation.goBack()
            : navigation.replace("Home", { screen: "Shop" });
    }, [navigation, signedIn]);

    if (!signedIn) {
        return null;
    }

    return <MyAccountScreenComponent />;
};

export const MyAccountScreen: React.FC<React.PropsWithChildren> = () => (
    <StateContextContainer>
        <FocusAwareStatusBar
            animated
            translucent
            backgroundColor="transparent"
            barStyle="dark-content"
        />

        <AsyncComponent fallback={LoadingPlaceholder}>
            <MyAccountScreenWrapper />
        </AsyncComponent>
    </StateContextContainer>
);
