import React from "react";
import { Pressable, TextInput } from "react-native";

import { Input, SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { useDebounceFn, useMountedRef } from "@swiggy-private/react-hooks";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";

type Props = { onChangeText: (text: string) => void; searchText: string; clearSearch: () => void };

const CatalogSearchInputComponent: React.FC<Props> = ({
    onChangeText,
    searchText,
    clearSearch,
}) => {
    const mountedRef = useMountedRef();

    const { value: theme } = useTheme();

    const inputRef = React.useRef<TextInput>(null);

    const store = useStoreInfo();

    const inputStyle = {
        fontFamily: theme["text-subheader-2-font-family"],
        letterSpacing: theme["text-subheader-2-letter-spacing"],
        lineHeight: theme["text-subheader-2-line-height"],
        color: theme["color-basic-100"],
    };

    const debouncedSearch = useDebounceFn(onChangeText, { wait: 300 });

    const clearSearchText = React.useCallback(() => {
        if (!mountedRef.current) {
            return;
        }

        inputRef.current?.clear();
        clearSearch();
    }, [inputRef, clearSearch, mountedRef]);

    return (
        <Box ph={SpacingValue["space-medium"]}>
            <Input
                ref={inputRef}
                placeholder={`Search ${store.name}`}
                accessoryLeft={
                    <Box justifyContent="center" alignItems="center">
                        <SvgIcon icon="Magnifier" color="color-basic-60" height={16} width={16} />
                    </Box>
                }
                accessoryRight={
                    searchText ? (
                        <Box justifyContent="center" alignItems="center">
                            <Pressable
                                accessible
                                accessibilityLabel="Clear Search"
                                onPress={clearSearchText}
                                hitSlop={{ top: 16, bottom: 16, left: 16, right: 16 }}>
                                <SvgIcon
                                    icon="Close"
                                    color="color-basic-60"
                                    height={16}
                                    width={16}
                                />
                            </Pressable>
                        </Box>
                    ) : undefined
                }
                textStyle={inputStyle}
                onChangeText={debouncedSearch}
                placeholderTextColor={theme["color-basic-45"]}
                autoFocus
            />
        </Box>
    );
};

export const CatalogSearchInput = React.memo(CatalogSearchInputComponent);
