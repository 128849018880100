import * as React from "react";
import { StyleSheet, ViewStyle, View } from "react-native";
import type { LayoutChangeEvent } from "react-native";
import { useSuspense } from "@rest-hooks/react";

import { SpacingValue, Surface, SurfaceProps, useTheme } from "@swiggy-private/rn-dls";
import { FocusAwareStatusBar, DashBorderLine } from "@swiggy-private/react-native-ui";
import { Box, useSelectScreen, Display, ScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { CatalogView } from "@minis-consumer/resources/catalog";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { ShopFooter } from "@minis-consumer/components/shop-footer";
import { StoreOnboardingBottomsheet } from "@minis-consumer/routes/shop/components/store-onboarding";
import { useAutoplayOnboarding } from "@minis-consumer/hooks/use-autoplay-onboarding";
import { useEnquiryModalHandler } from "@minis-consumer/hooks/use-enquiry-modal-handler";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";
import { StoreClosedContent } from "@minis-consumer/components/store-closed";
import { BLAZE_EVENTS, endCustomEvent } from "@minis-consumer/includes/blaze";
import {
    META_PIXEL_CONTENT_NAME,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { StorefrontNavBar } from "@minis-consumer/components/store-front-navbar";
import { StoreStories } from "@minis-consumer/components/store-stories";
import { ICatalogSectionsPos } from "@minis-consumer/interfaces/catalog";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";

import { ShopCatalog } from "../components/catalog";
import { CatalogLoader } from "../components/loader";
import { ShopFront } from "../components/shopfront";
import { ShopFrontCoupons } from "../components/shopfront-coupons";
import { InstagramWidgetLoader } from "../components/instagram-scroll-widget/instagram-loader";

type ShopViewProps = Omit<SurfaceProps, "children"> & {
    setNavbarLayout?: (e: LayoutChangeEvent) => void;
    setDeliveryInfoOffset: (offset: number) => void;
    catalogMeasureRef: React.RefObject<ICatalogSectionsPos>;
    storyId?: string;
};

const WithCatalog: React.FC<React.PropsWithChildren<{ storeSlug: string }>> = ({
    children,
    storeSlug,
}) => {
    useSuspense(CatalogView, { storeSlug });
    return <>{children}</>;
};

export const ShopView = React.forwardRef<View, ShopViewProps>(({ style, ...props }, ref) => {
    return <ShopContent {...props} handlerRef={ref} />;
});

const ShopContent: React.FC<ShopViewProps & { handlerRef: React.ForwardedRef<View> }> = ({
    style,
    handlerRef,
    setNavbarLayout,
    setDeliveryInfoOffset,
    catalogMeasureRef,
    storyId,
    ...props
}) => {
    const storeInfo = useStoreInfo();
    const isStoreEnabled = storeInfo.enabled !== false;

    const canShowOnboarding = useAutoplayOnboarding();
    const [isEnquiryModalOpen] = useEnquiryModalHandler();

    const [showOnboarding, setShowOnboarding] = React.useState(canShowOnboarding);

    const { value: theme } = useTheme();
    const isDesktop = useIsDesktop();

    useMount(() => {
        endCustomEvent(BLAZE_EVENTS.LOAD_TIME.STORE_FRONT);
        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.VIEW_CONTENT, {
            content_name: META_PIXEL_CONTENT_NAME.STORE_VIEW,
        });
    });

    const [borderTopLeftRadius, borderTopRightRadius] = useSelectScreen({
        lg: [16, 16, isStoreEnabled ? 0 : 16],
        md: [16, 16, 0],
        default: [0, 0, 0],
    }) as Array<ViewStyle["borderTopLeftRadius"] | ViewStyle["borderTopRightRadius"]>;

    const showCoupons = storeInfo.coupons?.length ?? 0 > 0;
    const showHighlights = storeInfo.highlights?.length ?? 0 > 0;

    return (
        <>
            <Surface
                style={[
                    styles.content,
                    style,
                    {
                        borderTopLeftRadius,
                        borderTopRightRadius,
                    },
                ]}
                {...props}>
                <ShopFront setDeliveryInfoOffset={setDeliveryInfoOffset} />
                <Display lt={ScreenSize.Medium}>
                    <Box mb={16} ref={handlerRef} collapsable={false}>
                        <StorefrontNavBar onLayout={setNavbarLayout} />
                    </Box>
                </Display>
                {storeInfo.highlights && !isDesktop ? (
                    <AsyncComponent fallback={InstagramWidgetLoader}>
                        <WithCatalog storeSlug={storeInfo.slug}>
                            <Box
                                mt={SpacingValue["space-x-small"]}
                                mb={SpacingValue["space-small"]}>
                                <StoreStories
                                    storeId={storeInfo.storeId}
                                    slug={storeInfo.slug}
                                    highlights={storeInfo.highlights}
                                    storyId={storyId}
                                />
                            </Box>
                        </WithCatalog>
                    </AsyncComponent>
                ) : null}
                {isStoreEnabled ? (
                    <>
                        {showCoupons && showHighlights ? (
                            <Box mb={SpacingValue["space-small"]} />
                        ) : null}
                        <ShopFrontCoupons />
                        {showCoupons || showHighlights ? (
                            <Box
                                mb={SpacingValue["space-x-large"]}
                                mh={SpacingValue["space-medium"]}>
                                <DashBorderLine
                                    borderColor={theme["color-basic-alpha-15"]}
                                    type="dashed"
                                    style={{ marginTop: SpacingValue["space-small"] }}
                                />
                            </Box>
                        ) : null}
                        <AsyncComponent fallback={CatalogLoader}>
                            <WithCatalog storeSlug={storeInfo.slug}>
                                <ShopCatalog
                                    catalogMeasureRef={catalogMeasureRef}
                                    layout={storeInfo.layoutConfig?.listing ?? "LIST"}
                                    showAddToCart={
                                        !storeInfo.userMetadata?.userExperiments?.isSfXpEnabled
                                    }
                                />
                            </WithCatalog>
                        </AsyncComponent>
                    </>
                ) : (
                    <StoreClosedContent flex={1} />
                )}
            </Surface>

            {isStoreEnabled ? <ShopFooter /> : null}

            <FocusAwareStatusBar
                translucent={true}
                backgroundColor="transparent"
                barStyle="dark-content"
            />

            {showOnboarding && isStoreEnabled ? (
                <StoreOnboardingBottomsheet onClose={setShowOnboarding} />
            ) : isEnquiryModalOpen ? null : null}
        </>
    );
};

if (__DEV__) {
    ShopView.displayName = "ShopView";
}

const styles = StyleSheet.create({
    content: {
        flex: 1,
        elevation: 0,
        paddingBottom: SpacingValue["space-medium"],
    },
});
