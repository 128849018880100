import React from "react";

import { useTheme } from "@swiggy-private/rn-dls";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Emoji } from "@swiggy-private/react-native-ui";

const EmojiComponent: React.FC = () => {
    const { value: theme } = useTheme();

    const containerSize = useSelectScreen({
        default: 48,
        md: 52,
        sm: 52,
    });

    const containerStyle = {
        borderWidth: 1,
        borderColor: theme["color-basic-15"],
        borderRadius: 12,
        width: containerSize,
        height: containerSize,
    };

    return (
        <Box style={containerStyle} justifyContent="center" alignItems="center">
            <Emoji id="sad_emoji" height={20} width={18} />
        </Box>
    );
};

export const EmojiIcon = React.memo(EmojiComponent);
