import React, { useCallback, useMemo } from "react";
import { Pressable, StyleSheet } from "react-native";
import LinearGradient from "react-native-linear-gradient";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { useGetHslFromRgb } from "@minis-consumer/hooks/use-get-hsl-from-hex";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import * as Constants from "./constants";

const PoweredByMinisComponent: React.FC<{ poweredMinisPressHandler: VoidFunction }> = ({
    poweredMinisPressHandler,
}) => {
    const { value: theme } = useTheme();
    const { settings } = useStoreInfo();
    const hasBackgroundImage = settings.backgroundImageId;

    const themeHslValue = useGetHslFromRgb();

    const gradientStyles = {
        ...styles.gradientStyle,
        borderBottomRightRadius: 24,
    };

    const getGradientColors = useCallback(() => {
        if (hasBackgroundImage) {
            return Constants.GRADIENT_COLORS_2;
        }

        return [
            `hsla(${themeHslValue[0] * 360}, 67%, 38%, 0.63)`,
            `hsla(${themeHslValue[0] * 360}, 63%, 51%, 0)`,
        ];
    }, [themeHslValue, hasBackgroundImage]);

    const textColor = useMemo(() => {
        if (hasBackgroundImage) {
            return;
        }

        return `hsla(${themeHslValue[0] * 360}, 13%, 83%, 1)`;
    }, [hasBackgroundImage]);

    const poweredByStyles = useMemo(() => {
        if (hasBackgroundImage) {
            return styles.poweredBy;
        }

        return {
            ...styles.poweredBy,
            color: textColor,
        };
    }, [textColor]);

    return (
        <Box style={styles.gradientStyle}>
            <LinearGradient
                start={Constants.GRADIENT_LOCATIONS_START}
                end={Constants.GRADIENT_LOCATIONS_END}
                colors={getGradientColors()}
                style={gradientStyles}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={SpacingValue["space-x-small"]}
                    style={styles.poweredByContainer}>
                    <Text
                        category="b3"
                        weight="medium"
                        color="color-basic-15"
                        style={poweredByStyles}>
                        {Constants.POWERED_BY}{" "}
                        <Text
                            category="b3"
                            weight="bold"
                            color="color-basic-0"
                            style={poweredByStyles}>
                            {Constants.SWIGGY_MINIS}
                        </Text>
                    </Text>
                    <Pressable
                        hitSlop={{ top: 6, left: 6, bottom: 6, right: 6 }}
                        onPress={poweredMinisPressHandler}>
                        <SvgIcon
                            icon="InfoFilled"
                            height={Constants.ALERT_HEIGHT}
                            width={Constants.ALERT_HEIGHT}
                            color={hasBackgroundImage ? theme["background_Secondary"] : textColor}
                            style={{ borderRadius: Constants.ALERT_HEIGHT }}
                        />
                    </Pressable>
                </Stack>
            </LinearGradient>
        </Box>
    );
};

export const PoweredByMinis = React.memo(PoweredByMinisComponent);

const styles = StyleSheet.create({
    gradientStyle: {
        height: 30,
        position: "absolute",
        bottom: 0,
        right: 0,
        width: "90%",
        zIndex: 2,
    },
    poweredByContainer: {
        alignSelf: "flex-end",
        paddingRight: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-x-small"],
        height: 30,
    },
    poweredByMinis: {
        alignSelf: "flex-end",
    },
    poweredBy: {
        fontStyle: "italic",
    },
});
