import React from "react";
import { Platform, Pressable, StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { NavigatorScreenParams, useNavigation } from "@react-navigation/core";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Container, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon, SvgIconType } from "@swiggy-private/connect-svg-icons";
import { InView } from "@swiggy-private/react-native-ui";

import { Card } from "@minis-consumer/components/card";
import { Divider } from "@minis-consumer/components/divider";
import { useSuspenseUser } from "@minis-consumer/hooks/use-user";
import { StoreNavigatorId } from "@minis-consumer/constants/navigation";
import { AccountRouteList, HomeRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { useMinisNavigation } from "@minis-consumer/hooks/use-minis-navigation";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Analytics } from "@minis-consumer/analytics";

import { BackgroundTheme } from "../background-theme";
import { ANALYTICS_COPIES } from "../constants";
import { HeaderLeft } from "../../components/header-left";

const isNative = Platform.OS !== "web";

const ListHeader: React.FC = () => {
    const insets = useSafeAreaInsets();
    const { value: theme } = useTheme();

    const storeInfo = useStoreInfo(false);

    const boxPaddingTop = React.useMemo(
        () => insets.top + (!isNative ? 60 : storeInfo ? 40 : 56),
        [insets.top, storeInfo],
    );

    const navigation = useNavigation<
        NativeStackNavigationProp<HomeRouteList, "MyAccount", string>
    >().getParent(StoreNavigatorId) as NativeStackNavigationProp<RouteList, "Home", string> | null;

    const minisNavigation = useMinisNavigation();

    const userData = useSuspenseUser();
    const analyticsContext = React.useMemo(() => {
        return JSON.stringify({
            customerName: userData?.name,
            customerEmail: userData?.email,
            customerMobile: userData?.mobile,
        });
    }, [userData]);

    const onImpression = React.useCallback(
        (isVisible: boolean) => {
            if (!isVisible) {
                return;
            }

            /** without context and non-conditional */
            const eventsForImpression = [
                ANALYTICS_COPIES.HELP_AND_SUPPORT_CTA,
                ANALYTICS_COPIES.ABANDONED_CART_BTN,
                ANALYTICS_COPIES.PROFILE_CARD_SAVED_ADDRESSED_CTA,
            ];

            eventsForImpression.forEach((event: string) => {
                Analytics.impressionEvent({
                    category: event,
                });
            });
            /** */

            /** with context */
            Analytics.impressionEvent({
                category: ANALYTICS_COPIES.PROFILE_CARD,
                context: analyticsContext,
            });

            /** conditional */
            isNative &&
                Analytics.impressionEvent({
                    category: ANALYTICS_COPIES.PROFILE_CARD_LOVED_MINIS_CTA,
                });
        },
        [analyticsContext],
    );

    const navigateToAccount = React.useCallback(
        (params: NavigatorScreenParams<AccountRouteList>) => {
            if (navigation != null) {
                navigation.navigate("Account", params);
            } else {
                minisNavigation?.navigate("Account", params);
            }
        },
        [minisNavigation, navigation],
    );

    const onPressSettings = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.PROFILE_CARD,
            context: analyticsContext,
        });

        navigateToAccount({ screen: "ProfileSettings" });
    }, [navigateToAccount, analyticsContext]);

    const onPressSavedAddress = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.PROFILE_CARD_SAVED_ADDRESSED_CTA,
        });

        navigateToAccount({ screen: "SavedAddresses" });
    }, [navigateToAccount]);

    const onPressMinisYouLove = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.PROFILE_CARD_LOVED_MINIS_CTA,
        });

        navigateToAccount({ screen: "FollowedStores" });
    }, [navigateToAccount]);

    const onPressHelpAndSupport = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.HELP_AND_SUPPORT_CTA,
        });

        navigateToAccount({
            screen: "Help",
            params: { screen: "HelpHome" },
        });
    }, [navigateToAccount]);

    const [containerFluid, disableContainerGutter] = useSelectScreen({
        default: [true, true],
        lg: [false, false],
    });

    return (
        <InView onChange={onImpression}>
            <Box
                ph={SpacingValue["space-medium"]}
                pt={boxPaddingTop}
                mh={-SpacingValue["space-medium"]}>
                <BackgroundTheme />
                {storeInfo ? (
                    <Box mt={-SpacingValue["space-medium"]}>
                        <HeaderLeft />
                    </Box>
                ) : null}

                <Container fluid={containerFluid} disableGutters={disableContainerGutter}>
                    <Stack
                        spacing={SpacingValue["space-medium"]}
                        style={{ marginTop: SpacingValue["space-large"] }}>
                        <Pressable onPress={onPressSettings}>
                            <Stack
                                direction="row"
                                spacing={SpacingValue["space-medium"]}
                                justifyContent="space-between"
                                alignItems="flex-start">
                                <Box collapsable={false}>
                                    <Text category="h3" color="color-basic-0">
                                        {userData?.name}
                                    </Text>
                                    <Stack direction="column" spacing={SpacingValue["space-small"]}>
                                        <Text category="b2" color="color-basic-0">
                                            {userData?.email}
                                        </Text>
                                        <Text category="b2" color="color-basic-0">
                                            {userData?.mobile}
                                        </Text>
                                    </Stack>
                                </Box>

                                <Box
                                    style={[
                                        styles.chevronIconContainer,
                                        { backgroundColor: theme["color-basic-alpha-15"] },
                                    ]}>
                                    <SvgIcon icon="ChevronRight" color="color-basic-0" />
                                </Box>
                            </Stack>
                        </Pressable>

                        <Card style={styles.card}>
                            {isNative ? (
                                <>
                                    <CardActionItem
                                        icon="HeartFilled"
                                        iconColor={theme["color-critical-300"].toString()}
                                        title="Your favourite Minis"
                                        onPress={onPressMinisYouLove}
                                    />

                                    <Divider />
                                </>
                            ) : null}
                            <CardActionItem
                                icon="LocationPinOutlineFilled"
                                iconColor={theme["color-positive-700"].toString()}
                                title="Your saved addresses"
                                onPress={onPressSavedAddress}
                            />

                            <Divider />

                            <CardActionItem
                                icon="InfoFilled"
                                iconColor={theme["color-basic-75"].toString()}
                                title="Help &amp; support"
                                onPress={onPressHelpAndSupport}
                            />
                        </Card>
                    </Stack>
                </Container>
            </Box>
        </InView>
    );
};

const CardActionItem: React.FC<{
    icon: SvgIconType["icon"];
    iconColor: string;
    title: string;
    onPress?: () => void;
}> = ({ icon, iconColor, title, onPress }) => (
    <Pressable onPress={onPress} accessibilityLabel={title}>
        <Box ph={SpacingValue["space-medium"]} pv={SpacingValue["space-large"]}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={SpacingValue["space-medium"]}>
                <Stack direction="row" alignItems="flex-end" spacing={SpacingValue["space-small"]}>
                    <SvgIcon icon={icon} color={iconColor} height={22} width={22} />

                    <Text category="b1" weight="medium" color="high">
                        {title}
                    </Text>
                </Stack>

                <SvgIcon icon="ChevronRight" color="color-basic-60" />
            </Stack>
        </Box>
    </Pressable>
);

export const ListHeaderComponent = React.memo(ListHeader);

const styles = StyleSheet.create({
    chevronIconContainer: {
        borderRadius: 8,
        overflow: "hidden",
        padding: SpacingValue["space-x-small"],
    },
    card: {
        padding: 0,
        marginBottom: SpacingValue["space-xxx-small"],
    },
});
