/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from "react";

import { createNavigator } from "@minis-consumer/helpers/navigation";
import { CommonRouteList } from "@minis-consumer/interfaces/route";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const FakeStackNavigator = () => createNavigator<CommonRouteList>();
export type CommonStackNavigator = ReturnType<typeof FakeStackNavigator>;

type CommonRoutesParams = {
    Stack: CommonStackNavigator;
    showNavbar?: boolean;
    signedIn?: boolean;
    context?: string;
};

export const CommonRoutes = ({
    Stack,
    showNavbar = false,
    signedIn = false,
    context,
}: CommonRoutesParams): JSX.Element => {
    return (
        <Stack.Group>
            <Stack.Screen
                name="ChatConversation"
                getComponent={() => require("./chat-conversation").ChatScreen}
                options={{
                    headerShown: !showNavbar,
                    headerBackVisible: true,
                }}
            />
            <Stack.Screen
                name="RatingAndReview"
                getComponent={() => require("./rating-and-review").RatingAndReviewScreen}
                options={{
                    headerShown: !showNavbar,
                    headerBackVisible: true,
                }}
            />

            <Stack.Screen
                name="Payment"
                getComponent={() => require("./payment").PaymentScreen}
                options={{ headerShown: false }}
            />
            <Stack.Screen
                name="Tracking"
                options={{ headerShown: !showNavbar }}
                getComponent={() => require("./order-track").OrderTrackingScreen}
            />
            <Stack.Screen
                name="Order"
                options={{ headerShown: !showNavbar }}
                getComponent={() => require("./order").OrderDetailScreen}
            />
            <Stack.Screen
                name="SelectReturnItems"
                options={{ headerShown: !showNavbar }}
                getComponent={() => require("./order/routes").SelectReturnItemsScreen}
            />
            <Stack.Screen
                name="SelectReturnReason"
                options={{ headerShown: !showNavbar }}
                getComponent={() => require("./order/routes").SelectReturnReasonScreen}
            />

            <Stack.Screen
                name="Product"
                getComponent={() => require("./product").ProductScreen}
                options={{ headerShown: !showNavbar }}
            />

            <Stack.Screen
                name="ProductForms"
                getComponent={() => require("./product/routes").ProductFormWrapper}
                options={{ headerShown: !showNavbar }}
            />

            {signedIn ? (
                <Stack.Group>
                    <Stack.Screen
                        name="Account"
                        getComponent={() => require("./account").AccountScreen}
                        options={{ headerShown: false }}
                    />
                    <Stack.Screen
                        name="Address"
                        getComponent={() => require("./address").AddNewAddressScreen}
                        options={{ headerShown: false }}
                    />
                </Stack.Group>
            ) : (
                <Stack.Group>
                    <Stack.Screen
                        name="GuestCheckout"
                        getComponent={() => require("./guest-checkout").GuestCheckoutScreen}
                        options={{ headerShown: !showNavbar, headerBackVisible: true }}
                    />
                    <Stack.Screen
                        name="Auth"
                        getComponent={() => require("./auth").AuthScreen}
                        options={{ headerShown: false }}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        initialParams={{ context }}
                    />
                </Stack.Group>
            )}

            <Stack.Screen name="Coupons" getComponent={() => require("./coupons").CouponScreen} />

            <Stack.Screen
                name="SlotSelection"
                getComponent={() => require("./slot-selection/wrapper").SlotSelectionWrapper}
                options={{
                    headerShown: !showNavbar,
                    headerBackVisible: true,
                }}
            />

            <Stack.Screen
                name="NotFound"
                getComponent={() => require("./notfound").NotFoundScreen}
                options={{ headerShown: !showNavbar, headerTitle: "", headerBackVisible: true }}
            />
        </Stack.Group>
    );
};
