import React, { useCallback } from "react";
import { FlatList, ListRenderItem, StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useMountedRef } from "@swiggy-private/react-hooks";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { ActivityIndicator, SpacingValue } from "@swiggy-private/rn-dls";

import { ProductSectionItem } from "@minis-consumer/components/catalog/product-section-list/section-item";
import { SearchNoResultsFound } from "@minis-consumer/components/catalog/search/no-results-found";
import { Divider } from "@minis-consumer/components/divider";
import { useFilteredProducts } from "@minis-consumer/hooks/use-filtered-products";
import { useGetProductBadges } from "@minis-consumer/hooks/use-get-badges";
import { Product } from "@minis-consumer/interfaces/catalog";
import { RouteList } from "@minis-consumer/interfaces/route";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { META_PIXEL_EVENT_NAMES } from "@minis-consumer/constants/meta-pixel-analytics";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import { CatalogSearchInput } from "../search-input";

const MINIMUM_SEARCH_LENGTH = 3;

const SearchMainComponent: React.FC = () => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const mountedRef = useMountedRef();
    const [isSearching, setIsSearching] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [filterListsFn, filteredList] = useFilteredProducts();
    const badges = useGetProductBadges();
    const storeInfo = useStoreInfo();

    const clearSearch = React.useCallback(() => setSearchText(""), [setSearchText]);

    const insets = useSafeAreaInsets();

    React.useEffect(() => {
        if (searchText.trim().length < MINIMUM_SEARCH_LENGTH) {
            filterListsFn("");
            return;
        }

        setIsSearching(true);
        filterListsFn(searchText);

        const timer = setTimeout(() => setIsSearching(false), 500);
        return () => {
            clearTimeout(timer);
        };
    }, [searchText, mountedRef, filterListsFn]);

    const onProductPress = React.useCallback(
        (productId: string) => {
            navigation.navigate("Product", {
                id: productId,
                slug: storeInfo?.slug,
            });
        },
        [navigation, storeInfo?.slug],
    );

    const renderItem: ListRenderItem<Product> = useCallback(
        ({ item, index }) => {
            return (
                <ProductSectionItem
                    item={item}
                    index={index}
                    badges={badges}
                    onProductPress={() => onProductPress(item.id)}
                />
            );
        },
        [badges, onProductPress],
    );

    const onChangeText = useCallback((text: string) => {
        const trimmedText = (text || "").trim();
        if (trimmedText) {
            fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.SEARCH, { content_name: trimmedText });
        }
        setSearchText(trimmedText);
    }, []);

    const isResultEmpty =
        !isSearching && !filteredList.length && searchText.length >= MINIMUM_SEARCH_LENGTH;

    return (
        <Box mt={SpacingValue["space-x-large"]} flex={1} style={styles.container}>
            <CatalogSearchInput
                onChangeText={onChangeText}
                searchText={searchText}
                clearSearch={clearSearch}
            />

            {isSearching && searchText.length > 0 ? (
                <Box style={styles.container} mt={SpacingValue["space-medium"]}>
                    <ActivityIndicator />
                </Box>
            ) : isResultEmpty ? (
                <SearchNoResultsFound searchText={searchText} />
            ) : (
                <FlatList
                    bounces={false}
                    showsVerticalScrollIndicator={true}
                    data={filteredList}
                    renderItem={renderItem}
                    keyExtractor={(item: Product) => item.id}
                    ItemSeparatorComponent={Divider}
                    contentContainerStyle={[
                        styles.contentContainer,
                        { paddingBottom: insets.bottom + SpacingValue["space-medium"] },
                    ]}
                />
            )}
        </Box>
    );
};

export const SearchMain = SearchMainComponent;

const styles = StyleSheet.create({
    container: {},
    contentContainer: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
});
