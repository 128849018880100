import React from "react";

import { getCardInfo } from "@minis-consumer/includes/juspay";
import { getCardLogoFromJuspayCardBrand } from "../helpers";

interface UseJustpayApiReturnValue {
    getCardLogoFromBin: (bin: string) => Promise<string>;
}

export const useJuspayApi = (): UseJustpayApiReturnValue => {
    const getCardLogoFromBin = React.useCallback(async (bin: string) => {
        try {
            const response = await getCardInfo({ cardBin: bin });
            /** Incase of API Outage, there would be no response */
            if (!response) {
                return "";
            }

            /** If response is there, but no brand - throw error */
            if (!response.brand) {
                throw new Error("Invalid card number");
            }

            return getCardLogoFromJuspayCardBrand(response.brand);
        } catch {
            throw new Error("Invalid card number");
        }
    }, []);

    return {
        getCardLogoFromBin,
    };
};
