export const LINK_COPY_SNACKBAR_CONSTANTS = {
    FALLBACK_MSSG: "Unable to copy link.",
    CTA_TEXT_FALLBACK: "X",
    MAX_SNACKBAR_WIDTH: 740,
};

export const MEETING_LINK_COPY_TEXT = "Meeting link copied!";
export const GOOGLE_MEET_ICON = "static-assets/gmeet-icon";
export const MEET_JOIN_BUTTON_TEXT = "Join";

export const ANALYTICS_COPIES = {
    CATEGORY: "post-order-join-meet",
    SCREEN: "order",
};
