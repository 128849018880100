import React from "react";
import { StyleSheet, ViewStyle, Platform } from "react-native";
import LinearGradient from "react-native-linear-gradient";

import { useTheme } from "@swiggy-private/rn-dls";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

interface Props {
    loading?: boolean;
}

const BackgroundThemeComponent: React.FC<Props> = ({ loading }) => {
    const { value: theme } = useTheme();

    const [alignItems, justifyContent] = useSelectScreen<
        [ViewStyle["alignItems"], ViewStyle["justifyContent"]]
    >({
        default: ["center", "flex-end"],
        lg: ["flex-start", "flex-end"],
    });

    const [imageWidth, imageHeight] = useSelectScreen<[number, number]>({
        default: [450, 126],
        lg: [635, 173],
    });

    return (
        <Box
            style={[
                styles.container,
                {
                    backgroundColor: loading ? theme["color-basic-30"] : theme["color-primary"],
                    alignItems,
                    justifyContent,
                },
            ]}>
            {loading ? null : (
                <LinearGradient
                    colors={[
                        theme["color-basic-0"].toString(),
                        theme["color-basic-100"].toString(),
                    ]}
                    start={{ x: -0.5, y: -0.5 }}
                    end={{ x: 1.5, y: 1.5 }}
                    style={styles.overlay}
                />
            )}
            <CdnImage
                isImageKitEnabled
                id="minis/minis_logo_translucent"
                width={imageWidth}
                height={imageHeight}
            />
        </Box>
    );
};

export const BackgroundTheme = React.memo(BackgroundThemeComponent);

const styles = StyleSheet.create({
    container: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        height: Platform.OS === "web" ? 232 : 272,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
    },
    overlay: {
        ...StyleSheet.absoluteFillObject,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
        opacity: 0.4,
    },
});
