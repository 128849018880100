import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { RadioButton, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { NetbankingPaymentMethod } from "@minis-consumer/interfaces/payment";
import {
    BANK_LOGO_MAP,
    NETBANKING_UNAVAILABLE,
    PAYMENT_GROUP_CATEGORY,
    PAYMENT_GROUP_METHOD,
} from "@minis-consumer/routes/payment/constants";
import { useSelectedPaymentMethod } from "@minis-consumer/routes/payment/hooks/use-selector";

interface BankItemProps extends NetbankingPaymentMethod {
    onPress: (bankName: string, bankCode: string) => void;
    showRadioInput?: boolean;
}

const BankItemComponent: React.FC<BankItemProps> = ({
    name,
    bankName,
    bankCode,
    onPress: onPressProp,
    disabled,
    message = NETBANKING_UNAVAILABLE,
    showRadioInput,
}) => {
    const isPaymentSelected = useSelectedPaymentMethod({
        category: PAYMENT_GROUP_CATEGORY.NEW,
        type: PAYMENT_GROUP_METHOD.NETBANKING,
        value: bankCode,
    });
    const onPress = (): void => {
        onPressProp(bankName, bankCode);
    };

    const { value: theme } = useTheme();
    const logoUrl = React.useMemo(() => (bankCode ? BANK_LOGO_MAP[bankCode] : ""), [bankCode]);

    const pressModuleStyle = React.useMemo(
        () => ({
            paddingVertical: logoUrl ? SpacingValue["space-medium"] : SpacingValue["space-x-large"],
        }),
        [logoUrl],
    );

    return (
        <Pressable onPress={onPress} style={pressModuleStyle}>
            <Stack spacing={SpacingValue["space-x-small"]}>
                <Stack spacing={SpacingValue["space-medium"]} direction="row" alignItems="center">
                    {logoUrl && showRadioInput ? (
                        <Box
                            style={[
                                styles.logoContainer,
                                {
                                    borderColor: theme["color-basic-15"],
                                },
                            ]}
                            ph={SpacingValue["space-xx-small"]}
                            pv={SpacingValue["space-xx-small"]}>
                            <CdnImage isImageKitEnabled id={logoUrl} height={30} width={30} />
                        </Box>
                    ) : null}

                    <Box flex={1}>
                        <Text category="b1" weight="medium" color="high">
                            {bankName || name}
                        </Text>
                    </Box>

                    {showRadioInput ? (
                        <RadioButton onChange={onPress} checked={isPaymentSelected} />
                    ) : (
                        <SvgIcon
                            icon="ChevronRight"
                            width={20}
                            height={20}
                            color="color-basic-30"
                        />
                    )}
                </Stack>
                {disabled && message ? (
                    <Text category="b3" color="critical">
                        {message}
                    </Text>
                ) : null}
            </Stack>
        </Pressable>
    );
};

export const BankItem = React.memo(BankItemComponent);

const styles = StyleSheet.create({
    logoContainer: {
        borderWidth: 1,
        borderRadius: 20,
    },
});
