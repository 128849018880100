import React, { memo, useRef } from "react";
import { FlatList, StyleSheet, useWindowDimensions, ViewStyle } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import {
    DEFAULT_DESKTOP_SIZE,
    DEFAULT_LIST_SIZE,
    LABELS,
} from "@minis-consumer/routes/slot-selection/constants";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";
import { convertToReadableDateAndTimeIST } from "@minis-consumer/helpers/date";
import {
    calculateItemSize,
    FormattedDate,
    SelectionListComponentProps,
    TimeSlot,
} from "@minis-consumer/routes/slot-selection/helpers";

import { SlotSelector } from "./slot-selector";

const ItemSeparatorComponent: React.FC = () => <Box style={styles.mb2} />;
const DateItemSeparatorComponent: React.FC = () => <Box style={styles.dateSeparator} />;
const EmptyListComponent: React.FC = () => (
    <Box alignItems="center">
        <Text category="s1" weight="bold" color="text_Med_Emphasis">
            {LABELS.ALL_SLOTS_BOOKED}
        </Text>
    </Box>
);

const SelectionListComponent: React.FC<SelectionListComponentProps> = ({
    timeList,
    dates,
    times,
    selectedDate,
    selectedTime,
    onTimeChange,
    onDateChange,
}) => {
    const { value: theme } = useTheme();
    const { width: windowWidth } = useWindowDimensions();
    const isDesktop = useIsDesktop();
    const flatListRef = useRef<FlatList>(null);

    const [isScrollViewReady, setIsScrollViewReady] = React.useState(false);

    const numColumns = DEFAULT_LIST_SIZE;
    const listContainerWidth = isDesktop ? DEFAULT_DESKTOP_SIZE : windowWidth;
    const itemSize = calculateItemSize({
        listContainerWidth: listContainerWidth,
        numColumns: numColumns,
    });

    const renderTimeItem: React.FC<{ item: TimeSlot; index: number }> = React.useCallback(
        ({ item, index }) => {
            const rightMargin = (index + 1) % numColumns === 0 ? 0 : SpacingValue["space-small"];
            const extraStyles: ViewStyle = {
                width: itemSize,
                marginRight: rightMargin,
                borderColor: theme["color-basic-15"],
                ...styles.timeSlot,
            };

            const formattedTitle = convertToReadableDateAndTimeIST(item.start, "hh:mm a");

            return (
                <SlotSelector
                    item={item}
                    title={formattedTitle}
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    extraStyles={extraStyles}
                    onPress={() => onTimeChange(item)}
                />
            );
        },
        [itemSize, numColumns, onTimeChange, selectedDate, selectedTime, theme],
    );

    const renderDateItem: React.FC<{ item: FormattedDate; index: number }> = React.useCallback(
        ({ item, index }) => {
            return (
                <SlotSelector
                    item={item}
                    key={index}
                    title={item.label}
                    subTitle={item.date}
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    onPress={() => onDateChange(item)}
                />
            );
        },
        [onDateChange, selectedDate, selectedTime],
    );

    const onLayout = React.useCallback(() => {
        setIsScrollViewReady(true);
    }, []);

    React.useEffect(() => {
        if (isScrollViewReady && dates) {
            const selectedIndex = dates.findIndex((date) => date.dateEpoch === selectedDate);

            if (selectedIndex > -1 && flatListRef.current) {
                flatListRef.current?.scrollToOffset({
                    animated: true,
                    offset: selectedIndex * 80,
                });
            }
        }
    }, [isScrollViewReady, dates, selectedDate]);

    return timeList ? (
        <FlatList
            data={times}
            ListEmptyComponent={EmptyListComponent}
            renderItem={renderTimeItem}
            keyExtractor={(_, index) => index.toString()}
            numColumns={numColumns}
            ItemSeparatorComponent={ItemSeparatorComponent}
            showsVerticalScrollIndicator={isDesktop}
        />
    ) : (
        <FlatList
            onLayout={onLayout}
            ref={flatListRef}
            horizontal
            data={dates}
            renderItem={renderDateItem}
            keyExtractor={(_, index) => index.toString()}
            showsHorizontalScrollIndicator={isDesktop}
            ItemSeparatorComponent={DateItemSeparatorComponent}
        />
    );
};

const styles = StyleSheet.create({
    mb2: {
        marginBottom: SpacingValue["space-small"],
    },
    dateSeparator: {
        width: SpacingValue["space-small"],
    },
    timeSlot: {
        borderWidth: 1,
        borderRadius: 4,
        paddingVertical: SpacingValue["space-x-small"],
        paddingHorizontal: 0,
    },
});

export const SelectionList = memo(SelectionListComponent);
