import React from "react";

import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import { PaymentMethod } from "@minis-consumer/interfaces/payment";
import { ItemDivider } from "@minis-consumer/routes/payment/components/divider";
import { RECOMMENDED_PAYMENT_METHOD } from "@minis-consumer/routes/payment/constants";
import { usePaymentOrder } from "@minis-consumer/routes/payment/hooks/use-payment-order";

import { MethodCard } from "../../method-card";
import { SavedMethod } from "./methods";

interface SavedPaymentGroupProps {
    methods?: PaymentMethod[];
}

export const SavedPaymentGroup: React.FC<SavedPaymentGroupProps> = ({ methods }) => {
    const order = usePaymentOrder();

    useMount(() => {
        Analytics.impressionEvent({
            category: "saved-payment-options-card",
            label: order?.id,
        });
    });

    if (!methods || !methods.length) {
        return null;
    }

    return (
        <MethodCard title={RECOMMENDED_PAYMENT_METHOD}>
            {methods.map((paymentMethod, index) => (
                <React.Fragment key={index}>
                    {index !== 0 ? <ItemDivider /> : null}
                    <SavedMethod
                        paymentMethod={paymentMethod}
                        index={index}
                        hasError={paymentMethod.disabled}
                        errorMessage={paymentMethod.message}
                    />
                </React.Fragment>
            ))}
        </MethodCard>
    );
};
