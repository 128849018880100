import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    Platform,
    ScrollView,
    ScrollViewProps,
    VirtualizedList,
    ViewStyle,
    View,
} from "react-native";

import { useScrollToTop } from "@react-navigation/native";

import { useMount } from "@swiggy-private/react-hooks";
import {
    Box,
    Stack,
    useContainerStyle,
    useSelectScreen,
    Display,
    ScreenSize,
} from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { IOVirtualizedList } from "@swiggy-private/react-native-ui";

import { SidebarChat } from "@minis-consumer/components/sidebar-chat/index";
import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { ScrollContext } from "@minis-consumer/contexts/scroll-context";
import type {
    AboutScreenNavigationProp,
    ShopScreenNavigationProp,
} from "@minis-consumer/interfaces/route";
import { StoreDeleteErrorSnackbarProvider } from "@minis-consumer/containers/store-deleted-error-snack-provider";
import { useCartClearWithoutHardDelete } from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { RecentMinisTracker } from "@minis-consumer/includes/recent-minis";
import { ICatalogSectionsPos } from "@minis-consumer/interfaces/catalog";
import { useShowTimeBasedTooltip } from "@minis-consumer/hooks/use-time-based-tooltip";
import { useMinisNavigationGetRoute } from "@minis-consumer/hooks/use-minis-navigation";
import { CartTabFloating } from "@minis-consumer/components/cart-floating-tab";
import { FloatingChat } from "@minis-consumer/components/floating-chat";
import { MinisDownloadModal } from "@minis-consumer/components/download-minis-modal";

import { ShopView } from "./views/store-view";
import { Header } from "../ux2.0/components/header";
import { TOOLTIP_DEFAULT_VALUE } from "../constants";

type Props = ShopScreenNavigationProp | AboutScreenNavigationProp;

type IWrapperProps = ScrollViewProps & { scrollRef: React.RefObject<ScrollView> };

const WrapperComponent: React.FC<IWrapperProps> = ({ children, scrollRef, ...props }) => {
    const { value: theme } = useTheme();
    if (Platform.OS === "web") {
        const style = { backgroundColor: theme["color-background-secondary"] };
        return (
            <ScrollView {...props} style={style} ref={scrollRef}>
                {children}
            </ScrollView>
        );
    }

    return (
        <IOVirtualizedList
            {...props}
            getItemCount={() => 1}
            renderItem={() => <>{children}</>}
            bounces={false}
            getItem={() => 0}
            keyExtractor={(_, i) => i.toString()}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={scrollRef}
            removeClippedSubviews
        />
    );
};

const Wrapper = React.memo(WrapperComponent);

const Shop: React.FC<Props> = ({ route }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const landOnPDP = route?.params?.path?.includes("products/");
    const store = useStoreInfo();
    const { storeId, enabled } = store;
    const isStoreEnabled = enabled !== false;

    const currentRoute = useMinisNavigationGetRoute();

    const [deliveryInfoOffset, setDeliveryInfoOffset] = useState(0);

    const deleteCart = useCartClearWithoutHardDelete(storeId);
    const ShopViewRef = useRef<{ onScroll: ScrollViewProps["onScroll"] }>(null);
    const HeaderRef = useRef<{ onScroll: ScrollViewProps["onScroll"] }>(null);
    const storefrontNavBarRef = useRef<View>(null);
    const scrollPos = useRef<number>(0);
    const catalogMeasureRef = useRef<ICatalogSectionsPos>({});
    const [showPoweredMinisModal, setShowPoweredMinisModal] = React.useState(false);

    const { onDismissTooltip, isTooltipVisible, onMountTooltip } = useShowTimeBasedTooltip({
        key: TOOLTIP_DEFAULT_VALUE.KEY,
        value: showTooltip,
        startTime: TOOLTIP_DEFAULT_VALUE.START_TIME,
        dismissAfter: TOOLTIP_DEFAULT_VALUE.DISMISS_AFTER,
        lifetimeDisplayCount: TOOLTIP_DEFAULT_VALUE.LIFETIME_DISPLAY_COUNT,
        sessionDisplayCount: TOOLTIP_DEFAULT_VALUE.SESSION_DISPLAY_COUNT,
    });

    const [navBarPos, setNavBarPos] = React.useState<{
        height: number;
        pageY: number;
    }>();

    const scrollRef = useRef<ScrollView>(null);

    const dismissTooltipOnScroll = useCallback(() => {
        onDismissTooltip();
    }, [onDismissTooltip]);

    const updateContentOffset = useCallback((e: NativeSyntheticEvent<NativeScrollEvent>) => {
        const yOffset = e.nativeEvent.contentOffset.y;
        scrollPos.current = yOffset;
    }, []);

    const updateRef = useCallback((e: NativeSyntheticEvent<NativeScrollEvent>) => {
        ShopViewRef.current?.onScroll?.(e);
        HeaderRef.current?.onScroll?.(e);
    }, []);

    const onScroll = useCallback(
        (e: NativeSyntheticEvent<NativeScrollEvent>) => {
            updateRef(e);
            updateContentOffset(e);
            dismissTooltipOnScroll();
        },
        [dismissTooltipOnScroll, updateContentOffset, updateRef],
    );

    const containerStyle = useContainerStyle({
        fluid: false,
        disableGutters: { default: true, lg: false },
    });

    const contentStyle = [
        containerStyle,
        useSelectScreen({
            lg: { paddingVertical: SpacingValue["space-medium"] },
            default: null,
        }),
    ];

    const contentContainerHeight = useSelectScreen({
        lg: 640,
        md: "100%",
        default: "100%" as ViewStyle["height"],
    });

    const contentContainerHeightStyle = {
        minHeight: Platform.OS === "web" && !isStoreEnabled ? contentContainerHeight : undefined,
    };

    const poweredMinisPressHandler = React.useCallback(() => {
        setShowPoweredMinisModal(true);
    }, []);

    const closePoweredMinisModal = React.useCallback(() => {
        setShowPoweredMinisModal(false);
    }, []);

    useScrollToTop(scrollRef);

    const setNavbarLayout = (): void => {
        if (storefrontNavBarRef.current) {
            // eslint-disable-next-line max-params
            storefrontNavBarRef.current.measure((_x, _y, _width, height, _pageX, pageY) => {
                setNavBarPos({
                    height,
                    pageY: scrollPos.current + pageY,
                });
            });
        }
    };

    useMount(() => {
        RecentMinisTracker.getInstance().add(storeId);
        onMountTooltip();
    });

    useEffect(() => {
        const val = Boolean(!landOnPDP);
        setShowTooltip(val);
    }, [landOnPDP]);

    useEffect(() => {
        if (!isStoreEnabled) {
            deleteCart();
        }
    }, [deleteCart, isStoreEnabled]);

    const scrollToCategory = (categoryId: string): void => {
        if (!catalogMeasureRef?.current) {
            return;
        }

        const measures = catalogMeasureRef.current;
        const catRef = measures[categoryId];
        if (catRef) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line max-params
            catRef.measure((_x, _y, _width, _height, _pageX, pageY) => {
                const sfNavBarOffset = pageY < 0 ? navBarPos?.height ?? 0 : 0;
                const newpos = scrollPos.current + pageY - 2 * _height - sfNavBarOffset;
                const virtualizedListRef = scrollRef as unknown as React.RefObject<
                    VirtualizedList<unknown>
                >;
                virtualizedListRef.current?.scrollToOffset({
                    offset: newpos,
                    animated: true,
                });
                measures.openCategory?.(categoryId);
            });
        }
    };
    const routeParmas = currentRoute?.params ?? route?.params;
    const [selectedViewType, setSelectedViewType] = React.useState<any>("catalog");

    return (
        <>
            <ScrollContext.Provider value={scrollRef}>
                {route.name === "Shop" && (
                    <Display lt={ScreenSize.Medium}>
                        <Header
                            isTooltipVisible={isTooltipVisible}
                            ref={HeaderRef}
                            onCategoryPress={scrollToCategory}
                            navBarPos={navBarPos}
                            deliveryInfoOffset={deliveryInfoOffset}
                            isCatalogView={selectedViewType === "catalog"}
                        />
                    </Display>
                )}

                <Wrapper
                    contentContainerStyle={[contentStyle, contentContainerHeightStyle]}
                    onScroll={onScroll}
                    scrollRef={scrollRef}
                    scrollEventThrottle={16}>
                    <Stack
                        direction="row"
                        flex={1}
                        spacing={{
                            lg: SpacingValue["space-medium"],
                            default: 0,
                        }}>
                        <Box flex={1}>
                            {route.name === "Shop" ? (
                                <ShopView
                                    ref={storefrontNavBarRef}
                                    catalogMeasureRef={catalogMeasureRef}
                                    setDeliveryInfoOffset={setDeliveryInfoOffset}
                                    setNavbarLayout={setNavbarLayout}
                                    storyId={routeParmas?.storyId}
                                    viewType={selectedViewType}
                                    setSelectedViewType={setSelectedViewType}
                                    poweredMinisPressHandler={poweredMinisPressHandler}
                                    viewTypeParam={routeParmas.viewType}
                                />
                            ) : null}
                        </Box>
                        <SidebarChat />
                    </Stack>
                </Wrapper>

                {route.name === "Shop" && <CartTabFloating />}
            </ScrollContext.Provider>

            <Display lt={ScreenSize.Medium}>
                <FloatingChat storeId={storeId} storeImage={store.settings.backgroundImageId} />
            </Display>

            <StoreDeleteErrorSnackbarProvider />

            <MinisDownloadModal
                showModal={showPoweredMinisModal}
                closeModal={closePoweredMinisModal}
            />
        </>
    );
};

export const ShopScreen: React.FC<Props> = (props) => {
    return (
        <StateContextContainer>
            <Shop {...props} />
        </StateContextContainer>
    );
};
