import * as React from "react";
import { Pressable, StyleSheet } from "react-native";

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { CdnImage, InView } from "@swiggy-private/react-native-ui";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { HOME_AVATAR_ICON } from "@minis-consumer/constants/images";
import { useAuthHandler } from "@minis-consumer/hooks/use-auth-modal-handler";
import { useShowCounter } from "@minis-consumer/hooks/use-show-counter";
import { useSignedIn, useUserInfo } from "@minis-consumer/hooks/use-user";
import { SwiggyRNModule } from "@minis-consumer/includes/swiggy-rn-module";
import { MinisRouteList } from "@minis-consumer/interfaces/route";
import { HEADER_CHIP_HEIGHT } from "@minis-consumer/routes/minis-home/constants";

export const HeaderRight: React.FC = () => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NativeStackNavigationProp<MinisRouteList>>();

    const signedIn = useSignedIn();
    const user = useUserInfo();

    const bgColor = theme["color-background-primary"];

    const [, authHandler] = useAuthHandler();

    const onAccountPress = React.useCallback(() => {
        if (signedIn) {
            Analytics.clickEvent({ category: "header-account-btn" });
            navigation.navigate("MinisMyAccount");
            return;
        }

        authHandler(true);
    }, [authHandler, navigation, signedIn]);

    const onImpressionAccount = React.useCallback((isVisible: boolean) => {
        if (isVisible) {
            Analytics.impressionEvent({ category: "header-account-btn" });
        }
    }, []);

    const userName = String(user?.name || "");
    const [firstTime, setFirstTime] = useShowCounter("minis_home", 1);

    React.useEffect(() => {
        if (firstTime) {
            setFirstTime();
            SwiggyRNModule.getInstance().forceRefreshHomeScreen();
        }
    }, [firstTime, setFirstTime]);

    return (
        <Box justifyContent="flex-end" direction="row">
            <Pressable onPress={onAccountPress}>
                <InView onChange={onImpressionAccount}>
                    <Box
                        direction="row"
                        style={[styles.profile, { backgroundColor: theme["color-basic-alpha-5"] }]}
                        justifyContent="space-between"
                        alignItems="center">
                        {!signedIn ? (
                            <CdnImage
                                isImageKitEnabled
                                id={HOME_AVATAR_ICON.id}
                                width={HOME_AVATAR_ICON.width}
                                height={HOME_AVATAR_ICON.height}
                            />
                        ) : (
                            <Box
                                justifyContent="center"
                                alignItems="center"
                                style={[styles.iconContainer, { backgroundColor: bgColor }]}>
                                <Text weight="bold" color="color-primary">
                                    {userName.length > 0 ? userName[0].toUpperCase() : "S"}
                                </Text>
                            </Box>
                        )}

                        <Box style={styles.hamburger} justifyContent="center" alignItems="center">
                            <SvgIcon icon="HamMenu" color="color-basic-75" width={16} height={16} />
                        </Box>
                    </Box>
                </InView>
            </Pressable>
        </Box>
    );
};

const styles = StyleSheet.create({
    chat: { width: HEADER_CHIP_HEIGHT, height: HEADER_CHIP_HEIGHT, borderRadius: 20 },
    profile: {
        height: HEADER_CHIP_HEIGHT,
        borderRadius: 20,
        padding: SpacingValue["space-xx-small"],
        minWidth: HEADER_CHIP_HEIGHT * 2,
    },
    hamburger: { height: HEADER_CHIP_HEIGHT, width: HEADER_CHIP_HEIGHT },
    iconContainer: {
        width: HOME_AVATAR_ICON.width,
        height: HOME_AVATAR_ICON.height,
        borderRadius: 32,
    },
    chatBadge: {
        right: 0,
        top: -SpacingValue["space-xxx-small"],
    },
});
