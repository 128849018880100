import React from "react";
import { Pressable, StyleSheet, View, ViewToken } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { InView } from "@swiggy-private/react-native-ui";
import { useDebounceFn } from "@swiggy-private/react-hooks";

import { Divider } from "@minis-consumer/components/divider";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";
import { ProductCarousel } from "@minis-consumer/components/catalog/product-carousel";
import { ProductSmallCard } from "@minis-consumer/components/catalog/product-variants/small-card";
import { useGetOtherRecommendedProducts } from "@minis-consumer/routes/product/hooks/use-get-other-recommended-products";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Product } from "@minis-consumer/interfaces/catalog";
import { Analytics } from "@minis-consumer/analytics";
import { ARD_EVENTS } from "@minis-consumer/routes/product/constants";

import { CARD_PROPS, DEBOUNCE_TIME, TEST_IDS, TITLE } from "./constants";

interface OtherRecommendedProductsProps {
    onProductPress: (p: string, i: number) => void;
    productIds: string[];
    storeId: string;
}

const OtherRecommendedProductsComponent: React.FC<OtherRecommendedProductsProps> = ({
    productIds,
    storeId,
    onProductPress,
}) => {
    const [isFLVisible, setIsFLVisible] = React.useState(false);
    const [viewableCards, setViewableCards] = React.useState<string[]>([]);

    const storeInfo = useStoreInfo();

    const isDesktop = useIsDesktop();

    const analyticsContextData = React.useMemo(
        () =>
            JSON.stringify({
                storeId,
                productIds,
            }),
        [storeId, productIds],
    );

    const [gutter] = useSelectScreen({
        lg: [SpacingValue["space-x-large"], "h3"] as const,
        md: [SpacingValue["space-small"], "h5"] as const,
        default: [SpacingValue["space-small"], "h4"] as const,
    });

    const dividerStyle = {
        marginTop: SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-large"],
    };

    const [showRecommendedProducts, setShowRecommendedProducts] = React.useState(true);

    const toggleDescription = React.useCallback(() => {
        if (isDesktop) {
            return;
        }

        Analytics.clickEvent({
            category: ARD_EVENTS.RECOMMENDED_PRODUCTS,
            context: analyticsContextData,
        });

        setShowRecommendedProducts(!showRecommendedProducts);
    }, [showRecommendedProducts, isDesktop, analyticsContextData]);

    const separator = React.useMemo(() => <View style={{ width: gutter }} />, [gutter]);

    const products = useGetOtherRecommendedProducts(productIds ?? [], storeInfo.slug);

    const updateViewableItems = useDebounceFn(
        (viewableItems: ViewToken[]) => {
            setViewableCards(viewableItems.map((i) => i.item.id));
        },
        { wait: DEBOUNCE_TIME },
    );

    const handleOnViewableItemsChanged = React.useCallback(
        ({ viewableItems }: { viewableItems: ViewToken[] }) => {
            if (!viewableItems?.length) {
                return;
            }

            updateViewableItems(viewableItems);
        },
        // disabled as onViewableItemsChanged can't be changed on fly
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const renderItem = React.useCallback(
        ({ item, index }: { item: Product; index: number }): JSX.Element => {
            return (
                <ProductSmallCard
                    product={item}
                    index={index}
                    onProductPress={() => onProductPress(item.id, index)}
                    showRecommendedBadge={false}
                    imageSize={CARD_PROPS.IMAGE_SIZE}
                    productNameProps={CARD_PROPS.NAME_PROPS}
                />
            );
        },
        [onProductPress],
    );

    const onImpression = React.useCallback(
        (isVisible: boolean) => {
            // * inform inner horizontal cards about if entire widget is visible
            setIsFLVisible(isVisible);

            if (!isVisible) {
                return;
            }

            Analytics.impressionEvent({
                category: ARD_EVENTS.RECOMMENDED_PRODUCTS,
                context: analyticsContextData,
            });
        },
        [analyticsContextData],
    );

    const getContextData = React.useCallback(
        (cardId: string) => {
            return JSON.stringify({
                productId: cardId,
                storeId,
            });
        },
        [storeId],
    );

    React.useEffect(() => {
        if (!isFLVisible) {
            return;
        }

        viewableCards.forEach((cardId) => {
            Analytics.impressionEvent({
                category: ARD_EVENTS.RECOMMENDED_PRODUCT_CARD,
                context: getContextData(cardId),
            });
        });
    }, [viewableCards, isFLVisible, getContextData]);

    if (!productIds?.length) {
        return null;
    }

    return (
        <InView onChange={onImpression} testID={TEST_IDS.CONTAINER}>
            <Pressable onPress={toggleDescription} hitSlop={16}>
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                    style={styles.headerContainer}>
                    <Text
                        category="subheader_S1_Bold"
                        weight="bold"
                        color="high"
                        testID={TEST_IDS.TITLE}>
                        {TITLE}
                    </Text>

                    {!isDesktop ? (
                        <Box style={styles.caret}>
                            <SvgIcon
                                icon={showRecommendedProducts ? "ChevronUp" : "ChevronDown"}
                                color="color-basic-75"
                                width={20}
                            />
                        </Box>
                    ) : null}
                </Stack>
            </Pressable>

            {showRecommendedProducts ? (
                <ProductCarousel
                    sfWidget={TITLE}
                    renderItem={renderItem}
                    data={products}
                    style={[styles.carousel, { marginHorizontal: -gutter, marginTop: gutter }]}
                    ListHeaderComponent={separator}
                    ListFooterComponent={separator}
                    initialNumToRender={2}
                    isDraggable={!isDesktop}
                    imageSize={CARD_PROPS.IMAGE_SIZE}
                    testID={TEST_IDS.CAROUSEL_COMPONENT}
                    viewabilityConfig={{
                        minimumViewTime: 500,
                        viewAreaCoveragePercentThreshold: 100,
                    }}
                    onViewableItemsChanged={handleOnViewableItemsChanged}
                />
            ) : null}

            {!isDesktop ? <Divider style={dividerStyle} /> : null}
        </InView>
    );
};

export const OtherRecommendedProducts = React.memo(OtherRecommendedProductsComponent);

const styles = StyleSheet.create({
    caret: {
        width: 24,
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    headerContainer: {
        flex: 1,
    },
    carousel: {
        paddingBottom: SpacingValue["space-medium"],
    },
});
