import * as React from "react";
import { StyleSheet } from "react-native";

import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

import { TrackingMeta } from "@minis-consumer/hooks/use-order-tracking";
import { OrderStatus } from "@minis-consumer/interfaces/order";
import { TrackingIndicator } from "@minis-consumer/components/tracking-indicator";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { Divider } from "@minis-consumer/components/divider";
import { isRTOdOrder } from "@minis-consumer/routes/order/helpers";

interface StatusDescriptionProps {
    status: OrderStatus;
    header?: TrackingMeta["header"];
    body?: TrackingMeta["body"];
    cta?: React.ReactNode;
}

const StatusDescription: React.FC<StatusDescriptionProps> = ({ header, body, status, cta }) => {
    const { value: theme } = useTheme();
    const openUrl = useLinking();

    const titleCategory: TextProps["category"] = useSelectScreen({
        lg: "h4",
        default: "b1",
    });

    const descriptionCategory: TextProps["category"] = useSelectScreen({
        lg: "s3",
        default: "b2",
    });

    const isRTO = isRTOdOrder(status);

    return (
        <Stack
            flexGrow={1}
            flexShrink={1}
            spacing={{
                lg: SpacingValue["space-x-small"],
                default: SpacingValue["space-xx-small"],
            }}
            divider={
                body && header?.subTitle ? (
                    <DashBorderLine
                        borderColor={theme["color-basic-15"]}
                        style={styles.dashedLine}
                    />
                ) : isRTO ? (
                    <Divider style={styles.divider} />
                ) : null
            }>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={SpacingValue["space-x-small"]}>
                    <TrackingIndicator trackStatus={status} screen="Details" />

                    <Stack>
                        <Text color="high" category={titleCategory} weight="bold" numberOfLines={1}>
                            {header?.title || ""}
                        </Text>

                        {header?.subTitle ? (
                            <Text category={descriptionCategory} color="color-basic-60">
                                {header.subTitle}
                            </Text>
                        ) : null}
                    </Stack>
                </Stack>

                {cta ? cta : null}
            </Stack>

            {body ? (
                <Stack>
                    <Text category={descriptionCategory} color="color-basic-60">
                        {body.description || ""}
                    </Text>

                    {body.trackUrl ? (
                        <Text
                            suppressHighlighting
                            category={descriptionCategory}
                            color="color-highlight-400"
                            onPress={() => openUrl(body.trackUrl || "")}>
                            {body.trackUrl}
                        </Text>
                    ) : null}

                    {body.awb ? (
                        <Text
                            category={descriptionCategory}
                            color="color-basic-60"
                            weight="medium"
                            selectable>
                            {body.awb}
                        </Text>
                    ) : null}

                    {body.mobile ? (
                        <Stack direction={"row"}>
                            <Text
                                suppressHighlighting
                                category={descriptionCategory}
                                color="color-highlight-400"
                                onPress={() => openUrl(`tel:${body.mobile}`)}>
                                {body.mobile}
                            </Text>
                            <Text category={descriptionCategory} color="color-basic-60" selectable>
                                {`${body.agentName ? ` (${body.agentName})` : ""}`}
                            </Text>
                        </Stack>
                    ) : null}
                </Stack>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    dashedLine: {
        marginTop: 10,
        marginBottom: SpacingValue["space-small"],
    },
    trackingIndicator: {
        height: 16,
        width: 16,
        borderRadius: 50,
    },
    divider: {
        marginVertical: SpacingValue["space-small"],
    },
});

export default React.memo(StatusDescription);
