import React from "react";
import { StyleSheet, useWindowDimensions, ViewStyle } from "react-native";
import { CdnImageBackground } from "@swiggy-private/react-native-ui";
import { Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import {
    Box,
    Display,
    ScreenSize,
    Stack,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { Cart } from "@minis-consumer/interfaces/cart";
import { NAVBAR_HEIGHT } from "@minis-consumer/constants";
import { CART_GENERIC_ERROR_BACKGROUND_ID } from "@minis-consumer/constants/images";

import { isCartErrorUnknown, isCartOrStoreNotFound, isStoreConfigNotFound } from "../../helpers";
import { ErrorProps } from "./types";

interface GenericErrorProps {
    cartStatus: Cart["cartStatus"];
}

const GenericErrorComponent: React.FC<GenericErrorProps> = ({ cartStatus }) => {
    const { height: windowHeight } = useWindowDimensions();

    const errorData = React.useMemo((): ErrorProps | null => {
        if (isCartOrStoreNotFound(cartStatus)) {
            return {
                title: "Whooops!",
                subTitle:
                    "The Ministore/Cart that you are looking for might be temporarily unavailable or has been removed.",
                ctaText: "Explore other Minis",
            };
        }
        if (isStoreConfigNotFound(cartStatus)) {
            return {
                title: "Hmm..Can't reach the store",
                subTitle: "Please try again later",
            };
        }
        if (isCartErrorUnknown(cartStatus)) {
            return {
                title: "Something's not right",
                subTitle:
                    "Looks like there's an error finding the cart. Please check the link or try reaching the seller directly. ",
            };
        }
        return {
            title: "Whooops!",
            subTitle:
                "The Ministore/Cart that you are looking for might be temporarily unavailable or has been removed.",
            ctaText: "Explore other Minis",
        };
    }, [cartStatus]);

    // TODO: change height logic
    const containerHeight = useSelectScreen<ViewStyle["height"]>({
        lg: windowHeight / 2 - NAVBAR_HEIGHT,
        md: windowHeight / 2 - NAVBAR_HEIGHT,
        default: "100%",
    });

    const containerWidth = useSelectScreen<ViewStyle["height"]>({
        lg: 300,
        default: "100%",
    });

    const containerStyle = {
        width: containerWidth,
        height: containerHeight,
    };

    const containerFlex = useSelectScreen<ViewStyle["justifyContent"]>({
        lg: "center",
        default: "flex-end",
    });

    if (!errorData) {
        return null;
    }

    return (
        <Box style={[styles.container, containerStyle]} flex={1} justifyContent={containerFlex}>
            <Display lt={ScreenSize.Medium}>
                <CdnImageBackground
                    isImageKitEnabled
                    id={CART_GENERIC_ERROR_BACKGROUND_ID}
                    resizeMode="stretch"
                    style={styles.imageBackground}>
                    <ErrorMessageComponent data={errorData} />
                </CdnImageBackground>
            </Display>

            <Display gt={ScreenSize.Large}>
                <ErrorMessageComponent data={errorData} />
            </Display>
        </Box>
    );
};

interface ErrorMessageComponentProps {
    data: ErrorProps;
}

const ErrorMessageComponent: React.FC<ErrorMessageComponentProps> = ({ data }) => {
    const { value: theme } = useTheme();

    const btnStyle = React.useMemo(() => {
        return {
            backgroundColor: theme["color-basic-100"],
            marginTop: SpacingValue["space-xx-large"],
        };
    }, [theme]);

    const btnTextStyle = React.useMemo(() => {
        return {
            color: theme["color-basic-0"],
        };
    }, [theme]);

    return (
        <Stack alignItems="center" style={styles.content}>
            <Stack
                spacing={SpacingValue["space-x-small"]}
                alignItems="center"
                justifyContent="center">
                <Text category="h3" color="highest" style={styles.textCenter}>
                    {data.title}
                </Text>
                <Text category="b2" weight="medium" style={styles.textCenter}>
                    {data.subTitle}
                </Text>
            </Stack>
            {data.ctaText ? (
                <Button
                    color="tertiary"
                    style={btnStyle}
                    textStyle={btnTextStyle}
                    size="medium"
                    elevation={0}>
                    {data.ctaText}
                </Button>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: {
        alignSelf: "center",
    },
    imageBackground: {
        flexGrow: 0.5,
        justifyContent: "center",
    },
    content: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
    textCenter: {
        textAlign: "center",
    },
    scrollStyle: {
        paddingTop: SpacingValue["space-medium"],
    },
});

export const GenericError = React.memo(GenericErrorComponent);
