import React from "react";
import { StyleSheet, useWindowDimensions } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Text, SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { MinisCloudinaryContext } from "@minis-consumer/contexts/minis-cloudinary-context";

import { DISPLAY_COPIES } from "../../constants";

const ICON_SIZE = 22;

export const GuestCheckoutLoadingScreen: React.FC = () => {
    const { width: windowWidth } = useWindowDimensions();
    const { value: theme } = useTheme();

    const minisLogoWidth = windowWidth * 0.272;
    const minisLogoHeight = minisLogoWidth * 0.44;

    return (
        <Box flex={1} justifyContent="center" alignItems="center">
            <SvgIcon
                icon="LockFilled"
                color={theme["color-positive"]}
                width={ICON_SIZE}
                height={ICON_SIZE}
            />
            <Text color="low" category="b3" weight="medium" style={styles.textStyle}>
                {DISPLAY_COPIES.SECURE_CHECKOUT_TITLE}
            </Text>
            <MinisCloudinaryContext>
                <CdnImage
                    isImageKitEnabled
                    id="app-assets/minis_on_swiggy"
                    width={minisLogoWidth}
                    height={minisLogoHeight}
                />
            </MinisCloudinaryContext>
        </Box>
    );
};

const styles = StyleSheet.create({
    textStyle: {
        marginTop: SpacingValue["space-small"],
        marginBottom: SpacingValue["space-x-large"],
    },
});
