import React from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

export interface StatusProgressProps {
    type: "success" | "warning" | "danger";
    /** Percentage filled. Ranged between 0-1 */
    fillWidth: number;
    style?: StyleProp<ViewStyle>;
}

const StatusProgressComponent: React.FC<StatusProgressProps> = ({
    style: styleProp,
    type,
    fillWidth: fillWidthProp,
}) => {
    const { value: theme } = useTheme();

    const fillWidth = Math.min(fillWidthProp, 1) * 100;
    const fillColor =
        type === "success"
            ? theme["color-positive-200"]
            : type === "warning"
            ? theme["color-warning-300"]
            : theme["color-critical-400"];

    const indicatorContainerStyles = {
        backgroundColor:
            type === "success" ? "rgba(110, 184, 136, 0.2)" : "rgba(252, 205, 22, 0.2)",
    };

    const indicatorStyles = {
        backgroundColor:
            type === "success" ? theme["color-positive-400"] : theme["color-warning-500"],
    };

    return (
        <Box style={styles.container}>
            <Box style={[styles.backFill, { backgroundColor: theme["color-basic-15"] }, styleProp]}>
                <Box style={[styles.fill, { backgroundColor: fillColor, width: `${fillWidth}%` }]}>
                    {type === "danger" ? (
                        <SvgIcon
                            style={[
                                styles.alertIndicator,
                                { backgroundColor: theme["color-background-primary"] },
                            ]}
                            icon="AlertCircle"
                            color="color-critical-500"
                            height={12}
                            width={12}
                        />
                    ) : (
                        <Box style={[styles.indicatorContainer, indicatorContainerStyles]}>
                            <Box style={[styles.indicator, indicatorStyles]} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export const StatusProgress = React.memo(StatusProgressComponent);

const styles = StyleSheet.create({
    container: {
        paddingVertical: SpacingValue["space-xxx-small"],
    },
    backFill: {
        height: 4,
        borderRadius: 16,
    },
    fill: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        borderRadius: 16,
    },
    indicatorContainer: {
        padding: SpacingValue["space-xxx-small"],
        borderRadius: 4,
        position: "absolute",
        top: -3,
        right: -3,
    },
    indicator: {
        height: 6,
        width: 6,
        borderRadius: 3,
    },
    alertIndicator: {
        position: "absolute",
        right: -6,
        top: -4,
    },
});
