import React from "react";
import { FlatList, ListRenderItem, Pressable, StyleSheet } from "react-native";

import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Divider } from "@minis-consumer/components/divider";
import { Coupon } from "@minis-consumer/interfaces/coupon";
import { Analytics } from "@minis-consumer/analytics";

import { CouponCard } from "../coupon-card";

const COUPONS_HEADER = "Coupons & discounts";
const COUPONS_SUBHEADER_PREFIX = "Special discounts from";

const ItemSeparatorComponent: React.FC = () => {
    const separatorStyle = useSelectScreen({
        lg: {
            marginTop: SpacingValue["space-x-small"],
        },
        default: {
            marginLeft: SpacingValue["space-medium"],
        },
    });

    return <Box style={separatorStyle} />;
};

const CouponsComponent: React.FC<{ productId: string }> = ({ productId }) => {
    const store = useStoreInfo();

    const [showCoupons, setShowCoupons] = React.useState(true);

    const dividerStyle = {
        marginTop: SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-large"],
    };

    const horizontal = useSelectScreen({
        lg: false,
        default: true,
    });

    /** Coupon card render */
    const renderItem: ListRenderItem<Coupon> = React.useCallback(
        ({ item }) => {
            return (
                <>
                    <SvgIcon
                        icon="GlitterStars"
                        style={styles.star}
                        height={14}
                        width={14}
                        color="color-critical-300"
                    />
                    <CouponCard coupon={item} productId={productId} />
                </>
            );
        },
        [productId],
    );

    const toggleCouponsView = React.useCallback(() => {
        setShowCoupons(!showCoupons);
    }, [showCoupons]);

    const analyticsEventData = React.useMemo(() => {
        return {
            category: "coupon-dropdown-btn",
            label: `itemId: ${productId}, storeId: ${store.storeId}`,
        };
    }, [productId, store.storeId]);

    const onPressChevron = React.useCallback(() => {
        Analytics.clickEvent(analyticsEventData);

        toggleCouponsView();
    }, [analyticsEventData, toggleCouponsView]);

    useMount(() => {
        analyticsEventData && Analytics.impressionEvent(analyticsEventData);
    });

    if (!store.coupons?.length) {
        return null;
    }

    return (
        <Box>
            <Pressable onPress={onPressChevron} hitSlop={16}>
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                    style={styles.headerContainer}>
                    <Stack flex={1} spacing={SpacingValue["space-xx-small"]}>
                        <Text category="subheader_S1_Bold" weight="bold" color="high">
                            {COUPONS_HEADER}
                        </Text>
                        <Text category="b2" color="high">
                            {`${COUPONS_SUBHEADER_PREFIX} ${store.name}`}
                        </Text>
                    </Stack>

                    <Box style={styles.caret}>
                        <SvgIcon
                            icon={showCoupons ? "ChevronUp" : "ChevronDown"}
                            color="color-basic-75"
                            width={20}
                        />
                    </Box>
                </Stack>
            </Pressable>

            {showCoupons ? (
                <Box style={styles.details}>
                    <FlatList
                        horizontal={horizontal}
                        bounces={false}
                        data={store.coupons}
                        renderItem={renderItem}
                        keyExtractor={(item: Coupon) => item.code}
                        showsHorizontalScrollIndicator={false}
                        ItemSeparatorComponent={ItemSeparatorComponent}
                        contentContainerStyle={styles.contentContainerStyle}
                    />
                </Box>
            ) : null}

            <Divider style={dividerStyle} />
        </Box>
    );
};

const styles = StyleSheet.create({
    star: {
        position: "absolute",
        top: -2,
        left: 4,
        zIndex: 2,
    },
    caret: {
        width: 24,
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    headerContainer: {
        flex: 1,
    },
    contentContainerStyle: {
        paddingTop: SpacingValue["space-xxx-small"],
    },
    details: {
        marginTop: SpacingValue["space-medium"],
    },
});

export const Coupons = React.memo(CouponsComponent);
