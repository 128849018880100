import React, { useContext, useMemo } from "react";

import { InView } from "@swiggy-private/react-native-ui";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { SelectedAddressInfoContext } from "@minis-consumer/contexts/selected-address-context";
import { useGetDeliveryCharges, useGetDeliveryInfoEta } from "@minis-consumer/hooks/use-shop";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { IDeliveryInfo } from "@minis-consumer/interfaces/delivery";
import {
    ANALYTICS_COPIES,
    API_FETCHING_ERROR,
    COPIES,
} from "@minis-consumer/routes/shop/components/delivery-info/constants";

import { IconTextComponent } from "../../../icon-text";

export interface Props {
    productId: string;
    loading: boolean;
    deliveryInfo?: IDeliveryInfo;
    postalCode?: string | null;
}

export const DeliveryInformation: React.FC<Props> = React.memo(
    ({ postalCode, deliveryInfo, productId, loading }) => {
        const { storeId, name: storeName } = useStoreInfo();

        const selectedAddressCtx = useContext(SelectedAddressInfoContext);

        const dropPincodeIsAvailable = !!(
            selectedAddressCtx?.selectedAddressInfo?.postalCode || postalCode
        );
        const deliveryChargesText = useGetDeliveryCharges(dropPincodeIsAvailable, deliveryInfo);
        const deliveryEtaText = useGetDeliveryInfoEta(dropPincodeIsAvailable, deliveryInfo);
        const shouldShowDeliveredBy =
            deliveryInfo?.serviceabilityType !== "UNSERVICEABLE" &&
            (!deliveryEtaText?.text ||
                deliveryEtaText.text.indexOf(COPIES.DIRECT_DELIVERED) === -1);

        const deliveryCharges = useMemo(() => {
            if (!deliveryChargesText?.text) {
                return null;
            }

            const splitText = deliveryChargesText?.text.split("₹");

            return {
                text: splitText.length > 1 ? splitText[0] : deliveryChargesText?.text,
                highlightedText: splitText.length > 1 ? "₹" + splitText[1] : "",
            };
        }, [deliveryChargesText?.text]);

        const onImpressionDeliveryInfo = React.useCallback(
            (isVisible: boolean) => {
                if (isVisible && !loading) {
                    Analytics.impressionEvent({
                        category: ANALYTICS_COPIES.DEL_INFO,
                        context: JSON.stringify({
                            store_id: storeId,
                            product_id: productId,
                            del_estimate_msg: deliveryEtaText?.text,
                            del_charge_msg: deliveryChargesText?.text,
                            del_time: deliveryInfo?.deliveryEta ? 1 : 0,
                        }),
                    });
                }
            },
            [
                deliveryChargesText?.text,
                deliveryEtaText?.text,
                deliveryInfo?.deliveryEta,
                loading,
                productId,
                storeId,
            ],
        );

        return (
            <Stack>
                {!deliveryInfo?.error ? (
                    <InView onChange={onImpressionDeliveryInfo}>
                        <Stack>
                            <Stack>
                                {deliveryEtaText ? (
                                    <IconTextComponent
                                        testID="delivery-eta"
                                        iconName="Timer"
                                        text={
                                            deliveryInfo?.deliveryEta?.max
                                                ? "Estimated delivery in"
                                                : deliveryEtaText?.text
                                        }
                                        highligtedText={
                                            deliveryInfo?.deliveryEta?.max
                                                ? ` ${deliveryEtaText?.text}`
                                                : null
                                        }
                                        textColor={deliveryEtaText?.color}
                                        iconSize={12}
                                    />
                                ) : null}
                                {deliveryChargesText?.text ? (
                                    <IconTextComponent
                                        testID="delivery-charges"
                                        iconName="Star"
                                        text={deliveryCharges?.text ?? ""}
                                        highligtedText={deliveryCharges?.highlightedText ?? ""}
                                        iconSize={12}
                                    />
                                ) : null}

                                {shouldShowDeliveredBy ? (
                                    <IconTextComponent
                                        testID="delivery-by"
                                        iconName="DoubleTick"
                                        text={`Direct Delivery by ${storeName}`}
                                        iconSize={16}
                                        spacing={SpacingValue["space-xx-small"]}
                                    />
                                ) : null}
                            </Stack>
                        </Stack>
                    </InView>
                ) : (
                    <Text>{API_FETCHING_ERROR}</Text>
                )}
            </Stack>
        );
    },
);

if (__DEV__) {
    DeliveryInformation.displayName = "DeliveryInformation";
}
