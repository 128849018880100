import React from "react";

import { CartBill } from "@minis-consumer/interfaces/cart";
import { ChargesTooltipContent } from "../../../common/charges-tooltip-content";
import { getDeliveryFeeTooltipCharge } from "../../helper";

interface Props {
    deliveryFee: NonNullable<CartBill["deliveryFee"]>;
}

const DeliveryFeeTooltipComponent: React.FC<Props> = ({ deliveryFee }) => {
    const tooltipCharges = React.useMemo(
        () => getDeliveryFeeTooltipCharge(deliveryFee),
        [deliveryFee],
    );

    if (!deliveryFee) {
        return null;
    }

    return <ChargesTooltipContent title="Delivery fee breakup" charges={tooltipCharges} />;
};

export const DeliveryFeeTooltip = React.memo(DeliveryFeeTooltipComponent);
