import { useCallback, useRef, useState } from "react";
import { Linking, Platform } from "react-native";

import { NavigationProp, useNavigation } from "@react-navigation/native";

import { delay } from "@swiggy-private/common-helpers";
import { API_FAILURE_STORE_DELETED_CODE } from "@swiggy-private/connect-api-core";
import { useMountedRef } from "@swiggy-private/react-hooks";
import { getErrorDescription } from "@swiggy-private/react-native-ui";

import { Analytics } from "@minis-consumer/analytics";
import {
    makePaymentApi,
    MakePaymentApiParams,
    MakePaymentUpiMode,
} from "@minis-consumer/api/payment";
import { useCartClearWithoutHardDelete } from "@minis-consumer/hooks/use-cart";
import { useIsMobileWeb } from "@minis-consumer/hooks/use-mweb";
import { useStoreDeletedErrorSnackbarHandler } from "@minis-consumer/hooks/use-store-deleted-snack-handler";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { cashfreeOrderPayApi, CashfreeOrderPayParams } from "@minis-consumer/includes/cashfree";
import { Logger } from "@minis-consumer/includes/logger";
import { Order } from "@minis-consumer/interfaces/order";
import { PaymentTransaction } from "@minis-consumer/interfaces/payment";
import {
    PaymentNavigationProp,
    PaymentRouteList,
    RouteList,
} from "@minis-consumer/interfaces/route";
import { openUPIIntent } from "@minis-consumer/includes/upi-apps-list";
import { useCreateOrder } from "@minis-consumer/routes/payment/hooks/use-create-order";

import { APP_NOT_INSTALLED, APP_REDIRECTION_TOAST } from "../constants";
import { getPaymentRedirectUrl } from "../helpers";
import { usePaymentDispatch } from "./use-dispatch";
import { useNavigateToOrderWithReset } from "./use-navigate-order";
import { usePaymentOrder } from "./use-payment-order";
import { usePaymentStoreId } from "./use-payment-store";
import { usePaymentSelector } from "./use-selector";

export class PaymentError extends Error {
    private _order: Order;

    constructor(message: string, order: Order) {
        super(message);
        this._order = order;
    }

    get order(): Order {
        return this._order;
    }
}

export type TOldCard = {
    type: "SAVED";
    cvv: string;
    cardId: string;
};

export type TNewCard = {
    type: "NEW";
    cvv: string;
    cardNumber: string;
    cardHolderName: string;
    cardExpiryMM: string;
    cardExpiryYY: string;
};

export type MakePaymentParams = {
    paymentDetails: MakePaymentApiParams["paymentDetails"];
    savePaymentDetails?: boolean;
    order?: Order | null;
    cardDetails?: TOldCard | TNewCard;
};

type MakePaymentResponse = {
    orderId: string;
    paymentMethod: "UPI" | "NETBANKING" | "CARD";
    paymentId: string;
    transactionId: string;
    redirectUrl?: string;
    upiApps?: Record<string, string>;
};

/**
 * A hook used to make a payment. It first creates an order and then a transaction.
 */
export const useMakePayment = (): ((
    p: MakePaymentParams,
) => Promise<MakePaymentResponse | null>) => {
    const storeId = usePaymentStoreId();
    const createOrder = useCreateOrder();
    const clearCart = useCartClearWithoutHardDelete(storeId ?? "0", false);
    const mounted = useMountedRef();
    const navigation = useNavigation<NavigationProp<RouteList>>();
    const isMweb = useIsMobileWeb();

    const [, storeDeletedSnackbarHandler] = useStoreDeletedErrorSnackbarHandler();

    return useCallback(
        async (params: MakePaymentParams) => {
            let order: Order | null = params.order || null;

            if (!order) {
                const response = await createOrder();

                if (!response.orderDetails && response.checkoutStatus === "STORE_INACTIVE") {
                    storeDeletedSnackbarHandler(true);
                    return null;
                }

                if (!response.orderDetails && response.checkoutStatus === "BILL_PRICE_INCREASED") {
                    navigation.navigate("Home", {
                        screen: "Cart",
                        params: {
                            errorMessage: response.message,
                        },
                    });
                    return null;
                }

                if (!response.orderDetails || response.checkoutStatus !== "ORDER_CREATED") {
                    throw new Error("create order error: " + response.checkoutStatus);
                }

                order = response.orderDetails;
                // ensures that cart isn't cleared for MWeb and non-CUSTOM UPI
                const isMwebAndAppPayment =
                    isMweb &&
                    params.paymentDetails.paymentMethod === "UPI" &&
                    params.paymentDetails.upiMode !== "CUSTOM";

                if (mounted.current && !isMwebAndAppPayment) {
                    clearCart();
                }
            }

            if (!mounted.current) {
                return null;
            }

            // if (order.status === "TRANSACTION_IN_PROGRESS" && order.txnId) {
            //     transaction = await confirmPaymentTransactionApi({
            //         transactionId: order.txnId,
            //     });
            // }

            const transaction: PaymentTransaction | null = await makePaymentApi({
                ...params,
                orderId: order.id,
                storeId: order.storeDetails.id,
                savePaymentDetails: params.savePaymentDetails || false,
                redirectUrl: getPaymentRedirectUrl(),
            }).catch((err) => {
                if (err._response.statusCode === API_FAILURE_STORE_DELETED_CODE) {
                    storeDeletedSnackbarHandler(true);
                    return null;
                }

                throw new PaymentError(
                    getErrorDescription(err) ||
                        "Failed to create a transaction for order: " + order?.id,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    order!,
                );
            });

            if (!mounted.current || !transaction) {
                return null;
            }

            const cashfreeResponse = await cashfreeOrderPayApi(
                getCashfreeOrderPayParams(transaction, params),
            ).catch((err) => {
                throw new PaymentError(
                    getErrorDescription(err) ||
                        "Failed to create a cashfree transaction for order: " + order?.id,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    order!,
                );
            });

            if (!mounted.current) {
                return null;
            }

            return {
                orderId: order.id,
                paymentMethod: transaction.paymentMethod,
                redirectUrl: cashfreeResponse.data.url,
                paymentId: cashfreeResponse.cf_payment_id,
                transactionId: transaction.transactionId,
                upiApps: cashfreeResponse.data.payload,
            };
        },
        [clearCart, createOrder, isMweb, mounted, navigation, storeDeletedSnackbarHandler],
    );
};

const useMakePaymentWrapper = (): [
    boolean,
    (p: MakePaymentParams) => Promise<MakePaymentResponse | null>,
] => {
    const mounted = useMountedRef();
    const makePayment = useMakePayment();

    const dispatch = usePaymentDispatch();
    const order = usePaymentOrder();
    const paymentInProgress = usePaymentSelector((state) => state.paymentInProgress ?? false);

    return [
        paymentInProgress,
        useCallback(
            async (params: MakePaymentParams) => {
                dispatch({
                    type: "UPDATE_PAYMENT_PROGRESS",
                    payload: true,
                });

                try {
                    const response = await makePayment({
                        ...params,
                        order,
                    });

                    if (mounted.current) {
                        dispatch({
                            type: "UPDATE_PAYMENT_PROGRESS",
                            payload: false,
                        });
                    }

                    return response;
                } catch (err) {
                    if (!mounted.current) {
                        return null;
                    }

                    if (err instanceof PaymentError) {
                        dispatch({
                            type: "UPDATE_PAYMENT_ORDER",
                            payload: {
                                order: err.order,
                            },
                        });
                    }

                    dispatch({
                        type: "UPDATE_PAYMENT_PROGRESS",
                        payload: false,
                    });

                    throw err;
                }
            },
            [makePayment, order, mounted, dispatch],
        ),
    ];
};

/**
 * A hook used to make a payment using UPI.
 */
export const useMakePaymentUsingUpi = (): [
    boolean,
    (
        upiMode?: MakePaymentUpiMode,
        upiId?: string,
        saveMethod?: boolean,
        packageName?: string,
    ) => Promise<void>,
] => {
    const [paymentInprogress, makePayment] = useMakePaymentWrapper();
    const navigation = useNavigation<PaymentNavigationProp<"PaymentAddNewUpi">>();
    const mounted = useMountedRef();
    const errorHandler = usePaymentErrorHandler();
    const isMweb = useIsMobileWeb();
    const checkAndOpenUPI = useOpenUPIApps();
    const dispatch = usePaymentDispatch();
    const viewerRef = useRef<void | EventListenerOrEventListenerObject>();
    const isWaitTimeLapsed = useRef(false);
    const [stalePaymentResponse, setStaleResponse] = useState<MakePaymentResponse | null>(null);
    const [toast] = useToast();

    const redirectToTimerPage = useCallback(
        (response: MakePaymentResponse, upiMode: MakePaymentUpiMode) => {
            navigation.push("PaymentProceed", {
                screen: "PaymentTimer",
                params: {
                    method: "UPI",
                    txnId: response.transactionId,
                    orderId: response.orderId,
                    paymentId: response.paymentId,
                    upiMode,
                },
            });
        },
        [navigation],
    );

    // detaches visiblity handler
    const removeVisibilityHandler = useCallback((isTimeLapsed: boolean) => {
        isWaitTimeLapsed.current = isTimeLapsed;
        document.removeEventListener(
            "visibilitychange",
            viewerRef.current as EventListenerOrEventListenerObject,
        );
    }, []);

    // register listener for visibility change
    const createVisiblityHandler = useCallback(
        (response: MakePaymentResponse, upiMode: MakePaymentUpiMode) => {
            // kept here for realtime updates
            viewerRef.current = document.addEventListener(
                "visibilitychange",
                () => {
                    if (
                        (document.hidden || document.visibilityState === "hidden") &&
                        !isWaitTimeLapsed.current
                    ) {
                        // remove visiblity listener
                        removeVisibilityHandler(true);
                        // App has opened, now redirect to UPI-timer Screen
                        redirectToTimerPage(response, upiMode);
                    }
                },
                false,
            );
        },
        [redirectToTimerPage, removeVisibilityHandler],
    );

    return [
        paymentInprogress,
        useCallback(
            async (
                upiMode?: MakePaymentUpiMode,
                upiId?: string,
                saveMethod?: boolean,
                packageName?: string,
                // eslint-disable-next-line max-params
            ) => {
                if (paymentInprogress) {
                    return;
                }

                const isMwebAndUPIPaymentApp = isMweb && upiMode !== "CUSTOM";

                try {
                    let response: MakePaymentResponse | null = stalePaymentResponse;

                    // * triggers API
                    // * if Mweb and upiMode!=CUSTOM (yes cache)
                    // * if upiMode:CUSTOM (no cache)
                    if (
                        !(
                            isMwebAndUPIPaymentApp &&
                            stalePaymentResponse?.orderId &&
                            stalePaymentResponse?.paymentId
                        ) ||
                        !upiMode
                    ) {
                        response = await makePayment({
                            savePaymentDetails: saveMethod,
                            paymentDetails: {
                                paymentMethod: "UPI",
                                upiMode: upiMode ?? "CUSTOM",
                                upiVpa: upiId,
                            },
                        });
                        if (response) {
                            // useful if the user chooses different UPI api where entire resp remains same,
                            // as we clear the cart from BE, if we re-initiate another UPI transaction
                            setStaleResponse(response);
                        }
                    }

                    if (!response || !mounted.current) {
                        return;
                    }

                    if (isMwebAndUPIPaymentApp) {
                        removeVisibilityHandler(false);

                        dispatch({
                            type: "UPDATE_PAYMENT_PROGRESS",
                            payload: true,
                        });

                        toast(APP_REDIRECTION_TOAST);

                        // register listener for visibility change
                        createVisiblityHandler(response, upiMode ?? "CUSTOM");

                        // let user read the toast
                        await delay(1000);

                        // open upi intent if available...
                        await checkAndOpenUPI(
                            (stalePaymentResponse || response) as MakePaymentResponse,
                            upiMode,
                            packageName,
                        );

                        dispatch({
                            type: "UPDATE_PAYMENT_PROGRESS",
                            payload: false,
                        });

                        // avoid waiting for too long
                        // cancel timer to avoid unnecessary redirection
                        await delay(8000);
                        removeVisibilityHandler(true);
                        // don't execute other code in case of MWeb and Payment App
                        return;
                    }

                    redirectToTimerPage(response, upiMode ?? "CUSTOM");

                    // open upi intent if available...
                    await checkAndOpenUPI(response, upiMode, packageName);
                } catch (err) {
                    errorHandler(err);
                }
            },
            [
                paymentInprogress,
                isMweb,
                removeVisibilityHandler,
                stalePaymentResponse,
                mounted,
                redirectToTimerPage,
                checkAndOpenUPI,
                makePayment,
                dispatch,
                toast,
                createVisiblityHandler,
                errorHandler,
            ],
        ),
    ];
};

/**
 * A hook used to make a payment using NETBANKING.
 */
export const useMakePaymentUsingNetBanking = (): [
    boolean,
    (bankName: string, bankCode: string) => Promise<void>,
] => {
    const [paymentInprogress, makePayment] = useMakePaymentWrapper();
    const mounted = useMountedRef();
    const errorHandler = usePaymentErrorHandler();
    const navigation = useNavigation<PaymentNavigationProp<"PaymentNetBanking">>();

    return [
        paymentInprogress,
        useCallback(
            async (bankName: string, bankCode: string) => {
                if (paymentInprogress) {
                    return;
                }

                try {
                    const response = await makePayment({
                        savePaymentDetails: true,
                        paymentDetails: {
                            paymentMethod: "NETBANKING",
                            bankName,
                            bankCode,
                        },
                    });

                    if (!response || !mounted.current || !response.redirectUrl) {
                        return;
                    }

                    const url = response.redirectUrl;

                    if (Platform.OS === "web") {
                        window.location.href = url;
                        return;
                    }

                    navigation.push("PaymentProceed", {
                        screen: "PaymentVerify",
                        params: {
                            webUrl: url,
                            swgyOrderId: response.orderId,
                            swgyTransactionId: response.transactionId,
                        },
                    });
                } catch (err) {
                    errorHandler(err);
                }
            },
            [errorHandler, makePayment, mounted, navigation, paymentInprogress],
        ),
    ];
};

/**
 * A hook used to make a payment using CARD.
 */
export const useMakePaymentUsingCard = (): [
    boolean,
    (
        cardDetails: NonNullable<MakePaymentParams["cardDetails"]>,
        saveMethod?: boolean,
    ) => Promise<void>,
] => {
    const [paymentInprogress, makePayment] = useMakePaymentWrapper();
    const mounted = useMountedRef();
    const errorHandler = usePaymentErrorHandler();
    const navigation = useNavigation<PaymentNavigationProp<keyof PaymentRouteList>>();

    return [
        paymentInprogress,
        useCallback(
            async (
                cardDetails: NonNullable<MakePaymentParams["cardDetails"]>,
                saveMethod?: boolean,
            ) => {
                if (paymentInprogress) {
                    return;
                }

                try {
                    const cardId = cardDetails.type === "SAVED" ? cardDetails.cardId : "";
                    const cardBin =
                        cardDetails.type === "NEW" ? cardDetails.cardNumber.slice(0, 6) : "";

                    const response = await makePayment({
                        savePaymentDetails: saveMethod ?? true,
                        paymentDetails: {
                            paymentMethod: "CARD",
                            cardId,
                            cardBin,
                        },
                        cardDetails,
                    });

                    if (!response || !mounted.current || !response.redirectUrl) {
                        return;
                    }

                    const url = response.redirectUrl;

                    if (Platform.OS === "web") {
                        window.location.href = url;
                        return;
                    }

                    navigation.push("PaymentProceed", {
                        screen: "PaymentVerify",
                        params: {
                            webUrl: url,
                            swgyOrderId: response.orderId,
                            swgyTransactionId: response.transactionId,
                        },
                    });
                } catch (err) {
                    errorHandler(err);
                }
            },
            [errorHandler, makePayment, mounted, navigation, paymentInprogress],
        ),
    ];
};

const usePaymentErrorHandler = (): ((err: unknown) => void) => {
    const mounted = useMountedRef();
    const navigateToOrder = useNavigateToOrderWithReset();
    const [showToast] = useToast();

    return useCallback(
        (err: unknown) => {
            if (!mounted.current) {
                return;
            }

            if (err instanceof PaymentError) {
                const order = err.order;
                navigateToOrder(order.id, true);
            } else {
                showToast(getErrorDescription(err as Error) || "Failed to make payment");
            }
        },
        [mounted, navigateToOrder, showToast],
    );
};

const getCashfreeOrderPayParams = (
    transaction: PaymentTransaction,
    { paymentDetails, savePaymentDetails, cardDetails }: MakePaymentParams,
): CashfreeOrderPayParams => {
    const { orderToken } = transaction.thirdPartyPaymentInfo ?? {};

    let paymentMethod: CashfreeOrderPayParams["payment_method"] = {};

    switch (paymentDetails.paymentMethod) {
        case "UPI":
            const channel = paymentDetails.upiVpa ? "collect" : "link";
            paymentMethod = {
                upi: {
                    channel,
                    upi_expiry_minutes: 5,
                    upi_id: paymentDetails.upiVpa ?? "",
                },
            };
            break;

        case "NETBANKING":
            paymentMethod = {
                netbanking: {
                    channel: "link",
                    netbanking_bank_code: Number(paymentDetails.bankCode),
                },
            };
            break;

        case "CARD":
            if (cardDetails) {
                paymentMethod = {
                    card:
                        cardDetails.type === "SAVED"
                            ? {
                                  channel: "link",
                                  card_cvv: cardDetails.cvv,
                                  card_alias: cardDetails.cardId,
                              }
                            : cardDetails.type === "NEW"
                            ? {
                                  channel: "link",
                                  card_cvv: cardDetails.cvv,
                                  card_number: cardDetails.cardNumber,
                                  card_holder_name: cardDetails.cardHolderName,
                                  card_expiry_mm: cardDetails.cardExpiryMM,
                                  card_expiry_yy: cardDetails.cardExpiryYY,
                              }
                            : undefined,
                };
            }

            break;
    }

    return {
        order_token: orderToken ?? "",
        save_instrument: savePaymentDetails || false,
        save_payment_method: savePaymentDetails || false,
        payment_method: paymentMethod,
    };
};

// Opens UPI Apps
const useOpenUPIApps = (): ((
    response: MakePaymentResponse,
    upiMode: MakePaymentUpiMode | undefined,
    packageName?: string,
) => Promise<boolean | void>) => {
    const [toast] = useToast();
    const isMweb = useIsMobileWeb();
    const openExternalIntentApp = openApp(isMweb);

    const checkAndOpenUPI = useCallback(
        async (
            response: MakePaymentResponse,
            upiMode: MakePaymentUpiMode | undefined,
            packageName?: string,
        ): Promise<void> => {
            try {
                // open upi intent if available...
                if (response.upiApps && Object.keys(response.upiApps).length > 0) {
                    const upiApps = response.upiApps;

                    switch (upiMode) {
                        case "BHIM":
                            openExternalIntentApp(upiApps.bhim);
                            break;
                        case "GPAY":
                            openExternalIntentApp(upiApps.gpay);
                            break;
                        case "PAYTM":
                            let paytmUrlSchema = upiApps.paytm;
                            const delims = "paytm://upi/";
                            if (paytmUrlSchema.indexOf(delims) > -1) {
                                paytmUrlSchema = "paytmmp://" + upiApps.paytm.split(delims)[1];
                            }
                            openExternalIntentApp(paytmUrlSchema);
                            break;
                        case "PHONEPE":
                            openExternalIntentApp(upiApps.phonepe);
                            break;
                        case "OTHERS": {
                            // need to prefix this with package
                            const defaultDeepLink = upiApps.default;
                            if (packageName && defaultDeepLink) {
                                openUPIIntent(defaultDeepLink, packageName).then((result) => {
                                    Logger.info("--- open payment intent ----", result);
                                });
                            }
                        }
                    }
                }
            } catch (err) {
                if (upiMode !== "CUSTOM") {
                    toast(upiMode + " " + APP_NOT_INSTALLED);
                    // don't record error here, its clear the App isn't available
                    return;
                }
                // for understanding funnel-gap
                Analytics.clickEvent({ category: "upi-app-not-opened", label: upiMode });
                Logger.recordError(err);
            }
        },
        [openExternalIntentApp, toast],
    );
    return checkAndOpenUPI;
};

// TODO : move it to appropriate place
const openApp = (isMweb: boolean): ((url: string) => void) => {
    return (url: string): void => {
        try {
            if (isMweb) {
                // Check if the device is a mobile browser.
                // Try to open the apps-intent link URL.
                setTimeout(() => (window.location.href = url), 10);
            } else {
                setTimeout(async () => await Linking.openURL(url), 100);
            }
        } catch (err) {
            throw new Error(err as string);
        }
    };
};
