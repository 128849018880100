import React from "react";
import { FlatList, ListRenderItem, StyleSheet } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { getCouponPage } from "@minis-consumer/routes/shop/helpers";

interface Props {
    pageIconSize: number;
    couponsLength: number;
    slideIndex: number;
}

const MAX_POINTERS_IN_VIEW = 3;

const ShopfrontCouponsSliderComponent: React.FC<Props> = ({
    couponsLength,
    slideIndex,
    pageIconSize,
}) => {
    const { value: theme } = useTheme();

    const pages = React.useMemo(
        () => Array(Math.min(couponsLength, MAX_POINTERS_IN_VIEW)).fill(1),
        [couponsLength],
    );
    const [page, setPage] = React.useState(0);

    const getDotStyle = React.useCallback(
        (index: number) => {
            return {
                backgroundColor: index === page ? theme["color-basic-45"] : theme["color-basic-15"],
                width: index === page ? 8 : 6,
                height: index === page ? 8 : 6,
                borderRadius: 4,
            };
        },
        [page, theme],
    );

    /** Render page dot indicator */
    const renderItem: ListRenderItem<number> = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ item, index }) => {
            const dotStyle = getDotStyle(index);

            return <Box style={dotStyle} mr={SpacingValue["space-xxx-small"]} />;
        },
        [getDotStyle],
    );

    /** Change page index */
    React.useEffect(() => {
        const pageToSet = getCouponPage(slideIndex, couponsLength);
        setPage(pageToSet);
    }, [slideIndex, couponsLength]);

    return (
        <Box style={[{ width: pageIconSize }, styles.container]}>
            <Box justifyContent="center" alignItems="center">
                <Text category="b1" weight="medium" color="medium">
                    {slideIndex + 1}/{couponsLength}
                </Text>

                <FlatList
                    horizontal
                    bounces={false}
                    data={pages}
                    renderItem={renderItem}
                    keyExtractor={(_, index) => index.toString()}
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={styles.contentContainerStyle}
                />
            </Box>
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        alignSelf: "center",
    },
    contentContainerStyle: {
        alignItems: "center",
        height: 8,
        marginTop: SpacingValue["space-xx-small"],
    },
});

export const ShopfrontCouponsSlider = React.memo(ShopfrontCouponsSliderComponent);
