/* eslint-disable react-native/no-inline-styles */
import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { CdnImage } from "@swiggy-private/react-native-ui";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Input, RadioButton, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { PAYMENT_GROUP_CATEGORY } from "@minis-consumer/routes/payment/constants";
import { usePaymentDispatch } from "@minis-consumer/routes/payment/hooks/use-dispatch";
import { useSelectedPaymentMethod } from "@minis-consumer/routes/payment/hooks/use-selector";
import { PaymentMethodGroup } from "@minis-consumer/routes/payment/types";

type TupiOrCard = Extract<PaymentMethodGroup, "UPI" | "CARD">;

interface Props {
    type: TupiOrCard;
    title: string;
    onPressCard: (T: TupiOrCard, P?: string) => void;
    paymentId: string;
    logoUrl?: string;
    methodName?: string;
    error?: boolean;
    errorMessage?: string;
    inProgress?: boolean;
}

const SavedMethodWrapperComponent: React.FC<Props> = ({
    type,
    logoUrl,
    title,
    methodName,
    onPressCard,
    error,
    paymentId,
    errorMessage = "This is facing low success rates currently.\nPlease use another method.",
}) => {
    const { value: theme } = useTheme();
    const [cvv, setCvv] = React.useState("");
    const paymentDispatch = usePaymentDispatch();
    const isPaymentSelected = useSelectedPaymentMethod({
        category: "SAVED",
        type,
        value: title,
    });

    const onPress = React.useCallback(() => {
        onPressCard(type, title);
    }, [onPressCard, title, type]);

    const onChangeCVV = React.useCallback(
        (txt: string) => {
            setCvv(txt);
            if (txt && txt?.length < 3) {
                return;
            }
            paymentDispatch({
                type: "UPDATE_SELECTED_PAYMENT_METHOD",
                payload: {
                    category: PAYMENT_GROUP_CATEGORY.SAVED,
                    type,
                    value: title,
                    cardDetails: {
                        oldCard: {
                            type: "SAVED",
                            cvv: txt,
                            cardId: paymentId,
                        },
                    },
                },
            });
        },
        [paymentDispatch, paymentId, title, type],
    );

    const containerPadding = useSelectScreen({
        default: SpacingValue["space-large"],
        lg: SpacingValue["space-x-large"],
    });

    const paymentMethodName = React.useMemo(() => type.toLowerCase(), [type]);

    return (
        <Pressable
            onPress={onPress}
            disabled={error}
            style={{ paddingHorizontal: containerPadding, paddingVertical: containerPadding }}>
            <Stack flex={1} spacing={SpacingValue["space-x-small"]}>
                <Stack
                    flex={1}
                    direction="row"
                    alignItems="center"
                    spacing={SpacingValue["space-medium"]}>
                    {logoUrl ? (
                        <Box
                            style={[
                                styles.logoContainer,
                                { borderColor: theme["color-basic-15"] },
                            ]}>
                            <CdnImage isImageKitEnabled id={logoUrl} height={36} width={36} />
                        </Box>
                    ) : (
                        <Box
                            style={[
                                styles.logoContainer,
                                {
                                    borderColor: theme["color-basic-15"],
                                    backgroundColor: theme["color-background-secondary"],
                                },
                            ]}>
                            <Text category="b2" color="medium">
                                {paymentMethodName}
                            </Text>
                        </Box>
                    )}

                    <Stack
                        spacing={SpacingValue["space-medium"]}
                        alignItems="flex-start"
                        flex={1}
                        direction="column">
                        <Stack
                            flex={1}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: "100%" }}
                            spacing={SpacingValue["space-medium"]}>
                            <Box pt={SpacingValue["space-xx-small"]}>
                                <Text category="b1" weight="medium" color="high">
                                    {title}
                                </Text>

                                {methodName ? (
                                    <Text category="b3" color="medium">
                                        {methodName}
                                    </Text>
                                ) : null}
                            </Box>

                            <Box>
                                <RadioButton onChange={onPress} checked={isPaymentSelected} />
                            </Box>
                        </Stack>

                        {isPaymentSelected && type === "CARD" ? (
                            <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                                <Input
                                    label="CVV"
                                    size="small"
                                    value={cvv}
                                    onChangeText={onChangeCVV}
                                    secureTextEntry
                                    style={styles.cvvStyle}
                                    keyboardType="number-pad"
                                />
                            </Stack>
                        ) : null}
                    </Stack>
                </Stack>

                {error && errorMessage ? (
                    <Text category="b3" color="critical">
                        {errorMessage}
                    </Text>
                ) : null}
            </Stack>
        </Pressable>
    );
};

export const SavedMethodWrapper = React.memo(SavedMethodWrapperComponent);

const styles = StyleSheet.create({
    logoContainer: {
        borderWidth: 1,
        height: 36,
        width: 36,
        borderRadius: 6,
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
    },
    unselectedRadio: {
        height: 20,
        width: 20,
        borderRadius: 10,
        borderWidth: 1,
    },
    button: {
        flex: 1,
    },
    cvvStyle: {
        maxWidth: 80,
    },
});
