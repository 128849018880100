import React from "react";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Stack, Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { MinisRouteList } from "@minis-consumer/interfaces/route";
import { Analytics } from "@minis-consumer/analytics";
import { ANALYTICS_COPIES } from "../constants";

interface Props {
    shown?: boolean;
}

export const HeaderLeft: React.FC<Props> = ({ shown }) => {
    const navigation = useNavigation<NativeStackNavigationProp<MinisRouteList, "Account">>();

    const { value: theme } = useTheme();

    const onPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.BACK_BTN,
        });

        if (navigation.canGoBack()) {
            navigation.goBack();
            return;
        }

        navigation.replace("MinisHome", { screen: "Explore" });
    }, [navigation]);

    return (
        <Stack
            direction="row"
            spacing={SpacingValue["space-medium"]}
            alignItems="center"
            style={styles.container}>
            <Box
                justifyContent="center"
                alignItems="center"
                style={[
                    styles.backContainer,
                    // eslint-disable-next-line react-native/no-inline-styles
                    {
                        backgroundColor: !shown ? theme["text_Low_Emphasis"] : "transparent",
                    },
                ]}>
                <SvgIcon
                    icon="ArrowLeft"
                    height={16}
                    width={16}
                    onPress={onPress}
                    hitSlop={{ left: 8, right: 8, top: 8, bottom: 8 }}
                    color={shown ? "color-basic-75" : "color-basic-0"}
                />
            </Box>

            {shown ? (
                <Text category="b1" weight="medium" color="color-basic-75">
                    My Account
                </Text>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: 6,
    },
    backContainer: {
        height: 40,
        width: 40,
        borderRadius: 40,
    },
});
