import * as React from "react";
import { Platform, ScrollView, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { useMount } from "@swiggy-private/react-hooks";
import { Box, Stack, useContainerStyle, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme, Provider as DlsProvider } from "@swiggy-private/rn-dls";
import { IOScrollView } from "@swiggy-private/react-native-ui";

import { AsyncComponent } from "@minis-consumer/components/async-component";
import { SidebarChat } from "@minis-consumer/components/sidebar-chat";
import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useLocalCart } from "@minis-consumer/hooks/use-cart";
import { RouteList } from "@minis-consumer/interfaces/route";
import { StoreDeleteErrorSnackbarProvider } from "@minis-consumer/containers/store-deleted-error-snack-provider";
import {
    META_PIXEL_CONTENT_NAME,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { useShowTimeBasedTooltip } from "@minis-consumer/hooks/use-time-based-tooltip";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { useApplyStoreTheme } from "@minis-consumer/hooks/use-apply-theme";
import { CartTabFloating } from "@minis-consumer/components/cart-floating-tab";

import ProductViewPlaceholder from "../placeholder";
import { StoreDeletedProductComp } from "../store-deleted";
import { TOOLTIP_DEFAULT_VALUE } from "../../constants";
import { getVariantIdFromPath } from "../../helpers";
import { ProductDataContent } from "../product-screen-data-content";

const ProductScreenContentComponent: React.FC<
    NativeStackScreenProps<RouteList, "Product"> & {
        onMountCallback?: (img: string, storeId: string) => void;
    }
> = ({ route, onMountCallback }) => {
    const scrollRef = React.useRef<ScrollView>(null);

    const storeInfo = useStoreInfo();
    const { value: theme } = useTheme();
    const cartView = useLocalCart(storeInfo.storeId);

    const variantId = route.params.variantId || getVariantIdFromPath(route.path);

    useApplyStoreTheme(storeInfo?.slug, storeInfo?.settings?.brandColor);

    const { onDismissTooltip, isTooltipVisible, onMountTooltip } = useShowTimeBasedTooltip({
        key: TOOLTIP_DEFAULT_VALUE.KEY,
        value: true,
        startTime: TOOLTIP_DEFAULT_VALUE.START_TIME_IN_MS,
        dismissAfter: TOOLTIP_DEFAULT_VALUE.DISMISS_AFTER_IN_MS,
        lifetimeDisplayCount: TOOLTIP_DEFAULT_VALUE.LIFETIME_DISPLAY_COUNT,
        sessionDisplayCount: TOOLTIP_DEFAULT_VALUE.SESSION_DISPLAY_COUNT,
    });

    const cartItemsTotal = React.useMemo(
        () => cartView.items.reduce((sum, item) => sum + item.quantity, 0) || 0,
        [cartView.items],
    );

    const scrollStyle = {
        backgroundColor:
            Platform.OS === "web"
                ? theme["color-background-secondary"]
                : theme["color-background-primary"],
    };

    const contentStyle = [
        useContainerStyle({
            fluid: false,
            disableGutters: { default: true, lg: false },
        }),
        useSelectScreen({
            lg: { paddingVertical: SpacingValue["space-medium"] },
            default: null,
        }),
    ];

    const contentOptionalStyle = React.useMemo(
        () => (cartItemsTotal > 0 ? styles.scrollContentStyle : {}),
        [cartItemsTotal],
    );

    const onScroll = React.useCallback(() => {
        onDismissTooltip();
    }, [onDismissTooltip]);

    const scrollToTop = React.useCallback(() => {
        scrollRef.current?.scrollTo({
            y: 0,
            animated: true,
        });
    }, [scrollRef]);

    useMount(() => {
        onMountTooltip();
        onMountCallback?.(storeInfo.settings.backgroundImageId ?? "", storeInfo.storeId);

        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.VIEW_CONTENT, {
            content_name: META_PIXEL_CONTENT_NAME.PRODUCT_VIEW,
        });
    });

    return (
        <DlsProvider theme={storeInfo?.slug}>
            <StateContextContainer>
                <IOScrollView
                    ref={scrollRef}
                    onScroll={onScroll}
                    style={scrollStyle}
                    contentContainerStyle={[contentStyle, contentOptionalStyle]}
                    bounces={false}>
                    <Stack
                        direction="row"
                        flex={1}
                        spacing={{
                            lg: SpacingValue["space-medium"],
                            default: 0,
                        }}>
                        {storeInfo.enabled !== false ? (
                            <AsyncComponent fallback={ProductViewPlaceholder}>
                                <Box flex={1} mb={SpacingValue["space-xx-large"] * 2}>
                                    <ProductDataContent
                                        storeSlug={storeInfo.slug}
                                        storeId={storeInfo.storeId}
                                        productId={route.params.id}
                                        variantId={variantId}
                                        isTooltipVisible={isTooltipVisible}
                                        scrollToTop={scrollToTop}
                                    />
                                </Box>
                            </AsyncComponent>
                        ) : (
                            <StoreDeletedProductComp />
                        )}

                        <SidebarChat />
                    </Stack>
                </IOScrollView>
                <CartTabFloating />

                <StoreDeleteErrorSnackbarProvider addBottomMargin addBottomPadding />
            </StateContextContainer>
        </DlsProvider>
    );
};

export const ProductScreenContent = React.memo(ProductScreenContentComponent);

const styles = StyleSheet.create({
    scrollContentStyle: { paddingBottom: SpacingValue["space-xx-large"] },
});
