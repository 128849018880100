import React, { useState, useCallback } from "react";
import { Platform } from "react-native";

import { useMount, useMountedRef } from "@swiggy-private/react-hooks";
import { Accordion } from "@swiggy-private/react-native-ui";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { Logger } from "@minis-consumer/includes/logger";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";
import { NetBankingSection } from "@minis-consumer/routes/payment/components/net-banking-method/components/dialog";
import { NewCardSection } from "@minis-consumer/routes/payment/components/new-card-method/dialog";
import {
    ACCORDION_PAYMENT_GROUP,
    analyticsAccordionMapping,
    CUSTOM_UPI_HANDLER,
    PAYMENT_GROUP_METHOD,
    PAY_BY_ANY_UPI_LABEL,
    UPI_APPS,
} from "@minis-consumer/routes/payment/constants";
import {
    filterUPIApps,
    getAvailableUpiApps,
    sortUpiAppsByPriority,
} from "@minis-consumer/routes/payment/helpers";
import { usePaymentOrder } from "@minis-consumer/routes/payment/hooks/use-payment-order";
import { TExtractedMethodGroup, UPIApps } from "@minis-consumer/routes/payment/types";
import { getAllUpiAppsInstalledOnDevice } from "@minis-consumer/includes/upi-apps-list";
import { IUPIAppsList } from "@minis-consumer/includes/swiggy-rn-module";

import { PaymentApps } from "./apps";

const MethodListComponent: React.FC = () => {
    const order = usePaymentOrder();
    const mounted = useMountedRef();
    const isDWeb = useIsDesktopWeb();
    const [accordionOpenState, setAccordionOpenState] = useState({
        group: PAYMENT_GROUP_METHOD.UPI,
        isOpen: true,
    });

    const [upiApps, setUpiApps] = useState<Array<IUPIAppsList>>([]);

    const analyticsPress = React.useCallback(
        (method: string) => {
            Analytics.clickEvent({
                category: method,
                label: order?.id,
            });
        },
        [order?.id],
    );

    const shouldTriggerAccordion = React.useCallback(
        (key: TExtractedMethodGroup): boolean =>
            accordionOpenState?.group === key && accordionOpenState?.isOpen,
        [accordionOpenState?.group, accordionOpenState?.isOpen],
    );

    const onAccordionPress = React.useCallback(
        (type: TExtractedMethodGroup, isOpening: boolean) => {
            analyticsPress(analyticsAccordionMapping[type]);
            setAccordionOpenState({ group: type, isOpen: isOpening });
        },
        [analyticsPress],
    );

    const getAvailableApps = useCallback(() => {
        getAvailableUpiApps().then((response) => {
            let apps: IUPIAppsList[];
            if (isDWeb) {
                apps = [CUSTOM_UPI_HANDLER];
            } else {
                apps = Object.keys(response)
                    .filter((availableApps) => response[availableApps as UPIApps])
                    .map((app) => {
                        const { name, intentLogoUrl, label } =
                            UPI_APPS[app as keyof typeof UPI_APPS];
                        return {
                            icon: intentLogoUrl,
                            label: label || name,
                            packageName: name,
                        };
                    }) as IUPIAppsList[];
            }

            mounted.current && setUpiApps(apps);
        });
    }, [isDWeb, mounted]);

    useMount(() => {
        Analytics.impressionEvent({
            category: "new-payment-options-card",
            label: order?.id,
        });

        if (mounted.current) {
            // for web and ios
            if (Platform.OS !== "android") {
                getAvailableApps();
            } else {
                // fr android get the upi apps from native.
                getAllUpiAppsInstalledOnDevice()
                    .then((apps) => {
                        const filteredApps = filterUPIApps(apps);
                        const sortedApps = sortUpiAppsByPriority(filteredApps);
                        setUpiApps(sortedApps);
                    })
                    .catch((err) => {
                        // in case there is some issue in bridge,
                        // fallback to previous implementation.
                        Logger.recordError(err);
                        getAvailableApps();
                    });
            }
        }
    });

    const accordionMapping = React.useMemo(
        () =>
            ACCORDION_PAYMENT_GROUP.map((group) => {
                let children: JSX.Element = <></>;
                let title = group.title;
                let transparentLogoBg = false;
                switch (group?.key) {
                    case PAYMENT_GROUP_METHOD.UPI:
                        children = <PaymentApps apps={upiApps} />;
                        title = PAY_BY_ANY_UPI_LABEL;
                        transparentLogoBg = true;
                        break;
                    case PAYMENT_GROUP_METHOD.CARD:
                        children = <NewCardSection />;
                        break;
                    case PAYMENT_GROUP_METHOD.NETBANKING:
                        children = <NetBankingSection />;
                        break;
                }
                return { ...group, children: children, title, transparentLogoBg };
            }),
        [upiApps],
    );

    return (
        <Stack direction={"column"} spacing={SpacingValue["space-medium"]} flex={1}>
            {accordionMapping.map((val, key) => (
                <React.Fragment key={key}>
                    <Accordion
                        key={key}
                        accordionTitle={val?.title}
                        onPress={(isOpen) => onAccordionPress(val?.key, isOpen)}
                        triggerOpen={shouldTriggerAccordion(val?.key)}
                        isAccordionLogoTransparent={val.transparentLogoBg}
                        accordionLogo={val?.logo}>
                        {val?.children}
                    </Accordion>
                </React.Fragment>
            ))}
        </Stack>
    );
};

export const MethodList = React.memo(MethodListComponent);
