import * as React from "react";
import { StyleSheet } from "react-native";

import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

export const HelpContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { value: theme } = useTheme();
    const bgPrimaryColor = theme["color-background-primary"];

    const containerColor = { backgroundColor: bgPrimaryColor };

    const containerStyle = useSelectScreen({
        lg: {
            marginVertical: SpacingValue["space-xx-large"],
        },
        default: {
            marginVertical: SpacingValue["space-medium"],
        },
    });

    return (
        <Box style={[containerStyle, styles.contentBorderRadius, containerColor]} flex={1}>
            {children}
        </Box>
    );
};

const styles = StyleSheet.create({
    contentBorderRadius: { borderRadius: 16 },
});
