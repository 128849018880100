import { Pressable, ScrollView, StyleSheet } from "react-native";
import React, { Dispatch, FC, memo, SetStateAction, useCallback, useState } from "react";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { MEDIA_SOURCE_DATA, UploadedMediaFile } from "@swiggy-private/rn-image-picker";
import { isPlatform } from "@swiggy-private/react-native-ui";

import { unique } from "@minis-consumer/helpers/array";
import { MediaUploadModal } from "@minis-consumer/components/media-upload-modal";

import { DISPLAY_COPIES, UPLOAD_LIMIT } from "../constants";
import { CameraIcon } from "./camera-icon";
import { UploadedImage } from "./uploaded-image";

const { ADD_PHOTO, ADD_PHOTO_SUBTEXT } = DISPLAY_COPIES.CARD;

interface AddPhotoProps {
    selectedMedia?: string[];
    setSelectedMedia: Dispatch<SetStateAction<string[] | undefined>>;
}

const isWeb = isPlatform("web");

const AddPhotoComponent: FC<AddPhotoProps> = ({ selectedMedia = [], setSelectedMedia }) => {
    const { value: theme } = useTheme();
    const [isMediaUploadVisible, setIsMediaUploadVisible] = useState(false);

    const imageUploadLimit = UPLOAD_LIMIT - selectedMedia.length;
    const backgroundStyle = { backgroundColor: theme["color-background-secondary"] };

    const openMediaUpload = useCallback((): void => {
        setIsMediaUploadVisible(true);
    }, []);

    const closeMediaUpload = useCallback((): void => {
        setIsMediaUploadVisible(false);
    }, []);

    const onUpload = useCallback(
        (media?: UploadedMediaFile[]): void => {
            closeMediaUpload();

            if (!Array.isArray(media) || !media.length) {
                return;
            }

            // If more than limit images are uploaded, only the first eligible ones will be added
            let mediasToConsider = media;
            if (mediasToConsider.length > imageUploadLimit) {
                mediasToConsider = mediasToConsider.slice(0, imageUploadLimit);
            }
            const mediaIds = unique(mediasToConsider.map((m) => m.mediaId));
            setSelectedMedia((prev) => [...(prev || []), ...mediaIds]);
        },
        [closeMediaUpload, imageUploadLimit, setSelectedMedia],
    );

    return (
        <>
            {selectedMedia?.length ? (
                <ScrollView style={[styles.imagesContainer, backgroundStyle]} horizontal>
                    {selectedMedia.map((media) => (
                        <UploadedImage key={media} {...{ media, setSelectedMedia }} />
                    ))}

                    {imageUploadLimit ? (
                        <Pressable onPress={openMediaUpload} style={styles.cameraIcon}>
                            <CameraIcon />
                        </Pressable>
                    ) : null}
                </ScrollView>
            ) : (
                <Pressable
                    onPress={openMediaUpload}
                    style={[styles.uploadContainer, backgroundStyle]}>
                    <CameraIcon />
                    <Box flex={1}>
                        <Text category="b3" color="color-basic-60">
                            <Text category="btn5" weight="bold" color="color-basic-75">
                                {ADD_PHOTO}
                            </Text>
                            {ADD_PHOTO_SUBTEXT}
                        </Text>
                    </Box>
                </Pressable>
            )}

            {isMediaUploadVisible ? (
                <MediaUploadModal
                    onUpload={onUpload}
                    limit={imageUploadLimit}
                    sourceData={isWeb ? [MEDIA_SOURCE_DATA[1]] : undefined}
                />
            ) : null}
        </>
    );
};

export const AddPhoto = memo(AddPhotoComponent);

const styles = StyleSheet.create({
    uploadContainer: {
        flexDirection: "row",
        alignItems: "center",
        padding: SpacingValue["space-x-small"],
        marginVertical: SpacingValue["space-x-small"],
        borderRadius: 12,
    },
    imagesContainer: {
        marginVertical: SpacingValue["space-x-small"],
        paddingLeft: SpacingValue["space-x-small"],
        paddingRight: SpacingValue["space-x-large"],
        borderRadius: 12,
    },
    cameraIcon: {
        marginVertical: SpacingValue["space-x-small"],
    },
});
