import { useCallback } from "react";
import { useController } from "@rest-hooks/react";

import { Order } from "@minis-consumer/interfaces/order";
import { OrderCancel } from "@minis-consumer/resources/order";

export const useCancelOrder = (orderId: string): (() => Promise<Order>) => {
    const { fetch } = useController();

    return useCallback(async () => {
        return await fetch(OrderCancel, orderId);
    }, [fetch, orderId]);
};
