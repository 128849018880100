import React, { PropsWithChildren } from "react";
import { useSuspense } from "@rest-hooks/react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { useGetProduct } from "@minis-consumer/routes/product/hooks/use-get-product";
import { useGetCartItem } from "@minis-consumer/hooks/use-cart";
import { CatalogView } from "@minis-consumer/resources/catalog";
import { RouteList } from "@minis-consumer/interfaces/route";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { StoreProvider } from "@minis-consumer/containers/store-container";
import { AsyncComponent } from "@minis-consumer/components/async-component";

import { SlotSelectionScreen } from "./";
import { SlotSelectionScreenLoader } from "./components/loading";

type Props = NativeStackScreenProps<RouteList, "SlotSelection">;

const FormComponent: React.FC<PropsWithChildren<Props>> = ({ navigation, route }) => {
    const { id: productId, isEditMode, variantId } = route.params;
    const productData = useGetProduct(productId);
    const store = useStoreInfo();

    const cartItem = useGetCartItem(store.storeId, productId, variantId);

    let selectedSlot;

    if (isEditMode) {
        selectedSlot = cartItem?.slot;
    }

    return (
        <SlotSelectionScreen
            navigation={navigation}
            route={route}
            product={isEditMode ? { ...cartItem } : { ...productData }}
            selectedSlot={selectedSlot}
        />
    );
};

const WithSelectedSlot: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const { slug } = props.route.params;

    useSuspense(CatalogView, { storeSlug: slug });
    return (
        <StoreProvider slug={slug}>
            <FormComponent {...props} />
        </StoreProvider>
    );
};

const WithAsyncComponent: React.FC<React.PropsWithChildren<Props>> = (props) => {
    return (
        <AsyncComponent fallback={SlotSelectionScreenLoader}>
            <WithSelectedSlot {...props} />
        </AsyncComponent>
    );
};

export const SlotSelectionWrapper = React.memo(WithAsyncComponent);
