import { PerfMetricsWidgetProps } from "@swiggy-private/connect-business-commons";

import { IPerformanceMetrics } from "@minis-consumer/interfaces/store";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";

export const generatePerfWidgetsData = (
    perfMetrics: IPerformanceMetrics,
): PerfMetricsWidgetProps[] => {
    if (!perfMetrics) {
        return [];
    }

    const transformedMetricsData: PerfMetricsWidgetProps[] = [
        {
            icon: "StarFilled",
            text: `${perfMetrics.rating} (${formatToReadableString(perfMetrics.ratingsCount)})`,
            iconColor: "color-warning-400",
            testID: TEST_IDS_SF.PERF_METRICS.RATINGS,
        },
        {
            icon: "CatalogFilled",
            text: `${formatToReadableString(perfMetrics.orders)} ${
                perfMetrics.orders < 2 ? "Order" : "Orders"
            }`,
            iconColor: "color-tertiary-400",
            testID: TEST_IDS_SF.PERF_METRICS.ORDERS,
        },
        {
            icon: "HeartFilled",
            text: `${formatToReadableString(perfMetrics.followers)} Love this`,
            iconColor: "color-critical-400",
            testID: TEST_IDS_SF.PERF_METRICS.FOLLOWERS,
        },
    ];

    return transformedMetricsData;
};

export const formatToReadableString = (num: string | number): string | number => {
    const value = typeof num === "string" ? parseFloat(num) : num;
    if (isNaN(value)) {
        return num;
    }

    if (value >= 1000 && value < 1000000) {
        return (value / 1000).toFixed(1).replace(/\.0$/, "") + " k";
    } else if (value >= 1000000 && value < 1000000000) {
        return (value / 1000000).toFixed(1).replace(/\.0$/, "") + " M";
    } else if (value >= 1000000000) {
        return (value / 1000000000).toFixed(1).replace(/\.0$/, "") + " B";
    } else {
        return value.toString();
    }
};
