import React from "react";
import { StyleSheet } from "react-native";

import { useTheme } from "@swiggy-private/rn-dls";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

const DashSeparatorComponent: React.FC = () => {
    const { value: theme } = useTheme();

    return <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.mt0} />;
};

export const DashSeparator = React.memo(DashSeparatorComponent);

const styles = StyleSheet.create({
    mt0: {
        marginTop: 0,
    },
});
