import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { Card } from "@minis-consumer/components/card";
import { Slate } from "@minis-consumer/components/slate";

const AddressLSLoadingComponent: React.FC = () => {
    return (
        <Card>
            <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                <Slate height={48} width={48} borderRadius={10} />
                <Stack spacing={SpacingValue["space-x-small"]}>
                    <Slate height={24} width={160} borderRadius={8} />
                    <Slate height={16} width={80} borderRadius={8} />
                </Stack>
            </Stack>
            <Stack style={styles.mt24} direction="row" spacing={SpacingValue["space-medium"]}>
                <AddressCard />
                <AddressCard />
            </Stack>
            <Box mt={SpacingValue["space-x-large"]}>
                <Slate height={40} width="100%" borderRadius={8} />
            </Box>
        </Card>
    );
};

const AddressCard: React.FC = () => {
    const { value: theme } = useTheme();

    const cardBorderColor = {
        borderColor: theme["color-basic-15"],
    };

    return (
        <Card style={[styles.addressCard, cardBorderColor]}>
            <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                <Slate height={20} width={20} borderRadius={8} />
                <Slate height={20} width={64} borderRadius={8} />
            </Stack>
            <Box mt={SpacingValue["space-small"]}>
                <Slate height={12} width="80%" borderRadius={8} />
            </Box>
            <Box mt={SpacingValue["space-x-small"]}>
                <Slate height={12} width="70%" borderRadius={8} />
            </Box>
            <Box mt={SpacingValue["space-small"]}>
                <Slate height={32} width="40%" borderRadius={8} />
            </Box>
        </Card>
    );
};

const styles = StyleSheet.create({
    mt24: {
        marginTop: SpacingValue["space-x-large"],
    },
    addressCard: {
        backgroundColor: "transparent",
        borderWidth: 1,
        elevation: 0,
        flex: 1,
    },
});

export const AddressLSLoader = React.memo(AddressLSLoadingComponent);
