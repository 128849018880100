import React from "react";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { ActivityIndicator, useTheme } from "@swiggy-private/rn-dls";

import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { FollowedStoresContent } from "./components/content";

export const FollowedStores: React.FC = () => {
    const { value: theme } = useTheme();
    const bgSecondaryColor = theme["color-background-secondary"];

    const scrollStyle = {
        backgroundColor: bgSecondaryColor,
    };

    return (
        <StateContextContainer>
            <AsyncComponent
                fallback={
                    <Box flex={1} style={scrollStyle} justifyContent="center" alignItems="center">
                        <ActivityIndicator />
                    </Box>
                }>
                <FollowedStoresContent />
            </AsyncComponent>
        </StateContextContainer>
    );
};
