/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from "react";
import { Platform } from "react-native";

import { FONT_BOLD, Text } from "@swiggy-private/rn-dls";

import { AccountRouteList } from "@minis-consumer/interfaces/route";
import { useIsNavbarVisible } from "@minis-consumer/hooks/use-is-navbar-visible";
import { createNavigator } from "@minis-consumer/helpers/navigation";
import { useScreenOptions } from "@minis-consumer/hooks/use-screen-options";
import { ResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";

import { HEADER_TITLE_CONSTANTS } from "./constants/constants";

const Stack = createNavigator<AccountRouteList>();

const isNative = Platform.OS !== "web";

export const AccountScreen: React.FC = () => {
    const showNavbar = useIsNavbarVisible();
    const options = useScreenOptions<AccountRouteList>({
        headerShown: !showNavbar,
        animationEnabled: true,
        headerTitleStyle: { fontFamily: FONT_BOLD },
    });

    return (
        <ResetErrorBoundary>
            <Stack.Navigator screenOptions={options}>
                <Stack.Screen
                    name="ProfileSettings"
                    getComponent={() => require("./routes/profile-settings").ProfileSettings}
                    options={{ headerTitle: "Profile settings" }}
                />

                {isNative ? (
                    <Stack.Screen
                        name="FollowedStores"
                        getComponent={() => require("./routes/followed-stores").FollowedStores}
                        options={{ headerTitle: "Minis you love" }}
                    />
                ) : null}

                <Stack.Screen
                    name="FAQ"
                    getComponent={() => require("./routes/faq").FAQ}
                    options={{ headerTitle: "FAQs" }}
                />

                <Stack.Screen
                    name="Help"
                    getComponent={() => require("./routes/help").HelpScreen}
                    options={{ headerShown: false }}
                />

                <Stack.Screen
                    name="SavedAddresses"
                    getComponent={() => require("./routes/saved-addresses").SavedAddresses}
                    options={{ headerTitle: "Saved addresses" }}
                />

                <Stack.Screen
                    name="TermsAndConditions"
                    getComponent={() => require("./routes/terms-and-conditions").TermsAndConditions}
                    options={{ headerTitle: "Terms & conditions" }}
                />

                {isNative ? (
                    <Stack.Screen
                        name="AbandonedCart"
                        getComponent={() => require("./routes/abandoned-cart").AbandonedCart}
                        options={{
                            headerTitle: () => (
                                <Text category="b1" weight="bold" color="color-secondary-400">
                                    {HEADER_TITLE_CONSTANTS.ABANDONED_CART}
                                </Text>
                            ),
                        }}
                    />
                ) : null}
            </Stack.Navigator>
        </ResetErrorBoundary>
    );
};
