import React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { AsyncComponent } from "@swiggy-private/react-native-ui";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";

import { RouteList } from "@minis-consumer/interfaces/route";
import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { ResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { TrackAsyncTasksContext } from "@minis-consumer/contexts/async-tasks-context";
import { CART_UPDATION } from "@minis-consumer/containers/store-cart-container";
import { Logger } from "@minis-consumer/includes/logger";
import { MainHeader } from "@minis-consumer/routes/minis-home/components/header/header-wrapper";

import { AbandonedCartsLoader } from "./components/loader";
import { CartsList } from "./components/carts-list";

export const AbandonedCart: React.FC<NativeStackScreenProps<RouteList>> = (props) => {
    const { promises, clearAsyncTasksPromise } = React.useContext(TrackAsyncTasksContext);

    const onfetch = React.useCallback(
        (): Promise<void> =>
            Promise.all(promises?.[CART_UPDATION] ?? [])
                .then(() => clearAsyncTasksPromise(CART_UPDATION))
                .catch((err) => Logger.recordError(err)),
        [clearAsyncTasksPromise, promises],
    );

    useIsomorphicEffect(() => {
        props.navigation.setOptions({
            header: () => <MainHeader />,
            headerShown: true,
        });
    }, [props.navigation]);

    return (
        <StateContextContainer>
            <ResetErrorBoundary>
                <AsyncComponent
                    loader={<AbandonedCartsLoader canAnimate={false} />}
                    fetch={onfetch}
                    ContentComponent={() => <CartsList />}
                />
            </ResetErrorBoundary>
        </StateContextContainer>
    );
};
