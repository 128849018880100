import { useCallback } from "react";

import { useNavigation } from "@react-navigation/native";

import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import { confirmOrderApi } from "@minis-consumer/api/order";
import { confirmPaymentTransactionApi } from "@minis-consumer/api/payment";
import { Logger } from "@minis-consumer/includes/logger";
import { PaymentTransaction } from "@minis-consumer/interfaces/payment";
import { PaymentProceedNavigationProp } from "@minis-consumer/interfaces/route";

import { useNavigateToOrderWithReset } from "./use-navigate-order";

type Params = {
    transactionId: string;
    pollDuration?: number;
    token?: string;
    paymentId?: string;
    onCompletion?: () => void;
};

export const useConfirmTransaction = (): ((params: Params) => Promise<void>) => {
    const navigation = useNavigation<PaymentProceedNavigationProp<"PaymentVerify">>();
    const controller = new AbortController();
    const navigateToOrder = useNavigateToOrderWithReset();

    const fn = useCallback(
        async ({
            transactionId,
            pollDuration = 15000,
            paymentId,
            onCompletion,
        }: Params): Promise<void> => {
            const signal = controller.signal;

            const paymentTransaction = await pollConfirmTransaction({
                pollDuration,
                transactionId,
                paymentId,
                signal,
            });

            if (paymentTransaction.transactionStatus === "SUCCESS") {
                const order = await confirmOrderApi(paymentTransaction.referenceId).catch((err) => {
                    Logger.recordError(err);
                    return null;
                });

                if (!signal.aborted && order && order.status === "PENDING") {
                    onCompletion?.();
                    Analytics.impressionEvent({
                        category: "payment_order_successful",
                        context: JSON.stringify({
                            orderId: order.id,
                            storeName: order.storeDetails.name,
                            transactionId,
                        }),
                    });
                    navigation.reset({
                        index: 0,
                        routes: [
                            {
                                name: "PaymentSuccess",
                                params: {
                                    orderId: order.id,
                                    storeName: order.storeDetails.name,
                                    orderType: order.orderType ?? "PHYSICAL",
                                    transactionId,
                                },
                            },
                        ],
                    });

                    return;
                }
            }

            if (!signal.aborted) {
                onCompletion?.();

                navigateToOrder(
                    paymentTransaction.referenceId,
                    paymentTransaction.transactionStatus === "FAILED",
                );
            }
        },
        [controller.signal, navigateToOrder, navigation],
    );

    useMount(() => {
        return () => controller.abort();
    });

    return fn;
};

type PollConfirmTransactionParams = {
    pollDuration: number;
    transactionId: string;
    signal: AbortSignal;
    paymentId?: string;
};

const pollConfirmTransaction = async ({
    transactionId,
    pollDuration,
    paymentId,
    signal,
}: PollConfirmTransactionParams): Promise<PaymentTransaction> => {
    const startTime = Date.now();

    let transaction: PaymentTransaction | null = null;
    const pollFn = async (): Promise<void> => {
        try {
            transaction = await confirmPaymentTransactionApi({
                transactionId,
                paymentId,
            });
        } catch (err) {
            Logger.recordError(err);
        }
    };

    return new Promise(async (resolve, reject) => {
        while (true) {
            if (transaction && transaction?.transactionStatus !== "PENDING") {
                resolve(transaction);
                return;
            }

            const curTime = Date.now();
            if (curTime > startTime + pollDuration || signal.aborted) {
                transaction
                    ? resolve(transaction)
                    : reject(new Error("Failed to confirm the payment transaction"));
                return;
            }

            await pollFn();
            await new Promise((r) => setTimeout(r, 2000));
        }
    });
};
