import React from "react";

import { CartBill } from "@minis-consumer/interfaces/cart";
import { BillItem } from "@minis-consumer/components/bill-item";

const SubTotalComponent: React.FC<{ finalAmount: CartBill["itemTotal"] }> = ({ finalAmount }) => {
    return <BillItem title={"Subtotal"} finalAmount={finalAmount} />;
};

export const SubTotal = React.memo(SubTotalComponent);
