import React, { memo, useCallback, useMemo, useState } from "react";
import { StatusBar, StyleSheet, ScrollView, useWindowDimensions } from "react-native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useNavigation } from "@react-navigation/core";

import { BottomSheet } from "@swiggy-private/react-native-ui";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { OrderEntity } from "@minis-consumer/resources/order";
import { OrderItem as IOrderItem } from "@minis-consumer/interfaces/order";
import { RouteList } from "@minis-consumer/interfaces/route";

import { ReturnSelectCard } from "../../../routes/return-order/select-return-items/components/select-card";
import { SelectReturnReasonCard } from "../../../routes/return-order/select-return-reason/components/select-reason-card";
import { ReturnConfirmation } from "./return-confirmation";

interface ModalReturnStepsProps {
    showModal: boolean;
    onClose: VoidFunction;
    order: OrderEntity;
    onReturnRequest?: VoidFunction;
}

const gutter = SpacingValue["space-x-large"];

const ModalReturnStepsComponent: React.FC<ModalReturnStepsProps> = ({
    showModal,
    onClose,
    order,
    onReturnRequest,
}) => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const { height: windowHeight } = useWindowDimensions();
    const maxScrollViewHeight = windowHeight - gutter * 2 - (StatusBar.currentHeight ?? 0);

    const [selectedItems, setSelectedItems] = useState<IOrderItem[]>([]);
    const [isSelectionDone, setIsSelectionDone] = useState(false);
    const [isSubmitDone, setIsSubmitDone] = useState(false);

    const onContinuePress = useCallback(() => {
        setIsSelectionDone(true);
    }, []);

    const handleReasonSubmit = useCallback(() => {
        setIsSubmitDone(true);
    }, []);

    const handleReturnSubmitAcknowledgment = useCallback(() => {
        onReturnRequest?.();
    }, [onReturnRequest]);

    const renderStep: JSX.Element = useMemo(() => {
        const showSelectCard = order.items.length > 1;
        if (showSelectCard && !isSelectionDone) {
            return (
                <ReturnSelectCard
                    order={order}
                    isDisabled={selectedItems.length === 0}
                    onContinuePress={onContinuePress}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                />
            );
        }

        if (!isSubmitDone) {
            return (
                <SelectReturnReasonCard
                    order={order}
                    selectedItems={selectedItems}
                    onSendSuccess={handleReasonSubmit}
                    navigation={navigation}
                />
            );
        }

        return (
            <ReturnConfirmation
                order={order}
                selectedItems={selectedItems}
                onSubmit={handleReturnSubmitAcknowledgment}
            />
        );
    }, [
        order,
        onContinuePress,
        selectedItems,
        setSelectedItems,
        handleReasonSubmit,
        isSelectionDone,
        isSubmitDone,
        handleReturnSubmitAcknowledgment,
        navigation,
    ]);

    return (
        <BottomSheet open={showModal} onClose={onClose} style={styles.root}>
            <ScrollView style={{ maxHeight: maxScrollViewHeight }}>{renderStep}</ScrollView>
        </BottomSheet>
    );
};

const styles = StyleSheet.create({
    root: {
        borderRadius: SpacingValue["space-medium"],
        overflow: "hidden",
    },
});

export const ModalReturnSteps = memo(ModalReturnStepsComponent);
