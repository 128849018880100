import React, { memo } from "react";
import { StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { DottedUnderlineText } from "@swiggy-private/react-native-ui";

import { ReturnResolutionDetails } from "@minis-consumer/interfaces/return-order";

import { ORDER_RETURN_CLOSE_RESOLUTIONS, RETURN_ORDER_LABELS } from "../../constants";

interface IExchangeDetailsComponent {
    onCopy?: VoidFunction;
    tracking?: ReturnResolutionDetails["tracking"];
    partnerName?: ReturnResolutionDetails["partnerName"];
    details?: ReturnResolutionDetails["details"];
}

const ICON_SIZE = 16;

const ExchangeDetailsComponent: React.FC<IExchangeDetailsComponent> = ({
    onCopy,
    tracking,
    partnerName,
    details,
}) => {
    return (
        <Stack spacing={SpacingValue["space-xx-small"]}>
            <Text category="b3" color="medium">
                {RETURN_ORDER_LABELS.RESOLUTION}
            </Text>
            {partnerName ? (
                <Text category="b2" weight="bold" color="high">
                    {`${RETURN_ORDER_LABELS.SENT_AN_EXCHANGE} ${partnerName}`}
                </Text>
            ) : (
                <Text category="b2" weight="bold" color="high">
                    {ORDER_RETURN_CLOSE_RESOLUTIONS.EXCHANGE}
                </Text>
            )}
            {tracking ? (
                <Stack direction="row" alignItems="center" spacing={SpacingValue["space-xx-small"]}>
                    <DottedUnderlineText
                        category="b2"
                        color="color-basic-100"
                        underlineColor="color-basic-60"
                        suppressHighlighting>
                        {tracking}
                    </DottedUnderlineText>
                    <SvgIcon
                        icon="CopyOutline"
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                        color="color-basic-alpha-92"
                        onPress={onCopy}
                    />
                </Stack>
            ) : null}
            {details ? (
                <Stack
                    flex={1}
                    spacing={SpacingValue["space-xx-small"]}
                    style={styles.additonalDetailsContainer}>
                    <Text category="b3" color="medium">
                        {RETURN_ORDER_LABELS.ADDITIONAL_DETAILS}
                    </Text>

                    <Text style={styles.textStyles} category="b2" color="high">
                        {details}
                    </Text>
                </Stack>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    textStyles: {
        fontStyle: "italic",
    },
    additonalDetailsContainer: {
        marginTop: SpacingValue["space-small"],
    },
});

export const ExchangeDetails = memo(ExchangeDetailsComponent);
