import { IUPIAppsList } from "@minis-consumer/includes/swiggy-rn-module";
import { UPIApps } from "../types";

export const CUSTOM_UPI_LABEL = "CUSTOM_UPI";

/** @TODO - Replace logo URL */
export const UPI_APPS: Record<
    UPIApps,
    {
        collectLogoUrl: string;
        intentLogoUrl: string;
        name: string;
        label?: string;
    }
> = {
    PHONEPE: {
        collectLogoUrl: "minis/upi_collect_phonepe",
        intentLogoUrl: "minis/upi_intent_phonepe",
        name: "PhonePe",
    },
    PAYTM: {
        collectLogoUrl: "minis/upi_collect_paytm",
        intentLogoUrl: "minis/upi_intent_paytm",
        name: "PayTM",
    },
    GOOGLE_PAY: {
        collectLogoUrl: "minis/upi_collect_gpay",
        intentLogoUrl: "minis/upi_intent_gpay",
        name: "Google Pay",
    },
    AMAZON_PAY: {
        collectLogoUrl: "minis/upi_collect_amazonpay",
        intentLogoUrl: "minis/upi_intent_amazonpay",
        name: "Amazon Pay",
    },
    BHIM_UPI: {
        collectLogoUrl: "minis/upi_collect_bhim",
        intentLogoUrl: "minis/upi_intent_bhim",
        name: "BHIM",
    },
    CUSTOM_UPI: {
        collectLogoUrl: "minis/upi-collect-custom",
        intentLogoUrl: "minis/upi-collect-custom",
        name: "Add new UPI ID",
        label: CUSTOM_UPI_LABEL,
    },
};

export const CUSTOM_UPI_HANDLER: IUPIAppsList = {
    label: CUSTOM_UPI_LABEL,
    packageName: UPI_APPS.CUSTOM_UPI.name,
    icon: UPI_APPS.CUSTOM_UPI.intentLogoUrl,
};

export const DEFAULT_UPI_HANDLER: UPIApps = "BHIM_UPI";

/**
 * Source(s) -
 *
 * https://www.npci.org.in/what-we-do/upi/3rd-party-apps
 *
 * https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doRecognisedFpi=yes&intmId=43
 */
export const UPI_HANDLER_MAP: Record<string, UPIApps> = {
    apl: "AMAZON_PAY",
    yapl: "AMAZON_PAY",
    okaxis: "GOOGLE_PAY",
    okhdfcbank: "GOOGLE_PAY",
    okicici: "GOOGLE_PAY",
    oksbi: "GOOGLE_PAY",
    ybl: "PHONEPE",
    ibl: "PHONEPE",
    axl: "PHONEPE",
    paytm: "PAYTM",
};

export const UPI_APP_KEYS = Object.keys(UPI_APPS) as Array<keyof typeof UPI_APPS>;

export const APP_NOT_INSTALLED =
    "App might not be installed in your device, please choose a different app for UPI payment";

export const APP_REDIRECTION_TOAST =
    "You will be redirected to the UPI app if its installed in your device to complete the payment";

// TODO: make config driven
export const MASTER_UPI_APPS_LIST = [
    { name: "ABIPBL", packageName: "com.abipbl.upi" },
    { name: "Amazon Pay", packageName: "in.amazon.mShop.android.shopping" },
    { name: "Angel Mobile", packageName: "com.msf.angelmobile" },
    { name: "Angel Wealth", packageName: "com.angelbroking.angelwealth" },
    { name: "Axis Mobile", packageName: "com.axis.mobile" },
    { name: "Axis Pay", packageName: "com.upi.axispay" },
    {
        name: "Bajaj Finserv - Instant Loans,Credit Card,EMI Card",
        packageName: "org.altruist.BajajExperia",
    },
    { name: "Bajaj Finserv MARKETS", packageName: "in.bajajfinservmarkets.app" },
    { name: "Bank of Baroda", packageName: "com.bankofbaroda.upi" },
    { name: "BHIM", packageName: "in.org.npci.upiapp" },
    { name: "Chillr", packageName: "in.chillr" },
    { name: "CitrusPay", packageName: "com.citrus.citruspay" },
    { name: "Cred", packageName: "com.dreamplug.androidapp" },
    { name: "DBS", packageName: "com.dbs.in.digitalbank" },
    { name: "DCB", packageName: "com.olive.dcb.upi" },
    { name: "Dena", packageName: "com.dena.upi.gui" },
    { name: "Fave (Pinelabs)", packageName: "com.pinelabs.fave" },
    { name: "Federal Bank", packageName: "com.upi.federalbank.org.lotza" },
    { name: "Fino", packageName: "com.finopaytech.bpayfino" },
    { name: "Freecharge", packageName: "com.freecharge.android" },
    { name: "Funds India", packageName: "com.fundsindia" },
    { name: "GB WhatsApp", packageName: "com.gbwhatsapp" },
    { name: "Goibibo", packageName: "com.goibibo" },
    { name: "Google Pay", packageName: "com.google.android.apps.nbu.paisa.user" },
    { name: "Groww", packageName: "com.nextbillion.groww" },
    { name: "HDFC Snapwork", packageName: "com.snapwork.hdfc" },
    { name: "HDFC Wibmo", packageName: "com.enstage.wibmo.hdfc" },
    { name: "Hike", packageName: "com.bsb.hike" },
    { name: "ICICI Imobile", packageName: "com.csam.icici.bank.imobile" },
    { name: "ICICI Pockets", packageName: "com.icicibank.pockets" },
    { name: "IDFC PSP", packageName: "com.fss.idfcpsp" },
    { name: "iMuthoot", packageName: "com.muthootfinance.imuthoot" },
    { name: "Indus Bank", packageName: "com.mgs.induspsp" },
    { name: "Jammu and Kashmir", packageName: "com.fss.jnkpsp" },
    { name: "Jupiter", packageName: "money.jupiter" },
    { name: "Kiwi", packageName: "in.gokiwi.kiwitpap" },
    { name: "Kotak", packageName: "com.olive.kotak.upi" },
    { name: "KVB MyCompany", packageName: "com.mycompany.kvb" },
    { name: "Make My Trip", packageName: "com.makemytrip" },
    { name: "MI Pay", packageName: "com.mipay.wallet.in" },
    { name: "Mobikwik", packageName: "com.mobikwik_new" },
    { name: "MyAirtel App", packageName: "com.myairtelapp" },
    { name: "Navi", packageName: "com.naviapp" },
    { name: "Niyo Global", packageName: "finance.global.travel.niyo" },
    { name: "Ola", packageName: "com.olacabs.customer" },
    { name: "Oriental Bank of Commerce", packageName: "com.mgs.obcbank" },
    { name: "PayTM", packageName: "net.one97.paytm" },
    { name: "Paytm Mall", packageName: "com.paytmmall" },
    { name: "PhonePe", packageName: "com.phonepe.app" },
    { name: "PNB", packageName: "com.fss.pnbpsp" },
    { name: "Polymer", packageName: "com.microsoft.mobile.polymer" },
    { name: "RBL MoBank", packageName: "com.rblbank.mobank" },
    { name: "Samsung SPay", packageName: "com.samsung.android.spay" },
    { name: "Samsung SPayMini", packageName: "com.samsung.android.spaymini" },
    { name: "SBI", packageName: "com.sbi.upi" },
    { name: "Shriram One", packageName: "com.shriram.one" },
    { name: "slice", packageName: "indwin.c3.shareapp" },
    { name: "South Indian Bank", packageName: "com.SIBMobile" },
    { name: "TataNeu", packageName: "com.tatadigital.tcp" },
    { name: "Timepay", packageName: "com.npst.timepay.society" },
    { name: "Truecaller", packageName: "com.truecaller" },
    { name: "tvam (Atyati)", packageName: "indwin.c3.shareapp" },
    { name: "Ultracash", packageName: "com.ultracash.payment.customer" },
    { name: "UNBI", packageName: "com.fss.unbipsp" },
    { name: "Vijaya Bank", packageName: "com.fss.vijayapsp" },
    { name: "WhatsApp", packageName: "com.whatsapp" },
    { name: "WhatsApp Business", packageName: "com.whatsapp.w4b" },
    { name: "Wizely", packageName: "ai.wizely.android" },
    { name: "Yes Bank", packageName: "com.YesBank" },
    { name: "Yes Merchant", packageName: "com.mgs.yesmerchantnative.prod" },
    { name: "Zomato", packageName: "com.application.zomato" },
];
