import React from "react";

import { useMountedRef } from "@swiggy-private/react-hooks";
import { Display, ScreenSize } from "@swiggy-private/rn-adaptive-layout";

import { Logger } from "@minis-consumer/includes/logger";
import { UserAddress } from "@minis-consumer/interfaces/cart";

import { useValidateAddressMandatoryFields } from "../../hooks/use-validate-address";
import { AddressLargeScreen } from "./components/large-screen";
import { AddressSmallScreen } from "./components/small-screen";
import { useAddressListData } from "@minis-consumer/hooks/use-address-list-data";

type AddressListComponentProps = {
    storeId: string;
    setAddressSelected: (address: UserAddress | null) => void;
    open?: boolean;
    setOpen?: (b: boolean) => void;
    addressSelected?: UserAddress | null;
    shouldShowBanner?: boolean;
    fetchAddressesFromAddressApi?: boolean;
    showAddressInModal?: boolean;
    shouldShowUnserviceable?: boolean;
};

const AddressListComponent: React.FC<AddressListComponentProps> = ({
    storeId,
    open,
    setOpen,
    setAddressSelected,
    addressSelected,
    shouldShowBanner = true,
    fetchAddressesFromAddressApi = false,
    showAddressInModal = false,
    shouldShowUnserviceable = true,
}) => {
    const addressList = useAddressListData(storeId, fetchAddressesFromAddressApi);
    const validate = useValidateAddressMandatoryFields();
    const mountedRef = useMountedRef();

    const [isValidAddress, setIsValidAddress] = React.useState<boolean>();
    const [showAddress, setShowAddress] = React.useState<boolean>(false);

    const validateAddress = React.useCallback(async () => {
        try {
            const isValid = await validate(addressSelected);
            if (mountedRef.current) {
                setIsValidAddress(isValid);
                setShowAddress(true);
                !isValid && setAddressSelected(null);
            }
        } catch (err) {
            Logger.recordError(err);
        }
    }, [addressSelected, mountedRef, setAddressSelected, validate]);

    // Isomorphic effect was causing issue on Desktop
    React.useEffect(() => {
        if (addressSelected) {
            validateAddress();
        } else {
            setShowAddress(true);
        }
    }, [addressSelected, validateAddress]);

    return (
        <>
            <Display gt={ScreenSize.Large}>
                {showAddress ? (
                    <AddressLargeScreen
                        addresses={addressList}
                        setAddressSelected={setAddressSelected}
                        addressChosenAlready={isValidAddress ? addressSelected : null}
                        isOpen={open}
                        setOpen={setOpen}
                        showAddressInModal={showAddressInModal}
                        shouldShowUnserviceable={shouldShowUnserviceable}
                    />
                ) : null}
            </Display>

            <Display lt={ScreenSize.Medium}>
                <AddressSmallScreen
                    shouldShowBanner={shouldShowBanner}
                    addresses={addressList}
                    open={open ?? false}
                    setOpen={setOpen}
                    setShowAddressSelected={setAddressSelected}
                    shouldShowUnserviceable={shouldShowUnserviceable}
                />
            </Display>
        </>
    );
};

export const AddressList = React.memo(AddressListComponent);
