export const DEFAULTS = {
    BRAND_IMAGE_SIZE: 40,
    PRODUCT_IMAGE_SIZE: 68,
    STAR_SELLER_BADGE_ICON_SIZE: 14,
    QUANTITY_WRAPPER_SIZE: 24,
    CTA_HEIGHT: 52,
    CART_KEBAB_DROPDOWN_TEXT: "Clear cart",
    CTA_TEXT: "Proceed to checkout",
    GRADIENT_COLORS: ["rgba(250, 235, 233, 0))", "#FAEBE9"],
    GRADIENT_LOCATIONS: [0.3, 1],
    GRADIENT_START_POINT: { x: 0.09, y: 0.22 },
    GRADIENT_END_POINT: { x: 0.78, y: 0.92 },
    PRODUCT_FALLBACK_IMG: "minis/product_img_fallback",
    CLEAR_CART_API_DELAY_IN_MS: 4500,
    QUANTITY_GRADIENT_COLORS: ["#FFFFFF", "#F0F0F5", "#F0F0F5"],
    QUANTITY_GRADIENT_COLORS_START_POINT: { x: 1, y: 0 },
    QUANTITY_GRADIENT_COLORS_LOCATIONS: [0.2, 0.7, 1],
    TOOLTIP_SHOW_DURATION: 2000,
    STORE_NAME_CHAR_LTH: 20,
    FALLBACK_IMG: "minis/shop_logo_fallback",
};
