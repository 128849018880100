import * as React from "react";
import { Platform, StyleSheet } from "react-native";

import { Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { MinisRouteList } from "@minis-consumer/interfaces/route";
import { useMinisNavigation } from "@minis-consumer/hooks/use-minis-navigation";

export const FollowedStoresEmptyState: React.FC = () => {
    const { value: theme } = useTheme();
    const bgPrimaryColor = theme["color-background-primary"];
    const btnBgColor = theme["color-basic-100"];

    const navigation = useNavigation<NativeStackNavigationProp<MinisRouteList>>();

    const minisNavigation = useMinisNavigation();

    const onPressExploreMinis = React.useCallback(() => {
        if (minisNavigation !== null) {
            minisNavigation?.navigate("MinisHome", { screen: "Explore" });
        } else {
            navigation?.navigate("MinisHome", { screen: "Explore" });
        }
    }, [navigation, minisNavigation]);

    return (
        <Stack
            spacing={36}
            alignItems="center"
            justifyContent="center"
            flex={1}
            style={{ backgroundColor: bgPrimaryColor }}>
            <CdnImage
                isImageKitEnabled
                id="minis/followed_stores_empty_state.png"
                height={150}
                width={250}
            />
            <Stack spacing={SpacingValue["space-x-small"]} alignItems="center">
                <Stack spacing={SpacingValue["space-x-small"]} alignItems="center">
                    <Text category="h3" color="high" style={styles.textStyles}>
                        It&apos;s lonely in here...
                    </Text>

                    <Text category="b2" color="medium" style={styles.textStyles}>
                        {"Follow Ministores you love, and it will\nshow up here."}
                    </Text>
                </Stack>

                {Platform.OS !== "web" ? (
                    <Button onPress={onPressExploreMinis} style={{ backgroundColor: btnBgColor }}>
                        Explore Minis
                    </Button>
                ) : null}
            </Stack>
        </Stack>
    );
};

const styles = StyleSheet.create({
    textStyles: {
        textAlign: "center",
        paddingHorizontal: SpacingValue["space-medium"],
    },
});
