import React from "react";
import { useFocusEffect } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { SearchLocation } from "@swiggy-private/connect-address-ui";
import { Container, Display, ScreenSize, Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { Modal, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";

import { useSearchLocationFns } from "@minis-consumer/hooks/use-search-location-fns";
import { AddressRouteList } from "@minis-consumer/interfaces/route";
import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { Analytics } from "@minis-consumer/analytics";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import { HeaderRight } from "../../components/header-right";
import { HeaderLeft } from "../../components/header-left";

type Props = NativeStackScreenProps<AddressRouteList, "SearchLocation">;

export const SearchAddress: React.FC<Props> = ({ route, navigation }) => {
    const { params } = route;

    const storeInfo = useStoreInfo(false);

    const { onMount: onMountCall, onLocateMePress, onError } = useSearchLocationFns();
    const [showModal, setShowModal] = React.useState(true);

    const navigateToMapScreen = React.useCallback(() => {
        navigation.navigate("Map", {
            storeId: params?.storeId || storeInfo?.storeId,
        });
    }, [navigation, params?.storeId, storeInfo?.storeId]);

    const goBack = React.useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
    }, [navigation]);

    const onMount = React.useCallback(() => {
        Analytics.impressionEvent({ category: "locate-me-btn" });
        onMountCall?.();
    }, [onMountCall]);

    const onLocateMePressWithAnalytics = React.useCallback(() => {
        Analytics.clickEvent({ category: "locate-me-btn" });

        onLocateMePress();
    }, [onLocateMePress]);

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerTitle: "",
            title: "",
            headerLeft: () => <HeaderLeft onPressBack={goBack} title="Add your delivery address" />,
            headerRight: () => <HeaderRight progressNumber={1} totalProgressNumber={3} />,
        });
    }, [goBack, navigation]);

    useFocusEffect(
        React.useCallback(() => {
            setShowModal(true);
        }, []),
    );

    return (
        <StateContextContainer>
            <Display gt={ScreenSize.Large}>
                <Container fluid={false} disableGutters>
                    <Modal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        dismissOnPressingBackdrop={false}
                        modalStyles={{
                            modalBodyStyle: {
                                maxWidth: 600,
                                width: 600,
                                height: "66%",
                            },
                        }}
                        component={
                            <Box flex={1}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    style={{
                                        marginLeft: SpacingValue["space-medium"],
                                        paddingVertical: SpacingValue["space-xx-small"],
                                    }}>
                                    <SvgIcon icon="ArrowLeft" onPress={goBack} />
                                    <Text
                                        category="h5"
                                        weight="bold"
                                        color="high"
                                        style={{ paddingHorizontal: SpacingValue["space-medium"] }}>
                                        Add your delivery address
                                    </Text>
                                </Stack>
                                <SearchLocation
                                    onPressCTA={navigateToMapScreen}
                                    onMount={onMount}
                                    onError={onError}
                                    onLocateMePress={onLocateMePressWithAnalytics}
                                    placeholder="Search for your delivery address"
                                />
                            </Box>
                        }
                    />
                </Container>
            </Display>

            <Display lt={ScreenSize.Medium}>
                <Container fluid={false} disableGutters>
                    <SearchLocation
                        onPressCTA={navigateToMapScreen}
                        onMount={onMount}
                        onError={onError}
                        onLocateMePress={onLocateMePress}
                        placeholder="Search for your delivery address"
                    />
                </Container>
            </Display>
        </StateContextContainer>
    );
};
