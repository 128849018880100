import * as React from "react";
import {
    ViewStyle,
    StatusBar,
    StyleSheet,
    Animated,
    Pressable,
    NativeScrollPoint,
    View,
} from "react-native";
import type { LayoutChangeEvent } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import { useSafeAreaInsets } from "react-native-safe-area-context";

//components
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { useTheme, Surface, TouchableComponent, SpacingValue } from "@swiggy-private/rn-dls";

import { SearchBarWidget } from "@minis-consumer/components/widgets/search-bar-widget";
import { useRecentMinisMigrationNudgeContext } from "@minis-consumer/components/recent-minis-migration-nudge/context";
import { SearchStyleType } from "@minis-consumer/components/recent-minis-migration-nudge/helpers";
import { useActiveOrderTooltipContext } from "@minis-consumer/components/active-order-tooltip-nudge/context";
import { useActiveOrderTooltipCounter } from "@minis-consumer/routes/minis-home/hooks/use-active-order-view-count";
import { useOnboardingStoriesContext } from "@minis-consumer/contexts/onboarding-stories-context";
import { SCROLL_EVENT_THROTTLE } from "@minis-consumer/routes/minis-home/constants";

//widgets
import { HeaderLeft } from "../left";
import { HeaderRight } from "../right";

const searchTexts = ["Search for stores"];

type IProps = {
    onSearchWidgetPress: () => void;
    scrolledUp: boolean;
    isTooltipVisible: boolean;
    onDismissTooltip: VoidFunction;

    scrollPosition?: NativeScrollPoint;
    animatedValue?: Animated.Value;
    headerStyles?: ViewStyle;
    searchPlaceholderTexts?: string[];
    onLayout?: (e: LayoutChangeEvent) => void;
    animationDone?: boolean;
    headerShown?: boolean;
    isScrollMoving?: boolean;
    onlyFadeInAnimation?: boolean;
};

export const Header: React.FC = () => {
    return (
        <Wrapper>
            <MainHeader />
        </Wrapper>
    );
};

export const MainHeader: React.FC = () => {
    const insets = useSafeAreaInsets();

    return (
        <Box
            direction="row"
            mt={insets.top + SpacingValue["space-small"]}
            mb={SpacingValue["space-x-small"]}
            justifyContent="space-between"
            alignItems="center">
            <Box ph={16}>
                <HeaderLeft size={20} showLogo />
            </Box>
            <Box ph={16}>
                <HeaderRight />
            </Box>
        </Box>
    );
};

const HeaderWrapperComponent: React.FC<IProps> = ({
    headerStyles,
    searchPlaceholderTexts,
    onLayout,
    animationDone,
    scrolledUp,
    scrollPosition,
    isScrollMoving,
    headerShown,
    onSearchWidgetPress,
    animatedValue,
    isTooltipVisible,
    onlyFadeInAnimation,
}) => {
    const insets = useSafeAreaInsets();
    const { isOpen: areStoriesPlaying } = useOnboardingStoriesContext();

    const componentRef = React.useRef<View>(null);

    const elevation = scrolledUp ? 0 : 4;

    const { value: theme } = useTheme();
    const { getInputStyles } = useRecentMinisMigrationNudgeContext();

    const { hasActiveOrder, isTooltipShown, setCanShowTooltip } = useActiveOrderTooltipContext();
    const { isNudgeViewCountLegal } = useActiveOrderTooltipCounter();

    const searchWidgetRootStyle = React.useMemo(
        () =>
            getInputStyles({
                canShowNudge: isTooltipVisible,
                type: SearchStyleType.ROOT_CONTAINER,
                color: theme["color-primary-400"],
            }),
        [getInputStyles, isTooltipVisible, theme],
    );

    const searchWidgetInputStyle = React.useMemo(
        () =>
            getInputStyles({
                canShowNudge: isTooltipVisible,
                type: SearchStyleType.INPUT,
                color: theme["color-primary-400"],
            }),
        [getInputStyles, isTooltipVisible, theme],
    );

    const animatedOpacity = animatedValue?.interpolate({
        inputRange: [0, 1],
        outputRange: [1, 0],
    });
    const animatedTranslationY = animatedValue?.interpolate({
        inputRange: [0, 1],
        outputRange: [0, -52],
    });

    const searchViewStyle = {
        opacity: onlyFadeInAnimation ? animatedOpacity : 1,
    };

    const checkIfCanShowTooltip = React.useCallback(() => {
        /**
         * If scroll has not been moved or floating header is being shown after
         * scrolling back up but the user has an active order,
         * tooltip hasn't been displayed yet and frequency isn't exhausted
         * */

        /** TODO: use reqeustAnimationFrame instead */
        const t = setTimeout(() => {
            // eslint-disable-next-line max-params
            componentRef?.current?.measure?.((_x, _y, _width, _height) => {
                const inWindowHeight = _height;

                const isFloatingHeaderVisible =
                    (scrollPosition?.y || 0) > inWindowHeight && headerShown;
                const isMainHeaderVisible = !scrollPosition?.y && headerShown;
                const isScrollAtTop = !scrollPosition?.y && !headerShown;

                const isHeaderShownAndNotScrolling =
                    isScrollAtTop ||
                    ((isMainHeaderVisible || isFloatingHeaderVisible) && !isScrollMoving);

                const isContextDataValid = hasActiveOrder && !isTooltipShown;

                /** TODO: add early returns */
                const isValidToShowTooltip = Boolean(
                    isContextDataValid &&
                        !areStoriesPlaying &&
                        isHeaderShownAndNotScrolling &&
                        isNudgeViewCountLegal,
                );

                setCanShowTooltip(isValidToShowTooltip);
            });
        }, SCROLL_EVENT_THROTTLE);

        return () => clearTimeout(t);
    }, [
        componentRef,
        headerShown,
        hasActiveOrder,
        scrollPosition,
        isTooltipShown,
        isNudgeViewCountLegal,
        areStoriesPlaying,
        isScrollMoving,
        setCanShowTooltip,
    ]);

    React.useEffect(() => {
        checkIfCanShowTooltip();
    }, [checkIfCanShowTooltip]);

    return (
        <Animated.View
            style={{
                transform: [
                    {
                        translateY:
                            onlyFadeInAnimation || !animatedTranslationY ? 0 : animatedTranslationY,
                    },
                ],
            }}
            ref={componentRef}>
            <Pressable testID="minis-homepage-header">
                <Box
                    style={[
                        styles.StatusBar,
                        { paddingTop: insets.top },
                        // eslint-disable-next-line react-native/no-inline-styles
                        { backgroundColor: headerShown ? "transparent" : theme["color-basic-0"] },
                    ]}>
                    <StatusBar backgroundColor={"transparent"} barStyle="dark-content" />
                </Box>

                <Surface
                    style={[{ elevation }, headerStyles]}
                    onLayout={onLayout}
                    renderToHardwareTextureAndroid>
                    <Wrapper>
                        <Animated.View
                            style={{
                                opacity: animatedOpacity,
                            }}>
                            <MainHeader />
                        </Animated.View>

                        <Animated.View style={searchViewStyle}>
                            <TouchableComponent
                                testID="minis-homepage-header-wrapper"
                                accessibilityRole="button"
                                accessible
                                onPress={onSearchWidgetPress}>
                                <SearchBarWidget
                                    homeScreenSearchWidgetProps={{
                                        shouldStopMarqueeAnimation: animationDone,
                                        searchTexts: searchPlaceholderTexts ?? searchTexts,
                                        rootStyle: searchWidgetRootStyle,
                                        inputStyle: searchWidgetInputStyle,
                                    }}
                                />
                            </TouchableComponent>
                        </Animated.View>
                    </Wrapper>
                </Surface>
            </Pressable>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    StatusBar: { ...StyleSheet.absoluteFillObject, bottom: "auto" },
    recentMinisNudge: {
        marginTop: -3 * SpacingValue["space-xxx-small"],
    },
});

const Wrapper: React.FC<React.PropsWithChildren> = (props) => {
    const { value: theme } = useTheme();

    /** TODO: add color to DLS */
    const GRADIENT_COLORS = [theme["color-basic-0"].toString(), "#E8F8F7"];
    return <LinearGradient colors={GRADIENT_COLORS}>{props.children}</LinearGradient>;
};

export const HeaderWrapper = React.memo(HeaderWrapperComponent);
