import React, { FC, PropsWithChildren, useCallback, useState } from "react";
import { StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { CommonRouteList } from "@minis-consumer/interfaces/route";
import { OrderItem as IOrderItem } from "@minis-consumer/interfaces/order";

import { HeaderTitle } from "./components/header-title";
import { ReturnSelectCard } from "./components/select-card";

type Props = NativeStackScreenProps<CommonRouteList, "SelectReturnItems">;

export const SelectReturnItemsScreen: FC<PropsWithChildren<Props>> = ({ navigation, route }) => {
    const { value: theme } = useTheme();
    const { order } = route?.params || {};
    const [selectedItems, setSelectedItems] = useState<IOrderItem[]>([]);

    const isContinueDisabled = selectedItems.length === 0;

    const onContinuePress = useCallback(() => {
        navigation.navigate("SelectReturnReason", { order, selectedItems });
    }, [navigation, order, selectedItems]);

    const onHelpPress = useCallback(() => {
        navigation.navigate("Account", {
            screen: "Help",
            params: { screen: "HelpDetailsLevelOne", params: { level1: "orders" } },
        });
    }, [navigation]);

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerShadowVisible: true,
            headerTitle: () => <HeaderTitle orderId={order.id} />,
            headerRight: () => (
                <SvgIcon
                    icon={"Question"}
                    height={20}
                    width={20}
                    color="color-basic-60"
                    onPress={onHelpPress}
                    style={styles.svg}
                />
            ),
        });
    }, [navigation, onHelpPress, order.id]);

    return (
        <Stack
            flex={1}
            style={[styles.root, { backgroundColor: theme["color-background-secondary"] }]}>
            <ReturnSelectCard
                order={order}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                onContinuePress={onContinuePress}
                isDisabled={isContinueDisabled}
            />
        </Stack>
    );
};

const styles = StyleSheet.create({
    root: {
        padding: SpacingValue["space-medium"],
    },
    card: {
        flexShrink: 1,
    },
    svg: {
        marginRight: SpacingValue["space-medium"],
    },
    btn: {
        marginTop: SpacingValue["space-x-small"],
    },
});
