import React from "react";
import { StyleSheet } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { CartItem } from "@minis-consumer/interfaces/cart";
import { countItemNudges } from "@minis-consumer/routes/cart/helpers";
import { TEST_IDS } from "@minis-consumer/routes/payment/constants";

export const ItemNudgeCount: React.FC<{ cartItems: CartItem[] }> = ({ cartItems }) => {
    const countItemNudge = countItemNudges(cartItems);
    const { value: theme } = useTheme();

    if (countItemNudge <= 1) {
        return null;
    }

    return (
        <Box
            testID={TEST_IDS.item_nudge_count}
            style={[
                styles.countNudge,
                {
                    backgroundColor: theme["color-basic-5"],
                },
            ]}>
            <Text category="b3" weight="medium" color="color-basic-60">
                {countItemNudge}
            </Text>
        </Box>
    );
};

const styles = StyleSheet.create({
    countNudge: {
        position: "absolute",
        borderRadius: 40,
        paddingVertical: SpacingValue["space-xxx-small"],
        paddingHorizontal: SpacingValue["space-x-small"] - SpacingValue["space-xxx-small"],
        top: -4,
        right: -4,
        zIndex: 2,
    },
});
