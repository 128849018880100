import React from "react";
import { useCartClearWithoutHardDelete } from "@minis-consumer/hooks/use-cart";
import { ConfirmDialog } from "@minis-consumer/components/confirm-dialog";

interface Props {
    showModal: boolean;
    closeModal: () => void;
    storeId: string;
}

export const ClearCartConfirmationDialog: React.FC<Props> = ({
    showModal,
    closeModal,
    storeId,
}) => {
    const clearCart = useCartClearWithoutHardDelete(storeId);

    const onPressContinue = React.useCallback(() => {
        clearCart();
        closeModal();
    }, [clearCart, closeModal]);

    return (
        <ConfirmDialog
            disableBackdropClose={true}
            open={showModal}
            onClose={closeModal}
            onPressCancel={closeModal}
            onPressContinue={onPressContinue}
            title="Clear cart?"
            description="Are you sure you want to clear your cart?"
            continueBtnText="Yes, clear"
            cancelBtnText="No, cancel"
        />
    );
};
