import * as React from "react";
import type {
    NativeStackNavigationProp,
    NativeStackScreenProps,
} from "@react-navigation/native-stack";
import { useNavigation } from "@react-navigation/native";

import { getUnixTime, startOfDay } from "date-fns";

import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";

import { ResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { getSlotFromDateAndTime } from "@minis-consumer/helpers/date";
import { useGetProductSlots } from "@minis-consumer/hooks/use-get-product-slots";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartViewData, useLocalCartItem } from "@minis-consumer/hooks/use-cart";
import { useGetPdpCtaNavigation } from "@minis-consumer/hooks/use-pdp-cta-navigation";
import { Product } from "@minis-consumer/interfaces/catalog";
import { CartItem, ServiceDeliverySlot } from "@minis-consumer/interfaces/cart";
import { Analytics } from "@minis-consumer/analytics";

import { SlotSelectionWrapper as ScheduleForm } from "./components/form-new/wrapper";
import { SlotSelectionForm } from "./components/form";
import { HeaderLeft } from "./components/header-left";
import { SlotSelectionScreenLoader } from "./components/loading";
import { LABELS } from "./constants";
import { generateSubHeaderText } from "./helpers";

type Props = NativeStackScreenProps<RouteList, "SlotSelection">;

type SlotSelectionProps = Props & {
    selectedSlot?: ServiceDeliverySlot;
    product: Partial<CartItem | Product> | null;
};

const PROCEED_CTA_EVENT = "book-prefered-slot";

export const SlotSelectionScreen: React.FC<SlotSelectionProps> = React.memo(
    ({
        navigation,
        route: {
            params: { id, isEditMode, variantId, isEnquireMode, isCustom },
        },
        selectedSlot,
        product,
    }) => {
        const storeNavigation = useNavigation<NativeStackNavigationProp<RouteList>>();
        const minisNavigation = useNavigation<NativeStackNavigationProp<MinisRouteList>>();

        const storeInfo = useStoreInfo();

        const productData = (product as CartItem).item ?? (product as Product);

        const productId = productData?.id ?? "";
        /** Ask backend to match params for cart items in cart contracts */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const productBadges =
            (product as Product)?.badges || (product as CartItem["item"])?.badgeIds || [];
        const isVariantsPresent = !!(
            (product as Product)?.variantDetails ||
            (product as CartItem["item"])?.variantOptionValues
        );
        const isFormPresent = !!(
            (product as Product)?.form?.length || (product as CartItem).itemForm?.length
        );
        const isEnquiryFormPresent = !!(product as Product)?.enquiryForm?.length;

        const selectedSlotInEpoch = selectedSlot?.start;

        const [, increment] = useLocalCartItem({
            storeId: storeInfo.storeId,
            itemId: id,
            productType: productData?.productType ?? "PHYSICAL",
        });

        const subHeaderText = generateSubHeaderText(productData);

        const {
            productSelectedData,
            action: slotNavigation,
            resetProductSelectedData,
        } = useGetPdpCtaNavigation({
            productId,
            minisNavigation: navigation,
            isCustom,
        }) || {};

        const cartViewData = useCartViewData(storeInfo.storeId);

        const analyticsContext = React.useMemo(() => {
            return {
                productId: id,
                productType: productData?.productType,
                price: productData?.price,
                discountedPrice: productData?.discountedPrice,
                isRecommended: productBadges?.includes("Recommended"),
                isItemAvailable: productData?.inStock,
                isVariantsPresent,
                isFormPresent,
                isEnquiryFormPresent,
                variantId,
                isEditMode,
                isEnquireMode,
                cartId: cartViewData?.id,
                billAmount: cartViewData?.bill?.billTotal,
            };
        }, [
            cartViewData,
            id,
            variantId,
            isEditMode,
            isEnquireMode,
            isVariantsPresent,
            isFormPresent,
            isEnquiryFormPresent,
            productData,
            productBadges,
        ]);

        const analyticsData = React.useMemo(() => {
            return {
                category: PROCEED_CTA_EVENT,
                context: JSON.stringify(analyticsContext),
            };
        }, [analyticsContext]);

        const onProceed = React.useCallback(
            (sDate: number, sTime: number, sText?: string) => {
                if (!sDate || !sTime) {
                    return;
                }

                const slotStartDateInEpoch = getSlotFromDateAndTime(sDate * 1000, sTime * 1000);

                const cartSlot = {
                    start: slotStartDateInEpoch,
                };

                slotNavigation?.({
                    isEditMode,
                    isEnquireMode,
                    slot: cartSlot,
                    productNavState: 1,
                    originalCallback: () => {
                        increment(productSelectedData?.variantId, { productData, slot: cartSlot });
                        resetProductSelectedData?.();

                        const storeRoutes = storeNavigation.getState().routeNames;

                        if (storeRoutes.indexOf("Home") > -1) {
                            storeNavigation.navigate("Home", {
                                screen: "Cart",
                                params: {},
                            });
                        } else {
                            minisNavigation.replace("MiniStore", {
                                slug: storeInfo.slug,
                                initialPath: "cart",
                            });
                        }
                    },
                    callbackBeforeNavigation: () => {
                        Analytics.clickEvent({
                            category: PROCEED_CTA_EVENT,
                            context: JSON.stringify({
                                ...analyticsContext,
                                slot: cartSlot,
                                selectedSlotText: sText,
                            }),
                        });
                    },
                });
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [
                productSelectedData,
                increment,
                slotNavigation,
                resetProductSelectedData,
                isEditMode,
                isEnquireMode,
                minisNavigation,
                analyticsData,
                analyticsContext,
            ],
        );

        /** API call to fetch slots */
        const {
            loading: isLoading,
            slotData,
            fetchProductSlotData,
        } = useGetProductSlots({
            storeId: storeInfo.storeId,
            productId: id,
            custom: isCustom,
            date: selectedSlotInEpoch || getUnixTime(startOfDay(new Date())),
        });

        useIsomorphicEffect(() => {
            navigation.setOptions({
                title: LABELS.HEADER,
                headerTitle: () => <></>,
                headerLeft: () => <HeaderLeft productId={id} subHeaderText={subHeaderText} />,
                headerShadowVisible: true,
            });
        }, [navigation, id, subHeaderText]);

        useMount(() => {
            fetchProductSlotData();
        });

        if (!product) {
            return null;
        }

        return (
            <ResetErrorBoundary>
                <StateContextContainer>
                    {isLoading ? (
                        <SlotSelectionScreenLoader />
                    ) : slotData?.dateSlots ? (
                        <ScheduleForm
                            onProceed={onProceed}
                            selectedSlotInEpoch={selectedSlotInEpoch}
                            slotData={slotData}
                            analyticsData={analyticsData}
                            isCustom={isCustom}
                            productId={id}
                            headerSubTitle={subHeaderText}
                        />
                    ) : (
                        <SlotSelectionForm
                            onProceed={onProceed}
                            selectedSlotInEpoch={selectedSlotInEpoch}
                            slotData={slotData}
                            analyticsData={analyticsData}
                            productId={productId}
                        />
                    )}
                </StateContextContainer>
            </ResetErrorBoundary>
        );
    },
);

if (__DEV__) {
    SlotSelectionScreen.displayName = "SlotSelectionScreen";
}
