import React, { memo, useCallback, useMemo } from "react";
import { Pressable, StyleSheet } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/core";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { OrderEntity } from "@minis-consumer/resources/order";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";
import { RouteList } from "@minis-consumer/interfaces/route";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";

import { DISPLAY_COPIES } from "./constants";
import { InitiateReturnDweb } from "./initiate-return.dweb";

export interface ReturnDetailsProps {
    order: OrderEntity;
    refreshPage?: VoidFunction;
}

const InitiateReturnComponent: React.FC<ReturnDetailsProps> = ({ order }) => {
    const { value: theme } = useTheme();
    const isDweb = useIsDesktopWeb();
    const navigation = useNavigation<NavigationProp<RouteList>>();

    const { createdAt: returnCreatedAt } = order.returnDetails?.order || {};

    const { allowed: isReturnAllowed, deadline: deadlineEpoch = 0 } =
        order.returnDetails?.settings || {};

    const returnStartedText = useMemo(() => {
        if (!returnCreatedAt) {
            return "";
        }
        const dateText = convertToReadableDateAndTime(returnCreatedAt);
        return `${DISPLAY_COPIES.REQUEST_RAISED} ${dateText}`;
    }, [returnCreatedAt]);

    const exchangeDeadlineText = useMemo(() => {
        const readableDeadline = convertToReadableDateAndTime(deadlineEpoch);
        return isReturnAllowed && deadlineEpoch
            ? `${DISPLAY_COPIES.POSSIBLE_TILL} ${readableDeadline}`
            : DISPLAY_COPIES.OUT_OF_TIME;
    }, [deadlineEpoch, isReturnAllowed]);

    const onChatWithUsPress = useCallback(
        () => navigation.navigate("ChatConversation", { storeId: order.storeId }),
        [navigation, order.storeId],
    );

    const onExchangePress = useCallback(() => {
        if (!isReturnAllowed) {
            return;
        }

        const screenToNavigate =
            order.items.length > 1 ? "SelectReturnItems" : "SelectReturnReason";

        navigation.navigate(screenToNavigate, { order });
    }, [isReturnAllowed, navigation, order]);

    if (!order.returnDetails || order.status !== "DELIVERED") {
        return null;
    }

    if (isDweb) {
        return <InitiateReturnDweb order={order} />;
    }

    return (
        <Stack style={[styles.root, { backgroundColor: theme["color-basic-0"] }]}>
            <Text category="b1" weight="bold">
                {DISPLAY_COPIES.MORE_HELP_TEXT}
            </Text>

            <DashBorderLine
                style={styles.borderLine}
                borderColor={theme["color-secondary-400"]}
                type="dotted"
            />

            <Pressable style={styles.linkContainer} onPress={onChatWithUsPress}>
                <Text category="b2" weight="medium">
                    {DISPLAY_COPIES.QUERIES}
                </Text>
                <SvgIcon icon="ChevronRight" />
            </Pressable>

            <Pressable
                style={[styles.linkContainer, isReturnAllowed ? null : styles.greyOut]}
                onPress={onExchangePress}>
                <Stack style={styles.linkLeftContainer}>
                    <Text category="b2" weight="medium">
                        {DISPLAY_COPIES.EXCHANGE}
                    </Text>
                    <Text category="b3">
                        {returnCreatedAt ? returnStartedText : exchangeDeadlineText}
                    </Text>
                </Stack>
                <SvgIcon icon="ChevronRight" />
            </Pressable>
        </Stack>
    );
};

export const InitiateReturn = memo(InitiateReturnComponent);

const styles = StyleSheet.create({
    root: {
        borderRadius: SpacingValue["space-medium"],
        padding: SpacingValue["space-medium"],
    },
    linkContainer: {
        marginBottom: SpacingValue["space-medium"],
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    borderLine: {
        marginTop: SpacingValue["space-medium"],
        marginVertical: SpacingValue["space-medium"],
        opacity: 0.15,
    },
    greyOut: {
        opacity: 0.5,
    },
    linkLeftContainer: { flexBasis: "90%" },
});
