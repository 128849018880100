import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import {
    Stack,
    useSelectScreen,
    Box,
    Display,
    ScreenSize,
} from "@swiggy-private/rn-adaptive-layout";
import { StarSellerBadge, useStarSeller } from "@swiggy-private/connect-business-commons";

import { Divider } from "@minis-consumer/components/divider";
import { ShopLogo } from "@minis-consumer/components/shop-logo";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { getStoreBrandImageUrl } from "@minis-consumer/helpers/store";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { StarSellerInfo } from "@minis-consumer/components/star-seller-info";
import { useStarSellerInfo } from "@minis-consumer/hooks/use-star-seller-info";

import { KebabMenu } from "../../../common/kebab-menu";

const LOGO_SIZE = 40;

const StoreInfoComponent: React.FC = () => {
    const storeInfo = useStoreInfo();
    const cartViewData = useCartViewData(storeInfo.storeId);

    const isStarSeller = useStarSeller(storeInfo.badges);

    const logoBorderSize = useSelectScreen({
        default: 10,
    });

    const logoUrl = React.useMemo(
        () => getStoreBrandImageUrl(storeInfo, { height: LOGO_SIZE }, true),
        [storeInfo],
    );

    const isProductTypePhysical = cartViewData?.cartType === "PHYSICAL" || !cartViewData?.cartType;

    const {
        showStarSellerInfo,
        onCloseStarSellerInfo,
        onStarSellerBadgeMount,
        onStarSellerBadgePress,
    } = useStarSellerInfo(storeInfo.storeId);

    return (
        <>
            <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                <ShopLogo
                    isImageKitEnabled
                    size={LOGO_SIZE}
                    url={logoUrl}
                    borderRadius={logoBorderSize}
                />
                <Stack flex={1} spacing={SpacingValue["space-xxx-small"]} justifyContent="center">
                    <Text category="btn2" numberOfLines={1}>
                        {storeInfo.name}
                    </Text>

                    {isStarSeller && isProductTypePhysical ? (
                        <>
                            <StarSellerBadge
                                showPrimaryText
                                showSecondaryText
                                outerGradientStyle={styles.badgeGradient}
                                rootContainerStyle={styles.badgeContainer}
                                onPress={onStarSellerBadgePress}
                                onMount={onStarSellerBadgeMount}
                            />
                            <StarSellerInfo
                                storeId={storeInfo.storeId}
                                showDialog={showStarSellerInfo}
                                closeDialog={onCloseStarSellerInfo}
                            />
                        </>
                    ) : isProductTypePhysical ? (
                        <Text category="body_B3_Med" color="color-basic-60">
                            {`${
                                cartViewData?.pickupAddress.locality
                                    ? cartViewData?.pickupAddress.locality + ", "
                                    : ""
                            }${cartViewData?.pickupAddress.city}`}
                        </Text>
                    ) : null}
                </Stack>

                <Display gt={ScreenSize.Large}>
                    <Box justifyContent="center" alignItems="flex-end">
                        <KebabMenu storeId={storeInfo.storeId} />
                    </Box>
                </Display>
            </Stack>

            <Divider style={styles.mt16} />
        </>
    );
};

const styles = StyleSheet.create({
    mt16: {
        marginTop: SpacingValue["space-medium"],
    },
    ph0: {
        paddingHorizontal: 0,
    },
    badgeGradient: {
        alignSelf: "flex-start",
        marginTop: SpacingValue["space-xxx-small"],
    },
    badgeContainer: {
        paddingLeft: 0,
    },
});

export const StoreInfo = React.memo(StoreInfoComponent);
