import * as React from "react";
import { LayoutChangeEvent, Platform, View, ViewStyle } from "react-native";

import { useSafeAreaInsets } from "react-native-safe-area-context";

import { BottomSheet, useKeyboardHeight } from "@swiggy-private/react-native-ui";
import { DialogContent, Overlay, SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { DialogBody } from "./components/dialog-body";

type PreCheckoutAddEmailDialog = {
    canShowDialog: boolean;
    onCloseDialog: VoidFunction;
    onPressContinue: VoidFunction;

    onLayout?: (e: LayoutChangeEvent) => void;
};

const PreCheckoutAddEmailDialogComponent: React.FC<PreCheckoutAddEmailDialog> = ({
    canShowDialog,
    onCloseDialog,
    onPressContinue,
    onLayout,
}) => {
    const insets = useSafeAreaInsets();
    const { value: theme } = useTheme();

    const modalMaxWidth = useSelectScreen({
        lg: 420 as ViewStyle["width"],
        default: "100%",
    });

    const backdropStyle = useSelectScreen({
        default: { backgroundColor: theme["color-basic-alpha-60"], flex: 1 },
    });

    const modalStyles = useSelectScreen({
        default: {
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            paddingBottom: Math.max(
                insets.bottom + SpacingValue["space-xx-small"],
                SpacingValue["space-large"],
            ),
        },

        lg: {
            borderRadius: 16,
            padding: SpacingValue["space-x-large"],
        },
    });

    const keyboardHeight = useKeyboardHeight();

    const onPress = React.useCallback(() => {
        onPressContinue();
        onCloseDialog();
    }, [onCloseDialog, onPressContinue]);

    const backdropComponent = React.useCallback(
        () => <Overlay style={backdropStyle} backgroundColor="secondary" onPress={onCloseDialog} />,
        [backdropStyle, onCloseDialog],
    );

    if (!canShowDialog) {
        return null;
    }

    return (
        <BottomSheet
            disableBackdropClose={false}
            open={canShowDialog}
            onClose={onCloseDialog}
            BackdropComponent={backdropComponent}
            style={[modalStyles, { width: modalMaxWidth }]}>
            <DialogContent
                onLayout={onLayout}
                style={{
                    paddingVertical: SpacingValue["space-x-large"],
                    paddingBottom:
                        Platform.OS === "android"
                            ? SpacingValue["space-x-small"]
                            : SpacingValue["space-x-large"],
                }}>
                <DialogBody onPressContinue={onPress} />
                {Platform.OS === "ios" ? (
                    <View style={[{ height: keyboardHeight - insets.bottom }]} />
                ) : null}
            </DialogContent>
        </BottomSheet>
    );
};

export const PreCheckoutAddEmailDialog = React.memo(PreCheckoutAddEmailDialogComponent);
