import { Store } from "@minis-consumer/interfaces/store";
import { formatToReadableString } from "@minis-consumer/routes/shop/helpers/perf-metrics";
import { getMediaUrl } from "@minis-consumer/helpers/media";

const LOGO_PREFIX = "static-assets/social-metrics-logos";
const GOOGLE_REVIEW_TYPE = "GOOGLE_REVIEW";
const DEFAULT_ICON = "StarFilled";

export const transformMetricsData = (
    metricsData: Store["socialMetrics"],
): Store["socialMetrics"] => {
    const transforedData: Store["socialMetrics"] = metricsData?.map((metric) => {
        return {
            ...metric,
            header:
                metric.type === "CUSTOM"
                    ? metric.header
                    : (formatToReadableString(metric.header) as string),
            logo: metric.logo
                ? metric.logo
                : getMediaUrl(`${LOGO_PREFIX}/${metric.type.toLocaleLowerCase()}`, undefined, true),
            icon: metric.type === GOOGLE_REVIEW_TYPE ? DEFAULT_ICON : undefined,
        };
    });

    /** Order on the basis of priority */
    transforedData?.sort((a, b) => a.priority - b.priority);

    return transforedData;
};
