import React, { useMemo } from "react";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import type { CartStatuses } from "@minis-consumer/interfaces/cart";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { useCustomProductEnquiryChat } from "@minis-consumer/hooks/use-custom-product-enquiry-chat";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { OverlayLoader } from "@minis-consumer/components/overlay-loader";
import { RouteList } from "@minis-consumer/interfaces/route";
import { Analytics } from "@minis-consumer/analytics";

import { getCustomItems, isCustomItemOlderThanOneDay } from "../../helpers";
import { Alert, AlertProps } from "../common/alert";
import { IS_NATIVE, COPIES, EVENT_NAMES } from "./constants";

interface Props {
    cartStatus: CartStatuses;
    storeId: string;

    checkIsChatBlocked?: (blockedInfo: SdkConversation["blockedInfo"]) => boolean;
}

const CartAlertComponent: React.FC<Props> = ({ cartStatus, storeId, checkIsChatBlocked }) => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();

    const cartViewData = useCartViewData(storeId);

    const [sendingEnquiryMessage, setSendingEnquiryMessage] = React.useState(false);

    const [showToast] = useToast();

    const { sendEnquiryMessage, blockedInfo } = useCustomProductEnquiryChat(storeId);

    const oldCustomItems = React.useMemo(() => {
        const customItems = getCustomItems(cartViewData?.cartItems ?? []);
        if (!customItems.length) {
            return [];
        }

        const oldItems = customItems.filter((data) => {
            return isCustomItemOlderThanOneDay(data.item.createdAt);
        });

        return oldItems;
    }, [cartViewData?.cartItems]);

    const analyticsContext = useMemo(() => {
        return JSON.stringify({
            cartId: cartViewData?.id,
            cartType: cartViewData?.cartType,
            billAmount: cartViewData?.bill.billTotal,
        });
    }, [cartViewData]);

    const onPressChatToConfirm = React.useCallback(() => {
        Analytics.clickEvent({
            category: EVENT_NAMES.CHAT_TO_CONFIRM,
            context: analyticsContext,
        });

        if (checkIsChatBlocked && !checkIsChatBlocked(blockedInfo)) {
            IS_NATIVE && navigation.push("ChatConversation", { storeId });
            return;
        }

        setSendingEnquiryMessage(true);

        sendEnquiryMessage({
            text: COPIES.ENQUIRY_MESSAGE,
            customProductList: oldCustomItems.map((item) => item.item.name),
        });

        setSendingEnquiryMessage(false);

        if (IS_NATIVE) {
            navigation.push("ChatConversation", { storeId });
            return;
        }

        showToast(COPIES.ENQUIRY_SUCCESS_MESSAGE);
    }, [
        sendEnquiryMessage,
        oldCustomItems,
        navigation,
        storeId,
        showToast,
        blockedInfo,
        checkIsChatBlocked,
        analyticsContext,
    ]);

    const alertProps = React.useMemo<AlertProps>(() => {
        let data: AlertProps = {
            type: "NONE",
        };

        switch (cartStatus) {
            case "ITEMS_NOT_FOUND_IN_CATALOGUE":
            case "ITEMS_INACTIVE":
            case "ITEMS_OUT_OF_STOCK":
                data = {
                    type: "CRITICAL",
                    text: "Some item(s) in your cart are unavailable.\nPlease remove them to continue placing orders.",
                };
                break;
            case "STORE_INACTIVE":
                data = {
                    type: "CRITICAL",
                    text: "Whooops! This Ministore isn't available anymore.\nIt has been permenantly closed by the seller. ",
                };
                break;
            case "STORE_OFFLINE":
            case "STORE_DELIVERY_CONFIG_NOT_FOUND":
            case "STORE_MOBILE_NUMBER_NOT_FOUND":
            case "STORE_ADDRESS_NOT_FOUND":
                data = {
                    type: "CRITICAL",
                    text: "This store is not accepting orders at the moment.\nPlease check back later.",
                };
                break;

            case "STORE_BLOCKED":
                data = {
                    type: "CRITICAL",
                    text: "This store is temporarily blocked and is no longer accepting orders",
                };
                break;

            case "GUEST_CART":
            case "USER_ADDRESS_NOT_SELECTED":
            case "NO_USER_ADDRESS_FOUND":
            case "SELECTED_ADDRESS_UNSERVICEABLE":
            case "INVALID_USER_ADDRESS":
            case "VALID_CART":
                data = {
                    type: "NONE",
                };
                break;
            case "ITEM_SLOT_NOT_SELECTED":
            case "ITEM_SLOT_EXPIRED":
            case "ITEM_SLOT_TOO_FAR":
            case "ITEM_FORM_BLANK":
            case "ITEM_FORM_INCOMPLETE":
                data = {
                    type: "CRITICAL",
                    text: "",
                };
                break;
            case "SELLER_PAYMENT_NOT_CONFIGURED":
                data = {
                    type: "CRITICAL",
                    text: `Please ask ${
                        cartViewData?.store.name ?? "seller"
                    } to set up their payment details.`,
                    ctaText: "Chat to confirm",
                    ctaCallback: onPressChatToConfirm,
                };

                break;
            case "ITEM_SLOT_UNAVAILABLE":
                break;
            default:
                data = {
                    type: "CRITICAL",
                    text: "Oops! something went wrong. Please check back later.",
                };
        }

        /* In case of no critical errors check for custom items warning */
        if (data.type === "NONE" && oldCustomItems.length) {
            Analytics.impressionEvent({
                category: EVENT_NAMES.CHAT_TO_CONFIRM,
                context: analyticsContext,
            });

            data = {
                type: "WARNING",
                text: "Please confirm the availability of custom products before proceeding.",
                ctaText: "Chat to confirm",
                ctaCallback: onPressChatToConfirm,
            };
        }

        return data;
    }, [
        cartStatus,
        oldCustomItems.length,
        cartViewData?.store.name,
        onPressChatToConfirm,
        analyticsContext,
    ]);

    if (alertProps.type === "NONE" || !alertProps.text) {
        return null;
    }

    return (
        <Box>
            <Alert {...alertProps} />
            <OverlayLoader visible={sendingEnquiryMessage} />
        </Box>
    );
};

export const CartAlert = React.memo(CartAlertComponent);
