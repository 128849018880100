import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { InView } from "@swiggy-private/react-native-ui";

import { Product } from "@minis-consumer/interfaces/catalog";
import { Divider } from "@minis-consumer/components/divider";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";
import { Analytics } from "@minis-consumer/analytics";

import { RenderHTMLDescription } from "../render-html-description";
import { ANALYTICS_EVENT, TITLE } from "./constants";

interface ProductDescriptionProps {
    description: Product["description"];
    productId: string;
    storeId: string;
}

const ProductDescriptionComponent: React.FC<ProductDescriptionProps> = ({
    description,
    storeId,
    productId,
}) => {
    const analyticsContextData = React.useMemo(
        () =>
            JSON.stringify({
                storeId,
                productId,
            }),
        [storeId, productId],
    );

    const isDesktop = useIsDesktop();

    const dividerStyle = {
        marginTop: SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-large"],
    };

    const [showDescription, setShowDescription] = React.useState(true);

    const toggleDescription = React.useCallback(() => {
        if (isDesktop) {
            return;
        }

        Analytics.clickEvent({
            category: ANALYTICS_EVENT,
            context: analyticsContextData,
        });

        setShowDescription(!showDescription);
    }, [showDescription, analyticsContextData, isDesktop]);

    const onImpression = React.useCallback(
        (isVisible: boolean) => {
            isVisible &&
                Analytics.impressionEvent({
                    category: ANALYTICS_EVENT,
                    context: analyticsContextData,
                });
        },
        [analyticsContextData],
    );

    if (!description) {
        return null;
    }

    return (
        <InView onChange={onImpression}>
            <Pressable onPress={toggleDescription} hitSlop={16}>
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                    style={styles.headerContainer}>
                    <Text category="subheader_S1_Bold" weight="bold" color="high">
                        {TITLE}
                    </Text>

                    {!isDesktop ? (
                        <Box style={styles.caret}>
                            <SvgIcon
                                icon={showDescription ? "ChevronUp" : "ChevronDown"}
                                color="color-basic-75"
                                width={20}
                            />
                        </Box>
                    ) : null}
                </Stack>
            </Pressable>

            {showDescription ? (
                <Box mr={SpacingValue["space-medium"] * 2} mt={-SpacingValue["space-x-small"]}>
                    <RenderHTMLDescription description={description} />
                </Box>
            ) : null}

            {!isDesktop ? <Divider style={dividerStyle} /> : null}
        </InView>
    );
};

export const ProductDescription = React.memo(ProductDescriptionComponent);

const styles = StyleSheet.create({
    caret: {
        width: 24,
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    headerContainer: {
        flex: 1,
    },
});
