import React from "react";
import {
    FlatList,
    ListRenderItem,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Platform,
    StyleSheet,
    useWindowDimensions,
} from "react-native";

import { SpacingValue, Surface, useTheme } from "@swiggy-private/rn-dls";
import {
    ScreenSize,
    Stack,
    useScreenSize,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";
import { InView } from "@swiggy-private/react-native-ui";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Coupon } from "@minis-consumer/interfaces/coupon";
import { Analytics } from "@minis-consumer/analytics";

import { CouponCard } from "./components/coupon";
import { ShopfrontCouponsSlider } from "./components/slide-indicator";
import { getStorefrontCouponCardWidth } from "../../helpers";

const ICON_SIZE = 48;
const PAGINATION_BOX_SIZE = 30;

const ShopFrontCouponsComponent: React.FC = () => {
    const store = useStoreInfo();
    const { value: theme } = useTheme();

    const flatListRef = React.useRef<FlatList>(null);
    const [slideIndex, setSlideIndex] = React.useState(0);
    const [mounted, setMounted] = React.useState(false);

    const { width } = useWindowDimensions();
    const screenSize = useScreenSize();
    const containerWidth = screenSize === ScreenSize.Large ? 632 : width;

    const margin = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });
    const cardWidth = React.useMemo(
        () => getStorefrontCouponCardWidth(containerWidth, margin, PAGINATION_BOX_SIZE),
        [containerWidth, margin],
    );

    /** For initial impression and on slide change */
    const fireImpressionEvent = React.useCallback(
        (code: string) => {
            Analytics.impressionEvent({
                category: "coupon-carousel",
                label: `storeId: ${store.storeId}`,
                context: `couponCode: ${code}`,
            });
        },
        [store.storeId],
    );

    /** Coupon card render */
    const renderItem: ListRenderItem<Coupon> = React.useCallback(
        ({ item }) => {
            const onImpression = (isVisible: boolean): void => {
                isVisible && fireImpressionEvent(item.code);
            };

            return (
                <InView onChange={onImpression}>
                    <CouponCard coupon={item} cardWidth={cardWidth} iconSize={ICON_SIZE} />
                </InView>
            );
        },
        [cardWidth, fireImpressionEvent],
    );

    const slideToIndex = React.useCallback(
        (index: number) => {
            flatListRef?.current?.scrollToOffset({
                animated: true,
                offset: index * cardWidth,
            });
        },
        [flatListRef, cardWidth],
    );

    /** Automatically change coupon slides if there are more than 1 coupons */
    React.useEffect(() => {
        setMounted(true);
        if (
            !flatListRef?.current ||
            !store?.coupons ||
            store.coupons?.length < 2 ||
            (Platform.OS === "web" && !mounted)
        ) {
            return;
        }

        const totalCoupons = store.coupons?.length - 1;

        const interval = setInterval(() => {
            const indexToSlide = slideIndex === totalCoupons ? 0 : slideIndex + 1;

            setSlideIndex(indexToSlide);
            slideToIndex(indexToSlide);
        }, 3_000);

        return () => {
            interval && clearInterval(interval);
        };
    }, [flatListRef, slideIndex, cardWidth, store?.coupons, slideToIndex, mounted]);

    /** Handle auto scroll with manual slide change */
    const updateCurrentSlideIndex = React.useCallback(
        (e: NativeSyntheticEvent<NativeScrollEvent>): void => {
            const contentOffsetX = e.nativeEvent.contentOffset.x;
            const currentIndex = Math.round(contentOffsetX / cardWidth);

            setSlideIndex(currentIndex);
            slideToIndex(currentIndex);
        },
        [cardWidth, slideToIndex],
    );

    /** Initial impression for all coupons */
    useMount(() => {
        store.coupons?.length &&
            store.coupons.forEach((item) => {
                fireImpressionEvent(item.code);
            });
    });

    if (!store.coupons?.length || (Platform.OS === "web" && !mounted)) {
        return null;
    }

    return (
        <Surface
            style={[
                {
                    margin,
                    borderColor: theme["color-basic-15"],
                },
                styles.container,
            ]}>
            <Stack
                justifyContent="space-between"
                direction="row"
                spacing={SpacingValue["space-x-small"]}
                flex={1}>
                <FlatList
                    ref={flatListRef}
                    pagingEnabled
                    horizontal
                    bounces={false}
                    data={store.coupons}
                    renderItem={renderItem}
                    keyExtractor={(item: Coupon) => item.code}
                    showsHorizontalScrollIndicator={false}
                    onMomentumScrollEnd={updateCurrentSlideIndex}
                    scrollEnabled={Platform.OS !== "web"}
                />

                <ShopfrontCouponsSlider
                    pageIconSize={PAGINATION_BOX_SIZE}
                    couponsLength={store.coupons.length}
                    slideIndex={slideIndex}
                />
            </Stack>
        </Surface>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingVertical: SpacingValue["space-small"],
        paddingRight: SpacingValue["space-medium"],
        borderWidth: 1,
        borderRadius: 20,
        elevation: 2,
        flexDirection: "row",
        marginTop: 0,
    },
});

export const ShopFrontCoupons = React.memo(ShopFrontCouponsComponent);
