import * as React from "react";
import { Pressable, StyleSheet } from "react-native";

import { Portal, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import {
    GOOGLE_MEET_ICON,
    MEETING_LINK_COPY_TEXT,
    ANALYTICS_COPIES,
    MEET_JOIN_BUTTON_TEXT,
} from "@minis-consumer/routes/order/components/order-details/constants";
import { useCopyToClipboard } from "@minis-consumer/hooks/use-copy";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { Analytics } from "@minis-consumer/analytics";

import { LinkCopySnackbar } from "./link-copy-snackbar";

interface OrderAddressGistProps {
    appointmentLink: string;
    orderId: string;
}

const GoogleMeetDetailsComponent: React.FC<OrderAddressGistProps> = ({
    appointmentLink,
    orderId,
}) => {
    const { value: theme } = useTheme();
    const copyLink = useCopyToClipboard();
    const openUrl = useLinking();

    const meetIcon = GOOGLE_MEET_ICON;
    const formattedUrl = appointmentLink.replace("https://", "");

    const [canShowSnackbar, setShowSnackbar] = React.useState(false);

    const analyticsData = React.useMemo(() => {
        return {
            category: ANALYTICS_COPIES.CATEGORY,
            screen: ANALYTICS_COPIES.SCREEN,
            label: orderId,
        };
    }, [orderId]);

    const copyToClipboard = React.useCallback(() => {
        Analytics.clickEvent({
            ...analyticsData,
            context: JSON.stringify({
                orderId: orderId,
                action: "copy",
            }),
        });

        copyLink({
            message: appointmentLink,
            toastDuration: 0,
        });
        setShowSnackbar(true);
    }, [analyticsData, appointmentLink, copyLink, orderId]);

    const onJoinPress = React.useCallback(() => {
        Analytics.clickEvent({
            ...analyticsData,
            context: JSON.stringify({
                orderId: orderId,
                action: "join",
            }),
        });

        openUrl(appointmentLink);
    }, [analyticsData, appointmentLink, openUrl, orderId]);

    const textColor = {
        color: theme.text_Button_Secondary,
        textDecorationColor: theme.text_Button_Secondary,
    };

    const whiteBackground = {
        backgroundColor: theme["color-basic-0"],
    };

    return (
        <Box
            justifyContent="center"
            mt={SpacingValue["space-medium"]}
            pv={SpacingValue["space-medium"]}
            ph={SpacingValue["space-medium"]}
            style={[styles.msgContainer, { backgroundColor: theme.background_Secondary }]}>
            <Stack direction="row" alignItems="center" spacing={SpacingValue["space-small"]}>
                <CdnImage
                    id={meetIcon}
                    width={40}
                    height={40}
                    style={[styles.meetingIcon, whiteBackground]}
                />
                <Pressable onPress={onJoinPress} style={styles.meetingLinkContainer}>
                    <Text
                        numberOfLines={2}
                        style={[styles.meetingLink, textColor]}
                        category="b2"
                        color="color-basic-60"
                        weight="bold">
                        {formattedUrl}
                    </Text>
                </Pressable>
                <Pressable
                    onPress={copyToClipboard}
                    style={[styles.meetingIcon, styles.copyIcon, whiteBackground]}>
                    <SvgIcon icon={"CopyOutline"} width={16} height={16} color="color-basic-60" />
                </Pressable>
                <Pressable onPress={onJoinPress} style={[styles.meetingIcon, whiteBackground]}>
                    <Text category="b2" weight="bold" color="text_Button_Secondary">
                        {MEET_JOIN_BUTTON_TEXT}
                    </Text>
                </Pressable>
            </Stack>

            <Portal>
                <LinkCopySnackbar
                    canShowSnackbar={canShowSnackbar}
                    dismissSnackbar={() => setShowSnackbar(false)}
                    text={MEETING_LINK_COPY_TEXT}
                />
            </Portal>
        </Box>
    );
};

const styles = StyleSheet.create({
    msgContainer: {
        borderRadius: 12,
    },
    copyIcon: {
        paddingVertical: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-small"],
    },
    meetingIcon: {
        borderRadius: SpacingValue["space-small"],
        paddingVertical: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    meetingLinkContainer: {
        flex: 1,
    },
    meetingLink: {
        textDecorationLine: "underline",
    },
});

export const GoogleMeetDetails = React.memo(GoogleMeetDetailsComponent);
