import React, { useContext, useEffect } from "react";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

import { Cart, UserAddress } from "@minis-consumer/interfaces/cart";
import { RouteList } from "@minis-consumer/interfaces/route";
import {
    AddressStorageType,
    getAddressStorageMeta,
    setAddressStorageMeta,
} from "@minis-consumer/helpers/address";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { useCartStatus, useCartViewData } from "@minis-consumer/hooks/use-cart";
import { CartAddressState } from "@minis-consumer/interfaces/cart";
import { Analytics } from "@minis-consumer/analytics";
import { SelectedAddressInfoContext } from "@minis-consumer/contexts/selected-address-context";

import { CardItem } from "../card-item";
import { AddAddressCardItem } from "../add-address-card-item";

interface AddressListProps {
    addresses: NonNullable<Cart["cartViewData"]>["addresses"];
    setAddressSelected: (address: UserAddress | null) => void;
    setAddressIdSelected: (s: string) => void;
    setAddressState: (s: CartAddressState) => void;
    addressIdSelected: string;
    addressChosenAlready?: UserAddress | null;
    isOpen?: boolean;
    setOpen?: (b: boolean) => void;
    showAddressInModal?: boolean;
    shouldShowUnserviceable?: boolean;
}

const ADD_ADDRESS_BTN_ANALYTICS_DATA = "add-address-btn";

export const AddressList: React.FC<AddressListProps> = ({
    addresses,
    setAddressSelected,
    addressChosenAlready,
    setOpen,
    showAddressInModal,
    setAddressIdSelected,
    setAddressState,
    addressIdSelected,
    shouldShowUnserviceable,
}) => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const store = useStoreInfo();
    const [showToast] = useToast();
    const cartStatus = useCartStatus(store.storeId);
    const cartView = useCartViewData(store.storeId);

    const [openMissingFieldModal, setOpenMissingFieldModal] = React.useState(false);
    const [showPincode, setShowPincode] = React.useState(false);
    const [showCity, setShowCity] = React.useState(false);
    const [showState, setShowState] = React.useState(false);
    const selectedAddressCtx = useContext(SelectedAddressInfoContext);

    const onAddNewAddressPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: ADD_ADDRESS_BTN_ANALYTICS_DATA,
            label: cartView?.id,
        });

        navigation.navigate("Address", {
            screen: "SearchLocation",
            params: {
                storeId: store.storeId,
            },
        });
        showAddressInModal ? setOpen?.(false) : null;
    }, [cartView?.id, navigation, store.storeId, showAddressInModal, setOpen]);

    const setAddressFromStorage = React.useCallback(
        (selectedAddress: UserAddress, storage: { addresses: AddressStorageType } | null) => {
            const addressinStorage = storage?.addresses[Number(selectedAddress.id)];

            if (!addressinStorage) {
                return false;
            }

            setAddressSelected({
                ...selectedAddress,
                ...addressinStorage,
                postalCode: Number(addressinStorage.postalCode),
            });

            return true;
        },
        [setAddressSelected],
    );

    const onAddressSelected = React.useCallback(
        async (address: UserAddress) => {
            showAddressInModal ? setOpen?.(false) : null;
            setAddressIdSelected(address.id);

            const storageAddressDetails = await getAddressStorageMeta();

            const addressSet = setAddressFromStorage(address, storageAddressDetails);

            if (!addressSet && (!address.city || !address.postalCode || !address.state)) {
                !address.city && setShowCity(true);
                !address.postalCode && setShowPincode(true);
                !address.state && setShowState(true);

                setOpenMissingFieldModal(true);
            } else {
                !addressSet && setAddressSelected(address);
                setAddressState("SELECTED_SUCCESS");

                selectedAddressCtx?.updateSelectedAddressInfo?.({
                    ...selectedAddressCtx.selectedAddressInfo,
                    lng: address?.lng,
                    lat: address?.lat,
                    postalCode: address?.postalCode,
                    addressId: address?.id,
                    addressLabel: address?.displayName,
                });
            }
        },
        [
            selectedAddressCtx,
            setAddressFromStorage,
            setAddressIdSelected,
            setAddressSelected,
            setAddressState,
            setOpen,
            showAddressInModal,
        ],
    );

    const addressSelectedData = React.useMemo(() => {
        const data = addresses?.filter((adrs) => adrs?.id === addressIdSelected);
        return data?.[0];
    }, [addressIdSelected, addresses]);

    const onModalCtaPress = React.useCallback(
        (args: { pincode: string; city: string; state: string }) => {
            const { pincode, city, state } = args;

            const commonPayload = {
                postalCode: pincode.length ? Number(pincode) : addressSelectedData.postalCode,
                city: city.length ? city : addressSelectedData.city,
                state: state.length ? state : addressSelectedData.state,
            };

            setAddressStorageMeta({
                [addressSelectedData.id]: commonPayload,
            });

            setAddressSelected({
                ...addressSelectedData,
                ...commonPayload,
            });

            setOpenMissingFieldModal(false);
            setAddressState("SELECTED_SUCCESS");
        },
        [addressSelectedData, setAddressSelected, setAddressState],
    );

    useMount(() => {
        // TODO: Drive all cartStatus from constant
        if (cartStatus === "SELECTED_ADDRESS_UNSERVICEABLE") {
            showToast("Your new address is unserviceable");
            setAddressIdSelected("");
            setAddressState("UNSELECTED");
        }
    });

    useEffect(() => {
        setAddressIdSelected(addressChosenAlready?.id ?? "");
        setAddressState(addressChosenAlready?.id ? "SELECTED_SUCCESS" : "UNSELECTED");
    }, [addressChosenAlready, setAddressIdSelected, setAddressState]);

    return (
        <>
            <Stack direction="row" style={styles.addressItemContainer}>
                {addresses.length
                    ? addresses.map((address, index) => (
                          <Box
                              key={address.id}
                              style={[
                                  styles.addressItemWrapper,
                                  index <= 1 ? styles.mtO : null,
                                  index % 2 === 0 ? styles.prXSmall : styles.plXSmall,
                              ]}>
                              <CardItem
                                  address={address}
                                  setAddressSelected={() => onAddressSelected(address)}
                                  openMissingFieldModal={openMissingFieldModal}
                                  setOpenMissingFieldModal={setOpenMissingFieldModal}
                                  showPincode={showPincode}
                                  showCity={showCity}
                                  showState={showState}
                                  addressIdSelected={addressIdSelected}
                                  onModalCtaPress={onModalCtaPress}
                                  shouldShowUnserviceable={shouldShowUnserviceable}
                              />
                          </Box>
                      ))
                    : null}

                <Box
                    style={[
                        styles.addressItemWrapper,
                        addresses.length <= 1 ? styles.mtO : null,
                        addresses.length % 2 === 0 ? styles.prXSmall : styles.plXSmall,
                    ]}>
                    <AddAddressCardItem
                        onAddNewAddressPress={onAddNewAddressPress}
                        cartId={cartView?.id}
                    />
                </Box>
            </Stack>
        </>
    );
};

const styles = StyleSheet.create({
    addressItemContainer: {
        flexWrap: "wrap",
    },
    addressItemWrapper: {
        width: "50%",
        marginTop: SpacingValue["space-medium"],
    },
    plXSmall: {
        paddingLeft: SpacingValue["space-x-small"],
    },
    prXSmall: {
        paddingRight: SpacingValue["space-x-small"],
    },
    mtO: {
        marginTop: 0,
    },
});
