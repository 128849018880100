import * as React from "react";
import { useWindowDimensions, StyleSheet } from "react-native";

import { CdnImage } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Dialog, DialogContent } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

type EnquireDialogProps = {
    imgUrl: string;
    open: boolean;
    setOpen: (b: boolean) => void;
    onMakeAnEnquiry: () => void;
};

export const EnquireDialog: React.FC<EnquireDialogProps> = ({
    imgUrl,
    open,
    setOpen,
    onMakeAnEnquiry,
}) => {
    const { width: screenWidth } = useWindowDimensions();

    const imgWidth = Math.min(screenWidth * 0.84, 304);
    const imgHeight = imgWidth * 0.75;

    const closeDialog = React.useCallback(() => setOpen(false), [setOpen]);

    if (!open) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            style={styles.dialog}
            fullWidth
            verticalAlignDialog="center">
            <DialogContent>
                <Stack spacing={SpacingValue["space-medium"]}>
                    <Stack>
                        <CdnImage
                            isImageKitEnabled
                            id={imgUrl}
                            width={imgWidth}
                            height={imgHeight}
                            style={styles.img}
                        />

                        <Box style={styles.svg}>
                            <SvgIcon
                                icon="CrossFilled"
                                height={32}
                                width={32}
                                onPress={closeDialog}
                            />
                        </Box>
                    </Stack>

                    <Button onPress={onMakeAnEnquiry}>Make an enquiry</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

const styles = StyleSheet.create({
    dialog: {
        borderRadius: 12,
    },
    img: {
        borderRadius: 12,
        zIndex: 900,
    },
    svg: {
        zIndex: 1000,
        position: "absolute",
        left: "100%",
        bottom: "100%",
    },
});
