import React, { useMemo } from "react";
import { Platform, Pressable, StyleSheet, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { useMount } from "@swiggy-private/react-hooks";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Surface, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { useIsMobileWeb } from "@minis-consumer/hooks/use-mweb";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Bill } from "@minis-consumer/routes/cart/components/bill";

import { FOOTER_PAYMENT_ANALYTICS, PAYMENT_FOOTER_SECTION, TEST_IDS } from "../../constants";
import { useFooterPayment } from "../../hooks/use-footer-payment";
import { usePaymentSelector } from "../../hooks/use-selector";
import { BottomSheetWrapper } from "../bottom-sheet";
import { DWebWrapper } from "./web-layout-wrapper";

const PaymentFixedFooter: React.FC = () => {
    const { value: theme } = useTheme();
    const storeInfo = useStoreInfo();

    const isMweb = useIsMobileWeb();
    const cart = useCartViewData(storeInfo?.storeId);
    const orderSelector = usePaymentSelector((state) => state.order);

    const analyticsContext = useMemo(() => {
        return JSON.stringify({
            orderId: orderSelector?.id || "-",
            cartId: cart?.id || "-",
            productType: cart?.cartType || orderSelector?.orderType,
            paymentAmount: cart?.bill?.finalBillTotal || orderSelector?.bill?.finalBillTotal,
            transactionId: orderSelector?.txnId,
        });
    }, [orderSelector, cart]);

    const [showDetailedBill, setShowDetailedBill] = React.useState(false);
    const { isPaymentValid, payNow } = useFooterPayment(cart, orderSelector);
    const insets = useSafeAreaInsets();

    const getFormattedNumber = React.useCallback(
        (num: number) => formatNumberWithIndianSystem(num, { showRupeeSymbol: true }),
        [],
    );

    const formattedBillTotal = React.useMemo(
        () => getFormattedNumber(cart?.bill?.billTotal ?? orderSelector?.bill?.billTotal ?? 0),
        [cart?.bill?.billTotal, getFormattedNumber, orderSelector?.bill?.billTotal],
    );

    const toggleDetailedBill = React.useCallback(() => {
        Analytics.clickEvent({
            category: FOOTER_PAYMENT_ANALYTICS[`${showDetailedBill ? "HIDE_BILL" : "SHOW_BILL"}`],
            label: orderSelector?.id,
            context: analyticsContext,
        });
        setShowDetailedBill(!showDetailedBill);
    }, [orderSelector?.id, showDetailedBill, analyticsContext]);

    const closeDetailedBill = React.useCallback(() => {
        setShowDetailedBill(false);
    }, []);

    const surfaceStyle = React.useMemo(
        () => ({
            ...Platform.select({
                ios: { elevation: 4 },
                web: { elevation: 5 },
                android: { elevation: 8 },
            }),
            zIndex: 2,
        }),
        [],
    );

    useMount(() => {
        Analytics.impressionEvent({
            category: FOOTER_PAYMENT_ANALYTICS.SHOW_BILL,
            label: orderSelector?.id,
            context: analyticsContext,
        });
    });

    const bottomPaddingInsets = insets.bottom + SpacingValue["space-x-large"];

    const contentContainerStyle: ViewStyle | undefined = Platform.select({
        ios: {
            paddingBottom: bottomPaddingInsets * 1.5,
        },
        web: {
            paddingBottom: isMweb ? bottomPaddingInsets * 4 : 0,
        },
        android: {
            paddingBottom: bottomPaddingInsets * 4,
        },
    });

    const FixedFooter = React.useCallback((): JSX.Element => {
        return (
            <Surface style={surfaceStyle}>
                <Stack
                    direction={"row"}
                    spacing={SpacingValue["space-small"]}
                    style={[
                        styles.container,
                        {
                            backgroundColor: theme["color-surface-primary"],
                        },
                    ]}>
                    <Stack
                        direction={"column"}
                        style={styles.lhsContainer}
                        spacing={SpacingValue["space-xx-small"]}>
                        <Text category="h5" weight="bold" color="color-basic-75">
                            {formattedBillTotal}
                        </Text>
                        <Pressable onPress={toggleDetailedBill} hitSlop={{ top: 12, bottom: 12 }}>
                            <Text
                                category="btn4"
                                weight="bold"
                                color="color-primary-400"
                                suppressHighlighting>
                                {
                                    PAYMENT_FOOTER_SECTION[
                                        showDetailedBill
                                            ? "hide_detailed_bill"
                                            : "view_detailed_bill"
                                    ]
                                }
                            </Text>
                        </Pressable>
                    </Stack>
                    <Stack style={styles.rhsContainer}>
                        <Button
                            disabled={!isPaymentValid}
                            testID={TEST_IDS.pay_now}
                            onPress={payNow}
                            style={styles.paymentButton}>
                            {PAYMENT_FOOTER_SECTION.pay_now}
                        </Button>
                    </Stack>
                </Stack>
            </Surface>
        );
    }, [
        formattedBillTotal,
        isPaymentValid,
        payNow,
        showDetailedBill,
        surfaceStyle,
        theme,
        toggleDetailedBill,
    ]);

    return (
        <>
            <DWebWrapper nativeChildren={<FixedFooter />}>
                <FixedFooter />
            </DWebWrapper>

            <BottomSheetWrapper
                open={showDetailedBill}
                contentContainerStyle={contentContainerStyle}
                usePortal={false}
                onClose={closeDetailedBill}
                style={[styles.dialog]}>
                <Bill storeId={storeInfo?.storeId} shouldRenderFooterPayment />
            </BottomSheetWrapper>
        </>
    );
};

export const PaymentFooter = React.memo(PaymentFixedFooter);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        ...StyleSheet.absoluteFillObject,
        top: "auto",
        width: "100%",
        zIndex: 2,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        paddingVertical: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    lhsContainer: {
        width: "38%",
    },
    rhsContainer: {
        width: "58%",
    },
    paymentButton: {
        width: "100%",
    },
    surface: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    dialog: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        zIndex: 1,
    },
});
