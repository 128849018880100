import React from "react";
import { Platform } from "react-native";
import { useErrorHandler } from "react-error-boundary";

import { ActivityIndicator } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

import { WebView } from "@minis-consumer/components/webview";
import { TERMS_AND_CONDITIONS_URL } from "@minis-consumer/constants";
import { Analytics } from "@minis-consumer/analytics";
import { withResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";

export const TermsAndConditionsScreen: React.FC = () => {
    const errorHandler = useErrorHandler();

    useMount(() => {
        Analytics.impressionEvent({ category: "account-terms-and-conditions" });
    });

    return (
        <Box flex={1}>
            {Platform.OS === "web" ? (
                <iframe src={TERMS_AND_CONDITIONS_URL} height="100%" width="100%" />
            ) : (
                <WebView
                    source={{
                        uri: TERMS_AND_CONDITIONS_URL,
                    }}
                    startInLoadingState
                    onHttpError={(event) => errorHandler(new Error(event.nativeEvent.description))}
                    onError={(event) => errorHandler(new Error(event.nativeEvent.description))}
                    renderLoading={() => <ActivityIndicator />}
                />
            )}
        </Box>
    );
};

export const TermsAndConditions = withResetErrorBoundary(TermsAndConditionsScreen);
