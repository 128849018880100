import { OrderCardCtaType } from "../../helper";

export const ANALYTICS_COPIES = {
    PROFILE_CARD: "customer-profile-card",
    PROFILE_CARD_SAVED_ADDRESSED_CTA: "customer-profile-card-saved-addresses-btn",
    PROFILE_CARD_LOVED_MINIS_CTA: "customer-profile-card-loved-minis-btn",
    HELP_AND_SUPPORT_CTA: "help-and-support-btn",
    ABANDONED_CART_BTN: "customer-profile-carts-btn",
    ACTIVE_ORDERS_DETAILS_CARD: "active-orders-details-card",
    ACTIVE_ORDERS_RETRY_PAYMENT: "active-orders-retry-payment-btn",
    PAST_ORDERS_DETAILS_CARD: "past-orders-details-card",
    PAST_ORDERS_REORDER_CTA: "past-orders-reorder-btn",
    PAST_ORDERS_CHECK_REFUND: "past-orders-refund-btn",
    BACK_BTN: "account-back-btn",
};

export const EVENTS_MAP: Record<OrderCardCtaType, string> = {
    RETRY_PAYMENT: ANALYTICS_COPIES.ACTIVE_ORDERS_RETRY_PAYMENT,
    REORDER: ANALYTICS_COPIES.PAST_ORDERS_REORDER_CTA,
    CHECK_REFUND: ANALYTICS_COPIES.PAST_ORDERS_CHECK_REFUND,
};

export const JOIN_MEET_TEXT = "Join";
