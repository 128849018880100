import React, { memo, useMemo } from "react";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { Product } from "@minis-consumer/interfaces/catalog";
import { RenderHTMLCommon } from "@minis-consumer/components/render-html";
import { BREAKDOWN_TITLE } from "@minis-consumer/routes/product/constants";

interface Props {
    serviceDeliveryInfo: Product["serviceDeliveryInfo"];
    productType: Product["productType"];
}

/** To be used for Plan and Appointment type products */
const ProductServiceDeliveryInfoComponent: React.FC<Props> = ({
    serviceDeliveryInfo,
    productType,
}) => {
    const title = useMemo(() => BREAKDOWN_TITLE[productType ?? "DEFAULT"], [productType]);

    if (!serviceDeliveryInfo || productType === "DIGITAL" || productType === "PHYSICAL") {
        return null;
    }

    return (
        <Stack spacing={SpacingValue["space-xx-small"]}>
            <Text category="b2" color="low" weight="regular">
                {title}
            </Text>

            <RenderHTMLCommon html={serviceDeliveryInfo} />
        </Stack>
    );
};

export const ProductServiceDeliveryInfo = memo(ProductServiceDeliveryInfoComponent);
