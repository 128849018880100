import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Stack, Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { Divider } from "@minis-consumer/components/divider";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useGetDeliveryChargesText, useShopDispatchTime } from "@minis-consumer/hooks/use-shop";

const TITLE = "Delivery Information";

export const OrderAndDelivery: React.FC = () => {
    const { name: storeName } = useStoreInfo();
    const deliveryChargesText = useGetDeliveryChargesText();

    const dispatchTimeNote = useShopDispatchTime();

    const dividerStyle = {
        marginTop: SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-large"],
    };

    const [showDeliveryInfo, setShowDeliveryInfo] = React.useState(true);

    const toggleDeliveryInfo = React.useCallback(() => {
        setShowDeliveryInfo(!showDeliveryInfo);
    }, [showDeliveryInfo]);

    return (
        <>
            <Pressable onPress={toggleDeliveryInfo} hitSlop={16}>
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                    style={styles.headerContainer}>
                    <Text category="b1" weight="bold" color="high">
                        {TITLE}
                    </Text>

                    <Box style={styles.caret}>
                        <SvgIcon
                            icon={showDeliveryInfo ? "ChevronUp" : "ChevronDown"}
                            color="color-basic-75"
                            width={20}
                        />
                    </Box>
                </Stack>
            </Pressable>
            {showDeliveryInfo ? (
                <Stack spacing={SpacingValue["space-x-small"]} style={styles.container}>
                    <Stack
                        direction="row"
                        spacing={SpacingValue["space-x-small"]}
                        alignItems="center">
                        <Box>
                            <SvgIcon icon="Package" color="color-basic-75" width={12} height={12} />
                        </Box>
                        <Text category="b2" color="color-basic-75">
                            {"All orders will be delivered by "}
                            <Text category="b2" color="color-basic-75" weight="bold">
                                {storeName}
                            </Text>
                        </Text>
                    </Stack>

                    {deliveryChargesText ? (
                        <Stack
                            direction="row"
                            spacing={SpacingValue["space-x-small"]}
                            alignItems="center">
                            <Box>
                                <SvgIcon
                                    icon="Star"
                                    color="color-basic-75"
                                    width={12}
                                    height={12}
                                />
                            </Box>
                            <Text category="b2" color="color-basic-75">
                                {deliveryChargesText}
                            </Text>
                        </Stack>
                    ) : null}

                    {dispatchTimeNote ? (
                        <Stack
                            direction="row"
                            spacing={SpacingValue["space-x-small"]}
                            alignItems="flex-start">
                            <Box pt={SpacingValue["space-xx-small"]}>
                                <SvgIcon
                                    icon="Timer"
                                    color="color-basic-75"
                                    width={12}
                                    height={12}
                                />
                            </Box>
                            <Text category="b2" color="color-basic-75">
                                {dispatchTimeNote}
                            </Text>
                        </Stack>
                    ) : null}
                </Stack>
            ) : null}
            <Divider style={dividerStyle} />
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        width: "90%",
        marginTop: SpacingValue["space-medium"],
    },
    caret: {
        width: 24,
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    headerContainer: {
        flex: 1,
    },
});
