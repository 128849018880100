import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { SEGMENTED_COUPON_LOGO } from "@minis-consumer/constants/images";

import { CouponComponentData } from "../../types";

const IMG_SIZE = 34;

const CouponCardHeaderComponent: React.FC<CouponComponentData> = (props) => {
    return (
        <Pressable onPress={props.onClickHeaderCta}>
            <Box direction="row" style={styles.header} alignItems="center">
                {props.segmentedOffer ? (
                    <CdnImage id={SEGMENTED_COUPON_LOGO} height={IMG_SIZE} width={IMG_SIZE} />
                ) : (
                    <SvgIcon
                        color={props.headerIconColor}
                        icon="OfferFilled"
                        height={30}
                        width={30}
                    />
                )}
                <Box
                    direction="row"
                    flex={1}
                    ml={SpacingValue["space-x-small"]}
                    alignItems="center">
                    <Box flex={2}>
                        <Text category="b1" weight="bold" color={props.headerTitleColor}>
                            {props.headerTitle}
                        </Text>
                        <Text category="b2" color={props.headerDescriptionColor}>
                            {props.headerDescription}
                            <Text category="b2" weight="bold" color={props.headerDescriptionColor}>
                                {props.headerDescriptionBoldText}
                            </Text>
                        </Text>
                    </Box>

                    {props.headerCtaIcon ? (
                        <SvgIcon
                            icon={props.headerCtaIcon}
                            height={20}
                            width={20}
                            color={props.headerCtaColor}
                            style={styles.mlXXSmall}
                        />
                    ) : null}

                    {props.headerCtaText ? (
                        <Text
                            category="b2"
                            weight="bold"
                            color={props.headerCtaColor}
                            style={styles.mlXXSmall}>
                            {props.headerCtaText}
                        </Text>
                    ) : null}
                </Box>
            </Box>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    header: {
        padding: SpacingValue["space-medium"],
    },
    mlXXSmall: {
        marginLeft: SpacingValue["space-xx-small"],
    },
});

export const CouponCardHeader = React.memo(CouponCardHeaderComponent);
