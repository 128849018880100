import React, { useCallback, useState } from "react";
import { LayoutChangeEvent, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { DialogContent, useTheme, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { BottomSheet, CdnImage } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { RouteList } from "@minis-consumer/interfaces/route";
import { Cart, UserAddress } from "@minis-consumer/interfaces/cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Analytics } from "@minis-consumer/analytics";
import { useCartView } from "@minis-consumer/hooks/use-cart";

import { AddressListModalBody } from "./components/modal-body";
import { DEFAULT_CALLOUT_HEIGHT, CALLOUT_IMAGE_ID, MANAGE_DELIVERY_TEXT } from "./constants";

type AddressSmallScreenComponentProps = {
    open: boolean;
    setShowAddressSelected: (address: UserAddress | null) => void;
    addresses: NonNullable<Cart["cartViewData"]>["addresses"];
    shouldShowBanner?: boolean;
    setOpen?: (b: boolean) => void;
    shouldShowUnserviceable?: boolean;
};

const AddressSmallScreenComponent: React.FC<AddressSmallScreenComponentProps> = ({
    open,
    setOpen,
    addresses,
    setShowAddressSelected,
    shouldShowBanner = true,
    shouldShowUnserviceable = true,
}) => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const store = useStoreInfo();
    const cart = useCartView(store.storeId);
    const { value: theme } = useTheme();
    const [calloutHeight, setCalloutHeight] = useState(DEFAULT_CALLOUT_HEIGHT);

    const closeDialog = useCallback((): void => setOpen?.(false), [setOpen]);

    const onAddNewAddressPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: "add-address-btn",
            label: cart?.cartViewData?.id,
        });

        closeDialog();
        navigation.navigate("Address", {
            screen: "SearchLocation",
            params: {
                storeId: store.storeId,
            },
        });
    }, [closeDialog, navigation, store.storeId, cart?.cartViewData?.id]);

    const onAddressMissingFields = React.useCallback(
        (address: UserAddress) => {
            closeDialog();
            navigation.navigate("UpdateAddress", { address });
        },
        [closeDialog, navigation],
    );

    const onCalloutLayout = useCallback(
        (e: LayoutChangeEvent) =>
            setCalloutHeight(e?.nativeEvent?.layout?.height || DEFAULT_CALLOUT_HEIGHT),
        [],
    );

    if (!open) {
        return null;
    }

    return (
        <BottomSheet open={open} onClose={closeDialog} style={styles.sheet}>
            {shouldShowBanner ? (
                <Box
                    ph={SpacingValue["space-medium"]}
                    pv={SpacingValue["space-small"]}
                    style={[styles.callout, { backgroundColor: theme["color-critical-25"] }]}
                    onLayout={onCalloutLayout}>
                    <Stack
                        direction="row"
                        spacing={SpacingValue["space-medium"]}
                        alignItems="center">
                        <CdnImage isImageKitEnabled id={CALLOUT_IMAGE_ID} width={46} height={60} />
                        <Box flex={1}>
                            <Text category="b2" weight="medium" color="high">
                                {`${store.name} ${MANAGE_DELIVERY_TEXT}`}
                            </Text>
                        </Box>
                    </Stack>
                </Box>
            ) : null}
            <DialogContent
                style={[styles.dialogContent, { backgroundColor: theme["color-basic-0"] }]}>
                <AddressListModalBody
                    addresses={addresses}
                    onAddNewAddressPress={onAddNewAddressPress}
                    setShowAddressSelected={setShowAddressSelected}
                    closeDialog={closeDialog}
                    onAddressMissingFields={onAddressMissingFields}
                    cartId={cart?.cartViewData?.id}
                    calloutHeight={calloutHeight}
                    shouldShowUnserviceable={shouldShowUnserviceable}
                />
            </DialogContent>
        </BottomSheet>
    );
};

const styles = StyleSheet.create({
    sheet: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        maxHeight: "85%",
        backgroundColor: "transparent",
    },
    dialogContent: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
    },
    callout: {
        borderRadius: 16,
        marginHorizontal: SpacingValue["space-medium"],
        marginBottom: SpacingValue["space-medium"],
    },
});

export const AddressSmallScreen = React.memo(AddressSmallScreenComponent);
