import React, { useContext } from "react";
import { StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { Shimmer } from "@minis-consumer/components/shimmer";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { SelectedAddressInfoContext } from "@minis-consumer/contexts/selected-address-context";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";

interface DeliveryInfoProps {
    isLoading: boolean;
    storeId: string;
}

const DeliveryInfoComponent: React.FC<DeliveryInfoProps> = ({ isLoading, storeId }) => {
    const storeInfo = useStoreInfo();
    const cart = useCartViewData(storeId);
    const selectedAddressCtx = useContext(SelectedAddressInfoContext);
    const deliveryEta = selectedAddressCtx?.selectedAddressInfo?.deliveryEta;

    const dispatchTimeNote = React.useMemo(() => {
        const dispatchTime = cart?.store.settings.dispatchTime;

        if (dispatchTime) {
            if (dispatchTime.min === dispatchTime.max) {
                return `. Orders are usually dispatched in ${dispatchTime.min} day(s)`;
            } else {
                return `. Orders are usually dispatched in ${dispatchTime.min} - ${dispatchTime.max} days`;
            }
        }

        return "";
    }, [cart?.store.settings.dispatchTime]);

    // TODO: separate these out in different render functions
    return (
        <>
            {storeInfo.deliveryInfoViewEnabled ? (
                isLoading ? (
                    <Shimmer width="80%" height={20} />
                ) : (
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={SpacingValue["space-x-small"]}>
                        <SvgIcon icon="Package" color="color-basic-45" width={16} height={16} />

                        {selectedAddressCtx?.selectedAddressInfo?.addressId && deliveryEta ? (
                            <Text
                                category="b2"
                                color="color-basic-60"
                                style={styles.deliveryInfoText}>
                                Delivery by {storeInfo.name} in{" "}
                                <Text category="b2" color="highest" weight="bold">
                                    {deliveryEta}
                                </Text>
                            </Text>
                        ) : (
                            <Text category="b2" color="color-basic-60">
                                Order delivered by {storeInfo.name}
                            </Text>
                        )}
                    </Stack>
                )
            ) : (
                <Text category="b2" color="high" weight="medium">
                    {`The order delivery is managed by ${storeInfo.name}${dispatchTimeNote}`}
                </Text>
            )}
        </>
    );
};

export const DeliveryInfo = React.memo(DeliveryInfoComponent);

const styles = StyleSheet.create({
    deliveryInfoText: {
        flex: 1,
    },
});
