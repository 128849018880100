import React, { ReactNode, useCallback, useState } from "react";
import { ViewProps, Pressable, StyleSheet, ViewStyle } from "react-native";

import { SpacingValue, Text, TextProps } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Divider, InView } from "@swiggy-private/react-native-ui";

import { Analytics } from "@minis-consumer/analytics";

interface Props extends ViewProps {
    title: string;
    isDividerPresent: boolean;
    analyticsEvent: string;
    index: number;

    description?: string;
    titleTextColor?: TextProps["color"];
    children?: ReactNode;
    isExpanded?: boolean;
    testID?: string;
    iconTestID?: string;
}

export const ExpansionTile: React.FC<Props> = ({
    title,
    description,
    children,
    titleTextColor = "color-basic-75",
    isDividerPresent,
    isExpanded = true,
    testID,
    iconTestID,
    analyticsEvent,
    index,
}) => {
    const [expanded, setExpanded] = useState(isExpanded);
    const analyticsData = React.useMemo(() => {
        return {
            category: analyticsEvent,
            context: `isExpanded: ${expanded}`,
        };
    }, [analyticsEvent, expanded]);

    const toggleExpand = useCallback(() => {
        analyticsData && Analytics.clickEvent(analyticsData);

        setExpanded(!expanded);
    }, [expanded, analyticsData]);

    const containerPadding = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-large"],
    });

    const boxDirection = useSelectScreen({
        lg: "row",
        default: "column",
    }) as ViewStyle["flexDirection"];

    const textStyle = useSelectScreen({
        lg: { width: "80%", marginRight: SpacingValue["space-medium"] },
        default: { width: "100%", marginBottom: SpacingValue["space-medium"] },
    });

    const onViewChange = React.useCallback(
        (isVisible: boolean) => {
            if (!isVisible || !analyticsData) {
                return;
            }

            Analytics.impressionEvent(analyticsData);
        },
        [analyticsData],
    );

    return (
        <InView onChange={onViewChange}>
            <Box
                style={[
                    {
                        paddingHorizontal: containerPadding,
                        paddingTop: containerPadding,
                    },
                    styles.container,
                ]}
                testID={testID}>
                <Pressable onPress={toggleExpand}>
                    <Box
                        direction="row"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        mt={index !== 0 ? SpacingValue["space-xx-small"] : 0}>
                        <Text
                            category="b1"
                            color={titleTextColor}
                            style={styles.arrow}
                            weight="bold">
                            {title}
                        </Text>

                        <Box testID={iconTestID}>
                            <SvgIcon
                                width={SpacingValue["space-x-large"]}
                                height={SpacingValue["space-x-large"]}
                                icon={expanded ? "ChevronUp" : "ChevronDown"}
                                color="color-basic-60"
                            />
                        </Box>
                    </Box>
                </Pressable>

                {expanded ? (
                    <Box style={styles.gap} direction={boxDirection}>
                        {description ? (
                            <Text category="b2" color="color-basic-60" style={textStyle}>
                                {description}
                            </Text>
                        ) : null}
                        {children}
                    </Box>
                ) : null}
                <Box pt={containerPadding}>{isDividerPresent ? <Divider /> : null}</Box>
            </Box>
        </InView>
    );
};

const styles = StyleSheet.create({
    gap: { marginTop: SpacingValue["space-small"] },
    arrow: { marginRight: SpacingValue["space-medium"], flex: 1 },
    container: {
        backgroundColor: "#fff",
        paddingBottom: 0,
    },
});
