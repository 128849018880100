import React from "react";
import { ViewStyle } from "react-native";

import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { CouponCard } from "@swiggy-private/react-native-ui";

import { Slate } from "@minis-consumer/components/slate";

const CouponListPlaceholderComponent: React.FC = () => {
    const { value: theme } = useTheme();

    const containerFlexDirection = useSelectScreen({
        lg: "row",
        default: "column",
    }) as ViewStyle["flexDirection"];

    const conatinerPadding = useSelectScreen({
        lg: {
            padding: SpacingValue["space-x-large"],
        },
        default: {
            padding: SpacingValue["space-medium"],
        },
    });

    const cardOptions = {
        loading: true,
        cardStyle: {
            margin: 0,
            flex: 1,
            minHeight: 140,
        },
    };

    return (
        <Box style={conatinerPadding} pt={0} mt={SpacingValue["space-medium"]}>
            <Slate height={20} width={130} bgColor={theme["color-basic-15"]} />
            <Stack
                direction={containerFlexDirection}
                spacing={SpacingValue["space-medium"]}
                style={{ marginTop: SpacingValue["space-medium"] }}>
                {[1, 2].map((val2) => (
                    <CouponCard options={cardOptions} key={val2} />
                ))}
            </Stack>
        </Box>
    );
};

export const CouponListPlaceholder = React.memo(CouponListPlaceholderComponent);
