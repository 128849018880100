import React from "react";
import { Pressable, StyleProp, ViewStyle } from "react-native";
import { useLoading } from "@rest-hooks/hooks";
import { useController } from "@rest-hooks/react";

import { Portal } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

import { WidgetFlipper } from "@minis-consumer/components/widget-flipper";
import { ICartsListItem } from "@minis-consumer/interfaces/cart";
import { GenericFeedbackProps } from "@minis-consumer/components/generic-feedback-card/components/feedback-content";
import { GenericFeedbackCard } from "@minis-consumer/components/generic-feedback-card";
import { CART_API_ERRORS } from "@minis-consumer/api/cart";
import { Analytics } from "@minis-consumer/analytics";
import { useWidgetAnalytics } from "@minis-consumer/hooks/use-widget-analytics";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { CartDispatchContext } from "@minis-consumer/contexts/cart-context";
import { Logger } from "@minis-consumer/includes/logger";
import { SoftClearCart } from "@minis-consumer/resources/cart";

import { AbandonedCartContent } from "./components/cart-content";
import { DEFAULTS } from "./constants";
import { ClearCartSnackBar } from "../clear-cart-snackbar";
import { ANALYTICS_COPIES } from "../../constants";

type StringVoidFn = (s: string) => void;

interface AbandonedCartCardProps {
    frontContent: ICartsListItem;
    cartOrderSequence: number;
    style: StyleProp<ViewStyle>;

    backContent?: Partial<GenericFeedbackProps>;
    onSubmitFeedback?: StringVoidFn;
    onClearCartCb?: StringVoidFn;
    onScrollToIndex?: VoidFunction;
}

const AbandonedCartCardComponent: React.FC<AbandonedCartCardProps> = (props) => {
    const { store, cartId } = props.frontContent;

    const analyticsHandler = useWidgetAnalytics();
    const [showToast] = useToast();
    const { fetch } = useController();

    const dispatch = React.useContext(CartDispatchContext);

    const [canTriggerFlip, setTriggerFlip] = React.useState(false);
    const [shouldDoReverseFlip, setReverseFlip] = React.useState(false);
    const [canShowClearCartSnackbar, setClearCartSnackbar] = React.useState(false);
    const [wasUndoPressed, setUndoPressed] = React.useState(false);
    const [canStartCountdown, setStartCountdown] = React.useState(false);
    const [canEnableFeedbackCta, setFeedbackCta] = React.useState(false);
    const [wasSnackbarManuallyDismissed, setSnackbarManualDismiss] = React.useState(false);
    const [wasDeletionSuccess, setDeletionStatus] = React.useState(false);

    const onReverse = React.useCallback(() => {
        setReverseFlip(true);
        setTriggerFlip(true);
        setClearCartSnackbar(false);
    }, []);

    const [onDeleteCart] = useLoading(async () => {
        try {
            setDeletionStatus(true);

            await fetch(SoftClearCart, { storeId: store.id });

            dispatch({ type: "CLEAR_CART_ACTION", payload: { storeId: store.id } });
            setFeedbackCta(true);
        } catch (err) {
            Logger.recordError(err);
            showToast(CART_API_ERRORS.DELETE_CART);
            onReverse();
            setDeletionStatus(false);
            setSnackbarManualDismiss(false);
        }
    }, [dispatch, fetch, onReverse, showToast, store.id]);

    const onFeedbackAnimationCb = React.useCallback(() => {
        props.onClearCartCb?.(cartId);
    }, [cartId, props]);

    const onFeedback = React.useCallback(() => {
        props.onSubmitFeedback?.(store.name);
        onFeedbackAnimationCb();
    }, [onFeedbackAnimationCb, props, store.name]);

    const snackbarAnalyticsData = React.useMemo(() => {
        return {
            category: ANALYTICS_COPIES.UNDO_DELETE_CART_BTN,
            label: cartId,
        };
    }, [cartId]);

    const onUndoClearCart = React.useCallback(() => {
        Analytics.clickEvent(snackbarAnalyticsData);

        setUndoPressed(true);
        onReverse();
    }, [onReverse, snackbarAnalyticsData]);

    const onReverseFlipCb = React.useCallback(() => {
        setTriggerFlip(false);
        setReverseFlip(false);
    }, []);

    const onFlipCb = React.useCallback(() => {
        if (!shouldDoReverseFlip) {
            setTriggerFlip(false);
            return;
        }

        onReverseFlipCb();
    }, [onReverseFlipCb, shouldDoReverseFlip]);

    const onClearCart = React.useCallback(() => {
        if (wasUndoPressed) {
            setUndoPressed(false);
        }

        setTriggerFlip(true);
        setStartCountdown(true);
        setClearCartSnackbar(true);
    }, [wasUndoPressed]);

    const onControlSnackbar = React.useCallback(() => {
        if (wasDeletionSuccess) {
            return;
        }

        setSnackbarManualDismiss(true);
        onDeleteCart();
        setClearCartSnackbar(false);
    }, [onDeleteCart, wasDeletionSuccess]);

    React.useEffect(() => {
        let t: NodeJS.Timer;

        if (canStartCountdown) {
            t = setTimeout(() => {
                if (wasUndoPressed || wasSnackbarManuallyDismissed) {
                    return;
                }

                onDeleteCart();
            }, DEFAULTS.CLEAR_CART_API_DELAY_IN_MS);
        }

        return () => clearTimeout(t as unknown as number);
    }, [canStartCountdown, onDeleteCart, wasSnackbarManuallyDismissed, wasUndoPressed]);

    useMount(() => {
        analyticsHandler.onMountAnalytics({
            category: ANALYTICS_COPIES.SAVED_CART_WIDGET,
            label: cartId,
        });
    });

    return (
        <>
            <Pressable onPress={onControlSnackbar} disabled={!canShowClearCartSnackbar}>
                <WidgetFlipper
                    frontContent={
                        <AbandonedCartContent
                            {...props.frontContent}
                            cartOrderSequence={props.cartOrderSequence}
                            style={props.style}
                            onClearCart={onClearCart}
                        />
                    }
                    backContent={
                        <GenericFeedbackCard
                            {...props.backContent}
                            containerStyle={props.style}
                            storeId={store.id}
                            cartId={cartId}
                            onPressCta={onFeedback}
                            onPressClose={onFeedbackAnimationCb}
                            canEnableFeedbackCta={canEnableFeedbackCta}
                            commonCb={onControlSnackbar}
                            onScrollToIndex={props.onScrollToIndex}
                        />
                    }
                    canFlip={canTriggerFlip}
                    shouldDoReverseFlip={shouldDoReverseFlip}
                    onCb={onFlipCb}
                />
            </Pressable>

            {canShowClearCartSnackbar ? (
                <Portal>
                    <ClearCartSnackBar
                        storeName={store.name}
                        isOpen={canShowClearCartSnackbar}
                        onUndo={onUndoClearCart}
                        analytics={snackbarAnalyticsData}
                    />
                </Portal>
            ) : null}
        </>
    );
};

export const AbandonedCartCard = React.memo(AbandonedCartCardComponent);
