import React from "react";

import { useMakePaymentUsingUpi } from "@minis-consumer/routes/payment/hooks/use-make-payment";
import { useSelectedPaymentMethod } from "@minis-consumer/routes/payment/hooks/use-selector";

import { NewUPI } from "../../new-upi-method";

const NewUpiSectionComponent: React.FC = () => {
    const [paymentInProgress] = useMakePaymentUsingUpi();
    const isPaymentSelected = useSelectedPaymentMethod({
        category: "NEW",
        type: "UPI",
        value: "CUSTOM_UPI",
    });

    if (!isPaymentSelected) {
        return null;
    }

    return <NewUPI paymentInProgress={paymentInProgress} />;
};

export const NewUpiSection = React.memo(NewUpiSectionComponent);
