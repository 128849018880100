import { useContext, useMemo } from "react";
import { StateContext } from "@rest-hooks/react";
import { PixelRatio, Platform } from "react-native";

import { ProductEntity } from "@minis-consumer/resources/catalog";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { GOOGLE_API_KEY, GOOGLE_STATIC_MAP_API_URL } from "@minis-consumer/constants";

import type { Product } from "@minis-consumer/interfaces/catalog";

export const useGetProduct = (id: Product["id"]): Product | null => {
    const { entities } = useContext(StateContext);
    const product = entities[ProductEntity.key]?.[id] as ProductEntity | undefined;
    return useMemo(() => product ?? null, [product]);
};

export const useGetStoreMapImageUrl = (markerColor: string, height = 80): string | null => {
    const { address, name } = useStoreInfo();

    markerColor = markerColor.startsWith("#") ? `0x${markerColor.split("#")[1]}` : "0xFF0000";

    const markerLocation = useMemo(
        () => ({
            lat: address?.lat?.toString().trim(),
            lng: address?.lng?.toString().trim(),
        }),
        [address?.lat, address?.lng],
    );

    const markerLabel = name[0];
    const width = PixelRatio.getPixelSizeForLayoutSize(250);
    const nHeight = PixelRatio.getPixelSizeForLayoutSize(height);

    const params = useMemo(
        () => ({
            center: `${markerLocation.lat},${markerLocation.lng}`,
            zoom: "14",
            size: `${width}x${nHeight}`,
            key: GOOGLE_API_KEY,
            markers: `size:mid|color:${markerColor}|label:${markerLabel}|${markerLocation.lat},${markerLocation.lng}`,
        }),
        [markerColor, markerLabel, markerLocation.lat, markerLocation.lng, nHeight, width],
    );

    if (!address || !markerLocation.lat || !markerLocation.lng) {
        return null;
    }

    return `${GOOGLE_STATIC_MAP_API_URL}?${new URLSearchParams(params)}`;
};

export const useGetStoreMapUrl = (): string | null => {
    const { address, name } = useStoreInfo();

    const markerLocation = useMemo(
        () => ({
            lat: address?.lat?.toString().trim(),
            lng: address?.lng?.toString().trim(),
        }),
        [address?.lat, address?.lng],
    );

    if (!markerLocation.lat || !markerLocation.lng) {
        return null;
    }

    const latLng = `${markerLocation.lat},${markerLocation.lng}`;
    const markerLabel = name[0];

    /**
     * Refer - https://developers.google.com/maps/documentation/urls/get-started#search-action
     */
    const scheme = Platform.select({
        ios: "maps:0,0?q=",
        android: "geo:0,0?q=",
        default: "https://www.google.com/maps/search/?api=1&query=",
    });

    const url = Platform.select({
        ios: `${scheme}${markerLabel}@${latLng}`,
        android: `${scheme}${latLng}(${markerLabel})`,
        default: `${scheme}${encodeURIComponent(latLng)}`,
    });

    return url;
};
