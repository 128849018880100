import { useMemo } from "react";

import { useGetProducts } from "@minis-consumer/hooks/use-get-products";
import { useGetProductBadges } from "@minis-consumer/hooks/use-get-badges";

import type { ProductBadge, Product } from "@minis-consumer/interfaces/catalog";

export const useGetBadgesWithProducts = (): Array<readonly [ProductBadge, Product[]]> => {
    const badges = useGetProductBadges().filter((b) => b.active);
    const products = useGetProducts();

    return useMemo(() => {
        if (!badges || !products) {
            return [];
        }

        const result: Array<[ProductBadge, Product[]]> = badges.map((badge) => {
            const filteredProducts = badge.products
                .map((id) => {
                    return products.find(
                        (product) => product.id === id && product.active && product.inStock,
                    );
                })
                .filter((product) => product !== undefined);

            return [badge as ProductBadge, filteredProducts as Product[]];
        });

        return result.filter((p) => p[1]?.length > 0);
    }, [badges, products]);
};
