import * as React from "react";

import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";

import { ResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { useCartView } from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { RouteList } from "@minis-consumer/interfaces/route";

import { DeliveryInfoScreen } from "./routes/delivery-info-screen";
import { GuestCheckoutLoadingScreen } from "./routes/loading-screen";
import { GuestCheckoutState } from "./types";

type GuestCheckoutScreenProps = NativeStackScreenProps<RouteList, "GuestCheckout">;

export const GuestCheckout: React.FC<GuestCheckoutScreenProps> = ({ route, navigation }) => {
    const [state, setState] = React.useState<GuestCheckoutState>(
        route.params?.initialScreen ?? "LOADING",
    );

    const store = useStoreInfo();
    const cartView = useCartView(store.storeId);
    const isGuestCheckout = React.useRef(cartView?.cartStatus === "GUEST_CART").current;

    useIsomorphicEffect(
        () =>
            navigation.setOptions({
                title: store.name,
                headerShown: isGuestCheckout && state !== "LOADING",
            }),
        [isGuestCheckout, navigation, state, store.name],
    );

    React.useEffect(() => {
        if (!isGuestCheckout) {
            navigation.navigate("Home", { screen: "Cart", params: {} });
            return;
        }

        if (state === "LOADING") {
            const timer = setTimeout(() => setState("DELIVERY_INFO"), 1_000);
            return () => clearTimeout(timer);
        }

        // eslint-disable-next-line no-void
        return () => void 0;
    }, [isGuestCheckout, navigation, state]);

    if (!isGuestCheckout) {
        return null;
    }

    if (state === "LOADING") {
        return <GuestCheckoutLoadingScreen />;
    }

    return <DeliveryInfoScreen />;
};

export const GuestCheckoutScreen: React.FC<GuestCheckoutScreenProps> = (props) => {
    return (
        <ResetErrorBoundary>
            <StateContextContainer>
                <GuestCheckout {...props} />
            </StateContextContainer>
        </ResetErrorBoundary>
    );
};
