import React from "react";
import { StyleSheet, FlatList, Pressable } from "react-native";

import { SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage, InView } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { useFetchStoreLinks } from "@minis-consumer/hooks/use-fetch-store-links";
import { StoreLink } from "@minis-consumer/api/store";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";
import { Analytics } from "@minis-consumer/analytics";
import {
    SF_ANALYTICS_EVENT_NAMES,
    TABS,
    TYPE_ICON_MAP,
} from "@minis-consumer/routes/shop/constants";
import { hasHttpUrlScheme } from "@minis-consumer/routes/shop/helpers";
import { SectionHeading } from "@minis-consumer/routes/shop/components/section-separator";
import { useGetProducts } from "@minis-consumer/hooks/use-get-products";
import { ReadMoreText } from "@minis-consumer/components/read-more-text";
import {
    LOGO_SIZE,
    SECTION_HEADER_TEXT,
    MIN_LINKS,
    SEE_MORE_CTA,
    SEE_LESS_CTA,
    TEXT_MAX_COUNT,
    ICON_HEIGHT,
    ICON_WIDTH,
    ICON_FONT_WEIGHT,
    CONTAINER_MARGIN_TOP,
} from "./constants";

const ItemSeparatorComponent: React.FC = () => <Box style={styles.separator} />;

interface MyLinksComponentProps {
    toggleViewType: (viewType: TABS) => void;
}

const MyLinksComponent: React.FC<MyLinksComponentProps> = ({ toggleViewType }) => {
    const { value: theme } = useTheme();

    const storeLinks = useFetchStoreLinks();
    const hasStoreLinks = storeLinks?.data?.socialLinks.length ?? -1 > 0;

    const [isExpanded, setIsExpanded] = React.useState(false);

    const openUrl = useLinking();

    const productData = useGetProducts();
    const hasProducts = productData.length > 0;

    const getAnalyticsContext = React.useCallback((item: StoreLink) => {
        return {
            category: SF_ANALYTICS_EVENT_NAMES.MY_LINKS_WIDGET,
            context: JSON.stringify({
                title: item.title,
                url: item.url,
                hasImage: !!item.logo,
            }),
        };
    }, []);

    const fireImpressionEvent = React.useCallback(
        (item: StoreLink): void => {
            Analytics.impressionEvent(getAnalyticsContext(item));
        },
        [getAnalyticsContext],
    );

    const fireClickEvent = React.useCallback(
        (item: StoreLink): void => {
            Analytics.clickEvent(getAnalyticsContext(item));
        },
        [getAnalyticsContext],
    );

    const urlTextProps: TextProps = {
        category: "b3",
        weight: "medium",
        color: "text_High_Emphasis",
    };

    const renderItem = React.useCallback(
        ({ item, index }: { item: StoreLink; index: number }) => {
            const onImpression = (isVisible: boolean): void => {
                isVisible && fireImpressionEvent(item);
            };

            const onPress = (): void => {
                fireClickEvent(item);
                const hasUrlScheme = hasHttpUrlScheme(item.url);

                openUrl(hasUrlScheme ? item.url : `http://${item.url}`);
            };

            return (
                <InView onChange={onImpression}>
                    <Box
                        direction="row"
                        gap={SpacingValue["space-small"]}
                        ph={SpacingValue["space-x-small"]}
                        pv={SpacingValue["space-x-small"]}
                        alignItems="center"
                        testID={`${TEST_IDS_SF.MY_LINK_PREFIX}-${index}`}
                        style={{
                            backgroundColor: theme["color-primary-25"],
                            borderRadius: SpacingValue["space-small"],
                        }}>
                        <Box style={styles.image}>
                            {item?.logo ? (
                                <CdnImage
                                    id={item?.logo}
                                    width={LOGO_SIZE}
                                    height={LOGO_SIZE}
                                    style={styles.logo}
                                    isImageKitEnabled
                                />
                            ) : TYPE_ICON_MAP[item.type] ? (
                                <SvgIcon
                                    icon={TYPE_ICON_MAP[item.type]}
                                    width={LOGO_SIZE}
                                    height={LOGO_SIZE}
                                    color="color-primary"
                                />
                            ) : null}
                        </Box>
                        <Pressable onPress={onPress} style={styles.flexShrink}>
                            <Box direction="column">
                                <Text category="b1" weight="bold" color="text_Highest_Emphasis">
                                    {item.title}
                                </Text>
                                <ReadMoreText
                                    text={item.url}
                                    maxLengthCount={TEXT_MAX_COUNT}
                                    textProps={urlTextProps}
                                    expandedTextProps={urlTextProps}
                                    collapsedTextProps={urlTextProps}
                                    canHideCTA
                                />
                            </Box>
                        </Pressable>
                    </Box>
                </InView>
            );
        },
        [fireClickEvent, fireImpressionEvent, openUrl, theme],
    );

    const sortedLinks = React.useMemo(
        () => storeLinks.data?.socialLinks?.sort((a, b) => a.priority - b.priority),
        [storeLinks.data?.socialLinks],
    );

    const seeMoreCtaRequired = React.useMemo(
        () => (hasProducts && sortedLinks ? sortedLinks?.length > MIN_LINKS : false),
        [hasProducts, sortedLinks],
    );

    const displayedLinks = React.useMemo(() => {
        return sortedLinks ? (isExpanded ? sortedLinks : sortedLinks.slice(0, MIN_LINKS)) : null;
    }, [isExpanded, sortedLinks]);

    const handleSeeMore = React.useCallback(() => {
        setIsExpanded((prev) => !prev);
    }, []);

    React.useEffect(() => {
        if (!hasStoreLinks) {
            toggleViewType("catalog");
        }
    }, [hasStoreLinks, toggleViewType]);

    React.useEffect(() => {
        if (!hasProducts || (sortedLinks && sortedLinks.length <= MIN_LINKS)) {
            setIsExpanded(true);
        }
    }, [hasProducts, sortedLinks]);

    if (!hasStoreLinks) {
        return null;
    }

    return (
        <Box pb={SpacingValue["space-xx-large"]} mt={CONTAINER_MARGIN_TOP}>
            <>
                <SectionHeading title={SECTION_HEADER_TEXT} />
                <Box ph={SpacingValue["space-medium"]} gap={SpacingValue["space-small"]}>
                    <FlatList
                        data={displayedLinks}
                        renderItem={renderItem}
                        contentContainerStyle={styles.content}
                        keyExtractor={(item: StoreLink) => item.title}
                        ItemSeparatorComponent={ItemSeparatorComponent}
                        scrollEnabled
                        showsVerticalScrollIndicator
                    />
                </Box>

                {seeMoreCtaRequired ? (
                    <Pressable onPress={handleSeeMore}>
                        <Box
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            mt={SpacingValue["space-large"]}>
                            <Text category="btn4" color="background_Button_Primary">
                                {isExpanded ? SEE_LESS_CTA : SEE_MORE_CTA}
                            </Text>
                            <Box ml={SpacingValue["space-xx-small"]}>
                                <SvgIcon
                                    icon={isExpanded ? "ArrowUp" : "ArrowDown"}
                                    width={ICON_WIDTH}
                                    height={ICON_HEIGHT}
                                    fontWeight={ICON_FONT_WEIGHT}
                                    color={"background_Button_Primary"}
                                />
                            </Box>
                        </Box>
                    </Pressable>
                ) : null}
            </>
        </Box>
    );
};

export const MyLinks = React.memo(MyLinksComponent);

const styles = StyleSheet.create({
    content: {
        marginTop: SpacingValue["space-x-large"],
    },
    image: {
        height: LOGO_SIZE,
        width: LOGO_SIZE,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
    },
    logo: {
        borderRadius: 8,
    },
    separator: {
        height: SpacingValue["space-small"],
    },
    flexShrink: {
        flexShrink: 1,
    },
});
