import React, { useCallback } from "react";
import { Platform, StyleSheet } from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { format } from "@swiggy-private/common-helpers";

import { Divider } from "@minis-consumer/components/divider";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { ShareParams, useShare } from "@minis-consumer/hooks/use-share";
import { VariantOptionsList } from "@minis-consumer/components/variants";
import type { Product, Variant } from "@minis-consumer/interfaces/catalog";
import { useSelectedVariant, useVariantsList } from "@minis-consumer/hooks/use-variants";
import { useShopNavigation } from "@minis-consumer/hooks/use-shop-navigation";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";
import { PRODUCT_SHARE_TEXT } from "@minis-consumer/constants/share";

import { ProductChat } from "./components/chat";
import { Coupons } from "./components/coupons";
import { DeliveryInfo } from "./components/delivery-info";
import { ProductDescription } from "./components/description";
import { ProductDetailsMain } from "./components/main";
import { OrderAndDelivery } from "./components/orders-and-delivery";
import { ProductPrice } from "./components/price";
import { ProductShare } from "./components/share";
import { StoreDetails } from "./components/store";
import { ReturnPolicy } from "./components/return-policy";
import { KeyHighlights } from "./components/key-highlights";
import { OtherRecommendedProducts } from "./components/other-recommended-products";
import { HowItWorks } from "./components/how-does-it-work";

export const ProductDetails: React.FC<{
    product: Product;
    onRecommendedProductPress: (p: string, i: number) => void;

    variantId?: string;
    recommendedProductIds?: Array<string>;
}> = ({ product, variantId, onRecommendedProductPress, recommendedProductIds }) => {
    const isDesktop = useIsDesktop();

    const { deliveryInfoViewEnabled, settings, slug, name: storeName } = useStoreInfo();
    const goToStore = useShopNavigation();

    const share = useShare();
    const {
        id,
        name,
        badges,
        storeId,
        inStock,
        description,
        keyDetails,
        productType,
        productDuration,
        serviceDeliveryInfo,
        serviceDeliveryChannels,
        digitalAssetInfo,
        price,
    } = product || {};

    const { variantOptions = [], variantDetails = [] } = useVariantsList(id);
    const { selectedVariant, selectedVariantOptions, onVariantPress } = useSelectedVariant(
        id,
        variantId,
    );

    const onShare = useCallback(
        (social?: ShareParams["social"]) => {
            share({
                product,
                social,
                description: format(PRODUCT_SHARE_TEXT, product.name, storeName),
            });
        },
        [product, share, storeName],
    );

    const getProductPrice = (): Variant | Product => {
        return selectedVariant ? { ...selectedVariant } : { ...product };
    };

    const goToShop = React.useCallback(() => {
        goToStore(slug);
    }, [goToStore, slug]);

    const isProductTypePhysical = productType === "PHYSICAL" || !productType;
    return (
        <Box flex={1} mt={SpacingValue["space-xx-small"]}>
            <ProductDetailsMain
                name={name}
                description={description}
                badges={badges}
                goToShop={goToShop}
            />
            {variantOptions.length ? (
                <Stack style={styles.variantsContainer} spacing={SpacingValue["space-xx-large"]}>
                    <VariantOptionsList
                        options={variantOptions}
                        variantDetails={variantDetails}
                        selectedVariants={selectedVariantOptions}
                        selectedVariantDetails={selectedVariant}
                        onPress={onVariantPress}
                    />
                    <Divider />
                </Stack>
            ) : null}

            <ProductPrice
                {...getProductPrice()}
                id={id}
                name={name}
                storeId={storeId}
                selectedVariant={selectedVariant}
                inStock={selectedVariant?.available ?? inStock}
            />

            <Coupons productId={id} />

            <KeyHighlights keyDetails={keyDetails} productId={id} />

            {productType && productType !== "PHYSICAL" ? (
                <HowItWorks
                    productId={id}
                    productDuration={productDuration}
                    serviceDeliveryChannels={serviceDeliveryChannels}
                    serviceDeliveryInfo={serviceDeliveryInfo}
                    digitalAssetInfo={digitalAssetInfo}
                    type={productType}
                />
            ) : null}

            {!isDesktop ? (
                <ProductDescription
                    description={product.description}
                    storeId={storeId}
                    productId={product.id}
                />
            ) : null}

            {isProductTypePhysical ? (
                deliveryInfoViewEnabled ? (
                    <DeliveryInfo productId={product?.id} />
                ) : (
                    <OrderAndDelivery />
                )
            ) : null}

            {!isDesktop ? (
                <>
                    {isProductTypePhysical ? (
                        <ReturnPolicy policy={settings?.returnPolicy} productId={product.id} />
                    ) : null}
                    <OtherRecommendedProducts
                        productIds={recommendedProductIds ?? []}
                        onProductPress={onRecommendedProductPress}
                        storeId={storeId}
                    />
                </>
            ) : null}

            <ProductChat
                helperTextTitle="Need help deciding?"
                helperTextSubtitle="Start a chat with"
                ctaText="Chat"
                productId={id}
                productType={productType}
                selectedVariant={selectedVariant}
                isEnquireProduct={price === undefined || !price}
            />

            {Platform.OS === "android" || Platform.OS === "ios" ? (
                <ProductShare
                    helperText="Share this product with someone who'll love it."
                    onShare={onShare}
                    productId={id}
                />
            ) : null}

            <StoreDetails productId={product.id} goToShop={goToShop} />
        </Box>
    );
};

const styles = StyleSheet.create({
    variantsContainer: {
        marginTop: SpacingValue["space-x-large"],
    },
});
