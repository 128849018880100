import React from "react";
import { ViewStyle } from "react-native";

import { ColorPalette, SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { Box } from "@swiggy-private/rn-adaptive-layout";

interface Props extends TextProps {
    children: string;
    underlineColor: keyof ColorPalette;
    underlineStyle?: ViewStyle;
    underlineDistance?: keyof typeof SpacingValue | 0;
}

const DottedUnderlineTextComponent: React.FC<Props> = ({
    children,
    underlineColor,
    underlineStyle,
    underlineDistance = 0,
    ...props
}) => {
    const { value: theme } = useTheme();

    const dashBorderStyles: ViewStyle = React.useMemo(
        () => ({
            ...underlineStyle,
            position: "absolute",
            left: 0,
            right: 0,
            bottom: underlineDistance === 0 ? 0 : -SpacingValue[underlineDistance],
        }),
        [underlineDistance, underlineStyle],
    );

    return (
        <Box>
            <Text {...props}>{children}</Text>
            <DashBorderLine borderColor={theme[underlineColor]} style={dashBorderStyles} />
        </Box>
    );
};

export const DottedUnderlineText = React.memo(DottedUnderlineTextComponent);
