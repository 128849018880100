import { Platform } from "react-native";

export const COPIES = {
    ENQUIRY_MESSAGE: "Are all the products available? I added these via the link shared by you.",
    ENQUIRY_SUCCESS_MESSAGE: "Query sent to seller over chat",
};

export const IS_NATIVE = Platform.OS === "ios" || Platform.OS === "android";

export const EVENT_NAMES = {
    CHAT_TO_CONFIRM: "chat-to-confirm",
};
