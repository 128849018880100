import React, { memo, useMemo, useCallback } from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";
import {
    ReturnOrderSubStatuses,
    ReturnResolutionDetails,
} from "@minis-consumer/interfaces/return-order";

import { RefundDetails } from "../refund-details";
import { ExchangeDetails } from "../exchange-details";
import { DATE_FORMAT, RETURN_ORDER_LABELS } from "../../constants";

const ICON_SIZE = 16;

interface ICloseReturnDetailsComponent {
    closedAtEpoch: number;
    subStatus: ReturnOrderSubStatuses;

    resolutionDetails?: ReturnResolutionDetails;
    showHeaderText?: boolean;
}

const CloseReturnDetailsComponent: React.FC<ICloseReturnDetailsComponent> = ({
    subStatus,
    closedAtEpoch,
    resolutionDetails,
    showHeaderText = true,
}) => {
    const { value: theme } = useTheme();

    const innerContainerStyles: ViewStyle = {
        borderColor: theme["color-basic-15"],
        backgroundColor: theme["color-basic-5"],
    };

    const formattedClosedTime = convertToReadableDateAndTime(closedAtEpoch, DATE_FORMAT);

    const getContent = useCallback((): React.ReactElement | null => {
        if (!resolutionDetails) {
            return null;
        }

        const { partnerName, tracking, images = [], details, resolutionType } = resolutionDetails;

        if (subStatus === "EXCHANGED") {
            return (
                <ExchangeDetails tracking={tracking} partnerName={partnerName} details={details} />
            );
        }

        return <RefundDetails details={details} images={images} type={resolutionType} />;
    }, [resolutionDetails, subStatus]);

    const getResolutionComponent = useMemo(() => getContent(), [getContent]);

    if (!subStatus) {
        return null;
    }

    return (
        <Stack spacing={SpacingValue["space-small"]}>
            {showHeaderText ? (
                <Stack alignItems="center" spacing={SpacingValue["space-xx-small"]} direction="row">
                    <SvgIcon
                        icon="TickFilled"
                        color="color-positive-500"
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                    />
                    <Text
                        category="b2"
                        color="high">{`${RETURN_ORDER_LABELS.CLOSED_REQUEST} ${formattedClosedTime}`}</Text>
                </Stack>
            ) : null}
            <Stack style={[styles.innerContainer, innerContainerStyles]}>
                {getResolutionComponent}
            </Stack>
        </Stack>
    );
};

export const ReturnOrderCloseDetails = memo(CloseReturnDetailsComponent);

const styles = StyleSheet.create({
    textStyles: {
        fontStyle: "italic",
    },
    innerContainer: {
        borderWidth: 1,
        borderRadius: 8,
        padding: SpacingValue["space-small"],
    },
});
