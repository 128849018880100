import { useMemo } from "react";
import { useCache } from "@rest-hooks/react";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { CatalogView } from "@minis-consumer/resources/catalog";
import type { ProductCategory } from "@minis-consumer/interfaces/catalog";

export const useGetProductCategories = (): ProductCategory[] => {
    const storeInfo = useStoreInfo();
    const { categories } = useCache(CatalogView, { storeSlug: storeInfo.slug });

    return useMemo(
        () =>
            categories
                ? Object.values(categories)
                      .filter((c) => c.products.length > 0)
                      .sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0))
                : [],
        [categories],
    );
};
