import React from "react";
import { LayoutChangeEvent, Platform, StyleSheet, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { SpacingValue, Surface } from "@swiggy-private/rn-dls";
import {
    ScreenSize,
    Stack,
    useScreenSize,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { BottomSheet } from "@swiggy-private/react-native-ui";

import { useCartClearWithoutHardDelete, useCartView } from "@minis-consumer/hooks/use-cart";
import { Card } from "@minis-consumer/components/card";
import { useAuthHandler } from "@minis-consumer/hooks/use-auth-modal-handler";
import { Analytics } from "@minis-consumer/analytics";

import { CartErrorCta } from "./components/cart-error-cta";
import { Description } from "./components/description";
import { getCartErrorStatus } from "../../helpers";

interface CartErrorProps {
    storeId: string;
    onLayout?: (e: LayoutChangeEvent) => void;
    open?: boolean;
}

const CartErrorComponent: React.FC<CartErrorProps> = ({ storeId, onLayout, open = false }) => {
    const cart = useCartView(storeId);
    const insets = useSafeAreaInsets();

    const stackDirection = useSelectScreen<ViewStyle["flexDirection"]>({
        lg: "row",
        default: "column",
    });

    const containerStyle = useSelectScreen({
        lg: {
            borderBottomRightRadius: 16,
            borderBottomLeftRadius: 16,
        },
        default: {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            paddingTop: SpacingValue["space-x-large"],
        },
    });

    const cardStyle = useSelectScreen({
        default: {
            padding: SpacingValue["space-x-large"],
        },
        md: {
            paddingHorizontal: SpacingValue["space-x-large"],
            paddingBottom: insets.bottom + SpacingValue["space-x-large"],
        },
        sm: {
            paddingHorizontal: SpacingValue["space-medium"],
            paddingBottom: insets.bottom + SpacingValue["space-x-large"],
        },
        xs: {
            paddingHorizontal: SpacingValue["space-medium"],
            paddingBottom: insets.bottom + SpacingValue["space-x-large"],
        },
    });

    const localOrderStatus = React.useMemo(
        () => getCartErrorStatus(cart?.cartViewData?.cartItems, cart?.cartStatus),
        [cart?.cartStatus, cart?.cartViewData],
    );

    const screenSize = useScreenSize();

    const [, setIsAuthOpen] = useAuthHandler();
    const clearCart = useCartClearWithoutHardDelete(storeId);

    const captureLoginClickEvent = React.useCallback(() => {
        if (localOrderStatus === "GUEST_CART") {
            Analytics.clickEvent({
                category: "login-signup-btn",
            });
        }
    }, [localOrderStatus]);

    const onCtaPress = (): void => {
        switch (localOrderStatus) {
            case "ALL_ITEM_UNAVAILABLE":
                clearCart();
                break;

            case "GUEST_CART":
                captureLoginClickEvent();
                setIsAuthOpen(true);
                break;
        }
    };

    const surfaceAppStyle: ViewStyle = {
        elevation: Platform.select({
            android: 14,
            ios: 4,
        }),
        shadowOpacity: Platform.select({
            ios: 0.2,
        }),
    };

    const surfaceWebStyle: ViewStyle = useSelectScreen({
        lg: {
            elevation: 0,
            shadowOpacity: 0,
            backgroundColor: "transparent",
        },
        default: {
            elevation: 22,
            shadowOpacity: 1,
        },
    });

    const surfaceStyle = Platform.OS === "web" ? surfaceWebStyle : surfaceAppStyle;

    if (!localOrderStatus) {
        return null;
    }

    const content = (
        <Surface style={[styles.surface, surfaceStyle]}>
            <Card style={[containerStyle, cardStyle, styles.fullWidth]} onLayout={onLayout}>
                <Stack
                    direction={stackDirection}
                    spacing={SpacingValue["space-medium"]}
                    style={styles.fullWidth}>
                    <Description orderStatus={localOrderStatus} cartId={cart?.cartViewData?.id} />
                    <CartErrorCta orderStatus={localOrderStatus} onCtaPress={onCtaPress} />
                </Stack>
            </Card>
        </Surface>
    );

    if (screenSize === ScreenSize.Large) {
        return content;
    }

    return open ? (
        <BottomSheet
            usePortal={false}
            open={open}
            showBackdrop={false}
            animate={false}
            disableHardwareBackClose
            style={styles.dialog}>
            {content}
        </BottomSheet>
    ) : null;
};

export const CartError = React.memo(CartErrorComponent);

const styles = StyleSheet.create({
    surface: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    dialog: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    fullWidth: {
        width: "100%",
    },
});
