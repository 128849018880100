import React from "react";

import { UpiPaymentMethod } from "@minis-consumer/interfaces/payment";
import { useMakePaymentUsingUpi } from "@minis-consumer/routes/payment/hooks/use-make-payment";
import { getUpiCollectLogoFromHandler } from "@minis-consumer/routes/payment/helpers";
import { SavedMethodWrapper } from "../method-wrapper";

interface Props {
    onPress: (T?: "CARD" | "UPI", P?: string) => void;
    hasError?: boolean;
    errorMessage?: string;
}

const SavedUpiComponent: React.FC<UpiPaymentMethod & Props> = ({
    vpa,
    onPress,
    hasError,
    errorMessage,
}) => {
    const [paymentInProgress] = useMakePaymentUsingUpi();

    const handler = React.useMemo(() => {
        const handle = vpa.split("@");
        if (handle.length > 1) {
            return handle[1];
        }

        return "";
    }, [vpa]);

    return (
        <SavedMethodWrapper
            type={"UPI"}
            title={vpa || ""}
            onPressCard={onPress}
            inProgress={paymentInProgress}
            paymentId={vpa}
            error={hasError}
            errorMessage={errorMessage}
            logoUrl={getUpiCollectLogoFromHandler(handler)}
        />
    );
};

export const SavedUpi = React.memo(SavedUpiComponent);
