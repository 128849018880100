import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

import { Card } from "@minis-consumer/components/card";
import { Slate } from "@minis-consumer/components/slate";

const BillLoadingComponent: React.FC = () => {
    const { value: theme } = useTheme();

    return (
        <Card>
            <Slate height={24} width={148} borderRadius={8} />
            <Stack
                divider={
                    <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.mt16} />
                }>
                <Box
                    justifyContent="space-between"
                    direction="row"
                    mt={SpacingValue["space-medium"]}>
                    <Slate height={16} width={146} borderRadius={8} />
                    <Slate height={16} width={60} borderRadius={8} />
                </Box>
                <Box
                    justifyContent="space-between"
                    direction="row"
                    mt={SpacingValue["space-medium"]}>
                    <Slate height={16} width={124} borderRadius={8} />
                    <Slate height={16} width={60} borderRadius={8} />
                </Box>
                <Box
                    justifyContent="space-between"
                    direction="row"
                    mt={SpacingValue["space-medium"]}>
                    <Slate height={16} width={180} borderRadius={8} />
                    <Slate height={16} width={60} borderRadius={8} />
                </Box>
            </Stack>
        </Card>
    );
};

const styles = StyleSheet.create({
    mt16: {
        marginTop: SpacingValue["space-medium"],
    },
});

export const BillLoader = React.memo(BillLoadingComponent);
