import * as React from "react";
import { StyleSheet } from "react-native";

import { Button, SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";
import {
    Box,
    Display,
    ScreenSize,
    Stack,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { ThreeDotsLoader } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";
import { StarSellerBadge, useStarSeller } from "@swiggy-private/connect-business-commons";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useFollowUnfollowStore } from "@minis-consumer/hooks/use-store-follow";
import { Analytics } from "@minis-consumer/analytics";
import { useFollowersCountState } from "@minis-consumer/hooks/use-followers-count-state";
import { getNumberInShorthandFormat } from "@minis-consumer/helpers/number";
import { useStarSellerInfo } from "@minis-consumer/hooks/use-star-seller-info";
import { StarSellerInfo } from "@minis-consumer/components/star-seller-info";
import { SF_COPIES } from "@minis-consumer/routes/shop/constants";

const ICON_SIZE = 16;

export const AboutHeader: React.FC = () => {
    const storeInfo = useStoreInfo();
    const followersCountState = useFollowersCountState();

    const {
        following: followingStore,
        trigger: followTrigger,
        loading: followLoading,
        canFollow,
    } = useFollowUnfollowStore();

    const isStarSeller = useStarSeller(storeInfo.badges);

    const isStoreEnabled = storeInfo.enabled !== false;

    const showFollowUnfollowChatCtas = React.useMemo(
        () => canFollow && (isStoreEnabled || (!isStoreEnabled && followingStore)),
        [canFollow, followingStore, isStoreEnabled],
    );

    const { value: theme } = useTheme();

    const {
        showStarSellerInfo,
        onCloseStarSellerInfo,
        onStarSellerBadgeMount,
        onStarSellerBadgePress,
    } = useStarSellerInfo(storeInfo.storeId);

    const storeCategories = React.useMemo(
        () => storeInfo.categories?.join(", "),
        [storeInfo.categories],
    );

    const followers = getNumberInShorthandFormat(
        followersCountState[storeInfo.storeId].followersCount ?? storeInfo.followersCount,
    );

    const storeNameCategory: TextProps["category"] = useSelectScreen({
        lg: "h3",
        default: "h4",
    });

    const pressHandler = React.useCallback((): void => {
        Analytics.clickEvent({ category: "follow-store-btn" });
        followTrigger();
    }, [followTrigger]);

    useMount(() => {
        if (showFollowUnfollowChatCtas) {
            Analytics.impressionEvent({
                category: "follow-store-btn",
            });
        }
    });

    return (
        <Stack direction={{ default: "row" }} spacing={SpacingValue["space-medium"]}>
            <Stack flex={1} justifyContent="space-between">
                <Stack direction="row" justifyContent="space-between">
                    <Box flex={1}>
                        <Text category={storeNameCategory} weight="bold" color="highest">
                            {storeInfo.name}
                        </Text>

                        {storeCategories && isStoreEnabled ? (
                            <Text
                                category="b3"
                                color="color-basic-45"
                                numberOfLines={1}
                                style={styles.categories}>
                                {storeCategories}
                            </Text>
                        ) : null}

                        {!isStoreEnabled && storeCategories ? (
                            <Display gt={ScreenSize.Medium}>
                                <Text category="b3" color="color-basic-45" numberOfLines={1}>
                                    {storeCategories}
                                </Text>
                            </Display>
                        ) : null}

                        {!isStoreEnabled ? (
                            <Display lt={ScreenSize.Normal}>
                                <Text category="b2" color="color-basic-60">
                                    --
                                </Text>
                            </Display>
                        ) : null}
                    </Box>

                    {showFollowUnfollowChatCtas ? (
                        <Button
                            onPress={pressHandler}
                            elevation={2}
                            color="tertiary"
                            size="small"
                            accessoryAlignment="end"
                            disabled={followLoading}
                            style={[styles.btn, { borderColor: theme["color-primary"] }]}
                            accessoryLeft={
                                followLoading || followingStore ? undefined : (
                                    <SvgIcon
                                        icon="Heart"
                                        accessible={false}
                                        color="color-primary"
                                        height={ICON_SIZE}
                                        width={ICON_SIZE}
                                    />
                                )
                            }>
                            {followLoading ? (
                                <ThreeDotsLoader color="color-primary" />
                            ) : followingStore ? (
                                <SvgIcon
                                    icon="HeartFilled"
                                    accessible={false}
                                    height={ICON_SIZE}
                                    width={ICON_SIZE}
                                    color="color-primary-400"
                                />
                            ) : (
                                "Love this"
                            )}
                        </Button>
                    ) : null}
                </Stack>

                {isStoreEnabled ? (
                    <>
                        <Box
                            mt={SpacingValue["space-medium"]}
                            flex={1}
                            direction="row"
                            alignItems="center">
                            <Box>
                                <SvgIcon
                                    icon="HeartFilled"
                                    height={16}
                                    width={16}
                                    color="color-primary-400"
                                />
                            </Box>
                            <Text
                                category="b1"
                                weight="medium"
                                color="color-basic-60"
                                numberOfLines={1}
                                style={styles.followers}>
                                {`${followers} ${SF_COPIES.LOVE_THIS}`}
                            </Text>

                            {isStarSeller ? (
                                <>
                                    <Text
                                        category="b1"
                                        weight="medium"
                                        color="color-basic-60"
                                        style={styles.dot}>
                                        •
                                    </Text>
                                    <StarSellerBadge
                                        showPrimaryText
                                        showSecondaryText
                                        rootContainerStyle={styles.badgeContainer}
                                        onPress={onStarSellerBadgePress}
                                        onMount={onStarSellerBadgeMount}
                                    />
                                    <StarSellerInfo
                                        storeId={storeInfo.storeId}
                                        showDialog={showStarSellerInfo}
                                        closeDialog={onCloseStarSellerInfo}
                                    />
                                </>
                            ) : null}
                        </Box>

                        {storeInfo?.settings?.storeBio ? (
                            <Text category="b1" color="color-basic-60" style={styles.storeBioText}>
                                {storeInfo.settings.storeBio}
                            </Text>
                        ) : null}
                    </>
                ) : null}
            </Stack>
        </Stack>
    );
};

const styles = StyleSheet.create({
    btn: {
        height: 36,
        alignSelf: "center",
        shadowColor: "transparent",
        paddingHorizontal: SpacingValue["space-small"],
    },
    storeBioText: {
        marginTop: SpacingValue["space-small"],
    },
    badgeContainer: {
        paddingLeft: 0,
    },
    dot: {
        marginHorizontal: SpacingValue["space-x-small"],
    },
    categories: {
        maxWidth: "90%",
    },
    followers: {
        flexShrink: 1,
        marginLeft: SpacingValue["space-xx-small"],
    },
});
