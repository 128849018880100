export const PRODUCT_DETAILS_TITLE: Record<string, string> = {
    DIGITIAL: "What will you get?",
    PLAN: "Plan Details",
    APPOINTMENT: "Meeting Details",
    DEFAULT: "Details",
};

export const TEST_IDS = {
    PRESSABLE: "show-it-works-pressable",
    TITLE: "show-it-works-title",
    CHEVRON: "show-it-works-chevron",
};
