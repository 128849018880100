import * as React from "react";
import { StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Text, SpacingValue } from "@swiggy-private/rn-dls";

type HeaderLeftProps = {
    onClose: () => void;
};

export const HeaderLeft: React.FC<HeaderLeftProps> = ({ onClose }) => {
    return (
        <Stack
            direction="row"
            spacing={SpacingValue["space-medium"]}
            alignItems="center"
            style={styles.container}>
            <SvgIcon icon="Close" height={24} width={24} onPress={onClose} />
            <Text category="b1" weight="bold" color="highest">
                Confirm delivery address details
            </Text>
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: { paddingLeft: SpacingValue["space-medium"] },
});
