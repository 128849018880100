import React, { memo, useCallback, useEffect, useState } from "react";
import { StyleSheet, useWindowDimensions, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import getUnixTime from "date-fns/getUnixTime";
import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import startOfDay from "date-fns/startOfDay";
import isBefore from "date-fns/isBefore";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import { DatePickerProps } from "react-native-date-picker";

import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { ActivityIndicator, Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { InView } from "@swiggy-private/react-native-ui";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

import { getSlotFromDateAndTime } from "@minis-consumer/helpers/date";
import { Analytics } from "@minis-consumer/analytics";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useGetProductSlots } from "@minis-consumer/hooks/use-get-product-slots";
import { HeaderLeft } from "@minis-consumer/routes/slot-selection/components/header-left";
import {
    formatSelectedSlot,
    FormattedDate,
    generateDateRange,
    getSelectedTitles,
    ISlotSelectionFormComponent,
    TimeSlot,
} from "@minis-consumer/routes/slot-selection/helpers";
import { LABELS, DEFAULT_DATES } from "@minis-consumer/routes/slot-selection/constants";
import { SelectionList } from "@minis-consumer/routes/slot-selection/components/form-new/components/selection-list";

const SlotSelectionFormComponent: React.FC<ISlotSelectionFormComponent> = ({
    onProceed,
    analyticsData,
    slotData,
    productId,
    selectedSlotInEpoch = 0,
    vacationDays,
    isCustom,
    headerSubTitle,
}) => {
    const { value: theme } = useTheme();
    const isDesktop = useIsDesktop();
    const storeInfo = useStoreInfo();
    const { height: windowHeight } = useWindowDimensions();
    const insets = useSafeAreaInsets();

    // TODO: Refactor/Move this function to helper as a FF
    const getSelectedDateWithStartOfDay = React.useCallback(() => {
        if (!selectedSlotInEpoch) {
            return getUnixTime(startOfDay(new Date()));
        }

        const hours = getHours(selectedSlotInEpoch * 1000) * 60 * 60;
        const minutes = getMinutes(selectedSlotInEpoch * 1000) * 60;

        return selectedSlotInEpoch - (hours + minutes);
    }, [selectedSlotInEpoch]);

    const dateSlots = slotData.dateSlots[0].slots ?? [];

    const [selectedDate, setSelectedDate] = useState(getSelectedDateWithStartOfDay());
    const [selectedTime, setSelectedTime] = useState(selectedSlotInEpoch);
    const [data, setData] = React.useState<TimeSlot[] | null>(dateSlots);

    const allDates = generateDateRange();

    const orderedDates = allDates.filter((date) => {
        return !vacationDays?.includes(date.dateEpoch);
    });

    const {
        slotData: slotDataResponse,
        loading: isLoading,
        fetchProductSlotData,
    } = useGetProductSlots({
        storeId: storeInfo.storeId,
        productId: productId,
        custom: isCustom,
        date: selectedDate,
    });

    const slots = React.useMemo(
        () => data ?? slotDataResponse?.dateSlots[0]?.slots,
        [data, slotDataResponse?.dateSlots],
    );

    const onDateChange = useCallback(
        (date: FormattedDate) => {
            setData(null);
            setSelectedDate(date.dateEpoch);
            setSelectedTime(0);
            fetchProductSlotData(date.dateEpoch);
        },
        [fetchProductSlotData],
    );

    const onTimeChange = useCallback((timeSlot: TimeSlot) => {
        setSelectedTime(timeSlot.start);
    }, []);

    const selectedSlot = formatSelectedSlot(selectedTime, selectedDate);

    const onPressHandler = useCallback(() => {
        onProceed?.(selectedDate, selectedTime, selectedSlot);
    }, [onProceed, selectedDate, selectedTime, selectedSlot]);

    // TODO: Refactor/Move this block to helper as a FF
    const minuteInterval = DEFAULT_DATES.MIN_INTERVAL as DatePickerProps["minuteInterval"];

    const desktopContainerStyles: ViewStyle | undefined = useSelectScreen({
        lg: {
            width: 500,
            alignSelf: "center",
        },
        md: {
            width: 500,
            alignSelf: "center",
        },
        default: undefined,
    });

    const containerStyles: ViewStyle = {
        backgroundColor: theme["color-basic-0"],
    };

    const mainContainerStyles: ViewStyle = useSelectScreen({
        lg: {
            backgroundColor: theme["color-basic-5"],
        },
        md: {
            backgroundColor: theme["color-basic-5"],
        },
        default: {
            backgroundColor: theme["color-basic-0"],
        },
    });

    const headerStyles: ViewStyle = {
        borderBottomWidth: 1,
        borderBottomColor: theme["color-basic-15"],
        backgroundColor: theme["color-basic-0"],
    };

    const maxHeight = React.useMemo(
        () => windowHeight * 0.85 - 280 - insets.bottom + SpacingValue["space-small"],
        [insets.bottom, windowHeight],
    );

    const maxHeightStyle: ViewStyle = useSelectScreen({
        lg: {
            maxHeight: maxHeight - 2 * SpacingValue["space-xx-large"],
        },
        default: {
            maxHeight: maxHeight,
        },
    });

    const buttonStyles: ViewStyle = {
        paddingBottom: insets.bottom + SpacingValue["space-small"],
    };

    // TODO: Refactor/Move this function to helper as a FF
    /**
     * Since we are using a single datepicker component to set both
     * date and time, we need to keep validating the selected slot based
     * on the selected date as all slots shall be available for future dates
     */
    const getMinDate = React.useCallback(() => {
        if (!minuteInterval) {
            return new Date();
        }

        const selectedDateTimeInMillis = selectedDate * 1000;

        if (isAfter(selectedDateTimeInMillis, new Date())) {
            return startOfDay(new Date());
        }

        if (isSameDay(selectedDateTimeInMillis, new Date())) {
            const coeff = 1000 * 60 * minuteInterval;
            const date = new Date();
            const rounded = new Date(Math.ceil(date.getTime() / coeff) * coeff);

            return rounded;
        }

        return startOfDay(selectedDateTimeInMillis ? selectedDateTimeInMillis : new Date());
    }, [minuteInterval, selectedDate]);

    const isPastSlotSelected = React.useMemo(() => {
        const slotStartDateInEpoch = getSlotFromDateAndTime(
            selectedDate * 1000,
            selectedTime * 1000,
        );

        const minDate = getMinDate();

        return slotStartDateInEpoch ? isBefore(slotStartDateInEpoch, getUnixTime(minDate)) : false;
    }, [selectedDate, selectedTime, getMinDate]);

    const isWayPastMaxSlotSelected = React.useMemo(() => {
        const slotStartDateInEpoch = getSlotFromDateAndTime(
            selectedDate * 1000,
            selectedTime * 1000,
        );

        return slotStartDateInEpoch ? isAfter(slotStartDateInEpoch, DEFAULT_DATES.MAX_DATE) : false;
    }, [selectedDate, selectedTime]);

    const errorMessage = React.useMemo(
        () =>
            isPastSlotSelected
                ? LABELS.PAST_SLOT_ERROR_MESSAGE
                : isWayPastMaxSlotSelected
                ? LABELS.FUTURE_SLOT_ERROR_MESSAGE
                : "",
        [isPastSlotSelected, isWayPastMaxSlotSelected],
    );

    const infoContainerStyles: ViewStyle = {
        backgroundColor: errorMessage ? theme["color-critical-25"] : theme.rain_Sub,
    };

    const isDisabled =
        !selectedDate || !selectedTime || isPastSlotSelected || isWayPastMaxSlotSelected;

    const { title, subtitle } = getSelectedTitles(orderedDates, selectedDate, selectedSlotInEpoch);

    useEffect(() => {
        if (slotData?.duration) {
            slotData?.duration && Analytics.impressionEvent(analyticsData);
        }

        if (selectedDate > 0 && slots && slots.length === 0) {
            Analytics.impressionEvent({
                category: "all-slot-booked",
                context: JSON.stringify(analyticsData),
            });
        }
    }, [slotData?.duration, analyticsData, slots, selectedDate]);

    if (!slotData?.duration) {
        return null;
    }

    return (
        <InView style={[styles.inviewContainer, mainContainerStyles]}>
            <Stack
                flex={1}
                style={[
                    styles.mainContainer,
                    desktopContainerStyles,
                    containerStyles,
                    { borderTopColor: theme["color-basic-15"] },
                ]}>
                {isDesktop ? (
                    <Box pv={20} style={headerStyles}>
                        <HeaderLeft productId={productId} subHeaderText={headerSubTitle} />
                    </Box>
                ) : null}
                <Stack flex={1} justifyContent="space-between">
                    <Stack spacing={SpacingValue["space-xx-large"]} style={styles.contentContainer}>
                        <Stack direction={"column"} spacing={SpacingValue["space-medium"]}>
                            <Box
                                gap={SpacingValue["space-medium"]}
                                direction="row"
                                alignItems="center">
                                <Text category="b3" weight="regular" color="text_High_Emphasis">
                                    {title}
                                </Text>

                                <DashBorderLine
                                    borderColor={theme["color-basic-15"]}
                                    style={styles.line}
                                    type="solid"
                                />
                            </Box>

                            <Box mr={-SpacingValue["space-medium"]}>
                                <SelectionList
                                    dates={orderedDates}
                                    onDateChange={onDateChange}
                                    onTimeChange={onTimeChange}
                                    selectedDate={selectedDate}
                                    selectedTime={selectedTime}
                                />
                            </Box>
                        </Stack>

                        <Stack spacing={SpacingValue["space-medium"]} flex={0}>
                            <Box gap={16} direction="row" alignItems="center">
                                <Text category="b1" color="color-basic-75" weight="bold">
                                    {subtitle}
                                </Text>

                                <DashBorderLine
                                    borderColor={theme["color-basic-15"]}
                                    style={styles.line}
                                    type="solid"
                                />
                            </Box>

                            <Box style={maxHeightStyle}>
                                {isLoading ? (
                                    <ActivityIndicator />
                                ) : (
                                    <SelectionList
                                        timeList
                                        times={slots}
                                        onDateChange={onDateChange}
                                        onTimeChange={onTimeChange}
                                        selectedDate={selectedDate}
                                        selectedTime={selectedTime}
                                    />
                                )}
                            </Box>
                        </Stack>
                    </Stack>

                    <Stack>
                        {!isDisabled || errorMessage ? (
                            <Stack
                                style={[styles.infoContainer, infoContainerStyles]}
                                justifyContent="center">
                                <Text
                                    category="b2"
                                    weight="medium"
                                    color={errorMessage ? "color-critical" : "text_High_Emphasis"}
                                    style={errorMessage ? {} : styles.selectedSlotText}>
                                    {errorMessage || `Selected slot : ${selectedSlot}`}
                                </Text>
                            </Stack>
                        ) : null}

                        <Stack style={[styles.buttonContainer, buttonStyles]}>
                            <Button
                                onPress={onPressHandler}
                                disabled={isDisabled}
                                color="rn_dls_primary">
                                {LABELS.PROCEED}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </InView>
    );
};

const styles = StyleSheet.create({
    inviewContainer: {
        flex: 1,
    },
    mainContainer: {
        borderTopWidth: 1,
    },
    contentContainer: {
        borderBottomLeftRadius: 24,
        borderBottomRightRadius: 24,
        paddingVertical: SpacingValue["space-x-large"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    buttonContainer: {
        paddingTop: SpacingValue["space-small"],
        paddingBottom: SpacingValue["space-large"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    infoContainer: {
        paddingVertical: SpacingValue["space-x-small"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    line: {
        marginTop: 0,
        flex: 1,
    },
    selectedSlotText: {
        color: "#5134B6",
    },
    mb2: {
        marginBottom: SpacingValue["space-small"],
    },
});

export const SlotSelectionForm = memo(SlotSelectionFormComponent);
