import * as React from "react";
import { ViewStyle } from "react-native";

import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, TouchableComponent, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { Divider } from "@minis-consumer/components/divider";

export const CommonTile: React.FC<
    React.PropsWithChildren<{
        onPress: () => void;
        style?: ViewStyle;
        isDividerPresent?: boolean;
        id?: string;
        iconPresent?: boolean;
        canBeHovered?: boolean;
    }>
> = ({
    children,
    isDividerPresent = true,
    style,
    onPress,
    iconPresent = true,
    canBeHovered = true,
}) => {
    const { value: theme } = useTheme();
    const bgColor = theme["color-background-primary"];

    const [hovered, setHovered] = React.useState(false);

    const bgStyle = hovered ? theme["color-basic-alpha-5"] : bgColor;

    const containerStyle: ViewStyle = useSelectScreen({
        lg: {
            paddingHorizontal: SpacingValue["space-x-large"],
            paddingVertical: 28,
            backgroundColor: bgStyle,
        },
        default: {
            paddingHorizontal: SpacingValue["space-medium"],
            paddingVertical: 18,
            backgroundColor: bgStyle,
        },
    });

    return (
        <TouchableComponent
            onPress={onPress}
            onHoverIn={() => {
                canBeHovered && setHovered(true);
            }}
            onHoverOut={() => {
                canBeHovered && setHovered(false);
            }}>
            <Box
                direction="row"
                style={[containerStyle, style]}
                justifyContent="space-between"
                alignItems="center">
                <Box flex={1}>{children}</Box>
                {iconPresent ? (
                    <SvgIcon icon={"ChevronRight"} fontSize="12" color="color-basic-60" />
                ) : null}
            </Box>
            {isDividerPresent ? <Divider /> : null}
        </TouchableComponent>
    );
};
