export const HEADER_COPIES = {};

export const DISPLAY_COPIES = {
    HEADER: {
        TITLE: "Request a Return or Exchange",
        ORDER: "Order #",
    },
    CARD: {
        TITLE: "Need to make a return?",
        SUBTEXT: "Select the items you wish to return from your order",
        CONTINUE: "Continue",
    },
};
