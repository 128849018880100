import * as React from "react";
import { Pressable, StyleSheet, useWindowDimensions } from "react-native";
import { useIsFocused } from "@react-navigation/core";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { Text, useTheme, SpacingValue } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { StoreMedia } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";
import { format } from "@swiggy-private/common-helpers";

import { Analytics } from "@minis-consumer/analytics";
import { STORE_BRAND_FALLBACK } from "@minis-consumer/components/widgets/constants";

type SellerMsgNudgeProps = {
    storeName: string;
    onPress: VoidFunction;

    storeLogo?: string;
};

const NOTE_MSG = "You’ve a note from {0}";

const SellerMsgNudgeComponent: React.FC<SellerMsgNudgeProps> = ({
    onPress,
    storeName,
    storeLogo,
}) => {
    const { value: theme } = useTheme();

    const { width: windowWidth } = useWindowDimensions();
    const isFocused = useIsFocused();

    const menuBtnWidth = Math.min(144, windowWidth * 0.38);

    const showMenu = React.useCallback(() => {
        onPress();
        Analytics.clickEvent({ category: "categories-floating-btn" });
    }, [onPress]);

    const menuBtnExtraStyles = {
        minWidth: menuBtnWidth,
    };

    useMount(() => {
        Analytics.impressionEvent({ category: "categories-floating-btn" });
    });

    return (
        <Box style={[styles.container]} pointerEvents={isFocused ? "box-none" : "none"}>
            <Pressable
                onPress={showMenu}
                testID="categories-floating-btn"
                style={[
                    styles.menuBtn,
                    menuBtnExtraStyles,
                    { backgroundColor: theme["color-basic-75"] },
                ]}>
                <Stack direction="row" alignItems="center">
                    <StoreMedia
                        isImageKitEnabled
                        mediaId={storeLogo ?? STORE_BRAND_FALLBACK}
                        style={styles.image}
                        width={28}
                        height={28}
                        showLoader
                    />
                    <Box style={{ maxWidth: "80%" }} mr={4}>
                        <Text category="b1" color="color-basic-0" numberOfLines={1}>
                            {format(NOTE_MSG, storeName)}
                        </Text>
                    </Box>
                    <SvgIcon
                        icon="ChevronDown"
                        color="color-basic-0"
                        height={SpacingValue["space-large"]}
                        style={{ marginLeft: "auto" }}
                    />
                </Stack>
            </Pressable>
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        alignSelf: "center",
    },
    menuBtn: {
        borderWidth: 0,
        borderRadius: 30,
        paddingVertical: SpacingValue["space-xx-small"],
        paddingHorizontal: SpacingValue["space-xx-small"],
        paddingRight: SpacingValue["space-x-small"],
    },
    image: {
        borderRadius: 28,
        marginRight: SpacingValue["space-small"] / 2,
    },
});

export const SellerMsgNudge = React.memo(SellerMsgNudgeComponent);
