import React from "react";

import { usePaymentReducer } from "../hooks/use-reducer";
import { PaymentLayoutContextProvider } from "./payment-layout-context";

type PaymentReducerType = ReturnType<typeof usePaymentReducer>;
type State = PaymentReducerType[0];
type Dispatch = PaymentReducerType[1];

export const getInitialPaymentState = (): State => ({
    paymentAmount: "",
    paymentMethods: {
        paymentGroup: [],
    },
    order: null,
    paymentInProgress: false,
    payments: {
        active: true,
    },
    selectedPaymentMethod: {
        category: undefined,
        type: undefined,
        value: undefined,
        extraData: undefined,
    },
});

export const PaymentStateContext = React.createContext<State>(getInitialPaymentState());
// eslint-disable-next-line no-void
export const PaymentDispatchContext = React.createContext<Dispatch>(() => void 0);

export const PaymentContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [state, dispatch] = usePaymentReducer();

    return (
        <PaymentLayoutContextProvider>
            <PaymentStateContext.Provider value={state}>
                <PaymentDispatchContext.Provider value={dispatch}>
                    {children}
                </PaymentDispatchContext.Provider>
            </PaymentStateContext.Provider>
        </PaymentLayoutContextProvider>
    );
};
