import * as React from "react";
import {
    useWindowDimensions,
    StyleSheet,
    Platform,
    InteractionManager,
    Animated,
    ViewStyle,
    StyleProp,
} from "react-native";
import { useIsFocused } from "@react-navigation/core";

import { useMount, useMountedRef } from "@swiggy-private/react-hooks";
import { BottomSheet, CdnImage } from "@swiggy-private/react-native-ui";
import {
    Button,
    DialogContent,
    Portal,
    SpacingValue,
    Text,
    useAnimatedValue,
    useLayout,
    useTheme,
} from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { ShopLogo } from "@minis-consumer/components/shop-logo";
import { getStoreBrandImageUrl } from "@minis-consumer/helpers/store";
import { MINIS_LOGO_ORANGE } from "@minis-consumer/constants/images";
import { STORE_ONBOARDING_CONFETTI } from "@minis-consumer/lotties";

import { STORE_ONBOARDING_BODY_POINTS } from "./constants";
import { LottieAnimationContainer } from "./components/lottie-container";

const isNative = Platform.OS !== "web";

type StoreOnboardingBottomsheetProps = {
    onClose?: (b: boolean) => void;
};

/**
 * Onboarding component for the consumers who land on either of **Shop** or **PDP** screen from a social media link. This has to be shown only **once** in a customer's journey
 */
const StoreOnboardingBottomsheetComponent: React.FC<StoreOnboardingBottomsheetProps> = ({
    onClose,
}) => {
    const storeInfo = useStoreInfo();
    const { width: windowSize, height: windowHeight } = useWindowDimensions();
    const mounted = useMountedRef();
    const isFocused = useIsFocused();

    const [layout, onLayout] = useLayout();
    const animatedValue = useAnimatedValue(0);
    const { value: theme } = useTheme();

    const logoWidth = React.useMemo(() => Math.min(135, windowSize * 0.35), [windowSize]);
    const logoHeight = React.useMemo(() => logoWidth * 0.4, [logoWidth]);
    const logoUrl = React.useMemo(
        () => getStoreBrandImageUrl(storeInfo, { height: logoHeight, width: logoWidth }, true),
        [storeInfo, logoHeight, logoWidth],
    );

    const [show, setShow] = React.useState(false);

    const lottieStyle: ViewStyle = React.useMemo(() => {
        return {
            marginTop: layout.measured ? windowHeight - layout.height - logoHeight : undefined,
        };
    }, [layout.height, layout.measured, logoHeight, windowHeight]);

    const contentAnimatedStyle = React.useMemo(() => {
        const style: StyleProp<ViewStyle | Animated.WithAnimatedObject<ViewStyle>> = {
            opacity: animatedValue.interpolate({
                inputRange: [0.95, 1],
                outputRange: [0, 1],
            }),
        };

        return style as StyleProp<ViewStyle>;
    }, [animatedValue]);

    const dialogAnimatedStyle = React.useMemo(() => {
        const style: StyleProp<ViewStyle | Animated.WithAnimatedObject<ViewStyle>> = {
            backgroundColor: theme["color-background-primary"],
            transform: [
                {
                    translateY: animatedValue.interpolate({
                        inputRange: [0, 0.25, 0.5, 0.75, 1],
                        outputRange: [
                            layout.height,
                            layout.height / 3,
                            layout.height / 3,
                            layout.height / 3,
                            0,
                        ],
                    }),
                },
            ],
        };

        return style as StyleProp<ViewStyle>;
    }, [animatedValue, layout.height, theme]);

    const shopLogoAnimatedStyle = React.useMemo(() => {
        const style: StyleProp<ViewStyle | Animated.WithAnimatedObject<ViewStyle>> = {
            transform: [
                {
                    translateY: animatedValue.interpolate({
                        inputRange: [0, 0.25, 0.5, 0.75, 1],
                        outputRange: [0, layout.height, layout.height / 3, layout.height / 3, 0],
                    }),
                },
                {
                    scale: animatedValue.interpolate({
                        inputRange: [0, 0.25, 0.5, 0.75, 1],
                        outputRange: [0, 0.75, 2, 2, 1],
                    }),
                },
            ],
        };

        return style as StyleProp<ViewStyle>;
    }, [animatedValue, layout.height]);

    const onDismiss = React.useCallback(() => {
        Animated.timing(animatedValue, {
            toValue: 0,
            duration: 100,
            useNativeDriver: Platform.OS !== "web",
        }).start((result) => {
            if (result.finished) {
                setShow(false);
                onClose?.(false);
            }
        });
    }, [animatedValue, onClose]);

    useMount(() => {
        let timer: number | null = null;
        const task = InteractionManager.runAfterInteractions(() => {
            timer = setTimeout(() => mounted.current && setShow(true), 100) as unknown as number;
        });

        return () => {
            task.cancel();
            timer && clearTimeout(timer);
        };
    });

    React.useEffect(() => {
        Animated.timing(animatedValue, {
            toValue: 1,
            duration: 1200,
            useNativeDriver: Platform.OS !== "web",
        }).start();
    }, [animatedValue]);

    if (!isNative) {
        return null;
    }

    const minisLogoStyle = {
        width: logoWidth,
        height: logoHeight,
    };

    const canShowDialog = isFocused && show;

    if (!canShowDialog) {
        return null;
    }

    return (
        <>
            <BottomSheet
                open={canShowDialog}
                onClose={onDismiss}
                style={styles.mainContainer}
                animate={false}>
                <Animated.View
                    onLayout={onLayout}
                    style={[styles.mainContainer, dialogAnimatedStyle]}>
                    <DialogContent>
                        <Animated.View style={[styles.shopLogo, shopLogoAnimatedStyle]}>
                            <ShopLogo isImageKitEnabled url={logoUrl} borderRadius={20} />
                        </Animated.View>

                        <Animated.View style={contentAnimatedStyle}>
                            <Stack spacing={SpacingValue["space-xx-large"]} style={styles.content}>
                                <Stack
                                    spacing={SpacingValue["space-x-small"]}
                                    justifyContent="center"
                                    alignItems="center">
                                    <Stack
                                        spacing={SpacingValue["space-xx-small"]}
                                        alignItems="center">
                                        <Text category="b1" weight="bold" color="high">
                                            {storeInfo.name}
                                        </Text>
                                        <Text
                                            category="b1"
                                            weight="medium"
                                            color="color-tertiary-300">
                                            welcomes you to
                                        </Text>
                                    </Stack>

                                    <CdnImage
                                        isImageKitEnabled
                                        id={MINIS_LOGO_ORANGE}
                                        style={minisLogoStyle}
                                    />
                                </Stack>

                                <Stack spacing={SpacingValue["space-x-large"]}>
                                    <Text category="h4" color="high">
                                        What&apos;s Minis anyway?
                                    </Text>

                                    {STORE_ONBOARDING_BODY_POINTS.map((point, idx) => (
                                        <Stack
                                            key={idx}
                                            direction="row"
                                            alignItems="center"
                                            spacing={SpacingValue["space-medium"]}>
                                            <CdnImage
                                                isImageKitEnabled
                                                id={point.imgId}
                                                width={point.width}
                                                height={point.height}
                                            />
                                            <Text
                                                category="b1"
                                                weight="bold"
                                                color="high"
                                                style={styles.txt}>
                                                {point.content}
                                            </Text>
                                        </Stack>
                                    ))}

                                    <Button style={styles.btn} onPress={onDismiss}>
                                        Let&apos;s go!
                                    </Button>
                                </Stack>
                            </Stack>
                        </Animated.View>
                    </DialogContent>
                </Animated.View>
            </BottomSheet>

            <Portal>
                <Animated.View style={[lottieStyle, contentAnimatedStyle]}>
                    <LottieAnimationContainer lottiePath={STORE_ONBOARDING_CONFETTI} />
                </Animated.View>
            </Portal>
        </>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        backgroundColor: "transparent",
    },
    content: {
        marginTop: SpacingValue["space-x-large"],
    },
    shopLogo: {
        position: "absolute",
        alignSelf: "center",
        top: -30,
    },
    txt: {
        width: "75%",
    },
    btn: {
        marginTop: SpacingValue["space-xx-small"],
        shadowColor: "transparent",
    },
});

export const StoreOnboardingBottomsheet = React.memo(StoreOnboardingBottomsheetComponent);
