import React from "react";
import { Platform, StyleSheet, Image } from "react-native";

import { CdnImage, InView } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { RadioButton, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import {
    CUSTOM_UPI_LABEL,
    PAYMENT_GROUP_CATEGORY,
    PAYMENT_GROUP_METHOD,
    UPI_APPS,
} from "@minis-consumer/routes/payment/constants";
import { usePaymentOrder } from "@minis-consumer/routes/payment/hooks/use-payment-order";
import { useSelectedPaymentMethod } from "@minis-consumer/routes/payment/hooks/use-selector";
import { getFormattedTitle } from "@minis-consumer/routes/payment/helpers";

interface Props {
    title: string;
    logo: string;
    appSlug: string;
    onPress?: () => void;
}

const PaymentAppComponent: React.FC<Props> = ({ logo, title, appSlug, onPress: onPressProp }) => {
    const { value: theme } = useTheme();
    const order = usePaymentOrder();

    const isPaymentSelected = useSelectedPaymentMethod({
        category: PAYMENT_GROUP_CATEGORY.NEW,
        type: PAYMENT_GROUP_METHOD.UPI,
        value: appSlug,
    });

    const onImpression = React.useCallback(() => {
        Analytics.impressionEvent({
            category: "new-payment-options-card-upi-app-tile",
            label: order?.id,
            context: title,
        });
    }, [order?.id, title]);

    const onPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: "new-payment-options-card-upi-app-tile",
            label: order?.id,
            context: title,
        });

        onPressProp?.();
    }, [onPressProp, order?.id, title]);

    const imageComponent = React.useMemo(() => {
        if (Platform.OS === "android" && title !== CUSTOM_UPI_LABEL) {
            return (
                <Image
                    resizeMode="contain"
                    style={styles.imageStyles}
                    source={{ uri: `data:image/png;base64,${logo}` }}
                />
            );
        }

        return (
            <CdnImage
                isImageKitEnabled
                id={logo}
                resizeMode={"contain"}
                height={32}
                width={38}
                format={"png"}
            />
        );
    }, [logo, title]);

    const formattedTitle =
        title === CUSTOM_UPI_LABEL ? UPI_APPS.CUSTOM_UPI.name : getFormattedTitle(title);

    return (
        <InView onChange={onImpression} pressableProps={{ onPress }}>
            <Stack direction={"row"} justifyContent="space-between" alignItems="center">
                <Stack spacing={SpacingValue["space-large"]} direction="row" alignItems="center">
                    <Box style={[styles.logoContainer, { borderColor: theme["color-basic-15"] }]}>
                        {imageComponent}
                    </Box>

                    <Text category="b1" weight="medium" color="high">
                        {formattedTitle}
                    </Text>
                </Stack>
                <Box style={{ paddingRight: SpacingValue["space-xx-small"] }}>
                    <RadioButton onChange={onPress} checked={isPaymentSelected} />
                </Box>
            </Stack>
        </InView>
    );
};

export const PaymentApp = React.memo(PaymentAppComponent);

const styles = StyleSheet.create({
    logoContainer: {
        width: 40,
        height: 40,
        borderRadius: 24,
        borderWidth: 1,
        padding: 16,
        justifyContent: "center",
        alignItems: "center",
    },
    imageStyles: {
        height: 32,
        width: 32,
    },
});
