import React, { useMemo } from "react";

import { Text, TextProps } from "@swiggy-private/rn-dls";

import { Product } from "@minis-consumer/interfaces/catalog";
import { convertToReadableDateAndTimeIST } from "@minis-consumer/helpers/date";
import { LocalCartItem } from "@minis-consumer/interfaces/cart";
import { getProductDurationUnitText } from "@minis-consumer/helpers/catalog";

interface SelectedSlotDataProps {
    slot?: LocalCartItem["slot"];
    productDuration?: Product["productDuration"];
    textProps?: TextProps;
}

const SelectedSlotDataComponent: React.FC<SelectedSlotDataProps> = ({
    slot,
    productDuration,
    textProps = {},
}) => {
    const slotText = useMemo(() => {
        const { value: durationValue, unit: durationUnit } = productDuration ?? {};

        const readableSlot = convertToReadableDateAndTimeIST(slot?.start || 0, "dd MMM, hh:mm a");

        const durationUnitText = getProductDurationUnitText({
            value: durationValue ?? 0,
            unit: durationUnit ?? "DAYS",
        });

        const readableDuration = `( ${productDuration?.value} ${durationUnitText} )`;

        return `${readableSlot} ${readableDuration}`;
    }, [slot, productDuration]);

    if (!slot || !productDuration) {
        return null;
    }

    return (
        <Text color="low" category="b3" weight="medium" {...textProps}>
            {slotText}
        </Text>
    );
};

if (__DEV__) {
    SelectedSlotDataComponent.displayName = "SelectedSlotData";
}

export const SelectedSlotData = React.memo(SelectedSlotDataComponent);
