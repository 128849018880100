import * as React from "react";
import { Platform, StyleSheet, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import {
    ConditionalJSX,
    Divider,
    FocusAwareStatusBar,
    useIsomorphicEffect,
} from "@swiggy-private/react-native-ui";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Surface } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { PhotosCarousel } from "@minis-consumer/components/photos-carousel";
import { getProductImages } from "@minis-consumer/helpers/catalog";
import { useLocalCart } from "@minis-consumer/hooks/use-cart";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Product } from "@minis-consumer/interfaces/catalog";
import { RouteList } from "@minis-consumer/interfaces/route";

import { ProductDetails } from "../../components/product-details";
import { ARD_EVENTS } from "../../constants";
import { getImageAreaWidth } from "../../helpers";
import { HeaderPDP } from "../header";
import { ProductDescription } from "../product-details/components/description";
import { OtherRecommendedProducts } from "../product-details/components/other-recommended-products";
import { ReturnPolicy } from "../product-details/components/return-policy";

const ProductFullView: React.FC<{
    product: Product;
    storeId: string;
    storeSlug: string;

    isTooltipVisible?: boolean;
    variantId?: string;
    scrollToTop?: VoidFunction;
    recommendedProductIds?: Array<string>;
}> = ({
    product,
    storeId,
    isTooltipVisible,
    storeSlug,
    variantId,
    scrollToTop,
    recommendedProductIds,
}) => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const store = useStoreInfo();

    const { settings } = useStoreInfo();

    const isDesktop = useIsDesktop();

    const dividerMargin = useSelectScreen({
        lg: SpacingValue["space-large"],
        default: SpacingValue["space-medium"],
    });

    const dividerStyle = {
        marginTop: dividerMargin,
        marginBottom: dividerMargin,
    };

    const cartView = useLocalCart(storeId);
    const cartItemsTotal = cartView.items.reduce((sum, item) => sum + item.quantity, 0) || 0;

    const inset = useSafeAreaInsets();

    const stackDirection: ViewStyle["flexDirection"] = useSelectScreen({
        lg: "row",
        md: "row",
        default: "column",
    });

    const border = useSelectScreen({
        lg: SpacingValue["space-medium"],
        md: SpacingValue["space-small"],
        default: 0,
    });

    const contentPadding = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        md: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const webStyle = isDesktop
        ? { paddingTop: contentPadding, paddingHorizontal: contentPadding }
        : null;

    const contentStyle = {
        ...webStyle,
        borderRadius: border,
        paddingBottom:
            (Platform.OS !== "web" ? inset.bottom + 20 : contentPadding) + cartItemsTotal
                ? SpacingValue["space-x-large"] // extra padding to show when cart floating tab is shown
                : 0,
    };

    const imageContainer = useSelectScreen({
        lg: {
            width: "50%",
        },
        md: {
            width: "60%",
        },
        default: {
            width: "100%",
        },
    });

    const stackSpacing = useSelectScreen({
        lg: SpacingValue["space-medium"],
        md: SpacingValue["space-x-large"],
        default: 0,
    });

    const imageSize = getImageAreaWidth({ noOffset: !isDesktop });

    const images = React.useMemo(
        () =>
            getProductImages(
                product,
                {
                    height: imageSize,
                    width: imageSize,
                    qualityAuto: "eco",
                },
                true,
            ),
        [imageSize, product],
    );

    const getContextData = React.useCallback(
        (productId: string) => {
            return JSON.stringify({
                productId,
                storeId,
            });
        },
        [storeId],
    );

    const onRecommendedProductPress = React.useCallback(
        (productId: string, _index: number) => {
            scrollToTop?.();

            Analytics.clickEvent({
                category: ARD_EVENTS.RECOMMENDED_PRODUCT_CARD,
                context: getContextData(productId),
            });

            navigation.push("Product", {
                id: productId,
                slug: store.slug,
            });
        },
        [navigation, store.slug, scrollToTop, getContextData],
    );

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerTransparent: true,
            headerShown: false,
        });
    }, [navigation, storeId, product, isTooltipVisible, storeSlug]);

    const isProductTypePhysical = product.productType === "PHYSICAL" || !product.productType;
    const hasDescription = !!product.description?.trim();
    const hasReturnPolicy = !!settings?.returnPolicy && isProductTypePhysical;
    const hasRecommendedProducts = !!recommendedProductIds?.length;

    const canShowDivider = hasDescription || hasReturnPolicy || hasRecommendedProducts;

    const ProductDetailsFC: () => JSX.Element = React.useCallback(
        () => (
            <ProductDetails
                product={product}
                variantId={variantId}
                onRecommendedProductPress={onRecommendedProductPress}
                recommendedProductIds={recommendedProductIds}
            />
        ),
        [onRecommendedProductPress, product, recommendedProductIds, variantId],
    );

    return (
        <>
            <Surface style={[styles.content, contentStyle]}>
                {!isDesktop ? <HeaderPDP {...{ storeId, product }} /> : null}
                <Stack
                    direction={stackDirection}
                    spacing={{
                        lg: stackSpacing,
                        md: stackSpacing,
                        default: stackSpacing,
                    }}>
                    <Box style={imageContainer}>
                        <PhotosCarousel
                            imageList={images}
                            size={imageSize}
                            badges={product.badges}
                            productType={product.productType}
                        />
                    </Box>
                    <ConditionalJSX condition={!isDesktop} fallback={<ProductDetailsFC />}>
                        <Box ph={contentPadding}>
                            <ProductDetailsFC />
                        </Box>
                    </ConditionalJSX>
                </Stack>

                <ConditionalJSX condition={isDesktop}>
                    <Box ph={contentPadding}>
                        {canShowDivider ? <Divider style={dividerStyle} /> : null}

                        <Stack divider={<Divider style={dividerStyle} />}>
                            {hasDescription ? (
                                <ProductDescription
                                    description={product.description}
                                    storeId={storeId}
                                    productId={product.id}
                                />
                            ) : null}

                            {hasReturnPolicy ? (
                                <ReturnPolicy
                                    policy={settings?.returnPolicy}
                                    productId={product.id}
                                />
                            ) : null}

                            {hasRecommendedProducts ? (
                                <OtherRecommendedProducts
                                    productIds={recommendedProductIds}
                                    onProductPress={onRecommendedProductPress}
                                    storeId={storeId}
                                />
                            ) : null}
                        </Stack>
                    </Box>
                </ConditionalJSX>

                <FocusAwareStatusBar
                    translucent={true}
                    backgroundColor="transparent"
                    barStyle="dark-content"
                />
            </Surface>
        </>
    );
};

const styles = StyleSheet.create({
    content: {
        flex: 1,
        elevation: 0,
    },
});

export default React.memo(ProductFullView);
