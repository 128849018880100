/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from "react";
import {
    BottomTabNavigationOptions,
    createBottomTabNavigator,
    BottomTabBar,
    BottomTabBarProps,
} from "@react-navigation/bottom-tabs";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Icon, SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Display, ScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { Platform, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMount } from "@swiggy-private/react-hooks";

import type { HomeRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { useIsNavbarVisible } from "@minis-consumer/hooks/use-is-navbar-visible";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useLocalCart } from "@minis-consumer/hooks/use-cart";
import { useSignedIn } from "@minis-consumer/hooks/use-user";
import { Analytics } from "@minis-consumer/analytics";
import { StoreHomeNavigatorId } from "@minis-consumer/constants/navigation";
import { useAuthHandler } from "@minis-consumer/hooks/use-auth-modal-handler";
import { useCartIcon } from "@minis-consumer/hooks/use-cart-icon";

const Tab = createBottomTabNavigator<HomeRouteList>();

export const HomeTabView: React.FC<NativeStackScreenProps<RouteList, "Home">> = ({ route }) => {
    const { path } = route.params;
    const { value: theme } = useTheme();
    const { screen } = route.params ?? {};

    const showNavbar = useIsNavbarVisible();
    const storeInfo = useStoreInfo();

    const [, authHandler] = useAuthHandler();

    const cartView = useLocalCart(storeInfo.storeId);
    const cartItemCount = cartView.items.reduce((sum, item) => sum + item.quantity, 0);

    const isSignedIn = useSignedIn();

    const initialRouteName = screen || "Shop";

    const screenOptions: BottomTabNavigationOptions = React.useMemo(() => {
        const headerTitleStyle = {
            fontFamily: theme["text-body-1-bold-font-family"],
            color: theme["color-basic-100"].toString(),
            fontSize: theme["text-body-1-bold-font-size"],
            lineHeight: theme["text-body-1-bold-line-height"],
            letterSpacing: theme["text-body-1-bold-letter-spacing"],
            marginTop: SpacingValue["space-xxx-small"],
            fontWeight: "bold",
        } as BottomTabNavigationOptions["headerStyle"];

        const headerBackgroundContainerStyle = {
            backgroundColor: theme["color-background-primary"].toString(),
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderBottomColor: theme["color-basic-5"],
        };

        const headerTitleContainerStyle = {
            marginHorizontal: SpacingValue["space-medium"],
        };

        return {
            tabBarStyle: {
                backgroundColor: theme["color-surface-primary"],
            },
            tabBarLabelStyle: {
                fontFamily: theme["text-body-3-regular-font-family"],
                fontSize: theme["text-body-3-regular-font-size"],
                lineHeight: theme["text-body-3-regular-line-height"],
                letterSpacing: theme["text-body-3-regular-letter-spacing"],
                marginTop: SpacingValue["space-x-small"],
            },
            tabBarItemStyle: {
                paddingVertical: SpacingValue["space-medium"],
                display: "flex",
            },
            tabBarActiveTintColor: theme["color-primary"].toString(),
            tabBarInactiveTintColor: theme["color-basic-75"].toString(),
            headerShown: !showNavbar,
            tabBarHideOnKeyboard: true,
            headerLeft: () => null,
            headerTitleStyle,
            headerBackgroundContainerStyle,
            headerTitleContainerStyle,
            headerTitleAllowFontScaling: false,
            headerLeftContainerStyle: {
                flexGrow: 0,
            },
            headerTintColor: theme["color-basic-100"].toString(),
            id: StoreHomeNavigatorId,
        };
    }, [showNavbar, theme]);

    useMount(() => {
        Analytics.launchEvent({
            category: "store-view",
        });

        Analytics.impressionEvent({
            category: "bottom-bar",
            context: "shop,about,account" + cartItemCount ? ",cart" : "",
        });
    });

    const menuClick = React.useCallback(
        (tabName: string): object => ({
            tabPress: () => Analytics.clickEvent({ category: `bottom-bar-${tabName}-icon` }),
        }),
        [],
    );

    const getCartIcon = useCartIcon(cartItemCount);

    const ShopScreen = require("../shop/ux2.0").ShopScreen;

    return (
        <Tab.Navigator
            initialRouteName={initialRouteName}
            screenOptions={screenOptions}
            tabBar={TabBar}>
            <Tab.Screen
                name="Shop"
                getComponent={() => ShopScreen}
                options={{
                    tabBarIcon: (props) =>
                        tabBarIcon({
                            ...props,
                            icon: props.focused ? "ConsumerStoreFilled" : "ConsumerStoreOutline",
                        }),
                    tabBarItemStyle: screenOptions.tabBarItemStyle,
                    headerTitle: storeInfo.name,
                    headerShown: false,
                    tabBarStyle: {
                        display: "none",
                    },
                }}
                initialParams={{ path }}
                listeners={() => menuClick("shop")}
            />
            <Tab.Screen
                name="About"
                getComponent={() => require("../shop").ShopScreen}
                options={{
                    tabBarIcon: (props) =>
                        tabBarIcon({ ...props, icon: props.focused ? "InfoFilled" : "Info" }),
                    headerTitle: storeInfo.name,
                    tabBarItemStyle: screenOptions.tabBarItemStyle,
                    headerShown: showNavbar === false,
                }}
                initialParams={{ path }}
                listeners={() => menuClick("about")}
            />
            <Tab.Screen
                name="Cart"
                getComponent={() => require("../cart").CartScreen}
                options={{
                    tabBarIcon: (props) =>
                        tabBarIcon({
                            ...props,
                            icon: getCartIcon(props.focused),
                        }),
                    tabBarStyle: cartItemCount > 0 ? { display: "none" } : null,
                    headerTitle: `${storeInfo.name} | Cart`,
                    headerLeftContainerStyle: {
                        flexGrow: 1,
                    },
                    tabBarBadge:
                        cartItemCount > 0
                            ? cartItemCount > 99
                                ? "99+"
                                : cartItemCount
                            : undefined,
                    tabBarBadgeStyle: styles.tabBadgeStyle,
                    tabBarItemStyle: screenOptions.tabBarItemStyle,
                }}
                listeners={() => menuClick("cart")}
            />
            <Tab.Screen
                name="MyAccount"
                getComponent={() => require("../account/routes/my-account").MyAccountScreen}
                options={{
                    tabBarIcon: (props) =>
                        tabBarIcon({ ...props, icon: props.focused ? "AccountFilled" : "Account" }),
                    tabBarLabel: "My Account",
                    headerTitle: "My Account",
                    tabBarItemStyle: screenOptions.tabBarItemStyle,
                    tabBarStyle: {
                        display: "none",
                    },
                }}
                listeners={() => ({
                    tabPress: (e) => {
                        Analytics.clickEvent({ category: "bottom-bar-account-icon" });
                        if (!isSignedIn && !showNavbar) {
                            e.preventDefault();
                            authHandler(true);
                        }
                    },
                })}
            />
        </Tab.Navigator>
    );
};

const DEFAULT_TAB_BAR_HEIGHT = 72;

const TabBar: React.FC<BottomTabBarProps> = (props) => {
    const focusedRoute = props.state.routes[props.state.index];
    const focusedDescriptor = props.descriptors[focusedRoute.key];
    const focusedOptions = focusedDescriptor.options;

    const insets = props.insets;
    const paddingBottom = Math.max(insets.bottom - Platform.select({ ios: 4, default: 0 }), 0);

    focusedOptions.tabBarStyle = [
        focusedOptions.tabBarStyle,
        {
            height: paddingBottom + DEFAULT_TAB_BAR_HEIGHT,
        },
    ];

    return (
        <Display lt={ScreenSize.Medium}>
            <BottomTabBar {...props} />
        </Display>
    );
};

const tabBarIcon: React.FC<{
    focused: boolean;
    color: string;
    icon: Icon;
    size: number;
}> = (props) => {
    return <SvgIcon icon={props.icon} color={props.color} width={props.size} height={props.size} />;
};

const styles = StyleSheet.create({
    tabBadgeStyle: {
        maxWidth: 36,
        textAlign: "center",
        position: "absolute",
        top: -5,
    },
});
