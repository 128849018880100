import React from "react";
import {
    FlatList,
    ListRenderItem,
    SectionList,
    SectionListRenderItem,
    Keyboard,
} from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import {
    CartCoupons,
    CartCouponSection,
    CartCouponSectionCoupon,
} from "@minis-consumer/interfaces/coupon";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { getMediaUrl } from "@minis-consumer/helpers/media";

import { getCouponSectionsData } from "../../helpers";
import { CouponEmptyState } from "../empty-state";
import { SectionTitle } from "./components/section-title";
import { CouponCard } from "./components/coupon-card";
import { BRAND_IMAGE_FALLBACK } from "../../constants";

const CouponsListComponent: React.FC<{
    onApply: (code: string, eventName: string, pos?: number) => void;

    cartId?: string;
    coupons?: CartCoupons;
}> = ({ coupons, cartId, onApply }) => {
    const store = useStoreInfo();
    const brandImageUrl = React.useMemo(
        () =>
            getMediaUrl(store.settings.brandImageId ?? BRAND_IMAGE_FALLBACK, { height: 60 }, true),
        [store.settings.brandImageId],
    );

    const couponSectionListData = React.useMemo(() => {
        return getCouponSectionsData(coupons);
    }, [coupons]);

    const numOfColumns = useSelectScreen({
        lg: 2 as const,
        default: 1 as const,
    });

    const renderSection:
        | SectionListRenderItem<
              {
                  items: CartCouponSectionCoupon[];
              },
              CartCouponSection
          >
        | undefined = ({ item }) => {
        return (
            <FlatList
                data={item.items}
                numColumns={numOfColumns}
                renderItem={renderItem}
                keyExtractor={(coupon) => coupon.code}
                ItemSeparatorComponent={() => <Box mt={SpacingValue["space-large"]} />}
                collapsable
            />
        );
    };

    const renderItem: ListRenderItem<CartCouponSectionCoupon> | null | undefined = ({
        item,
        index,
    }) => {
        const onPressApply = (): void => {
            onApply(item.code, "available-coupon-apply-btn", index + 1);
        };

        return (
            <CouponCard
                coupon={item}
                index={index}
                key={item.code}
                brandImageUrl={brandImageUrl}
                onApply={item.active ? onPressApply : undefined}
                cartId={cartId}
            />
        );
    };

    const dismissKeyboard = React.useCallback(() => {
        Keyboard.dismiss();
    }, []);

    const contentContainerStyle = useSelectScreen({
        lg: {
            padding: SpacingValue["space-large"],
        },
        default: {
            padding: SpacingValue["space-medium"],
            flexGrow: 1,
        },
    });

    return (
        <SectionList
            onScroll={dismissKeyboard}
            keyboardShouldPersistTaps="handled"
            bounces={false}
            sections={couponSectionListData}
            renderSectionHeader={({ section: { title } }) => <SectionTitle title={title} />}
            renderItem={renderSection}
            scrollEventThrottle={16}
            stickySectionHeadersEnabled={false}
            SectionSeparatorComponent={() => <Box mt={SpacingValue["space-medium"]} />}
            contentContainerStyle={contentContainerStyle}
            ListEmptyComponent={CouponEmptyState}
        />
    );
};

export const CouponsList = React.memo(CouponsListComponent);
