export const ORDER_JOURNEY = [
    {
        key: "PENDING",
        activeState: "Order confirmation pending",
        completedState: "Order confirmed",
    },
    {
        key: "ACCEPTED",
        activeState: "Order being processed",
        completedState: "Order packed",
    },
    {
        key: "READY_FOR_PICKUP",
        activeState: "Order ready for dispatch",
        completedState: "Order dispatched",
    },
    { key: "IN_TRANSIT", activeState: "Order in transit", completedState: "Order in transit" },
    { key: "DELIVERED", activeState: "Order delivered", completedState: "Order delivered" },
];

export const CANCEL_ORDER = {
    CTA_TITLE: "Cancel order",
    MODAL: {
        TITLE: "Cancel this order?",
        DESCRIPTION: "You will receive a full refund since the seller is yet to confirm this order",
        PRIMARY_CTA: "Cancel order",
        SECONDARY_CTA: "Dismiss",
    },
};

export const HEADER_TITLE = "Tracking details";

export const STORE_DELETED_TEXT = "This Ministore has been permenantly closed by the seller.";

export const RETURN_SUBMITTED_STORAGE_KEY = "return-submitted";
