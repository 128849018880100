import React, { useContext } from "react";

import { PAYMENT_GROUP_CATEGORY, PAYMENT_GROUP_METHOD } from "../constants";
import { PaymentStateContext } from "../contexts/payment-context";
import { PaymentState } from "../reducers/types";
import { useNetBankingBanks } from "./use-netbanking-banks";

type TSelectedPaymentMethod = (
    props: NonNullable<PaymentState["selectedPaymentMethod"]>,
) => boolean;

export const usePaymentSelector = <Selected>(callback: (s: PaymentState) => Selected): Selected => {
    const context = useContext(PaymentStateContext);
    if (!context) {
        throw new Error("Missing payment state context");
    }

    return callback(context);
};

export const useSelectedPaymentMethod: TSelectedPaymentMethod = (Props): boolean => {
    const selectedMethod = usePaymentSelector((s) => s.selectedPaymentMethod);

    const isPaymentMethodSelected = React.useMemo(() => {
        return (
            selectedMethod?.category === Props?.category &&
            selectedMethod?.type === Props?.type &&
            selectedMethod?.value === Props?.value
        );
    }, [Props, selectedMethod]);

    return isPaymentMethodSelected;
};

export const useOtherBankSelectedName = (): string => {
    const selectedMethod = usePaymentSelector((s) => s.selectedPaymentMethod);
    const { allBanks } = useNetBankingBanks();

    const otherBankName = React.useMemo(() => {
        return (
            selectedMethod?.category === PAYMENT_GROUP_CATEGORY.NEW &&
            selectedMethod?.type === PAYMENT_GROUP_METHOD.NETBANKING &&
            allBanks?.find((val) => val?.bankCode === selectedMethod?.value)?.bankName
        );
    }, [allBanks, selectedMethod?.category, selectedMethod?.type, selectedMethod?.value]);

    return otherBankName || "Other Banks";
};
