import React from "react";

import { Icon } from "@swiggy-private/connect-svg-icons";

import { UserAddress } from "@minis-consumer/interfaces/cart";
import { useCompleteAddress } from "@minis-consumer/hooks/use-complete-address";
import { displayAddressNameAndIcon } from "@minis-consumer/helpers/address";

import { ADDRESS_BOTTOM_SHEET_STATE } from "../components/cart-address-bottomsheet/constants";
import { useValidateAddressMandatoryFields } from "./use-validate-address";

type AddressBottomSheetMetaParams = {
    addressListLength: number;
    userSelectedAddress: UserAddress | null;
};

export type AddressBottomSheetMeta = {
    TITLE: string;
    DESCRIPTION: string;
    CTA_TEXT: string;
    HIDE_CHEVRON_ICON: boolean;
    SHOW_ERROR_MESSAGE: boolean;
    IS_VALID_ADDRESS?: boolean;
    ADDRESS_ICON?: Icon;
};

type AddressBottomSheetReturnType = () => Promise<AddressBottomSheetMeta>;

export const useAddressBottomSheetMeta = (
    params: AddressBottomSheetMetaParams,
): AddressBottomSheetReturnType => {
    const { addressListLength, userSelectedAddress } = params;
    const validateAddress = useValidateAddressMandatoryFields();
    const completeAddress = useCompleteAddress(userSelectedAddress);

    const addressListLengthBasedMeta = React.useCallback(
        (isValid = true) => {
            switch (addressListLength) {
                case 0:
                    return { ...ADDRESS_BOTTOM_SHEET_STATE.NO_ADDRESS, IS_VALID_ADDRESS: isValid };
                default:
                    return {
                        ...ADDRESS_BOTTOM_SHEET_STATE.MULTIPLE_ADDRESSES,
                        DESCRIPTION: `You have ${addressListLength} saved ${
                            addressListLength > 1 ? "addresses" : "address"
                        }`,
                        IS_VALID_ADDRESS: isValid,
                    };
            }
        },
        [addressListLength],
    );

    return React.useCallback(async () => {
        if (!userSelectedAddress) {
            return addressListLengthBasedMeta();
        } else if (userSelectedAddress.serviceabilityType === "UNSERVICEABLE") {
            return ADDRESS_BOTTOM_SHEET_STATE.UNSERVICEABLE_ADDRESS;
        }

        const isUserSelectedAddressValid = await validateAddress(userSelectedAddress);

        if (!isUserSelectedAddressValid) {
            return addressListLengthBasedMeta(false);
        }

        const { addressTag, icon } = displayAddressNameAndIcon(userSelectedAddress, true);
        return {
            ...ADDRESS_BOTTOM_SHEET_STATE.SERVICEABLE_ADDRESS,
            TITLE: addressTag,
            DESCRIPTION: completeAddress,
            IS_VALID_ADDRESS: true,
            ADDRESS_ICON: icon,
        };
    }, [addressListLengthBasedMeta, completeAddress, userSelectedAddress, validateAddress]);
};
