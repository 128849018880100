import React, { Dispatch, FC, memo, SetStateAction } from "react";
import { StyleSheet } from "react-native";

import { RadioButton, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { DISPLAY_COPIES, RETURN_REASONS } from "../constants";

const { REASON_TITLE } = DISPLAY_COPIES.CARD;

interface ReasonSelectorProps {
    selectedReasonIndex: number;
    setSelectedReasonIndex: Dispatch<SetStateAction<number>>;
}

const ReasonSelectorComponent: FC<ReasonSelectorProps> = ({
    selectedReasonIndex,
    setSelectedReasonIndex,
}) => {
    return (
        <Stack>
            <Box mb={SpacingValue["space-x-small"]}>
                <Text category="b1" weight="bold" color="color-basic-75">
                    {REASON_TITLE}
                </Text>
            </Box>
            {RETURN_REASONS.map((reason, index) => (
                <RadioButton
                    key={reason}
                    color="primary"
                    checked={selectedReasonIndex === index}
                    onChange={() => setSelectedReasonIndex(index)}
                    style={styles.radio}>
                    <>
                        <Text
                            category="b2"
                            weight="medium"
                            color="color-basic-75"
                            style={styles.radioText}>
                            {reason}
                        </Text>
                    </>
                </RadioButton>
            ))}
        </Stack>
    );
};

export const ReasonSelector = memo(ReasonSelectorComponent);

const styles = StyleSheet.create({
    radio: {
        marginVertical: SpacingValue["space-x-small"],
    },
    radioText: {
        marginLeft: SpacingValue["space-x-small"],
    },
});
