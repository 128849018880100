import React, { FC, memo } from "react";

import { variantsValueFormatter } from "@swiggy-private/common-helpers";
import { OrderItemSummary } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { OrderEntity } from "@minis-consumer/resources/order";
import { DOT_SEPARATOR } from "@minis-consumer/helpers/variants";

interface ReturnItemsListProps {
    order: OrderEntity;
}

const ReturnItemsListComponent: FC<ReturnItemsListProps> = ({ order }) => {
    const { returnDetails } = order || {};
    const { order: returnOrder } = returnDetails || {};

    const returnItems = returnOrder?.items || [];
    const numberOfReturnItems = returnItems.length || 0;
    const returnOrderAmount = returnOrder?.bill?.finalItemTotal || 0;

    const returnOrderInfoText = `${numberOfReturnItems} ${
        numberOfReturnItems === 1 ? "item" : "items"
    } ${DOT_SEPARATOR} ₹${returnOrderAmount}`;

    const orderItems = returnItems.map((item) => {
        const {
            quantity,
            item: { id, name, imageIds, price, discountedPrice, variantOptionValues },
        } = item;

        const discountApplied = discountedPrice ? discountedPrice !== price : false;
        const totalPrice = price * quantity;
        const totalDiscountedPrice = (discountedPrice || price) * quantity;

        const selectedVariants = variantsValueFormatter(variantOptionValues || []);
        const variantsText = selectedVariants ? ` (${selectedVariants})` : "";

        return (
            <Box key={id}>
                <OrderItemSummary
                    productName={name}
                    quantity={quantity}
                    price={totalPrice}
                    discountedPrice={discountApplied ? totalDiscountedPrice : undefined}
                    imageId={imageIds?.[0]}
                    variantText={variantsText}
                />
            </Box>
        );
    });

    return (
        <Stack flex={1}>
            <Box mb={SpacingValue["space-small"]}>
                <Text category="b2" weight="medium" color="high">
                    {returnOrderInfoText}
                </Text>
            </Box>
            <Stack spacing={SpacingValue["space-medium"]}>{orderItems}</Stack>
        </Stack>
    );
};

export const ReturnItemsList = memo(ReturnItemsListComponent);
