import React, { useEffect } from "react";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { Layout, SpacingValue, Text, useLayout } from "@swiggy-private/rn-dls";

import { Card } from "@minis-consumer/components/card";
import { OfferDiscount } from "@minis-consumer/components/offer-discount";

import { useOrderPaymentData } from "../../hooks/use-payment-data";
import { AdditionalCharges } from "./components/additional-charges";
import { CartTotal } from "./components/cart-total";
import { DashSeparator } from "./components/dash-separator";
import { DeliveryFee } from "./components/delivery-fee";
import { FinalAmount } from "./components/final-amount";
import { ProductCartStack } from "./components/product-stack";
import { SubTotal } from "./components/sub-total";

const BILL_DETAILS = "Bill Details";

const BillComponent: React.FC<{
    storeId: string;
    getBillComponentLayout?: (e: Layout) => void;
    shouldRenderFooterPayment?: boolean;
}> = ({ storeId, getBillComponentLayout, shouldRenderFooterPayment }) => {
    const {
        cart,
        cartStatus,
        itemTotal,
        deliveryFee,
        itemDiscount,
        couponDiscount,
        totalDiscount,
        discountedItemTotal,
        appliedCoupon,
        additionalCharges,
        billTotal,
    } = useOrderPaymentData(storeId);

    const isPhysicalCart = cart?.cartType === "PHYSICAL";

    const [layout, onLayout] = useLayout();

    useEffect(() => {
        if (layout.measured) {
            getBillComponentLayout?.(layout);
        }
    }, [getBillComponentLayout, layout]);

    if (shouldRenderFooterPayment) {
        return (
            <Stack spacing={SpacingValue["space-medium"]}>
                <ProductCartStack cart={cart} />
                <Stack spacing={SpacingValue["space-medium"]}>
                    <DashSeparator />
                    {totalDiscount ? (
                        <OfferDiscount
                            finalAmount={totalDiscount}
                            itemDiscount={itemDiscount}
                            couponDiscount={couponDiscount}
                            appliedCoupon={appliedCoupon}
                        />
                    ) : null}
                </Stack>

                <SubTotal finalAmount={discountedItemTotal} />
                <AdditionalCharges charges={additionalCharges} />

                {isPhysicalCart ? (
                    <Stack spacing={SpacingValue["space-medium"]}>
                        <DashSeparator />
                        <DeliveryFee deliveryFee={deliveryFee} cartStatus={cartStatus} />
                    </Stack>
                ) : null}
            </Stack>
        );
    }

    return (
        <Card onLayout={onLayout}>
            <Stack spacing={SpacingValue["space-medium"]}>
                <Text color="high" category="b1" weight="bold">
                    {BILL_DETAILS}
                </Text>

                {itemTotal > discountedItemTotal ? (
                    <Stack spacing={SpacingValue["space-medium"]}>
                        <CartTotal finalAmount={itemTotal} />

                        {totalDiscount ? (
                            <OfferDiscount
                                finalAmount={totalDiscount}
                                itemDiscount={itemDiscount}
                                couponDiscount={couponDiscount}
                                appliedCoupon={appliedCoupon}
                            />
                        ) : null}

                        <DashSeparator />
                    </Stack>
                ) : null}

                <SubTotal finalAmount={discountedItemTotal} />
                <AdditionalCharges charges={additionalCharges} />

                {isPhysicalCart ? (
                    <Stack spacing={SpacingValue["space-medium"]}>
                        <DashSeparator />
                        <DeliveryFee deliveryFee={deliveryFee} cartStatus={cartStatus} />
                    </Stack>
                ) : null}

                <Stack spacing={SpacingValue["space-medium"]}>
                    <DashSeparator />
                    <FinalAmount billTotal={billTotal} />
                </Stack>
            </Stack>
        </Card>
    );
};

export const Bill = React.memo(BillComponent);
