import React, { FC, memo } from "react";
import { StyleSheet } from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { Card } from "@minis-consumer/components/card";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartView } from "@minis-consumer/hooks/use-cart";
import { CartItem as ICartItem } from "@minis-consumer/interfaces/cart";

import { CartItem } from "../item-list/components/item";
import { InfoBanner } from "./components/info-banner";

interface ChangedItemsProps {
    changedItems: ICartItem[];
    removeChangedItems: VoidFunction;
}

const ChangedItemsComponent: FC<ChangedItemsProps> = ({ changedItems, removeChangedItems }) => {
    const storeInfo = useStoreInfo();
    const { storeId, slug } = storeInfo;

    const cart = useCartView(storeId);

    const contentPadding = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        md: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const changedCartItems = React.useMemo(() => {
        const contentStyle = {
            paddingHorizontal: contentPadding,
        };

        return changedItems.map((data: ICartItem) => (
            <CartItem
                style={contentStyle}
                id={data.item.id}
                name={data.item.name}
                price={data.item.price}
                variantId={data.item.variantId}
                discountedPrice={data.item.discountedPrice}
                isCustom={data.metadata.custom}
                inStock={data.metadata.custom ? true : data.item.inStock}
                key={`${data.item.id}-${data.item.variantId ?? ""}`}
                storeId={storeId}
                quantity={data.quantity}
                cartId={cart?.cartViewData?.id}
                productImageId={data.item.imageIds?.[0]}
                storeSlug={slug}
                type={data.item.productType}
                itemForm={data.itemForm}
                slot={data.slot}
                productDuration={data.item.productDuration}
                itemStatus={data.status}
                isChanged
            />
        ));
    }, [cart?.cartViewData?.id, changedItems, contentPadding, slug, storeId]);

    const totalItemsInCart = cart?.cartViewData?.cartItems?.length;
    const totalItemsChanged = changedItems?.length;

    if (!totalItemsInCart || !totalItemsChanged) {
        return null;
    }

    return (
        <Card style={styles.card}>
            <InfoBanner
                itemsChanged={totalItemsChanged}
                totalItems={totalItemsInCart}
                onClosePress={removeChangedItems}
                infoText={cart?.cartViewData?.attributesChangedMessage}
            />

            {changedCartItems}
        </Card>
    );
};

export const ChangedItems = memo(ChangedItemsComponent);

const styles = StyleSheet.create({
    card: {
        marginBottom: SpacingValue["space-medium"],
        padding: 0,
        overflow: "hidden",
    },
});
