export const CONST = {
    TITLE_COPY: "You currently have items in your cart!",
    DESCRIPTION_COPY: "You will have to clear them before adding products from the link?",
    REPLACE_BTN_TXT_COPY: "Replace items",
    KEEP_BTN_TXT_COPY: "Keep items",
    CANCEL_BTN_TXT_COPY: "Cancel",
    MAX_DESKTOP_WIDTH: 410,
    KEEP_ITEMS_ANALYTICS_CATEGORY: "keep-items-btn",
    REPLACE_ITEMS_ANALYTICS_CATEGORY: "replace-items-btn",
};
