import * as React from "react";
import { Pressable, StyleSheet, ViewStyle } from "react-native";

import { SpacingValue, Text, TextProps } from "@swiggy-private/rn-dls";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { StarSellerBadge } from "@swiggy-private/connect-business-commons";

interface OrderAddressGistProps {
    annotation: string;
    locality: string;
    city: string;
    isStarSeller?: boolean;
    isStoreEnabled?: boolean;
    isStoreAddress?: boolean;
    style?: ViewStyle;
    onPress?: () => void;
}

const OrderAddressGist: React.FC<OrderAddressGistProps> = ({
    annotation,
    locality,
    city,
    isStoreEnabled,
    isStoreAddress,
    style,
    onPress,
    isStarSeller,
}) => {
    const storeLocation = React.useMemo(
        () => [locality, city].filter(Boolean).join(", "),
        [city, locality],
    );

    const titleCategory: TextProps["category"] = useSelectScreen({
        lg: "h4",
        default: "h5",
    });

    const descriptionCategory: TextProps["category"] = useSelectScreen({
        lg: "s3",
        default: "b2",
    });

    const stackStyle = {
        opacity: !isStoreEnabled && isStoreAddress ? 0.5 : 1,
    };

    return (
        <Pressable
            onPress={() => onPress?.()}
            hitSlop={{ left: 4, top: 12, bottom: 12, right: 16 }}>
            <Stack style={[stackStyle, style]}>
                <Box direction="row" alignItems="center">
                    <Text
                        category={titleCategory}
                        color="color-basic-100"
                        numberOfLines={1}
                        style={styles.annotation}>
                        {annotation}
                    </Text>
                    {isStarSeller ? (
                        <StarSellerBadge
                            outerGradientStyle={styles.badgeGradient}
                            rootContainerStyle={{
                                padding: SpacingValue["space-xxx-small"],
                            }}
                        />
                    ) : null}
                </Box>
                <Text
                    category={descriptionCategory}
                    weight="regular"
                    color="color-basic-60"
                    numberOfLines={1}>
                    {storeLocation}
                </Text>
            </Stack>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    badgeGradient: {
        padding: 0,
        marginLeft: SpacingValue["space-xxx-small"],
    },
    annotation: {
        flexShrink: 1,
    },
});

export default React.memo(OrderAddressGist);
