import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { DashBorderLine, InView } from "@swiggy-private/react-native-ui";

import { Divider } from "@minis-consumer/components/divider";
import { Analytics } from "@minis-consumer/analytics";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { ARD_EVENTS } from "@minis-consumer/routes/product/constants";
import { Product } from "@minis-consumer/interfaces/catalog";
import { useGetProduct } from "@minis-consumer/routes/product/hooks/use-get-product";

import { TEST_IDS, PRODUCT_DETAILS_TITLE } from "./constants";
import { ProductDuration } from "../duration";
import { ProductCommChannels } from "../communication-channels";
import { ProductServiceDeliveryInfo } from "../service-delivery-info";
import { ProductDigitalAssetInfo } from "../digital-asset-info";

type HowItWorksProps = {
    productId: string;
    header?: string;
    type?: Product["productType"];
    productDuration?: Product["productDuration"];
    serviceDeliveryChannels?: Product["serviceDeliveryChannels"];
    serviceDeliveryInfo?: Product["serviceDeliveryInfo"];
    digitalAssetInfo?: Product["digitalAssetInfo"];
};

const HowItWorksComponent: React.FC<HowItWorksProps> = ({
    productId,
    type,
    productDuration,
    serviceDeliveryChannels,
    serviceDeliveryInfo,
    digitalAssetInfo,
}) => {
    const { value: theme } = useTheme();

    const { storeId } = useStoreInfo();
    const product = useGetProduct(productId);

    const dividerStyle = {
        marginTop: SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-large"],
    };

    const dashBorderStyle = {
        marginTop: SpacingValue["space-large"],
        marginBottom: SpacingValue["space-large"],
    };

    const [showDetails, setShowDetails] = React.useState(true);

    const analyticsContextData = React.useMemo(
        () =>
            JSON.stringify({
                storeId,
                productId,
            }),
        [storeId, productId],
    );

    const toggleView = React.useCallback(() => {
        Analytics.clickEvent({
            category: ARD_EVENTS.HOW_IT_WORKS,
            context: analyticsContextData,
        });

        setShowDetails(!showDetails);
    }, [showDetails, analyticsContextData]);

    const omImpression = React.useCallback(
        (isVisible: boolean) => {
            if (!isVisible || !showDetails) {
                return;
            }

            Analytics.impressionEvent({
                category: ARD_EVENTS.DETAILS,
                context: analyticsContextData,
            });
        },
        [showDetails, analyticsContextData],
    );

    if (!productId || type === "PHYSICAL") {
        return null;
    }

    return (
        <InView onChange={omImpression}>
            <Pressable onPress={toggleView} hitSlop={16} testID={TEST_IDS.PRESSABLE}>
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                    style={styles.headerContainer}>
                    <Text
                        category="subheader_S1_Bold"
                        weight="bold"
                        color="high"
                        testID={TEST_IDS.TITLE}>
                        {PRODUCT_DETAILS_TITLE[product?.productType ?? "DEFAULT"] ??
                            PRODUCT_DETAILS_TITLE.DEFAULT}
                    </Text>

                    <Box style={styles.caret} testID={TEST_IDS.CHEVRON}>
                        <SvgIcon
                            icon={showDetails ? "ChevronUp" : "ChevronDown"}
                            color="color-basic-75"
                            width={20}
                        />
                    </Box>
                </Stack>
            </Pressable>

            {showDetails ? (
                <Stack
                    divider={
                        <DashBorderLine
                            borderColor={theme["color-basic-15"]}
                            style={dashBorderStyle}
                        />
                    }
                    style={styles.detailsContainer}>
                    {digitalAssetInfo ? (
                        <ProductDigitalAssetInfo
                            digitalAssetInfo={digitalAssetInfo}
                            name={product?.name}
                        />
                    ) : null}
                    {productDuration ? (
                        <ProductDuration productType={type} productDuration={productDuration} />
                    ) : null}
                    {serviceDeliveryChannels?.length ? (
                        <ProductCommChannels serviceDeliveryChannels={serviceDeliveryChannels} />
                    ) : null}
                    {serviceDeliveryInfo ? (
                        <ProductServiceDeliveryInfo
                            serviceDeliveryInfo={serviceDeliveryInfo}
                            productType={product?.productType}
                        />
                    ) : null}
                </Stack>
            ) : null}

            <Divider style={dividerStyle} />
        </InView>
    );
};

export const HowItWorks = React.memo(HowItWorksComponent);

const styles = StyleSheet.create({
    caret: {
        width: 24,
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    headerContainer: {
        flex: 1,
    },
    detailsContainer: { marginTop: SpacingValue["space-small"] },
});
