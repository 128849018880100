import * as React from "react";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";

import { useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";

import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import type { RouteList } from "@minis-consumer/interfaces/route";

import { HeaderRight } from "./components/header/right";
import { SearchMain } from "./components/main";

type StoreCatalogSearchScreenNavigationProp = NativeStackScreenProps<
    RouteList,
    "StoreCatalogSearch"
>;

export const StoreCatalogSearchScreen: React.FC<StoreCatalogSearchScreenNavigationProp> = (
    props,
) => {
    const { navigation } = props;
    const { value: theme } = useTheme();
    const store = useStoreInfo();

    const mainContainerStyle = { backgroundColor: theme["color-basic-0"], flex: 1 };

    useIsomorphicEffect(() => {
        const onChatPress = (): void => {
            navigation.navigate("ChatConversation", { storeId: store.storeId });
        };

        navigation.setOptions({
            headerTitle: `Search ${store.name}`,
            headerRight: () => <HeaderRight onChatPress={onChatPress} />,
        });
    }, [navigation, store.storeId, store.name, theme]);

    return (
        <StateContextContainer>
            <Box style={mainContainerStyle} pointerEvents="box-none">
                <SearchMain />
            </Box>
        </StateContextContainer>
    );
};
