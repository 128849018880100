import React from "react";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { Slate } from "@minis-consumer/components/slate";
import { StyleSheet } from "react-native";

const CouponInputPlaceholderComponent: React.FC = () => {
    const { value: theme } = useTheme();

    return (
        <Box
            style={[
                styles.container,
                {
                    borderColor: theme["color-basic-30"],
                },
            ]}
            justifyContent="center">
            <Slate height={20} width="30%" />
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        borderRadius: 12,
        borderWidth: 1,
        height: 56,
        paddingHorizontal: SpacingValue["space-medium"],
    },
});

export const CouponInputPlaceholder = React.memo(CouponInputPlaceholderComponent);
