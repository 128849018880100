import React, { useContext, useCallback, useEffect, useRef, useState } from "react";
import { Pressable, StyleSheet } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { useErrorHandler } from "react-error-boundary";
import { useMount } from "@swiggy-private/react-hooks";

import {
    PaymentProceedNavigationProp,
    PaymentProceedRouteProp,
} from "@minis-consumer/interfaces/route";
import { Analytics } from "@minis-consumer/analytics";
import { getPaymentProceedTitle } from "@minis-consumer/routes/payment/helpers";
import { withPaymentMainContainer } from "@minis-consumer/routes/payment/containers/main-container";
import { Countdown } from "./countdown";
import { useConfirmTransaction } from "@minis-consumer/routes/payment/hooks/use-confirm-transaction";
import { MAX_UPI_PAYMENT_TIME } from "@minis-consumer/routes/payment/constants";
import { useNavigateToOrderWithReset } from "@minis-consumer/routes/payment/hooks/use-navigate-order";
import { withResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { ConfirmDialog } from "@minis-consumer/components/confirm-dialog";
import { usePaymentStoreId } from "@minis-consumer/routes/payment/hooks/use-payment-store";
import { CartDispatchContext } from "@minis-consumer/contexts/cart-context";

const PaymentTimerComponent: React.FC = () => {
    const navigation = useNavigation<PaymentProceedNavigationProp<"PaymentTimer">>();
    const route = useRoute<PaymentProceedRouteProp<"PaymentTimer">>();

    const { txnId, orderId, paymentId, upiMode } = route.params;
    const storeId = usePaymentStoreId();
    const confirmTransaction = useConfirmTransaction();
    const errorHandler = useErrorHandler();
    const dispatch = useContext(CartDispatchContext);
    const canGoBackRef = useRef(false);
    const [showBackDialog, setShowBackDialog] = useState(false);

    const onConfirmTransactionCompletion = useCallback(() => {
        canGoBackRef.current = true;

        //clear local cart
        dispatch({ type: "CLEAR_CART_ACTION", payload: { storeId: storeId ?? "" } });
    }, [dispatch, storeId]);

    useMount(() => {
        Analytics.impressionEvent({
            category: "cancel-payment-btn",
            label: orderId,
            context: txnId,
        });

        Analytics.impressionEvent({
            category: "upi-app-opened",
            label: upiMode,
            context: JSON.stringify({ txnId, orderId }),
        });

        navigation.getParent()?.setOptions({
            title: getPaymentProceedTitle(route.params.method ?? "UPI"),
        });

        confirmTransaction({
            transactionId: txnId,
            pollDuration: MAX_UPI_PAYMENT_TIME * 1000,
            paymentId,
            onCompletion: onConfirmTransactionCompletion,
        }).catch(errorHandler);
    });

    const navigateToOrder = useNavigateToOrderWithReset();

    const cancelPayment = useCallback(() => {
        Analytics.clickEvent({
            category: "cancel-payment-btn",
            label: orderId,
            context: txnId,
        });

        if (canGoBackRef.current) {
            navigateToOrder(orderId, true);
        } else {
            setShowBackDialog(true);
        }
    }, [navigateToOrder, orderId, txnId]);

    useEffect(() => {
        return navigation.addListener("beforeRemove", (e) => {
            if (canGoBackRef.current) {
                return;
            }

            e.preventDefault();
            setShowBackDialog(true);
        });
    }, [navigation]);

    return (
        <Box
            alignItems="center"
            flex={1}
            ph={SpacingValue["space-medium"]}
            pv={SpacingValue["space-medium"]}>
            <Box style={styles.container} flex={1}>
                <Stack spacing={SpacingValue["space-x-large"]}>
                    <Countdown duration={MAX_UPI_PAYMENT_TIME} />
                    <Text category="h4" color="highest" style={styles.textAlignCenter}>
                        Open your UPI app to approve the payment request before the timer runs out
                    </Text>
                </Stack>
                <Box pv={SpacingValue["space-medium"]} justifyContent="center" flex={1}>
                    <Stack spacing={48} alignItems="center">
                        <Text
                            category="b2"
                            weight="medium"
                            color="low"
                            style={styles.textAlignCenter}>
                            <Text category="b2" weight="bold" color="highest">
                                {"Note: "}
                            </Text>
                            Do not hit back button or close this screen until the transaction is
                            complete
                        </Text>
                        <Pressable onPress={cancelPayment}>
                            <Text
                                color="color-critical-300"
                                category="btn2"
                                style={styles.textAlignCenter}>
                                Cancel payment
                            </Text>
                        </Pressable>
                    </Stack>
                </Box>
            </Box>
            <ConfirmDialog
                open={showBackDialog}
                onClose={() => setShowBackDialog(false)}
                onPressCancel={() => setShowBackDialog(false)}
                onPressContinue={() => {
                    setShowBackDialog(false);
                    canGoBackRef.current = true;
                    navigateToOrder(orderId, true);
                }}
                title="Are you sure you want to cancel this payment?"
            />
        </Box>
    );
};

export const PaymentTimer = React.memo(
    withResetErrorBoundary(
        withPaymentMainContainer(PaymentTimerComponent, {
            backgroundColor: "color-background-primary",
        }),
    ),
);

const styles = StyleSheet.create({
    container: {
        paddingTop: SpacingValue["space-large"],
        maxWidth: 300,
    },
    textAlignCenter: {
        textAlign: "center",
    },
});
