import * as React from "react";
import { StyleSheet } from "react-native";

import { Stack, StackProps, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

import { Card } from "@minis-consumer/components/card";
import { Shimmer } from "@minis-consumer/components/shimmer";

const OrderDetailsLoader: React.FC = () => {
    const { value: theme } = useTheme();

    const containerSpacing = {
        lg: SpacingValue["space-x-large"],
        md: SpacingValue["space-large"],
        default: SpacingValue["space-medium"],
    };

    const logoSize = useSelectScreen({
        lg: 60,
        default: 40,
    });

    const direction: StackProps["direction"] = useSelectScreen({
        lg: "row",
        default: "column",
    });

    const showDivider = useSelectScreen({
        lg: false,
        default: true,
    });

    const btnWidth = useSelectScreen({
        lg: "30%",
        default: "100%",
    });

    return (
        <Stack spacing={containerSpacing}>
            <Card>
                <Stack spacing={SpacingValue["space-small"]}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                            <Shimmer
                                height={logoSize}
                                width={logoSize}
                                style={styles.br10}
                                stopAutoRun={false}
                            />
                            <Stack spacing={SpacingValue["space-x-small"]}>
                                <Shimmer height={16} width={146} style={styles.br10} />
                                <Shimmer height={8} style={styles.br10} />
                            </Stack>
                        </Stack>
                        <Shimmer height={logoSize} width={logoSize} style={styles.br50} />
                    </Stack>
                    <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.mt0} />
                    <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                        <Shimmer height={logoSize} width={logoSize} style={styles.br10} />
                        <Stack spacing={SpacingValue["space-x-small"]}>
                            <Shimmer height={16} width={146} style={styles.br10} />
                            <Shimmer height={8} style={styles.br10} />
                        </Stack>
                    </Stack>
                </Stack>
            </Card>

            <Card>
                <Stack spacing={SpacingValue["space-small"]}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                            <Shimmer height={logoSize} width={logoSize} style={styles.br10} />
                            <Stack spacing={SpacingValue["space-x-small"]}>
                                <Shimmer height={16} width={146} style={styles.br10} />
                                <Shimmer height={8} style={styles.br10} />
                            </Stack>
                        </Stack>
                        <Shimmer height={16} width={34} style={[styles.br10, styles.mt16]} />
                    </Stack>
                    <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.mt0} />
                    <Stack
                        justifyContent="space-between"
                        direction={direction}
                        spacing={{ lg: 0, default: SpacingValue["space-medium"] }}>
                        <Stack spacing={SpacingValue["space-xx-small"]}>
                            <Shimmer height={16} width={104} style={styles.br10} />
                            <Shimmer height={8} width={270} style={styles.br10} />
                            <Shimmer height={8} width={230} style={styles.br10} />
                        </Stack>
                        {showDivider ? (
                            <DashBorderLine
                                borderColor={theme["color-basic-15"]}
                                style={[styles.mt0]}
                            />
                        ) : undefined}
                        <Shimmer height={40} width={btnWidth} style={styles.br10} />
                    </Stack>
                </Stack>
            </Card>

            <Card>
                <Stack spacing={SpacingValue["space-small"]}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                            <Shimmer height={logoSize} width={logoSize} style={styles.br10} />
                            <Stack spacing={SpacingValue["space-x-small"]}>
                                <Shimmer height={16} width={146} style={styles.br10} />
                                <Shimmer height={8} style={styles.br10} />
                            </Stack>
                        </Stack>
                        <Shimmer height={16} width={34} style={[styles.br10, styles.mt16]} />
                    </Stack>
                    <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.mt0} />
                    <Stack spacing={SpacingValue["space-xx-small"]}>
                        <Shimmer height={16} width={104} style={styles.br10} />
                        <Shimmer height={8} width={270} style={styles.br10} />
                        <Shimmer height={8} width={230} style={styles.br10} />
                    </Stack>
                    <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.mt0} />
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Shimmer height={16} width={150} style={styles.br10} />
                        <Shimmer height={32} width={100} style={styles.br10} />
                    </Stack>
                </Stack>
            </Card>
        </Stack>
    );
};

const styles = StyleSheet.create({
    mt0: {
        marginTop: 0,
    },
    br10: {
        borderRadius: 10,
    },
    br50: {
        borderRadius: 50,
    },
    mt16: {
        marginTop: SpacingValue["space-medium"],
    },
});

export default React.memo(OrderDetailsLoader);
