import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

import { Card } from "@minis-consumer/components/card";

interface Props {
    title: string;
    children: React.ReactNode;
}

const SectionComponent: React.FC<React.PropsWithChildren<Props>> = ({ title, children }) => {
    return (
        <Stack spacing={SpacingValue["space-medium"]}>
            <Text category="b1" weight="medium" color="color-basic-60">
                {title}
            </Text>

            <Card style={styles.card}>{children}</Card>
        </Stack>
    );
};

export const Section = React.memo(SectionComponent);

const styles = StyleSheet.create({
    card: {
        padding: 0,
        overflow: "hidden",
    },
});
