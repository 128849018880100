import {
    CartCoupon,
    CartCoupons,
    CartCouponSection,
    CartCouponSectionData,
} from "@minis-consumer/interfaces/coupon";
import { CART_COUPONS_SECTION_DATA } from "../constants";

export const getCouponSectionsData = (coupons?: CartCoupons): CartCouponSection[] => {
    if (!coupons) {
        return [];
    }
    const COUPON_TYPES = Object.keys(coupons) as Array<keyof CartCoupons>;

    const sections: CartCouponSection[] = COUPON_TYPES.filter((key) => coupons[key]?.length).map(
        (key1) => {
            return {
                title: CART_COUPONS_SECTION_DATA[key1].title,
                data: getCoupons(coupons[key1], CART_COUPONS_SECTION_DATA[key1].isEligible),
            };
        },
    );

    return sections;
};

const getCoupons = (couponList: CartCoupon[], isEligible: boolean): CartCouponSectionData[] => {
    return [
        {
            items: couponList.map((data) => {
                return { ...data.coupon, isEligible };
            }),
        },
    ];
};
