import { Dimensions } from "react-native";

export const getImageAreaWidth = (options: { noOffset?: boolean } | void): number => {
    const windowWidth = Dimensions.get("window").width;

    if (windowWidth <= 600) {
        // 32 is the horizontal padding for small devices
        return windowWidth - (options?.noOffset ? 0 : 32);
    }

    if (windowWidth > 600 && windowWidth <= 900) {
        // 48 is the horizontal padding for small devices
        // 0.6 is the product image area width percentage factor
        return (windowWidth - (options?.noOffset ? 0 : 48)) * 0.6;
    }

    if (windowWidth > 900 && windowWidth < 1032) {
        // 32 (horizontal main container padding for medium devices) + 48 (content padding in productFullViiew)
        // 320 is the chat area width
        // 0.5 is the product image area width percentage factor
        return (windowWidth - 400) * 0.5;
    }

    return 308;
};

export const getVariantIdFromPath = (path?: string): string | undefined => {
    return path?.match(/variantId=([^&]*)/)?.[1];
};
