import React from "react";

import { PaymentLayout } from "../layout";
import { LeftShimmer } from "./left";
import { RightShimmer } from "./right";

const PaymentShimmerComponent: React.FC = () => {
    return <PaymentLayout left={<LeftShimmer />} right={<RightShimmer />} />;
};

export const PaymentShimmer = React.memo(PaymentShimmerComponent);
