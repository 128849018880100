import * as React from "react";
import { ScrollView } from "react-native";
import { Box, Container } from "@swiggy-private/rn-adaptive-layout";
import { ActivityIndicator, useTheme } from "@swiggy-private/rn-dls";

import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { AsyncComponent } from "@minis-consumer/components/async-component";

import { SavedAddressesContent } from "./components/content";

export const SavedAddresses: React.FC = () => {
    const { value: theme } = useTheme();
    const bgSecondaryColor = theme["color-background-secondary"];

    const scrollStyle = {
        backgroundColor: bgSecondaryColor,
    };

    return (
        <StateContextContainer>
            <AsyncComponent
                fallback={
                    <Box flex={1} style={scrollStyle} justifyContent="center" alignItems="center">
                        <ActivityIndicator />
                    </Box>
                }>
                <ScrollView style={scrollStyle}>
                    <Container fluid={false}>
                        <SavedAddressesContent />
                    </Container>
                </ScrollView>
            </AsyncComponent>
        </StateContextContainer>
    );
};
