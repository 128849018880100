import { SvgIconType } from "@swiggy-private/connect-svg-icons";

import { TPAYMENT_GROUP_CATEGORY, TExtractedMethodGroup } from "../types";

export const RECOMMENDED_PAYMENT_METHOD = "Preferred/Saved Payment Methods";

export const SELECT_A_BANK = "Select a Bank";

/** @TODO - Replace icons with actual icons */
export const PAYMENT_METHODS: {
    [key in TExtractedMethodGroup]: {
        icon: SvgIconType["icon"];
        newItemMessage: {
            title: string;
            description: string;
        };
    };
} = {
    UPI: {
        icon: "Wallet",
        newItemMessage: {
            title: "Add a new UPI",
            description: "Paytm, Mobikwik, APay etc.",
        },
    },
    CARD: {
        icon: "Payouts",
        newItemMessage: {
            title: "Credit & Debit Cards",
            description: "Visa, MasterCard, Amex, Rupay etc.",
        },
    },
    NETBANKING: {
        icon: "Netbanking",
        newItemMessage: {
            title: "Net Banking",
            description: "Select from a list of banks",
        },
    },
};

export const PAYMENT_METHOD_KEYS = Object.keys(PAYMENT_METHODS) as Array<
    keyof typeof PAYMENT_METHODS
>;

export const PAYMENT_GROUP_METHOD: Record<TExtractedMethodGroup, TExtractedMethodGroup> = {
    UPI: "UPI",
    CARD: "CARD",
    NETBANKING: "NETBANKING",
};

export const PAYMENT_GROUP_CATEGORY: Record<TPAYMENT_GROUP_CATEGORY, TPAYMENT_GROUP_CATEGORY> = {
    NEW: "NEW",
    SAVED: "SAVED",
};

export const PAY_BY_ANY_UPI_LABEL = "Pay by any UPI app";

export const ACCORDION_PAYMENT_GROUP: Array<{
    key: TExtractedMethodGroup;
    title: string;
    logo: string;
}> = [
    {
        key: PAYMENT_GROUP_METHOD.UPI,
        title: PAY_BY_ANY_UPI_LABEL,
        logo: "minis/payments/npci_green_logo",
    },
    {
        key: PAYMENT_GROUP_METHOD.CARD,
        title: "Credit/Debit Cards",
        logo: "minis/payments/card_green_logo",
    },
    {
        key: PAYMENT_GROUP_METHOD.NETBANKING,
        title: "Net Banking",
        logo: "minis/payments/bank_green_logo",
    },
];

export const SHIELD_UPI = "minis/payments/shield_upi_green";
