import React, { memo, useMemo } from "react";
import { Platform, StyleSheet } from "react-native";
import LinearGradient from "react-native-linear-gradient";

import { hexToRGB, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { Product } from "@minis-consumer/interfaces/catalog";
import { CHANNEL_ICON_MAP, DEFAULT_CHANNEL_ICON } from "@minis-consumer/constants/product";

import * as Constants from "./constants";

interface Props {
    serviceDeliveryChannels: Product["serviceDeliveryChannels"];
}

const isWeb = Platform.OS === "web";

/** To be used for Plan and Appointment type products */
const ProductCommChannelsComponent: React.FC<Props> = ({ serviceDeliveryChannels }) => {
    const { value: theme } = useTheme();

    const gradientColors = useMemo(() => {
        const rgbValue = hexToRGB(theme.background_Button_Secondary.toString());
        return [
            `rgba(${rgbValue.red}, ${rgbValue.green}, ${rgbValue.blue}, 0)`,
            `rgba(${rgbValue.red}, ${rgbValue.green}, ${rgbValue.blue}, 1)`,
        ];
    }, [theme]);

    if (!serviceDeliveryChannels?.length) {
        return null;
    }

    return (
        <Stack>
            <Text category="b2" color="low" weight="regular" numberOfLines={1} ellipsizeMode="tail">
                {Constants.HEADER}
            </Text>

            <Stack
                direction="row"
                spacing={isWeb ? 0 : SpacingValue["space-medium"]}
                style={styles.channelListContainer}>
                {serviceDeliveryChannels.map((channel, index) => {
                    const iconName =
                        CHANNEL_ICON_MAP[channel.toUpperCase()]?.icon ?? DEFAULT_CHANNEL_ICON;
                    return (
                        <LinearGradient
                            start={Constants.GRADIENT_LOCATIONS_START}
                            end={Constants.GRADIENT_LOCATIONS_END}
                            colors={gradientColors}
                            style={{
                                paddingHorizontal: SpacingValue["space-small"],
                                paddingVertical: SpacingValue["space-x-small"],
                                marginBottom: SpacingValue["space-small"],
                                marginRight: isWeb ? SpacingValue["space-small"] : 0,
                                borderTopLeftRadius: 32,
                                borderBottomLeftRadius: 32,
                            }}
                            key={`channel_${index}`}>
                            <Stack
                                direction="row"
                                spacing={SpacingValue["space-x-small"]}
                                alignItems="center">
                                <SvgIcon
                                    icon={iconName}
                                    height={16}
                                    width={16}
                                    color="background_Button_Primary"
                                />

                                <Text category="b2" color="high" weight="medium">
                                    {CHANNEL_ICON_MAP[channel.toUpperCase()]?.text ?? channel}
                                </Text>
                            </Stack>
                        </LinearGradient>
                    );
                })}
            </Stack>
        </Stack>
    );
};

export const ProductCommChannels = memo(ProductCommChannelsComponent);

const styles = StyleSheet.create({
    channelListContainer: {
        flexWrap: "wrap",
        marginTop: SpacingValue["space-small"],
        marginBottom: -SpacingValue["space-x-small"],
    },
});
