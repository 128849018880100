import React from "react";

import { Text, SpacingValue } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

interface Props {
    title: string;
    onPressBack: () => void;
}

export const HeaderLeft: React.FC<Props> = ({ title, onPressBack }) => {
    return (
        <Box
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            ml={SpacingValue["space-medium"]}>
            <SvgIcon icon="ArrowLeft" onPress={onPressBack} width={20} height={20} />
            <Box ml={SpacingValue["space-medium"]}>
                <Text category="h5" color="high" weight="bold">
                    {title}
                </Text>
            </Box>
        </Box>
    );
};
