import * as React from "react";
import { Pressable, StyleSheet, ViewStyle } from "react-native";

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useMount } from "@swiggy-private/react-hooks";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { Stack, StackProps, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { Card } from "@minis-consumer/components/card";
import { OrderRatingCard } from "@minis-consumer/components/order-rating";
import { useOrderBasedDeliveryETA } from "@minis-consumer/hooks/use-delivery-eta";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";
import { TrackingMeta, useOrderTracking } from "@minis-consumer/hooks/use-order-tracking";
import { useReorderItems } from "@minis-consumer/hooks/use-reorder-items";
import { PaymentProgress } from "@minis-consumer/includes/payment-progress";
import { Order } from "@minis-consumer/interfaces/order";
import { RouteList } from "@minis-consumer/interfaces/route";
import { ConfirmDialog } from "@minis-consumer/components/confirm-dialog";
import { useCancelOrderUtil } from "@minis-consumer/hooks/use-cancel-order";

import StatusDescription from "./components/status-desciption";
import { ORDER_TRACKING_CTA_TEXT, CANCEL_ORDER } from "./constants";

type OrderTrackingGistProps = {
    order: Order;
    onRetryPaymentTimeout: () => void;
};

const OrderTrackingGist: React.FC<OrderTrackingGistProps> = ({ order, onRetryPaymentTimeout }) => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const reorderItems = useReorderItems(order.storeDetails.id, order.storeDetails.slug);
    const { fetchOrderETA, deliveryETAinDays } = useOrderBasedDeliveryETA();

    const status = order.status;
    const rating = order.rating;
    const isStoreEnabled = order.storeDetails.enabled !== false;
    const storeId = order.storeDetails.id;

    const {
        showModal,
        showCancelOrderDialog,
        onCancelPress,
        closeDialog,
        loading,
        dialogImpressionEvent,
    } = useCancelOrderUtil(order);

    const trackingMeta = useOrderTracking({
        orderStatus: status,
        shippingDetails: order.shippingDetails,
        cancellationDetails: order.cancellationDetails,
        consumer: "OrderDetailsScreen",
        updatedAt: order.updatedAt,
        createdAt: order.createdAt,
        orderTimeout: order.orderTimeout,
        orderType: order.orderType,
        statusUpdatedAt: order.statusUpdatedAt,
        storeDetails: order.storeDetails,
        onTimeout: onRetryPaymentTimeout,
        orderDeliveryETA: deliveryETAinDays,
    });

    const showSellerNote = React.useMemo(
        () => Boolean(trackingMeta?.body?.sellerCancellationReason?.length),
        [trackingMeta?.body?.sellerCancellationReason],
    );

    const orderRating = React.useMemo(
        () =>
            rating && rating.value > 0
                ? { isOrderRated: true, value: rating.value }
                : { isOrderRated: false, value: 0 },
        [rating],
    );

    const direction: StackProps["direction"] = useSelectScreen({
        lg: "column",
        default: "column",
    });

    const stackStyle: ViewStyle = useSelectScreen({
        lg: { width: "100%", flexGrow: 1 },
        default: { width: "100%" },
    });

    const btnStyle: ViewStyle = useSelectScreen({
        lg: { maxWidth: "30%", flexGrow: 1 },
        default: { width: "100%" },
    });

    const showDivider = useSelectScreen({
        lg: false,
        default: true,
    });

    const isDesktop = useIsDesktop();

    const ratingStyle: ViewStyle = useSelectScreen({
        lg: { marginBottom: 0, maxWidth: "30%", flexGrow: 1 },
        default: { marginBottom: SpacingValue["space-medium"], width: "100%" },
    });

    const triggerCtaClickEvent = React.useCallback(
        (category: string) => {
            Analytics.clickEvent({
                category,
                label: order.id,
                context: order.status,
            });
        },
        [order.id, order.status],
    );

    const onCtaPress = React.useCallback(async () => {
        if (trackingMeta?.ctaType === "Track") {
            triggerCtaClickEvent("track-order-btn");
            navigation.navigate("Tracking", { id: order.id });
        } else if (trackingMeta?.ctaType === "Reorder") {
            triggerCtaClickEvent("repeat-order-btn");
            reorderItems(order.items);
        } else if (trackingMeta?.ctaType === "Cancel") {
            triggerCtaClickEvent("cancel-order-btn");
            showCancelOrderDialog();
        } else if (trackingMeta?.ctaType === "RetryPayment") {
            triggerCtaClickEvent("retry-payment-btn");
            await PaymentProgress.clear();
            navigation.navigate("Payment", {
                screen: "PaymentHome",
                params: { orderId: order.id },
            });
        }
    }, [
        navigation,
        order.id,
        order.items,
        reorderItems,
        trackingMeta?.ctaType,
        triggerCtaClickEvent,
    ]);

    useMount(() => {
        if (order.status === "DELIVERED") {
            Analytics.impressionEvent({
                category: "rating-bar",
                label: order.id,
            });
        }

        //* Fetches delivery ETA based on order
        fetchOrderETA?.(order);
    });

    return (
        <>
            <Card style={!isDesktop && showSellerNote ? styles.firstCard : undefined}>
                <Stack
                    spacing={SpacingValue["space-medium"]}
                    flexGrow={1}
                    divider={
                        status === "DELIVERED" ? (
                            <DashBorderLine
                                borderColor={theme["color-basic-15"]}
                                style={styles.line}
                            />
                        ) : undefined
                    }>
                    <Stack
                        divider={
                            status === "DELIVERED" && showDivider ? (
                                <DashBorderLine
                                    borderColor={theme["color-basic-15"]}
                                    style={styles.line}
                                />
                            ) : undefined
                        }
                        flexGrow={1}
                        alignItems="center"
                        justifyContent="space-between"
                        direction={direction}
                        spacing={SpacingValue["space-medium"]}>
                        <Stack direction="row" alignItems="center" style={stackStyle}>
                            <Stack
                                flex={1}
                                divider={
                                    <DashBorderLine
                                        borderColor={theme["color-basic-15"]}
                                        style={styles.line2}
                                    />
                                }>
                                <StatusDescription
                                    header={trackingMeta?.header}
                                    body={trackingMeta?.body}
                                    status={status}
                                    cta={
                                        isDesktop && order.status === "IN_TRANSIT" ? (
                                            <OrderDetailCta
                                                btnStyle={btnStyle}
                                                onCtaPress={onCtaPress}
                                                ctaType={trackingMeta?.ctaType}
                                                order={order}
                                            />
                                        ) : undefined
                                    }
                                />

                                {isDesktop && showSellerNote ? (
                                    <Text>
                                        <Text category="b1" color="color-basic-60" weight="bold">
                                            {"Seller note: "}
                                        </Text>
                                        <Text category="s3" color="color-basic-60">
                                            &ldquo;
                                            <Text category="s3" color="color-basic-60">
                                                {trackingMeta?.body?.sellerCancellationReason}
                                            </Text>
                                            &rdquo;
                                        </Text>
                                    </Text>
                                ) : null}
                            </Stack>
                        </Stack>

                        {!isDesktop && status === "DELIVERED" ? (
                            <OrderRatingCard
                                style={ratingStyle}
                                rated={orderRating.isOrderRated}
                                value={orderRating.value}
                                orderId={order.id}
                                lgDirection="column"
                                isStoreEnabled={isStoreEnabled}
                                storeId={storeId}
                            />
                        ) : null}

                        {(trackingMeta?.ctaType !== "None" && !isDesktop) ||
                        (isDesktop && order.status !== "IN_TRANSIT") ? (
                            <OrderDetailCta
                                btnStyle={btnStyle}
                                onCtaPress={onCtaPress}
                                ctaType={trackingMeta?.ctaType}
                                order={order}
                            />
                        ) : null}
                    </Stack>

                    {isDesktop && status === "DELIVERED" ? (
                        <OrderRatingCard
                            rated={orderRating.isOrderRated}
                            value={orderRating.value}
                            orderId={order.id}
                            lgDirection="column"
                            isStoreEnabled={isStoreEnabled}
                            storeId={storeId}
                        />
                    ) : null}
                </Stack>
            </Card>

            {!isDesktop && showSellerNote ? (
                <Card
                    style={[
                        styles.secondCard,
                        {
                            backgroundColor: theme["color-warning-25"],
                        },
                    ]}>
                    <Stack spacing={SpacingValue["space-x-small"]}>
                        <Text category="b2" color="color-basic-60" weight="bold">
                            Note from {order.storeDetails.name}:
                        </Text>
                        <Text category="b2" color="color-basic-60">
                            &ldquo;
                            <Text category="b2" color="color-basic-60">
                                {trackingMeta?.body?.sellerCancellationReason}
                            </Text>
                            &rdquo;
                        </Text>
                    </Stack>
                </Card>
            ) : null}

            <ConfirmDialog
                open={showModal}
                onClose={closeDialog}
                title={CANCEL_ORDER.MODAL.TITLE}
                description={CANCEL_ORDER.MODAL.DESCRIPTION}
                onPressCancel={closeDialog}
                onPressContinue={onCancelPress}
                cancelBtnText={CANCEL_ORDER.MODAL.SECONDARY_CTA}
                continueBtnText={CANCEL_ORDER.MODAL.PRIMARY_CTA}
                loading={loading}
                onMount={dialogImpressionEvent}
            />
        </>
    );
};

type OrderDetailCtaProps = {
    onCtaPress: () => void;
    order: Order;

    ctaType?: TrackingMeta["ctaType"];
    btnStyle?: ViewStyle;
};

const OrderDetailCta: React.FC<OrderDetailCtaProps> = ({
    btnStyle,
    order,
    onCtaPress,
    ctaType,
}) => {
    const btnAlignment = useSelectScreen<ViewStyle>({
        lg: {
            alignSelf: "flex-start",
            marginLeft: SpacingValue["space-medium"],
        },
        default: {
            alignSelf: "flex-start",
        },
    });

    const isStoreEnabled = order.storeDetails.enabled !== false;

    const disableCta = React.useMemo(
        () => !isStoreEnabled && (ctaType === "Reorder" || ctaType === "RetryPayment"),
        [ctaType, isStoreEnabled],
    );

    const triggerImpressionEvent = React.useCallback(
        (category: string) => {
            Analytics.impressionEvent({
                category,
                label: order.id,
                context: order.status,
            });
        },
        [order.id, order.status],
    );

    useMount(() => {
        switch (ctaType) {
            case "Track":
                triggerImpressionEvent("track-order-btn");
                break;

            case "Reorder":
                triggerImpressionEvent("repeat-order-btn");
                break;

            case "RetryPayment":
                triggerImpressionEvent("retry-payment-btn");
                break;

            case "Cancel":
                triggerImpressionEvent("cancel-order-btn");
                break;
        }
    });

    return (
        <>
            {ctaType && ctaType !== "None" && ctaType !== "Refund" && ctaType !== "Cancel" ? (
                <Button
                    style={[styles.btn, btnAlignment, btnStyle]}
                    onPress={onCtaPress}
                    disabled={disableCta}
                    color={ctaType === "Reorder" ? "tertiary" : "primary"}>
                    {ORDER_TRACKING_CTA_TEXT[ctaType]}
                </Button>
            ) : null}
            {ctaType && ctaType === "Cancel" ? (
                <Pressable onPress={onCtaPress} disabled={disableCta} style={styles.cancel}>
                    <Text category="b2" weight="bold" color={"primary"}>
                        {ORDER_TRACKING_CTA_TEXT[ctaType]}
                    </Text>
                </Pressable>
            ) : null}
        </>
    );
};

const styles = StyleSheet.create({
    firstCard: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    secondCard: {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        paddingVertical: SpacingValue["space-medium"],
    },
    line: {
        marginTop: 0,
        marginBottom: SpacingValue["space-medium"],
        width: "100%",
    },
    line2: {
        marginVertical: SpacingValue["space-small"],
        width: "100%",
    },
    btn: {
        shadowColor: "transparent",
    },
    cancel: {
        width: "100%",
        display: "flex",
    },
});

export default React.memo(OrderTrackingGist);
