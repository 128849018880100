import * as React from "react";
import { StyleSheet } from "react-native";

import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Shimmer } from "@minis-consumer/components/shimmer";
import { CommonTile } from "./common-tile";

const shimmerWidth = [100, 213, 250, 96, 210, 120, 156, 78, 66];

export const FAQHelpPlaceholder: React.FC = () => {
    const { value: theme } = useTheme();
    const bgPrimaryColor = theme["color-background-primary"];

    const containerColor = { backgroundColor: bgPrimaryColor };
    const containerStyle = useSelectScreen({
        lg: {
            marginVertical: SpacingValue["space-xx-large"],
        },
        default: {
            marginVertical: SpacingValue["space-medium"],
        },
    });

    return (
        <Box style={[containerStyle, styles.contentBorderRadius, containerColor]}>
            <Stack spacing={SpacingValue["space-medium"]}>
                {shimmerWidth.map((val, index) => {
                    return (
                        <CommonTile
                            iconPresent={false}
                            key={index}
                            onPress={() => 0}
                            canBeHovered={false}
                            isDividerPresent={true}
                            style={
                                index === 0
                                    ? styles.firstBoxStyle
                                    : index === 8
                                    ? styles.lastBoxStyle
                                    : {}
                            }>
                            <Shimmer
                                height={20}
                                width={val}
                                shimmerStyle={styles.contentBorderRadius}
                            />
                        </CommonTile>
                    );
                })}
            </Stack>
        </Box>
    );
};

const styles = StyleSheet.create({
    contentBorderRadius: { borderRadius: 16 },
    firstBoxStyle: { borderTopLeftRadius: 16, borderTopRightRadius: 16 },
    lastBoxStyle: { borderBottomLeftRadius: 16, borderBottomRightRadius: 16 },
});
