import * as React from "react";
import { useWindowDimensions, ViewStyle } from "react-native";

import { Shimmer } from "@swiggy-private/react-native-ui";
import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

export const SlotSelectionScreenLoader: React.FC = React.memo(() => {
    const { width: windowWidth } = useWindowDimensions();

    const containerWidth = windowWidth - 2 * SpacingValue["space-medium"];

    const contentStyle: ViewStyle | undefined = useSelectScreen({
        lg: { width: 500, alignSelf: "center" },
        md: { width: 500, alignSelf: "center" },
        default: { width: containerWidth },
    });

    const containerStyle = {
        ...contentStyle,
        marginHorizontal: SpacingValue["space-medium"],
        marginTop: SpacingValue["space-large"],
    };

    return (
        <Stack flex={1} alignItems="center" style={containerStyle}>
            <Stack spacing={SpacingValue["space-x-large"]}>
                <Stack spacing={SpacingValue["space-medium"]}>
                    <Shimmer height={20} width={100} />

                    <Shimmer height={40} width={contentStyle.width as number} />
                </Stack>

                <Stack spacing={SpacingValue["space-medium"]}>
                    <Shimmer height={20} width={100} />

                    <Shimmer height={40} width={contentStyle.width as number} />
                </Stack>
            </Stack>
        </Stack>
    );
});

if (__DEV__) {
    SlotSelectionScreenLoader.displayName = "SlotSelectionScreenLoader";
}
