import React from "react";
import { StyleSheet } from "react-native";

import { CdnImage } from "@swiggy-private/react-native-ui";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { Text, SpacingValue } from "@swiggy-private/rn-dls";

const COUPON_EMPTY_STATE_TEXT =
    "Sorry there are no coupons available at this moment.\n Please check back later.";
const EMPTY_IMAGE_WIDTH = 144;
const EMPTY_IMAGE_HEIGHT = 152;

export const CouponEmptyState: React.FC = () => {
    return (
        <Box
            alignItems="center"
            ph={2 * SpacingValue["space-x-large"]}
            justifyContent="center"
            flex={1}>
            <CdnImage
                isImageKitEnabled
                width={EMPTY_IMAGE_WIDTH}
                height={EMPTY_IMAGE_HEIGHT}
                id="minis/coupon_empty_state"
                resizeMode="cover"
                showLoader
            />

            <Text category="b1" color="medium" style={styles.text}>
                {COUPON_EMPTY_STATE_TEXT}
            </Text>
        </Box>
    );
};

const styles = StyleSheet.create({
    text: {
        textAlign: "center",
        marginTop: SpacingValue["space-medium"],
    },
});
