import { ViewStyle } from "react-native";
import { addDays, format, getUnixTime, isToday, isTomorrow, startOfDay } from "date-fns";

import { SpacingValue } from "@swiggy-private/rn-dls";

import { Event as AnalyticsEventData } from "@minis-consumer/analytics";
import { FetchScheduledMeetingSlotsResponse } from "@minis-consumer/api/product/schedule-management";
import { ProductDuration } from "@minis-consumer/interfaces/catalog";

export interface FormattedDate {
    label: string;
    date: string;
    dateEpoch: number;
    slots?: {
        start: number;
        end: number;
    }[];
}

export interface TimeSlot {
    start: number;
    end: number;
}

export interface SelectionListComponentProps {
    onTimeChange: (time: TimeSlot) => void;
    onDateChange: (date: FormattedDate) => void;
    selectedTime: number;
    selectedDate: number;
    timeList?: boolean;
    dates?: FormattedDate[];
    times?: TimeSlot[];
}

export interface DateTimeItem {
    dateEpoch?: number;
    start?: number;
    end?: number;
}

export interface SlotSelectionComponentProps {
    onPress: () => void;
    title?: string;
    subTitle?: string;
    item: DateTimeItem;
    selectedTime?: number;
    selectedDate?: number;
    extraStyles?: ViewStyle;
}

export interface ISlotSelectionFormComponent {
    onProceed: (sD: number, sT: number, sText: string) => void;
    analyticsData: AnalyticsEventData;
    productId: string;

    slotData: FetchScheduledMeetingSlotsResponse;
    selectedSlotInEpoch?: number;
    vacationDays?: number[];
    isCustom?: boolean;
    headerSubTitle?: string;
}

interface SelectedTitles {
    title: string;
    subtitle: string;
}

interface ProductData {
    name: string;
    productDuration?: ProductDuration;
}

export function generateDateRange(): FormattedDate[] {
    const dates: FormattedDate[] = [];

    let currentDate = startOfDay(new Date());
    const lastDate = addDays(currentDate, 29);

    while (currentDate <= lastDate) {
        let label: string;
        if (isToday(currentDate)) {
            label = "Today";
        } else if (isTomorrow(currentDate)) {
            label = "Tomorrow";
        } else {
            label = format(currentDate, "EEEE");
        }

        dates.push({
            label,
            date: format(currentDate, "do',' MMMM"),
            dateEpoch: getUnixTime(currentDate),
        });

        currentDate = addDays(currentDate, 1);
    }

    return dates;
}

export const calculateItemSize = ({
    listContainerWidth,
    numColumns,
    horizontalPadding = SpacingValue["space-medium"],
    columnGap = SpacingValue["space-small"],
}: {
    listContainerWidth: number;
    numColumns: number;
    horizontalPadding?: number;
    columnGap?: number;
}): number => {
    const totalHorizontalPadding = 2 * horizontalPadding;
    const totalColumnGaps = (numColumns - 1) * columnGap;
    const availableWidth = listContainerWidth - totalHorizontalPadding - totalColumnGaps;
    return availableWidth / numColumns;
};

export const getSelectedTitles = (
    orderedDates: FormattedDate[],
    selectedDate: number,
    selectedSlotInEpoch: number,
): SelectedTitles => {
    const selected = orderedDates.find((date) => date.dateEpoch === selectedDate);

    const subtitle = selected
        ? `${selected.label}, ${selected.date}`
        : format(selectedSlotInEpoch * 1000, "do',' MMMM");

    const title = selected
        ? format(selected.dateEpoch * 1000, "MMMM")
        : format(selectedSlotInEpoch * 1000, "MMMM");

    return { title, subtitle };
};

export const formatSelectedSlot = (selectedTime: number, selectedDate: number): string => {
    const formatTime = (timestamp: number): string => format(new Date(timestamp * 1000), "hh:mm a");

    const formatDate = (timestamp: number): string =>
        format(new Date(timestamp * 1000), "eee, d MMM");

    return `${formatTime(selectedTime)}, ${formatDate(selectedDate)}`;
};

export const generateSubHeaderText = (productData: ProductData): string => {
    const { name, productDuration } = productData;

    if (!productDuration) {
        return `for ${name}`;
    }

    const { value, unit } = productDuration;
    const formattedUnit = unit.toLowerCase();

    return `for ${name} • ${value} ${formattedUnit}`;
};
