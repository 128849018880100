import React, { useEffect } from "react";
import { Pressable, StyleSheet, ViewStyle, useWindowDimensions } from "react-native";

import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Surface, Text, useTheme } from "@swiggy-private/rn-dls";
import { BottomSheet, useBackListener } from "@swiggy-private/react-native-ui";

import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";
import { Analytics } from "@minis-consumer/analytics";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";

import { CONST } from "./constants";

interface ICustomCartItemsHandler {
    canShowModal: boolean;
    onReplaceItemsCb: VoidFunction;
    onKeepItemsCb: VoidFunction;
    storeId: string;
    setShowCustomCartHandlerModal: (c: boolean) => void;
}

export const CustomCartLinkHandlerComponent: React.FC<ICustomCartItemsHandler> = (props) => {
    const { onReplaceItemsCb, storeId, setShowCustomCartHandlerModal } = props;
    const cart = useCartViewData(storeId);

    const cartId = cart?.id ?? "";

    const isDesktop = useIsDesktop();
    const { value: theme } = useTheme();

    const screenSizeSpecificRootStyle: ViewStyle = useSelectScreen({
        default: {
            paddingTop: SpacingValue["space-x-large"],
            paddingBottom: SpacingValue["space-xx-large"],
            paddingHorizontal: SpacingValue["space-medium"],
        },
        lg: {
            paddingBottom: SpacingValue["space-x-large"],
            paddingTop: SpacingValue["space-xx-large"],
            paddingHorizontal: SpacingValue["space-xx-large"],
        },
    });

    const screenSizeSpecificButtonGap: number = useSelectScreen({
        default: SpacingValue["space-small"],
        lg: SpacingValue["space-medium"],
    });

    const primaryBtnStyle: ViewStyle = {
        borderColor: theme["color-positive-500"],
        backgroundColor: theme["color-positive-500"],
    };

    const secondaryBtnStyle: ViewStyle = {
        borderColor: theme["color-critical-100"],
        backgroundColor: theme["color-basic-0"],
    };

    const onPressReplaceItems = React.useCallback(() => {
        Analytics.clickEvent({
            category: CONST.REPLACE_ITEMS_ANALYTICS_CATEGORY,
            context: storeId,
            label: cartId,
        });

        onReplaceItemsCb();
    }, [cartId, onReplaceItemsCb, storeId]);

    const onCancel = React.useCallback(() => {
        setShowCustomCartHandlerModal(false);
    }, [setShowCustomCartHandlerModal]);

    useEffect(() => {
        if (!cartId) {
            return;
        }

        [CONST.KEEP_ITEMS_ANALYTICS_CATEGORY, CONST.REPLACE_ITEMS_ANALYTICS_CATEGORY].forEach(
            (category) =>
                Analytics.impressionEvent({
                    category,
                    context: storeId,
                    label: cartId,
                }),
        );
    }, [cartId, storeId]);

    return (
        <Stack style={screenSizeSpecificRootStyle} spacing={SpacingValue["space-x-small"]}>
            <Text
                category={isDesktop ? "h4" : "b1"}
                weight={!isDesktop ? "bold" : undefined}
                color="color-basic-100">
                {CONST.TITLE_COPY}
            </Text>
            <Text category={isDesktop ? "b1" : "b2"} weight="regular" color="color-basic-60">
                {CONST.DESCRIPTION_COPY}
            </Text>

            <Stack
                alignItems="center"
                justifyContent="center"
                direction="row"
                style={styles.btnContainer}
                spacing={screenSizeSpecificButtonGap}>
                <Surface style={styles.surface}>
                    <Pressable onPress={onCancel} style={[styles.btn, secondaryBtnStyle]}>
                        <Text
                            category="b1"
                            weight="bold"
                            color="color-critical-200"
                            numberOfLines={1}>
                            {CONST.CANCEL_BTN_TXT_COPY}
                        </Text>
                    </Pressable>
                </Surface>
                <Surface style={styles.surface}>
                    <Pressable onPress={onPressReplaceItems} style={[styles.btn, primaryBtnStyle]}>
                        <Text category="btn3" weight="bold" color="color-basic-0" numberOfLines={1}>
                            {CONST.REPLACE_BTN_TXT_COPY}
                        </Text>
                    </Pressable>
                </Surface>
            </Stack>
        </Stack>
    );
};

const CustomCartLinkHandlerMain: React.FC<ICustomCartItemsHandler> = React.memo((props) => {
    const { canShowModal } = props;

    const { width: screenWidth } = useWindowDimensions();
    const { value: theme } = useTheme();

    const screenSizeSpecificBottomSheetStyle: ViewStyle = useSelectScreen({
        default: {
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
        },
        lg: {
            borderRadius: 20,
            width: Math.min(CONST.MAX_DESKTOP_WIDTH, screenWidth * 0.28),
        },
    });

    useBackListener(() => true);

    return (
        <BottomSheet
            disableBackdropClose
            open={canShowModal}
            disableHardwareBackClose
            style={[
                screenSizeSpecificBottomSheetStyle,
                styles.bottomSheet,
                { borderColor: theme["color-basic-5"] },
            ]}>
            {canShowModal ? <CustomCartLinkHandlerComponent {...props} /> : null}
        </BottomSheet>
    );
});

if (__DEV__) {
    CustomCartLinkHandlerMain.displayName = "CustomCartLinkHandlerMain";
}

const styles = StyleSheet.create({
    bottomSheet: {
        borderWidth: StyleSheet.hairlineWidth,
    },
    btnContainer: {
        marginTop: SpacingValue["space-medium"],
        alignItems: "center",
        justifyContent: "center",
    },
    btn: {
        borderRadius: 12,
        borderWidth: 1,
        paddingVertical: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-medium"],
        alignItems: "center",
    },
    surface: {
        elevation: 2,
        backgroundColor: "none",
        borderRadius: 12,
        flex: 1,
    },
});

export const CustomCartLinkHandler = React.memo(CustomCartLinkHandlerMain);
