import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SpacingValue, Text, TextProps } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { InView } from "@swiggy-private/react-native-ui";

import { ReadMoreText } from "@minis-consumer/components/read-more-text";
import { Divider } from "@minis-consumer/components/divider";
import { StoreSettings } from "@minis-consumer/interfaces/store";
import { Analytics } from "@minis-consumer/analytics";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";

type ReturnPolicyParam = {
    policy: StoreSettings["returnPolicy"];
    productId: string;
};

const TEXT_MAX_COUNT = 140;
const TITLE = "Refund and Returns";
const ANALYTICS_POLICY_CATEGORY = "refunds-return-tab";
const DEFAULT_REFUND_TEXT =
    "Refund / return policy not available. Chat now with the store for more details.";

const ReturnPolicyComponent: React.FC<ReturnPolicyParam> = ({ policy, productId }) => {
    const isDesktop = useIsDesktop();

    const { storeId } = useStoreInfo();

    const dividerStyle = {
        marginTop: SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-large"],
    };

    const [showPolicy, setShowPolicy] = React.useState(true);

    const togglePolicyView = React.useCallback(() => {
        !isDesktop && setShowPolicy(!showPolicy);
    }, [showPolicy, isDesktop]);

    const onImpressionPolicy = React.useCallback(
        (isVisible: boolean) => {
            if (!isVisible || !showPolicy) {
                return;
            }

            Analytics.impressionEvent({
                category: ANALYTICS_POLICY_CATEGORY,
                context: JSON.stringify({
                    store_id: storeId,
                    product_id: productId,
                }),
            });
        },
        [productId, showPolicy, storeId],
    );

    const textProps: TextProps = {
        category: "b2",
        weight: "bold",
        color: "color-primary",
    };

    const policyTextProps: TextProps = {
        category: "b2",
        weight: "regular",
        color: "color-basic-60",
    };

    return (
        <>
            <Pressable onPress={togglePolicyView} hitSlop={16}>
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                    style={styles.headerContainer}>
                    <Text category="subheader_S1_Bold" weight="bold" color="high">
                        {TITLE}
                    </Text>

                    {!isDesktop ? (
                        <Box style={styles.caret}>
                            <SvgIcon
                                icon={showPolicy ? "ChevronUp" : "ChevronDown"}
                                color="color-basic-75"
                                width={20}
                            />
                        </Box>
                    ) : null}
                </Stack>
            </Pressable>
            {showPolicy ? (
                <InView onChange={onImpressionPolicy}>
                    <Box style={styles.details} mr={SpacingValue["space-medium"] * 2}>
                        {policy ? (
                            <ReadMoreText
                                text={policy as string}
                                maxLengthCount={TEXT_MAX_COUNT}
                                textProps={policyTextProps}
                                expandedTextProps={textProps}
                                collapsedTextProps={textProps}
                            />
                        ) : (
                            <Text category="b2" weight="regular" color="color-basic-60">
                                {DEFAULT_REFUND_TEXT}
                            </Text>
                        )}
                    </Box>
                </InView>
            ) : null}

            {!isDesktop ? <Divider style={dividerStyle} /> : null}
        </>
    );
};

export const ReturnPolicy = React.memo(ReturnPolicyComponent);

const styles = StyleSheet.create({
    caret: {
        width: 24,
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    headerContainer: {
        flex: 1,
    },
    details: {
        marginTop: SpacingValue["space-medium"],
    },
});
