import React, { useMemo } from "react";
import { Platform, Pressable, ScrollView, StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Surface, Text, useTheme } from "@swiggy-private/rn-dls";

import { useIsMobileWeb } from "@minis-consumer/hooks/use-mweb";
import { BottomSheetWrapper } from "@minis-consumer/routes/payment/components/bottom-sheet";
import { ItemDivider } from "@minis-consumer/routes/payment/components/divider";
import { NetBankingMethod } from "@minis-consumer/routes/payment/components/net-banking-method";
import {
    PAYMENT_GROUP_CATEGORY,
    PAYMENT_GROUP_METHOD,
    PAYNOW_METHOD_ANALYTICS,
    SELECT_A_BANK,
} from "@minis-consumer/routes/payment/constants";
import { usePaymentDispatch } from "@minis-consumer/routes/payment/hooks/use-dispatch";
import { useNetBankingBanks } from "@minis-consumer/routes/payment/hooks/use-netbanking-banks";
import { useOtherBankSelectedName } from "@minis-consumer/routes/payment/hooks/use-selector";
import { Analytics } from "@minis-consumer/analytics";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { usePaymentOrder } from "@minis-consumer/routes/payment/hooks/use-payment-order";

const defaultContentHeight = 450;

const NetBankingSectionComponent: React.FC = () => {
    const storeInfo = useStoreInfo();
    const cart = useCartViewData(storeInfo?.storeId);
    const order = usePaymentOrder();
    const { popularBanks, allBanks } = useNetBankingBanks();
    const paymentDispatch = usePaymentDispatch();
    const otherBankSelectedName = useOtherBankSelectedName();
    const isMweb = useIsMobileWeb();
    const [showBankSheet, setBankSheet] = React.useState(false);
    const { value: theme } = useTheme();

    const analyticsContext = useMemo(() => {
        return {
            orderId: order?.id || "-",
            cartId: cart?.id || "-",
            productType: cart?.cartType || order?.orderType,
            paymentAmount: cart?.bill?.finalBillTotal || order?.bill?.finalBillTotal,
            transactionId: order?.txnId,
        };
    }, [order, cart]);

    const onPayCallback = (bankName: string, bankCode: string): void => {
        Analytics.clickEvent({
            category: PAYNOW_METHOD_ANALYTICS.SELECT_BANK,
            context: JSON.stringify({
                ...analyticsContext,
                selectedBank: bankName,
            }),
        });

        closeBottomSheet();
        paymentDispatch({
            type: "UPDATE_SELECTED_PAYMENT_METHOD",
            payload: {
                category: PAYMENT_GROUP_CATEGORY.NEW,
                type: PAYMENT_GROUP_METHOD.NETBANKING,
                value: bankCode,
                extraData: bankName,
            },
        });
    };

    const headerShadowStyle = Platform.select({
        ios: { elevation: 2 },
        web: { elevation: isMweb ? 4 : 0 },
        android: { elevation: 4 },
    });

    const isBankNotSelected = React.useMemo(
        () => otherBankSelectedName.indexOf("Other") > -1,
        [otherBankSelectedName],
    );

    const closeBottomSheet = React.useCallback(() => {
        setBankSheet(false);
    }, []);
    const openBottomSheet = React.useCallback(() => {
        setBankSheet(true);
    }, []);

    const modalSurfaceStyle = useSelectScreen({
        lg: { borderRadius: 16, height: defaultContentHeight },
        default: {},
    });

    const contentHeight = Platform.select({
        ios: defaultContentHeight,
        web: defaultContentHeight,
        android: defaultContentHeight - 50,
    });

    return (
        <>
            <Stack flex={1} direction="column" style={styles.container}>
                <NetBankingMethod popularBanks={popularBanks} onPress={onPayCallback} />
                <ItemDivider />

                <Pressable onPress={openBottomSheet}>
                    <Stack
                        direction={"row"}
                        justifyContent="space-between"
                        alignItems="center"
                        style={[
                            styles.allBanksButton,
                            {
                                borderColor: theme["color-basic-30"],
                            },
                        ]}>
                        <Text
                            category="b1"
                            weight="medium"
                            color={isBankNotSelected ? "color-basic-45" : "color-basic-75"}>
                            {otherBankSelectedName}
                        </Text>
                        <SvgIcon icon="ChevronDown" color={"color-basic-60"} />
                    </Stack>
                </Pressable>
            </Stack>
            <BottomSheetWrapper
                open={showBankSheet}
                contentHeight={contentHeight}
                onClose={closeBottomSheet}
                style={[styles.dialog, modalSurfaceStyle]}>
                <Surface style={[styles.bottomSheetTopSection, headerShadowStyle]}>
                    <Stack direction={"row"} justifyContent="space-between" alignItems="center">
                        <Text category="b1" weight="bold" color="highest">
                            {SELECT_A_BANK}
                        </Text>
                        <Pressable
                            onPress={closeBottomSheet}
                            style={[
                                {
                                    backgroundColor: theme["color-background-secondary"],
                                },
                                styles.closeIconWrapper,
                            ]}>
                            <SvgIcon
                                icon="Close"
                                width={10}
                                height={10}
                                fontWeight={"bold"}
                                color={"color-basic-60"}
                            />
                        </Pressable>
                    </Stack>
                </Surface>

                <ScrollView>
                    <NetBankingMethod allBanks={allBanks} onPress={onPayCallback} />
                </ScrollView>
            </BottomSheetWrapper>
        </>
    );
};

export const NetBankingSection = React.memo(NetBankingSectionComponent);

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SpacingValue["space-xx-small"],
        paddingVertical: SpacingValue["space-xxx-small"],
    },
    surface: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    dialog: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    fullWidth: {
        width: "100%",
    },
    closeIconWrapper: {
        borderRadius: 20,
        padding: SpacingValue["space-small"] - SpacingValue["space-xxx-small"],
    },
    bottomSheetTopSection: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        marginTop: -SpacingValue["space-medium"],
        padding: SpacingValue["space-medium"],
        marginHorizontal: -SpacingValue["space-medium"],
    },
    allBanksButton: {
        borderWidth: 1,
        marginTop: SpacingValue["space-large"],
        marginBottom: SpacingValue["space-small"],
        padding: SpacingValue["space-medium"],
        borderRadius: 12,
    },
});
