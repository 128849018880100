import React from "react";
import { StyleSheet } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { IndicatorCircle } from "./circle";
import { IndicatorLine } from "./line";

const AddressIndicatorComponent: React.FC = () => {
    return (
        <Box style={styles.container} alignItems="center" pv={SpacingValue["space-xx-small"]}>
            <IndicatorCircle />
            <IndicatorLine />
            <IndicatorCircle />
        </Box>
    );
};

export const AddressIndicator = React.memo(AddressIndicatorComponent);

const styles = StyleSheet.create({
    container: {
        minWidth: 20,
    },
});
