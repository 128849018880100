import React from "react";
import { useCache } from "@rest-hooks/react";

import { convertArrayListToRecord } from "@minis-consumer/helpers/object";
import { Product } from "@minis-consumer/interfaces/catalog";
import { CatalogView } from "@minis-consumer/resources/catalog";

export const useGetOtherRecommendedProducts = (
    productIds: string[],
    storeSlug: string,
): Product[] => {
    const [recommendedProducts, setRecommendedProducts] = React.useState<Product[]>([]);

    const catalogView = useCache(CatalogView, { storeSlug });

    const getRecommendedProducts = React.useCallback(() => {
        if (!productIds?.length) {
            setRecommendedProducts([]);
            return;
        }

        const productsRecord = convertArrayListToRecord(catalogView?.products ?? []);

        const filteredProducts = productIds.map((id) => productsRecord[id]);

        setRecommendedProducts(filteredProducts);
    }, [productIds, catalogView?.products]);

    React.useEffect(() => {
        getRecommendedProducts();
    }, [productIds, getRecommendedProducts]);

    return recommendedProducts;
};
