import React from "react";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { Card } from "../../../../components/card";
import { BulletPoint } from "./components/bullet-point";
import { DottedUnderlineText } from "../common/dotted-underline-text";
import { DEFAULT_SHOWN_CANCELLATION_POLICY_COUNT } from "./constant";

interface Props {
    policy: string[];
}

const CancellationPolicyComponent: React.FC<Props> = ({ policy }) => {
    const [showFullPolicy, setShowFullPolicy] = React.useState(false);

    const policyToShow = React.useMemo(
        () => (showFullPolicy ? policy : policy.slice(0, DEFAULT_SHOWN_CANCELLATION_POLICY_COUNT)),
        [policy, showFullPolicy],
    );

    if (policy.length === 0) {
        return null;
    }

    return (
        <Card>
            <Stack spacing={SpacingValue["space-medium"]}>
                <Text color="high" category="b1" weight="bold">
                    Cancellation Policy
                </Text>

                <Stack spacing={SpacingValue["space-x-small"]}>
                    {policyToShow.map((item, index) => (
                        <BulletPoint key={index} text={item} />
                    ))}
                </Stack>

                {!showFullPolicy && policy.length > DEFAULT_SHOWN_CANCELLATION_POLICY_COUNT ? (
                    <Box direction="row">
                        <DottedUnderlineText
                            color="color-primary"
                            category="btn5"
                            underlineColor={"color-primary"}
                            underlineDistance={"space-xx-small"}
                            suppressHighlighting
                            onPress={() => setShowFullPolicy(true)}>
                            See full policy
                        </DottedUnderlineText>
                    </Box>
                ) : null}
            </Stack>
        </Card>
    );
};

export const CancellationPolicy = React.memo(CancellationPolicyComponent);
