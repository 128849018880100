import React from "react";

import { Stack, Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Text, SpacingValue, TextProps } from "@swiggy-private/rn-dls";

import { TrackingMeta } from "@minis-consumer/hooks/use-order-tracking";
import { useLinking } from "@minis-consumer/hooks/use-linking";

import { OrderStatus } from "@minis-consumer/interfaces/order";

import { getStatusProgress } from "@minis-consumer/routes/account/routes/my-account/helper";
import { StatusProgress } from "@minis-consumer/routes/account/routes/my-account/components/status-progress";

interface Props {
    status: OrderStatus;
    header?: TrackingMeta["header"];
    body?: TrackingMeta["body"];
}

export const OrderStatusDescription: React.FC<Props> = ({ status, header, body }) => {
    const openUrl = useLinking();

    const statusProgress = React.useMemo(() => getStatusProgress(status), [status]);

    const descriptionCategory: TextProps["category"] = useSelectScreen({
        lg: "s3",
        default: "b2",
    });

    return (
        <Stack>
            <Stack spacing={SpacingValue["space-x-small"]}>
                {header?.title ? (
                    <Text category="h4" color="color-basic-75" weight="medium">
                        {header?.title}
                    </Text>
                ) : null}
                {header?.subTitle ? (
                    <Text category="b2" color="color-secondary-400">
                        {header?.subTitle}
                    </Text>
                ) : null}
            </Stack>

            <Box
                mv={
                    status === "DELIVERED"
                        ? SpacingValue["space-x-small"]
                        : SpacingValue["space-medium"]
                }>
                {status !== "DELIVERED" ? (
                    <StatusProgress
                        fillWidth={statusProgress?.fillWidth || 0}
                        type={statusProgress?.type || "success"}
                    />
                ) : null}
            </Box>

            {body ? (
                <Stack>
                    <Text category="b2" weight="medium" color="color-basic-60">
                        {body.description}
                    </Text>

                    {body.trackUrl ? (
                        <Text
                            suppressHighlighting
                            category={descriptionCategory}
                            color="color-highlight-400"
                            onPress={() => openUrl(body.trackUrl || "")}>
                            {" "}
                            {body.trackUrl}
                        </Text>
                    ) : null}

                    {body.awb ? (
                        <Text
                            category={descriptionCategory}
                            color="color-basic-60"
                            weight="medium"
                            selectable>
                            {body.awb}
                        </Text>
                    ) : null}

                    {body.mobile ? (
                        <Stack direction={"row"}>
                            <Text
                                suppressHighlighting
                                category={descriptionCategory}
                                color="color-highlight-400"
                                onPress={() => openUrl(`tel:${body.mobile}`)}>
                                {body.mobile}
                            </Text>
                            <Text category={descriptionCategory} color="color-basic-60" selectable>
                                {`${body.agentName ? ` (${body.agentName})` : ""}`}
                            </Text>
                        </Stack>
                    ) : null}
                </Stack>
            ) : null}
        </Stack>
    );
};
