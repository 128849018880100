import React from "react";

import { Text } from "@swiggy-private/rn-dls";

interface Props {
    label: string;
    address: string;
}

const AddressTextComponent: React.FC<Props> = ({ label, address }) => {
    return (
        <Text numberOfLines={1}>
            <Text category="b2" color="high" weight="medium">
                {label}
            </Text>
            {address ? (
                <Text category="b2" color="low" weight="medium">
                    {` | ${address}`}
                </Text>
            ) : (
                ""
            )}
        </Text>
    );
};

export const AddressText = React.memo(AddressTextComponent);
