export * from "./payment-methods";
export * from "./upi-apps";

/** Logo Map */
export * from "./bank-logo-map";
export * from "./card-logo-map";

/** Max payment time (in seconds) */
export const MAX_UPI_PAYMENT_TIME = 5 * 60;

export const SAVE_CARD = "Save card as per ";
export const RBI_GUIDELINE = "RBI guidelines.";

export const MORE_PAYMENT_METHOD = "More Payment Methods";

export const PAYMENT_FOOTER_SECTION = {
    view_detailed_bill: "View Detailed Bill",
    hide_detailed_bill: "Hide Detailed Bill",
    pay_now: "Pay Now",
};

export const NEW_UPI_METHOD = {
    ENTER_UPI_ID: "Enter your UPI ID",
    VPA_ERROR: "Please enter valid UPI ID",
    UPI_SAVED_SECURELY: "UPI ID will be securely saved for future use",
};

export const PAYMENT = "Payment";

export const NETBANKING_UNAVAILABLE = "Netbanking unavailable for this bank";

export const analyticsAccordionMapping = {
    UPI: "new-payment-options-card-upi-accordion",
    CARD: "new-payment-options-card-debit-credit-card-accordion",
    NETBANKING: "new-payment-options-card-net-banking-accordion",
};

export const TEST_IDS = {
    pay_now: "pay_now",
    accordion_content: "accordion_content",
    accordion_title: "accordion_title",
    cart_item_image: "cart_item_image",
    cart_section: "cart_section",
    item_nudge_count: "item_nudge_count",
};

export const PAYMENT_WARNING_DELAY = 1_500;
export const PAYMENT_WARNING_TEXT =
    "You will be redirected to your bank’s website, please DO NOT refresh or press back while completing the transaction!";

export const PAYNOW_METHOD_ANALYTICS = {
    NEW_UPI: "new-upi-method",
    NEW_CARD: "new-card-method",
    NEW_NETBANKING: "new-netbanking-method",
    SAVED_CARD: "saved-card-method",
    SAVED_UPI: "saved-upi-method",
    SELECT_BANK: "select-netbanking-bank",
    SELECT_UPI_APP: "select-upi-app",
    BACK_BTN: "payment-back-btn",
};

export const FOOTER_PAYMENT_ANALYTICS = {
    SHOW_BILL: "show-detailed-bill",
    HIDE_BILL: "hide-detailed-bill",
    PAY_NOW: "pay-now-btn",
};

export const UPI_APPS_PRIORITY = ["googlepay_gpay", "phonepe", "paytm"];

export const ORDER_SUCCESS_COPY = {
    YAAY_ORDER_PLACED: "Yaay!\nOrder successfully\nplaced!",
    CONFIRM_IN_24HR: "will confirm your\nMinis order within 24 hours",
    DELIVERY_IN_DAYS: "will deliver the order in ",
};
