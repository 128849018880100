import { useCallback } from "react";
import { NavigationProp, useNavigation } from "@react-navigation/core";

import { MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { MinisNavigatorId, StoreNavigatorId } from "@minis-consumer/constants/navigation";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

export const useNavigateToCart = (): (() => void) => {
    const navigation =
        useNavigation<NativeStackNavigationProp<RouteList | MinisRouteList, "Payment", string>>();

    const storeNavigation = navigation.getParent(StoreNavigatorId) as NavigationProp<
        RouteList,
        "Payment"
    > | null;

    const minisNavigation = navigation.getParent(MinisNavigatorId) as NavigationProp<
        MinisRouteList,
        "Payment"
    > | null;

    return useCallback(() => {
        if (storeNavigation != null) {
            storeNavigation.reset({
                index: 0,
                routes: [
                    {
                        name: "Home",
                        state: {
                            key: "home",
                            index: 0,
                            routes: [
                                {
                                    name: "Cart",
                                    key: "cart",
                                },
                            ],
                        },
                    },
                ],
            });

            return;
        }

        if (minisNavigation != null) {
            minisNavigation.reset({
                index: 0,
                routes: [
                    {
                        name: "MinisHome",
                    },
                ],
            });
        }
    }, [minisNavigation, storeNavigation]);
};
