import React from "react";

import { TooltipPlacement, Text, SpacingValue } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { Cart, CartBill } from "@minis-consumer/interfaces/cart";
import { BillItem } from "@minis-consumer/components/bill-item";

import { DeliveryFeeTooltip } from "./component/tooltip";

interface Props {
    deliveryFee: CartBill["deliveryFee"];
    cartStatus: Cart["cartStatus"];
}

const DeliveryFeeComponent: React.FC<Props> = ({ deliveryFee, cartStatus }) => {
    return (
        <>
            <BillItem
                title={"Delivery Fee"}
                amount={deliveryFee?.value ?? undefined}
                finalAmount={deliveryFee?.finalValue ?? 0}
                helper={
                    !deliveryFee && cartStatus === "USER_ADDRESS_NOT_SELECTED"
                        ? "Please select an address to see delivery fee"
                        : ""
                }
                tooltip={deliveryFee ? <DeliveryFeeTooltip deliveryFee={deliveryFee} /> : null}
                tooltipPlacement={TooltipPlacement.RIGHT}
                isDeliveryFeeMissing={deliveryFee ? false : true}
            />

            {deliveryFee?.type === "PER_KM_DELIVERY_TYPE" ? (
                <Box mt={SpacingValue["space-xx-small"]}>
                    <Text category="b3" weight="medium" color="medium">
                        For {deliveryFee.distanceInKm} km(s) total distance
                    </Text>
                </Box>
            ) : null}
        </>
    );
};

export const DeliveryFee = React.memo(DeliveryFeeComponent);
