import React from "react";
import { StyleSheet } from "react-native";
import { shadow, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { CountdownTimer, CountdownTimerProps } from "@swiggy-private/countdown-timer";

import { convertSecondsToMinutesAndSeconds } from "@minis-consumer/helpers/time";

interface CountdownProps {
    duration: number;
}

const CountdownComponent: React.FC<CountdownProps> = ({ duration }) => {
    const { value: theme } = useTheme();
    const shadowStyles = shadow(2);

    return (
        <Box alignItems="center">
            <Box
                style={[styles.timerContainer, styles.timerBorderRadius]}
                ph={SpacingValue["space-large"]}
                pv={SpacingValue["space-large"]}
                justifyContent="center"
                alignItems="center">
                <Box
                    ph={SpacingValue["space-small"]}
                    pv={SpacingValue["space-small"]}
                    style={[
                        styles.timerBorderRadius,
                        shadowStyles,
                        {
                            backgroundColor: theme["color-background-primary"],
                            shadowOffset: { height: 0, width: 0 },
                        },
                    ]}>
                    <CountdownTimer
                        duration={duration}
                        size={132}
                        colors={["#1BA672", "#FFB608", "#E53554", "#FFFFFF"]}
                        rotation="counterclockwise"
                        trailColor={"#FFFFFF"}
                        strokeWidth={8}
                        colorsTime={[duration, duration / 2, duration / 4, 0]}
                        isSmoothColorTransition={false}>
                        {TimerChild}
                    </CountdownTimer>
                </Box>
            </Box>
        </Box>
    );
};

const TimerChild: CountdownTimerProps["children"] = (props) => {
    const displayTimer = convertSecondsToMinutesAndSeconds(props.remainingTime);
    const showSeconds = props.remainingTime < 60;

    return (
        <Stack spacing={SpacingValue["space-xxx-small"]} alignItems="center">
            <Text category="h2" color="high">
                {showSeconds ? props.remainingTime : displayTimer}
            </Text>
            <Text category="b3" weight="medium" color="low">
                {`${showSeconds ? "secs" : "mins"} remaining`}
            </Text>
        </Stack>
    );
};

export const Countdown = React.memo(CountdownComponent);

const styles = StyleSheet.create({
    timerContainer: {
        backgroundColor: "rgba(240, 240, 245, 0.25)",
    },
    timerBorderRadius: {
        borderRadius: 100,
    },
    timerBar: {
        height: "100%",
        borderRadius: 4,
    },
});
