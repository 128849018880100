import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { useChatStoreUnreadCount } from "@minis-consumer/hooks/use-chat-unread-count";
import { Badge } from "@minis-consumer/components/badge";

import { KebabMenu } from "../../common/kebab-menu";

interface Props {
    storeId: string;
    onChatPress: () => void;
    emptyCart: boolean;
}

const HeaderRightComponent: React.FC<Props> = ({ storeId, onChatPress, emptyCart }) => {
    const chatUnreadCount = useChatStoreUnreadCount(storeId) ?? 0;

    if (emptyCart) {
        return null;
    }

    return (
        <Box
            flex={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            mr={SpacingValue["space-medium"]}>
            <Box
                style={styles.chatContainer}
                direction="row"
                justifyContent="center"
                alignItems="center"
                mr={SpacingValue["space-small"]}>
                <SvgIcon
                    icon="Chat"
                    height={16}
                    width={16}
                    color="color-basic-60"
                    onPress={onChatPress}
                    hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                />
                <Badge count={chatUnreadCount} size={18} textStyle={styles.badgeText} />
            </Box>
            <KebabMenu storeId={storeId} />
        </Box>
    );
};

export const HeaderRight = React.memo(HeaderRightComponent);

const styles = StyleSheet.create({
    chatContainer: {
        height: 36,
        width: 36,
        borderRadius: 18,
    },
    badgeText: {
        fontSize: 8,
        height: 10,
        lineHeight: 10,
    },
});
