import React from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { Card } from "@minis-consumer/components/card";
import { PaymentDowntimeNudge } from "@minis-consumer/components/payment-downtime-nudge";
import { useCartView } from "@minis-consumer/hooks/use-cart";
import { Cart } from "@minis-consumer/interfaces/cart";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { META_PIXEL_EVENT_NAMES } from "@minis-consumer/constants/meta-pixel-analytics";

import { hidePayment } from "../../helpers";

interface Props {
    cartStatus: Cart["cartStatus"];
    storeId: string;
    onClickProceed: VoidFunction;
    disabled?: boolean;
}

const PaymentComponent: React.FC<Props> = ({ cartStatus, storeId, disabled, onClickProceed }) => {
    const { value: theme } = useTheme();

    const cart = useCartView(storeId);

    const iconContainerStyles = React.useMemo<ViewStyle>(
        () => ({
            borderColor: theme["color-primary"],
            backgroundColor: disabled ? theme["color-background-primary"] : theme["color-primary"],
        }),
        [disabled, theme],
    );

    const isPaymentDown = !cart?.payments?.active;

    const ctaTextColor = React.useMemo(
        () => (disabled ? "color-basic-45" : "color-basic-0"),
        [disabled],
    );

    const cartErrorStatus = React.useMemo(
        () => hidePayment(cart?.cartViewData?.cartItems, cartStatus),
        [cart?.cartViewData?.cartItems, cartStatus],
    );

    const onPressCTA = React.useCallback(() => {
        Analytics.clickEvent({
            category: "proceed-to-pay-btn",
            label: cart?.cartViewData?.id,
            context: "make-payment",
        });

        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.INITIATE_CHECKOUT, {
            value: String(cart?.cartViewData?.cartItemsCount),
        });

        onClickProceed();
    }, [onClickProceed, cart?.cartViewData]);

    useMount(() => {
        if (!cartErrorStatus) {
            Analytics.impressionEvent({
                category: "proceed-to-pay-btn",
                label: cart?.cartViewData?.id,
                context: "make-payment",
            });
        }
    });

    if (cartErrorStatus) {
        return null;
    }

    return (
        <Card>
            <PaymentDowntimeNudge
                showNudge={isPaymentDown}
                message={cart?.payments?.message}
                style={styles.downtime}
            />
            <Stack spacing={SpacingValue["space-medium"]} direction="row" alignItems="center">
                <Box style={[styles.iconContainer, iconContainerStyles]}>
                    <SvgIcon
                        icon="Payment"
                        height={24}
                        width={24}
                        color={disabled ? "color-primary" : "color-basic-0"}
                    />
                </Box>

                <Stack spacing={SpacingValue["space-xxx-small"]} flex={1}>
                    <Text category="b1" weight="bold" color="high">
                        Make payment
                    </Text>

                    <Text category="b2" color="medium">
                        Accepts all payment methods
                    </Text>
                </Stack>

                <Button
                    disabled={disabled || isPaymentDown}
                    color={disabled ? "tertiary" : "primary"}
                    onPress={onPressCTA}>
                    <Box ph={SpacingValue["space-x-large"]}>
                        <Text category="b2" color={ctaTextColor} weight="bold">
                            Proceed to payment
                        </Text>
                    </Box>
                </Button>
            </Stack>
        </Card>
    );
};

export const Payment = React.memo(PaymentComponent);

const styles = StyleSheet.create({
    iconContainer: {
        height: 48,
        width: 48,
        borderRadius: 12,
        borderWidth: 1,
        alignItems: "center",
        justifyContent: "center",
        padding: SpacingValue["space-small"],
    },
    downtime: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        marginTop: -SpacingValue["space-x-large"],
        marginHorizontal: -SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-large"],
    },
});
