import React from "react";
import { ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { BottomSheet } from "@swiggy-private/react-native-ui";
import { DialogContent, SpacingValue } from "@swiggy-private/rn-dls";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { DialogBody } from "./components/dialog-body";

type RbiTncDialog = {
    canShowDialog: boolean;
    onCloseDialog: VoidFunction;
};

const RbiTncDialogComponent: React.FC<RbiTncDialog> = ({ canShowDialog, onCloseDialog }) => {
    const insets = useSafeAreaInsets();

    const modalMaxWidth = useSelectScreen({
        lg: 420 as ViewStyle["width"],
        default: "100%",
    });
    const modalPaddingBottomStyle = React.useMemo(
        () => Math.max(insets.bottom + SpacingValue["space-xx-small"], SpacingValue["space-large"]),
        [insets],
    );

    const modalStyles = useSelectScreen({
        default: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            paddingBottom: modalPaddingBottomStyle,
        },

        lg: {
            borderRadius: 16,
            padding: SpacingValue["space-x-large"],
        },
    });

    if (!canShowDialog) {
        return null;
    }

    return (
        <BottomSheet
            disableBackdropClose={false}
            open={canShowDialog}
            onClose={onCloseDialog}
            style={[modalStyles, { width: modalMaxWidth }]}>
            <DialogContent>
                <DialogBody onPressOkay={onCloseDialog} />
            </DialogContent>
        </BottomSheet>
    );
};

export const RbiTncDialog = React.memo(RbiTncDialogComponent);
