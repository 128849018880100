import React from "react";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { usePaymentAmount } from "@minis-consumer/routes/payment/hooks/use-payment-amount";

import { BackCTA } from "./back-cta";
import { EditCTA } from "./edit-cta";

interface HeaderDesktopComponentProps {
    showEditCta?: boolean;
}

const HeaderDesktopComponent: React.FC<HeaderDesktopComponentProps> = ({ showEditCta = true }) => {
    const paymentAmount = usePaymentAmount();

    return (
        <Stack direction="row" spacing={SpacingValue["space-medium"]} alignItems="center">
            <BackCTA />

            <Box flex={1}>
                <Text color="high" category="b1" weight="bold">
                    To pay: {paymentAmount}
                </Text>
            </Box>

            {showEditCta ? (
                <Box flex={1}>
                    <EditCTA />
                </Box>
            ) : null}
        </Stack>
    );
};

export const HeaderDesktop = React.memo(HeaderDesktopComponent);
