import { SF_ANALYTICS_EVENT_NAMES } from "../../constants";

export type CtaType = "search" | "chat" | "share-store" | "account";

export const COPIES = {
    TOOLTIP_TITLE: "Need help choosing a product?",
    TOOLTIP_DESCRIPTION: "Send us a message!",
};

export const DEFAULT_VALUE = {
    TOOLTIP_IMAGE_SIZE: 40,
};

export const CTA_ANALYTICS_EVENT_MAP: Record<string, string> = {
    search: SF_ANALYTICS_EVENT_NAMES.SEARCH_CTA,
    chat: SF_ANALYTICS_EVENT_NAMES.CHAT_CTA,
    account: SF_ANALYTICS_EVENT_NAMES.ACCOUNT_CTA,
};
