import React, { useContext, useEffect, useMemo } from "react";
import { StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { Shimmer } from "@minis-consumer/components/shimmer";
import { useGetDeliveryCharges, useGetDeliveryInfoEta } from "@minis-consumer/hooks/use-shop";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { IDeliveryInfo } from "@minis-consumer/interfaces/delivery";
import { Analytics } from "@minis-consumer/analytics";
import { SelectedAddressInfoContext } from "@minis-consumer/contexts/selected-address-context";

import { API_FETCHING_ERROR, ANALYTICS_COPIES } from "./constants";

type DeliveryInfoDetailsProps = {
    fontCategory?: "b1" | "b2";
    loading?: boolean;
    deliveryInfo?: IDeliveryInfo;
    dropPincodeIsAvailable?: boolean;
};

export const DeliveryInfoDetails: React.FC<DeliveryInfoDetailsProps> = ({
    fontCategory = "b2",
    loading,
    deliveryInfo,
    dropPincodeIsAvailable,
}) => {
    const { name: storeName, storeId } = useStoreInfo();
    const deliveryChargesText = useGetDeliveryCharges(dropPincodeIsAvailable, deliveryInfo);
    const deliveryEtaText = useGetDeliveryInfoEta(dropPincodeIsAvailable, deliveryInfo);
    const selectedAddressCtx = useContext(SelectedAddressInfoContext);
    const deliveryCharges = useMemo(() => {
        if (!deliveryChargesText?.text) {
            return null;
        }

        const splitText = deliveryChargesText?.text.split("₹");

        return {
            text: splitText.length > 1 ? splitText[0] : deliveryChargesText?.text,
            highlightedText: splitText.length > 1 ? "₹" + splitText[1] : "",
        };
    }, [deliveryChargesText?.text]);

    const analyticsData = useMemo(() => {
        return {
            category: ANALYTICS_COPIES.CLICK_ADDRESS,
            context: JSON.stringify({
                address_label: selectedAddressCtx?.selectedAddressInfo?.addressLabel,
                pincode: selectedAddressCtx?.selectedAddressInfo?.postalCode,
                del_eta: deliveryEtaText?.text,
                store_id: storeId,
                del_charge_msg: deliveryChargesText?.text,
                del_time: deliveryInfo?.deliveryEta ? 1 : 0,
            }),
        };
    }, [
        deliveryChargesText?.text,
        deliveryEtaText?.text,
        deliveryInfo?.deliveryEta,
        selectedAddressCtx?.selectedAddressInfo?.addressLabel,
        selectedAddressCtx?.selectedAddressInfo?.postalCode,
        storeId,
    ]);

    useEffect(() => {
        if (!loading && selectedAddressCtx?.selectedAddressInfo?.postalCode) {
            Analytics.impressionEvent(analyticsData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Stack
            spacing={{
                default: SpacingValue["space-xx-small"],
                lg: SpacingValue["space-x-small"],
            }}
            style={styles.container}>
            {loading ? (
                <Stack style={styles.loadingWrapper}>
                    <Shimmer
                        style={[styles.headerSlate, styles.shimmer]}
                        width="100%"
                        height={14}
                    />
                    <Shimmer style={[styles.headerSlate, styles.shimmer]} width="60%" height={14} />
                </Stack>
            ) : (
                <Stack>
                    {!deliveryInfo?.error ? (
                        <Stack style={styles.deliveryEtaDetails}>
                            <Stack>
                                {deliveryEtaText ? (
                                    deliveryInfo?.deliveryEta?.max ? (
                                        <Text
                                            category={fontCategory}
                                            color={deliveryEtaText.color}
                                            style={styles.deliveryInfo}>
                                            {storeName} delivers in{" "}
                                            <Text
                                                category={fontCategory}
                                                color="color-basic-60"
                                                weight="medium">
                                                {deliveryEtaText.text}
                                            </Text>
                                        </Text>
                                    ) : (
                                        <Text
                                            category={fontCategory}
                                            color={deliveryEtaText.color}
                                            style={styles.deliveryInfo}>
                                            {deliveryEtaText.text}
                                        </Text>
                                    )
                                ) : null}
                                {deliveryChargesText?.text ? (
                                    <Text
                                        category={fontCategory}
                                        color={deliveryChargesText?.color}>
                                        {deliveryCharges?.text ?? ""}

                                        <Text
                                            category={fontCategory}
                                            color="color-basic-60"
                                            weight="medium">
                                            {deliveryCharges?.highlightedText}
                                        </Text>
                                    </Text>
                                ) : null}
                            </Stack>
                        </Stack>
                    ) : (
                        <Text>{API_FETCHING_ERROR}</Text>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: {
        width: "100%",
    },
    headerSlate: {
        borderRadius: 4,
    },
    loadingWrapper: {
        marginTop: SpacingValue["space-xx-small"],
    },
    shimmer: {
        marginTop: SpacingValue["space-xxx-small"],
    },
    deliveryInfo: {
        marginBottom: SpacingValue["space-x-small"],
    },
    deliveryEtaDetails: {
        marginBottom: SpacingValue["space-xx-small"],
    },
});
