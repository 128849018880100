export const DEFAULT_CALLOUT_HEIGHT = 84;
export const CALLOUT_IMAGE_ID = "minis/parachute_delivery";
export const MANAGE_DELIVERY_TEXT =
    "will manage delivery and send tracking updates for the selected address";
export const MODAL_BODY_COPIES = {
    unserviceable: "Unserviceable",
    chooseAddress: "Choose a delivery address",
    addNewAddress: "Add New Address",
};

export const ANALYTICS_COPIES = {
    CHOOSE_ADDRESS_CARD: "choose-address-card",
    ADD_ADDRESS_BTN: "add-address-btn",
};
