import React from "react";

import { Text } from "@swiggy-private/rn-dls";

const SubtitleComponent: React.FC<{ subtitle: string }> = ({ subtitle }) => {
    return (
        <Text category="b2" color="medium" weight="regular">
            {subtitle}
        </Text>
    );
};

export const Subtitle = React.memo(SubtitleComponent);
