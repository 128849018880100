export const DEFAULTS = {
    DESKTOP_IMG_SIZE: 84,
    COUNTER_WIDTH: 96,
    GRADIENT_COLORS: ["rgba(250, 235, 233, 0))", "#FAEBE9"],
    TRANSPARENT_GRADIENT_COLORS: ["rgba(250, 235, 233, 0))", "rgba(250, 235, 233, 0))"],
    GRADIENT_LOCATIONS: [0.3, 1],
    GRADIENT_START_POINT: { x: 0, y: 0.2 },
    GRADIENT_END_POINT: { x: 0.5, y: 0.3 },
    MAX_DESKTOP_PRODUCT_TITLE_LENGTH: "50%",
    PRODUCT_IMAGE_ANALYTICS_CATEGORY: "cart-product-image",
    TRASH_ICON_ANALYTICS_CATEGORY: "trash-icon",
    DESKTOP_PRICE_CONTAINER_WIDTH: 75,
    UNAVAILABLE_COUNTER_WIDTH: 125,
};
