export const TOOLTIP_DEFAULT_VALUE = {
    KEY: "chat-icon-tooltip",
    VALUE: false,
    START_TIME_IN_MS: 2000,
    DISMISS_AFTER_IN_MS: 8000,
    LIFETIME_DISPLAY_COUNT: 3,
    SESSION_DISPLAY_COUNT: 1,
};

export const IMAGE_CONTAINER_CLASS = "imageContainer";

export const ARD_EVENTS = {
    DETAILS: "product-details",
    RECOMMENDED_PRODUCT_CARD: "recommended-products-card",
    RECOMMENDED_PRODUCTS: "recommended-products",
    HOW_IT_WORKS: "how-it-works",
};

export const TEST_IDS = {
    ACCESSIBLITY_ICON: "accessibilityIcon",
    ACCESSIBLITY_ICON_CHILDREN: "accessibilityIconChildren",
};

export const BREAKDOWN_TITLE: Record<string, string> = {
    PLAN: "Plan breakdown",
    APPOINTMENT: "Meeting breakdown",
    DEFAULT: "Breakdown",
};
