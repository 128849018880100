import { useFocusEffect } from "@react-navigation/native";
import { useController } from "@rest-hooks/react";
import React, { useCallback } from "react";
import { StyleSheet, useWindowDimensions } from "react-native";

import { useMount } from "@swiggy-private/react-hooks";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Surface } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { Divider } from "@minis-consumer/components/divider";
import { ShopLogo } from "@minis-consumer/components/shop-logo";
import { getStoreBrandImageUrl } from "@minis-consumer/helpers/store";
import { useFetchAddressListFromServer } from "@minis-consumer/hooks/use-addresses";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useFollowUnfollowStore } from "@minis-consumer/hooks/use-store-follow";
import { Logger } from "@minis-consumer/includes/logger";
import { AddressListResource } from "@minis-consumer/resources/address";

import { DeliveryInfoActions } from "../delivery-info-actions";
import { ShopActions } from "../shop-actions";
import { ShopHeader } from "../shop-header";

type IProps = { setDeliveryInfoOffset?: (offset: number) => void };

export const ShopDetails: React.FC<IProps> = ({ setDeliveryInfoOffset }) => {
    const storeInfo = useStoreInfo();

    const isStoreEnabled = storeInfo.enabled !== false;

    const { invalidate } = useController();
    const fetchAddresses = useFetchAddressListFromServer(storeInfo?.storeId);

    useFocusEffect(
        useCallback(() => {
            invalidate(AddressListResource, {
                storeId: storeInfo.storeId,
            }).catch((err) => {
                Logger.recordError(err);
            });

            fetchAddresses();
        }, [fetchAddresses, invalidate, storeInfo.storeId]),
    );

    const { width: windowWidth } = useWindowDimensions();

    const gutter = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        md: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const logoSize = useSelectScreen({
        lg: 80,
        md: 80,
        default: windowWidth * 0.18 || 70,
    });

    const contentStyle = useSelectScreen({
        lg: {
            marginTop: isStoreEnabled ? SpacingValue["space-medium"] : 0,
        },
        default: {
            marginTop: isStoreEnabled ? SpacingValue["space-x-small"] : 0,
        },
    });

    const contentAdditionalStyles = useSelectScreen({
        lg: {
            borderRadius: 0,
        },
        md: {
            borderRadius: 0,
        },
        default: {
            borderRadius: 20,
        },
    });

    const containerStyle = {
        paddingHorizontal: gutter,
        paddingBottom: gutter,
    };

    const logoStyle = {
        top: -logoSize / 2,
        left: gutter,
    };

    const { canFollow, following: followingStore } = useFollowUnfollowStore();

    const showShopActions = React.useMemo(() => {
        const storeInactiveFollow = !isStoreEnabled && followingStore;

        return canFollow && (isStoreEnabled || storeInactiveFollow);
    }, [canFollow, followingStore, isStoreEnabled]);

    const imgUrl = React.useMemo(
        () => (storeInfo ? getStoreBrandImageUrl(storeInfo, { height: logoSize }, true) : null),
        [storeInfo, logoSize],
    );

    useMount(() => {
        Analytics.impressionEvent({ category: "store-details-card" });
        fetchAddresses();
    });

    return (
        <Surface style={[styles.container, containerStyle, contentAdditionalStyles]}>
            {isStoreEnabled ? (
                <ShopLogo
                    isImageKitEnabled
                    size={logoSize}
                    url={imgUrl}
                    style={[styles.logo, logoStyle]}
                />
            ) : null}
            {showShopActions ? (
                <ShopActions isStoreEnabled={isStoreEnabled} />
            ) : (
                <Box style={styles.actionsPlaceholder} />
            )}
            <Stack style={contentStyle}>
                <ShopHeader />
                <Divider style={styles.divider} />

                {isStoreEnabled ? (
                    <DeliveryInfoActions setDeliveryInfoOffset={setDeliveryInfoOffset} />
                ) : null}
            </Stack>
        </Surface>
    );
};

const styles = StyleSheet.create({
    container: {
        elevation: 0,
        zIndex: 1,
    },
    logo: {
        position: "absolute",
        zIndex: 1,
    },
    divider: {
        marginVertical: SpacingValue["space-medium"],
    },
    online: {
        width: 12,
        height: 12,
        marginLeft: SpacingValue["space-x-small"],
    },
    actionsPlaceholder: {
        height: 48,
    },
});
