import React, { useMemo } from "react";

import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import {
    CardPaymentMethod,
    PaymentMethod,
    UpiPaymentMethod,
} from "@minis-consumer/interfaces/payment";
import { PAYMENT_GROUP_CATEGORY } from "@minis-consumer/routes/payment/constants";
import { usePaymentDispatch } from "@minis-consumer/routes/payment/hooks/use-dispatch";
import { usePaymentOrder } from "@minis-consumer/routes/payment/hooks/use-payment-order";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import { SavedCard } from "./card";
import { SavedUpi } from "./upi";

interface Props {
    paymentMethod: PaymentMethod;
    index: number;
    hasError?: boolean;
    errorMessage?: string;
}

const SavedMethodComponent: React.FC<Props> = ({ paymentMethod, index, ...props }) => {
    const storeInfo = useStoreInfo();
    const cart = useCartViewData(storeInfo?.storeId);
    const order = usePaymentOrder();
    const paymentDispatch = usePaymentDispatch();

    const methodType = paymentMethod?.paymentMethodType;

    const analyticsContext = useMemo(() => {
        return JSON.stringify({
            orderId: order?.id || "-",
            cartId: cart?.id || "-",
            productType: cart?.cartType || order?.orderType,
            paymentAmount: cart?.bill?.finalBillTotal || order?.bill?.finalBillTotal,
            transactionId: order?.txnId,
            paymentMethod: methodType.toLowerCase(),
        });
    }, [cart, order, methodType]);

    useMount(() => {
        Analytics.impressionEvent({
            category: "saved-payment-options-card-pay-" + methodType?.toLowerCase(),
            label: order?.id,
            context: analyticsContext,
            value: index,
        });
    });

    const onPress = React.useCallback(
        (method?: "CARD" | "UPI", value?: string) => {
            Analytics.clickEvent({
                category: "saved-payment-options-card-pay-" + method?.toLowerCase(),
                label: order?.id,
                context: analyticsContext,
                value: index,
            });

            paymentDispatch({
                type: "UPDATE_SELECTED_PAYMENT_METHOD",
                payload: {
                    category: PAYMENT_GROUP_CATEGORY.SAVED,
                    type: method,
                    value: value,
                },
            });
        },
        [order?.id, analyticsContext, index, paymentDispatch],
    );

    if (methodType === "UPI") {
        return <SavedUpi {...(paymentMethod as UpiPaymentMethod)} onPress={onPress} {...props} />;
    }

    if (methodType === "CARD") {
        return <SavedCard {...(paymentMethod as CardPaymentMethod)} onPress={onPress} {...props} />;
    }

    return null;
};

export const SavedMethod = React.memo(SavedMethodComponent);
