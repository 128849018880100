import React from "react";
import { Pressable, PressableProps, StyleProp, StyleSheet, ViewStyle } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

export type IconContainerProps = Omit<PressableProps, "style"> & {
    style?: StyleProp<ViewStyle>;
};

export const IconContainer: React.FC<React.PropsWithChildren<IconContainerProps>> = (props) => {
    const {
        accessible = true,
        accessibilityRole = "button",
        style,
        children,
        ...restProps
    } = props;

    return (
        <Pressable
            accessible={accessible}
            accessibilityRole={accessibilityRole}
            style={{ marginLeft: SpacingValue["space-x-small"] }}
            {...restProps}>
            <Box style={[styles.iconContainer, style]}>{children}</Box>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    iconContainer: {
        width: 36,
        height: 36,
        justifyContent: "center",
        alignItems: "center",
    },
    toolTip: {
        width: 250,
    },
    badgeCount: {
        top: -3 * SpacingValue["space-xxx-small"],
        right: -SpacingValue["space-xxx-small"],
    },
});
