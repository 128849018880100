import { useFocusEffect } from "@react-navigation/native";
import { useController, useSuspense } from "@rest-hooks/react";
import React, { useCallback } from "react";
import { StyleSheet } from "react-native";

import { useMount } from "@swiggy-private/react-hooks";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Surface, useTheme } from "@swiggy-private/rn-dls";
import { StoryProps } from "@swiggy-private/react-native-stories";

import { Analytics } from "@minis-consumer/analytics";
import { useFetchAddressListFromServer } from "@minis-consumer/hooks/use-addresses";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Logger } from "@minis-consumer/includes/logger";
import { AddressListResource } from "@minis-consumer/resources/address";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { InstagramWidgetLoader } from "@minis-consumer/routes/shop/components/instagram-scroll-widget/instagram-loader";
import { StoreStories } from "@minis-consumer/components/store-stories";
import { CatalogView } from "@minis-consumer/resources/catalog";
import { useGetConfigInfo } from "@minis-consumer/hooks/use-get-config-info";

import { ShopHeader } from "../shop-header";

type IProps = { setDeliveryInfoOffset?: (offset: number) => void; storyId?: string };

export const ShopDetails: React.FC<IProps> = ({ setDeliveryInfoOffset, storyId }) => {
    const storeInfo = useStoreInfo();
    const { value: theme } = useTheme();

    const isStoreEnabled = storeInfo.enabled !== false;

    const { invalidate } = useController();
    const fetchAddresses = useFetchAddressListFromServer(storeInfo?.storeId);
    const isNewServicesEnabled = useGetConfigInfo()?.isNewServicesEnabled;

    useFocusEffect(
        useCallback(() => {
            if (!storeInfo.address) {
                return;
            }

            invalidate(AddressListResource, {
                storeId: storeInfo.storeId,
            }).catch((err) => {
                Logger.recordError(err);
            });

            fetchAddresses();
        }, [fetchAddresses, invalidate, storeInfo]),
    );

    const gutter = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        md: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const contentStyle = useSelectScreen({
        lg: {
            marginTop: isStoreEnabled ? SpacingValue["space-medium"] : 0,
        },
        default: {
            marginTop: isStoreEnabled ? SpacingValue["space-x-small"] : 0,
        },
    });

    const contentAdditionalStyles = useSelectScreen({
        lg: {
            borderRadius: 0,
        },
        md: {
            borderRadius: 0,
        },
        default: {
            borderRadius: 20,
        },
    });

    const containerStyle = {
        paddingHorizontal: gutter,
        paddingBottom: gutter,
    };

    useMount(() => {
        Analytics.impressionEvent({ category: "store-details-card" });
        if (!storeInfo.address) {
            return;
        }
        fetchAddresses();
    });

    const isDesktop = useIsDesktop();

    const storiesProps: Partial<StoryProps> = React.useMemo(() => {
        return {
            avatarSize: isNewServicesEnabled ? 60 : undefined,
            unPressedBorderColor: isNewServicesEnabled ? theme["color-basic-30"] : undefined,
            avatarWrapperStyle: isNewServicesEnabled ? styles.avatarWrapperStyle : undefined,
        };
    }, [isNewServicesEnabled, theme]);

    return (
        <>
            <Surface style={[styles.container, containerStyle, contentAdditionalStyles]}>
                <Stack style={contentStyle}>
                    <ShopHeader setDeliveryInfoOffset={setDeliveryInfoOffset} />
                </Stack>
            </Surface>

            {storeInfo.highlights?.length && !isDesktop ? (
                <AsyncComponent fallback={InstagramWidgetLoader}>
                    <WithCatalog storeSlug={storeInfo.slug}>
                        <Box mb={SpacingValue["space-x-large"]}>
                            <StoreStories
                                storeId={storeInfo.storeId}
                                slug={storeInfo.slug}
                                highlights={storeInfo.highlights}
                                storyId={storyId}
                                storiesProps={storiesProps}
                            />
                        </Box>
                    </WithCatalog>
                </AsyncComponent>
            ) : null}
        </>
    );
};

const WithCatalog: React.FC<React.PropsWithChildren<{ storeSlug: string }>> = ({
    children,
    storeSlug,
}) => {
    useSuspense(CatalogView, { storeSlug });
    return <>{children}</>;
};

const styles = StyleSheet.create({
    container: {
        elevation: 0,
        zIndex: 1,
    },
    avatarWrapperStyle: {
        borderWidth: 1,
    },
});
