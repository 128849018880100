import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { ConfirmDialog, TextStyleProps } from "@minis-consumer/components/confirm-dialog";
import { useWidgetAnalytics } from "@minis-consumer/hooks/use-widget-analytics";
import { Analytics } from "@minis-consumer/analytics";

import { ANALYTICS_COPIES } from "../../constants";

interface Props {
    canShowModal?: boolean;
    title?: string;
    description?: string;
    continueBtnText?: string;
    cancelBtnText?: string;
    onCloseModal: VoidFunction;
    onPressProceed?: VoidFunction;
    isLoading?: boolean;
}

export const ClearCartConfirmationDialog: React.FC<Props> = ({
    canShowModal,
    onCloseModal,
    title,
    description,
    continueBtnText,
    cancelBtnText,
    onPressProceed,
    isLoading,
}) => {
    const analyticsHandler = useWidgetAnalytics();
    const { value: theme } = useTheme();

    const onContinueBtnPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.CLEAR_ALL_CART_CONFIRMATION,
            label: ANALYTICS_COPIES.YES_VALUE,
        });

        onPressProceed?.();
    }, [onPressProceed]);

    const styleOverrider = {
        elevation: 0,
        paddingHorizontal: SpacingValue["space-small"],
        paddingVertical: SpacingValue["space-small"],
    };

    const titleTextProps = {
        category: "h4",
    } as TextStyleProps;

    const overlayStyleOverrider = {
        backgroundColor: theme["color-basic-alpha-45"],
    };

    const onCancelBtnPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.CLEAR_ALL_CART_CONFIRMATION,
            label: ANALYTICS_COPIES.NO_VALUE,
        });

        onCloseModal?.();
    }, [onCloseModal]);

    const onMount = React.useCallback(() => {
        analyticsHandler.onMountAnalytics({
            category: ANALYTICS_COPIES.CLEAR_ALL_CART_CONFIRMATION,
        });
    }, [analyticsHandler]);

    return (
        <ConfirmDialog
            disableBackdropClose
            open={canShowModal}
            onClose={onCloseModal}
            onPressCancel={onCancelBtnPress}
            onPressContinue={onContinueBtnPress}
            title={title}
            description={description}
            continueBtnText={continueBtnText}
            cancelBtnText={cancelBtnText}
            loading={isLoading}
            titleTextProps={titleTextProps}
            btnCriticalExtraStyle={styleOverrider}
            btnTertiaryExtraStyle={styleOverrider}
            onMount={onMount}
            style={styles.root}
            overlayStyle={overlayStyleOverrider}
        />
    );
};

const styles = StyleSheet.create({
    root: {
        width: "80%",
    },
});
