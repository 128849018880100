import React from "react";
import { Pressable, StyleSheet, ViewStyle, Platform } from "react-native";

import { SpacingValue, useTheme, Text, useLayout } from "@swiggy-private/rn-dls";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { TextCategory } from "@swiggy-private/rn-dls/src/styles/interfaces/text";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { Coupon } from "@minis-consumer/interfaces/coupon";
import { getCouponDataForCard } from "@minis-consumer/helpers/coupon";
import { Analytics } from "@minis-consumer/analytics";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { Logger } from "@minis-consumer/includes/logger";
import { SEGMENTED_COUPON_BANNER } from "@minis-consumer/constants/images";

import { ClipboardService } from "./clipboard";

const { IMG_ID, HEIGHT, WIDTH } = SEGMENTED_COUPON_BANNER;

const CouponCardComponent: React.FC<{ coupon: Coupon; productId: string }> = ({
    coupon,
    productId,
}) => {
    const store = useStoreInfo();

    const cardData = React.useMemo(
        () => getCouponDataForCard({ coupon, addCodePrefix: true, city: store?.address?.city }),
        [coupon, store?.address?.city],
    );

    const [showToast] = useToast();
    const copyToClipboard = React.useCallback(() => {
        /**
         * Try catch added to avoid app crashes
         * in future when Clipboard is deprecated
         */
        try {
            ClipboardService.setString(coupon.code);

            showToast("Coupon code copied!");
        } catch (err) {
            Logger.error("Could not copy text", err);
        }
    }, [coupon.code, showToast]);

    const { value: theme } = useTheme();

    const [layout, onLayout] = useLayout();

    const _height = layout.measured ? layout.height : undefined;

    const cardContainer: ViewStyle = useSelectScreen({
        lg: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderColor: theme["color-basic-15"],
            borderStyle: "dashed",
            borderRightWidth: 0,
            flex: 1,
        },
        default: {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottomWidth: 0,
            borderColor: theme["color-basic-15"],
            flex: Platform.OS === "web" ? 1 : 0,
            height: _height ? _height - 34 : undefined,
        },
    });

    const copyCouponText = useSelectScreen({
        lg: "Copy",
        default: "Copy coupon code",
    });

    const textContainerFlex = useSelectScreen({
        lg: 1,
        default: undefined,
    });

    const couponTitleCategory: TextCategory = useSelectScreen({
        lg: "b2",
        default: "b1",
    });

    const couponDescriptionCategory: TextCategory = useSelectScreen({
        lg: "b2",
        default: "b3",
    });

    const copyCodeCommonStyle: ViewStyle = {
        backgroundColor: theme["color-positive-25"],
        borderColor: theme["color-positive-50"],
        borderWidth: 1,
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-x-small"],
        justifyContent: "center",
    };

    const copyCodeStyle: ViewStyle = useSelectScreen({
        lg: {
            borderBottomRightRadius: 12,
            borderTopRightRadius: 12,
            width: 60,
            ...copyCodeCommonStyle,
        },
        default: {
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            width: "100%",
            ...copyCodeCommonStyle,
        },
    });

    const cardContentArrangement = useSelectScreen({
        lg: "row",
        default: "column",
    }) as ViewStyle["flexDirection"];

    const cardCopyArrangement = useSelectScreen({
        lg: "column",
        default: "row",
    }) as ViewStyle["flexDirection"];

    const analyticsEventData = React.useMemo(() => {
        return {
            category: "coupon-copy-btn",
            label: `itemId: ${productId}, storeId: ${store.storeId}`,
            context: `couponCode: ${coupon.code}`,
        };
    }, [coupon.code, productId, store.storeId]);

    const onPressCopy = React.useCallback(() => {
        Analytics.clickEvent(analyticsEventData);

        copyToClipboard();
    }, [analyticsEventData, copyToClipboard]);

    useMount(() => {
        Analytics.impressionEvent(analyticsEventData);
    });

    return (
        <Box direction={cardContentArrangement} onLayout={onLayout} flex={1}>
            <Box style={[styles.container, cardContainer]} direction={cardContentArrangement}>
                <Box style={styles.textContainer} flex={textContainerFlex}>
                    <Stack
                        direction="row"
                        spacing={3 * SpacingValue["space-xxx-small"]}
                        alignItems="center">
                        <Text
                            numberOfLines={1}
                            style={styles.title}
                            category={couponTitleCategory}
                            color="color-critical-300"
                            weight="bold">
                            {cardData.title}
                        </Text>
                        {coupon.segmentedOffer ? (
                            <CdnImage id={IMG_ID} height={HEIGHT} width={WIDTH} />
                        ) : null}
                    </Stack>
                    <Text category={couponDescriptionCategory} color="medium">
                        {cardData.description}
                    </Text>

                    <Text category={couponDescriptionCategory} color="medium">
                        {`${cardData.codePrefix}`}
                        <Text category={couponDescriptionCategory} color="medium" weight="bold">
                            {cardData.code}
                        </Text>
                    </Text>
                </Box>
            </Box>

            <Pressable style={copyCodeStyle} onPress={onPressCopy}>
                <Stack
                    direction={cardCopyArrangement}
                    spacing={SpacingValue["space-x-small"]}
                    justifyContent="center"
                    alignItems="center">
                    <SvgIcon icon="Outline" height={16} width={16} color="color-positive-500" />
                    <Text category="btn5" color="color-positive-500" weight="bold">
                        {copyCouponText}
                    </Text>
                </Stack>
            </Pressable>
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderRadius: 12,
    },
    textContainer: {
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-small"],
        maxWidth: 260,
    },
    title: {
        flexShrink: 1,
    },
});

export const CouponCard = React.memo(CouponCardComponent);
