import * as React from "react";
import { Platform, Pressable, SafeAreaView, ScrollView, StyleSheet, TextInput } from "react-native";
import { useLoading } from "@rest-hooks/hooks";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { Input, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { useMount, useMountedRef } from "@swiggy-private/react-hooks";
import { ActionButton } from "@swiggy-private/react-native-ui";

import { Analytics } from "@minis-consumer/analytics";
import { Logger } from "@minis-consumer/includes/logger";
import { REGEX } from "@minis-consumer/constants/regex";

import { AuthService } from "../../../api/auth";
import { AuthScreenContainer } from "../components/screen-container";
import { AuthHeader } from "../components/header";
import { OTP_LENGTH, OTP_RESENT_TIME_IN_SECS } from "../constants";
import { AuthContext } from "../contexts/auth-context";
import { useLoginViaOtp } from "../../../hooks/use-login";
import { convertTimeInSecsToMinSecs, removeSpaces } from "../helpers";

import type { AuthRouteList } from "../types";

type Props = NativeStackScreenProps<AuthRouteList, "VerifyOtp">;

export const VerifyOtp: React.FC<Props> = ({ route, navigation }) => {
    const { phoneNumber, ardEventCategory } = route.params;
    const { value: theme } = useTheme();

    const mountedRef = useMountedRef();
    const timer = React.useRef(0);
    const inputRef = React.useRef<TextInput>(null);

    const [otp, setOtp] = React.useState("");
    const [timeToResent, setTimeToResent] = React.useState(OTP_RESENT_TIME_IN_SECS);

    const authContext = React.useContext(AuthContext);
    const loginViaOtp = useLoginViaOtp();

    const remainingTime = React.useMemo(
        () => convertTimeInSecsToMinSecs(timeToResent),
        [timeToResent],
    );

    const [clickHandler, loading, error] = useLoading(async () => {
        await loginViaOtp(otp);

        Analytics.clickEvent({
            category: ardEventCategory ?? "verifyotp-btn",
        });

        authContext?.exit();
    }, [loginViaOtp, otp, ardEventCategory, authContext]);

    const smsOtp = React.useCallback(() => {
        AuthService.smsOtp(phoneNumber)
            .then(() => {
                if (!mountedRef.current) {
                    return;
                } else {
                    setTimeToResent(OTP_RESENT_TIME_IN_SECS);
                    setOtp("");
                }
            })
            .catch(Logger.recordError);
    }, [mountedRef, phoneNumber]);

    const onChangeText = React.useCallback((text: string) => {
        const _otp = removeSpaces(text);
        if (!_otp || REGEX.OTP.test(_otp)) {
            setOtp(_otp);
        }
    }, []);

    useMount(() => {
        clearInterval(timer.current);
        timer.current = setInterval(
            () => setTimeToResent((s) => Math.max(0, s - 1)),
            1_000,
        ) as unknown as number;

        const frameTimer = requestAnimationFrame(
            () => mountedRef.current && inputRef.current?.focus(),
        );

        return () => {
            clearInterval(timer.current);
            cancelAnimationFrame(frameTimer);
        };
    });

    useMount(() => {
        AuthService.smsOtp(phoneNumber).catch(Logger.recordError);
    });

    const ph = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    return (
        <AuthScreenContainer style={styles.container}>
            <AuthHeader
                title="Verify OTP"
                description="Confirm mobile number using the OTP"
                onBack={navigation.goBack}
            />
            <ScrollView>
                <Box
                    ph={ph}
                    pt={SpacingValue["space-xx-large"]}
                    style={{ backgroundColor: theme["color-background-primary"] }}>
                    <Text category="h4" weight="bold" color="high">
                        Please enter OTP
                    </Text>
                    <Text category="b1" weight="medium" color="medium">
                        sent to {phoneNumber}.
                    </Text>
                    <Box mt={40}>
                        <Stack
                            direction="row"
                            style={styles.otpTextContainer}
                            spacing={SpacingValue["space-small"]}>
                            {Array(OTP_LENGTH)
                                .fill(0)
                                .map((_, i) => (
                                    <Text
                                        key={i}
                                        category="s2"
                                        color="medium"
                                        style={styles.otpTextUnit}>
                                        {otp.charAt(i) || "-"}
                                    </Text>
                                ))}
                        </Stack>
                        <Input
                            testID="input-otp"
                            keyboardType="phone-pad"
                            ref={inputRef}
                            maxLength={OTP_LENGTH}
                            value={otp}
                            textContentType="oneTimeCode"
                            onChangeText={onChangeText}
                            textStyle={styles.otpInputText}
                            style={styles.otpInput}
                            autoFocus
                            caretHidden={otp.length === OTP_LENGTH}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            autoComplete={Platform.OS === "web" ? "one-time-code" : "sms-otp"}
                        />
                    </Box>
                    <Box direction="row">
                        <Text category="b2" color="high">
                            {"Didn't receive OTP yet? "}
                        </Text>
                        {timeToResent > 0 ? (
                            <>
                                <Text category="b2" color="high">
                                    {"Re-send in "}
                                </Text>
                                <Text category="b2" color="color-positive-500" weight="medium">
                                    {remainingTime}
                                </Text>
                            </>
                        ) : null}
                        {timeToResent === 0 ? (
                            <Pressable onPress={smsOtp}>
                                <Text category="b2" color="color-positive-500" weight="medium">
                                    {"Re-send"}
                                </Text>
                            </Pressable>
                        ) : null}
                    </Box>
                </Box>
            </ScrollView>
            <SafeAreaView>
                <Box
                    ph={SpacingValue["space-x-large"]}
                    pb={SpacingValue["space-x-large"]}
                    pt={SpacingValue["space-xx-large"]}
                    justifyContent="flex-end">
                    {error ? (
                        <Text color="critical" weight="medium" style={styles.error}>
                            {error.message}
                        </Text>
                    ) : null}
                    <ActionButton
                        loading={loading}
                        disabled={otp.length !== OTP_LENGTH || loading}
                        onPress={clickHandler}>
                        Confirm and Proceed
                    </ActionButton>
                </Box>
            </SafeAreaView>
        </AuthScreenContainer>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        overflow: "hidden",
    },
    otpInput: {
        backgroundColor: "transparent",
        borderColor: "transparent",
        paddingLeft: 0,
    },
    otpInputText: {
        fontSize: 24,
        lineHeight: 28,
        letterSpacing: SpacingValue["space-small"],
        color: "transparent",
    },
    otpTextContainer: {
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        top: 0,
        bottom: 0,
    },
    otpTextUnit: {
        fontSize: 24,
        lineHeight: 28,
    },
    error: {
        marginBottom: SpacingValue["space-x-small"],
    },
});
