import React from "react";
import { StyleSheet } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { Button, Text, SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { CdnImage, DashBorderLine } from "@swiggy-private/react-native-ui";

import { RBI_TNC_MODAL_BODY_COPIES } from "../constants";

type DialogBodyProps = {
    onPressOkay: VoidFunction;
};

const IMAGE_SIZE = 34;

const DialogBodyComponent: React.FC<DialogBodyProps> = ({ onPressOkay }) => {
    const { value: theme } = useTheme();

    const ctaStyle = {
        backgroundColor: theme["color-positive-500"],
        borderColor: theme["color-positive-500"],
    };
    const onPressCta = React.useCallback(() => {
        onPressOkay();
    }, [onPressOkay]);

    return (
        <Stack spacing={SpacingValue["space-x-small"]}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Text category="h3" weight="bold" color="highest">
                    {RBI_TNC_MODAL_BODY_COPIES.TITLE}
                </Text>
                <Box style={styles.cancelCta}>
                    <SvgIcon
                        icon="Close"
                        height={16}
                        width={16}
                        onPress={onPressCta}
                        color="color-basic-60"
                    />
                </Box>
            </Stack>

            <Text category="b2" color="medium">
                {RBI_TNC_MODAL_BODY_COPIES.SUBTITLE}
            </Text>

            <Stack style={styles.cardProtectedItem} direction="row" alignItems="center">
                <CdnImage
                    isImageKitEnabled
                    width={IMAGE_SIZE}
                    height={IMAGE_SIZE}
                    id={RBI_TNC_MODAL_BODY_COPIES.CARD_PROTECTED.IMAGE_ID}
                    style={styles.image}
                />
                <Text category="b1" color="high" style={styles.text}>
                    {RBI_TNC_MODAL_BODY_COPIES.CARD_PROTECTED.TEXT}
                </Text>
            </Stack>

            <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.divider} />

            <Stack style={styles.creditCardItem} direction="row" alignItems="center">
                <CdnImage
                    isImageKitEnabled
                    width={IMAGE_SIZE}
                    height={IMAGE_SIZE}
                    id={RBI_TNC_MODAL_BODY_COPIES.CREDIT_CARD.IMAGE_ID}
                    style={styles.image}
                />
                <Text category="b1" color="high" style={styles.text}>
                    {RBI_TNC_MODAL_BODY_COPIES.CREDIT_CARD.TEXT}
                </Text>
            </Stack>

            <Button style={[ctaStyle, styles.cta]} onPress={onPressCta}>
                <Text style={{ color: theme["color-basic-0"] }}>
                    {RBI_TNC_MODAL_BODY_COPIES.PRIMARY_BTN}
                </Text>
            </Button>
        </Stack>
    );
};

const styles = StyleSheet.create({
    divider: {
        marginTop: 0,
    },

    text: {
        flex: 1,
        flexWrap: "wrap",
    },
    image: {
        borderRadius: 8,
        marginRight: SpacingValue["space-medium"],
    },
    cardProtectedItem: {
        marginTop: SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-small"],
    },
    creditCardItem: {
        marginTop: SpacingValue["space-medium"],
        marginBottom: SpacingValue["space-x-small"],
    },
    cancelCta: {
        paddingHorizontal: SpacingValue["space-x-small"],
        paddingBottom: SpacingValue["space-x-small"],
        paddingTop: SpacingValue["space-xx-small"],
    },
    cta: {
        borderRadius: SpacingValue["space-x-small"],
        width: "100%",
        height: 52,
        marginTop: SpacingValue["space-medium"],
    },
});

export const DialogBody = React.memo(DialogBodyComponent);
