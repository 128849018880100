import React from "react";
import { Platform, StyleSheet, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { IOScrollView } from "@swiggy-private/react-native-ui";
import { Container, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { ColorPalette, SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { GuestCheckoutDeliveryFee } from "@minis-consumer/components/guest-checkout/delivery-fee";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";
import { useUserInfo } from "@minis-consumer/hooks/use-user";

import { PaymentFooter } from "../../components/footer-payment";

type BackgroundColor = Extract<
    keyof ColorPalette,
    "color-background-primary" | "color-background-secondary"
>;

type TExtraProps = {
    backgroundColor: BackgroundColor;
    canShowGuestCheckoutDeliveryFee?: boolean;
    shouldShowPaymentFooter?: boolean;
};

export const withPaymentMainContainer =
    <P extends object>(Component: React.ComponentType<P>, extraData: TExtraProps): React.FC<P> =>
    // eslint-disable-next-line react/display-name
    (props) => {
        return (
            <PaymentMainContainer {...extraData} {...props}>
                <Component {...props} />
            </PaymentMainContainer>
        );
    };

interface Props extends TExtraProps {
    children: React.ReactNode;
}

const PaymentMainContainer: React.FC<Props> = ({
    children,
    backgroundColor = "color-background-secondary",
    canShowGuestCheckoutDeliveryFee = false,
    shouldShowPaymentFooter = false,
}) => {
    const insets = useSafeAreaInsets();
    const { value: theme } = useTheme();
    const isDweb = useIsDesktopWeb();

    const userInfo = useUserInfo();

    const insetBottom = Math.min(insets.bottom, SpacingValue["space-large"]);

    const contentContainerStyle: ViewStyle = {
        paddingBottom: insetBottom,
        backgroundColor: theme[backgroundColor],
    };

    const contentWrapperStyle: ViewStyle = useSelectScreen({
        lg: {},
        default: { paddingBottom: Platform.OS !== "web" ? insetBottom : 0 },
    });

    const contentInnerStyle: ViewStyle = isDweb
        ? { marginVertical: SpacingValue["space-x-large"] * 2 }
        : { marginBottom: SpacingValue["space-x-large"] * 2 };

    return (
        <Stack style={[styles.container, contentWrapperStyle]}>
            <IOScrollView
                style={styles.container}
                contentContainerStyle={[styles.contentContainer, contentContainerStyle]}
                showsVerticalScrollIndicator={false}
                bounces={false}>
                {!userInfo && canShowGuestCheckoutDeliveryFee ? <GuestCheckoutDeliveryFee /> : null}
                <Container style={contentInnerStyle} fluid={false} disableGutters>
                    {children}
                </Container>
            </IOScrollView>
            {shouldShowPaymentFooter ? <PaymentFooter /> : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: { flex: 1 },
    contentContainer: { flexGrow: 1 },
});
