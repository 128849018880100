import React from "react";
import { ViewStyle, StyleSheet, ImageStyle } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { CouponCard as CommonCouponCard } from "@swiggy-private/react-native-ui";

import { CartCouponSectionCoupon } from "@minis-consumer/interfaces/coupon";
import { Analytics } from "@minis-consumer/analytics";
import { getCouponDataForCard } from "@minis-consumer/helpers/coupon";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { SEGMENTED_COUPON_BANNER } from "@minis-consumer/constants/images";

const APPLY_CTA_TEXT = "Apply coupon";
const { IMG_ID, HEIGHT, WIDTH } = SEGMENTED_COUPON_BANNER;

interface CouponCardComponentProps {
    coupon: CartCouponSectionCoupon;
    index: number;

    cartId?: string;
    brandImageUrl?: string;
    onApply?: () => void;
}

const CouponCardComponent: React.FC<CouponCardComponentProps> = ({
    coupon,
    index,
    cartId,
    brandImageUrl,
    onApply,
}) => {
    const store = useStoreInfo(false);
    const { value: theme } = useTheme();

    const { isEligible, code } = coupon;

    const commonCardStyle = {
        borderRadius: 16,
    };

    const specificCardStyle: ViewStyle = useSelectScreen({
        lg: {
            marginRight: index % 2 === 0 ? SpacingValue["space-x-small"] : 0,
            marginLeft: index % 2 === 0 ? 0 : SpacingValue["space-x-small"],
            width: 342,
        },
        default: {},
    });

    const activateCoupon = React.useMemo(() => {
        return {
            text: APPLY_CTA_TEXT,
            onActivate: onApply,
            style: {
                marginTop: SpacingValue["space-small"],
            },
        };
    }, [onApply]);

    const couponData = React.useMemo(
        () => getCouponDataForCard({ coupon, city: store?.address?.city }),
        [coupon, store?.address?.city],
    );

    const tagStyle: ImageStyle = {
        opacity: isEligible ? 1 : 0.4,
    };

    const couponTag = {
        canShowTag: coupon.segmentedOffer,
        imgId: IMG_ID,
        height: HEIGHT,
        width: WIDTH,
        tagStyle,
    };

    const onMount = React.useCallback(() => {
        Analytics.impressionEvent({
            category: isEligible ? "available-coupon-apply-btn" : "more-offers-coupon",
            label: cartId,
            context: code,
            value: index + 1,
        });
    }, [cartId, code, isEligible, index]);

    return (
        <CommonCouponCard
            textContent={{
                title: couponData.title,
                titleTextProps: {
                    category: isEligible ? "h5" : "b1",
                    weight: "bold",
                    color: isEligible ? "color-basic-75" : "color-basic-60",
                    numberOfLines: 1,
                },
                description: couponData.description,
                descriptionProps: { category: "b3", color: "color-basic-60" },
                titleStyle: styles.titleStyle,
            }}
            code={{
                text: couponData.code,
                prefix: "Coupon Code: ",
                prefixProps: {
                    category: "b2",
                    color: isEligible ? "color-basic-75" : "color-basic-60",
                },
                props: {
                    category: "b2",
                    color: isEligible ? "color-primary" : "color-basic-60",
                    weight: "bold",
                },
            }}
            options={{
                isActive: isEligible,
                cardStyle: {
                    ...commonCardStyle,
                    ...specificCardStyle,
                },
                imgContainerStyle: { opacity: isEligible ? 1 : 0.4 },
                brandColor: isEligible ? theme["color-primary"] : theme["color-basic-60"],
                onMount: onMount,
                brandImageId: brandImageUrl,
                couponTag,
            }}
            activateCoupon={isEligible && onApply ? activateCoupon : undefined}
        />
    );
};

const styles = StyleSheet.create({
    titleStyle: {
        flexShrink: 1,
    },
});

export const CouponCard = React.memo(CouponCardComponent);
