import React from "react";

import { useClearCartTooltipCounter } from "@minis-consumer/routes/account/routes/abandoned-cart/hooks/use-clear-abanadoned-cart-tooltip-count";

type ReturnValue = {
    isTooltipVisible: boolean;
    onDismissTooltip: VoidFunction;
    onMountTooltip: VoidFunction;
};

const VALID_ORDER_SEQUENCE = 0;

export const useShowClearCartTooltip = (cartOrderSequence?: number): ReturnValue => {
    const { isTooltipViewCountLegal, incrementCounter } = useClearCartTooltipCounter();

    const [show, setShow] = React.useState(false);

    const onDismissClearCartTooltip = React.useCallback(() => {
        setShow(false);
    }, []);

    const onMount = React.useCallback(() => {
        const isValidStatus = isTooltipViewCountLegal && cartOrderSequence === VALID_ORDER_SEQUENCE;

        setShow(isValidStatus);

        if (isValidStatus) {
            incrementCounter();
        }

        return () => {
            onDismissClearCartTooltip();
        };
    }, [isTooltipViewCountLegal, incrementCounter, onDismissClearCartTooltip, cartOrderSequence]);

    return {
        isTooltipVisible: show,
        onDismissTooltip: onDismissClearCartTooltip,
        onMountTooltip: onMount,
    };
};
