import React from "react";
import { ViewStyle } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

import { useNavigationBack } from "@minis-consumer/hooks/use-navigation-back";
import { HEADER_TITLE } from "../constants";

type HeaderLeftComponentProps = {
    orderId: string;
    containerStyle?: ViewStyle;
};

const HeaderLeftComponent: React.FC<HeaderLeftComponentProps> = ({ orderId, containerStyle }) => {
    const goBack = useNavigationBack();

    return (
        <Stack
            style={containerStyle}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={SpacingValue["space-medium"]}>
            <SvgIcon
                icon="ArrowLeft"
                onPress={goBack}
                height={20}
                width={20}
                color="color-secondary-400"
                hitSlop={{
                    left: 16,
                    right: 16,
                    top: 16,
                    bottom: 16,
                }}
            />
            <Stack spacing={SpacingValue["space-xxx-small"]}>
                <Text category="b1" weight="bold" color="highest">
                    {HEADER_TITLE}
                </Text>
                <Text category="b3" weight="regular" color="medium" selectable>
                    {`Order #${orderId}`}
                </Text>
            </Stack>
        </Stack>
    );
};

export default React.memo(HeaderLeftComponent);
