import React, { memo, useCallback, useMemo } from "react";
import { Pressable, StyleSheet } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/core";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";
import { RouteList } from "@minis-consumer/interfaces/route";

import { DISPLAY_COPIES } from "./constants";
import { ModalReturnSteps } from "./components/modal-return-steps";
import { ReturnDetailsProps } from ".";

const DELIVERED = "DELIVERED";

const InitiateReturnDwebComponent: React.FC<ReturnDetailsProps> = ({ order, refreshPage }) => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NavigationProp<RouteList>>();
    const [showModal, setShowModal] = React.useState(false);
    const { createdAt: returnCreatedAt } = order.returnDetails?.order || {};

    const { allowed: isReturnAllowed, deadline: deadlineEpoch = 0 } =
        order.returnDetails?.settings || {};

    const exchangeDeadlineText = useMemo(() => {
        if (returnCreatedAt) {
            const dateText = convertToReadableDateAndTime(returnCreatedAt);
            return `${DISPLAY_COPIES.REQUEST_RAISED} ${dateText}`;
        }

        const readableDeadline = convertToReadableDateAndTime(deadlineEpoch);
        return isReturnAllowed && deadlineEpoch
            ? `${DISPLAY_COPIES.POSSIBLE_TILL} ${readableDeadline}`
            : DISPLAY_COPIES.OUT_OF_TIME;
    }, [deadlineEpoch, isReturnAllowed, returnCreatedAt]);

    const onChatWithUsPress = useCallback(
        () => navigation.navigate("ChatConversation", { storeId: order.storeId }),
        [navigation, order.storeId],
    );

    const onExchangePress = useCallback(() => {
        if (!isReturnAllowed) {
            return;
        }

        setShowModal(true);
    }, [isReturnAllowed]);

    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);

    const handleOnReturnRequest = useCallback(() => {
        handleCloseModal();
        refreshPage?.();
    }, [handleCloseModal, refreshPage]);

    if (!order.returnDetails || order.status !== DELIVERED) {
        return null;
    }

    return (
        <Stack style={[styles.root, { backgroundColor: theme["color-basic-0"] }]}>
            <Text category="b1" weight="bold">
                {DISPLAY_COPIES.MORE_HELP_TEXT}
            </Text>

            <DashBorderLine
                style={styles.borderLine}
                borderColor={theme["color-secondary-400"]}
                type="dotted"
            />

            <Pressable style={styles.linkContainer} onPress={onChatWithUsPress}>
                <Text category="b2" weight="medium">
                    {DISPLAY_COPIES.QUERIES}
                </Text>
                <SvgIcon icon="ChevronRight" />
            </Pressable>

            <Pressable
                style={[styles.linkContainer, !isReturnAllowed ? styles.greyOut : null]}
                onPress={onExchangePress}>
                <Stack style={styles.linkLeftContainer}>
                    <Text category="b2" weight="medium">
                        {DISPLAY_COPIES.EXCHANGE}
                    </Text>
                    <Text category="b3">{exchangeDeadlineText}</Text>
                </Stack>
                <SvgIcon icon="ChevronRight" />
            </Pressable>

            <ModalReturnSteps
                order={order}
                showModal={showModal}
                onClose={handleCloseModal}
                onReturnRequest={handleOnReturnRequest}
            />
        </Stack>
    );
};

export const InitiateReturnDweb = memo(InitiateReturnDwebComponent);

const styles = StyleSheet.create({
    root: {
        borderRadius: SpacingValue["space-medium"],
        padding: SpacingValue["space-medium"],
    },
    linkContainer: {
        marginBottom: SpacingValue["space-medium"],
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    borderLine: {
        marginTop: SpacingValue["space-medium"],
        marginVertical: SpacingValue["space-medium"],
        opacity: 0.15,
    },
    greyOut: {
        opacity: 0.5,
    },
    linkLeftContainer: { flexBasis: "90%" },
});
