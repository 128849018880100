import React, { memo } from "react";
import { ViewStyle } from "react-native";

import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { LABELS } from "../../constants";

const AvailabilityMessageComponent: React.FC<{
    availabilityMessage: string;
}> = ({ availabilityMessage }) => {
    const { value: theme } = useTheme();

    const storeInfo = useStoreInfo();

    const containerScreenStyle = useSelectScreen({
        lg: {
            marginHorizontal: 0,
            borderRadius: 0,
            marginTop: 0,
        },
        default: undefined,
    });

    const containerStyle: ViewStyle = {
        backgroundColor: theme["color-primary-25"],
        borderRadius: 12,
        padding: SpacingValue["space-small"],
        marginHorizontal: SpacingValue["space-medium"],
        marginTop: SpacingValue["space-large"],
        ...containerScreenStyle,
    };

    if (!availabilityMessage) {
        return null;
    }

    return (
        <Box mt={SpacingValue["space-x-large"]} style={containerStyle}>
            <Stack spacing={SpacingValue["space-xxx-small"]}>
                <Text category="b2" color="color-primary" weight="bold">
                    {LABELS.AVAILABILITY_MESSAGE_PREFIX} {storeInfo.name}
                </Text>

                <Text category="b2" color="high" weight="regular">
                    {availabilityMessage}
                </Text>
            </Stack>
        </Box>
    );
};

export const AvailabilityMessage = memo(AvailabilityMessageComponent);
