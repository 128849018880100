import React from "react";
import { Platform } from "react-native";

import { Box, useScreenSize, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { useCartStatus, useSyncCartWithServer } from "@minis-consumer/hooks/use-cart";
import { useCustomCartContext } from "@minis-consumer/contexts/custom-cart-context";

import { EmptyCart } from "../empty-cart";
import { SmallScreenLayout } from "../layout/small-screen";
import { LargeScreenLayout } from "../layout/large-screen";
import { Placeholder } from "../placeholder";
import { isCartErrorUnknown, isCartOrStoreNotFound, isStoreConfigNotFound } from "../../helpers";
import { GenericError } from "../generic-errors";

const isWeb = Platform.OS === "web";

interface CartMainProps {
    storeId: string;
    emptyCart?: boolean;
    navigationReset: () => void;
    cartKey?: string;
    errorMessage?: string;
}

export const CartMainPage: React.FC<CartMainProps> = ({
    storeId,
    emptyCart,
    navigationReset,
    errorMessage,
}) => {
    const screenSize = useScreenSize();
    const cartSyncing = useSyncCartWithServer(storeId);
    const cartStatus = useCartStatus(storeId);
    const { isCustomCartKeyUsedForStore } = useCustomCartContext();

    const canShowPreCheckoutDialog = React.useMemo(() => {
        if (isWeb) {
            return false;
        }

        return !isCustomCartKeyUsedForStore(storeId);
    }, [isCustomCartKeyUsedForStore, storeId]);

    const marginTopEmptyStyle = useSelectScreen({
        lg: "20%",
        default: undefined,
    });

    if (
        isCartOrStoreNotFound(cartStatus) ||
        isStoreConfigNotFound(cartStatus) ||
        isCartErrorUnknown(cartStatus)
    ) {
        return <GenericError cartStatus={cartStatus} />;
    }

    if (emptyCart) {
        if (cartSyncing) {
            return <Placeholder />;
        }

        return (
            <Box style={{ marginTop: marginTopEmptyStyle }} flex={1}>
                <EmptyCart navigationReset={navigationReset} />
            </Box>
        );
    }

    return screenSize === "lg" ? (
        <LargeScreenLayout
            storeId={storeId}
            cartSyncing={cartSyncing}
            errorMessage={errorMessage}
            canShowPreCheckoutDialog={canShowPreCheckoutDialog}
        />
    ) : (
        <SmallScreenLayout
            storeId={storeId}
            cartSyncing={cartSyncing}
            errorMessage={errorMessage}
            canShowPreCheckoutDialog={canShowPreCheckoutDialog}
        />
    );
};

export const CartMain = React.memo(CartMainPage);
