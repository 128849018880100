import React, { FC, PropsWithChildren } from "react";
import { NativeStackScreenProps, NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useNavigation } from "@react-navigation/native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { useTheme, Text, SpacingValue } from "@swiggy-private/rn-dls";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";

import { CommonRouteList } from "@minis-consumer/interfaces/route";
import { DynamicMultipleForms } from "@minis-consumer/components/dynamic-forms/forms";
import { IDynamicForm } from "@minis-consumer/interfaces/dynamic-forms";
import { ICartItemForm } from "@minis-consumer/interfaces/cart";
import { useCartViewData, useLocalCartItem } from "@minis-consumer/hooks/use-cart";
import { useGetPdpCtaNavigation } from "@minis-consumer/hooks/use-pdp-cta-navigation";
import { getMediaAssets, getProductDurationText } from "@minis-consumer/helpers/catalog";
import { MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { Product } from "@minis-consumer/interfaces/catalog";
import { useEnquiryChat } from "@minis-consumer/hooks/use-product-enquiry-chat";
import { Analytics } from "@minis-consumer/analytics";
import { getSelectedVariants } from "@minis-consumer/helpers/variants";
import { useSelectedVariantWithSlug } from "@minis-consumer/hooks/use-variants";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";

import { FORM_CTA, FORM_SEPARATOR_TEXT, HEADER_TEXT } from "./constants";
import { formatDate } from "./helpers";
import { HeaderLeft } from "./components/header-left";

type Props = NativeStackScreenProps<CommonRouteList, "ProductForms">;

type ProductFormProps = Props & {
    data?: IDynamicForm[];
    product: Product | null;
    isEnquiryForm: boolean;

    disabled?: boolean;
    readOnly?: boolean;
    variantId?: string;
};

const PROCEED_CTA_EVENT = "product-cta-user-form";

export const ProductForm: FC<PropsWithChildren<ProductFormProps>> = ({
    navigation,
    route,
    data,
    product,
    isEnquiryForm,
    disabled,
    readOnly,
    variantId,
}) => {
    const { value: theme } = useTheme();

    const { storeId = "", slug: storeSlug, isEnquireMode, isEditMode } = route.params;

    const productId = product?.id ?? "";
    const storeNavigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const minisNavigation = useNavigation<NativeStackNavigationProp<MinisRouteList>>();

    const selectedVariantId = variantId;
    const isDesktop = useIsDesktop();

    const { productSelectedData, resetProductSelectedData, setProductSelectedData } =
        useGetPdpCtaNavigation({
            productId,
            storeId,
            storeSlug,
            minisNavigation: storeNavigation,
        }) || {};

    const { sendEnquiryMessage } = useEnquiryChat(storeId);

    const { selectedVariant } = useSelectedVariantWithSlug(productId, storeSlug, selectedVariantId);

    const getVariantText = React.useCallback((): string => {
        const selectedVariantOptionsMap = selectedVariant?.optionValueMap;

        const variantOptions = product?.variantDetails?.options || [];

        const selectedVariantOptions: string[] = [];
        variantOptions?.forEach((option) => {
            const variantName = option.name;
            const variantValue = selectedVariantOptionsMap?.[variantName] ?? "";
            selectedVariantOptions.push(variantValue);
        });

        return getSelectedVariants(selectedVariantOptions);
    }, [selectedVariant, product]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, increment] = useLocalCartItem({
        storeId: product?.storeId ?? storeId,
        itemId: productId,
        productType: product?.productType ?? "PHYSICAL",
    });

    const cartViewData = useCartViewData(storeId);

    const analyticsData = React.useMemo(() => {
        return {
            category: PROCEED_CTA_EVENT,
            context: JSON.stringify({
                productId,
                variantId,
                productType: product?.productType,
                price: product?.price,
                discountedPrice: product?.discountedPrice,
                isRecommended: product?.badges.includes("Recommended"),
                isItemAvailable: product?.inStock,
                isFormPresent: true,
                isEnquiryFormPresent: isEnquiryForm,
                isEditMode,
                isEnquireMode,
                cartId: cartViewData?.id,
                billAmount: cartViewData?.bill?.billTotal,
            }),
        };
    }, [productId, variantId, product, isEnquiryForm, isEditMode, isEnquireMode, cartViewData]);

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerShadowVisible: true,
            title: HEADER_TEXT,
        });
    }, [navigation]);

    useMount(() => {
        Analytics.impressionEvent(analyticsData);
    });

    const FormSeparator = React.useMemo(() => {
        return (
            <Box
                ph={16}
                pv={16}
                mt={SpacingValue["space-large"]}
                style={{ backgroundColor: theme["color-basic-5"] }}>
                <Text category="b1" weight="bold">
                    {FORM_SEPARATOR_TEXT}
                </Text>
            </Box>
        );
    }, [theme]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setFormData = (formData: Record<string, any>): void => {
        if (!data || data.length === 0) {
            return;
        }
        const lastFormIndex = Object.keys(formData).length - 1;
        const sellerFormData = formData[lastFormIndex];
        const formQuestions = data[lastFormIndex] ?? [];

        let enquiryFormValues: string[] = [];
        let allFormData: IDynamicForm[] = [];

        //get enquiry form answeres if enquiry form is present
        if (isEnquiryForm) {
            const enquiryForm = formData[0];
            enquiryFormValues = Object.values(enquiryForm);

            const enquireFormQues = data[0];

            const enquiryFormData = enquireFormQues.map((ques, index) => {
                return { ...ques, answer: enquiryFormValues[index] };
            });
            allFormData = [enquiryFormData];
        }

        const viewFormData: IDynamicForm = [];

        // get form data for seller questions answered
        let finalData: ICartItemForm[] = Object.keys(sellerFormData)
            .map((key) => {
                const id = key;
                const value = sellerFormData[id];

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const field = formQuestions.find((formField: any) => formField.id === id);
                if (field === undefined) {
                    return;
                }
                const { answerType, id: questionId, question } = field;

                viewFormData.push({ id: questionId, answer: value, answerType, question });

                return {
                    id: questionId,
                    answer: value,
                    answerType,
                } as ICartItemForm;
            })
            .filter((form): form is ICartItemForm => form !== undefined);

        // add existing questions answered while editing form
        if (formQuestions) {
            const existingAnswers = formQuestions.filter((d) => {
                const newData = finalData.find((fd) => fd.id === d.id);
                return !newData && !!d.answer;
            });
            const existingCartAns = existingAnswers.map((ans) => {
                return {
                    id: ans.id,
                    answer: ans.answer,
                    answerType: ans.answerType,
                } as ICartItemForm;
            });
            finalData = [...existingCartAns, ...finalData];
        }

        //only enquiry form is present
        if (isEnquiryForm && lastFormIndex === 0) {
            onSubmitForm(finalData, enquiryFormValues, allFormData);
            return;
        }
        allFormData = [...allFormData, viewFormData];
        onSubmitForm(finalData, enquiryFormValues, allFormData);
    };

    const onSubmitForm = React.useCallback(
        (
            finalData: ICartItemForm[],
            enquiryFormData: string[],
            viewFormData: IDynamicForm[],
        ): void => {
            Analytics.clickEvent(analyticsData);

            if (isEnquiryForm && product && enquiryFormData) {
                const variantLabel = getVariantText() ?? "";
                const [contactNum, enquiryText] = enquiryFormData;

                const durationUnitText = getProductDurationText({
                    value: product.productDuration?.value ?? 0,
                    unit: product.productDuration?.unit ?? "DAYS",
                });

                sendEnquiryMessage({
                    text: enquiryText,
                    productId: product.id,
                    productName: product.name,
                    productImageId: getMediaAssets(product)?.[0],
                    productDescription: product.description,
                    productPrice: selectedVariant?.price ?? product.price,
                    productDiscountedPrice:
                        selectedVariant?.discountPercent ?? product.discountedPrice,
                    variantId: productSelectedData?.variantId,
                    variantLabel,
                    productType: product.productType,
                    productSlot: formatDate(productSelectedData?.slot?.start ?? 0),
                    contactInfo: contactNum,
                    assetType: product.digitalAssetInfo?.extension,
                    formData: JSON.stringify(viewFormData),
                    productDuration: product.productDuration?.value ? durationUnitText : "",
                });

                setProductSelectedData?.({ formData: finalData });
                setTimeout(() => {
                    storeNavigation.replace("ChatConversation", {
                        storeId: storeId,
                    });
                }, 0);

                return;
            }

            increment(
                productSelectedData?.variantId ?? variantId,
                { slot: productSelectedData?.slot, itemForm: finalData },
                true,
            );
            resetProductSelectedData?.();

            const storeRoutes = storeNavigation.getState().routeNames;

            if (storeRoutes.indexOf("Home") > -1) {
                storeNavigation.navigate("Home", {
                    screen: "Cart",
                    params: {},
                });
            } else {
                minisNavigation.navigate("MiniStore", { slug: storeSlug, initialPath: "cart" });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            analyticsData,
            isEnquiryForm,
            product,
            increment,
            productSelectedData?.variantId,
            productSelectedData?.slot,
            variantId,
            resetProductSelectedData,
            storeNavigation,
            getVariantText,
            sendEnquiryMessage,
            setProductSelectedData,
            storeId,
            minisNavigation,
            storeSlug,
        ],
    );

    const renderHeader = (): React.ReactElement | null => {
        return isDesktop ? (
            <Box
                pv={20}
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                    backgroundColor: theme["color-basic-0"],
                    borderBottomColor: theme["color-basic-15"],
                    borderBottomWidth: 1,
                }}>
                <HeaderLeft
                    productId={productId}
                    isBookingType={product?.productType === "APPOINTMENT"}
                />
            </Box>
        ) : null;
    };

    if (!data || data.length === 0) {
        return null;
    }
    return (
        <DynamicMultipleForms
            forms={data}
            submitButtonText={isEnquiryForm ? FORM_CTA.SUBMIT_QUERY : FORM_CTA.PROCEED_TO_CART}
            FormSeparator={FormSeparator}
            onSubmitForms={setFormData}
            disabled={disabled}
            readonly={readOnly}
            renderHeader={renderHeader}
        />
    );
};
