import React, { useCallback } from "react";
import { Platform, Pressable, View, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useFocusEffect, useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { AnimatedView } from "@swiggy-private/react-native-ui";
import { Text, SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Stack, useScreenSize, ScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { Card } from "@minis-consumer/components/card";
import { useLocalCart } from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { Analytics } from "@minis-consumer/analytics";

const CartTabFloatingComponent: React.FC = () => {
    const screenSize = useScreenSize();
    const store = useStoreInfo();

    const insets = useSafeAreaInsets();
    const { value: theme } = useTheme();

    const cartView = useLocalCart(store.storeId);
    const cartItemsTotal = cartView.items.reduce((sum, item) => sum + item.quantity, 0) || 0;

    const [cartItemCount, setCartItemCount] = React.useState(0);
    const [willshow, setWillShow] = React.useState(false);

    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const minisNavigation = useNavigation<NativeStackNavigationProp<MinisRouteList>>();

    const navigateToCart = useCallback(() => {
        const miniRoutes = minisNavigation?.getState().routeNames;

        if (miniRoutes?.indexOf("MiniStore") > -1) {
            minisNavigation?.navigate("MiniStore", { slug: store.slug, initialPath: "cart" });
        } else {
            navigation.navigate("Home", {
                screen: "Cart",
                params: {},
            });
        }

        Analytics.clickEvent({
            category: "your-cart-bottom-bar",
            context: JSON.stringify({
                items: cartView.items.map((item) => item.id).concat(","),
            }),
        });
    }, [navigation, minisNavigation, cartView.items, store.slug]);

    useFocusEffect(
        useCallback(() => {
            Analytics.impressionEvent({
                category: "your-cart-bottom-bar",
                context: JSON.stringify({
                    items: cartView.items.map((item) => item.id).concat(","),
                }),
            });

            if (cartItemsTotal > 0) {
                setCartItemCount(cartItemsTotal);
                setWillShow(true);
            } else {
                Platform.OS === "web" && setCartItemCount(0);
                setWillShow(false);
            }

            return () => {
                setWillShow(false);
            };
        }, [cartItemsTotal, cartView.items]),
    );

    if (screenSize === ScreenSize.Large || !cartItemCount) {
        return null;
    }

    const containerStyle: ViewStyle = {
        position: "absolute",
        right: 0,
        left: 0,
        bottom: 0,
    };

    const cardStyle: ViewStyle = {
        backgroundColor: theme["color-primary"],
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        paddingBottom: Math.max(insets.bottom, SpacingValue["space-medium"]),
    };

    const cartTextStyle: ViewStyle = {
        marginBottom: Platform.select({
            android: -SpacingValue["space-xxx-small"],
            ios: -((SpacingValue["space-xx-small"] + SpacingValue["space-xxx-small"]) / 2),
        }),
    };

    const renderCart = (): React.ReactElement => {
        return (
            <Card style={cardStyle}>
                <Pressable onPress={navigateToCart}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack
                            spacing={SpacingValue["space-small"]}
                            direction="row"
                            alignItems="center">
                            <SvgIcon icon="BagFull" color="color-basic-0" height={20} width={20} />
                            <Text
                                category="btn4"
                                weight="bold"
                                color="color-basic-0"
                                style={cartTextStyle}>
                                Your Cart ({cartItemCount || 0})
                            </Text>
                        </Stack>
                        {cartItemCount ? (
                            <SvgIcon
                                icon="ArrowRight"
                                color="color-basic-0"
                                height={18}
                                width={22}
                            />
                        ) : null}
                    </Stack>
                </Pressable>
            </Card>
        );
    };

    return (
        <View style={containerStyle} pointerEvents="box-none">
            {Platform.OS === "web" ? (
                renderCart()
            ) : (
                <AnimatedView
                    duration={350}
                    animationType="slide_vertical"
                    reverse={!willshow}
                    onFinish={() => setCartItemCount(cartItemsTotal)}>
                    {renderCart()}
                </AnimatedView>
            )}
        </View>
    );
};

export const CartTabFloating = React.memo(CartTabFloatingComponent);
