import React from "react";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";

interface Props {
    itemCount: number;
    totalAmount: number;
}

const CART_HEADER_TITLE = "Your cart";

const HeaderTitleComponent: React.FC<Props> = ({ itemCount, totalAmount }) => {
    const billAmount = React.useMemo(
        () => formatNumberWithIndianSystem(totalAmount, { showRupeeSymbol: true }),
        [totalAmount],
    );

    return (
        <Box flex={1} direction="row" justifyContent="flex-start">
            <Box justifyContent="center">
                <Text category="b1" weight="bold" color="highest">
                    {CART_HEADER_TITLE}
                </Text>

                {itemCount ? (
                    <Box direction="row" mt={SpacingValue["space-xxx-small"]}>
                        <Text category="b3" weight="regular" color="medium">
                            {`${itemCount} item${itemCount > 1 ? "s" : ""}  |  ${billAmount}`}
                        </Text>
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};

export const HeaderTitle = React.memo(HeaderTitleComponent);
