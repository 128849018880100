import React from "react";
import { StyleSheet } from "react-native";

import { StarSellerBadge, useStarSeller } from "@swiggy-private/connect-business-commons";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextProps } from "@swiggy-private/rn-dls";

import { ReadMoreText } from "@minis-consumer/components/read-more-text";

import { StarSellerInfo } from "@minis-consumer/components/star-seller-info";
import { getNumberInShorthandFormat } from "@minis-consumer/helpers/number";
import { useFollowersCountState } from "@minis-consumer/hooks/use-followers-count-state";
import { useStarSellerInfo } from "@minis-consumer/hooks/use-star-seller-info";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { DESCRIPTION_MAX_LENGTH, SF_COPIES } from "@minis-consumer/routes/shop/constants";

const ShopHeaderComponent: React.FC = () => {
    const storeInfo = useStoreInfo();
    const isStoreEnabled = storeInfo.enabled !== false;
    const followersCountState = useFollowersCountState();

    const isStarSeller = useStarSeller(storeInfo?.badges);
    const {
        showStarSellerInfo,
        onCloseStarSellerInfo,
        onStarSellerBadgeMount,
        onStarSellerBadgePress,
    } = useStarSellerInfo(storeInfo.storeId);

    const followers = getNumberInShorthandFormat(
        followersCountState[storeInfo.storeId]?.followersCount ?? storeInfo?.followersCount ?? 0,
    );

    const storeCategories = React.useMemo(
        () => storeInfo.categories?.join(", "),
        [storeInfo.categories],
    );

    const shopHeaderStyle = {
        paddingTop: isStoreEnabled ? SpacingValue["space-small"] : 0,
    };
    const textProps: TextProps = {
        category: "b2",
        weight: "bold",
        color: "color-primary",
    };

    return (
        <Stack
            direction={{ default: "column", md: "row", lg: "row" }}
            spacing={SpacingValue["space-x-large"]}
            style={shopHeaderStyle}>
            <Stack flex={1} spacing={SpacingValue["space-small"]}>
                <Stack>
                    <Text category="h3" weight="bold" color="highest">
                        {storeInfo.name}
                    </Text>

                    {storeCategories && isStoreEnabled ? (
                        <Text category="b2" color="color-basic-45" numberOfLines={1}>
                            {storeCategories}
                        </Text>
                    ) : null}
                </Stack>

                {isStoreEnabled ? (
                    <Box direction="row" flex={1} alignItems="center">
                        <SvgIcon
                            icon="HeartFilled"
                            height={18}
                            width={18}
                            color="color-primary-400"
                        />

                        <Box
                            flex={1}
                            ml={SpacingValue["space-xx-small"]}
                            direction="row"
                            alignItems="center">
                            <Text
                                category="b2"
                                weight="bold"
                                color="color-basic-75"
                                numberOfLines={1}
                                style={styles.followers}>
                                {`${followers} ${SF_COPIES.LOVE_THIS}`}
                            </Text>
                            {isStarSeller ? (
                                <>
                                    <Text
                                        category="b2"
                                        weight="regular"
                                        color="color-basic-30"
                                        style={styles.dot}>
                                        •
                                    </Text>
                                    <StarSellerBadge
                                        primaryTextStyle={styles.primaryText}
                                        secondaryTextStyle={styles.secondaryText}
                                        primaryTextProps={{
                                            color: "color-basic-75",
                                        }}
                                        iconSize={16}
                                        showPrimaryText
                                        showSecondaryText
                                        rootContainerStyle={styles.badgeRootContainer}
                                        onPress={onStarSellerBadgePress}
                                        onMount={onStarSellerBadgeMount}
                                    />
                                    <StarSellerInfo
                                        storeId={storeInfo.storeId}
                                        showDialog={showStarSellerInfo}
                                        closeDialog={onCloseStarSellerInfo}
                                    />
                                </>
                            ) : null}
                        </Box>
                    </Box>
                ) : null}

                {storeInfo?.settings?.storeBio ? (
                    <ReadMoreText
                        text={storeInfo.settings.storeBio}
                        maxLengthCount={DESCRIPTION_MAX_LENGTH}
                        textProps={{ category: "b2", weight: "regular", color: "color-basic-60" }}
                        expandedTextProps={textProps}
                        collapsedTextProps={textProps}
                    />
                ) : null}
            </Stack>
        </Stack>
    );
};

if (__DEV__) {
    ShopHeaderComponent.displayName = "ShopHeaderComponent";
}

export const ShopHeader = React.memo(ShopHeaderComponent);

const styles = StyleSheet.create({
    dot: {
        marginLeft: SpacingValue["space-small"],
    },
    followers: {
        flexShrink: 1,
    },
    primaryText: {
        fontFamily: "ProximaNovaCond-Bold",
        fontSize: 14,
        fontWeight: "700",
    },
    secondaryText: {
        fontFamily: "ProximaNovaA-Black",
        fontSize: 14,
        fontWeight: "900",
        lineHeight: 19,
        letterSpacing: -0.3,
    },
    badgeRootContainer: {
        marginLeft: SpacingValue["space-x-small"],
        paddingLeft: 0,
        paddingRight: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-x-small"],
    },
});
