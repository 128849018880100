import React from "react";

import { Modal } from "@swiggy-private/rn-dls";

import { AllCoupons } from "../main";

interface Props {
    showModal: boolean;
    closeModal: () => void;
}

const AllCouponsModalComponent: React.FC<Props> = ({ showModal, closeModal }) => {
    return (
        <Modal
            showModal={showModal}
            setShowModal={closeModal}
            modalStyles={{
                modalBodyStyle: {
                    minWidth: 740,
                    minHeight: 500,
                    maxHeight: 700,
                    padding: 0,
                    borderRadius: 24,
                    overflow: "hidden",
                },
            }}
            component={<AllCoupons closeModal={closeModal} />}
        />
    );
};

export const AllCouponsModal = React.memo(AllCouponsModalComponent);
