import * as React from "react";
import { StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { SpacingValue, Text, Input } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { ActionButton } from "@swiggy-private/react-native-ui";
import { useLoading } from "@rest-hooks/hooks";
import { useMountedRef } from "@swiggy-private/react-hooks";

import { AuthService } from "@minis-consumer/api/auth";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Analytics } from "@minis-consumer/analytics";
import { REGEX } from "@minis-consumer/constants/regex";

import { AuthScreenContainer } from "../components/screen-container";
import { AuthHeader } from "../components/header";
import { AuthContext } from "../contexts/auth-context";

import { PHONE_NUMBER_LENGTH } from "../constants";
import type { AuthRouteList } from "../types";
import { removeSpaces } from "../helpers";

type Props = NativeStackScreenProps<AuthRouteList, "Login">;

export const Login: React.FC<Props> = ({ navigation }) => {
    const store = useStoreInfo(false);

    const [phoneNumber, setPhoneNumber] = React.useState("");
    const mounted = useMountedRef();

    const [clickHandler, loading, error] = useLoading(async () => {
        const response = await AuthService.fetchMobileNumberInfo(phoneNumber);
        if (!mounted.current) {
            return;
        }

        Analytics.clickEvent({
            category: "login-continue-btn",
        });

        if (response.registered) {
            navigation.navigate("VerifyOtp", {
                phoneNumber,
                ardEventCategory: "login-continue-verify-btn",
            });
        } else {
            navigation.navigate("Signup", { phoneNumber });
        }
    }, [phoneNumber, navigation, mounted]);

    const authContext = React.useContext(AuthContext);

    const onChangeText = React.useCallback((text: string) => {
        const _phoneNumber = removeSpaces(text);
        if (!_phoneNumber || REGEX.PHONE_NUMBER.test(_phoneNumber)) {
            setPhoneNumber(_phoneNumber);
        }
    }, []);

    return (
        <AuthScreenContainer>
            {authContext?.fullscreen ? (
                <AuthHeader title="Login" description="" onBack={navigation.goBack} />
            ) : null}
            <Stack
                spacing={SpacingValue["space-x-large"]}
                style={authContext?.fullscreen ? styles.fullscreenContainer : null}>
                <Box>
                    <Text color="high" weight="bold" category="h4">
                        Almost there
                    </Text>
                    <Text color="medium" category="b3">
                        Login or Signup to continue to
                        {store ? ` ${store.name} Ministore on Swiggy` : ""}
                    </Text>
                </Box>
                <Input
                    placeholder="Phone Number"
                    keyboardType="number-pad"
                    maxLength={PHONE_NUMBER_LENGTH}
                    value={phoneNumber}
                    onChangeText={onChangeText}
                />
                {error && error.message ? <Text color="critical">{error.message}</Text> : null}
                <ActionButton
                    size="large"
                    disableElevation
                    loading={loading}
                    disabled={phoneNumber.length !== PHONE_NUMBER_LENGTH || loading}
                    onPress={clickHandler}>
                    Continue
                </ActionButton>
            </Stack>
        </AuthScreenContainer>
    );
};

const styles = StyleSheet.create({
    fullscreenContainer: {
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-medium"],
    },
});
