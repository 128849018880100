import * as React from "react";
import {
    Animated,
    Pressable,
    PressableProps,
    StyleProp,
    StyleSheet,
    ViewStyle,
} from "react-native";

import { NavigationProp, useNavigation } from "@react-navigation/native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TooltipPlacement } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import { Badge } from "@minis-consumer/components/badge";
import { useChatStoreUnreadCount } from "@minis-consumer/hooks/use-chat-unread-count";
import { useShare } from "@minis-consumer/hooks/use-share";
import { RouteList } from "@minis-consumer/interfaces/route";
import { TimeBasedTooltipComponent } from "@minis-consumer/components/time-based-tooltip";
import { useSignedIn } from "@minis-consumer/hooks/use-user";

import {
    META_PIXEL_CONTENT_CATEGORY,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { COMMON_COPIES } from "@minis-consumer/constants";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";

import { COPIES, CtaType, CTA_ANALYTICS_EVENT_MAP } from "./constants";

export type IconContainerProps = Omit<PressableProps, "style"> & {
    style?: Animated.WithAnimatedValue<StyleProp<ViewStyle>>;
};

export const IconContainer: React.FC<React.PropsWithChildren<IconContainerProps>> = (props) => {
    const {
        accessible = true,
        accessibilityRole = "button",
        style,
        children,
        ...restProps
    } = props;

    return (
        <Pressable
            accessible={accessible}
            accessibilityRole={accessibilityRole}
            style={{ marginLeft: SpacingValue["space-x-small"] }}
            {...restProps}>
            <Animated.View style={[styles.iconContainer, style]}>{children}</Animated.View>
        </Pressable>
    );
};

const ICON_SIZE = 20;

interface HeaderRightProps {
    storeId: string;
    isStoreEnabled: boolean;
    color?: string;
    containerStyle?: IconContainerProps["style"];
    canShowChatIconText?: boolean;
    canShowChatIcon?: boolean;
    canShowShareIcon?: boolean;
    canShowAccountIcon?: boolean;
    isTooltipVisible?: boolean;
}

export const HeaderRight: React.FC<HeaderRightProps> = ({
    color,
    containerStyle,
    storeId,
    isStoreEnabled,
    canShowChatIconText,
    canShowChatIcon,
    canShowShareIcon,
    canShowAccountIcon,
    isTooltipVisible,
}) => {
    const navigation = useNavigation<NavigationProp<RouteList>>();
    const share = useShare();
    const chatUnreadCount = useChatStoreUnreadCount(storeId) ?? 0;
    const containerStyles = React.useMemo(() => {
        return canShowChatIconText ? { width: 86 } : {};
    }, [canShowChatIconText]);

    const signedIn = useSignedIn();
    const getAnalyticsData = React.useCallback(
        (ctaType: CtaType) => {
            return {
                category: CTA_ANALYTICS_EVENT_MAP[ctaType],
                label: storeId,
            };
        },
        [storeId],
    );

    const handlePress = React.useCallback(
        (area: CtaType): void => {
            switch (area) {
                case "search":
                    navigation.navigate("StoreCatalogSearch");
                    break;
                case "chat":
                    fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.CONTACT, {
                        content_category: META_PIXEL_CONTENT_CATEGORY.CHAT,
                    });
                    navigation.navigate("ChatConversation", { storeId });
                    break;
                case "share-store":
                    share();
                    break;
                case "account":
                    if (!signedIn) {
                        return navigation.navigate("Auth", { screen: "Login" });
                    }
                    navigation.navigate("Home", { screen: "MyAccount" });
                    break;
            }
            Analytics.clickEvent({ category: `header-${area}-btn` });
        },
        [navigation, storeId, share, signedIn],
    );

    const handleShareStore = React.useCallback(() => {
        handlePress("share-store");
    }, [handlePress]);

    const handleAccountPress = React.useCallback(() => {
        Analytics.clickEvent(getAnalyticsData("account"));

        handlePress("account");
    }, [handlePress, getAnalyticsData]);

    const connectWithSeller = React.useCallback(() => {
        handlePress("chat");
    }, [handlePress]);

    const handleSearch = React.useCallback(() => {
        Analytics.clickEvent(getAnalyticsData("search"));

        handlePress("search");
    }, [handlePress, getAnalyticsData]);

    useMount(() => {
        canShowAccountIcon && Analytics.impressionEvent(getAnalyticsData("account"));
        Analytics.impressionEvent(getAnalyticsData("search"));
    });

    if (!isStoreEnabled) {
        return null;
    }

    return (
        <Box
            direction="row"
            mr={SpacingValue["space-small"]}
            style={styles.iconsWrapper}
            testID="sf-header-right">
            {canShowChatIcon ? (
                <TimeBasedTooltipComponent
                    analytics={getAnalyticsData("chat")}
                    isTooltipVisible={isTooltipVisible}
                    tooltipContentTitle={COPIES.TOOLTIP_TITLE}
                    tooltipContentDescription={COPIES.TOOLTIP_DESCRIPTION}
                    icon="Chat"
                    tooltipStyle={styles.toolTip}
                    placement={TooltipPlacement.BOTTOM_END}
                    tooltipContentClickHandler={connectWithSeller}
                    pointerOffset={30}>
                    <IconContainer
                        accessibilityLabel="Chat"
                        onPress={connectWithSeller}
                        style={[containerStyle, containerStyles]}>
                        <Stack
                            direction="row"
                            spacing={SpacingValue["space-xx-small"]}
                            testID={TEST_IDS_SF.CHAT_CTA}>
                            <SvgIcon
                                icon="Chat"
                                color={color}
                                width={ICON_SIZE}
                                height={ICON_SIZE}
                            />
                            {canShowChatIconText ? (
                                <Text category="b1" weight="medium" color="color-basic-0">
                                    {COMMON_COPIES.CHAT}
                                </Text>
                            ) : null}
                        </Stack>
                        <Badge count={chatUnreadCount} style={styles.badgeCount} />
                    </IconContainer>
                </TimeBasedTooltipComponent>
            ) : null}

            <IconContainer
                accessibilityLabel="Search"
                onPress={handleSearch}
                style={containerStyle}
                testID={TEST_IDS_SF.SEARCH_CTA}>
                <SvgIcon icon="Magnifier" color={color} width={ICON_SIZE} height={ICON_SIZE} />
            </IconContainer>

            {canShowShareIcon ? (
                <IconContainer
                    accessibilityLabel="Share"
                    onPress={handleShareStore}
                    style={containerStyle}
                    testID={TEST_IDS_SF.SHARE_CTA}>
                    <SvgIcon icon="Share" color={color} width={ICON_SIZE} height={ICON_SIZE} />
                </IconContainer>
            ) : null}

            {canShowAccountIcon ? (
                <IconContainer
                    accessibilityLabel="Account"
                    onPress={handleAccountPress}
                    style={containerStyle}
                    testID={TEST_IDS_SF.MY_ACCOUNT}>
                    <SvgIcon
                        icon="AccountFilled"
                        color={color}
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                    />
                </IconContainer>
            ) : null}
        </Box>
    );
};

const styles = StyleSheet.create({
    iconContainer: {
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
    },
    iconsWrapper: {
        marginLeft: "auto",
    },
    badgeCount: {
        top: -SpacingValue["space-xx-small"],
        right: 0,
    },
    toolTip: {
        width: 250,
    },
});
