import * as React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { useSuspenseUser } from "@minis-consumer/hooks/use-user";

const IMAGE_SIZE = 64;

export const AccountUserDetails: React.FC = () => {
    const userData = useSuspenseUser();

    return (
        <Stack spacing={SpacingValue["space-medium"]} direction="row" alignItems="center">
            <AccountProfilePictureIcon userName={userData?.name ?? ""} />
            <Stack spacing={SpacingValue["space-x-small"]}>
                <Text category="b1" weight="bold" color="color-basic-75">
                    {userData?.name ?? ""}
                </Text>
                <Box>
                    <Text category="b1" weight="medium" color="color-basic-60">
                        {userData?.email ?? ""}
                    </Text>
                    <Text category="b1" weight="medium" color="color-basic-60">
                        {userData?.mobile ?? ""}
                    </Text>
                </Box>
            </Stack>
        </Stack>
    );
};

export const AccountProfilePictureIcon: React.FC<{ userName: string }> = ({ userName }) => {
    const { value: theme } = useTheme();

    const bgColor = theme["color-primary"];
    return (
        <Box
            justifyContent="center"
            alignItems="center"
            style={[styles.iconContainer, { backgroundColor: bgColor }]}>
            <Text category="h2" color="color-basic-0" style={styles.letterHeight}>
                {userName.length > 0 ? userName[0].toUpperCase() : "S"}
            </Text>
        </Box>
    );
};

const styles = StyleSheet.create({
    iconContainer: {
        width: IMAGE_SIZE,
        height: IMAGE_SIZE,
        borderRadius: 32,
        marginBottom: SpacingValue["space-x-small"],
    },
    letterHeight: { height: 28 },
});
