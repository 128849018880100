import React from "react";
import { Platform, ScrollView, StyleSheet, useWindowDimensions } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useHeaderHeight } from "@react-navigation/elements";

import { SpacingValue } from "@swiggy-private/rn-dls";
import {
    Box,
    Display,
    ScreenSize,
    Stack,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";

import { ItemsLoader } from "./components/items";
import { BillLoader } from "./components/bill";
import { CancellationPolicyLoader } from "./components/cancellation-policy";
import { AddressLoader } from "./components/address";
import { PaymentLoader } from "./components/payment";
import { CouponLoader } from "./components/coupon";

const PlaceholderComponent: React.FC = () => {
    const contentPadding = useSelectScreen({
        lg: 0,
        default: SpacingValue["space-medium"],
    });

    const containerStyle = {
        flex: 1,
        paddingHorizontal: contentPadding,
        paddingTop: contentPadding,
    };

    return (
        <>
            <Display gt={ScreenSize.Large}>
                <Box style={containerStyle}>
                    <LargeScreenLayout />
                </Box>
            </Display>
            <Display lt={ScreenSize.Medium}>
                <SmallScreenLayout />
            </Display>
        </>
    );
};

const SmallScreenLayout: React.FC = () => {
    const headerHeight = useHeaderHeight();

    const { height: windowHeight } = useWindowDimensions();
    const insets = useSafeAreaInsets();

    const containerStyle = {
        // subtracting header height
        height: Platform.OS === "web" ? windowHeight - headerHeight : "100%",
    };

    const stackStyle = {
        paddingBottom: 200 + insets.bottom + SpacingValue["space-medium"],
    };

    return (
        <Box style={containerStyle}>
            <ScrollView style={styles.scrollStyle}>
                <Stack style={stackStyle} spacing={SpacingValue["space-medium"]}>
                    <ItemsLoader />
                    <CouponLoader />
                    <BillLoader />
                    <CancellationPolicyLoader />
                </Stack>
            </ScrollView>
            <AddressLoader />
        </Box>
    );
};

const LargeScreenLayout: React.FC = () => {
    return (
        <>
            <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                <Box flex={2}>
                    <Stack spacing={SpacingValue["space-medium"]}>
                        <ItemsLoader />
                        <AddressLoader />
                        <PaymentLoader />
                    </Stack>
                </Box>
                <Box flex={1}>
                    <Stack spacing={SpacingValue["space-medium"]}>
                        <BillLoader />
                        <CouponLoader />
                        <CancellationPolicyLoader />
                    </Stack>
                </Box>
            </Stack>
        </>
    );
};

const styles = StyleSheet.create({
    scrollStyle: {
        paddingTop: SpacingValue["space-medium"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
});

export const Placeholder = React.memo(PlaceholderComponent);
