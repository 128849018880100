import React from "react";
import { StyleSheet, Pressable } from "react-native";

import { Text } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";

import { ClearCartConfirmationDialog } from "../clear-cart-confirmation-dialog";
import { ANALYTICS_COPIES, COPIES } from "../../constants";

interface Props {
    showClearAllCartsCta: boolean;
    onBulkDelete: () => Promise<void>;
    isLoading: boolean;
}

const HeaderRightComponent: React.FC<Props> = ({
    showClearAllCartsCta,
    isLoading,
    onBulkDelete,
}) => {
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = React.useState(false);

    const clearCartHandler = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.CLEAR_ALL_CART_BTN,
        });

        setShowDeleteConfirmationModal(true);
    }, []);

    const hideConfirmation = React.useCallback(() => setShowDeleteConfirmationModal(false), []);

    const onDelete = React.useCallback(async () => {
        await onBulkDelete();
        hideConfirmation();
    }, [hideConfirmation, onBulkDelete]);

    if (!showClearAllCartsCta) {
        return null;
    }

    return (
        <>
            <Pressable onPress={clearCartHandler} style={styles.container}>
                <Text style={styles.button} category="b3" weight="regular" color="color-basic-45">
                    {COPIES.CLEAR_CART_TEXT}
                    <Text category="b3" weight="bold" style={styles.clearCartText}>
                        {COPIES.CLEAR_CART_BTN_TEXT}
                    </Text>
                </Text>
            </Pressable>
            <ClearCartConfirmationDialog
                canShowModal={showDeleteConfirmationModal}
                onCloseModal={hideConfirmation}
                title={COPIES.CART_CLEAR_CONFIRMATION_TITLE}
                description={COPIES.CART_CLEAR_CONFIRMATION_DESCRIPTION}
                continueBtnText={COPIES.CLEAR_ALL_CART_CONFIRMATION_TEXT}
                cancelBtnText={COPIES.CART_CLEAR_CONFIRMATION_CANCEL_BTN_TEXT}
                onPressProceed={onDelete}
                isLoading={isLoading}
            />
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingBottom: 100,
    },
    clearCartText: {
        color: "#CE4736",
    },
    button: {
        textAlign: "center",
    },
});
export const HeaderRight = React.memo(HeaderRightComponent);
