import React from "react";

import { MORE_PAYMENT_METHOD } from "@minis-consumer/routes/payment/constants";

import { MethodCard } from "../../method-card";
import { MethodList } from "../../method-list";

export const OtherPaymentGroup: React.FC = () => {
    return (
        <MethodCard hasBGCard={false} title={MORE_PAYMENT_METHOD}>
            <MethodList />
        </MethodCard>
    );
};
