import { SvgIconType } from "@swiggy-private/connect-svg-icons";

import { StoreLink } from "@minis-consumer/api/store";
import { ReturnSettingsUnit } from "@minis-consumer/interfaces/store";

export const SF_COPIES = { LOVE_THIS: "Love this" };

export const HEADER_HEIGHT = 64;
export const NAV_BAR_HEIGHT_DEFAULT = 50;
export const DESCRIPTION_MAX_LENGTH = 70;
export const TOOLTIP_DEFAULT_VALUE = {
    KEY: "chat-icon-tooltip",
    VALUE: false,
    START_TIME: 2000,
    DISMISS_AFTER: 8000,
    LIFETIME_DISPLAY_COUNT: 3,
    SESSION_DISPLAY_COUNT: 1,
};

export const SF_ANALYTICS_EVENT_NAMES = {
    CHAT_FBA: "chat-fba",
    OND_ACCORDION: "order-and-delivery-accordian",
    RP_ACCORDION: "return-policy-accordian",
    CP_ACCORDION: "cancellation-policy-accordian",
    SI_ACCORDION: "store-info-accordian",
    DELIVER_INFO: "delivery-info",
    CART_FOOTER: "cart-footer",
    DI_TOAST: "delivery-info-toast",
    ACCOUNT_CTA: "my-account-btn",
    SEARCH_CTA: "search-storefront",
    CHAT_CTA: "chat-btn",
    BACK: "back-btn",
    LOVE_STORE_CTA: "love-this-store",
    SHARE_STORE_CTA: "share-store",
    PERF_METRICS: "store-representation-widget",
    SOCIAL_METRICS: "store-external-metrics-widget",
    STORY_HIGHLIGHTS: "story-highlights",
    STORE_TAB: "store-tab",
    LINKS_TAB: "links-tab",
    BADGE_WIDGET_SUFFIX: "badge-items-widget",
    PRODUCT_CARD: "product-card",
    PRODUCT_CARD_ADD: "product-card-add",
    CATALOG_WIDGET_ACCORDION: "catalog-widget-accordion",
    INSTAGRAM_WIDGET: "instagram-widget",
    INSTAGRAM_WIDGET_MORE_PHOTOS: "instagram-widget-more-photos",
    START_A_CHAT_CTA: "start-a-chat-btn",
    MY_LINKS_WIDGET: "my-links-widget",
    CATEGORY_DROPDOWN_OPTION_CLICK: "categories-fba-dropdown",
};
export const STORE_POLICY = {
    NOT_ALLOWED: "Not allowed",
};

export const UNIT_OPTIONS_MAP: {
    [key in ReturnSettingsUnit]: string;
} = {
    DAYS: "days",
    HOURS: "Hrs",
};

export const UNIT_OPTIONS: ReturnSettingsUnit[] = ["DAYS", "HOURS"];

export const TYPE_ICON_MAP: Record<StoreLink["type"], SvgIconType["icon"]> = {
    YOUTUBE: "Youtube",
    WHATSAPP: "WhatsappNew",
    CALENDAR: "CalendarNew",
    INSTAGRAM: "Instagram",
    FORM: "GoogleForms",
};

export type TABS = "catalog" | "links";
