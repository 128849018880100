import * as React from "react";
import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    Pressable,
    ScrollViewProps,
    StyleSheet,
} from "react-native";

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useSuspense } from "@rest-hooks/react";

import { StarSellerBadge, useStarSeller } from "@swiggy-private/connect-business-commons";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { CdnImage, InView, IOScrollView } from "@swiggy-private/react-native-ui";
import { Box, Container, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { FollowedStoreResponseDataItem } from "@minis-consumer/api/store";
import { Divider } from "@minis-consumer/components/divider";
import { getStoreBrandImageUrl } from "@minis-consumer/helpers/store";
import { MinisRouteList } from "@minis-consumer/interfaces/route";
import { FollowedStores } from "@minis-consumer/resources/store";

import { FollowedStoresEmptyState } from "./empty-state";
import { UnfollowStoreDialog } from "./unfollow-store-dialog";

const IMAGE_SIZE = 48;

export const FollowedStoresContent: React.FC = () => {
    const followedStores = useSuspense(FollowedStores);

    const { value: theme } = useTheme();
    const bgPrimaryColor = theme["color-background-primary"];
    const bgSecondaryColor = theme["color-background-secondary"];

    const containerColor = { backgroundColor: bgPrimaryColor };

    const scrollStyle = {
        backgroundColor: bgSecondaryColor,
    };
    const ref = React.useRef<{ onScroll: ScrollViewProps["onScroll"] }>(null);

    const onScroll = React.useCallback((e: NativeSyntheticEvent<NativeScrollEvent>) => {
        ref.current?.onScroll?.(e);
    }, []);

    const containerStyle = useSelectScreen({
        lg: {
            padding: SpacingValue["space-x-large"],
            marginVertical: SpacingValue["space-xx-large"],
        },
        default: {
            padding: SpacingValue["space-medium"],
            marginVertical: SpacingValue["space-medium"],
        },
    });

    if (followedStores.stores.length === 0) {
        return <FollowedStoresEmptyState />;
    }

    return (
        <IOScrollView style={scrollStyle} onScroll={onScroll} scrollEventThrottle={16}>
            <Container fluid={false}>
                <Box
                    direction="column"
                    style={[
                        containerStyle,
                        styles.contentBorderRadius,
                        containerColor,
                        { paddingVertical: SpacingValue["space-x-large"] },
                    ]}
                    flex={1}>
                    {followedStores.stores.map((store, index) => {
                        return (
                            <FollowingListItem
                                key={store.storeId}
                                data={store}
                                index={index}
                                isDividerPresent={index !== followedStores.stores.length - 1}
                            />
                        );
                    })}
                </Box>
            </Container>
        </IOScrollView>
    );
};

const FollowingListItem: React.FC<{
    data: FollowedStoreResponseDataItem;
    index: number;
    isDividerPresent?: boolean;
}> = ({ isDividerPresent = true, data, index }) => {
    const isStarSeller = useStarSeller(data.badges);

    const [showModal, setShowModal] = React.useState(false);

    const handleFollowing = React.useCallback(() => {
        setShowModal(true);
    }, []);

    const closeModal = React.useCallback(() => {
        setShowModal(false);
    }, []);

    const storeCategories = React.useMemo(() => data?.categories?.join(", "), [data.categories]);

    const brandImageUrl = data.brandImageId
        ? getStoreBrandImageUrl(
              data.brandImageId ?? "minis/store_following_placeholder",
              {
                  width: IMAGE_SIZE,
                  height: IMAGE_SIZE,
              },
              true,
          )
        : null;

    const navigation = useNavigation<NativeStackNavigationProp<MinisRouteList>>();

    const onImpression = React.useCallback(
        (isVisible: boolean) => {
            if (isVisible) {
                Analytics.impressionEvent({
                    category: data?.slug,
                    value: index,
                });
            }
        },
        [data?.slug, index],
    );

    const onPressHandler = React.useCallback(() => {
        Analytics.clickEvent({
            category: data?.slug,
            value: index,
        });

        navigation.navigate("MiniStore", { slug: data?.slug });
    }, [navigation, data?.slug, index]);

    return (
        <Pressable onPress={onPressHandler}>
            <InView onChange={onImpression}>
                <Box direction="row" justifyContent="space-between" alignItems="center">
                    <Box style={styles.imageContainer}>
                        <CdnImage
                            isImageKitEnabled
                            id={brandImageUrl ? brandImageUrl : "minis/store_following_placeholder"}
                            style={styles.imageContainer}
                        />
                    </Box>

                    <Box
                        flex={1}
                        justifyContent="space-between"
                        direction="row"
                        alignItems="center">
                        <Box flex={1}>
                            <Box direction="row" alignItems="center">
                                <Text
                                    ellipsizeMode="tail"
                                    numberOfLines={1}
                                    category="b1"
                                    weight="bold"
                                    color="color-basic-75"
                                    style={styles.storeName}>
                                    {data.storeName}
                                </Text>
                                {isStarSeller ? (
                                    <StarSellerBadge
                                        outerGradientStyle={styles.badgeGradient}
                                        rootContainerStyle={{
                                            padding: SpacingValue["space-xxx-small"],
                                        }}
                                    />
                                ) : null}
                            </Box>
                            <Text category="b3" color="color-basic-60" numberOfLines={1}>
                                {storeCategories}
                            </Text>
                        </Box>
                        <Button color="secondary" onPress={handleFollowing} style={styles.heartBtn}>
                            <SvgIcon
                                icon="HeartFilled"
                                height={16}
                                width={16}
                                color="color-primary-400"
                            />
                        </Button>
                    </Box>
                </Box>
            </InView>
            {isDividerPresent ? (
                <Divider style={{ marginVertical: SpacingValue["space-x-large"] }} />
            ) : null}

            <UnfollowStoreDialog
                showModal={showModal}
                closeModal={closeModal}
                storeId={data.storeId}
                storeName={data.storeName}
            />
        </Pressable>
    );
};

const styles = StyleSheet.create({
    contentBorderRadius: { borderRadius: 16 },
    imageContainer: {
        width: IMAGE_SIZE,
        height: IMAGE_SIZE,
        borderRadius: 12,
        marginRight: SpacingValue["space-medium"],
    },
    badgeGradient: {
        padding: 0,
        marginLeft: SpacingValue["space-xxx-small"],
    },
    storeName: {
        flexShrink: 1,
        paddingBottom: SpacingValue["space-xx-small"],
    },
    heartBtn: {
        marginLeft: SpacingValue["space-medium"],
    },
});
