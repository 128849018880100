import React from "react";
import { ViewStyle } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { RouteList } from "@minis-consumer/interfaces/route";

import { useNavigationBack } from "@minis-consumer/hooks/use-navigation-back";

import { LABELS } from "../../constants";
import { useGetPdpCtaNavigation } from "@minis-consumer/hooks/use-pdp-cta-navigation";

type HeaderLeftComponentProps = {
    productId: string;
    containerStyle?: ViewStyle;
    hideBack?: boolean;
    subHeaderText?: string;
};

const HeaderLeftComponent: React.FC<HeaderLeftComponentProps> = ({
    productId,
    containerStyle,
    hideBack,
    subHeaderText,
}) => {
    const storeNavigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const { resetProductSelectedData } =
        useGetPdpCtaNavigation({
            productId,
            minisNavigation: storeNavigation,
        }) || {};

    const goBack = useNavigationBack();

    const onBackPress = React.useCallback(() => {
        resetProductSelectedData?.();
        goBack();
    }, [resetProductSelectedData, goBack]);

    return (
        <Stack
            style={[
                containerStyle,
                {
                    paddingHorizontal: SpacingValue["space-medium"],
                    paddingBottom: SpacingValue["space-xx-small"],
                },
            ]}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={SpacingValue["space-medium"]}>
            {!hideBack ? (
                <SvgIcon
                    icon="ArrowLeft"
                    onPress={onBackPress}
                    height={20}
                    width={20}
                    color="color-secondary-400"
                    hitSlop={{
                        left: 16,
                        right: 16,
                        top: 16,
                        bottom: 16,
                    }}
                />
            ) : null}
            <Stack spacing={SpacingValue["space-xxx-small"]}>
                <Text category="b1" weight="bold" color="highest" selectable>
                    {LABELS.HEADER}
                </Text>
                {subHeaderText ? (
                    <Text category="b3" weight="regular" color="medium" selectable>
                        {subHeaderText}
                    </Text>
                ) : null}
            </Stack>
        </Stack>
    );
};

export const HeaderLeft = React.memo(HeaderLeftComponent);
