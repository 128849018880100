import * as React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import {
    PerfMetricsWidget,
    PerfMetricsWidgetProps,
} from "@swiggy-private/connect-business-commons";
import { InView } from "@swiggy-private/react-native-ui";

import { Store } from "@minis-consumer/interfaces/store";
import { generatePerfWidgetsData } from "@minis-consumer/routes/shop/helpers/perf-metrics";
import { Analytics } from "@minis-consumer/analytics";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";

export const PerfMetricsComponent: React.FC<{
    performanceMetrics: Store["performanceMetrics"];
}> = ({ performanceMetrics }) => {
    const { value: theme } = useTheme();

    const perfWidgetData = React.useMemo(
        () => (performanceMetrics ? generatePerfWidgetsData(performanceMetrics) : []),
        [performanceMetrics],
    );

    const ItemSeparator: React.FC = () => (
        <Box
            style={[
                styles.itemSeparator,
                {
                    borderColor: theme["color-basic-30"],
                },
            ]}
        />
    );

    const containerStyle = {
        flexGrow: 1,
    };

    const dataWithSeparators = React.useMemo(() => {
        const dataWithSeparator: Array<PerfMetricsWidgetProps | null> = [];

        perfWidgetData.forEach((item, index) => {
            dataWithSeparator.push(item);
            if (index !== perfWidgetData.length - 1) {
                dataWithSeparator.push(null);
            }
        });

        return dataWithSeparator;
    }, [perfWidgetData]);

    const onImpression = React.useCallback(
        (isVisible: boolean) => {
            if (!isVisible) {
                return;
            }

            const { rating, orders, followers } = performanceMetrics ?? {};

            Analytics.impressionEvent({
                category: SF_ANALYTICS_EVENT_NAMES.PERF_METRICS,
                context: JSON.stringify({
                    rating,
                    orders,
                    followers,
                }),
            });
        },
        [performanceMetrics],
    );

    if (!perfWidgetData.length) {
        return null;
    }

    return (
        <InView onChange={onImpression}>
            <Box style={containerStyle} direction="row" justifyContent="space-between">
                {dataWithSeparators?.map((item, index) =>
                    item ? (
                        <PerfMetricsWidget
                            {...item}
                            key={`metric_${index}`}
                            textProps={{ color: "text_Med_Emphasis", weight: "bold" }}
                        />
                    ) : (
                        <ItemSeparator key={`separator_${index}`} />
                    ),
                )}
            </Box>
        </InView>
    );
};

export const PerfMetrics = React.memo(PerfMetricsComponent);

PerfMetrics.displayName = "PerfMetrics";

const styles = StyleSheet.create({
    itemSeparator: {
        borderRightWidth: 1,
        marginHorizontal: SpacingValue["space-x-small"],
    },
    contentContainerStyle: {
        justifyContent: "space-around",
        backgroundColor: "pink",
        flexGrow: 1,
    },
});
