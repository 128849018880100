import { DeliveryInfoParams } from "@minis-consumer/api/delivery";
import { SelectedAddressInfo } from "@minis-consumer/contexts/selected-address-context";
import { Cart, CartItem } from "@minis-consumer/interfaces/cart";
import { OrderAddress } from "@minis-consumer/interfaces/order";
import { StoreAddress } from "@minis-consumer/interfaces/store";
import { PRODUCT_TYPE } from "@minis-consumer/interfaces/catalog";
import {
    CANCELLATION_POLICIES,
    BOOKING_CANCELLATION_POLICIES,
    PLAN_CANCELLATION_POLICIES,
    DIGITAL_CANCELLATION_POLICIES,
} from "@minis-consumer/constants";

import { CartErrorStatus } from "../components/cart-error/types";

export const getNonCustomItems = (items: CartItem[]): CartItem[] => {
    const nonCustomItems = items.filter(
        (item) => !item.metadata.custom && !item?.item?.attributesChanged?.length,
    );
    return nonCustomItems || [];
};

export const getCustomItems = (items: CartItem[]): CartItem[] => {
    const customItems = items.filter(
        (item) => item.metadata.custom && !item?.item?.attributesChanged?.length,
    );
    return customItems || [];
};

const allItemsUnavailableCheck = (items?: CartItem[]): boolean => {
    if (!items) {
        return false;
    }

    const customItems = getCustomItems(items);
    const nonCustomItems = getNonCustomItems(items);

    const customItemsAvailable = customItems.filter(
        (data) => data.item.active && data.item.inStock,
    );

    const nonCustomItemsAvailable = nonCustomItems.filter(
        (data) => data.item.active && data.item.inStock,
    );

    if (!customItemsAvailable.length && !nonCustomItemsAvailable.length) {
        return true;
    }

    return false;
};

export const getCartErrorStatus = (
    items?: CartItem[],
    cartStatus?: Cart["cartStatus"],
): CartErrorStatus | null => {
    if (!items && !cartStatus) {
        return null;
    }

    switch (cartStatus) {
        case "STORE_INACTIVE":
        case "STORE_OFFLINE":
            return "STORE_OFFLINE";

        case "ITEMS_NOT_FOUND_IN_CATALOGUE":
        case "ITEMS_INACTIVE":
        case "ITEMS_OUT_OF_STOCK":
        case "CUSTOM_ITEMS_INVALID_QUANTITY":
            /** if all items are unavilable and there is no
             *  custom item return different status */
            if (allItemsUnavailableCheck(items)) {
                return "ALL_ITEM_UNAVAILABLE";
            }

            return "ITEM_UNAVAILABLE";

        case "GUEST_CART":
            return "GUEST_CART";

        default:
            return null;
    }
};

export const isCustomItemOlderThanOneDay = (createdAt: number): boolean => {
    const timeDiff = Date.now() - new Date(createdAt * 1000).getTime();
    return timeDiff >= 86400;
};

export const isCartOrStoreNotFound = (cartStatus: Cart["cartStatus"]): boolean => {
    if (!cartStatus) {
        return false;
    }

    switch (cartStatus) {
        case "STORE_NOT_FOUND":
        case "STORE_MOBILE_NUMBER_NOT_FOUND":
        case "STORE_ADDRESS_NOT_FOUND":
        case "CUSTOM_CART_NOT_FOUND":
        case "CART_NOT_FOUND":
        case "STORE_INACTIVE":
            return true;

        default:
            return false;
    }
};

export const isStoreConfigNotFound = (cartStatus: Cart["cartStatus"]): boolean => {
    if (!cartStatus) {
        return false;
    }

    switch (cartStatus) {
        case "STORE_DELIVERY_CONFIG_NOT_FOUND":
            return true;

        default:
            return false;
    }
};

export const isCartErrorUnknown = (cartStatus: Cart["cartStatus"]): boolean => {
    if (!cartStatus) {
        return false;
    }

    switch (cartStatus) {
        case "INVALID_CUSTOM_CART":
        case "UNKNOWN":
            return true;

        default:
            return false;
    }
};

export const isGuestCart = (cartStatus: Cart["cartStatus"]): boolean => {
    if (!cartStatus) {
        return false;
    }

    return cartStatus === "GUEST_CART";
};

export const hidePayment = (items?: CartItem[], cartStatus?: Cart["cartStatus"]): boolean => {
    if (!cartStatus || !items) {
        return false;
    }

    if (cartStatus === "NO_USER_ADDRESS_FOUND" || getCartErrorStatus(items, cartStatus)) {
        return true;
    }

    return false;
};

export const getDeliveryEtaApiParams = ({
    storeId,
    selectedAddress,
    pickupAddress,
    dropPincode,
}: {
    storeId: string;
    selectedAddress?: SelectedAddressInfo;
    pickupAddress?: StoreAddress | OrderAddress;
    dropPincode?: string;
}): DeliveryInfoParams => {
    return {
        storeId: storeId,
        dropLat: Number(selectedAddress?.lat),
        dropLng: Number(selectedAddress?.lng),
        dropPincode: dropPincode ?? selectedAddress?.postalCode?.toString() ?? "",
        pickupLat: Number(pickupAddress?.lat),
        pickupLng: Number(pickupAddress?.lng),
        pickupPincode: pickupAddress?.postalCode?.toString() ?? "",
    };
};

export const trimmedProductNames = (cartItems: CartItem[], charLimit = 30): string | null => {
    const firstItemName = cartItems[0]?.item?.name;
    if (!firstItemName) {
        return null;
    }
    return (
        (firstItemName.length >= charLimit
            ? firstItemName?.substring(0, charLimit) + "..."
            : firstItemName) +
        (cartItems.length > 1 ? " & " + cartItems.length + " more items" : "")
    );
};

export const countItemNudges = (cartItems: CartItem[]): number => {
    return cartItems.reduce((totalCount, val) => totalCount + val.quantity, 0);
};

export const getChangedItems = (items: CartItem[]): CartItem[] => {
    const changedItems = items.filter((item) => !!item?.item?.attributesChanged?.length);
    return changedItems || [];
};

export const getUnchangedItems = (items: CartItem[]): CartItem[] => {
    const unchangedItems = items.filter((item) => !item?.item?.attributesChanged?.length);
    return unchangedItems || [];
};

export const getCancellationPolicies = (cartType: PRODUCT_TYPE): string[] => {
    switch (cartType) {
        case "APPOINTMENT":
            return BOOKING_CANCELLATION_POLICIES;
        case "PLAN":
            return PLAN_CANCELLATION_POLICIES;
        case "DIGITAL":
            return DIGITAL_CANCELLATION_POLICIES;
        case "PHYSICAL":
        default:
            return CANCELLATION_POLICIES;
    }
};
