import React, { memo } from "react";

import { Text } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

import { Product } from "@minis-consumer/interfaces/catalog";

/** To be used for Digital type products */
const ProductDigitalAssetInfoComponent: React.FC<Partial<Product>> = ({
    digitalAssetInfo,
    name,
}) => {
    const { type, extension } = digitalAssetInfo ?? {};

    if (!type) {
        return null;
    }

    const documentType = extension ?? type;

    return (
        <Stack direction="row">
            <Text category="b2" color="medium" weight="regular">
                You will receive a{" "}
                <Text category="b2" color="high" weight="bold">
                    {documentType}
                </Text>
                {` of ${name} once your payment is done`}
            </Text>
        </Stack>
    );
};

export const ProductDigitalAssetInfo = memo(ProductDigitalAssetInfoComponent);
