import { CartErrorData } from "../types";

export const CART_ERROR: CartErrorData = {
    STORE_OFFLINE: {
        title: "Uh-oh! Store is offline",
        subtitle: "Please check back later",
        ctaText: "Store not accepting orders",
        emoji: "sad_emoji",
    },
    ITEM_UNAVAILABLE: {
        title: "Some products are unavailable",
        subtitle: "Remove unavailable products to proceed to select delivery address",
        ctaText: "Remove unavailable products",
    },
    ALL_ITEM_UNAVAILABLE: {
        title: "All products are unavailable",
        subtitle: "Oops...! Products in your cart are no longer available. ",
        ctaText: "Clear cart",
        ctaTextColor: "color-critical-300",
        ctaBorderColor: "color-critical-300",
        ctaBackgroundColor: "color-basic-0",
    },
    GUEST_CART: {
        title: "Almost there",
        subtitle: "Enter login details or create an account",
        ctaText: "Proceed with Phone Number",
        ctaTextColor: "color-basic-0",
        ctaBorderColor: "color-primary-400",
        ctaBackgroundColor: "color-primary-400",
    },
};
