import React, { useContext, useMemo } from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Dimensions, Platform, StyleSheet } from "react-native";

import { Portal, SpacingValue } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import {
    useCartStatus,
    useCartViewData,
    useCelebrationModal,
    useSelectedCartAddress,
    useUpdateCartAddress,
    useUpdateCartAppliedCoupon,
    useUpdateItemInLocalCart,
} from "@minis-consumer/hooks/use-cart";
import { UserAddress } from "@minis-consumer/interfaces/cart";
import { getCartErrorStatus, getChangedItems } from "@minis-consumer/routes/cart/helpers";
import { HomeRouteList } from "@minis-consumer/interfaces/route";
import { ADDRESS_LAT_LNG_ID } from "@minis-consumer/constants";
import { OverlayLoader } from "@minis-consumer/components/overlay-loader";
import { Analytics } from "@minis-consumer/analytics";
import { useIsEmailToBeAdded } from "@minis-consumer/routes/cart/hooks/use-is-email-added";
import { CouponErrorSnackbar } from "@minis-consumer/components/coupon-error-snackbar";
import { useCouponInvalidHandler } from "@minis-consumer/hooks/use-coupon-invalid-handler";
import { COUPON_ERROR_SNACKBAR_CONSTANTS } from "@minis-consumer/components/coupon-error-snackbar/constants";
import { SelectedAddressInfoContext } from "@minis-consumer/contexts/selected-address-context";
import { useChatBlockedErrorSnackbarHandler } from "@minis-consumer/hooks/use-chat-blocked-snack-handler";
import { getCancellationPolicies } from "@minis-consumer/routes/cart/helpers";
import { useInvalidAppointmentHandler } from "@minis-consumer/hooks/use-invalid-appointment-handler";
import { AppointmentErrorSnackbar } from "@minis-consumer/components/appointment-error-snackbar";

import { CartItemList } from "../../item-list";
import { Bill } from "../../bill";
import { CancellationPolicy } from "../../cancellation-policy";
import { AddressList } from "../../address-list";
import { Payment } from "../../payment";
import { CartAlert } from "../../cart-alert";
import { CartError } from "../../cart-error";
import { PreCheckoutDialog } from "../../pre-checkout-dialog";
import { CouponCard } from "../../coupon";
import { PreCheckoutAddEmailDialog } from "../../pre-checkout-add-email-dialog";
import { GuestCheckoutDesktop } from "../../guest-checkout-desktop";
import { ChangedItems } from "../../changed-items";
import { CartInstructions } from "../../item-list/components/instructions";
import { CART_V2_ERRORS_STATUS } from "../../item-list/constants";

interface LargeScreenLayoutProps {
    storeId: string;
    canShowPreCheckoutDialog: boolean;

    cartSyncing?: boolean;
    errorMessage?: string;
}

const isWeb = Platform.OS === "web";

const LargeScreenLayoutComponent: React.FC<LargeScreenLayoutProps> = ({
    storeId,
    cartSyncing,
    errorMessage,
    canShowPreCheckoutDialog,
}) => {
    const cartStatus = useCartStatus(storeId);
    const cart = useCartViewData(storeId);
    const isPhysicalCart = useMemo(() => cart?.cartType === "PHYSICAL" || !cart?.cartType, [cart]);

    const localCartAddress = useSelectedCartAddress(storeId);
    const navigation = useNavigation<NativeStackNavigationProp<HomeRouteList, "Cart">>();
    const { activateOrDeactivateCelebrationModal } = useCelebrationModal(storeId);
    const applyCouponHook = useUpdateCartAppliedCoupon(storeId);
    const isEmailToBeAdded = useIsEmailToBeAdded(cart);
    const { canShowSnackbar, dismissSnackbar, errorText } = useCouponInvalidHandler({
        checkoutErrorMsg: errorMessage,
        cart,
    });
    const {
        canShowSnackbar: canShowSnackbarForAppointment,
        dismissSnackbar: dismissSnackbarForAppointment,
        errorText: appointmentErrorText,
    } = useInvalidAppointmentHandler({
        checkoutErrorMsg: errorMessage,
        cart,
    });
    const updateItemInCart = useUpdateItemInLocalCart(storeId);

    const { dataHandler } = useChatBlockedErrorSnackbarHandler();

    const selectedAddressCtx = useContext(SelectedAddressInfoContext);
    const selectedAddressId = selectedAddressCtx?.selectedAddressInfo?.addressId;

    // TODO: Check if this logic exists in a hook or create one
    const addressList: UserAddress[] = useMemo(() => {
        return (
            cart?.addresses.filter((address: UserAddress) => address.id !== ADDRESS_LAT_LNG_ID) ||
            []
        );
    }, [cart?.addresses]);

    const [showPreCheckoutDialog, setShowPreCheckoutDialog] = React.useState(false);
    const [showPreCheckoutAddEmailDialog, setShowPreCheckoutAddEmailDialog] = React.useState(false);
    const [userSelectedAddress, setUserSelectedAddress] = React.useState<UserAddress | null>(
        localCartAddress,
    );

    const cartErrorStatus = React.useMemo(
        () => getCartErrorStatus(cart?.cartItems, cartStatus),
        [cart?.cartItems, cartStatus],
    );

    const updateAddress = useUpdateCartAddress(storeId);

    const onSelectAddress = React.useCallback(
        (address: UserAddress | null) => {
            if (address) {
                setUserSelectedAddress(address);
                updateAddress(address.id);
            } else {
                setUserSelectedAddress(null);
            }
        },
        [updateAddress],
    );

    const getAddressById = React.useCallback(
        (addresses: UserAddress[], addressId?: string): UserAddress | null => {
            if (!addressId || !addresses?.length) {
                return null;
            }

            const addressById = addresses?.find((address: UserAddress) => address.id === addressId);
            return addressById ?? null;
        },
        [],
    );

    const navigateToPayment = React.useCallback(
        () =>
            navigation.getParent()?.navigate("Payment", {
                screen: "PaymentHome",
                params: { cartId: cart?.id },
            }),
        [navigation, cart?.id],
    );

    const hidePreCheckoutDialog = React.useCallback(() => {
        setShowPreCheckoutDialog(false);
    }, []);

    const hidePreCheckoutAddEmailDialog = React.useCallback(() => {
        setShowPreCheckoutAddEmailDialog(false);
    }, []);

    const onPressProceed = React.useCallback(() => {
        hidePreCheckoutDialog();
        navigateToPayment();
    }, [hidePreCheckoutDialog, navigateToPayment]);

    useMount(() => {
        const address = getAddressById(addressList, selectedAddressId);

        onSelectAddress(address);
    });

    const fireCouponEvent = React.useCallback(
        (eventName: string) => {
            Analytics.clickEvent({
                category: eventName,
                label: `cartId: ${cart?.id}`,
                context: `couponCode: ${cart?.bestCoupon?.coupon.code}`,
            });
        },
        [cart?.id, cart?.bestCoupon?.coupon.code],
    );

    const onApply = React.useCallback(() => {
        fireCouponEvent("apply-coupon-btn");

        activateOrDeactivateCelebrationModal(true);
        applyCouponHook(cart?.bestCoupon?.coupon.code);
    }, [
        cart?.bestCoupon?.coupon.code,
        applyCouponHook,
        fireCouponEvent,
        activateOrDeactivateCelebrationModal,
    ]);

    const onRemove = React.useCallback(() => {
        fireCouponEvent("remove-coupon-btn");

        applyCouponHook();
    }, [applyCouponHook, fireCouponEvent]);

    const onViewAllCoupons = React.useCallback(() => {
        fireCouponEvent("view-coupons-btn");

        // navigate to coupons screen
        navigation.getParent()?.navigate("Coupons", {
            cartId: cart?.id,
        });
    }, [cart?.id, navigation, fireCouponEvent]);

    const onClickPayment = React.useCallback(() => {
        if (isEmailToBeAdded) {
            setShowPreCheckoutAddEmailDialog(true);
        } else if (canShowPreCheckoutDialog && isPhysicalCart) {
            setShowPreCheckoutDialog(true);
        } else {
            navigateToPayment();
        }
    }, [canShowPreCheckoutDialog, isPhysicalCart, isEmailToBeAdded, navigateToPayment]);

    const checkIsChatBlocked = React.useCallback(
        (blockedInfo: SdkConversation["blockedInfo"]): boolean => {
            if (blockedInfo?.blockedBy?.length) {
                dataHandler(true, blockedInfo, styles.snackbar);
                return false;
            }

            return true;
        },
        [dataHandler],
    );

    const changedItems = useMemo(
        () => (cart?.cartItems ? getChangedItems(cart.cartItems) : []),
        [cart?.cartItems],
    );

    const cancellationPolicy = useMemo(() => {
        return getCancellationPolicies(cart?.cartType ?? "PHYSICAL");
    }, [cart?.cartType]);

    const removeChangedItems = React.useCallback(() => {
        changedItems.forEach(({ item, metadata }) => {
            updateItemInCart({
                itemId: item.id,
                quantity: 0,
                custom: metadata.custom,
                variantId: item.variantId,
            });
        });
    }, [changedItems, updateItemInCart]);

    // if there are no billable items in the cart, disable the proceed button
    // billable items are the ones that have not been changed while it was in the cart
    const isProceedDisabled = React.useMemo(
        () =>
            !cart?.bill?.items?.length ||
            (cartStatus && CART_V2_ERRORS_STATUS.indexOf(cartStatus) > -1),
        [cart?.bill?.items?.length, cartStatus],
    );

    const hideCartInstructions = React.useMemo(() => {
        return cartStatus && CART_V2_ERRORS_STATUS.indexOf(cartStatus) > -1;
    }, [cartStatus]);

    return (
        <Box>
            <ChangedItems changedItems={changedItems} removeChangedItems={removeChangedItems} />

            {cartStatus === "STORE_BLOCKED" ? (
                <Stack spacing={SpacingValue["space-medium"]}>
                    <CartItemList />
                    <CartAlert
                        cartStatus={cartStatus}
                        storeId={storeId}
                        checkIsChatBlocked={checkIsChatBlocked}
                    />
                </Stack>
            ) : (
                <>
                    <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                        <Box flex={2}>
                            <Stack spacing={SpacingValue["space-medium"]} flex={1}>
                                <CartItemList />

                                {hideCartInstructions ? (
                                    <CartAlert
                                        cartStatus={cartStatus}
                                        storeId={storeId}
                                        checkIsChatBlocked={checkIsChatBlocked}
                                    />
                                ) : (
                                    <CartInstructions />
                                )}

                                {!cartErrorStatus ? (
                                    isPhysicalCart ? (
                                        <AddressList
                                            storeId={storeId}
                                            setAddressSelected={onSelectAddress}
                                            addressSelected={userSelectedAddress}
                                        />
                                    ) : null
                                ) : cartErrorStatus === "GUEST_CART" && isWeb ? (
                                    <GuestCheckoutDesktop storeId={storeId} />
                                ) : (
                                    <CartError storeId={storeId} />
                                )}

                                {userSelectedAddress || !isPhysicalCart ? (
                                    <Payment
                                        cartStatus={cartStatus}
                                        storeId={storeId}
                                        onClickProceed={onClickPayment}
                                        disabled={isProceedDisabled}
                                    />
                                ) : null}
                            </Stack>
                        </Box>

                        <Stack spacing={SpacingValue["space-medium"]} flex={1}>
                            <Bill storeId={storeId} />

                            {cart?.couponsAvailable ? (
                                <CouponCard
                                    couponsAvailable={!cart?.couponsAvailable}
                                    bestCoupon={cart?.bestCoupon}
                                    appliedCoupon={cart?.appliedCoupon}
                                    onApply={onApply}
                                    onRemove={onRemove}
                                    onViewAllCoupons={onViewAllCoupons}
                                    cartId={cart?.id}
                                    storeId={storeId}
                                    cartSyncing={cartSyncing}
                                />
                            ) : null}

                            <CancellationPolicy policy={cancellationPolicy} />
                        </Stack>
                    </Stack>
                </>
            )}

            {canShowPreCheckoutDialog ? (
                <PreCheckoutDialog
                    showDialog={showPreCheckoutDialog}
                    closeDialog={hidePreCheckoutDialog}
                    onPressContinue={onPressProceed}
                    cartId={cart?.id || ""}
                    cartType={cart?.cartType}
                />
            ) : null}

            <PreCheckoutAddEmailDialog
                canShowDialog={showPreCheckoutAddEmailDialog}
                onCloseDialog={hidePreCheckoutAddEmailDialog}
                onPressContinue={onPressProceed}
            />

            <OverlayLoader visible={!!cartSyncing} />
            <Portal>
                <CouponErrorSnackbar
                    errorText={errorText}
                    canShowSnackbar={canShowSnackbar}
                    dismissSnackbar={dismissSnackbar}
                    extraStyle={styles.snackbar}
                />
                <AppointmentErrorSnackbar
                    errorText={appointmentErrorText}
                    canShowSnackbar={canShowSnackbarForAppointment}
                    dismissSnackbar={dismissSnackbarForAppointment}
                    ctaText="Choose"
                    extraStyle={styles.snackbar}
                    cart={cart}
                />
            </Portal>
        </Box>
    );
};

const styles = StyleSheet.create({
    snackbar: {
        marginLeft:
            (Dimensions.get("window").width - COUPON_ERROR_SNACKBAR_CONSTANTS.MAX_SNACKBAR_WIDTH) /
            2,
    },
});

export const LargeScreenLayout = React.memo(LargeScreenLayoutComponent);
