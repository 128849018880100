import React from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { Text, SpacingValue, Button, useTheme, TextProps } from "@swiggy-private/rn-dls";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

interface Props {
    onPressCancel: () => void;
    onPressRemove: () => void;
    storeName: string;
}

type TextStyleProps = Pick<TextProps, "category" | "weight">;

const ModalBodyComponent: React.FC<Props> = ({ onPressCancel, onPressRemove, storeName }) => {
    const { value: theme } = useTheme();

    const btnTertiary: ViewStyle = {
        backgroundColor: theme["color-positive-25"],
        borderColor: "transparent",
        flex: 1,
    };

    const btnCritical: ViewStyle = {
        flex: 1,
    };

    const btnTertiaryText = {
        color: theme["color-positive-500"],
    };

    const titleText = useSelectScreen<TextStyleProps>({
        default: { category: "h5" },
        lg: { category: "h4" },
    });

    const subtitleText = useSelectScreen<TextStyleProps>({
        default: { category: "b2" },
        lg: { category: "b1" },
    });

    const marginStyle = useSelectScreen({
        default: {
            marginTop: SpacingValue["space-medium"],
        },
        md: {
            marginTop: SpacingValue["space-x-large"],
        },
        lg: {
            marginTop: SpacingValue["space-x-large"],
        },
    });

    return (
        <Box>
            <Text category={titleText.category} weight="bold">
                {`Unfollow ${storeName}`}
            </Text>
            <Text category={subtitleText.category} color="medium" style={styles.mt8}>
                If you change your mind, you can always follow them later.
            </Text>

            <Stack direction="row" spacing={SpacingValue["space-small"]} style={marginStyle}>
                <Button
                    color="tertiary"
                    elevation={1}
                    style={btnTertiary}
                    textStyle={btnTertiaryText}
                    onPress={onPressCancel}>
                    Cancel
                </Button>
                <Button color="critical" elevation={1} style={btnCritical} onPress={onPressRemove}>
                    Unfollow
                </Button>
            </Stack>
        </Box>
    );
};

const styles = StyleSheet.create({
    mt8: {
        marginTop: SpacingValue["space-x-small"],
    },
});

export const ModalBody = React.memo(ModalBodyComponent);
