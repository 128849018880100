import { useRef } from "react";

import { displayAddressNameAndIcon } from "@minis-consumer/helpers/address";
import {
    useLocalCart,
    useSelectedCartAddress,
    useSelectedCartLocation,
} from "@minis-consumer/hooks/use-cart";

import { usePaymentOrder } from "./use-payment-order";
import { usePaymentStore } from "./use-payment-store";

type Address = {
    label: string;
    address: string;
    name?: string;
    phoneNumber?: string;
    email?: string;
};

export const usePaymentAddress = (): null | [Address, Address] => {
    const order = usePaymentOrder();
    const store = usePaymentStore();

    const cartSelectedAddress = useRef(useSelectedCartAddress(store?.storeId ?? "0")).current;
    const guestDetails = useRef(useLocalCart(store?.storeId ?? "0")).current?.guestDetails;

    if (!store) {
        return null;
    }

    if (order && (order.orderType === "PHYSICAL" || !order.orderType)) {
        const { addressTag: dropAddressLabel } = displayAddressNameAndIcon(order?.dropAddress);
        return [
            {
                label: store.name,
                address: order.pickupAddress.formattedAddress,
            },
            {
                label: dropAddressLabel,
                address: order.dropAddress.formattedAddress,
            },
        ];
    }

    if (cartSelectedAddress) {
        const { addressTag: cartSelectedAddressLabel } =
            displayAddressNameAndIcon(cartSelectedAddress);
        return [
            {
                label: store.name,
                address: "",
            },
            {
                label: cartSelectedAddressLabel,
                address: cartSelectedAddress.formattedAddress ?? "",
            },
        ];
    }

    if (guestDetails) {
        return [
            {
                label: store.name,
                address: "",
            },
            {
                label: "Guest",
                address: guestDetails?.address ?? "",
                name: guestDetails?.fullName ?? "",
                phoneNumber: guestDetails?.phoneNumber ?? "",
                email: guestDetails?.emailAddress ?? "",
            },
        ];
    }

    return null;
};

export const usePaymentDropAddressId = (): string => {
    const order = usePaymentOrder();
    const store = usePaymentStore();
    const cartSelectedAddress = useRef(useSelectedCartAddress(store?.storeId ?? "0")).current;

    return order ? order?.dropAddress?.id : cartSelectedAddress?.id ?? "";
};

export const usePaymentDropAddressLocation = (): { lat: string; lng: string } | null => {
    const order = usePaymentOrder();
    const store = usePaymentStore();
    const cartSelectedLocation = useRef(useSelectedCartLocation(store?.storeId ?? "0")).current;

    if (order?.guestOrder) {
        return order.dropAddress;
    }

    return cartSelectedLocation;
};
