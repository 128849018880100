import { INTRO_STORY_1 } from "@minis-consumer/lotties";
import type { STORY as ONBOARDING_STORY } from "../components/minis-onboarding/stories-container";

/**
 * DURATION = Frames / Frame Rate
 * Frames = Open lottie file and look for "op"
 * Frame Rate = Open lottie file and look for "fr"
 *
 * For faster speed, reduce duration
 * */
export const ONBOARDING_STORIES: ONBOARDING_STORY[] = [
    {
        file: INTRO_STORY_1,
        duration: 8 * 1000,
    },
];

export const TOOLTIP_RENDER_DELAY_MS = 50;
export const TOOLTIP_DISMISS_DELAY_MS = 5000;
export const SKIP_CTA_TEXT = "SKIP";
export const SCROLL_EVENT_THROTTLE = 16;

export const MASTHEAD_IMAGE_ID = "minis/consumer_hp_masthead";

export const HEADER_CHIP_HEIGHT = 36;
export const TOOLTIP_WIDTH = 280;

export const MASTHEAD_LOTTIE_ID = "masthead_lotttie.json";
