import React from "react";
import { StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { useDeliveryETAContextStatus } from "@minis-consumer/hooks/use-delivery-eta";
import { useGetDeliveryChargesText } from "@minis-consumer/hooks/use-shop";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";

import { ALL_ORDERS_DELIVERED_BY, DELIVERY_ACROSS_INDIA, DELIVERY_COPY } from "./constants";

type ShopDeliveryDetailsProps = {
    showTitle?: boolean;
    fontCategory?: "b1" | "b2";
};

export const ShopDeliveryDetails: React.FC<ShopDeliveryDetailsProps> = ({
    showTitle = true,
    fontCategory = "b2",
}) => {
    const { name: storeName, address, interDeliveryConfig } = useStoreInfo();
    const { selectedAddress, isUserPincodeAvailable, isDeliveryETAHidden, isDeliveryETAAvailable } =
        useDeliveryETAContextStatus();

    const deliveryChargesText = useGetDeliveryChargesText();

    const ICON_SIZE = 13;

    const deliveryAcrossCopy = interDeliveryConfig?.enabled
        ? DELIVERY_ACROSS_INDIA
        : address?.city
        ? DELIVERY_COPY.DELIVERY_ONLY_IN + address?.city
        : "";

    return (
        <Stack
            spacing={{
                default: SpacingValue["space-xx-small"],
                lg: SpacingValue["space-x-small"],
            }}
            style={styles.container}
            testID={TEST_IDS_SF.DELIVERY_DETAILS_VIEW}>
            {showTitle ? (
                <Text category="h4" weight="bold">
                    {DELIVERY_COPY.ORDERS_AND_DELIVERY}
                </Text>
            ) : null}

            {!isDeliveryETAAvailable ? (
                <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                    <Box pt={SpacingValue["space-xxx-small"]}>
                        <SvgIcon
                            icon="Package"
                            color="color-basic-60"
                            width={ICON_SIZE}
                            height={ICON_SIZE}
                        />
                    </Box>

                    <Text category={fontCategory} color="color-basic-60">
                        {deliveryAcrossCopy}
                    </Text>
                </Stack>
            ) : null}

            {isDeliveryETAAvailable && selectedAddress?.deliveryEta ? (
                <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                    <Box pt={SpacingValue["space-xxx-small"]}>
                        <SvgIcon
                            icon="Timer"
                            color="color-basic-60"
                            width={ICON_SIZE}
                            height={ICON_SIZE}
                        />
                    </Box>
                    <Text category={fontCategory} color="color-basic-60" weight="bold">
                        {storeName}
                    </Text>
                    <Text category={fontCategory} color="color-basic-60">
                        {DELIVERY_COPY.DELIVERS_IN}
                        {selectedAddress?.deliveryEta}
                    </Text>
                </Stack>
            ) : null}

            {deliveryChargesText ? (
                <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                    <Box pt={SpacingValue["space-xxx-small"]}>
                        <SvgIcon
                            icon="Star"
                            color="color-basic-60"
                            width={ICON_SIZE}
                            height={ICON_SIZE}
                        />
                    </Box>

                    <Text category={fontCategory} color="color-basic-60">
                        {deliveryChargesText}
                    </Text>
                </Stack>
            ) : null}

            {isDeliveryETAAvailable ? null : (
                <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                    <Box pt={SpacingValue["space-xxx-small"]}>
                        <SvgIcon
                            icon="BagEmpty"
                            color="color-basic-60"
                            width={ICON_SIZE}
                            height={ICON_SIZE}
                        />
                    </Box>

                    <Text category={fontCategory} color="color-basic-60">
                        {ALL_ORDERS_DELIVERED_BY}
                        <Text category={fontCategory} color="color-basic-60" weight="bold">
                            {storeName}
                        </Text>
                    </Text>
                </Stack>
            )}

            {!isUserPincodeAvailable && !isDeliveryETAHidden ? (
                <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                    <Box pt={SpacingValue["space-xxx-small"]}>
                        <SvgIcon
                            icon="LocationPinOutlineFilled"
                            color="color-basic-60"
                            width={ICON_SIZE}
                            height={ICON_SIZE}
                        />
                    </Box>

                    <Text category={fontCategory} color="color-basic-60">
                        {DELIVERY_COPY.ENTER_PINCODE_FOR_ETA}
                    </Text>
                </Stack>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: {
        width: "90%",
    },
});
