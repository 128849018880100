import * as React from "react";
import { Platform, Pressable, StyleSheet } from "react-native";
import { useNavigation, NavigationProp } from "@react-navigation/native";

import { SpacingValue, useTheme, Text } from "@swiggy-private/rn-dls";
import {
    Box,
    getScreenSize,
    ScreenSize,
    Stack,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

import { Card } from "@minis-consumer/components/card";
import { RouteList } from "@minis-consumer/interfaces/route";
import { OrderAddress } from "@minis-consumer/interfaces/order";
import { useShopNavigation } from "@minis-consumer/hooks/use-shop-navigation";
import { displayAddressNameAndIcon } from "@minis-consumer/helpers/address";

import OrderAddressGist from "./components/order-address-gist";
import OrderAddressLogo from "./components/order-address-logo";

interface OrderAddressCardProps {
    storeName: string;
    storeId: string;
    pickupAddress: OrderAddress;
    dropAddress: OrderAddress;
    chatAnalyticsEvent: () => void;
    isStoreEnabled: boolean;
    slug: string;
    isStarSeller?: boolean;
    storeBrandImageId?: string;
    isChatEnabled?: boolean;
}

const STORE_DELETED_TEXT = "This Ministore has been permenantly closed by the seller.";

const OrderAddressCard: React.FC<OrderAddressCardProps> = ({
    storeName,
    pickupAddress,
    dropAddress,
    storeId,
    storeBrandImageId,
    chatAnalyticsEvent,
    isStoreEnabled,
    slug,
    isStarSeller,
    isChatEnabled = true,
}) => {
    const { value: theme } = useTheme();

    const navigation = useNavigation<NavigationProp<RouteList>>();

    const goToStore = useShopNavigation();

    const chatContainerSize = useSelectScreen({
        lg: 60,
        default: 40,
    });

    const chatSize = useSelectScreen({
        lg: 24,
        default: 20,
    });

    const { addressTag } = displayAddressNameAndIcon(dropAddress);

    const onChatPress = React.useCallback(() => {
        chatAnalyticsEvent();
        navigation.navigate("ChatConversation", { storeId });
    }, [chatAnalyticsEvent, navigation, storeId]);

    const showChatIcon = React.useMemo(() => {
        if (Platform.OS === "web" && getScreenSize() === ScreenSize.Large) {
            return false;
        }

        return isChatEnabled;
    }, [isChatEnabled]);

    const marginValue = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const storeClosedTextContainer = {
        marginHorizontal: -marginValue,
        marginBottom: -marginValue,
        marginTop: SpacingValue["space-medium"],
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
        backgroundColor: theme["color-critical-25"],
        paddingVertical: SpacingValue["space-medium"],
        paddingHorizontal: marginValue,
    };

    return (
        <Card>
            <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                <OrderAddressLogo
                    storeBrandImageId={storeBrandImageId}
                    isStoreEnabled={isStoreEnabled}
                    onShopLogoPress={() => goToStore(slug)}
                />

                <Stack
                    justifyContent="space-between"
                    flex={1}
                    flexGrow={1}
                    divider={
                        <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.line} />
                    }>
                    <Stack direction="row" justifyContent="space-between">
                        <Box
                            justifyContent="center"
                            style={[{ height: chatContainerSize }, styles.address]}>
                            <OrderAddressGist
                                annotation={storeName}
                                locality={pickupAddress.locality ?? ""}
                                city={pickupAddress.city ?? ""}
                                isStoreEnabled={isStoreEnabled}
                                isStoreAddress
                                onPress={() => goToStore(slug)}
                                isStarSeller={isStarSeller}
                            />
                        </Box>

                        {showChatIcon && isStoreEnabled ? (
                            <Pressable
                                onPress={onChatPress}
                                style={[
                                    styles.svgContainer,
                                    // eslint-disable-next-line react-native/no-inline-styles
                                    {
                                        height: chatContainerSize,
                                        width: chatContainerSize,
                                        borderWidth: 1,
                                        borderColor: theme["color-primary"],
                                        borderRadius: chatContainerSize / 2,
                                        backgroundColor: theme["color-background-primary"],
                                        ...theme.elevations[1],
                                    },
                                ]}>
                                <SvgIcon
                                    icon="Chat"
                                    width={chatSize}
                                    height={chatSize}
                                    color="color-primary"
                                />
                            </Pressable>
                        ) : null}
                    </Stack>

                    {dropAddress ? (
                        <Box justifyContent="center" style={{ height: chatContainerSize }}>
                            <OrderAddressGist
                                annotation={addressTag}
                                locality={dropAddress.locality ?? ""}
                                city={dropAddress.city ?? ""}
                            />
                        </Box>
                    ) : null}
                </Stack>
            </Stack>

            <Box style={storeClosedTextContainer} display={isStoreEnabled ? "none" : "flex"}>
                <Text category="b2" color="color-critical-500">
                    {STORE_DELETED_TEXT}
                </Text>
            </Box>
        </Card>
    );
};

const styles = StyleSheet.create({
    svgContainer: {
        borderRadius: 50,
        alignItems: "center",
        justifyContent: "center",
        shadowColor: "transparent",
    },
    line: {
        marginTop: 0,
    },
    address: {
        width: "75%",
    },
});

export default React.memo(OrderAddressCard);
