import { ColorPalette, TextColor } from "@swiggy-private/rn-dls";

import { PRODUCT_TYPE } from "@minis-consumer/interfaces/catalog";

import {
    CartErrorsV2Type,
    CART_V2_ERRORS_STATUS,
    CART_V2_ERRORS_STATUS_DATA,
    CART_TYPE_MESSAGE_DATA,
} from "../constants";

export const getMessageDataToDisplay = ({
    cartStatus,
    cartType,
}: {
    cartStatus: CartErrorsV2Type;
    cartType: PRODUCT_TYPE;
}): {
    message: string;
    textColor: TextColor | undefined;
    backgroundColor: keyof ColorPalette;
} | null => {
    if (cartStatus === "VALID_CART") {
        /** check cart items, if appointment, show slot message */

        if (cartType === "APPOINTMENT" || cartType === "DIGITAL") {
            return CART_TYPE_MESSAGE_DATA[cartType];
        }

        return null;
    }

    if (CART_V2_ERRORS_STATUS.indexOf(cartStatus) < 0) {
        return null;
    }

    return CART_V2_ERRORS_STATUS_DATA[cartStatus];
};
