import { CartStatuses } from "@minis-consumer/interfaces/cart";
import { ColorPalette, TextProps } from "@swiggy-private/rn-dls";

export const CONST = {
    CUSTOM_PRODUCT_SECTION_TITLE: "CUSTOM PRODUCTS",
    STORE_ANALYTICS_CATEGORY: "store-info",
};

export const CART_V2_ERRORS_STATUS: CartStatuses[] = [
    "ITEM_SLOT_NOT_SELECTED",
    "ITEM_SLOT_EXPIRED",
    "ITEM_SLOT_TOO_FAR",
    "ITEM_FORM_BLANK",
    "ITEM_FORM_INCOMPLETE",
    "SELLER_PAYMENT_NOT_CONFIGURED",
    "ITEM_SLOT_UNAVAILABLE",
];

export type CartErrorsV2Type =
    | "ITEM_SLOT_NOT_SELECTED"
    | "ITEM_SLOT_EXPIRED"
    | "ITEM_SLOT_TOO_FAR"
    | "ITEM_FORM_BLANK"
    | "ITEM_FORM_INCOMPLETE"
    | "VALID_CART";

export const CART_V2_ERRORS_STATUS_DATA: Record<
    CartErrorsV2Type,
    {
        message: string;
        textColor: TextProps["color"];
        backgroundColor: keyof ColorPalette;
    }
> = {
    ITEM_SLOT_NOT_SELECTED: {
        message: "* Please select a slot to proceed",
        textColor: "negative",
        backgroundColor: "negative_Sub",
    },
    ITEM_SLOT_EXPIRED: {
        message: "* Slot is expired",
        textColor: "negative",
        backgroundColor: "negative_Sub",
    },
    ITEM_SLOT_TOO_FAR: {
        message: "* Slot is too far",
        textColor: "negative",
        backgroundColor: "negative_Sub",
    },
    ITEM_FORM_BLANK: {
        message: "* Please fill the form uploaded by the seller to proceed",
        textColor: "negative",
        backgroundColor: "negative_Sub",
    },
    ITEM_FORM_INCOMPLETE: {
        message: "* Please fill the form uploaded by the seller to proceed",
        textColor: "negative",
        backgroundColor: "negative_Sub",
    },
    VALID_CART: {
        message: "",
        textColor: "color-positive-400",
        backgroundColor: "color-positive-25",
    },
};

export const CART_TYPE_MESSAGE_DATA: Record<
    string,
    {
        message: string;
        textColor: TextProps["color"];
        backgroundColor: keyof ColorPalette;
    }
> = {
    APPOINTMENT: {
        message: "* Slots will be confirmed after payment",
        textColor: "color-positive-400",
        backgroundColor: "color-positive-25",
    },
    DIGITAL: {
        message: "* Your file(s) will be available for download after payment",
        textColor: "color-positive-400",
        backgroundColor: "color-positive-25",
    },
};

export const CTA_ICON_SIZE = 12;
