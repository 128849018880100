import * as React from "react";
import { ScrollView } from "react-native";
import { Container } from "@swiggy-private/rn-adaptive-layout";
import { useTheme } from "@swiggy-private/rn-dls";

import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { withResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { FAQContent } from "./components/content";

export const FAQScreen: React.FC = () => {
    const { value: theme } = useTheme();
    const bgSecondaryColor = theme["color-background-secondary"];

    const scrollStyle = {
        backgroundColor: bgSecondaryColor,
    };

    return (
        <StateContextContainer>
            <ScrollView style={scrollStyle}>
                <Container fluid={false}>
                    <FAQContent />
                </Container>
            </ScrollView>
        </StateContextContainer>
    );
};

export const FAQ = withResetErrorBoundary(FAQScreen);
