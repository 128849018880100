import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, Surface, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

interface Props {
    title?: string;
    charges?: Array<{
        left: string;
        right: string;
    }>;
}

const ChargesTooltipContentComponent: React.FC<Props> = ({ title, charges }) => {
    const { value: theme } = useTheme();

    if (!charges) {
        return null;
    }

    return (
        <Surface style={[styles.container, { borderColor: theme["color-basic-15"] }]}>
            <Stack spacing={SpacingValue["space-small"]}>
                {title ? (
                    <Text category="b2" color="high" weight="medium">
                        {title}
                    </Text>
                ) : null}

                {charges.map((charge, index) => (
                    <Stack key={index} spacing={SpacingValue["space-x-small"]}>
                        <Stack direction={"row"} spacing={SpacingValue["space-x-small"]}>
                            <Box flex={1}>
                                <Text category="b3" color="medium">
                                    {charge.left}
                                </Text>
                            </Box>

                            <Box>
                                <Text category="b3" color="medium">
                                    {charge.right}
                                </Text>
                            </Box>
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </Surface>
    );
};

export const ChargesTooltipContent = React.memo(ChargesTooltipContentComponent);

const styles = StyleSheet.create({
    container: {
        paddingTop: SpacingValue["space-large"],
        paddingBottom: SpacingValue["space-medium"],
        paddingHorizontal: SpacingValue["space-medium"],
        borderRadius: 8,
        borderWidth: 1,
        minWidth: 200,
    },
});
