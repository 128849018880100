import React from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { displayAddressNameAndIcon } from "@minis-consumer/helpers/address";
import { Cart } from "@minis-consumer/interfaces/cart";
import { useCompleteAddress } from "@minis-consumer/hooks/use-complete-address";

import { AddressMissingFieldDialog } from "../address-missing-fields-dialog";

interface Props {
    address: NonNullable<Cart["cartViewData"]>["addresses"][0];
    setAddressSelected: () => void;
    openMissingFieldModal: boolean;
    setOpenMissingFieldModal: (b: boolean) => void;
    showPincode: boolean;
    showCity: boolean;
    showState: boolean;
    addressIdSelected: string;
    onModalCtaPress: ({
        pincode,
        city,
        state,
    }: {
        pincode: string;
        city: string;
        state: string;
    }) => void;
    shouldShowUnserviceable?: boolean;
}

const SERVICEABLE_TEXT = "Deliver here";
const UNSERVICEABLE_TEXT = "Unavailable";

const CardItemComponent: React.FC<Props> = ({
    address,
    setAddressSelected,
    openMissingFieldModal,
    setOpenMissingFieldModal,
    showPincode,
    showCity,
    showState,
    addressIdSelected,
    onModalCtaPress,
    shouldShowUnserviceable,
}) => {
    const { value: theme } = useTheme();
    const completeAddress = useCompleteAddress(address);

    const { addressTag, icon } = displayAddressNameAndIcon(address);

    const serviceable = React.useMemo(
        () => address.serviceabilityType !== "UNSERVICEABLE",
        [address.serviceabilityType],
    );

    const containerStyles: ViewStyle = {
        borderColor: theme["color-basic-15"],
    };

    const positiveCtaStyles: ViewStyle = {
        backgroundColor: theme["color-primary-400"],
        borderColor: theme["color-primary-400"],
    };

    const disabledStyle: ViewStyle = {
        shadowColor: "transparent",
    };

    const buttonStyle: ViewStyle =
        !serviceable && shouldShowUnserviceable ? disabledStyle : positiveCtaStyles;

    const buttonText: string =
        !serviceable && shouldShowUnserviceable ? UNSERVICEABLE_TEXT : SERVICEABLE_TEXT;

    return (
        <Box
            flex={1}
            pv={SpacingValue["space-medium"]}
            ph={SpacingValue["space-medium"]}
            style={[styles.container, containerStyles]}>
            <Stack spacing={SpacingValue["space-small"]} flex={1}>
                <Stack spacing={SpacingValue["space-x-small"]} direction="row" alignItems="center">
                    <SvgIcon icon={icon} height={16} width={16} color="color-basic-100" />
                    <Text category="b1" weight="bold" color="high">
                        {addressTag}
                    </Text>
                </Stack>

                <Box flex={1}>
                    <Text category="b2" color="medium">
                        {completeAddress}
                    </Text>
                </Box>

                <Box direction="row">
                    <Button
                        onPress={setAddressSelected}
                        color={!serviceable && shouldShowUnserviceable ? "tertiary" : "primary"}
                        style={buttonStyle}
                        disabled={!serviceable && shouldShowUnserviceable}
                        size="small">
                        {buttonText}
                    </Button>
                </Box>
            </Stack>
            {addressIdSelected === address.id ? (
                <AddressMissingFieldDialog
                    open={openMissingFieldModal}
                    setOpen={setOpenMissingFieldModal}
                    streetAddress={completeAddress}
                    annotation={addressTag}
                    showPincode={showPincode}
                    showCity={showCity}
                    showState={showState}
                    onCtaPress={onModalCtaPress}
                />
            ) : null}
        </Box>
    );
};

export const CardItem = React.memo(CardItemComponent);

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderRadius: 10,
    },
});
