import React from "react";
import { StyleSheet } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

const IndicatorCircleComponent: React.FC = () => {
    const { value: theme } = useTheme();
    return <Box style={[styles.container, { borderColor: theme["color-primary"] }]} />;
};

export const IndicatorCircle = React.memo(IndicatorCircleComponent);

const styles = StyleSheet.create({
    container: {
        height: SpacingValue["space-small"],
        width: SpacingValue["space-small"],
        borderRadius: SpacingValue["space-small"] / 2,
        borderWidth: 3,
    },
});
