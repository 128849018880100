import { useMountedRef } from "@swiggy-private/react-hooks";
import { useErrorHandler } from "react-error-boundary";
import { useCallback } from "react";

import { fetchPaymentMethodsApi } from "@minis-consumer/api/payment";
import { usePaymentDispatch } from "./use-dispatch";
import { usePaymentStoreId } from "./use-payment-store";

type Props = {
    addressId?: string;
    orderId?: string;
    location?: { lat: string; lng: string } | null;
};

export const useFetchPaymentMethods = (): ((props: Props) => void) => {
    const mountedRef = useMountedRef();
    const handleError = useErrorHandler();
    const storeId = usePaymentStoreId();
    const dispatch = usePaymentDispatch();

    return useCallback(
        ({ addressId, orderId, location }: Props) => {
            if (!storeId) {
                return;
            }

            fetchPaymentMethodsApi({
                storeId,
                addressId,
                getSavedCards: true,
                orderId,
                lat: location?.lat,
                lng: location?.lng,
            })
                .then((response) => {
                    if (mountedRef.current) {
                        dispatch({
                            type: "UPDATE_PAYMENT_AMOUNT",
                            payload: response.paymentAmount,
                        });

                        dispatch({
                            type: "UPDATE_PAYMENT_METHODS",
                            payload: response.paymentMethods,
                        });

                        dispatch({
                            type: "UPDATE_PAYMENT_SERVICE_STATUS",
                            payload: response.payments,
                        });
                    }
                })
                .catch((e) => {
                    mountedRef.current && handleError(e);
                });
        },
        [dispatch, handleError, mountedRef, storeId],
    );
};
