import React from "react";
import { Platform, StyleSheet, useWindowDimensions } from "react-native";
import { useHeaderHeight } from "@react-navigation/elements";

import { CdnImage } from "@swiggy-private/react-native-ui";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { useTheme, Text, SpacingValue } from "@swiggy-private/rn-dls";

import { StoreClosedContent } from "@minis-consumer/components/store-closed";
import { Divider } from "@minis-consumer/components/divider";
import { StoreCloseSubtextAndCta } from "@minis-consumer/components/store-close-subtext-and-cta";
import { useMinisNavigation } from "@minis-consumer/hooks/use-minis-navigation";

const IMAGE_WIDTH = 258;
const IMAGE_HEIGHT = 140;
const PRODUCT_UNAVAIlABLE = "Whoops! This product is not available anymore";

const StoreDeletedProductComponent: React.FC = () => {
    const { value: theme } = useTheme();
    const minisNavigation = useMinisNavigation();

    const headerHeight = useHeaderHeight();
    const { height: windowHeight } = useWindowDimensions();

    const containerStyleMinHeight = useSelectScreen({
        lg: 640,
        md: windowHeight - headerHeight,
        default: windowHeight - headerHeight,
    });

    const containerStyleBgColor = useSelectScreen({
        lg: theme["color-basic-0"],
        md: theme["color-background-secondary"],
        default: theme["color-background-secondary"],
    });

    const containerBorderRadius = useSelectScreen({
        lg: 16,
        md: 16,
        default: 0,
    });

    const containerStyle = {
        backgroundColor: containerStyleBgColor,
        borderRadius: containerBorderRadius,
        minHeight: containerStyleMinHeight,
        paddingHorizontal: SpacingValue["space-x-large"],
    };

    const navigateToHome = React.useCallback(() => {
        minisNavigation?.navigate("MinisHome", { screen: "Explore" });
    }, [minisNavigation]);

    return (
        <Box flex={1} style={containerStyle} justifyContent="center" alignItems="center">
            <CdnImage
                isImageKitEnabled
                id="minis/pdp_store_deleted"
                width={IMAGE_WIDTH}
                height={IMAGE_HEIGHT}
            />

            <Text category="s1" color="high" weight="bold" style={styles.supportiveText}>
                {PRODUCT_UNAVAIlABLE}
            </Text>

            <StoreClosedContent style={styles.storeClosedCopy} />

            {Platform.OS !== "web" ? (
                <>
                    <Divider style={styles.divider} />
                    <StoreCloseSubtextAndCta
                        textMarginTop={2 * SpacingValue["space-x-large"]}
                        ctaMarginTop={SpacingValue["space-medium"]}
                        onPressCta={navigateToHome}
                    />
                </>
            ) : null}
        </Box>
    );
};

export const StoreDeletedProductComp = React.memo(StoreDeletedProductComponent);

const styles = StyleSheet.create({
    supportiveText: {
        textAlign: "center",
        marginTop: SpacingValue["space-x-large"],
        width: IMAGE_WIDTH,
    },
    storeClosedCopy: {
        paddingVertical: SpacingValue["space-x-large"],
    },
    divider: {
        marginTop: SpacingValue["space-xx-large"],
    },
});
