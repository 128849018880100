import * as React from "react";
import { StyleSheet, useWindowDimensions } from "react-native";

import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Button, Text, SpacingValue } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { TERMS_AND_CONDITIONS_URL } from "@minis-consumer/constants";
import { Analytics } from "@minis-consumer/analytics";
import { PRODUCT_TYPE } from "@minis-consumer/interfaces/catalog";

type DialogBodyProps = {
    onPressContinue: () => void;
    cartId: string;

    cartType?: PRODUCT_TYPE;
};

const DialogBodyComponent: React.FC<DialogBodyProps> = ({
    onPressContinue,
    cartId,
    cartType = "PHYSICAL",
}) => {
    const store = useStoreInfo();
    const openUrl = useLinking();
    const { width: screenWidth } = useWindowDimensions();

    const isPhysicalCart = cartType === "PHYSICAL";

    const imgWidth = Math.min(197, screenWidth * 0.53);
    const imgHeight = imgWidth * 0.8;

    const imgMarginOffset = useSelectScreen({
        default: 0,
        lg: SpacingValue["space-large"],
    });

    const imgTopMargin = React.useMemo(
        () => imgHeight / 2 + imgMarginOffset,
        [imgHeight, imgMarginOffset],
    );

    const storeName = React.useMemo(() => store.name, [store.name]);

    const onTnCPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: "share-details-tnc-btn",
            label: cartId,
        });

        openUrl(TERMS_AND_CONDITIONS_URL);
    }, [cartId, openUrl]);

    const onPressCta = React.useCallback(() => {
        Analytics.clickEvent({
            category: "share-details-continue-btn",
            label: cartId,
        });

        onPressContinue();
    }, [cartId, onPressContinue]);

    useMount(() => {
        Analytics.impressionEvent({
            category: "share-details-tnc-btn",
            label: cartId,
        });

        Analytics.impressionEvent({
            category: "share-details-continue-btn",
            label: cartId,
        });
    });

    return (
        <Stack spacing={SpacingValue["space-x-large"]} alignItems="center">
            <CdnImage
                isImageKitEnabled
                id="minis/consumer_checkout_dialog"
                width={imgWidth}
                height={imgHeight}
                style={{ marginTop: -imgTopMargin }}
            />

            <Text
                style={[styles.txt, styles.title]}
                category="h4"
                weight="bold">{`This order will be delivered by ${storeName}`}</Text>

            <SvgIcon icon="Squiggle" height={4} width={60} color="color-basic-60" />

            <Text category="b2" color="high" style={styles.txt}>
                {`${storeName} would need your name, phone number and ${
                    isPhysicalCart ? "" : "email"
                } address to deliver this order. Swiggy Minis does not share any other personal information.`}
            </Text>

            <Text category="b2" color="high" style={styles.txt}>
                {"For further details check out "}
                <Text
                    weight="medium"
                    style={styles.underlinedText}
                    onPress={onTnCPress}
                    suppressHighlighting>
                    {"terms and conditions"}
                </Text>
            </Text>

            <Button style={styles.btn} onPress={onPressCta}>
                I understand, continue
            </Button>
        </Stack>
    );
};

const styles = StyleSheet.create({
    title: {
        width: "80%",
    },
    txt: {
        textAlign: "center",
    },
    underlinedText: {
        textDecorationLine: "underline",
    },
    btn: {
        width: "100%",
    },
});

export const DialogBody = React.memo(DialogBodyComponent);
