import React, { useCallback } from "react";
import { ImageStyle, Pressable, StyleProp, StyleSheet } from "react-native";

import { NavigationProp, useNavigation } from "@react-navigation/core";

import { StoreMedia } from "@swiggy-private/react-native-ui";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { BillItem } from "@minis-consumer/components/bill-item";
import { getMediaAssets } from "@minis-consumer/helpers/catalog";
import { Cart, CartItem } from "@minis-consumer/interfaces/cart";
import { RouteList } from "@minis-consumer/interfaces/route";
import { trimmedProductNames } from "@minis-consumer/routes/cart/helpers";
import { TEST_IDS } from "@minis-consumer/routes/payment/constants";
import { usePaymentSelector } from "@minis-consumer/routes/payment/hooks/use-selector";

import { getProductFallbackImages } from "@minis-consumer/helpers/product-fallback-images";
import { ItemNudgeCount } from "./count-item-nudge";

type Props = { cart: Cart["cartViewData"]; onImagePressed?: VoidFunction };

const IMAGE_DIMENSION = { width: 60, height: 60 };

const ProductCartStackComponent: React.FC<Props> = ({ cart, onImagePressed }) => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NavigationProp<RouteList>>();
    const orderSelector = usePaymentSelector((s) => s.order);

    const itemTotal = cart?.bill?.itemTotal ?? orderSelector?.bill.itemTotal ?? 0;
    const cartItems = cart?.cartItems ?? ((orderSelector?.items as CartItem[]) || []);

    const firstThreeImages = cartItems.slice(0, 3);

    const trimmedItemNames = trimmedProductNames(cartItems);

    const imageStyleCallback = useCallback(
        (index: number): StyleProp<ImageStyle> =>
            StyleSheet.flatten([
                {
                    transform: [{ rotate: `${index * 10}deg` }],
                },
                { ...IMAGE_DIMENSION },
            ]),
        [],
    );

    const textAreaStyle = useSelectScreen({
        lg: { width: "80%" },
        md: { width: "75%" },
        default: { width: "60%" },
    });

    const goToCart = React.useCallback(() => {
        onImagePressed?.();
        navigation.navigate("Home", { screen: "Cart" });
    }, [navigation, onImagePressed]);

    const getMediaId = useCallback(
        (cartItem: CartItem) => getMediaAssets(cartItem?.item)?.[0] ?? productImgFallback,
        [],
    );

    if (!cartItems.length) {
        return null;
    }

    const productImgFallback = getProductFallbackImages(cartItems[0].item.productType);

    return (
        <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            spacing={SpacingValue["space-small"]}>
            <Stack
                direction={"row"}
                justifyContent="flex-start"
                alignItems="center"
                flexShrink={1}
                style={textAreaStyle}
                spacing={SpacingValue["space-medium"]}>
                <Pressable
                    testID={TEST_IDS.cart_section}
                    onPress={goToCart}
                    style={[
                        styles.imageSection,
                        {
                            borderColor: theme["color-basic-5"],
                        },
                    ]}>
                    <ItemNudgeCount cartItems={cartItems} />
                    <Box style={styles.gapFix} />

                    {firstThreeImages.map((val, index) => (
                        <Box key={index} style={styles.cartImage}>
                            <StoreMedia
                                testID={TEST_IDS.cart_item_image}
                                {...IMAGE_DIMENSION}
                                disableVideoPlay
                                onPress={goToCart}
                                style={imageStyleCallback(index)}
                                mediaId={getMediaId(val)}
                            />
                        </Box>
                    ))}
                </Pressable>
                {trimmedItemNames ? (
                    <Text category="b2" color="color-basic-60" weight="medium">
                        {trimmedItemNames}
                    </Text>
                ) : null}
            </Stack>

            <Stack justifyContent="flex-end" direction={"row"} style={styles.amountBox}>
                <BillItem title="" finalAmount={itemTotal} />
            </Stack>
        </Stack>
    );
};

export const ProductCartStack = React.memo(ProductCartStackComponent);

const styles = StyleSheet.create({
    cartImage: {
        position: "absolute",
    },
    imageSection: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderWidth: 1,
        borderRadius: 8,
        padding: SpacingValue["space-xx-small"],
    },
    gapFix: {
        width: 60,
        height: 60,
    },
    amountBox: {
        width: 60,
    },
});
