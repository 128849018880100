export const COPIES = {
    TOOLTIP_TITLE: "Got questions about products?",
    TOOLTIP_DESCRIPTION: "Let’s chat, we’re happy to help",
};

export const ANALYTICS_COPIES = {
    HEADER_SHARE_STORE_BTN_CATEGORY: "header-share-store-btn",
    HEADER_CHAT_BTN_CATEGORY: "header-chat-btn",
    HEADER_SEARCH_BTN_CATEGORY: "header-search-btn",
    HEADER_CHAT_POPUP_CATEGORY: "header-chat-popup",
};
