import React from "react";
import { ImageStyle, StyleProp, StyleSheet, useWindowDimensions, ViewStyle } from "react-native";
import LinearGradient from "react-native-linear-gradient";

import {
    CdnImageBackgroundProps,
    CdnImageBackground,
    CdnImage,
} from "@swiggy-private/react-native-ui";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { getStoreBackgroundImageUrl, getStoreBrandImageUrl } from "@minis-consumer/helpers/store";
import { ShopLogo } from "@minis-consumer/components/shop-logo";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";
import { useGetHslFromRgb } from "@minis-consumer/hooks/use-get-hsl-from-hex";

import { PoweredByMinis } from "./powered-by-minis";
import * as Constants from "./constants";

const GRADIENT_COLORS = ["rgba(0, 0, 0, 0.15)", "rgba(0, 0, 0, 0)"];

interface ShopBackgroundProps {
    poweredMinisPressHandler: VoidFunction;

    isStoreEnabled?: boolean;
    imageId?: string | null;
    style?: StyleProp<ImageStyle>;
    imageStyle?: StyleProp<ImageStyle>;
    children?: React.ReactNode;
}

const ShopBackgroundComponent: React.FC<ShopBackgroundProps> = ({
    isStoreEnabled,
    imageId,
    style: propStyle,
    imageStyle: propImageStyle,
    poweredMinisPressHandler,
}) => {
    const storeInfo = useStoreInfo();

    const { value: theme } = useTheme();
    const { width: windowWidth } = useWindowDimensions();

    const logoSize = useSelectScreen({
        lg: 80,
        md: 80,
        default: windowWidth * 0.15,
    });
    const logoUrl = React.useMemo(
        () => (storeInfo ? getStoreBrandImageUrl(storeInfo, { height: logoSize }, true) : null),
        [storeInfo, logoSize],
    );

    const height = imageId ? 324 : storeInfo.settings.brandImageId ? 194 : 150;

    const [width, wrapperStyle, borderTopLeftRadius, borderTopRightRadius] = useSelectScreen({
        lg: [undefined, styles.br, 24, 24],
        md: [undefined, null, 0, 0],
        default: [windowWidth, null, 0, 0],
    });

    const bgColor = isStoreEnabled ? theme["color-background-secondary"] : theme["color-basic-30"];
    const coverImageUrl = React.useMemo(
        () =>
            imageId
                ? getStoreBackgroundImageUrl(
                      imageId,
                      {
                          height,
                          width,
                          qualityAuto: "eco",
                      },
                      true,
                  )
                : null,
        [height, width, imageId],
    );

    const coverImageStyles = React.useMemo(() => {
        return [
            styles.coverImageWrapper,
            wrapperStyle,
            {
                backgroundColor: coverImageUrl
                    ? theme["color-secondary-25"]
                    : theme["color-primary"],
            },
            propImageStyle,
        ];
    }, [coverImageUrl, wrapperStyle, propImageStyle, theme]);

    const gutter = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        md: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const logoStyle = {
        ...styles.logo,
        left: gutter,
    };

    const coverWrapperStyle = {
        ...styles.coverImageWrapper,
        backgroundColor: bgColor,
        height,
        propStyle,
    };

    const storeDeletedAssetStyle = {
        backgroundColor: bgColor,
        height,
        ...styles.storeDeletedBgImage,
    };

    return isStoreEnabled ? (
        <StoreBgImageWrapper
            imageUrl={coverImageUrl}
            height={height}
            width={width}
            imageStyle={coverImageStyles}
            style={coverWrapperStyle}>
            {logoUrl ? (
                <ShopLogo
                    size={logoSize}
                    url={logoUrl}
                    style={logoStyle}
                    borderRadius={SpacingValue["space-small"]}
                />
            ) : (
                <></>
            )}

            <PoweredByMinis poweredMinisPressHandler={poweredMinisPressHandler} />
            {imageId ? (
                <LinearGradient
                    style={[styles.overlay, { borderTopLeftRadius, borderTopRightRadius }]}
                    colors={GRADIENT_COLORS}
                    locations={[0, 1]}
                />
            ) : (
                <></>
            )}
        </StoreBgImageWrapper>
    ) : (
        <StoreDeleteDetailWrapper style={storeDeletedAssetStyle}>
            <CdnImage
                id={Constants.STORE_DELETED_BG_IMAGE}
                height={Constants.STORE_DELETED_BG_IMAGE_HEIGHT}
                width={Constants.STORE_DELETED_BG_IMAGE_WIDTH}
            />
            <Box style={[styles.backgroundContainer, propStyle]} />
        </StoreDeleteDetailWrapper>
    );
};

interface StoreBgImageWrapper {
    imageUrl: string | null;
    height?: number;
    width?: number;
    style?: StyleProp<ImageStyle>;
    imageStyle?: StyleProp<ImageStyle>;
    children?: CdnImageBackgroundProps["children"];
}

const StoreBgImageWrapper: React.FC<StoreBgImageWrapper> = ({
    imageUrl,
    height,
    width,
    style: propStyle,
    imageStyle: propImageStyle,
    children,
}) => {
    const { value: theme } = useTheme();

    const themeHslValue = useGetHslFromRgb();

    if (!imageUrl) {
        return (
            <Box style={[{ height, width, backgroundColor: theme["color-basic-5"] }, propStyle]}>
                <LinearGradient
                    style={[{ height, width }, styles.overlay, propStyle]}
                    colors={[
                        `hsla(${themeHslValue[0] * 360}, 100%, 73%, 1)`,
                        `hsla(${themeHslValue[0] * 360}, 43%, 39%, 1)`,
                    ]}
                    locations={[0, 1]}
                />
                {children}
            </Box>
        );
    }

    return (
        <CdnImageBackground
            isImageKitEnabled
            resizeMode="cover"
            id={imageUrl}
            height={height}
            width={width}
            imageStyle={propImageStyle}
            style={propStyle}
            testID={TEST_IDS_SF.COVER_IMAGE}>
            {children}
        </CdnImageBackground>
    );
};

interface StoreDeleteDetailWrapperProps {
    style?: StyleProp<ImageStyle>;
    children?: React.ReactNode;
}

const StoreDeleteDetailWrapper: React.FC<
    React.PropsWithChildren<StoreDeleteDetailWrapperProps>
> = ({ style, children }) => {
    const [borderBottomHorizontalRadius, borderTopHorizontalRadius, justifyContent] =
        useSelectScreen({
            lg: [0, SpacingValue["space-medium"], "center"],
            md: [0, 0, "center"],
            default: [SpacingValue["space-xx-large"], 0, "flex-start"],
        });

    const storeDeletedBgStyle = {
        borderBottomLeftRadius: borderBottomHorizontalRadius,
        borderBottomRightRadius: borderBottomHorizontalRadius,
        borderTopLeftRadius: borderTopHorizontalRadius,
        borderTopRightRadius: borderTopHorizontalRadius,
        justifyContent,
    } as ViewStyle;

    return <Box style={[storeDeletedBgStyle, style]}>{children}</Box>;
};

const styles = StyleSheet.create({
    br: {
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        width: "100%",
    },
    coverImageWrapper: {
        borderBottomLeftRadius: 24,
        borderBottomRightRadius: 24,
    },
    backgroundContainer: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
    gradientStyle: {
        paddingHorizontal: SpacingValue["space-medium"],
        flex: 1,
    },
    logo: {
        position: "absolute",
        zIndex: 1,
        bottom: SpacingValue["space-medium"],
    },
    storeDeletedBgImage: {
        alignItems: "center",
        justifyContent: "center",
    },
    overlay: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: "100%",
    },
});

export const ShopBackground = React.memo(ShopBackgroundComponent);
