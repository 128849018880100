import { Dispatch, useContext } from "react";

import { PaymentDispatchContext } from "../contexts/payment-context";
import { PaymentAction } from "../reducers/action-types";

export function usePaymentDispatch(): Dispatch<PaymentAction> {
    const context = useContext(PaymentDispatchContext);
    if (!context) {
        throw new Error("Missing payment dispatch context");
    }

    return context;
}
