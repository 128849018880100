import React, { FC, memo } from "react";
import { Text } from "@swiggy-private/rn-dls";
import { CARD_TITLE } from "../constants";

const ReturnDetailsCardTitle: FC = () => (
    <Text category="b1" weight="bold" color="highest">
        {CARD_TITLE}
    </Text>
);

export const CardTitle = memo(ReturnDetailsCardTitle);
