import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { Platform } from "react-native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useController } from "@rest-hooks/react";
import { Endpoint } from "@rest-hooks/endpoint";
import { useLoading } from "@rest-hooks/hooks";

import { useChatSdk, useSendMessage } from "@swiggy-private/connect-chat-ui";
import {
    CustomMessageTypes,
    ReturnOrderMessagePayload,
} from "@swiggy-private/connect-chat-commons";
import { popAndNavigate } from "@swiggy-private/common-helpers";
import { AsyncStorageService } from "@swiggy-private/rn-services";

import { RETURN_REASONS } from "@minis-consumer/routes/order/routes/return-order/select-return-reason/constants";
import { useCreateChatConversation } from "@minis-consumer/hooks/use-start-chat-conversation";
import { IReturnItem, OrderItem, ReturnOrderParams } from "@minis-consumer/interfaces/order";
import { getOrderItemsTitle } from "@minis-consumer/helpers/order";
import { OrderEntity } from "@minis-consumer/resources/order";
import { RouteList } from "@minis-consumer/interfaces/route";
import { returnOrderApi } from "@minis-consumer/api/order";
import { useIsMobileWeb } from "@minis-consumer/hooks/use-mweb";

import { RETURN_SUBMITTED_STORAGE_KEY } from "../constants";

const PHONE_REGEX = new RegExp("^[0-9]{10}$");
const isWeb = Platform.OS === "web";

interface ReturnType {
    selectedReasonIndex: number;
    setSelectedReasonIndex: Dispatch<SetStateAction<number>>;

    comments: string;
    setComments: Dispatch<SetStateAction<string>>;

    phoneNumber: string;
    setPhoneNumber: Dispatch<SetStateAction<string>>;

    selectedMedia?: string[];
    setSelectedMedia: Dispatch<SetStateAction<string[] | undefined>>;

    orderId: string;
    isBtnDisabled: boolean;
    selectedItemsImageIds: string[];
    itemsTitle: string;

    onSendPress: VoidFunction;
    isLoading: boolean;
}

/* eslint-disable max-params */
export const useReturnOrder = function (
    order: OrderEntity,
    selectedItems: OrderItem[],
    handleSuccess?: VoidFunction,
    navigation?: NativeStackNavigationProp<RouteList>,
): ReturnType {
    const isMweb = useIsMobileWeb();
    const [selectedReasonIndex, setSelectedReasonIndex] = useState(0);
    const [comments, setComments] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedMedia, setSelectedMedia] = useState<string[]>();

    const { id: orderId, items: orderItems, storeId } = order || {};
    const reason = RETURN_REASONS[selectedReasonIndex];
    const isBtnDisabled =
        !reason || !comments || !selectedMedia?.length || !phoneNumber.match(PHONE_REGEX);

    const createChatParams = { storeId, ignoreCache: true };

    const { conversationId } = useCreateChatConversation(createChatParams);
    const sendMessage = useSendMessage(conversationId ?? "");
    const sdk = useChatSdk();
    const { fetch } = useController();

    const itemsToConsider = useMemo(
        () => (selectedItems?.length ? selectedItems : orderItems || []),
        [orderItems, selectedItems],
    );

    const selectedItemsImageIds = useMemo(
        () =>
            itemsToConsider.reduce((acc, item) => {
                const { imageIds } = item?.item || {};
                const firstImageId = imageIds?.[0];
                return firstImageId ? [...acc, firstImageId] : acc;
            }, [] as string[]),
        [itemsToConsider],
    );

    const itemsTitle = getOrderItemsTitle(itemsToConsider, true);

    const apiParams: ReturnOrderParams = useMemo(() => {
        const items = itemsToConsider.map((item): IReturnItem => {
            const { id, variantId } = item?.item || {};
            return { id, variantId };
        });

        return { items, reason, comments, mobile: phoneNumber, images: selectedMedia || [] };
    }, [itemsToConsider, reason, comments, phoneNumber, selectedMedia]);

    const chatMessage = useMemo(
        () => ({
            type: CustomMessageTypes.RETURN_ORDER,
            payload: {
                issue: reason,
                orderId,
                storeId,
                itemsTitle,
                comments,
                phoneNumber,
                text: reason,
            },
        }),
        [reason, orderId, storeId, itemsTitle, comments, phoneNumber],
    );

    const handleNavigateToChat = useCallback(() => {
        if (!navigation) {
            return;
        }

        const navigationState = navigation?.getState?.();
        const doesSelectItemsScreenExist = navigationState?.routes?.some(
            (route) => route.name === "SelectReturnItems",
        );

        const numberOfScreensToPop = doesSelectItemsScreenExist ? 2 : 1;

        popAndNavigate({
            navigation,
            numberOfScreensToPop,
            newRouteName: "ChatConversation",
            params: { storeId },
        });
    }, [navigation, storeId]);

    const [onSendPress, isLoading] = useLoading(async () => {
        if (!order || !sdk) {
            return;
        }

        const endpoint = new Endpoint(returnOrderApi);
        const res = await fetch(endpoint, orderId, apiParams);

        if (res) {
            handleSuccess?.();
            const message = sdk.prepareMessage<ReturnOrderMessagePayload>(chatMessage);
            sendMessage(message);

            if (isWeb && !isMweb) {
                return;
            }

            await AsyncStorageService.setItem(RETURN_SUBMITTED_STORAGE_KEY, String(true));
            handleNavigateToChat();
        }
    }, [
        order,
        sdk,
        fetch,
        orderId,
        apiParams,
        chatMessage,
        sendMessage,
        handleNavigateToChat,
        handleSuccess,
    ]);

    return {
        selectedReasonIndex,
        setSelectedReasonIndex,

        comments,
        setComments,

        phoneNumber,
        setPhoneNumber,

        selectedMedia,
        setSelectedMedia,

        orderId,
        isBtnDisabled,
        selectedItemsImageIds,
        itemsTitle,

        onSendPress,
        isLoading,
    };
};
