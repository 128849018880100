import React from "react";
import { Platform } from "react-native";

import { useMount } from "@swiggy-private/react-hooks";
import { MinisMapView } from "@swiggy-private/connect-address-ui";

import { useGoogleMapFns } from "@minis-consumer/hooks/use-google-map-fns";
import { AddressCreationContext } from "@minis-consumer/routes/address/types";
import { Analytics } from "@minis-consumer/analytics";

type Props = {
    onPressCTA: () => void;
    onSearchPress?: () => void;
    context?: AddressCreationContext;
    addressId?: string;
};

export const AddMapPointer: React.FC<Props> = ({
    onPressCTA,
    onSearchPress,
    context,
    addressId,
}) => {
    const { onMount, onLocateMePress, onError } = useGoogleMapFns();

    const forwardNavigation = React.useCallback(() => {
        Analytics.clickEvent({ category: "select-and-proceed-btn" });
    }, []);

    const onMountMapAddressPill = React.useCallback(() => {
        Analytics.clickEvent({ category: "gps-btn" });
    }, []);

    useMount(() => {
        Analytics.impressionEvent({ category: "gps-btn" });

        Analytics.impressionEvent({ category: "select-and-proceed-btn" });
    });

    return (
        <MinisMapView
            onPressCTA={onPressCTA}
            onSearchPress={onSearchPress}
            context={context}
            addressId={addressId}
            onMountMapAddressPill={onMountMapAddressPill}
            onMount={onMount}
            onLocateMePress={onLocateMePress}
            onError={onError}
            forwardNavigationAnalytics={forwardNavigation}
            calloutMarkerId="minis/map_delivery_marker"
            provider={Platform.OS === "android" ? "google" : "default"}
        />
    );
};
