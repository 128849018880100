import React from "react";
import { Linking, Pressable, StyleSheet } from "react-native";

import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { CdnImage, ThreeDotsLoader } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount, useMountedRef } from "@swiggy-private/react-hooks";
import { StarSellerBadge, useStarSeller } from "@swiggy-private/connect-business-commons";

import { Analytics } from "@minis-consumer/analytics";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { ShopLogo } from "@minis-consumer/components/shop-logo";
import { useFollowUnfollowStore } from "@minis-consumer/hooks/use-store-follow";
import { getStoreBrandImageUrl, getStoreShortAddress } from "@minis-consumer/helpers/store";
import {
    useGetProduct,
    useGetStoreMapImageUrl,
    useGetStoreMapUrl,
} from "@minis-consumer/routes/product/hooks/use-get-product";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";
import { StarSellerInfo } from "@minis-consumer/components/star-seller-info";
import { useStarSellerInfo } from "@minis-consumer/hooks/use-star-seller-info";

export const StoreDetails: React.FC<{
    productId: string;
    goToShop: VoidFunction;
}> = ({ productId, goToShop }) => {
    const storeInfo = useStoreInfo();
    const [showToast] = useToast();
    const mapExternalUrl = useGetStoreMapUrl();
    const mountedRef = useMountedRef();
    const isStarSeller = useStarSeller(storeInfo?.badges);
    const isDesktop = useIsDesktop();

    const product = useGetProduct(productId);
    const { value: theme } = useTheme();

    const shortAddress = getStoreShortAddress(storeInfo);
    const logoSize = useSelectScreen({
        default: 60,
    });

    const {
        showStarSellerInfo,
        onCloseStarSellerInfo,
        onStarSellerBadgeMount,
        onStarSellerBadgePress,
    } = useStarSellerInfo(storeInfo.storeId);

    const logoUrl = React.useMemo(
        () => getStoreBrandImageUrl(storeInfo, { height: logoSize }, true),
        [storeInfo, logoSize],
    );
    const mapImageUrl = useGetStoreMapImageUrl(theme["color-primary"].toString(), 80);

    const openMap = React.useCallback(() => {
        if (!mapExternalUrl) {
            return;
        }

        Linking.openURL(mapExternalUrl).catch(() => {
            mountedRef.current && showToast("Could not open maps");
        });
    }, [mapExternalUrl, mountedRef, showToast]);

    useMount(() => {
        Analytics.impressionEvent({
            category: "store-details",
            label: productId,
        });
    });

    const isProductTypePhysical = product?.productType === "PHYSICAL" || !product?.productType;
    return (
        <Box>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={{
                    default: SpacingValue["space-small"],
                }}>
                <Box flex={2}>
                    <Pressable onPress={goToShop}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={{
                                default: SpacingValue["space-small"],
                            }}>
                            {logoUrl ? (
                                <ShopLogo
                                    isImageKitEnabled
                                    size={logoSize}
                                    url={logoUrl}
                                    borderRadius={10}
                                />
                            ) : null}
                            <Box flex={1}>
                                <Text
                                    category={isDesktop ? "b2" : "b1"}
                                    numberOfLines={1}
                                    weight="bold">
                                    {storeInfo.name}
                                </Text>

                                {storeInfo.categories?.length ? (
                                    <Text category="b3" numberOfLines={1} color="color-basic-45">
                                        {storeInfo.categories?.join(" • ")}
                                    </Text>
                                ) : null}

                                {isStarSeller ? (
                                    <>
                                        <StarSellerBadge
                                            showPrimaryText
                                            showSecondaryText
                                            outerGradientStyle={styles.sellerBadgeGradient}
                                            rootContainerStyle={styles.sellerBadgeContainer}
                                            onPress={onStarSellerBadgePress}
                                            onMount={onStarSellerBadgeMount}
                                        />
                                        <StarSellerInfo
                                            storeId={storeInfo.storeId}
                                            showDialog={showStarSellerInfo}
                                            closeDialog={onCloseStarSellerInfo}
                                        />
                                    </>
                                ) : null}
                            </Box>
                        </Stack>
                    </Pressable>
                </Box>

                {!isDesktop ? <FollowUnfollowCTA productId={productId} /> : null}
            </Stack>

            {isProductTypePhysical ? (
                <Stack
                    spacing={{ default: SpacingValue["space-x-small"] }}
                    style={styles.locationStack}>
                    <Text category="b2" weight="medium" color="color-basic-60">
                        {storeInfo.interDeliveryConfig?.enabled
                            ? "Pan-India delivery from"
                            : "Delivery only in"}
                    </Text>

                    {mapImageUrl ? (
                        <Pressable
                            onPress={openMap}
                            accessible
                            accessibilityLabel="Open store location on map">
                            <CdnImage
                                isImageKitEnabled
                                resizeMode="cover"
                                height={80}
                                id={mapImageUrl}
                                style={styles.map}
                            />
                        </Pressable>
                    ) : null}

                    {shortAddress ? (
                        <Text category="b3" color="color-basic-60">
                            {shortAddress}
                        </Text>
                    ) : null}
                </Stack>
            ) : null}
        </Box>
    );
};

const FollowUnfollowCTA: React.FC<{ productId: string }> = ({ productId }) => {
    const { value: theme } = useTheme();

    const { following: followingStore, trigger, loading, canFollow } = useFollowUnfollowStore();

    const actionBtn = {
        backgroundColor: theme["color-primary-25"],
        height: 32,
        borderColor: "transparent",
    };

    const onPressFollowCta = React.useCallback(() => {
        Analytics.clickEvent({
            category: followingStore
                ? "store-details-unfollow-store-btn"
                : "store-details-follow-store-btn",
            label: productId,
        });

        trigger();
    }, [trigger, productId, followingStore]);

    if (!canFollow) {
        return null;
    }

    return (
        <Button
            elevation={2}
            color="tertiary"
            style={actionBtn}
            disableElevation
            disabled={loading}
            onPress={onPressFollowCta}>
            {loading ? (
                <ThreeDotsLoader color="color-primary" />
            ) : followingStore ? (
                <SvgIcon icon="HeartFilled" height={16} width={16} color="color-primary-400" />
            ) : (
                "Love this"
            )}
        </Button>
    );
};

const styles = StyleSheet.create({
    locationStack: {
        marginTop: SpacingValue["space-small"],
    },
    map: {
        borderRadius: 10,
    },
    sellerBadgeGradient: {
        alignSelf: "flex-start",
        marginTop: SpacingValue["space-xx-small"],
    },
    sellerBadgeContainer: {
        paddingLeft: 0,
        paddingVertical: SpacingValue["space-xx-small"],
        paddingRight: 5 * SpacingValue["space-xxx-small"],
    },
});
