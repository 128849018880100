import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

import { CommonTile } from "@minis-consumer/routes/account/components/common-tile";
import { useMinisNavigation } from "@minis-consumer/hooks/use-minis-navigation";
import { HelpRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { useHelpData } from "@minis-consumer/hooks/use-help-data";
import { Analytics } from "@minis-consumer/analytics";

import { HelpContentFetcher } from "../../../components/help-fetcher";
import { Section } from "./section";
import { useMount } from "@swiggy-private/react-hooks";

export const HelpHomeContentFetcher: React.FC = () => {
    const data = useHelpData();
    const { value: theme } = useTheme();
    const minisNavigation = useMinisNavigation();

    const navigation =
        useNavigation<NativeStackNavigationProp<HelpRouteList & RouteList, "HelpHome">>();

    const onPress = React.useCallback(
        (key: string, index: number) => {
            Analytics.clickEvent({ category: key, value: index });

            if (data && data[key].details && data[key].details.length > 1) {
                navigation.navigate("HelpDetailsLevelOne", {
                    level1: key,
                });
            } else if (data && data[key].details && data[key].details.length === 1) {
                navigation.navigate("HelpDetailsLevelTwo", {
                    level1: key,
                    level2: data[key].details[0].topic,
                });
            }
        },
        [data, navigation],
    );

    useMount(() => {
        Analytics.impressionEvent({ category: "faqs-btn" });
        Analytics.impressionEvent({ category: "terms-and-conditions-btn" });
    });

    const onPressFAQ = React.useCallback(() => {
        Analytics.clickEvent({ category: "faqs-btn" });

        if (navigation != null) {
            navigation.navigate("Account", {
                screen: "FAQ",
            });
        } else {
            minisNavigation?.navigate("Account", {
                screen: "FAQ",
            });
        }
    }, [minisNavigation, navigation]);

    const onPressTnC = React.useCallback(() => {
        Analytics.clickEvent({ category: "terms-and-conditions-btn" });

        if (navigation != null) {
            navigation.navigate("Account", {
                screen: "TermsAndConditions",
            });
        } else {
            minisNavigation?.navigate("Account", {
                screen: "TermsAndConditions",
            });
        }
    }, [minisNavigation, navigation]);

    return (
        <Stack
            spacing={SpacingValue["space-xx-large"]}
            style={{ backgroundColor: theme["color-background-secondary"] }}>
            <Section title="What do you need help with?">
                {data &&
                    Object.keys(data)?.map((key, index) => (
                        <CommonTile
                            key={key + index}
                            onPress={() => onPress(key, index)}
                            isDividerPresent={index !== Object.keys(data).length - 1}>
                            <Text category="b1" color="color-basic-75" weight="medium">
                                {data[key].topic}
                            </Text>
                        </CommonTile>
                    ))}
            </Section>

            <Section title="General queries">
                <CommonTile onPress={onPressFAQ} isDividerPresent>
                    <Text category="b1" color="color-basic-75" weight="medium">
                        FAQs
                    </Text>
                </CommonTile>
                <CommonTile onPress={onPressTnC}>
                    <Text category="b1" color="color-basic-75" weight="medium">
                        Terms &amp; conditions
                    </Text>
                </CommonTile>
            </Section>
        </Stack>
    );
};

export const HelpHomeContent: React.FC = () => {
    return (
        <HelpContentFetcher>
            <HelpHomeContentFetcher />
        </HelpContentFetcher>
    );
};
