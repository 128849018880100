import React from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import {
    Box,
    Display,
    ScreenSize,
    Stack,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { Card } from "@minis-consumer/components/card";
import { GuestDetails } from "@minis-consumer/components/guest-checkout/selected-address";
import { HeaderDesktop } from "@minis-consumer/routes/payment/components/header/desktop";
import { usePaymentAddress } from "@minis-consumer/routes/payment/hooks/use-payment-address";

import { AddressIndicator } from "./indicator";
import { AddressText } from "./text";

const PaymentAddressComponent: React.FC = () => {
    const { value: theme } = useTheme();
    const addresses = usePaymentAddress();
    const inset = useSafeAreaInsets();

    const calculatedInsetTop = Math.min(SpacingValue["space-small"], inset.top);

    const innerContentStyle: ViewStyle = {
        paddingTop: calculatedInsetTop,
    };

    const containerStyle = useSelectScreen<ViewStyle | null>({
        lg: {} /** null/undefined not working */,
        default: StyleSheet.flatten([styles.container, innerContentStyle]),
    });

    const guestContainerStyle = useSelectScreen<ViewStyle | null>({
        lg: {
            marginTop: 0,
            marginHorizontal: 0,
        },
        default: {
            marginTop: SpacingValue["space-large"],
            marginHorizontal: SpacingValue["space-medium"],
        },
    });

    if (Array.isArray(addresses) && addresses[1]?.label === "Guest") {
        const address = addresses[1];

        return (
            <Stack>
                <GuestDetails
                    name={address.name}
                    phone={address.phoneNumber || ""}
                    address={address.address}
                    email={address.email || ""}
                    style={guestContainerStyle}
                />
            </Stack>
        );
    }

    return (
        <Card style={[styles.card, containerStyle]}>
            <Display gt={ScreenSize.Large}>
                <Box
                    ph={SpacingValue["space-x-large"]}
                    pv={SpacingValue["space-x-large"]}
                    style={[
                        styles.desktopHeaderContainer,
                        { borderBottomColor: theme["color-basic-5"] },
                    ]}>
                    <HeaderDesktop />
                </Box>
            </Display>

            {addresses ? (
                <Box ph={SpacingValue["space-large"]} pv={SpacingValue["space-medium"]}>
                    <Stack spacing={SpacingValue["space-medium"]} direction="row">
                        <AddressIndicator />

                        <Box flex={1}>
                            <Stack spacing={SpacingValue["space-x-small"]}>
                                <AddressText
                                    label={addresses[0].label}
                                    address={addresses[0].address}
                                />

                                <AddressText
                                    label={addresses[1].label}
                                    address={addresses[1].address}
                                />
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            ) : null}
        </Card>
    );
};

export const PaymentAddress = React.memo(PaymentAddressComponent);

const styles = StyleSheet.create({
    card: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    container: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    desktopHeaderContainer: {
        borderBottomWidth: 1,
    },
    guestBreadcumb: {
        marginHorizontal: SpacingValue["space-medium"],
        marginTop: SpacingValue["space-xx-large"],
    },
});
