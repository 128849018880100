import React from "react";

import { Text, TextProps } from "@swiggy-private/rn-dls";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

const TitleComponent: React.FC<{ title: string }> = ({ title }) => {
    const textCategory: TextProps["category"] = useSelectScreen({
        default: "h5",
        md: "h4",
        sm: "h4",
    });

    return (
        <Text category={textCategory} color="high" weight="medium">
            {title}
        </Text>
    );
};

export const Title = React.memo(TitleComponent);
