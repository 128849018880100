import React, { useEffect, useState } from "react";

import { Pressable, StyleSheet, useWindowDimensions } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import {
    Box,
    ScreenSize,
    Stack,
    useScreenSize,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { CANCELLATION_POLICIES } from "@minis-consumer/constants";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { StoreSettings } from "@minis-consumer/interfaces/store";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import {
    META_PIXEL_CONTENT_CATEGORY,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { useHasPhysicalProducts } from "@minis-consumer/hooks/use-get-products";

import { ExpansionTile } from "../simple-accordian";
import { ShopDeliveryDetails } from "./components/delivery";
import { SectionHeading } from "../../../components/section-separator";

type ReturnPolicyParam = {
    policy: StoreSettings["returnPolicy"];
};

const CancellationPolicy: React.FC = () => {
    return (
        <Stack
            style={styles.cancellationPolicy}
            spacing={{
                default: SpacingValue["space-xx-small"],
                lg: SpacingValue["space-x-small"],
            }}>
            {CANCELLATION_POLICIES.map((item) => (
                <Stack direction="row" spacing={SpacingValue["space-x-small"]} key={item}>
                    <SvgIcon
                        icon="CircleFilled"
                        color="color-primary"
                        width={6}
                        height={6}
                        style={styles.cancellationPolicyIcon}
                    />
                    <Text category="b2" color="color-basic-60">
                        {item}
                    </Text>
                </Stack>
            ))}
        </Stack>
    );
};

const ReturnPolicy: React.FC<ReturnPolicyParam> = ({ policy }) => {
    const screenSize = useScreenSize();

    const characterThreshold = screenSize === ScreenSize.Large ? 250 : 150;
    const policyLength = policy ? policy.length : 0;

    const [showFullPolicy, setShowFullPolicy] = React.useState(() =>
        policyLength < characterThreshold ? true : false,
    );

    if (!policyLength) {
        return null;
    }

    return (
        <Stack
            style={styles.cancellationPolicy}
            spacing={{
                default: SpacingValue["space-xx-small"],
                lg: SpacingValue["space-x-small"],
            }}>
            <Stack direction="row" spacing={SpacingValue["space-x-small"]} alignItems="center">
                <Text category="b1" color="color-basic-60">
                    {showFullPolicy ? policy : policy?.slice(0, characterThreshold - 1) + "..."}
                </Text>
            </Stack>

            {policyLength > characterThreshold ? (
                <Pressable onPress={() => setShowFullPolicy((show) => !show)}>
                    <Text category="btn5" color="primary">
                        {showFullPolicy ? "Hide full policy" : "See full policy"}
                    </Text>
                </Pressable>
            ) : null}
        </Stack>
    );
};

const StoreInfo: React.FC = () => {
    const { width: windowWidth } = useWindowDimensions();
    const { settings, address } = useStoreInfo();
    const openUrl = useLinking();

    const addressStyle = useSelectScreen({
        lg: {
            maxWidth: 250,
        },
        default: {
            maxWidth: windowWidth - 64,
        },
    });

    const connectWithSeller = React.useCallback(
        (type: "mobile" | "email") => {
            if (type === "mobile") {
                fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.CONTACT, {
                    content_name: settings.mobile,
                    content_category: META_PIXEL_CONTENT_CATEGORY.MOBILE,
                });
                openUrl(`tel:${settings.mobile}`);
            } else if (type === "email") {
                fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.CONTACT, {
                    content_name: settings.email,
                    content_category: META_PIXEL_CONTENT_CATEGORY.EMAIL,
                });
                openUrl(`tel:${settings.email}`);
            }
        },
        [openUrl, settings.mobile, settings.email],
    );

    const canShowBussinessNumber = React.useMemo(
        () => settings.showBusinessNumber && settings.mobile,
        [settings],
    );

    return (
        <Stack
            style={styles.storeInfoContainer}
            spacing={{
                default: SpacingValue["space-small"],
            }}>
            {address?.address ? (
                <Stack
                    direction="row"
                    spacing={SpacingValue["space-x-small"]}
                    alignItems="flex-start">
                    <SvgIcon icon="Location" color="color-basic-60" width={16} height={16} />
                    <Text category="b2" color="color-basic-60" style={addressStyle} selectable>
                        {address?.address}
                    </Text>
                </Stack>
            ) : null}

            {canShowBussinessNumber ? (
                <Stack
                    direction="row"
                    spacing={SpacingValue["space-x-small"]}
                    alignItems="flex-start">
                    <SvgIcon icon="Phone" color="color-basic-60" width={16} height={16} />
                    <Text
                        category="b2"
                        color="color-basic-60"
                        suppressHighlighting
                        onPress={() => connectWithSeller("mobile")}>
                        {settings.mobile}
                    </Text>
                </Stack>
            ) : null}

            {settings.email ? (
                <Stack
                    direction="row"
                    spacing={SpacingValue["space-x-small"]}
                    alignItems="flex-start">
                    <Text color="color-basic-60" category="b1">
                        @
                    </Text>
                    <Text
                        category="b2"
                        color="color-basic-60"
                        suppressHighlighting
                        onPress={() => connectWithSeller("email")}>
                        {settings.email}
                    </Text>
                </Stack>
            ) : null}

            {settings.workingHours ? (
                <Stack
                    direction="row"
                    spacing={SpacingValue["space-x-small"]}
                    alignItems="flex-start">
                    <SvgIcon icon="Timer" color="color-basic-60" width={16} height={16} />
                    <Text category="b2" color="color-basic-60">
                        {settings.workingHours}
                    </Text>
                </Stack>
            ) : null}
        </Stack>
    );
};

const AboutSectionComponent: React.FC = () => {
    const { value: theme } = useTheme();

    const { settings } = useStoreInfo();
    const [data, setData] = useState([
        {
            title: "Cancellation policy",
            child: <CancellationPolicy />,
            testID: TEST_IDS_SF.CANCELLATION_POLICY,
            iconTestID: TEST_IDS_SF.CANCELLATION_POLICY_ICON,
            analyticsEvent: SF_ANALYTICS_EVENT_NAMES.CP_ACCORDION,
        },
        settings.returnPolicy
            ? {
                  title: "Return policy",
                  child: <ReturnPolicy policy={settings.returnPolicy} />,
                  testID: TEST_IDS_SF.RETURN_POLICY,
                  iconTestID: TEST_IDS_SF.RETURN_POLICY_ICON,
                  analyticsEvent: SF_ANALYTICS_EVENT_NAMES.RP_ACCORDION,
              }
            : undefined,
        {
            title: "How to reach us",
            child: <StoreInfo />,
            testID: TEST_IDS_SF.STORE_INFO,
            iconTestID: TEST_IDS_SF.STORE_INFO_ICON,
            analyticsEvent: SF_ANALYTICS_EVENT_NAMES.SI_ACCORDION,
        },
    ]);

    const hasPhysicalProducts = useHasPhysicalProducts();

    useEffect(() => {
        if (hasPhysicalProducts) {
            setData([
                {
                    title: "Orders and delivery",
                    child: <ShopDeliveryDetails />,
                    testID: TEST_IDS_SF.ORDER_AND_DELIVERY,
                    iconTestID: TEST_IDS_SF.ORDER_AND_DELIVERY_ICON,
                    analyticsEvent: SF_ANALYTICS_EVENT_NAMES.OND_ACCORDION,
                },
                ...data,
            ]);
        }
    }, [hasPhysicalProducts]);

    const containerStyle = {
        backgroundColor: theme["color-basic-0"],
        paddingTop: SpacingValue["space-medium"],
    };

    return (
        <Box style={containerStyle}>
            <SectionHeading title={"ABOUT US"} />
            {data.map((item, index) => {
                if (!item) {
                    return null;
                }
                return (
                    <ExpansionTile
                        key={index}
                        title={item.title}
                        index={index}
                        isDividerPresent={index !== data.length - 1}
                        isExpanded
                        testID={item.testID}
                        iconTestID={item.iconTestID}
                        analyticsEvent={item.analyticsEvent}
                        titleTextColor="text_Highest_Emphasis">
                        {item.child}
                    </ExpansionTile>
                );
            })}
        </Box>
    );
};

export const AboutSection = React.memo(AboutSectionComponent);

const styles = StyleSheet.create({
    cancellationPolicy: {
        width: "90%",
    },
    storeInfoContainer: {
        paddingBottom: SpacingValue["space-xx-large"],
    },
    cancellationPolicyIcon: {
        marginTop: SpacingValue["space-x-small"],
    },
    aboutUs: {
        flex: 1,
        paddingRight: SpacingValue["space-medium"],
        maxWidth: 100,
    },
});
