/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from "react";

import { HelpRouteList } from "@minis-consumer/interfaces/route";
import { createNavigator } from "@minis-consumer/helpers/navigation";
import { useScreenOptions } from "@minis-consumer/hooks/use-screen-options";
import { useIsNavbarVisible } from "@minis-consumer/hooks/use-is-navbar-visible";

const Stack = createNavigator<HelpRouteList>();

export const HelpScreen: React.FC = () => {
    const showNavbar = useIsNavbarVisible();
    const screenOptions = useScreenOptions<HelpRouteList>({
        headerShown: !showNavbar,
        headerTitle: "Help & support",
    });

    return (
        <Stack.Navigator initialRouteName="HelpHome" screenOptions={screenOptions}>
            <Stack.Screen
                name="HelpHome"
                getComponent={() => require("./routes/help-home").HelpHome}
            />
            <Stack.Screen
                name="HelpDetailsLevelOne"
                getComponent={() => require("./routes/help-details-level-1").HelpDetailsLevelOne}
            />
            <Stack.Screen
                name="HelpDetailsLevelTwo"
                getComponent={() => require("./routes/help-details-level-2").HelpDetailsLevelTwo}
            />
        </Stack.Navigator>
    );
};
