import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { LottieView } from "@swiggy-private/react-native-ui";

type LottieAnimationContainerProps = {
    lottiePath: string;
    style?: StyleProp<ViewStyle>;
};

const LOTTIE_HEIGHT = 140;

export const LottieAnimationContainer: React.FC<LottieAnimationContainerProps> = ({
    lottiePath,
    style,
}) => {
    if (!lottiePath) {
        return null;
    }

    return (
        <View style={[styles.lottieSize, style]}>
            <LottieView
                source={lottiePath}
                autoPlay
                loop
                resizeMode="cover"
                style={styles.lottieSize}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    lottieSize: {
        height: LOTTIE_HEIGHT,
    },
});
