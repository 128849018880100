import React from "react";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { NetbankingPaymentMethod } from "@minis-consumer/interfaces/payment";
import {
    META_PIXEL_CONTENT_NAME,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";

import { usePaymentOrder } from "../../hooks/use-payment-order";
import { NetBankingSection } from "./components/section";

interface Props {
    onPress: (bankName: string, bankCode: string) => void;
    popularBanks?: NetbankingPaymentMethod[];
    allBanks?: NetbankingPaymentMethod[];
}

const NetBankingMethodComponent: React.FC<Props> = ({
    allBanks,
    popularBanks,
    onPress: onPayProp,
}) => {
    const order = usePaymentOrder();

    const onPress = React.useCallback(
        (type: string) => (bankName: string, bankCode: string) => {
            Analytics.clickEvent({
                category: type,
                label: order?.id,
                context: bankName,
            });

            fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.ADD_PAYMENT_INFO, {
                content_name: META_PIXEL_CONTENT_NAME.BANK,
            });
            onPayProp(bankName, bankCode);
        },
        [onPayProp, order?.id],
    );

    const onPayPopularBanks = onPress("popular-banks");
    const onPayAllBanks = onPress("all-banks");

    return (
        <Stack flex={1} spacing={SpacingValue["space-xx-large"]}>
            {popularBanks ? (
                <NetBankingSection
                    banks={popularBanks}
                    showRadioInput
                    onBankPress={onPayPopularBanks}
                />
            ) : (
                <NetBankingSection banks={allBanks} onBankPress={onPayAllBanks} />
            )}
        </Stack>
    );
};

export const NetBankingMethod = React.memo(NetBankingMethodComponent);
