import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { LayoutChangeEvent, Platform, Pressable, StyleSheet, View, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Icon, SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Surface, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { PaymentDowntimeNudge } from "@minis-consumer/components/payment-downtime-nudge";
import {
    SelectedAddressInfo,
    SelectedAddressInfoContext,
} from "@minis-consumer/contexts/selected-address-context";
import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";
import { convertToProperCase } from "@minis-consumer/helpers/string";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { useDeliveryEta } from "@minis-consumer/hooks/use-delivery-eta";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import { getDeliveryEtaApiParams } from "../../helpers";
import { DeliveryInfo } from "./components/delivery-info";
import { ADDRESS_BOTTOM_SHEET_STATE } from "./constants";

interface BottomSheetComponentProps {
    title: string;
    description: string;
    ctaText: string;
    onChangeLocation: () => void;
    storeId: string;
    showDetailedBill: () => void;
    onPressCta: () => void;
    addressListLength: number;
    showErrorMessage?: boolean;
    hideCheveronIcon?: boolean;
    onLayout?: (e: LayoutChangeEvent) => void;
    show?: boolean;
    cartId?: string;
    isPaymentDown?: boolean;
    paymentDowntimeMessage?: string;
    addressIcon?: Icon;
    isProceedDisabled?: boolean;
}

const BottomSheetComponent: React.FC<BottomSheetComponentProps> = ({
    title,
    description,
    ctaText,
    onChangeLocation: onChangeLocationProp,
    storeId,
    showDetailedBill,
    onPressCta,
    addressListLength,
    showErrorMessage,
    hideCheveronIcon,
    onLayout,
    show,
    cartId,
    isPaymentDown = false,
    paymentDowntimeMessage,
    addressIcon,
    isProceedDisabled,
}) => {
    const insets = useSafeAreaInsets();
    const storeInfo = useStoreInfo();
    const cart = useCartViewData(storeId);
    const { value: theme } = useTheme();
    const selectedAddressCtx = useContext(SelectedAddressInfoContext);
    const { fetchDeliveryEta, isLoading } = useDeliveryEta();

    const containerStyle = useSelectScreen({
        default: {
            paddingTop: SpacingValue["space-medium"],
            paddingBottom: Math.max(
                insets.bottom + SpacingValue["space-xx-small"],
                Platform.OS === "android"
                    ? SpacingValue["space-xx-large"]
                    : SpacingValue["space-medium"],
            ),
            paddingHorizontal: SpacingValue["space-medium"],
        },
        md: {
            paddingTop: SpacingValue["space-x-large"],
            paddingBottom: Math.max(
                insets.bottom + SpacingValue["space-xx-small"],
                SpacingValue["space-x-large"],
            ),
            paddingHorizontal: SpacingValue["space-x-large"],
        },
    });

    const surfaceStyle: ViewStyle = {
        elevation: Platform.select({
            android: 14,
            ios: 4,
            web: 22,
        }),
        shadowOpacity: Platform.select({
            web: 1,
            ios: 0.2,
        }),
    };

    const billTotal = React.useMemo(() => cart?.bill?.billTotal ?? 0, [cart]);

    const getFormattedNumber = React.useCallback(
        (num: number) => formatNumberWithIndianSystem(num, { showRupeeSymbol: true }),
        [],
    );

    const formattedBillTotal = React.useMemo(
        () => getFormattedNumber(billTotal),
        [billTotal, getFormattedNumber],
    );

    const onChangeLocation = React.useCallback(() => {
        Analytics.clickEvent({
            category: showErrorMessage ? "change-address-btn" : "select-address-btn",
            label: cartId,
            context: showErrorMessage && addressListLength ? "unserviceable" : "",
        });

        onChangeLocationProp();
    }, [cartId, onChangeLocationProp, addressListLength, showErrorMessage]);

    const btnDisabled = React.useMemo(
        () =>
            (isPaymentDown || isProceedDisabled) &&
            ctaText === ADDRESS_BOTTOM_SHEET_STATE.SERVICEABLE_ADDRESS.CTA_TEXT,
        [ctaText, isPaymentDown, isProceedDisabled],
    );

    useMount(() => {
        if (show) {
            // This is for location icon CTA
            Analytics.impressionEvent({
                category: showErrorMessage ? "change-address-btn" : "select-address-btn",
                label: cartId,
                context: showErrorMessage && addressListLength ? "unserviceable" : "",
            });

            // This is for bottom CTA
            if (ctaText === ADDRESS_BOTTOM_SHEET_STATE.SERVICEABLE_ADDRESS.CTA_TEXT) {
                Analytics.impressionEvent({
                    category: "proceed-to-pay-btn",
                    label: cartId,
                    context: "cart-bottom-sheet",
                });
            } else {
                Analytics.impressionEvent({
                    category: addressListLength
                        ? showErrorMessage
                            ? "change-address-btn"
                            : "select-address-btn"
                        : "add-address-btn",
                    label: cartId,
                    context: showErrorMessage && addressListLength ? "unserviceable" : "",
                });
            }
        }
    });

    const divider = <DashBorderLine style={styles.divider} borderColor={theme["color-basic-15"]} />;

    const addressTitle = useMemo(() => convertToProperCase(title), [title]);

    const fetchDeliveryEtaInfo = useCallback(
        async (selectedAddr?: SelectedAddressInfo) => {
            const params = getDeliveryEtaApiParams({
                storeId,
                selectedAddress: selectedAddr,
                pickupAddress: storeInfo?.address,
            });

            fetchDeliveryEta(params);
        },
        [fetchDeliveryEta, storeId, storeInfo?.address],
    );

    useEffect(() => {
        const selectedAddr = selectedAddressCtx?.selectedAddressInfo;

        if (selectedAddr?.postalCode) {
            fetchDeliveryEtaInfo(selectedAddr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddressCtx?.selectedAddressInfo?.postalCode]);

    return (
        <Surface style={[styles.surface, surfaceStyle]}>
            <View
                pointerEvents="box-none"
                collapsable={false}
                onLayout={onLayout}
                style={[styles.dialog, { backgroundColor: theme["color-background-primary"] }]}>
                <PaymentDowntimeNudge
                    style={styles.downtime}
                    showNudge={isPaymentDown}
                    message={paymentDowntimeMessage}
                />
                <Stack
                    divider={divider}
                    pointerEvents="box-none"
                    style={containerStyle}
                    onLayout={onLayout}
                    collapsable={false}>
                    <Stack direction="row" justifyContent="space-between">
                        <Pressable style={styles.textContainer} onPress={onChangeLocation}>
                            <Text category="h4" weight="bold" color="high">
                                {addressTitle}
                            </Text>
                            <Text category="b2" color="color-basic-60" numberOfLines={1}>
                                {description}
                            </Text>
                        </Pressable>

                        <Pressable
                            onPress={onChangeLocation}
                            style={[
                                styles.svgContainer,
                                {
                                    borderColor: theme["color-basic-15"],
                                },
                            ]}>
                            <SvgIcon
                                icon={addressIcon || "Location"}
                                color="color-primary"
                                height={SpacingValue["space-large"]}
                            />
                            {!hideCheveronIcon ? (
                                <SvgIcon
                                    icon="ChevronDown"
                                    color="color-primary"
                                    height={SpacingValue["space-medium"]}
                                />
                            ) : null}
                        </Pressable>
                    </Stack>

                    <Box mt={SpacingValue["space-medium"]}>
                        {showErrorMessage ? (
                            <Text category="b2" color="color-critical-500" weight="medium">
                                {`Oops, looks like ${storeInfo.name} doesn't deliver to this location`}
                            </Text>
                        ) : (
                            <DeliveryInfo isLoading={isLoading} storeId={storeId} />
                        )}
                    </Box>

                    <Box
                        pointerEvents="box-none"
                        mt={SpacingValue["space-medium"]}
                        direction="row"
                        justifyContent="space-between">
                        <Box justifyContent="center">
                            <Text category="h5" weight="bold" color="high" style={styles.billTotal}>
                                {formattedBillTotal}
                            </Text>
                            <Pressable onPress={showDetailedBill} hitSlop={{ top: 12, bottom: 12 }}>
                                <Text
                                    category="btn5"
                                    weight="bold"
                                    color="color-primary-400"
                                    suppressHighlighting>
                                    View Detailed Bill
                                </Text>
                            </Pressable>
                        </Box>
                        <Button onPress={onPressCta} disabled={btnDisabled}>
                            {ctaText}
                        </Button>
                    </Box>
                </Stack>
            </View>
        </Surface>
    );
};

export const CartAddressBottomSheet = React.memo(BottomSheetComponent);

const styles = StyleSheet.create({
    surface: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
    },
    dialog: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    svgContainer: {
        flexDirection: "row",
        alignItems: "center",
        borderWidth: 1,
        paddingHorizontal: SpacingValue["space-small"],
        paddingVertical: SpacingValue["space-x-small"],
        borderRadius: 12,
    },
    textContainer: {
        width: "70%",
        justifyContent: "center",
    },
    downtime: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    divider: {
        marginTop: SpacingValue["space-medium"],
    },
    billTotal: {
        marginBottom: SpacingValue["space-xx-small"],
    },
});
