import * as React from "react";
import { KeyboardAvoidingView, ScrollView, StyleSheet, Platform } from "react-native";
import { useRoute, RouteProp } from "@react-navigation/core";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useHeaderHeight } from "@react-navigation/elements";

import { SpacingValue, useTheme, Text, Input, Button, useLayout } from "@swiggy-private/rn-dls";
import { Stack, Box } from "@swiggy-private/rn-adaptive-layout";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { RouteList } from "@minis-consumer/interfaces/route";
import { Divider } from "@minis-consumer/components/divider";
import { displayAddressNameAndIcon, setAddressStorageMeta } from "@minis-consumer/helpers/address";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useUpdateCartAddress } from "@minis-consumer/hooks/use-cart";
import { useCompleteAddress } from "@minis-consumer/hooks/use-complete-address";

import { HeaderLeft } from "./components/header-left";

export const UpdateAddressScreen: React.FC<NativeStackScreenProps<RouteList, "UpdateAddress">> = (
    props,
) => {
    const route = useRoute<RouteProp<RouteList, "UpdateAddress">>();

    const { navigation } = props;
    const { address } = route.params;

    const { value: theme } = useTheme();
    const store = useStoreInfo();
    const headerHeight = useHeaderHeight();
    const updateAddress = useUpdateCartAddress(store.storeId);
    const [layout, onLayout] = useLayout();
    const completeAddress = useCompleteAddress(address);

    const [pincode, setPincodeValue] = React.useState("");
    const [city, setCityValue] = React.useState("");
    const [state, setStateValue] = React.useState("");
    const [showPincode, setShowPincode] = React.useState(false);
    const [showCity, setShowCity] = React.useState(false);
    const [showState, setShowState] = React.useState(false);

    const keyboardOffset = React.useMemo(
        () => (layout.measured ? layout.height + headerHeight : headerHeight),
        [headerHeight, layout.height, layout.measured],
    );
    const btnDisabled = React.useMemo(() => {
        if (showPincode && pincode.trim().length < 6) {
            return true;
        }

        if (showCity && !city.trim().length) {
            return true;
        }

        if (showState && !state.trim().length) {
            return true;
        }

        return false;
    }, [city, pincode, showCity, showPincode, showState, state]);

    const onClose = React.useCallback(() => {
        navigation.pop();
    }, [navigation]);

    const checkMandatoryFields = React.useCallback(() => {
        if (address) {
            !address.city && setShowCity(true);
            !address.postalCode && setShowPincode(true);
            !address.state && setShowState(true);
        }
    }, [address]);

    const onSave = React.useCallback(() => {
        if (address) {
            const commonPayload = {
                city: city.length ? city : address.city,
                state: state.length ? state : address.state,
                postalCode: pincode.length ? Number(pincode) : address.postalCode,
            };

            setAddressStorageMeta({
                [address.id]: commonPayload,
            });

            updateAddress(address.id);

            onClose();
        }
    }, [address, city, onClose, pincode, state, updateAddress]);

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerShown: true,
            headerTitle: "",
            headerLeft: () => <HeaderLeft onClose={onClose} />,
            headerShadowVisible: true,
        });
    }, [navigation, onClose]);

    useIsomorphicEffect(() => {
        if (address) {
            checkMandatoryFields();
        } else {
            onClose();
        }
    }, [address, checkMandatoryFields, onClose]);

    if (!address) {
        return null;
    }

    const { addressTag, icon } = displayAddressNameAndIcon(address);

    return (
        <StateContextContainer>
            <KeyboardAvoidingView
                style={styles.keyboard}
                keyboardVerticalOffset={keyboardOffset}
                behavior={Platform.OS === "ios" ? "padding" : "height"}>
                <ScrollView contentContainerStyle={styles.scrollView}>
                    <Stack spacing={SpacingValue["space-medium"]} style={styles.mainContainer}>
                        <Stack
                            direction="row"
                            spacing={SpacingValue["space-medium"]}
                            onLayout={onLayout}>
                            <Box
                                alignItems="center"
                                justifyContent="center"
                                style={[
                                    styles.svgContainer,
                                    {
                                        borderColor: theme["color-basic-15"],
                                    },
                                ]}>
                                <SvgIcon
                                    icon={icon}
                                    height={24}
                                    width={24}
                                    color={"color-primary"}
                                />
                            </Box>

                            <Box flex={1}>
                                <Text category="h5" weight="bold" color="highest">
                                    {addressTag}
                                </Text>

                                <Box>
                                    <Text category="b2" color="high">
                                        {completeAddress}
                                    </Text>
                                </Box>
                            </Box>
                        </Stack>

                        <Divider />

                        <Text category="b2" weight="medium" color="color-primary">
                            For a hassle free delivery,
                            <Text category="b2" weight="bold" color="color-primary">
                                {` ${store.name} `}
                            </Text>
                            has requested the following info:
                        </Text>

                        {showPincode ? (
                            <Input
                                placeholder="Confirm pincode"
                                testID="input-pincode"
                                keyboardType="phone-pad"
                                maxLength={6}
                                value={pincode}
                                onChangeText={setPincodeValue}
                                autoFocus
                            />
                        ) : null}

                        {showCity ? (
                            <Input
                                placeholder="Confirm city"
                                testID="input-city"
                                value={city}
                                onChangeText={setCityValue}
                                autoFocus
                            />
                        ) : null}

                        {showState ? (
                            <Input
                                placeholder="Confirm state"
                                testID="input-state"
                                value={state}
                                onChangeText={setStateValue}
                                autoFocus
                            />
                        ) : null}

                        <Button disabled={btnDisabled} onPress={onSave}>
                            Continue
                        </Button>
                    </Stack>
                </ScrollView>
            </KeyboardAvoidingView>
        </StateContextContainer>
    );
};

const styles = StyleSheet.create({
    keyboard: {
        flex: 1,
    },
    scrollView: {
        flexGrow: 1,
    },
    mainContainer: {
        marginTop: SpacingValue["space-x-large"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    svgContainer: {
        height: 40,
        width: 40,
        borderRadius: 8,
        borderWidth: 1,
    },
});
