import React from "react";
import { StyleSheet } from "react-native";
import LinearGradient from "react-native-linear-gradient";

import { useMount, useMountedRef } from "@swiggy-private/react-hooks";
import { CdnImage, FormInput, ThreeDotsLoader } from "@swiggy-private/react-native-ui";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import {
    META_PIXEL_CONTENT_NAME,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import {
    NEW_UPI_METHOD,
    PAYMENT_GROUP_CATEGORY,
    PAYMENT_GROUP_METHOD,
    SHIELD_UPI,
} from "../../constants";
import { usePaymentDispatch } from "../../hooks/use-dispatch";
import { usePaymentOrder } from "../../hooks/use-payment-order";
import { useVerifyUPI } from "../../hooks/use-verify-upi";

interface NewUpiProps {
    paymentInProgress?: boolean;
}

const UPI_ID_REGEX = /^[\w._-]+@[\w]+$/;

const NewUPIComponent: React.FC<NewUpiProps> = ({ paymentInProgress = false }) => {
    const mountedRef = useMountedRef();
    const { loading: verifyLoading, trigger: verifyUPI } = useVerifyUPI();
    const order = usePaymentOrder();
    const paymentDispatch = usePaymentDispatch();
    const { value: theme } = useTheme();

    const { storeId } = useStoreInfo();

    const cart = useCartViewData(storeId);

    const [upiId, setUpiId] = React.useState("");
    const [vpaVerified, setVpaVerified] = React.useState(false);
    const [vpaHasError, setVpaHasError] = React.useState(false);

    const linearGradientElement = {
        start: { x: 0, y: 1 },
        end: { x: 0.5, y: 1 },
        colors: [
            theme["color-positive-25"] as string,
            theme["color-basic-0"] as string,
            theme["color-basic-0"] as string,
        ],
    };

    useMount(() => {
        Analytics.impressionEvent({
            category: "save-upi-check-box",
            label: order?.id,
            context: JSON.stringify({ checked: true }),
        });

        Analytics.impressionEvent({
            category: "add-upi-textbox",
            context: JSON.stringify({ cartId: cart?.id || "-" }),
        });
    });

    const onChangeVPA = React.useCallback((text: string) => {
        setVpaVerified(false);
        setVpaHasError(false);
        setUpiId(text);
    }, []);

    const canVerify = React.useMemo(
        () => UPI_ID_REGEX.test(upiId) && !vpaVerified && !paymentInProgress,
        [paymentInProgress, upiId, vpaVerified],
    );

    const onPressVerify = React.useCallback(async () => {
        setVpaHasError(false);
        const verified = await verifyUPI(upiId);

        if (mountedRef.current) {
            Analytics.impressionEvent({
                category: "upi-verification-msg",
                context: JSON.stringify({ cartId: cart?.id || "-" }),
            });

            setVpaVerified(verified);
            setVpaHasError(!verified);

            if (!verified) {
                return;
            }
            Analytics.clickEvent({
                category: "add-upi-textbox",
                context: JSON.stringify({ cartId: cart?.id || "-" }),
            });
            fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.ADD_PAYMENT_INFO, {
                content_name: META_PIXEL_CONTENT_NAME.UPI,
            });
            paymentDispatch({
                type: "UPDATE_SELECTED_PAYMENT_METHOD",
                payload: {
                    type: PAYMENT_GROUP_METHOD.UPI,
                    category: PAYMENT_GROUP_CATEGORY.NEW,
                    value: "CUSTOM_UPI",
                    extraData: upiId,
                },
            });
        }
    }, [cart?.id, mountedRef, paymentDispatch, upiId, verifyUPI]);

    return (
        <Stack spacing={SpacingValue["space-medium"]} style={styles.container}>
            <FormInput
                name="vpa"
                label={NEW_UPI_METHOD.ENTER_UPI_ID}
                onChangeText={onChangeVPA}
                returnKeyType="go"
                hint={vpaHasError ? NEW_UPI_METHOD.VPA_ERROR : ""}
                error={vpaHasError}
                accessoryRight={() => (
                    <VerifyUpiId
                        disabled={!canVerify}
                        onPress={onPressVerify}
                        loading={verifyLoading}
                        verified={vpaVerified}
                    />
                )}
            />

            <LinearGradient
                start={linearGradientElement.start}
                end={linearGradientElement.end}
                colors={linearGradientElement.colors}
                style={styles.shieldUPI}>
                <Stack
                    direction={"row-reverse"}
                    justifyContent="flex-end"
                    spacing={SpacingValue["space-x-small"]}>
                    <Text category="b3" weight="regular" color="medium">
                        {NEW_UPI_METHOD.UPI_SAVED_SECURELY}
                    </Text>
                    <CdnImage width={16} height={18} id={SHIELD_UPI} />
                </Stack>
            </LinearGradient>
        </Stack>
    );
};

const VerifyUpiId: React.FC<{
    disabled: boolean;
    onPress: () => void;
    loading: boolean;
    verified: boolean;
}> = ({ disabled, onPress: onPressVerify, loading, verified }) => {
    const isDisabled = React.useMemo(() => disabled || loading, [disabled, loading]);

    const onPress = React.useCallback(() => {
        if (isDisabled || verified) {
            return;
        }

        onPressVerify();
    }, [isDisabled, onPressVerify, verified]);

    return (
        <Text
            color={isDisabled && !verified ? "medium" : "primary"}
            weight={!isDisabled ? "medium" : "regular"}
            suppressHighlighting
            onPress={onPress}
            style={styles.verifiedText}>
            {loading ? <ThreeDotsLoader color="color-primary" /> : verified ? "Verified" : "Verify"}
        </Text>
    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-xxx-small"],
    },
    verifiedText: {
        paddingLeft: SpacingValue["space-medium"],
    },
    shieldUPI: {
        padding: SpacingValue["space-small"],
        paddingLeft: SpacingValue["space-medium"],
        borderRadius: 16,
        gap: 8,
    },
});

export const NewUPI = React.memo(NewUPIComponent);
