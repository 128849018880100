import { TextProps } from "@swiggy-private/rn-dls";

export const TITLE = "Other Recommended Products";

export const CARD_PROPS = {
    IMAGE_SIZE: 140,
    NAME_PROPS: {
        category: "b2",
        weight: "medium",
    } as TextProps,
};

export const TEST_IDS = {
    CONTAINER: "recommended-products-container",
    TITLE: "recommended-products-title",
    CAROUSEL_COMPONENT: "recommended-products-carousel",
};

export const DEBOUNCE_TIME = 300;
