import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";
import { CartCoupon } from "@minis-consumer/interfaces/coupon";

import { CouponComponentProps } from ".";
import { CouponComponentData } from "./types";

export const getCouponCardData = (
    props: CouponComponentProps & { isSignedIn: boolean },
): CouponComponentData => {
    // default data
    const couponCardData: CouponComponentData = {
        headerIconColor: "color-critical-100",
        headerTitle: "Coupons & discounts",
        headerTitleColor: "color-basic-60",
        headerDescription: "View all coupons",
        headerDescriptionColor: "color-basic-60",
        headerCtaColor: "color-basic-60",
        onClickHeaderCta: props.onViewAllCoupons,
    };

    const bestCoupon = props.bestCoupon;
    const appliedCoupon = props.appliedCoupon;

    // no eligible or applied coupons are present
    if (!bestCoupon?.coupon && !appliedCoupon?.applied) {
        return {
            ...couponCardData,
            headerCtaIcon: "ChevronRight",
        };
    }

    // best coupon exists and applied coupon is not applied or is invalid
    if (bestCoupon?.coupon && !appliedCoupon?.applied) {
        return {
            ...couponCardData,
            headerIconColor: "color-critical-100",
            headerTitle: getDiscountText(bestCoupon),
            headerTitleColor: "color-critical-100",
            headerDescription: "Use code: ",
            headerDescriptionBoldText: bestCoupon.coupon.code,
            headerCtaText: "Apply",
            headerCtaColor: "color-primary",
            onClickHeaderCta: props.onApply,

            footerText: props.isSignedIn ? "View all coupons" : "",
            footerTextColor: "color-basic-60",
            footerCtaIcon: "ChevronRight",
            footerCtaColor: "color-basic-60",
            onClickFooterCta: props.onViewAllCoupons,
            segmentedOffer: bestCoupon.coupon.segmentedOffer,
        };
    }

    // if applied coupon exists but best coupon doesn't
    if (appliedCoupon) {
        const data: CouponComponentData = {
            ...couponCardData,
            headerDescription: appliedCoupon.applied
                ? `You've saved ₹${formatNumberWithIndianSystem(
                      appliedCoupon.discountAmount,
                  )} on this order`
                : appliedCoupon.message,
            headerDescriptionColor: appliedCoupon.applied ? "color-basic-60" : "color-critical-300",
            headerCtaText: appliedCoupon.applied ? "Remove" : "",
            headerCtaColor: appliedCoupon.applied ? "color-critical-300" : "color-basic-60",
            onClickHeaderCta: appliedCoupon.applied ? props.onRemove : undefined,
            segmentedOffer: appliedCoupon.segmentedOffer,
        };

        if (appliedCoupon.applied) {
            data.headerIconColor = "color-critical-100";
            data.headerTitle = `${appliedCoupon.code} applied`;
            data.headerTitleColor = "color-critical-100";
        } else {
            data.footerText = "View all coupons";
            data.footerTextColor = "color-basic-60";
            data.footerCtaIcon = "ChevronRight";
            data.footerCtaColor = "color-basic-60";
            data.onClickFooterCta = props.onViewAllCoupons;
        }

        return data;
    }

    return couponCardData;
};

const getDiscountText = (cartCoupon: CartCoupon): string => {
    const discountText = `Save ₹${formatNumberWithIndianSystem(
        cartCoupon.discountAmount ?? cartCoupon.coupon.maxDiscountAmount,
    )} off on this order`;

    return discountText;
};
