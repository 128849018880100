import React from "react";
import { Platform, StyleSheet } from "react-native";
import { MixedStyleDeclaration } from "react-native-render-html";

import { SpacingValue, useLayout } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import {
    getParsedDescriptionWithImageContainer,
    isHTMLString,
} from "@swiggy-private/common-helpers";

import { Product } from "@minis-consumer/interfaces/catalog";
import { IMAGE_CONTAINER_CLASS } from "@minis-consumer/routes/product/constants";
import { RenderHTMLCommon } from "@minis-consumer/components/render-html";

interface ProductDescriptionProps {
    description: Product["description"];
    customBodyStyle?: MixedStyleDeclaration;
}

const isWeb = Platform.OS === "web";
const IMAGE_SIZE = 240;

const RenderHTMLDescriptionComponent: React.FC<ProductDescriptionProps> = ({
    description,
    customBodyStyle,
}) => {
    const [parentLayout, onLayout] = useLayout();

    const parsedDescription = React.useMemo(() => {
        const descriptionData = description ?? "";

        try {
            if (isHTMLString(description)) {
                return getParsedDescriptionWithImageContainer(
                    descriptionData,
                    IMAGE_CONTAINER_CLASS,
                );
            }

            /** TO replace \n with br to handle existing non html descriptions */
            const fixWithBrTags = descriptionData?.replace(/\n/g, "<br>");
            return fixWithBrTags;
        } catch (err) {
            return descriptionData;
        }
    }, [description]);

    if (!description) {
        return null;
    }

    const listItemTagStyles: MixedStyleDeclaration = {
        width: parentLayout.width - SpacingValue["space-xx-large"],
        flexWrap: isWeb ? "wrap" : undefined,
    };

    const imageClassStyles: MixedStyleDeclaration = {
        width: IMAGE_SIZE,
        height: IMAGE_SIZE,
        resizeMode: "cover",
        alignSelf: "center",
    };

    const imageContainerStyles: MixedStyleDeclaration = {
        borderRadius: 8,
        marginVertical: SpacingValue["space-large"],
        width: IMAGE_SIZE,
        height: IMAGE_SIZE,
        justifyContent: "center",
        alignSelf: "center",
        overflow: "hidden",
    };

    return (
        <Box style={styles.details} onLayout={onLayout}>
            <RenderHTMLCommon
                html={parsedDescription}
                classesStyles={{
                    image: imageClassStyles,
                    [IMAGE_CONTAINER_CLASS]: imageContainerStyles,
                }}
                listItemTagStyle={listItemTagStyles}
                bodyStyle={customBodyStyle}
            />
        </Box>
    );
};

export const RenderHTMLDescription = React.memo(RenderHTMLDescriptionComponent);

const styles = StyleSheet.create({
    details: {
        marginTop: SpacingValue["space-medium"],
    },
});
