import React from "react";

import { usePaymentSelector } from "./use-selector";
import { getPaymentAmount } from "../helpers";

export const usePaymentAmount = (): string => {
    const paymentAmount = usePaymentSelector((state) => state.paymentAmount);

    const formattedPaymentAmount = React.useMemo(
        () => getPaymentAmount(paymentAmount),
        [paymentAmount],
    );

    return formattedPaymentAmount;
};
