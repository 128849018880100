import React from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { Text, SpacingValue, useTheme, TextProps } from "@swiggy-private/rn-dls";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { AlertTypes, TextStyleProps } from "./types";

export interface AlertProps {
    type: AlertTypes;
    text?: string;
    ctaText?: string;
    ctaCallback?: () => void;
}

const AlertComponent: React.FC<AlertProps> = ({ type, text, ctaText, ctaCallback }) => {
    const { value: theme } = useTheme();

    const containerStyles = React.useMemo<ViewStyle>(() => {
        if (type === "WARNING") {
            return {
                borderColor: theme["color-warning-100"],
                backgroundColor: theme["color-warning-25"],
            };
        }

        return {
            borderColor: theme["color-critical-50"],
            backgroundColor: theme["color-critical-25"],
        };
    }, [theme, type]);

    const textColor = React.useMemo<TextProps["color"]>(() => {
        if (type === "WARNING") {
            return "color-warning-800";
        }

        return "color-critical-400";
    }, [type]);

    const textStyles = useSelectScreen<TextStyleProps>({
        default: { category: "b2" },
        lg: { category: "s3" },
    });

    const ctaStyles = useSelectScreen<TextStyleProps>({
        default: { category: "btn5" },
        lg: { category: "btn3" },
    });

    const ctaMarginTop = useSelectScreen({
        default: SpacingValue["space-medium"],
        lg: SpacingValue["space-large"],
    });

    const containerPaddingHorizontal = useSelectScreen({
        default: SpacingValue["space-medium"],
        lg: SpacingValue["space-x-large"],
    });

    const containerPaddingVertical = useSelectScreen({
        default: SpacingValue["space-medium"],
        lg: SpacingValue["space-x-large"],
    });

    return (
        <Box
            ph={containerPaddingHorizontal}
            pv={containerPaddingVertical}
            style={[styles.container, containerStyles]}>
            <Text color={textColor} category={textStyles.category} weight={textStyles.weight}>
                {text}
            </Text>

            {ctaText ? (
                <Box alignItems="flex-start" mt={ctaMarginTop}>
                    <Text
                        color={textColor}
                        category={ctaStyles.category}
                        weight={ctaStyles.weight}
                        onPress={ctaCallback}
                        suppressHighlighting>
                        {ctaText}
                    </Text>
                </Box>
            ) : null}
        </Box>
    );
};

export const Alert = React.memo(AlertComponent);

const styles = StyleSheet.create({
    container: {
        borderRadius: 16,
        borderWidth: 1,
    },
});
