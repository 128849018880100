import React from "react";
import { ViewStyle, StyleSheet, Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { SpacingValue, Surface } from "@swiggy-private/rn-dls";
import { FocusAwareStatusBar, useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { useNavigation } from "@react-navigation/core";

import { Divider } from "@minis-consumer/components/divider";
import { Slate } from "@minis-consumer/components/slate";
import { Shimmer } from "@minis-consumer/components/shimmer";

import { getImageAreaWidth } from "../../helpers";

const ProductViewPlaceholder: React.FC = () => {
    const inset = useSafeAreaInsets();
    const navigation = useNavigation();

    const stackDirection: ViewStyle["flexDirection"] = useSelectScreen({
        lg: "row",
        md: "row",
        default: "column",
    });

    const border = useSelectScreen({
        lg: SpacingValue["space-medium"],
        default: 0,
    });

    const contentPadding = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        md: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const placeholderDetailSectionMarginTop = useSelectScreen({
        lg: 0,
        md: 0,
        default: SpacingValue["space-medium"],
    });

    const imageContainer = useSelectScreen({
        lg: {
            width: "50%",
        },
        md: {
            width: "60%",
        },
        default: {
            width: "100%",
        },
    });

    const stackSpacing = useSelectScreen({
        lg: SpacingValue["space-medium"],
        md: SpacingValue["space-x-large"],
        default: 0,
    });

    const imageSize = getImageAreaWidth();

    const contentStyle = {
        borderTopLeftRadius: border,
        borderTopRightRadius: border,
        paddingHorizontal: contentPadding,
        paddingTop: contentPadding,
        paddingBottom:
            Platform.OS === "android" || Platform.OS === "ios" ? inset.bottom + 20 : contentPadding,
    };

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerTitle: () => <HeaderPlaceholder />,
        });
    }, [navigation]);

    return (
        <Surface style={[styles.content, contentStyle]}>
            <Stack
                direction={stackDirection}
                spacing={{
                    lg: stackSpacing,
                    md: stackSpacing,
                    default: stackSpacing,
                }}>
                <Box style={imageContainer}>
                    <Slate height={imageSize} borderRadius={16} flex={1} />
                </Box>

                <Box flex={1}>
                    <Slate
                        height={18}
                        width={"30%"}
                        marginTop={placeholderDetailSectionMarginTop}
                    />
                    <Slate height={24} marginTop={SpacingValue["space-x-small"]} />
                    <Slate height={24} marginTop={SpacingValue["space-x-small"]} />
                    <Slate height={20} width={"25%"} marginTop={SpacingValue["space-x-large"]} />
                    <Divider style={styles.mt24} />
                    <Stack
                        style={styles.mt16}
                        justifyContent="space-between"
                        direction="row"
                        spacing={{
                            default: SpacingValue["space-large"],
                        }}>
                        <Box flex={1}>
                            <Slate height={18} />
                            <Slate
                                height={16}
                                width={"80%"}
                                marginTop={SpacingValue["space-x-small"]}
                            />
                        </Box>
                        <Box flex={1}>
                            <Slate height={42} />
                        </Box>
                    </Stack>
                    <Divider style={styles.mt16} />
                    <Stack
                        style={styles.mt16}
                        direction="row"
                        spacing={{
                            default: SpacingValue["space-large"],
                        }}>
                        <Box flex={1}>
                            <Slate height={16} />
                            <Slate
                                height={12}
                                width={"80%"}
                                marginTop={SpacingValue["space-x-small"]}
                            />
                        </Box>
                        <Box flex={1}>
                            <Stack
                                direction="row"
                                spacing={{
                                    default: SpacingValue["space-large"],
                                }}>
                                <Box flex={1}>
                                    <Slate height={32} borderRadius={8} />
                                </Box>
                                <Box flex={1}>
                                    <Slate height={32} borderRadius={8} />
                                </Box>
                                <Box flex={1}>
                                    <Slate height={32} borderRadius={8} />
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                    <Divider style={styles.mt16} />
                    <Slate height={24} width={"60%"} marginTop={SpacingValue["space-medium"]} />
                    <Slate height={12} width={"70%"} marginTop={SpacingValue["space-x-small"]} />
                    <Slate height={12} width={"65%"} marginTop={SpacingValue["space-x-small"]} />
                    <Slate height={12} width={"65%"} marginTop={SpacingValue["space-x-small"]} />
                    <Slate height={12} width={"65%"} marginTop={SpacingValue["space-x-small"]} />
                    <Divider style={styles.mt16} />
                    <Stack
                        style={styles.mt24}
                        justifyContent="space-between"
                        direction="row"
                        spacing={{
                            default: SpacingValue["space-large"],
                        }}>
                        <Box flex={1}>
                            <Slate height={46} />
                        </Box>
                        <Box flex={3}>
                            <Slate height={22} width={"80%"} />
                            <Slate
                                height={16}
                                width={"80%"}
                                marginTop={SpacingValue["space-x-small"]}
                            />
                        </Box>
                    </Stack>
                    <Slate height={18} width={"45%"} marginTop={SpacingValue["space-x-large"]} />
                    <Slate height={64} marginTop={SpacingValue["space-x-small"]} />
                </Box>
            </Stack>

            <FocusAwareStatusBar
                translucent={true}
                backgroundColor="transparent"
                barStyle="dark-content"
            />
        </Surface>
    );
};

const styles = StyleSheet.create({
    content: {
        flex: 1,
        elevation: 0,
    },
    mt24: {
        marginTop: SpacingValue["space-x-large"],
    },
    mt16: {
        marginTop: SpacingValue["space-medium"],
    },
});

export default React.memo(ProductViewPlaceholder);

const HeaderPlaceholder: React.FC = () => {
    return (
        <Box flex={1} direction="row" alignItems="center" justifyContent="flex-start">
            <Shimmer width={100} height={16} />
        </Box>
    );
};
