import React from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { SpacingValue, Surface, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { ConsumerAddressResponse } from "@swiggy-private/connect-address-ui";

import { displayAddressNameAndIcon, getFormattedAddress } from "@minis-consumer/helpers/address";

interface Props {
    address: ConsumerAddressResponse;
}

const CardItemComponent: React.FC<Props> = ({ address }) => {
    const { value: theme } = useTheme();

    const { addressTag, icon } = displayAddressNameAndIcon(address);

    const containerStyles: ViewStyle = {
        borderColor: theme["color-basic-15"],
        minHeight: 100,
    };

    return (
        <Surface style={styles.container}>
            <Box
                flex={1}
                pv={SpacingValue["space-medium"]}
                ph={SpacingValue["space-medium"]}
                style={[
                    styles.container,
                    containerStyles,
                    { backgroundColor: theme["color-basic-0"] },
                ]}>
                <Stack spacing={SpacingValue["space-small"]} flex={1}>
                    <Stack
                        spacing={SpacingValue["space-x-small"]}
                        direction="row"
                        alignItems="center">
                        <SvgIcon icon={icon} height={16} width={16} color="color-basic-100" />
                        <Text category="b1" weight="bold" color="high">
                            {addressTag}
                        </Text>
                    </Stack>

                    <Box flex={1}>
                        <Text category="b2" color="medium">
                            {getFormattedAddress(address)}
                        </Text>
                    </Box>
                </Stack>
            </Box>
        </Surface>
    );
};

export const CardItem = React.memo(CardItemComponent);

const styles = StyleSheet.create({
    container: {
        borderRadius: 10,
    },
});
