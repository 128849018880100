import { useCallback, useRef } from "react";

import { createOrderApi, CreateOrderParams, CreateOrderResponse } from "@minis-consumer/api/order";
import { getAddressStorageMeta } from "@minis-consumer/helpers/address";
import { useCartInstructions, useCartViewData, useLocalCart } from "@minis-consumer/hooks/use-cart";

import { usePaymentStoreId } from "./use-payment-store";

export const useCreateOrder = (): ((
    params?: CreateOrderParams,
) => Promise<CreateOrderResponse>) => {
    const storeId = usePaymentStoreId() ?? "0";
    const cart = useRef(useCartViewData(storeId)).current;

    const [cartInstructions, setCartInstructions] = useCartInstructions(storeId);
    const guestUserDetails = useRef(useLocalCart(storeId)?.guestDetails).current;

    return useCallback(
        async (params?: CreateOrderParams) => {
            const _storeId = params?.storeId || storeId;

            const billAmount = params?.totalBillAmount || cart?.bill.billTotal || 0;
            const instructions = cartInstructions || "";

            let addressIdForLoggedInCart;
            let isAddressIdValid;
            let addressesMeta;
            let addressMeta;

            const isPhysicalCart = cart?.cartType === "PHYSICAL" || !cart?.cartType;

            if (isPhysicalCart) {
                addressIdForLoggedInCart = params?.addressId || cart?.serviceableAddressId || "";
                isAddressIdValid =
                    addressIdForLoggedInCart && addressIdForLoggedInCart !== "latLng";

                addressesMeta = await getAddressStorageMeta();
                addressMeta = addressIdForLoggedInCart
                    ? addressesMeta?.addresses?.[addressIdForLoggedInCart]
                    : undefined;
            }

            setCartInstructions("");

            return createOrderApi({
                storeId: _storeId,
                addressId: isAddressIdValid ? addressIdForLoggedInCart : undefined,
                totalBillAmount: billAmount,
                addressMeta: params?.addressMeta || addressMeta,
                instructions,
                guestUserDetails: isAddressIdValid ? undefined : guestUserDetails,
            });
        },
        [
            cart?.bill.billTotal,
            cart?.serviceableAddressId,
            cartInstructions,
            guestUserDetails,
            setCartInstructions,
            storeId,
            cart?.cartType,
        ],
    );
};
