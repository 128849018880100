import React, { useContext } from "react";

import { Text, TextProps } from "@swiggy-private/rn-dls";
import { ServiceabilityType } from "@swiggy-private/connect-address-ui";

import { SelectedAddressInfoContext } from "@minis-consumer/contexts/selected-address-context";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { getDisplayTextAndStyles } from "./helpers";

const AddressLabelPrefixComponent: React.FC<{
    hasError?: boolean;
    serviceabilityType?: ServiceabilityType;
}> = ({ hasError, serviceabilityType }) => {
    const storeInfo = useStoreInfo();
    const selectedAddressCtx = useContext(SelectedAddressInfoContext);

    const addressId = selectedAddressCtx?.selectedAddressInfo?.addressId;
    const deliveryEta = selectedAddressCtx?.selectedAddressInfo?.deliveryEta;
    const postalCode = selectedAddressCtx?.selectedAddressInfo?.postalCode;

    const displayTextAndStyles: {
        text: string;
        color: TextProps["color"];
    } | null = React.useMemo(
        () =>
            getDisplayTextAndStyles({
                addressId,
                postalCode,
                deliveryEta,
                storeName: storeInfo?.name,
                hasError,
                serviceabilityType,
            }),
        [addressId, deliveryEta, storeInfo?.name, postalCode, hasError, serviceabilityType],
    );

    if (!displayTextAndStyles) {
        return null;
    }

    return (
        <Text category="b2" color={displayTextAndStyles.color}>
            {displayTextAndStyles.text}
        </Text>
    );
};

export const AddressLabelPrefix = React.memo(AddressLabelPrefixComponent);
