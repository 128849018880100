import React from "react";
import {
    Animated,
    Pressable,
    PressableProps,
    StyleProp,
    StyleSheet,
    ViewStyle,
} from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";

export type IconContainerProps = Omit<PressableProps, "style"> & {
    style?: Animated.WithAnimatedValue<StyleProp<ViewStyle>>;
};
export const IconContainer: React.FC<React.PropsWithChildren<IconContainerProps>> = React.memo(
    (props) => {
        const {
            accessible = true,
            accessibilityRole = "button",
            style,
            children,
            ...restProps
        } = props;

        return (
            <Pressable
                accessible={accessible}
                accessibilityRole={accessibilityRole}
                style={styles.iconWrapper}
                {...restProps}>
                <Animated.View style={[styles.iconContainer, style]}>{children}</Animated.View>
            </Pressable>
        );
    },
);

if (__DEV__) {
    IconContainer.displayName = "IconContainer";
}

const styles = StyleSheet.create({
    iconContainer: {
        width: 36,
        height: 36,
        justifyContent: "center",
        alignItems: "center",
    },
    iconWrapper: {
        marginLeft: SpacingValue["space-x-small"],
    },
});
