import React, { useContext } from "react";
import { Keyboard } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { Box, Container, Display, ScreenSize, Stack } from "@swiggy-private/rn-adaptive-layout";
import { Modal, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { ConsumerAddressResponse } from "@swiggy-private/connect-address-ui";

import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { AddressRouteList } from "@minis-consumer/interfaces/route";
import { useUpdateCartAddress } from "@minis-consumer/hooks/use-cart";
import { SelectedAddressInfoContext } from "@minis-consumer/contexts/selected-address-context";

import { EnterAddressDetails } from "./components/enter-address-details";
import { HeaderRight } from "../../components/header-right";
import { HeaderLeft } from "../../components/header-left";

type Props = NativeStackScreenProps<AddressRouteList, "AddressForm">;

export const PickupAddressForm: React.FC<Props> = ({ route, navigation }) => {
    const { params } = route;

    const updateCartAddressesCallback = useUpdateCartAddress(params.storeId ?? "0");
    const selectedAddressCtx = useContext(SelectedAddressInfoContext);

    const isCartInvocator = React.useMemo(() => Boolean(params.storeId), [params.storeId]);

    const refreshCart = React.useCallback(
        (id: string) => {
            updateCartAddressesCallback(id);
        },
        [updateCartAddressesCallback],
    );

    const [showModal, setShowModal] = React.useState(true);

    const goBack = React.useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
    }, [navigation]);

    const navigateToBaseScreen = React.useCallback(
        (address?: ConsumerAddressResponse) => {
            if (address) {
                selectedAddressCtx?.updateSelectedAddressInfo?.({
                    ...selectedAddressCtx.selectedAddressInfo,
                    lng: address.lng,
                    lat: address.lat,
                    postalCode: Number(address.postalCode),
                    addressId: address.id,
                    addressLabel: address.displayName,
                });
            }

            Keyboard.dismiss();
            if (navigation.getParent()?.canGoBack()) {
                navigation.getParent()?.goBack();
            }
        },
        [navigation, selectedAddressCtx],
    );

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerTitle: "",
            title: "",
            headerLeft: () => <HeaderLeft onPressBack={goBack} title="Enter address details" />,
            headerRight: () => <HeaderRight progressNumber={3} totalProgressNumber={3} />,
        });
    }, [goBack, navigation]);

    return (
        <StateContextContainer>
            <Display gt={ScreenSize.Large}>
                <Container fluid={false} disableGutters>
                    <Modal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        dismissOnPressingBackdrop={false}
                        modalStyles={{
                            modalBodyStyle: {
                                maxWidth: 600,
                                width: 600,
                                height: "66%",
                            },
                            backdropStyle: {
                                backgroundColor: "transparent",
                            },
                        }}
                        component={
                            <Box flex={1}>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    style={{
                                        marginLeft: SpacingValue["space-medium"],
                                        paddingVertical: SpacingValue["space-xx-small"],
                                    }}>
                                    <SvgIcon icon="ArrowLeft" onPress={goBack} />
                                    <Text
                                        category="h5"
                                        weight="bold"
                                        color="high"
                                        style={{ paddingHorizontal: SpacingValue["space-medium"] }}>
                                        Add your delivery address
                                    </Text>
                                </Stack>
                                <EnterAddressDetails
                                    onPressCTA={navigateToBaseScreen}
                                    onEditPress={goBack}
                                    refreshCart={isCartInvocator ? refreshCart : undefined}
                                />
                            </Box>
                        }
                    />
                </Container>
            </Display>

            <Display lt={ScreenSize.Medium}>
                <Container fluid={false} disableGutters>
                    <EnterAddressDetails
                        onPressCTA={navigateToBaseScreen}
                        onEditPress={goBack}
                        refreshCart={isCartInvocator ? refreshCart : undefined}
                    />
                </Container>
            </Display>
        </StateContextContainer>
    );
};
