import React from "react";
import { useTheme, Button } from "@swiggy-private/rn-dls";

import { CartErrorStatus } from "../../types";
import { CART_ERROR } from "../../constants";
import { Platform, ViewStyle } from "react-native";

interface Props {
    orderStatus: CartErrorStatus;
    onCtaPress: () => void;
}

const CartErrorCtaComponent: React.FC<Props> = ({ orderStatus, onCtaPress }) => {
    const { value: theme } = useTheme();

    const fontColor = React.useMemo(() => {
        const color = CART_ERROR[orderStatus].ctaTextColor || "color-basic-75";
        return theme[color];
    }, [orderStatus, theme]);

    const borderColor = React.useMemo(() => {
        const color = CART_ERROR[orderStatus].ctaBorderColor || "color-basic-30";
        return theme[color];
    }, [orderStatus, theme]);

    const backgroundColor = React.useMemo(() => {
        const color = CART_ERROR[orderStatus].ctaBackgroundColor || "color-basic-30";
        return theme[color];
    }, [orderStatus, theme]);

    const btnStyle: ViewStyle = {
        backgroundColor,
        borderColor,
        maxHeight: 52,
        flex: Platform.OS === "web" ? 1 : undefined,
    };

    const btnTextStyle = {
        color: fontColor,
    };

    return (
        <Button color="tertiary" style={btnStyle} textStyle={btnTextStyle} onPress={onCtaPress}>
            {CART_ERROR[orderStatus].ctaText}
        </Button>
    );
};

export const CartErrorCta = React.memo(CartErrorCtaComponent);
