import React, { useContext } from "react";
import { StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { InView } from "@swiggy-private/react-native-ui";
import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { SelectedAddressInfoContext } from "@minis-consumer/contexts/selected-address-context";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import {
    ANALYTICS_COPIES,
    COPIES,
} from "@minis-consumer/routes/shop/components/delivery-info/constants";

interface Props {
    productId: string;
}

export const AddressLabel: React.FC<Props> = React.memo(({ productId }) => {
    const { settings, storeId } = useStoreInfo();

    const selectedAddressCtx = useContext(SelectedAddressInfoContext);
    const { value: theme } = useTheme();

    const addressLabel = `${
        selectedAddressCtx?.selectedAddressInfo?.addressLabel
            ? `${selectedAddressCtx?.selectedAddressInfo?.addressLabel} - `
            : ""
    } ${selectedAddressCtx?.selectedAddressInfo?.postalCode}`;

    const addressPickerStyle = {
        backgroundColor: theme["color-basic-5"],
    };

    const onImpressionAddress = React.useCallback(
        (isVisible: boolean) => {
            if (isVisible) {
                Analytics.impressionEvent({
                    category: ANALYTICS_COPIES.DEL_INFO_ADDRESS,
                    context: JSON.stringify({
                        store_id: storeId,
                        product_id: productId,
                    }),
                });
            }
        },
        [productId, storeId],
    );

    const addressLabelStyle = {
        width: useSelectScreen({
            lg: 120,
            default: 200,
        }),
    };

    return (
        <InView onChange={onImpressionAddress}>
            <Stack
                direction="row"
                spacing={SpacingValue["space-xx-small"]}
                alignItems="center"
                justifyContent="space-between"
                style={[addressPickerStyle, styles.addressPicker]}>
                <Stack direction="row" alignItems="center">
                    <SvgIcon
                        icon="Truck"
                        height={24}
                        width={24}
                        color="color-basic-60"
                        style={styles.addressIcon}
                    />

                    {selectedAddressCtx?.selectedAddressInfo?.postalCode ? (
                        <Text category="b2" weight="regular" color="color-basic-60">
                            {COPIES.DELIVER_TO}
                            <Text
                                category="b2"
                                weight="bold"
                                color="color-basic-100"
                                numberOfLines={1}
                                style={[styles.addressLabel, addressLabelStyle]}>
                                {addressLabel}
                            </Text>
                        </Text>
                    ) : (
                        <Text category="b2" weight="bold" color="color-basic-75">
                            {COPIES.GET_DEL_INFO}
                        </Text>
                    )}
                </Stack>

                <SvgIcon icon="ChevronRight" color={settings?.brandColor} height={20} width={20} />
            </Stack>
        </InView>
    );
});

if (__DEV__) {
    AddressLabel.displayName = "AddressLabel";
}

const styles = StyleSheet.create({
    addressPicker: {
        borderRadius: 12,
        paddingHorizontal: SpacingValue["space-small"] + SpacingValue["space-xxx-small"],
        paddingVertical: SpacingValue["space-small"],
        marginBottom: SpacingValue["space-xx-small"] + SpacingValue["space-xxx-small"],
        marginTop: SpacingValue["space-medium"] + SpacingValue["space-xxx-small"],
    },
    addressIcon: {
        marginRight: SpacingValue["space-x-small"],
    },
    addressLabel: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "100%",
    },
});
