import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { NavigationProp, useNavigation } from "@react-navigation/core";

import { useMount } from "@swiggy-private/react-hooks";
import { DashBorderLine, InView } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";
import { convertSecondsToMinutesAndSeconds } from "@minis-consumer/helpers/time";
import { useReorderItems } from "@minis-consumer/hooks/use-reorder-items";
import { PaymentProgress } from "@minis-consumer/includes/payment-progress";
import { Order } from "@minis-consumer/interfaces/order";
import { RouteList } from "@minis-consumer/interfaces/route";
import { getOrderListInfo } from "@minis-consumer/routes/account/routes/my-account/helper";
import { useItemsGist } from "@minis-consumer/routes/account/routes/my-account/hooks/use-items-gist";
import { useLinking } from "@minis-consumer/hooks/use-linking";

import { RatingContainer } from "../../rating-container";
import { EVENTS_MAP, JOIN_MEET_TEXT } from "../../constants";
import { OrderStatus } from "../order-status";

const MAX_PAYMENT_TIME_ALLOWED_IN_SECONDS = 5 * 60;

interface Props {
    order: Order;
    goToOrder: () => void;
    isActive?: boolean;
}

const OrderInformationComponent: React.FC<Props> = ({ order, goToOrder, isActive }) => {
    const { value: theme } = useTheme();
    const itemList = useItemsGist(order.items, order.orderType);
    const navigation = useNavigation<NavigationProp<RouteList>>();
    const reorderItems = useReorderItems(order.storeDetails.id, order.storeDetails.slug);
    const openUrl = useLinking();

    const [timer, setTimer] = React.useState<number>();

    const orderInfo = React.useMemo(
        () =>
            getOrderListInfo(
                order.status,
                order.storeDetails.name,
                order.orderType ?? "PHYSICAL",
                order.shippingDetails?.courierPartner,
            ),
        [
            order.orderType,
            order.shippingDetails?.courierPartner,
            order.status,
            order.storeDetails.name,
        ],
    );

    const ctaType = orderInfo?.ctaType;
    const appointmentLink = order.metadata?.appointmentLink;

    const orderedOn = React.useMemo(
        () => convertToReadableDateAndTime(order.createdAt),
        [order.createdAt],
    );

    const displayTimer = React.useMemo(
        () => convertSecondsToMinutesAndSeconds(timer || 0),
        [timer],
    );

    const transactionFailed = order.status === "TRANSACTION_FAILED";
    const orderDelivered = order.status === "DELIVERED";
    const showMeetJoinButton =
        order.orderType === "APPOINTMENT" && appointmentLink && order.status === "ACCEPTED";

    const joinMeetAnalyticsData = React.useMemo(() => {
        return {
            label: order.id,
            context: JSON.stringify({
                status: order.status,
                orderId: order.id,
                vendorId: order.storeDetails.id,
                meetJoinPresent: !!appointmentLink,
            }),
        };
    }, [appointmentLink, order]);

    useMount(() => {
        if (!transactionFailed) {
            return;
        }

        const now = Date.now() / 1000;
        const maxTime = order.createdAt + MAX_PAYMENT_TIME_ALLOWED_IN_SECONDS;

        if (now >= maxTime) {
            return;
        }

        const secondsRemaining = maxTime - now;

        if (secondsRemaining <= 0) {
            return;
        }

        setTimer(Math.round(secondsRemaining));

        const interval = setInterval(() => {
            setTimer((prev) => {
                if (prev && prev > 0) {
                    return prev - 1;
                }

                clearInterval(interval);
                return 0;
            });
        }, 1_000);

        return () => {
            interval && clearInterval(interval);
        };
    });

    const onImpression = React.useCallback(
        (isVisbile: boolean) => {
            if (!isVisbile) {
                return;
            }

            const analyticsData = {
                label: order.id,
                context: JSON.stringify({
                    status: order.status,
                    refundId: order.refundDetails?.refundId,
                }),
            };

            if (ctaType) {
                Analytics.impressionEvent({
                    category: EVENTS_MAP[ctaType],
                    ...analyticsData,
                });
            }

            if (appointmentLink) {
                Analytics.impressionEvent({
                    screen: appointmentLink ? "order-listing-page" : undefined,
                    category: "active-orders-imp",
                    ...joinMeetAnalyticsData,
                });
            }
        },
        [ctaType, order, appointmentLink],
    );

    const onReorder = React.useCallback(() => {
        Analytics.clickEvent({
            category: "past-orders-reorder-btn",
            label: order.id,
            context: JSON.stringify({ status: order.status }),
        });

        reorderItems(order.items);
    }, [order.id, order.items, order.status, reorderItems]);

    const onRetryPayment = React.useCallback(() => {
        Analytics.clickEvent({
            category: "active-orders-retry-payment-btn",
            label: order.id,
            context: JSON.stringify({ status: order.status }),
        });

        navigation.navigate("Payment", { screen: "PaymentHome", params: { orderId: order.id } });
    }, [navigation, order.id, order.status]);

    const onCheckRefund = React.useCallback(() => {
        Analytics.clickEvent({
            category: "past-orders-refund-btn",
            label: order.id,
            context: JSON.stringify({
                status: order.status,
                refundId: order.refundDetails?.refundId,
            }),
        });

        goToOrder();
    }, [goToOrder, order.id, order.status]);

    const onPressCta = React.useCallback(async () => {
        if (!orderInfo) {
            return;
        }

        if (ctaType === "REORDER") {
            onReorder();
        } else if (ctaType === "RETRY_PAYMENT") {
            await PaymentProgress.clear();
            onRetryPayment();
        } else if (ctaType === "CHECK_REFUND") {
            onCheckRefund();
        }
    }, [ctaType, onCheckRefund, onReorder, onRetryPayment, orderInfo]);

    const onPressJoin = React.useCallback(() => {
        if (!appointmentLink) {
            return;
        }

        openUrl(appointmentLink);
        Analytics.clickEvent({
            screen: appointmentLink ? "order-listing-page" : undefined,
            category: "active-order-join-meet",
            ...joinMeetAnalyticsData,
        });
    }, [appointmentLink, joinMeetAnalyticsData, openUrl]);

    if (!orderInfo) {
        return null;
    }

    return (
        <InView onChange={onImpression}>
            <Stack spacing={SpacingValue["space-small"]}>
                <Stack direction="row" alignItems="center">
                    <Box direction="column" flex={1}>
                        <Text category="b1" weight="bold" color={orderInfo.titleColor ?? "high"}>
                            {orderInfo.title}
                        </Text>

                        {orderInfo.subTitle ? (
                            <Text category="b2" color="color-basic-60">
                                {orderInfo.subTitle}
                            </Text>
                        ) : null}
                    </Box>

                    <Box>
                        {showMeetJoinButton ? (
                            <Pressable
                                onPress={onPressJoin}
                                style={[styles.joinButton, { backgroundColor: theme.positive }]}>
                                <Text category="b3" weight="bold" color={"color-basic-0"}>
                                    {JOIN_MEET_TEXT}
                                </Text>
                            </Pressable>
                        ) : null}
                    </Box>
                </Stack>

                <Stack spacing={SpacingValue["space-xxx-small"]}>
                    <Text category="b2" color="color-basic-60">
                        {itemList.title}
                    </Text>
                    {itemList.subtitle ? (
                        <Text category="b3" color="color-basic-60">
                            {itemList.subtitle}
                        </Text>
                    ) : null}

                    <Text category="b3" color="color-basic-45">
                        Ordered on {orderedOn} &bull; Order ID: #{order.id}
                    </Text>
                </Stack>

                {transactionFailed && timer ? (
                    <Text category="b2" weight="medium" color="critical">
                        Payment failed. To continue with this order, please retry payment within{" "}
                        {displayTimer} mins.
                    </Text>
                ) : null}

                {isActive && !transactionFailed ? null : (
                    <DashBorderLine style={styles.mv0} borderColor={theme["color-basic-5"]} />
                )}

                <OrderStatus order={order} />

                {orderDelivered ? (
                    <RatingContainer
                        orderId={order.id}
                        orderStatus={order.status}
                        rating={order.rating?.value || 0}
                        storeName={order.storeDetails.name}
                        storeId={order.storeDetails.id}
                    />
                ) : null}

                {ctaType ? (
                    <Button
                        onPress={onPressCta}
                        color={ctaType === "RETRY_PAYMENT" ? "positive" : "tertiary"}
                        elevation={1}
                        textStyle={
                            ctaType === "RETRY_PAYMENT"
                                ? undefined
                                : {
                                      color: theme["color-positive"],
                                  }
                        }>
                        {ctaType === "REORDER"
                            ? "Reorder"
                            : ctaType === "CHECK_REFUND"
                            ? "Check refund status"
                            : "Retry Payment"}
                    </Button>
                ) : null}
            </Stack>
        </InView>
    );
};

export const OrderInformation = React.memo(OrderInformationComponent);

const styles = StyleSheet.create({
    mv0: {
        marginTop: 0,
        marginBottom: 0,
    },
    bullet: {
        height: 8,
        width: 8,
        borderRadius: 4,
    },
    squareBullet: {
        height: 8,
        width: 8,
        borderRadius: 1,
    },
    joinButton: {
        borderRadius: 33,
        height: 36,
        minHeight: 36,
        paddingVertical: 0,
        borderWidth: 0,
        paddingHorizontal: 12,
        alignItems: "center",
        justifyContent: "center",
        marginLeft: SpacingValue["space-medium"],
    },
});
