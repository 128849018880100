import * as React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { Stack, useSelectScreen, Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";
import { InView, useMeasure } from "@swiggy-private/react-native-ui";

import { ProductCarousel } from "@minis-consumer/components/catalog/product-carousel";
import { ProductBadge, Product } from "@minis-consumer/interfaces/catalog";
import { getBadgeCategoryNativeId } from "@minis-consumer/helpers/catalog";
import { ICatalogSectionsPos } from "@minis-consumer/interfaces/catalog";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";
import { Analytics } from "@minis-consumer/analytics";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";

import { SectionHeading } from "../../../section-separator";
interface BadgeProductCarouselProps {
    badge: ProductBadge;
    products: Product[];
    style?: StyleProp<ViewStyle>;
    catalogMeasureRef: React.RefObject<ICatalogSectionsPos>;
}

export const BadgeProductCarousel: React.FC<BadgeProductCarouselProps> = ({
    products,
    badge,
    style,
    catalogMeasureRef,
}) => {
    const [gutter] = useSelectScreen({
        lg: [SpacingValue["space-x-large"], "h3"] as const,
        md: [SpacingValue["space-x-large"], "h5"] as const,
        default: [SpacingValue["space-medium"], "h4"] as const,
    });

    const sectionHeaderRef = React.useRef<View>(null);
    const measure = useMeasure(sectionHeaderRef.current);

    const separator = React.useMemo(() => <View style={{ width: gutter }} />, [gutter]);

    React.useEffect(() => {
        if (!measure || !catalogMeasureRef.current) {
            return;
        }

        catalogMeasureRef.current[badge.id] = sectionHeaderRef.current;
    }, [catalogMeasureRef, badge.id, measure]);

    const onViewImpression = (isVisible: boolean): void => {
        if (!isVisible) {
            return;
        }

        Analytics.impressionEvent({
            category: `${badge.name.toLocaleLowerCase()}-${
                SF_ANALYTICS_EVENT_NAMES.BADGE_WIDGET_SUFFIX
            }`,
            context: `items: ${products.map((item) => item.id)}`,
        });
    };

    return (
        <InView onChange={onViewImpression}>
            <Stack
                direction="column"
                style={style}
                nativeID={getBadgeCategoryNativeId(badge.id)}
                testID={TEST_IDS_SF.BADGE_PRODUCT}>
                <Box ref={sectionHeaderRef} collapsable={false} ml={-16}>
                    <SectionHeading title={badge.name} />
                </Box>
                <ProductCarousel
                    sfWidget={badge.name}
                    data={products}
                    style={[styles.carousel, { marginHorizontal: -gutter }]}
                    ListHeaderComponent={separator}
                    ListFooterComponent={separator}
                />
            </Stack>
        </InView>
    );
};

const styles = StyleSheet.create({
    carousel: {
        marginTop: SpacingValue["space-small"],
        paddingBottom: SpacingValue["space-x-large"],
    },
});
