import * as React from "react";

import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextProps } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { OrderBill, OrderItem, PaymentType } from "@minis-consumer/interfaces/order";
import { Coupon } from "@minis-consumer/interfaces/coupon";

import { ORDER_PAYMENT_TYPE_MAP, SECTION_TITLE } from "./constants";
import BillCard from "./components/bill-card";
import { getOrderItemsCategoryList } from "./helper";

type BillDetailsProps = {
    items: OrderItem[];
    bill: OrderBill;
    paymentMethod: PaymentType;
    appliedCoupon?: Coupon["code"];
};

const BillDetails: React.FC<BillDetailsProps> = ({ items, bill, paymentMethod, appliedCoupon }) => {
    const { itemsBill, itemTotal, finalItemTotal, billTotal, itemDiscount, couponDiscount } = bill;

    const orderPaymentType = React.useMemo(() => {
        return ORDER_PAYMENT_TYPE_MAP[paymentMethod] ?? null;
    }, [paymentMethod]);

    const titleCategory: TextProps["category"] = useSelectScreen({
        lg: "b2",
        default: "b3",
    });

    const { normalItems, customItems } = getOrderItemsCategoryList({ items, itemsBill });

    const aggregateDetails = {
        itemTotal,
        finalItemTotal,
        billTotal,
        itemDiscount,
        couponDiscount,
    };

    return (
        <Stack
            spacing={{
                lg: SpacingValue["space-x-large"],
                default: 10,
            }}>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={{
                    lg: SpacingValue["space-x-small"],
                    default: 10,
                }}>
                <SvgIcon icon="ArrowDown" width={10} height={10} color="color-basic-45" />
                <Text category={titleCategory} weight="medium" color="color-basic-45">
                    {SECTION_TITLE}
                </Text>
                <SvgIcon icon="ArrowDown" width={10} height={10} color="color-basic-45" />
            </Stack>

            <BillCard
                normalItems={normalItems}
                customItems={customItems}
                deliveryFee={bill.deliveryFee}
                otherCharges={bill.charges}
                aggregateDetails={aggregateDetails}
                orderPaymentType={orderPaymentType}
                appliedCoupon={appliedCoupon}
            />
        </Stack>
    );
};

export default React.memo(BillDetails);
