import React from "react";

import { SpacingValue, useTheme, Text } from "@swiggy-private/rn-dls";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartView } from "@minis-consumer/hooks/use-cart";

import { CartErrorsV2Type, CART_V2_ERRORS_STATUS } from "../../constants";
import { getMessageDataToDisplay } from "../helpers";

const CartErrorsV2Component: React.FC = () => {
    const { value: theme } = useTheme();

    const storeInfo = useStoreInfo();
    const cart = useCartView(storeInfo.storeId);

    const contentPadding = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        md: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const messageDataToDisplay = React.useMemo(() => {
        return getMessageDataToDisplay({
            cartStatus: cart?.cartStatus as CartErrorsV2Type,
            cartType: cart?.cartViewData?.cartType ?? "PHYSICAL",
        });
    }, [cart]);

    const contentStyle = {
        padding: contentPadding,
        backgroundColor: theme[messageDataToDisplay?.backgroundColor ?? "negative_Sub"],
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
    };

    if (
        !cart?.cartStatus ||
        (CART_V2_ERRORS_STATUS.indexOf(cart?.cartStatus) < 0 && cart?.cartStatus !== "VALID_CART")
    ) {
        return null;
    }
    if (!messageDataToDisplay) {
        return null;
    }

    return (
        <Box style={contentStyle}>
            <Text category="b2" color={messageDataToDisplay?.textColor ?? "negative"}>
                {messageDataToDisplay?.message}
            </Text>
        </Box>
    );
};

export const CartErrorsV2 = CartErrorsV2Component;
