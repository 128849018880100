import React from "react";
import { ViewStyle } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";

import { CartAddressState } from "@minis-consumer/interfaces/cart";

interface Props {
    state: CartAddressState;
}

const DeliveryManagementDisclaimerComponent: React.FC<Props> = ({ state }) => {
    const { value: theme } = useTheme();
    const storeInfo = useStoreInfo();
    const cart = useCartViewData(storeInfo.storeId);

    const dispatchTimeNote = React.useMemo(() => {
        const dispatchTime = cart?.store.settings.dispatchTime;

        if (dispatchTime) {
            if (dispatchTime.min === dispatchTime.max) {
                return `. Orders are usually dispatched in ${dispatchTime.min} day(s)`;
            } else {
                return `. Orders are usually dispatched in ${dispatchTime.min} - ${dispatchTime.max} days`;
            }
        }

        return "";
    }, [cart?.store.settings.dispatchTime]);

    if (state === "UNSELECTED") {
        const containerStyles: ViewStyle = {
            backgroundColor: theme["color-critical-25"],
            borderRadius: 12,
        };

        return (
            <Box
                ph={SpacingValue["space-medium"]}
                pv={SpacingValue["space-medium"]}
                style={containerStyles}>
                <Stack direction="row" spacing={SpacingValue["space-medium"]} alignItems="center">
                    <CdnImage
                        isImageKitEnabled
                        id="minis/parachute_delivery"
                        width={28}
                        height={36}
                    />
                    <Stack spacing={SpacingValue["space-xxx-small"]}>
                        <Text category="b2" color="high" weight="bold">
                            The order delivery is managed by {storeInfo.name}.
                        </Text>

                        <Text category="b3" color="high">
                            Tracking information will be available once the order is dispatched
                        </Text>
                    </Stack>
                </Stack>
            </Box>
        );
    }

    if (state === "SELECTED_SUCCESS") {
        const containerStyles: ViewStyle = {
            backgroundColor: theme["color-basic-5"],
            borderRadius: 8,
        };

        return (
            <Box
                style={containerStyles}
                ph={SpacingValue["space-x-small"]}
                pv={SpacingValue["space-x-small"]}>
                <Text category="b2" weight="medium" color="medium">
                    {`All deliveries are managed by ${storeInfo.name}${dispatchTimeNote}`}
                </Text>
            </Box>
        );
    }

    const containerStyles: ViewStyle = {
        backgroundColor: theme["color-critical-25"],
        borderRadius: 8,
    };

    return (
        <Box
            style={containerStyles}
            ph={SpacingValue["space-x-small"]}
            pv={SpacingValue["space-x-small"]}>
            <Text category="b2" weight="medium" color="color-critical-500">
                {storeInfo.name} doesn’t provide delivery services here.
            </Text>
        </Box>
    );
};

export const DeliveryManagementDisclaimer = React.memo(DeliveryManagementDisclaimerComponent);
