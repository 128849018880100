import { useContext } from "react";
import { PaymentLayoutStateContext, TPaymentLayout } from "../contexts/payment-layout-context";

export const usePaymentLayoutUpdates = (): TPaymentLayout => {
    const ctx = useContext(PaymentLayoutStateContext);
    if (!ctx) {
        throw new Error("usePaymentLayoutUpdates must be used within a PaymentLayoutProvider");
    }
    return ctx;
};
