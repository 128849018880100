import * as React from "react";
import { StyleSheet } from "react-native";

import { Display, ScreenSize, Stack } from "@swiggy-private/rn-adaptive-layout";
import { ActivityIndicator, SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { ShopLoaderMobileView, CatalogLoaderMobileView } from "./mobile-view";

export const ShopLoader: React.FC = React.memo(() => {
    return (
        <>
            <Display gt={ScreenSize.Large}>
                <ActivityIndicator color="color-primary" size="large" style={styles.indicator} />
            </Display>

            <Display lt={ScreenSize.Medium}>
                <ShopLoaderMobileView />
            </Display>
        </>
    );
});

ShopLoader.displayName = "ShopLoader";

export const CatalogLoader: React.FC = () => {
    const { value: theme } = useTheme();
    return (
        <Stack
            flex={1}
            flexGrow={1}
            alignItems="center"
            style={{
                padding: SpacingValue["space-large"],
                backgroundColor: theme["color-basic-0"],
            }}>
            <Display gt={ScreenSize.Large}>
                <ActivityIndicator color="color-primary" size="large" style={styles.indicator} />
            </Display>

            <Display lt={ScreenSize.Medium}>
                <CatalogLoaderMobileView />
            </Display>
        </Stack>
    );
};

const styles = StyleSheet.create({
    indicator: {
        marginTop: "25%",
    },
});
