import * as React from "react";
import { LayoutChangeEvent, ViewStyle } from "react-native";

import { BottomSheet } from "@swiggy-private/react-native-ui";
import { DialogContent, SpacingValue } from "@swiggy-private/rn-dls";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { PRODUCT_TYPE } from "@minis-consumer/interfaces/catalog";

import { DialogBody } from "./components/dialog-body";
import { useSafeAreaInsets } from "react-native-safe-area-context";

type PreCheckoutDialog = {
    showDialog: boolean;
    closeDialog: () => void;
    onPressContinue: () => void;
    cartId: string;

    cartType?: PRODUCT_TYPE;
    onLayout?: (e: LayoutChangeEvent) => void;
};

const PreCheckoutDialogComponent: React.FC<PreCheckoutDialog> = ({
    showDialog,
    closeDialog,
    onPressContinue,
    cartId,
    cartType,
    onLayout,
}) => {
    const insets = useSafeAreaInsets();

    const modalMaxWidth = useSelectScreen({
        lg: 420 as ViewStyle["width"],
        default: "100%",
    });

    const modalStyles = useSelectScreen({
        default: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            paddingBottom: Math.max(
                insets.bottom + SpacingValue["space-xx-small"],
                SpacingValue["space-large"],
            ),
        },

        lg: {
            borderRadius: 16,
            padding: SpacingValue["space-x-large"],
        },
    });

    if (!showDialog) {
        return null;
    }

    return (
        <BottomSheet
            disableBackdropClose={false}
            open={showDialog}
            onClose={closeDialog}
            style={[modalStyles, { width: modalMaxWidth }]}>
            <DialogContent onLayout={onLayout}>
                <DialogBody onPressContinue={onPressContinue} cartId={cartId} cartType={cartType} />
            </DialogContent>
        </BottomSheet>
    );
};

export const PreCheckoutDialog = React.memo(PreCheckoutDialogComponent);
