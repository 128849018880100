import React from "react";

import { AsyncComponent } from "@minis-consumer/components/async-component";
import { FAQHelpPlaceholder } from "@minis-consumer/routes/account/components/faq-help-placeholder";

import { HelpContainer } from "./help-container";

export const HelpContentFetcher: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <AsyncComponent fallback={FAQHelpPlaceholder}>
            <HelpContainer>{children}</HelpContainer>
        </AsyncComponent>
    );
};
