import React, { FC, memo, useCallback, useMemo } from "react";
import { Platform, Pressable, StyleSheet, ViewStyle } from "react-native";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { ImageGalleryList } from "@swiggy-private/rn-image-gallery";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";
import { OrderEntity } from "@minis-consumer/resources/order";
import { RouteList } from "@minis-consumer/interfaces/route";

import { DATE_FORMAT, ICON_SIZE, VIEW_ON_CHAT } from "../constants";

interface ReturnInfoProps {
    order: OrderEntity;
}

const ReturnInfoComponent: FC<ReturnInfoProps> = ({ order }) => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();

    const { returnDetails, storeId } = order || {};
    const { reason, comments, images, createdAt } = returnDetails?.order || {};

    const createdAtTime = useMemo(() => {
        return createdAt ? convertToReadableDateAndTime(createdAt, DATE_FORMAT) : "";
    }, [createdAt]);

    const innerContainerStyles: ViewStyle = {
        borderWidth: theme["border-width"],
        borderColor: theme["color-basic-30"],
        backgroundColor: theme["color-basic-5"],
    };

    const dottedTriangleSideStyles = {
        backgroundColor: theme["color-basic-5"],
    };

    const navigateToChatScreen = useCallback(() => {
        navigation.navigate("ChatConversation", { storeId });
    }, [navigation, storeId]);

    return (
        <Stack spacing={SpacingValue["space-small"]}>
            <Stack spacing={SpacingValue["space-x-small"]}>
                <Box mb={SpacingValue["space-x-small"]}>
                    <Text category="b1" weight="bold" color="highest">
                        {reason}
                    </Text>
                </Box>

                <Stack style={[styles.commentContainer, innerContainerStyles]}>
                    <Box style={[styles.left, dottedTriangleSideStyles]}>
                        <DashBorderLine style={styles.mt0} borderColor={theme["color-basic-30"]} />
                    </Box>
                    <Box style={[styles.right, dottedTriangleSideStyles]}>
                        <DashBorderLine style={styles.mt0} borderColor={theme["color-basic-30"]} />
                    </Box>

                    <Text style={styles.textStyles} category="b2" color="medium">
                        {`“${comments}”`}
                    </Text>

                    {images ? (
                        <Stack style={styles.imageListContainer}>
                            <ImageGalleryList
                                images={images}
                                imagesVisible={Platform.select({ web: 10 })}
                            />
                        </Stack>
                    ) : null}
                </Stack>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Pressable onPress={navigateToChatScreen}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={SpacingValue["space-xx-small"]}>
                        <Text category="btn5" weight="bold" color="color-positive-500">
                            {VIEW_ON_CHAT}
                        </Text>

                        <SvgIcon
                            icon="External"
                            width={ICON_SIZE}
                            height={ICON_SIZE}
                            color="color-positive-500"
                        />
                    </Stack>
                </Pressable>

                <Text category="b3" color="low">
                    {createdAtTime}
                </Text>
            </Stack>
        </Stack>
    );
};

export const ReturnInfo = memo(ReturnInfoComponent);

const styles = StyleSheet.create({
    commentContainer: {
        borderRadius: 12,
        borderStyle: "dashed",
        paddingVertical: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    triangleContainer: {
        position: "absolute",
        top: -10,
        left: 30,
        borderStyle: "dashed",
    },
    triangleBottom: {
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderTopWidth: 0,
        borderRightWidth: 10,
        borderBottomWidth: 10,
        borderLeftWidth: 10,
        borderTopColor: "transparent",
        borderRightColor: "transparent",
        borderLeftColor: "transparent",
    },
    imageListContainer: { marginTop: SpacingValue["space-x-small"] },
    textStyles: {
        fontStyle: "italic",
    },
    mt0: {
        marginTop: 0,
    },
    left: {
        position: "absolute",
        width: 14,
        height: 8,
        top: -7,
        left: 36,
        transform: [{ rotate: "44deg" }],
    },
    right: {
        position: "absolute",
        width: 14,
        height: 8,
        top: -7,
        left: 30,
        transform: [{ rotate: "-44deg" }],
    },
});
