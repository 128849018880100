import * as React from "react";
import { Platform, StyleSheet } from "react-native";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { StoreCloseSubtextAndCta } from "@minis-consumer/components/store-close-subtext-and-cta";
import { useMinisNavigation } from "@minis-consumer/hooks/use-minis-navigation";

import { CartAlert } from "../cart-alert";

const LARGE_IMAGE_SIZE = 316;
const SMALL_IMAGE_SIZE = 200;

const StoreDeletedCartViewComponent: React.FC = () => {
    const bottomBarHeight = useBottomTabBarHeight();

    const store = useStoreInfo();

    const navigation = useMinisNavigation();

    const [height, width, marginTop] = useSelectScreen({
        lg: [LARGE_IMAGE_SIZE, LARGE_IMAGE_SIZE, 2 * SpacingValue["space-xx-large"]],
        md: [LARGE_IMAGE_SIZE, LARGE_IMAGE_SIZE, 2 * SpacingValue["space-xx-large"]],
        default: [
            SMALL_IMAGE_SIZE,
            SMALL_IMAGE_SIZE,
            Platform.OS !== "web" ? 0 : 2 * SpacingValue["space-xx-large"],
        ],
    });

    const marginBottom = useSelectScreen({
        lg: 0,
        md: 0,
        default: bottomBarHeight,
    });

    const navigateToHome = React.useCallback(() => {
        navigation?.navigate("MinisHome", { screen: "Explore" });
    }, [navigation]);

    return (
        <Box
            flex={1}
            mh={SpacingValue["space-medium"]}
            mv={SpacingValue["space-medium"]}
            pb={SpacingValue["space-medium"]}
            justifyContent={Platform.OS !== "web" ? "space-between" : "flex-start"}
            alignItems="center"
            style={{ marginBottom }}>
            <Box style={styles.fullWidth}>
                <CartAlert cartStatus="STORE_INACTIVE" storeId={store.storeId} />
            </Box>

            <CdnImage
                isImageKitEnabled
                id="minis/cart_store_deleted"
                height={height}
                width={width}
                style={{ marginTop }}
            />

            {Platform.OS !== "web" ? (
                <StoreCloseSubtextAndCta
                    ctaMarginTop={SpacingValue["space-medium"]}
                    onPressCta={navigateToHome}
                />
            ) : null}
        </Box>
    );
};

const styles = StyleSheet.create({
    fullWidth: {
        width: "100%",
    },
});

export const StoreDeletedCartView = React.memo(StoreDeletedCartViewComponent);
