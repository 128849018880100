import React, { useEffect } from "react";
import { StyleSheet, ScrollView, useWindowDimensions, View } from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";
import { Modal } from "@swiggy-private/rn-dls";

import { Cart, UserAddress } from "@minis-consumer/interfaces/cart";
import { Card } from "@minis-consumer/components/card";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { useCartStatus } from "@minis-consumer/hooks/use-cart";
import { CartAddressState } from "@minis-consumer/interfaces/cart";

import { CardHeader } from "./components/card-header";
import { SelectedAddress } from "./components/selected-address";
import { DeliveryManagementDisclaimer } from "./components/delivery-management-disclaimer";
import { AddressList } from "./components/address-list";

interface Props {
    addresses: NonNullable<Cart["cartViewData"]>["addresses"];
    setAddressSelected: (address: UserAddress | null) => void;
    addressChosenAlready?: UserAddress | null;
    isOpen?: boolean;
    setOpen?: (b: boolean) => void;
    showAddressInModal?: boolean;
    shouldShowUnserviceable?: boolean;
}

interface RenderInModalProps {
    addresses: UserAddress[];
    setAddressSelected: (address: UserAddress | null) => void;
    addressChosenAlready?: UserAddress | null;
    setOpen: (b: boolean) => void;
    showAddressInModal?: boolean;
    setAddressIdSelected: (s: string) => void;
    setAddressState: (s: CartAddressState) => void;
    addressIdSelected: string;
    shouldShowUnserviceable?: boolean;
    addressSelected: boolean;
    addressState: CartAddressState;
    isOpen: boolean;
}

interface RenderInCardProps {
    addresses: UserAddress[];
    setAddressSelected: (address: UserAddress | null) => void;
    addressChosenAlready?: UserAddress | null;
    setOpen?: (b: boolean) => void;
    showAddressInModal?: boolean;
    setAddressIdSelected: (s: string) => void;
    setAddressState: (s: CartAddressState) => void;
    addressIdSelected: string;
    shouldShowUnserviceable?: boolean;
    addressSelected: boolean;
    addressState: CartAddressState;
}

const RenderInModal: React.FC<RenderInModalProps> = ({
    addresses,
    setAddressSelected,
    addressChosenAlready,
    setOpen,
    showAddressInModal,
    setAddressIdSelected,
    setAddressState,
    addressIdSelected,
    shouldShowUnserviceable,
    addressSelected,
    addressState,
    isOpen,
}) => {
    const { height: windowHeight } = useWindowDimensions();

    return (
        <Modal
            showModal={isOpen}
            setShowModal={setOpen}
            dismissOnPressingBackdrop={true}
            modalStyles={{
                modalBodyStyle: {
                    maxWidth: 600,
                    width: 600,
                    paddingBottom: SpacingValue["space-x-large"],
                },
            }}
            component={
                <Stack
                    spacing={
                        addressSelected
                            ? SpacingValue["space-medium"]
                            : SpacingValue["space-x-large"]
                    }>
                    <CardHeader state={addressState} addressLength={addresses.length} />
                    <ScrollView
                        bounces={false}
                        showsVerticalScrollIndicator={false}
                        keyboardShouldPersistTaps="handled"
                        contentContainerStyle={styles.scrollContainer}>
                        <View style={[{ height: windowHeight * 0.5 }]}>
                            <AddressList
                                addresses={addresses}
                                setAddressSelected={setAddressSelected}
                                addressChosenAlready={addressChosenAlready}
                                setOpen={setOpen}
                                showAddressInModal={showAddressInModal}
                                setAddressIdSelected={setAddressIdSelected}
                                setAddressState={setAddressState}
                                addressIdSelected={addressIdSelected}
                                shouldShowUnserviceable={shouldShowUnserviceable}
                            />
                        </View>
                    </ScrollView>
                </Stack>
            }
        />
    );
};

const RenderInCard: React.FC<RenderInCardProps> = ({
    addresses,
    setAddressSelected,
    addressChosenAlready,
    setOpen,
    showAddressInModal,
    setAddressIdSelected,
    setAddressState,
    addressIdSelected,
    shouldShowUnserviceable,
    addressSelected,
    addressState,
}) => {
    const addressSelectedData = React.useMemo(() => {
        const data = addresses?.filter((adrs) => adrs?.id === addressIdSelected);
        return data?.[0];
    }, [addressIdSelected, addresses]);

    const onChangeAddress = React.useCallback(() => {
        setAddressSelected(null);
        setAddressState("UNSELECTED");
    }, [setAddressSelected, setAddressState]);

    return (
        <Card>
            <Stack
                spacing={
                    addressSelected ? SpacingValue["space-medium"] : SpacingValue["space-x-large"]
                }>
                <CardHeader state={addressState} addressLength={addresses.length} />

                {addressSelected ? (
                    <SelectedAddress
                        state={addressState}
                        address={addressSelectedData}
                        onChangeAddress={onChangeAddress}
                    />
                ) : (
                    <AddressList
                        addresses={addresses}
                        setAddressSelected={setAddressSelected}
                        addressChosenAlready={addressChosenAlready}
                        setOpen={setOpen}
                        showAddressInModal={showAddressInModal}
                        setAddressIdSelected={setAddressIdSelected}
                        setAddressState={setAddressState}
                        addressIdSelected={addressIdSelected}
                        shouldShowUnserviceable={shouldShowUnserviceable}
                    />
                )}

                <DeliveryManagementDisclaimer state={addressState} />
            </Stack>
        </Card>
    );
};

const AddressLargeScreenComponent: React.FC<Props> = ({
    addresses,
    setAddressSelected,
    addressChosenAlready,
    isOpen = false,
    setOpen,
    showAddressInModal,
    shouldShowUnserviceable,
}) => {
    const store = useStoreInfo();
    const [showToast] = useToast();

    const cartStatus = useCartStatus(store.storeId);

    const [addressIdSelected, setAddressIdSelected] = React.useState(
        addressChosenAlready?.id ?? "",
    );
    const [addressState, setAddressState] = React.useState<CartAddressState>(
        addressIdSelected ? "SELECTED_SUCCESS" : "UNSELECTED",
    );

    const addressSelected = React.useMemo(() => addressState !== "UNSELECTED", [addressState]);

    useMount(() => {
        if (cartStatus === "SELECTED_ADDRESS_UNSERVICEABLE") {
            showToast("Your new address is unserviceable");
            setAddressIdSelected("");
            setAddressState("UNSELECTED");
        }
    });

    useEffect(() => {
        const addressStateVal = addressChosenAlready?.id ? "SELECTED_SUCCESS" : "UNSELECTED";

        setAddressIdSelected(addressChosenAlready?.id ?? "");
        setAddressState(addressStateVal);
    }, [addressChosenAlready]);

    return (
        <>
            {showAddressInModal && setOpen ? (
                <RenderInModal
                    addresses={addresses}
                    setAddressSelected={setAddressSelected}
                    addressChosenAlready={addressChosenAlready}
                    setOpen={setOpen}
                    showAddressInModal={showAddressInModal}
                    setAddressIdSelected={setAddressIdSelected}
                    setAddressState={setAddressState}
                    addressIdSelected={addressIdSelected}
                    shouldShowUnserviceable={shouldShowUnserviceable}
                    addressSelected={addressSelected}
                    addressState={addressState}
                    isOpen={isOpen}
                />
            ) : (
                <RenderInCard
                    addresses={addresses}
                    setAddressSelected={setAddressSelected}
                    addressChosenAlready={addressChosenAlready}
                    setOpen={setOpen}
                    showAddressInModal={showAddressInModal}
                    setAddressIdSelected={setAddressIdSelected}
                    setAddressState={setAddressState}
                    addressIdSelected={addressIdSelected}
                    shouldShowUnserviceable={shouldShowUnserviceable}
                    addressSelected={addressSelected}
                    addressState={addressState}
                />
            )}
        </>
    );
};

export const AddressLargeScreen = React.memo(AddressLargeScreenComponent);

const styles = StyleSheet.create({
    scrollContainer: {
        flexGrow: 1,
    },
});
