import React from "react";

import { useMountedRef } from "@swiggy-private/react-hooks";
import { verifyUPI } from "@minis-consumer/api/payment";
import { Logger } from "@minis-consumer/includes/logger";

interface UseVerifyUPIReturnValue {
    loading: boolean;
    trigger: (vpa: string) => Promise<boolean>;
}

export const useVerifyUPI = (): UseVerifyUPIReturnValue => {
    const mountedRef = useMountedRef();
    const [loading, setLoading] = React.useState(false);

    const trigger = React.useCallback(
        async (vpa: string) => {
            setLoading(true);

            try {
                const response = await verifyUPI({ vpa });
                mountedRef.current && setLoading(false);
                return mountedRef.current && response;
            } catch (err) {
                if (mountedRef.current) {
                    setLoading(false);
                    Logger.recordError(err);
                }

                return false;
            }
        },
        [mountedRef],
    );

    return {
        loading,
        trigger,
    };
};
