import * as React from "react";
import { StyleSheet } from "react-native";

import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { Divider } from "@minis-consumer/components/divider";
import { Shimmer } from "@minis-consumer/components/shimmer";

const TrackingComponentLoading: React.FC = () => {
    const dividerHeight = useSelectScreen({
        lg: SpacingValue["space-large"],
        default: SpacingValue["space-medium"],
    });

    const oddIndexStyles = useSelectScreen({
        lg: "60%",
        default: "80%",
    });

    const evenIndexStyles = useSelectScreen({
        lg: "50%",
        default: "70%",
    });

    return (
        <Stack spacing={SpacingValue["space-x-large"]} flex={1}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                    <Shimmer height={40} width={40} style={styles.br10} />
                    <Stack>
                        <Shimmer height={16} width={80} style={styles.br10} />
                        <Shimmer
                            height={8}
                            width={150}
                            style={[styles.br10, { marginTop: SpacingValue["space-x-small"] }]}
                        />
                    </Stack>
                </Stack>
                <Shimmer height={40} width={40} style={styles.br50} />
            </Stack>

            <Divider />

            <Stack spacing={SpacingValue["space-medium"]}>
                <Stack
                    divider={
                        <Divider
                            horizontal={false}
                            style={[styles.ml10, { height: dividerHeight }]}
                        />
                    }>
                    {Array(5)
                        .fill(1)
                        .map((_, idx) => (
                            <Stack
                                key={idx}
                                spacing={SpacingValue["space-x-small"]}
                                direction="row">
                                <Shimmer height={16} width={16} style={styles.br50} />
                                <Shimmer
                                    height={16}
                                    width={(idx + 1) % 2 === 0 ? evenIndexStyles : oddIndexStyles}
                                    style={styles.br10}
                                />
                            </Stack>
                        ))}
                </Stack>

                <Shimmer height={16} style={styles.br10} width={"25%"} />
            </Stack>
        </Stack>
    );
};

const styles = StyleSheet.create({
    ml10: {
        marginLeft: SpacingValue["space-x-small"],
    },
    br10: {
        borderRadius: 10,
    },
    br50: {
        borderRadius: 50,
    },
});

export default React.memo(TrackingComponentLoading);
