import React from "react";
import { Pressable, ViewStyle } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { RouteList } from "@minis-consumer/interfaces/route";

/** @TODO Implement onPress */
const BackCTAComponent: React.FC = () => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NavigationProp<RouteList>>();

    const onPress = React.useCallback(() => {
        navigation.navigate("Home", {
            screen: "Cart",
            params: {},
        });
    }, [navigation]);

    const containerStyles: ViewStyle = {
        borderRadius: 12,
        borderColor: theme["color-basic-15"],
        backgroundColor: theme["color-basic-5"],
    };

    return (
        <Pressable onPress={onPress}>
            <Box
                style={containerStyles}
                ph={SpacingValue["space-small"]}
                pv={SpacingValue["space-small"]}>
                <SvgIcon icon="ArrowLeft" />
            </Box>
        </Pressable>
    );
};

export const BackCTA = React.memo(BackCTAComponent);
