import React from "react";
import { Pressable, StyleSheet, TextStyle, ViewStyle } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { SlotSelectionComponentProps } from "@minis-consumer/routes/slot-selection/helpers";

const SlotSelectionComponent: React.FC<SlotSelectionComponentProps> = ({
    onPress,
    title,
    subTitle,
    item,
    selectedDate,
    selectedTime,
    extraStyles,
}) => {
    const { value: theme } = useTheme();

    const isSelected = selectedDate === item?.dateEpoch || item?.start === selectedTime;

    const dateStyles: ViewStyle = {
        backgroundColor: theme.background_Secondary,
        borderColor: theme.background_Secondary,
        ...styles.date,
    };

    const selectedStyles: ViewStyle = {
        borderColor: theme.primary,
        backgroundColor: theme.primary_Sub,
    };

    const textStyles: TextStyle = {
        ...(isSelected
            ? {
                  color: theme.primary,
              }
            : {}),
    };

    return (
        <Pressable
            style={[dateStyles, extraStyles, isSelected ? selectedStyles : {}]}
            onPress={onPress}>
            <Box direction="column" justifyContent="center" alignItems="center">
                <Text
                    category={subTitle ? "b1" : "b3"}
                    color="text_High_Emphasis"
                    weight="medium"
                    style={textStyles}>
                    {title}
                </Text>
                {subTitle ? (
                    <Text
                        category="b3"
                        color="text_Med_Emphasis"
                        weight="regular"
                        style={textStyles}>
                        {subTitle}
                    </Text>
                ) : null}
            </Box>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    date: {
        borderWidth: 1,
        borderRadius: 8,
        paddingVertical: SpacingValue["space-xxx-small"] + SpacingValue["space-xx-small"],
        paddingHorizontal: SpacingValue["space-small"],
    },
});

export const SlotSelector = React.memo(SlotSelectionComponent);
