import * as React from "react";
import { StyleSheet } from "react-native";

import {
    Box,
    Stack,
    useSelectScreen,
    Display,
    ScreenSize,
} from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import {
    ProductSectionList,
    ProductSectionListHandler,
} from "@minis-consumer/components/catalog/product-section-list";
import { Divider } from "@minis-consumer/components/divider";
import {
    getBadgeCategoryNativeId,
    getProductCategoryNativeId,
} from "@minis-consumer/helpers/catalog";
import { convertArrayListToRecord } from "@minis-consumer/helpers/object";
import { convertToProperCase } from "@minis-consumer/helpers/string";
import { useGetProductBadges } from "@minis-consumer/hooks/use-get-badges";
import { useGetProducts } from "@minis-consumer/hooks/use-get-products";
import { CatalogLayout, ICatalogSectionsPos } from "@minis-consumer/interfaces/catalog";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";

import { SectionHeading } from "../../components/section-separator";
import { BadgeProductCarousel } from "./components/badge-product-carousel";
import { SidebarCatalogIndex } from "./components/sidebar-catalog-index";
import { useGetBadgesWithProducts } from "./hooks/use-get-badges-with-products";
import { useGetProductCategories } from "./hooks/use-get-categories";

const SECTION_HEADER_TEXT = "What I offer";

export const ShopCatalog: React.FC<{
    catalogMeasureRef: React.RefObject<ICatalogSectionsPos>;
    layout: CatalogLayout;
    showAddToCart?: boolean;
}> = (props) => {
    const badgesWithProducts = useGetBadgesWithProducts();
    const products = useGetProducts();
    const [gutter] = useSelectScreen({
        lg: [SpacingValue["space-x-large"], true],
        md: [SpacingValue["space-x-large"], false],
        default: [SpacingValue["space-medium"], false],
    });

    const { value: theme } = useTheme();

    return (
        <Stack direction="column">
            {badgesWithProducts.map((pair) => (
                <>
                    <BadgeProductCarousel
                        badge={pair[0]}
                        products={pair[1]}
                        key={pair[0].id}
                        catalogMeasureRef={props.catalogMeasureRef}
                        style={{ marginHorizontal: gutter }}
                    />
                    <Display lt={ScreenSize.Medium}>
                        <Box
                            style={[{ backgroundColor: theme["color-basic-15"] }, styles.separator]}
                        />
                    </Display>
                    <Display gt={ScreenSize.Medium}>
                        <Box style={[styles.separator]} />
                    </Display>
                </>
            ))}

            {products?.length > 0 ? <SectionHeading title={SECTION_HEADER_TEXT} /> : null}
            <ShopCatalogFullView {...props} />
        </Stack>
    );
};

type CatalogIndex = Array<{ id: string; name: string }>;

const ShopCatalogFullView: React.FC<{
    catalogMeasureRef: React.RefObject<ICatalogSectionsPos>;
    layout: CatalogLayout;
    showAddToCart?: boolean;
}> = ({ catalogMeasureRef, layout, showAddToCart }) => {
    const categories = useGetProductCategories();
    const products = useGetProducts();
    const productListObject = React.useMemo(() => convertArrayListToRecord(products), [products]);

    const badges = useGetProductBadges();
    const badgesWithProducts = useGetBadgesWithProducts();
    const pslRef = React.useRef<ProductSectionListHandler>(null);

    const catalogIndex: CatalogIndex = React.useMemo(() => {
        return [
            ...badgesWithProducts.map((p) => ({
                id: getBadgeCategoryNativeId(p[0].id),
                name: p[0].name,
            })),
            ...categories.map((c) => ({
                id: getProductCategoryNativeId(c.id),
                name: convertToProperCase(c.name),
            })),
        ];
    }, [badgesWithProducts, categories]);

    const [gutter, showSidebarCatalogIndex] = useSelectScreen({
        lg: [SpacingValue["space-x-large"], true],
        md: [SpacingValue["space-x-large"], false],
        default: [SpacingValue["space-medium"], false],
    });

    if (!categories.length || !products.length) {
        return null;
    }

    return (
        <Stack direction="row" divider={horizontalDivider} testID={TEST_IDS_SF.CATALOG_FULL_VIEW}>
            {showSidebarCatalogIndex && catalogIndex.length > 0 ? (
                <SidebarCatalogIndex index={catalogIndex} />
            ) : null}

            <Stack
                flex={1}
                direction="column"
                divider={verticalDivider}
                spacing={SpacingValue["space-medium"]}
                style={{ marginHorizontal: gutter, marginBottom: SpacingValue["space-x-small"] }}>
                <Box key="product_list">
                    <ProductSectionList
                        products={productListObject}
                        categories={categories}
                        badges={badges}
                        ref={pslRef}
                        catalogMeasureRef={catalogMeasureRef}
                        layout={layout}
                        showAddToCart={showAddToCart}
                    />
                </Box>
            </Stack>
        </Stack>
    );
};

const styles = StyleSheet.create({
    verticalDivider: {
        marginBottom: SpacingValue["space-x-small"],
    },
    separator: {
        height: SpacingValue["space-x-small"],
        marginBottom: SpacingValue["space-x-large"],
    },
    title: {
        flex: 1,
        flexShrink: 0,
        flexBasis: "auto",
        paddingRight: SpacingValue["space-medium"],
        letterSpacing: 1.6,
        textTransform: "uppercase",
    },
});

const horizontalDivider = <Divider horizontal={false} />;
const verticalDivider = <Divider style={styles.verticalDivider} />;
