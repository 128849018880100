import * as React from "react";
import { Pressable, StyleSheet } from "react-native";

import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Surface, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { ShopLogo } from "@minis-consumer/components/shop-logo";
import { getStoreBrandImageUrl } from "@minis-consumer/helpers/store";
import { Divider } from "@minis-consumer/components/divider";

interface OrderAddressLogoProps {
    isStoreEnabled: boolean;
    onShopLogoPress?: () => void;
    storeBrandImageId?: string;
}

const OrderAddressLogo: React.FC<OrderAddressLogoProps> = ({
    isStoreEnabled,
    storeBrandImageId,
    onShopLogoPress,
}) => {
    const { value: theme } = useTheme();

    const logoSize = useSelectScreen({
        lg: 60,
        default: 40,
    });

    const svgSize = useSelectScreen({
        lg: 30,
        default: 20,
    });

    const logoBorderSize = useSelectScreen({
        lg: 16,
        default: 10,
    });

    const dividerHeight = useSelectScreen({
        lg: 48,
        default: 32,
    });

    const logoUrl = storeBrandImageId
        ? getStoreBrandImageUrl(
              storeBrandImageId,
              {
                  height: logoSize,
                  width: logoSize,
              },
              true,
          )
        : null;

    const logoStyle = {
        opacity: isStoreEnabled ? 1 : 0.5,
    };

    return (
        <Stack
            justifyContent="space-between"
            alignItems="center"
            divider={<Divider horizontal={false} style={{ height: dividerHeight }} />}>
            <Pressable onPress={() => onShopLogoPress?.()} hitSlop={12}>
                {logoUrl ? (
                    <ShopLogo
                        isImageKitEnabled
                        size={logoSize}
                        url={logoUrl}
                        borderRadius={logoBorderSize}
                        style={logoStyle}
                    />
                ) : (
                    <Surface
                        style={[
                            styles.svgContainer,
                            {
                                borderRadius: logoBorderSize,
                                height: logoSize,
                                width: logoSize,
                                borderColor: theme["color-basic-30"],
                            },
                        ]}>
                        <SvgIcon
                            icon="Store"
                            style={logoStyle}
                            height={24}
                            width={24}
                            color="color-basic-60"
                        />
                    </Surface>
                )}
            </Pressable>

            <Surface
                style={[
                    styles.svgContainer,
                    {
                        borderRadius: logoBorderSize,
                        height: logoSize,
                        width: logoSize,
                        borderColor: theme["color-basic-30"],
                    },
                ]}>
                <SvgIcon
                    icon="LocationPinFilled"
                    width={svgSize}
                    height={svgSize}
                    color="color-primary"
                />
            </Surface>
        </Stack>
    );
};

const styles = StyleSheet.create({
    svgContainer: {
        alignItems: "center",
        justifyContent: "center",
        elevation: 1,
        borderWidth: StyleSheet.hairlineWidth,
    },
});

export default React.memo(OrderAddressLogo);
