import React from "react";
import { StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

import { Card } from "@minis-consumer/components/card";
import { Slate } from "@minis-consumer/components/slate";

const AddressSSLoadingComponent: React.FC = () => {
    const { value: theme } = useTheme();
    const insets = useSafeAreaInsets();

    const containerPaddingBottom = {
        paddingBottom: Math.max(insets.bottom, SpacingValue["space-x-large"]),
    };

    return (
        <Card style={[styles.container, containerPaddingBottom]}>
            <Box justifyContent="space-between" direction="row">
                <Stack spacing={SpacingValue["space-x-small"]}>
                    <Slate height={24} width={208} borderRadius={8} />
                    <Slate height={16} width={192} borderRadius={8} />
                </Stack>
                <Slate height={48} width={60} borderRadius={8} />
            </Box>
            <DashBorderLine borderColor={theme["color-basic-15"]} />
            <Box justifyContent="space-between" direction="row" style={styles.mv16}>
                <Stack spacing={SpacingValue["space-x-small"]}>
                    <Slate height={24} width={108} borderRadius={8} />
                    <Slate height={16} width={108} borderRadius={8} />
                </Stack>
                <Slate height={48} width={180} borderRadius={8} />
            </Box>
        </Card>
    );
};

const styles = StyleSheet.create({
    container: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    mv16: {
        marginVertical: SpacingValue["space-medium"],
    },
});

export const AddressSSLoader = React.memo(AddressSSLoadingComponent);
