import * as React from "react";
import { StyleSheet } from "react-native";
import { RouteProp, useRoute } from "@react-navigation/native";

import { SpacingValue, Tab, Tabs, TabPanel, TabView, useTheme, Text } from "@swiggy-private/rn-dls";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { HomeRouteList } from "@minis-consumer/interfaces/route";
import { useStoreInstagram } from "@minis-consumer/hooks/use-store-instagram";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useGetConfigInfo } from "@minis-consumer/hooks/use-get-config-info";

import { Overview } from "../overview";
import { ShopImages } from "../photos";

export const AboutTabs: React.FC = () => {
    const storeInfo = useStoreInfo();
    const isStoreEnabled = storeInfo.enabled !== false;

    const { value: theme } = useTheme();
    const route = useRoute<RouteProp<HomeRouteList, "About">>();
    const [tabIndex, setTabIndex] = React.useState(route.params?.defaultTab === "photos" ? 1 : 0);

    const { instagramData } = useStoreInstagram();
    const photos = React.useMemo(() => instagramData?.media?.data, [instagramData?.media?.data]);

    const gutter = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const containerStyle = {
        paddingTop: SpacingValue["space-x-large"],
    };

    const tabsStyle = {
        paddingHorizontal: gutter,
        minHeight: 0,
        borderBottomColor: theme["color-basic-5"],
        borderBottomWidth: 1,
    };

    const tabIndicatorStyle = {
        left: gutter,
        borderTopLeftRadius: SpacingValue["space-xx-small"],
        borderTopRightRadius: SpacingValue["space-xx-small"],
    };

    const isNewServicesEnabled = useGetConfigInfo()?.isNewServicesEnabled;

    return (
        <Box style={containerStyle}>
            {isNewServicesEnabled ? (
                isStoreEnabled ? (
                    <ShopImages
                        photos={photos}
                        handleName={instagramData?.user?.userName}
                        followersCount={instagramData?.user?.followersCount}
                    />
                ) : null
            ) : (
                <TabView index={tabIndex} onIndexChange={setTabIndex}>
                    <Tabs style={tabsStyle} indicatorStyle={tabIndicatorStyle} variant="standard">
                        <Tab style={styles.tab}>Overview</Tab>

                        {isStoreEnabled ? (
                            <Tab style={styles.tab}>
                                <Text>Photos{photos ? ` (${photos.length})` : " (0)"}</Text>
                            </Tab>
                        ) : (
                            <></>
                        )}
                    </Tabs>

                    <TabPanel>
                        <Overview />
                    </TabPanel>

                    {isStoreEnabled ? (
                        <TabPanel>
                            <ShopImages
                                photos={photos}
                                handleName={instagramData?.user?.userName}
                                followersCount={instagramData?.user?.followersCount}
                            />
                        </TabPanel>
                    ) : (
                        <></>
                    )}
                </TabView>
            )}
        </Box>
    );
};

const styles = StyleSheet.create({
    tab: {
        paddingBottom: SpacingValue["space-small"],
        paddingTop: 0,
        alignItems: "center",
        justifyContent: "center",
    },
    tabs: {
        minHeight: 0,
    },
});
