import * as React from "react";
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { Text, SpacingValue, TextProps } from "@swiggy-private/rn-dls";

interface ProductPriceProps {
    price?: number;
    discountedPrice?: number;
    discountPercent?: number;
    stackStyle?: StyleProp<ViewStyle>;
    priceStyle?: TextStyle;
    strikedPriceStyle?: TextStyle;
    discountPercentStyle?: TextStyle;
    finalPriceTextProps?: TextProps;
    strikedPriceTextProps?: TextProps;
    discountPercentTextProps?: TextProps;
    isAvailable?: boolean;
}

const ProductPriceComponent: React.FC<ProductPriceProps> = ({
    price,
    discountedPrice,
    discountPercent,
    stackStyle,
    priceStyle,
    finalPriceTextProps,
    strikedPriceStyle,
    strikedPriceTextProps,
    discountPercentStyle,
    discountPercentTextProps,
    isAvailable,
}) => {
    const showDiscount = React.useMemo(
        () => price != null && discountedPrice != null && price !== discountedPrice,
        [price, discountedPrice],
    );

    const finalPrice = React.useMemo(
        () => (showDiscount ? discountedPrice : price),
        [discountedPrice, price, showDiscount],
    );

    if (!price) {
        return null;
    }

    return (
        <Stack
            spacing={SpacingValue["space-xx-small"]}
            direction="row"
            alignItems="center"
            style={stackStyle}>
            {finalPrice ? (
                <Text
                    category="b2"
                    weight="bold"
                    color={isAvailable ? "color-basic-75" : "color-basic-45"}
                    style={priceStyle}
                    {...finalPriceTextProps}>
                    ₹{finalPrice}
                </Text>
            ) : null}

            {showDiscount ? (
                <Text
                    category="b3"
                    weight="regular"
                    style={[styles.strike, strikedPriceStyle]}
                    color="color-basic-45"
                    {...strikedPriceTextProps}>
                    ₹{price}
                </Text>
            ) : null}

            {discountPercent ? (
                <Text
                    category="b3"
                    weight="medium"
                    color={isAvailable ? "color-warning-700" : "color-basic-45"}
                    style={discountPercentStyle}
                    {...discountPercentTextProps}
                    testID="discount-percent-text">
                    {`(${discountPercent}% Off)`}
                </Text>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    strike: {
        textDecorationLine: "line-through",
    },
});
export const ProductPrice = React.memo(ProductPriceComponent);
