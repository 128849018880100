import React, { Dispatch, FC, memo, SetStateAction, useCallback, useMemo } from "react";
import { Pressable, StyleSheet } from "react-native";

import { CheckBox, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { CdnImage, Divider } from "@swiggy-private/react-native-ui";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { OrderItem as IOrderItem } from "@minis-consumer/interfaces/order";
import { PRODUCT_IMAGE_FALLBACK } from "@minis-consumer/components/widgets/constants";
import { getSelectedVariants } from "@minis-consumer/helpers/variants";

interface OrderItemProps {
    item: IOrderItem;
    selectedItems: IOrderItem[];
    setSelectedItems: Dispatch<SetStateAction<IOrderItem[]>>;
}

const ItemSeparatorComponent: FC = () => <Divider style={styles.divider} />;
export const ItemSeparator = memo(ItemSeparatorComponent);

const OrderItemComponent: FC<OrderItemProps> = ({ item, selectedItems, setSelectedItems }) => {
    const { id, name, imageIds = [], variantId, variantOptionValues } = item?.item || {};

    const isSelected = useMemo(
        () =>
            selectedItems.some((selectedItem) =>
                variantId
                    ? selectedItem?.item?.variantId === variantId
                    : selectedItem?.item?.id === id,
            ),
        [id, selectedItems, variantId],
    );

    const onPress = useCallback(() => {
        if (isSelected) {
            const newSelectedItems = selectedItems.filter(
                (selectedItem) =>
                    selectedItem?.item?.id !== id || selectedItem.item.variantId !== variantId,
            );
            setSelectedItems(newSelectedItems);
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    }, [id, isSelected, item, selectedItems, setSelectedItems, variantId]);

    const nameWithQuantity = `${name} x ${item.quantity}`;
    const variantsLabel = getSelectedVariants(variantOptionValues);

    return (
        <Pressable style={styles.item} onPress={onPress}>
            <CheckBox onChange={onPress} checked={isSelected} />

            <CdnImage
                id={imageIds[0] || PRODUCT_IMAGE_FALLBACK}
                height={54}
                width={54}
                style={styles.image}
            />

            <Box flex={1}>
                <Text
                    category="b1"
                    weight="medium"
                    color="color-basic-75"
                    style={styles.name}
                    numberOfLines={3}
                    ellipsizeMode="middle">
                    {nameWithQuantity}
                </Text>

                {variantsLabel ? (
                    <Text color="medium" category="b3" weight="medium">
                        {variantsLabel}
                    </Text>
                ) : null}
            </Box>
        </Pressable>
    );
};

export const OrderItem = memo(OrderItemComponent);

const styles = StyleSheet.create({
    item: {
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: SpacingValue["space-x-small"],
    },
    image: {
        borderRadius: 8,
        marginHorizontal: SpacingValue["space-x-small"],
    },
    name: {
        alignSelf: "flex-start",
    },
    divider: {
        marginVertical: SpacingValue["space-x-small"],
    },
});
