import React from "react";

import { SpacingValue, useTheme, Input } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { useDebounce } from "@swiggy-private/react-hooks";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartInstructions, useCartView } from "@minis-consumer/hooks/use-cart";
import { CART_V2_ERRORS_STATUS } from "../../constants";

const CartInstructionsComponent: React.FC = () => {
    const { value: theme } = useTheme();
    const storeInfo = useStoreInfo();
    const cart = useCartView(storeInfo.storeId);
    const [stateInstructions, setStateInstructions] = useCartInstructions(storeInfo.storeId);

    const [instructions, setInstructions] = React.useState(stateInstructions || "");
    const debouncedInstructions = useDebounce(instructions, { wait: 300 });

    React.useEffect(() => {
        setStateInstructions(debouncedInstructions);
    }, [debouncedInstructions, setStateInstructions]);

    const inputStyle = {
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-large"],
        borderWidth: 0,
        minHeight: 18,
    };

    const inputTextStyle = {
        fontSize: 14,
        color: theme["color-basic-100"],
        lineHeight: 16,
    };

    if (cart?.cartStatus && CART_V2_ERRORS_STATUS.indexOf(cart?.cartStatus) > -1) {
        return null;
    }

    return (
        <>
            <Box>
                <Input
                    placeholder="Write instructions, gifting ideas etc..."
                    style={inputStyle}
                    textStyle={inputTextStyle}
                    value={instructions}
                    onChangeText={setInstructions}
                />
            </Box>
        </>
    );
};

export const CartInstructions = CartInstructionsComponent;
