import { useFocusEffect } from "@react-navigation/core";
import { useController } from "@rest-hooks/react";
import React, { useCallback, useContext, useEffect } from "react";
import { Pressable, StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { Divider } from "@minis-consumer/components/divider";
import { PincodeForm } from "@minis-consumer/components/pincode-form";
import {
    SelectedAddressInfo,
    SelectedAddressInfoContext,
} from "@minis-consumer/contexts/selected-address-context";
import { useFetchAddressListFromServer } from "@minis-consumer/hooks/use-addresses";
import { useDeliveryEta } from "@minis-consumer/hooks/use-delivery-eta";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useSignedIn } from "@minis-consumer/hooks/use-user";
import { Logger } from "@minis-consumer/index.web";
import { UserAddress } from "@minis-consumer/interfaces/cart";
import { AddressListResource } from "@minis-consumer/resources/address";
import { AddressList } from "@minis-consumer/routes/cart/components/address-list";
import { getDeliveryEtaApiParams } from "@minis-consumer/routes/cart/helpers";
import {
    ANALYTICS_COPIES,
    COPIES,
} from "@minis-consumer/routes/shop/components/delivery-info/constants";

import { AddressLabel } from "./components/address-label";
import { DeliveryInfoPlaceholder } from "./components/delivery-info-placeholder";
import { DeliveryInformation } from "./components/delivery-information";

interface Props {
    productId: string;
}

export const DeliveryInfo: React.FC<Props> = ({ productId }) => {
    const { storeId, address } = useStoreInfo();
    const { invalidate } = useController();
    const isSignedIn = useSignedIn();

    const selectedAddressCtx = useContext(SelectedAddressInfoContext);
    const fetchAddresses = useFetchAddressListFromServer(storeId);
    const { fetchDeliveryEta, deliveryInfo, isLoading } = useDeliveryEta();

    const [showAddressListModal, setShowAddressListModal] = React.useState(false);
    const [postalCode, setPostalCode] = React.useState<string | null>();

    const onSelectAddress = useCallback(
        (addr: UserAddress | null) => {
            if (addr) {
                selectedAddressCtx?.updateSelectedAddressInfo?.({
                    ...selectedAddressCtx.selectedAddressInfo,
                    lng: addr?.lng,
                    lat: addr?.lat,
                    postalCode: addr?.postalCode,
                    addressId: addr?.id,
                    addressLabel: addr?.displayName,
                });
            }
        },
        [selectedAddressCtx],
    );

    const handlePincodeSubmit = useCallback(
        async (pincode?: string) => {
            setPostalCode(pincode);

            const params = getDeliveryEtaApiParams({
                storeId,
                dropPincode: pincode,
                pickupAddress: address,
            });

            fetchDeliveryEta(params);
            setShowAddressListModal(false);
        },
        [address, fetchDeliveryEta, storeId],
    );

    const fetchDeliveryEtaInfo = useCallback(
        async (selectedAddr?: SelectedAddressInfo) => {
            const params = getDeliveryEtaApiParams({
                storeId,
                selectedAddress: selectedAddr,
                pickupAddress: address,
            });

            fetchDeliveryEta(params);
        },
        [address, fetchDeliveryEta, storeId],
    );
    useEffect(() => {
        const selectedAddr = selectedAddressCtx?.selectedAddressInfo;

        if (selectedAddr?.postalCode) {
            fetchDeliveryEtaInfo(selectedAddr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddressCtx?.selectedAddressInfo?.postalCode]);

    useFocusEffect(
        useCallback(() => {
            if (!address) {
                return;
            }
            invalidate(AddressListResource, {
                storeId: storeId,
            }).catch((err) => {
                Logger.recordError(err);
            });

            fetchAddresses();
        }, [address, fetchAddresses, invalidate, storeId]),
    );

    const [showDeliveryInfo, setShowDeliveryInfo] = React.useState(true);

    const toggleDeliveryInfo = React.useCallback(() => {
        setShowDeliveryInfo(!showDeliveryInfo);
    }, [showDeliveryInfo]);

    const dividerStyle = {
        marginTop: SpacingValue["space-x-large"],
        marginBottom: SpacingValue["space-x-large"],
    };

    const onAddressLabelClick = useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.DEL_INFO_ADDRESS,
            context: JSON.stringify({
                store_id: storeId,
                product_id: productId,
            }),
        });
        setShowAddressListModal(true);
    }, [productId, storeId]);

    return (
        <>
            <Pressable onPress={toggleDeliveryInfo} hitSlop={16}>
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                    style={styles.headerContainer}>
                    <Text category="subheader_S1_Bold" weight="bold" color="high">
                        {COPIES.TITLE}
                    </Text>

                    <Box style={styles.caret}>
                        <SvgIcon
                            icon={showDeliveryInfo ? "ChevronUp" : "ChevronDown"}
                            color="color-basic-75"
                            width={20}
                        />
                    </Box>
                </Stack>
            </Pressable>

            {showDeliveryInfo ? (
                <>
                    <Pressable onPress={onAddressLabelClick}>
                        <AddressLabel productId={productId} />
                    </Pressable>
                    <Stack
                        spacing={{
                            default: SpacingValue["space-xx-small"],
                            lg: SpacingValue["space-x-small"],
                        }}
                        style={styles.container}>
                        {isLoading ? (
                            <DeliveryInfoPlaceholder />
                        ) : (
                            <DeliveryInformation
                                postalCode={postalCode}
                                deliveryInfo={deliveryInfo}
                                productId={productId}
                                loading={isLoading}
                            />
                        )}
                    </Stack>
                </>
            ) : null}

            {showAddressListModal && !isSignedIn ? (
                <PincodeForm
                    canOpen={showAddressListModal}
                    setOpen={setShowAddressListModal}
                    handlePincodeSubmit={handlePincodeSubmit}
                />
            ) : null}

            {showAddressListModal && isSignedIn ? (
                <AddressList
                    storeId={storeId}
                    open={showAddressListModal}
                    setOpen={setShowAddressListModal}
                    setAddressSelected={onSelectAddress}
                    shouldShowBanner={false}
                    fetchAddressesFromAddressApi={true}
                    showAddressInModal
                    shouldShowUnserviceable={false}
                />
            ) : null}
            <Divider style={dividerStyle} />
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        width: "100%",
    },
    loadingWrapper: {
        marginTop: SpacingValue["space-xx-small"],
    },
    shimmer: {
        marginTop: SpacingValue["space-x-small"] + SpacingValue["space-xxx-small"],
        borderRadius: 4,
    },
    icon: {
        marginTop: SpacingValue["space-xx-small"],
    },
    deliveryInfoItem: {
        marginTop: SpacingValue["space-x-small"] + SpacingValue["space-xxx-small"],
    },
    dividerStyle: {
        marginTop: SpacingValue["space-xx-small"],
        marginBottom: SpacingValue["space-medium"],
    },
    caret: {
        width: 24,
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    headerContainer: {
        flex: 1,
    },
});
