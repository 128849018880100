import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { MinisRouteList } from "@minis-consumer/interfaces/route";
import { Analytics } from "@minis-consumer/analytics";
import { PAYMENT, PAYNOW_METHOD_ANALYTICS } from "@minis-consumer/routes/payment/constants";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { usePaymentOrder } from "@minis-consumer/routes/payment/hooks/use-payment-order";

interface Props {
    showTitle?: boolean;
}

export const PaymentHeader: React.FC<Props> = ({ showTitle }) => {
    const storeInfo = useStoreInfo();
    const cart = useCartViewData(storeInfo?.storeId);
    const order = usePaymentOrder();
    const navigation = useNavigation<NativeStackNavigationProp<MinisRouteList, "Account">>();

    const analyticsContext = useMemo(() => {
        return JSON.stringify({
            orderId: order?.id || "-",
            cartId: cart?.id || "-",
            productType: cart?.cartType || order?.orderType,
            paymentAmount: cart?.bill?.finalBillTotal || order?.bill?.finalBillTotal,
            transactionId: order?.txnId,
        });
    }, [cart, order]);

    const onPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: PAYNOW_METHOD_ANALYTICS.BACK_BTN,
            context: analyticsContext,
        });

        if (navigation.canGoBack()) {
            navigation.goBack();
            return;
        }

        navigation.replace("MinisHome", { screen: "Explore" });
    }, [navigation, analyticsContext]);

    return (
        <Stack
            direction="row"
            spacing={SpacingValue["space-medium"]}
            alignItems="center"
            style={styles.container}>
            <SvgIcon
                icon="ArrowLeft"
                height={20}
                width={20}
                onPress={onPress}
                hitSlop={{ left: 8, right: 8, top: 8, bottom: 8 }}
                color="color-basic-100"
            />

            {showTitle ? (
                <Text category="b1" weight="medium" color="color-basic-100">
                    {PAYMENT}
                </Text>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: {
        marginLeft: SpacingValue["space-medium"],
    },
});
