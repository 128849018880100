import React, { memo, useCallback } from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme, Text } from "@swiggy-private/rn-dls";

interface IListWithIconComponent {
    list: Array<React.ReactElement | string | undefined>;

    iconComponent?: React.ReactElement;
    containerStyles?: StyleProp<ViewStyle>;
    iconContainerStyles?: StyleProp<ViewStyle>;
}

const ICON_SIZE = 20;

const ListWithIconComponent: React.FC<IListWithIconComponent> = ({
    list = [],
    iconComponent = null,
    containerStyles = {},
    iconContainerStyles = {},
}) => {
    const { value: theme } = useTheme();

    const totalItems = list.length;

    const getItemStyle = useCallback(
        (index: number) => {
            return {
                borderLeftColor:
                    index !== totalItems - 1 && totalItems > 1
                        ? theme["color-basic-15"]
                        : theme["color-basic-0"],
                paddingBottom: totalItems > 1 ? SpacingValue["space-large"] : 0,
            };
        },
        [totalItems, theme],
    );

    if (!totalItems) {
        return null;
    }

    const iconContStyle: ViewStyle = {
        backgroundColor: theme["color-basic-0"],
        width: ICON_SIZE,
        height: ICON_SIZE,
    };

    const nonEmptyList = list.filter((item) => item);

    return (
        <Stack>
            {nonEmptyList.map((component, index) => {
                return (
                    <Stack
                        key={index}
                        direction="row"
                        style={[styles.container, getItemStyle(index), containerStyles]}
                        spacing={SpacingValue["space-small"]}>
                        <Stack
                            alignItems="center"
                            style={[styles.iconContainer, iconContStyle, iconContainerStyles]}>
                            {iconComponent ? (
                                iconComponent
                            ) : (
                                <SvgIcon
                                    icon="TickFilled"
                                    color="color-positive-400"
                                    width={ICON_SIZE}
                                    height={ICON_SIZE}
                                />
                            )}
                        </Stack>
                        {typeof component === "string" ? (
                            <Text category="b2" color="medium">
                                {component}
                            </Text>
                        ) : (
                            component
                        )}
                    </Stack>
                );
            })}
        </Stack>
    );
};

export const ListWithIcon = memo(ListWithIconComponent);

const styles = StyleSheet.create({
    container: {
        borderLeftWidth: 2,
        paddingLeft: SpacingValue["space-xx-small"],
    },
    iconContainer: {
        left: -1 * (ICON_SIZE / 2),
        borderRadius: 100,
        position: "absolute",
    },
});
