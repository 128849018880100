export const CARD_LOGO_MAP: Record<string, string> = {
    /** Visa */
    visa: "minis/visa",

    /** Master Card */
    mastercard: "minis/mastercard",

    /** Rupay */
    rupay: "minis/rupay",

    /** Amex */
    amex: "minis/amex",

    /** Diner's Club */
    diner: "minis/diners",

    /** Discover */
    discover: "",
};
