import React, { Dispatch, SetStateAction, memo, useCallback } from "react";
import { FlatList, StyleSheet } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Text } from "@swiggy-private/rn-dls";

import { Card } from "@minis-consumer/components/card";
import { OrderEntity } from "@minis-consumer/resources/order";
import { OrderItem as IOrderItem } from "@minis-consumer/interfaces/order";

import { ItemSeparator, OrderItem } from "./order-item";
import { DISPLAY_COPIES } from "../constants";

interface SelectReturnProps {
    order: OrderEntity;
    selectedItems: IOrderItem[];
    setSelectedItems: Dispatch<SetStateAction<IOrderItem[]>>;
    onContinuePress: VoidFunction;

    isDisabled?: boolean;
}

const { TITLE, SUBTEXT, CONTINUE } = DISPLAY_COPIES.CARD;

const ReturnSelectCardComponent: React.FC<SelectReturnProps> = ({
    order,
    onContinuePress,
    isDisabled = false,
    selectedItems,
    setSelectedItems,
}) => {
    const renderItem = useCallback(
        ({ item }: { item: IOrderItem }) => (
            <OrderItem {...{ item, selectedItems, setSelectedItems }} />
        ),
        [selectedItems, setSelectedItems],
    );

    return (
        <Card style={styles.card}>
            <Box mb={SpacingValue["space-medium"]}>
                <Text category="b1" weight="bold" color="color-basic-75">
                    {TITLE}
                </Text>
                <Text category="b2" color="color-basic-60">
                    {SUBTEXT}
                </Text>
            </Box>

            <FlatList
                data={order.items}
                renderItem={renderItem}
                ItemSeparatorComponent={ItemSeparator}
                keyExtractor={(item) => item.item.variantId ?? item?.item?.id}
            />

            <ItemSeparator />

            <Button style={styles.btn} onPress={onContinuePress} disabled={isDisabled}>
                {CONTINUE}
            </Button>
        </Card>
    );
};

const styles = StyleSheet.create({
    root: {
        padding: SpacingValue["space-medium"],
    },
    card: {
        flexShrink: 1,
        borderRadius: 4,
    },
    svg: {
        marginRight: SpacingValue["space-medium"],
    },
    btn: {
        marginTop: SpacingValue["space-x-small"],
    },
});

export const ReturnSelectCard = memo(ReturnSelectCardComponent);
