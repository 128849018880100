import React from "react";
import { StyleSheet } from "react-native";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { Card } from "@minis-consumer/components/card";

interface Props {
    title: string;
    children: React.ReactNode;
    hasBGCard?: boolean;
}

export const MethodCard: React.FC<React.PropsWithChildren<Props>> = ({
    title,
    hasBGCard = true,
    children,
}) => {
    const FinalCard = hasBGCard ? Card : Box;
    return (
        <Stack spacing={SpacingValue["space-medium"]}>
            <Text category="b1" weight="bold" color="highest">
                {title}
            </Text>
            <FinalCard style={styles.card}>{children}</FinalCard>
        </Stack>
    );
};

const styles = StyleSheet.create({
    card: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
});
