export const STORE_ONBOARDING_BODY_POINTS = [
    {
        content: "Discover brands, shop, pay and track orders — all in one single app.",
        imgId: "minis/consumer_onboarding_star",
        width: 34,
        height: 34,
    },
    {
        content:
            "Brands on Minis pay zero commission. This means you get to support brands that you believe in.",
        imgId: "minis/consumer_onboarding_heart",
        width: 34,
        height: 34,
    },
    {
        content: "Chat with brands and even customize your orders.",
        imgId: "minis/consumer_onboarding_chat",
        width: 34,
        height: 26,
    },
];
