import * as React from "react";
import { ScrollView, ViewStyle, StyleSheet, Platform } from "react-native";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useSuspense } from "@rest-hooks/react";
import {
    Container,
    useSelectScreen,
    Stack,
    getScreenSize,
    ScreenSize,
} from "@swiggy-private/rn-adaptive-layout";
import { FocusAwareStatusBar, useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { useTheme, SpacingValue } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";
import { useStarSeller } from "@swiggy-private/connect-business-commons";

import { Card } from "@minis-consumer/components/card";
import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { CommonRouteList } from "@minis-consumer/interfaces/route";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { OrderDetail } from "@minis-consumer/resources/order";
import { OrderStatus } from "@minis-consumer/interfaces/order";
import { Analytics } from "@minis-consumer/analytics";

import StoreGist from "./components/store-gist";
import OrderTracking from "./components/tracking";
import TrackingComponentLoading from "./components/loading-state";
import HeaderLeftComponent from "./components/header-left";

type TrackingComponentProps = {
    orderId: string;
};

const TrackingComponent: React.FC<TrackingComponentProps> = ({ orderId }) => {
    const order = useSuspense(OrderDetail, orderId);
    const navigation = useNavigation<NativeStackNavigationProp<CommonRouteList>>();

    const isStarSeller = useStarSeller(order?.storeDetails?.badges);

    const storeLocation = React.useMemo(
        () => [order.pickupAddress?.locality, order.pickupAddress?.city].filter(Boolean).join(", "),
        [order.pickupAddress],
    );

    const validStatus: OrderStatus[] = React.useMemo(
        () => ["PENDING", "ACCEPTED", "READY_FOR_PICKUP", "IN_TRANSIT", "DELIVERED"],
        [],
    );

    const onHelpPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: "header-help-btn",
            label: orderId,
        });

        navigation.navigate("Account", {
            screen: "Help",
            params: { screen: "HelpDetailsLevelOne", params: { level1: "orders" } },
        });
    }, [navigation, orderId]);

    useIsomorphicEffect(() => {
        navigation.setOptions({
            title: "",
            headerTitle: () => <></>,
            headerShadowVisible: true,
            headerLeft: () => (
                <HeaderLeftComponent orderId={orderId} containerStyle={styles.container} />
            ),
            headerRight: () => (
                <SvgIcon
                    icon="Question"
                    height={20}
                    width={20}
                    color="color-basic-60"
                    onPress={onHelpPress}
                    style={styles.svg}
                />
            ),
        });
    }, [navigation, onHelpPress, orderId]);

    const showChatIcon = React.useMemo(() => {
        if (Platform.OS === "web" && getScreenSize() === ScreenSize.Large) {
            return false;
        }

        return true;
    }, []);

    useMount(() => {
        if (!validStatus.includes(order.status)) {
            navigation.replace("Order", { id: order.id });
        }
    });

    if (!validStatus.includes(order.status)) {
        return null;
    }

    return (
        <Stack
            direction="row"
            flex={1}
            spacing={{
                lg: SpacingValue["space-medium"],
                default: 0,
            }}>
            <Stack flex={1}>
                <Card>
                    <Stack spacing={SpacingValue["space-x-large"]}>
                        <StoreGist
                            store={order.storeDetails}
                            location={storeLocation}
                            showChatIcon={showChatIcon}
                            orderId={orderId}
                            isStarSeller={isStarSeller}
                        />
                        <OrderTracking
                            order={order}
                            validStatus={validStatus}
                            showChatIcon={showChatIcon}
                        />
                    </Stack>
                </Card>
            </Stack>
        </Stack>
    );
};

const LoadingComponent: React.FC = () => {
    return (
        <Card>
            <TrackingComponentLoading />
        </Card>
    );
};

export const OrderTrackingScreen: React.FC = () => {
    const { value: theme } = useTheme();
    const route = useRoute<RouteProp<CommonRouteList, "Tracking">>();
    const orderId = route.params.id;

    const scrollStyle = { backgroundColor: theme["color-background-secondary"] };

    /* The md value was not rolling up for lg */
    const containerStyle: ViewStyle = useSelectScreen({
        lg: {
            paddingVertical: SpacingValue["space-x-large"],
        },
        md: {
            paddingVertical: SpacingValue["space-large"],
        },
        default: {
            paddingVertical: SpacingValue["space-medium"],
        },
    });

    return (
        <StateContextContainer>
            <ScrollView style={scrollStyle}>
                <Container style={containerStyle} fluid={false}>
                    <AsyncComponent fallback={LoadingComponent}>
                        <TrackingComponent orderId={orderId} />
                    </AsyncComponent>
                    <FocusAwareStatusBar
                        translucent={true}
                        backgroundColor="transparent"
                        barStyle="dark-content"
                    />
                </Container>
            </ScrollView>
        </StateContextContainer>
    );
};

const styles = StyleSheet.create({
    svg: {
        marginRight: SpacingValue["space-medium"],
    },
    container: {
        marginLeft: SpacingValue["space-medium"],
    },
});
