import React from "react";
import { ActivityIndicator } from "react-native";

import { useTheme } from "@swiggy-private/rn-dls";

import { HeaderRight } from "../header-right";

interface ListFooterProps {
    isFetching: boolean;
    showClearAllCartsCta: boolean;
    isLoading: boolean;
    onBulkDelete: () => Promise<void>;
}

const ListFooterComponent: React.FC<ListFooterProps> = ({ isFetching, ...props }) => {
    const { value: theme } = useTheme();

    return (
        <>
            <HeaderRight {...props} />
            <ActivityIndicator animating={isFetching} color={theme["color-primary"]} />
        </>
    );
};

export const ListFooter = React.memo(ListFooterComponent);
