import * as React from "react";
import { Platform, ScrollView, useWindowDimensions, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { useFocusEffect } from "@react-navigation/core";
import { Header, useHeaderHeight } from "@react-navigation/elements";

import { useMount } from "@swiggy-private/react-hooks";
import {
    FocusAwareStatusBar,
    IOScrollView,
    useIsomorphicEffect,
} from "@swiggy-private/react-native-ui";
import {
    ScreenSize,
    useContainerStyle,
    useScreenSize,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { ResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { StateContextContainer } from "@minis-consumer/containers/state-context-container";
import { useCartStatus, useCartView, useLoadCartView } from "@minis-consumer/hooks/use-cart";
import { useNavigationReset } from "@minis-consumer/hooks/use-navigation-reset";
import { useStoreInfo, useStoreRefetch } from "@minis-consumer/hooks/use-store";
import { useUserInfo } from "@minis-consumer/hooks/use-user";
import { AppEvent, AppEventTypes } from "@minis-consumer/includes/app-event";
import { CartScreenNavigationProp } from "@minis-consumer/interfaces/route";
import { IWrapperProps } from "@minis-consumer/types/scroll";
import {
    META_PIXEL_CONTENT_NAME,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { useCustomCartContext } from "@minis-consumer/contexts/custom-cart-context";

import { HeaderLeft } from "./components/header/left";
import { HeaderRight } from "./components/header/right";
import { HeaderTitle } from "./components/header/title";
import { CartMain } from "./components/main";
import { Placeholder } from "./components/placeholder";
import { StoreDeletedCartView } from "./components/store-deleted-cart-view";
import { CustomCartLinkHandler } from "./components/custom-cart-link-handler";
import { getUnchangedItems } from "./helpers";

const WrapperComponent: React.FC<IWrapperProps> = ({ children, scrollRef }) => {
    const { height: windowHeight } = useWindowDimensions();
    const headerHeight = useHeaderHeight();
    const insets = useSafeAreaInsets();

    const { value: theme } = useTheme();

    const ScrollComponent = useScreenSize() === ScreenSize.Large ? IOScrollView : View;

    const containerHeight = React.useMemo(() => {
        if (Platform.OS === "android") {
            return windowHeight - headerHeight + insets.top;
        } else if (Platform.OS === "ios") {
            return windowHeight - headerHeight;
        }

        return "100%";
    }, [windowHeight, headerHeight, insets.top]);

    const containerStyle = [
        useContainerStyle({
            disableGutters: { default: true, lg: false },
            fluid: false,
        }),
        useSelectScreen({
            lg: { paddingVertical: SpacingValue["space-medium"], height: containerHeight },
            default: {
                height: containerHeight,
            },
        }),
    ];
    return (
        <ScrollComponent
            ref={scrollRef}
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
                backgroundColor: theme["background_Secondary"],
                flex: 1,
            }}>
            <View style={containerStyle}>{children}</View>
        </ScrollComponent>
    );
};

const Wrapper = React.memo(WrapperComponent);

const CartScreenComponent: React.FC<CartScreenNavigationProp> = (props) => {
    const { route, navigation } = props;

    const { value: theme } = useTheme();

    const store = useStoreInfo();
    const cartView = useCartView(store.storeId);
    const cartStatus = useCartStatus(store.storeId);
    const itemCount = cartView?.cartViewData?.cartItemsCount ?? 0;

    const refetchStore = useStoreRefetch(store.slug);

    const [cartKey, setKey] = React.useState<string | undefined>(route?.params?.key);

    const userInfo = useUserInfo();
    const navigationReset = useNavigationReset();

    const itemQuantityCount = React.useMemo(() => {
        const unchangedItems = getUnchangedItems(cartView?.cartViewData?.cartItems ?? []);
        return unchangedItems.reduce((sum, item) => sum + item.quantity, 0) || 0;
    }, [cartView?.cartViewData?.cartItems]);

    const cartTotalAmount = cartView?.cartViewData?.bill?.billTotal ?? 0;
    const emptyCart = (itemCount as number) === 0;

    const scrollRef = React.useRef<ScrollView>(null);

    const onChatPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: "header-chat-btn",
            label: userInfo?.id ? cartView?.cartViewData?.id : "",
        });

        navigation.navigate("ChatConversation", { storeId: store.storeId });
    }, [navigation, store, cartView, userInfo]);

    const clearCartKeyParam = React.useCallback(() => {
        setKey(undefined);

        const key = route.params?.key;

        if (!key) {
            return;
        }

        navigation.setParams({
            key: undefined,
        });
    }, [navigation, route.params?.key]);

    useMount(() => {
        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.VIEW_CONTENT, {
            content_name: META_PIXEL_CONTENT_NAME.CART_VIEW,
        });
    });

    useIsomorphicEffect(() => {
        navigation.setOptions({
            header: () => (
                <Header
                    headerLeft={() => <HeaderLeft />}
                    title=""
                    headerTitleAlign="left"
                    headerTitle={() => (
                        <HeaderTitle itemCount={itemQuantityCount} totalAmount={cartTotalAmount} />
                    )}
                    headerRight={() => (
                        <HeaderRight
                            emptyCart={emptyCart}
                            storeId={store.storeId}
                            onChatPress={onChatPress}
                        />
                    )}
                />
            ),
        });
    }, [
        navigation,
        itemCount,
        cartTotalAmount,
        store.storeId,
        store.name,
        cartStatus,
        itemQuantityCount,
        emptyCart,
        onChatPress,
    ]);

    /**
     * Update store data if store cart changes to STORE_INACTIVE
     * but the store is enabled in the local context
     */
    React.useEffect(() => {
        if (cartView?.cartStatus === "STORE_INACTIVE" && store.enabled !== false) {
            refetchStore();
        }
    }, [cartView?.cartStatus, refetchStore, store.enabled]);

    useFocusEffect(
        React.useCallback(() => {
            AppEvent.listen(AppEventTypes.NEW_CHAT_MESSAGE, (event) => {
                if (!event.handled) {
                    event.handled = true;
                }
            });

            return () => clearCartKeyParam();
        }, [clearCartKeyParam]),
    );

    return (
        <>
            {store.enabled !== false ? (
                <CartViewFetcher
                    storeId={store.storeId}
                    cartKey={cartKey}
                    placeholder={
                        <Wrapper scrollRef={scrollRef}>
                            <Placeholder />
                        </Wrapper>
                    }>
                    <Wrapper scrollRef={scrollRef}>
                        <CartMain
                            storeId={store.storeId}
                            emptyCart={emptyCart}
                            navigationReset={navigationReset}
                            cartKey={cartKey}
                            errorMessage={route?.params?.errorMessage}
                        />
                    </Wrapper>
                </CartViewFetcher>
            ) : (
                <Wrapper scrollRef={scrollRef}>
                    <StoreDeletedCartView />
                </Wrapper>
            )}

            <FocusAwareStatusBar
                translucent={true}
                backgroundColor={theme["color-background-primary"]}
                barStyle="dark-content"
            />
        </>
    );
};

interface CartViewFetcherProps {
    storeId: string;
    cartKey?: string | null;
    placeholder?: NonNullable<React.ReactNode> | null;
}

const CartViewFetcher: React.FC<React.PropsWithChildren<CartViewFetcherProps>> = ({
    storeId,
    cartKey,
    children,
    placeholder,
}) => {
    const {
        isLoading,
        canShowCustomCartHandlerModal,
        customCartChoiceHandler,
        setShowCustomCartHandlerModal,
    } = useLoadCartView(storeId, cartKey);

    const { addCustomCartKey } = useCustomCartContext();

    const onKeepItemsCb = React.useCallback(() => {
        customCartChoiceHandler({ canMergeItems: true });
    }, [customCartChoiceHandler]);

    const onReplaceItemsCb = React.useCallback(() => {
        customCartChoiceHandler({ canMergeItems: false });
    }, [customCartChoiceHandler]);

    React.useEffect(() => {
        if (!cartKey?.trim().length) {
            return;
        }

        addCustomCartKey(cartKey, storeId);
    }, [addCustomCartKey, cartKey, storeId]);

    return (
        <>
            {isLoading ? (
                placeholder
            ) : (
                <>
                    {children}
                    <CustomCartLinkHandler
                        canShowModal={canShowCustomCartHandlerModal}
                        onKeepItemsCb={onKeepItemsCb}
                        onReplaceItemsCb={onReplaceItemsCb}
                        storeId={storeId}
                        setShowCustomCartHandlerModal={setShowCustomCartHandlerModal}
                    />
                </>
            )}
        </>
    );
};

export const CartScreen: React.FC<CartScreenNavigationProp> = (props) => {
    return (
        <StateContextContainer>
            <ResetErrorBoundary>
                <CartScreenComponent {...props} />
            </ResetErrorBoundary>
        </StateContextContainer>
    );
};
