import React from "react";
import { StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { useNavigation } from "@react-navigation/core";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { RouteList } from "@minis-consumer/interfaces/route";

import { HeaderLeft } from "../header-left";
import { HeaderRight, HeaderRightProps } from "../header-right";

export type Navigation = NativeStackScreenProps<RouteList>;
type IHeaderPDPProps = HeaderRightProps;

const HEADER_HEIGHT = 64;

const HeaderPDPMain: React.FC<IHeaderPDPProps> = (props) => {
    const inset = useSafeAreaInsets();
    const navigation = useNavigation<Navigation["navigation"]>();

    const { value: theme } = useTheme();
    const containerStyle = {
        paddingTop: inset.top,
    };

    const iconContainerStyle = StyleSheet.flatten([
        {
            backgroundColor: theme["color-basic-alpha-15"].toString(),
        },
        styles.iconArea,
    ]);
    const iconColor = theme["color-basic-0"] as string;

    return (
        <Box style={[styles.headerContainer, containerStyle]}>
            <Stack
                justifyContent="space-between"
                direction="row"
                alignItems="center"
                style={[styles.header]}>
                <HeaderLeft
                    {...{
                        navigation,
                        containerStyle: iconContainerStyle,
                        color: iconColor,
                    }}
                />
                <HeaderRight
                    {...{ containerStyle: iconContainerStyle, color: iconColor }}
                    {...props}
                />
            </Stack>
        </Box>
    );
};

export const HeaderPDP = React.memo(HeaderPDPMain);

const styles = StyleSheet.create({
    iconArea: { borderRadius: 20, width: 40, height: 40 },
    headerContainer: { ...StyleSheet.absoluteFillObject, zIndex: 2, height: HEADER_HEIGHT },
    header: { height: HEADER_HEIGHT, paddingHorizontal: SpacingValue["space-medium"] },
});
