import React from "react";
import { StyleSheet } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { NetbankingPaymentMethod } from "@minis-consumer/interfaces/payment";

import { ItemDivider } from "../../../divider";
import { BankItem } from "../bank-item";

interface Props {
    onBankPress: (bankName: string, bankCode: string) => void;
    banks?: NetbankingPaymentMethod[];
    showRadioInput?: boolean;
}

const NetBankingSectionComponent: React.FC<Props> = ({
    banks,
    onBankPress,
    showRadioInput = false,
}) => {
    if (!banks) {
        return null;
    }
    if (banks.length === 0) {
        return null;
    }

    return (
        <Stack spacing={SpacingValue["space-large"]}>
            <Box style={[styles.card]}>
                {banks.map((bank, index) => (
                    <React.Fragment key={index}>
                        <ItemDivider />
                        <BankItem showRadioInput={showRadioInput} {...bank} onPress={onBankPress} />
                    </React.Fragment>
                ))}
            </Box>
        </Stack>
    );
};

export const NetBankingSection = React.memo(NetBankingSectionComponent);

const styles = StyleSheet.create({
    card: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
});
