import React, { FC, memo } from "react";
import { StyleSheet } from "react-native";

import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { OrderEntity } from "@minis-consumer/resources/order";
import { Card } from "@minis-consumer/components/card";

import { CardTitle } from "./components/card-title";
import { ReturnItemsList } from "./components/return-items-list";
import { ReturnInfo } from "./components/return-info";
import { ReturnResolution } from "./components/return-resolution";
import { ReturnTimeline } from "./components/return-timeline";

interface ReturnDetailsProps {
    order: OrderEntity;
}

const ReturnDetailsComponent: FC<ReturnDetailsProps> = ({ order }) => {
    const { value: theme } = useTheme();

    if (!order.returnDetails?.order) {
        return null;
    }

    const returnOrder = order.returnDetails?.order;

    return (
        <Card style={styles.root}>
            <CardTitle />

            <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.divider} />

            <ReturnInfo order={order} />

            <DashBorderLine borderColor={theme["color-basic-15"]} style={styles.divider} />

            <ReturnItemsList order={order} />
            <DashBorderLine
                borderColor={theme["color-basic-15"]}
                style={[styles.divider, styles.fillContWidthDivider]}
                type="solid"
            />
            <ReturnTimeline returnOrder={returnOrder} />
            <ReturnResolution returnOrder={returnOrder} />
        </Card>
    );
};

export const ReturnDetails = memo(ReturnDetailsComponent);

const styles = StyleSheet.create({
    root: { flex: 1, overflow: "hidden" },
    divider: {
        marginTop: SpacingValue["space-small"],
        marginBottom: SpacingValue["space-small"],
    },
    fillContWidthDivider: {
        transform: [{ scaleX: 1.5 }],
    },
});
