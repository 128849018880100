import React from "react";
import { ViewStyle } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { Coupon } from "@minis-consumer/interfaces/coupon";
import { getCouponDataForCard } from "@minis-consumer/helpers/coupon";
import { STOREFRONT_OFFER_LOGO, SEGMENTED_COUPON_LOGO } from "@minis-consumer/constants/images";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

interface Props {
    coupon: Coupon;
    cardWidth: number;
    iconSize: number;
}

export const CouponCardComponent: React.FC<Props> = ({ coupon, cardWidth, iconSize }) => {
    const store = useStoreInfo(false);

    const cardData = React.useMemo(
        () => getCouponDataForCard({ coupon, city: store?.address?.city }),
        [coupon, store?.address?.city],
    );

    const textAreaWidth = React.useMemo(
        () => cardWidth - iconSize - SpacingValue["space-x-small"],
        [cardWidth, iconSize],
    );

    const textContainerStyle: ViewStyle = {
        width: textAreaWidth,
        flex: 1,
    };

    const containerStyle = {
        width: cardWidth,
        paddingLeft: SpacingValue["space-small"],
    };

    return (
        <Box direction="row" alignItems="center" style={containerStyle}>
            <CdnImage
                id={coupon.segmentedOffer ? SEGMENTED_COUPON_LOGO : STOREFRONT_OFFER_LOGO}
                height={iconSize}
                width={iconSize}
            />
            <Box ml={SpacingValue["space-x-small"]} style={textContainerStyle}>
                <Text category="h4" color="high">
                    {cardData.title}
                </Text>
                <Text category="b3" color="medium">
                    {cardData.description}
                </Text>
            </Box>
        </Box>
    );
};

export const CouponCard = React.memo(CouponCardComponent);
