import React from "react";
import { KeyboardAvoidingView, Platform, StyleSheet, View } from "react-native";
import { SpacingValue } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";
import { AddressForm, ConsumerAddressResponse } from "@swiggy-private/connect-address-ui";
import { ThreeDotsLoader } from "@swiggy-private/react-native-ui";
import { useHeaderHeight } from "@react-navigation/elements";

import { useGoogleMapFns } from "@minis-consumer/hooks/use-google-map-fns";
import { createAddressApi } from "@minis-consumer/api/address";
import { AddressCreationContext } from "@minis-consumer/routes/address/types";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { Analytics } from "@minis-consumer/analytics";
import { Logger } from "@minis-consumer/includes/logger";

type Props = {
    onPressCTA: (address?: ConsumerAddressResponse) => void;
    addressId?: string;
    context?: AddressCreationContext;
    onEditPress?: (payload: { lat: number; long: number }) => void;
    refreshCart?: (id: string) => void;
};

export const EnterAddressDetails: React.FC<Props> = ({
    onPressCTA,
    addressId,
    context,
    onEditPress,
    refreshCart,
}) => {
    const { onMount, onError } = useGoogleMapFns();
    const [toast] = useToast();
    const headerHeight = useHeaderHeight();

    const offset = React.useMemo(() => headerHeight, [headerHeight]);

    const onMountMapAddressPill = React.useCallback(() => {
        Analytics.clickEvent({ category: "locate-me-btn" });
    }, []);

    const saveOrUpdateAnalyticsEvent = React.useCallback(() => {
        Analytics.clickEvent({ category: "save-address-btn" });
    }, []);

    const logger = React.useCallback((err: unknown) => {
        Logger.recordError(err);
    }, []);

    useMount(() => {
        Analytics.impressionEvent({ category: "save-address-btn" });
    });

    return (
        <View style={styles.rootContainer}>
            <KeyboardAvoidingView
                keyboardVerticalOffset={offset}
                behavior={Platform.OS === "ios" ? "padding" : "height"}
                style={styles.keyboardView}>
                <AddressForm
                    onPressCTA={onPressCTA}
                    addressId={addressId}
                    context={context}
                    onEditPress={onEditPress}
                    onMountMap={onMount}
                    onErrorMap={onError}
                    onMountMapAddressPill={onMountMapAddressPill}
                    LoaderComponent={ThreeDotsLoader}
                    logger={logger}
                    toast={toast}
                    saveOrUpdateAnalyticsEvent={saveOrUpdateAnalyticsEvent}
                    createConsumerAddressApi={createAddressApi}
                    refreshCart={refreshCart}
                    provider={Platform.OS === "android" ? "google" : "default"}
                />
            </KeyboardAvoidingView>
        </View>
    );
};

const styles = StyleSheet.create({
    rootContainer: {
        flex: 1,
    },
    header: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
    keyboardView: {
        flex: 1,
    },
});
