import * as React from "react";
import { StyleSheet } from "react-native";
import { useController } from "@rest-hooks/react";

import { Stack, useScreenSize, ScreenSize, Box } from "@swiggy-private/rn-adaptive-layout";
import { Input, SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { useDebounceFn, useMountedRef } from "@swiggy-private/react-hooks";

import { User } from "@minis-consumer/resources/user";
import { useSuspenseUser } from "@minis-consumer/hooks/use-user";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { UserService } from "@minis-consumer/api/user";
import { useAppDispatch } from "@minis-consumer/hooks/use-app-dispatch";
import { User as IUser } from "@minis-consumer/interfaces/user";
import { Analytics } from "@minis-consumer/analytics";

import { AccountProfilePictureIcon } from "../../../components/user-details";

export const ProfileSettingsContent: React.FC = () => {
    const userData = useSuspenseUser();

    const screenSize = useScreenSize();
    const mountedRef = useMountedRef();

    const { value: theme } = useTheme();
    const bgPrimaryColor = theme["color-background-primary"];

    const containerColor = { backgroundColor: bgPrimaryColor };

    const [name, setName] = React.useState(userData?.name ?? "");
    const appDispatch = useAppDispatch();
    const [showToast] = useToast();

    const { invalidate } = useController();

    const onNameChange = React.useCallback(
        (newName: string) => {
            Analytics.clickEvent({ category: "profile-change-name" });

            UserService.updateUser({ name: newName })
                .then(() => {
                    if (mountedRef.current) {
                        appDispatch({
                            type: "SET_USER_ACTION",
                            payload: { ...userData, name: newName } as IUser,
                        });
                        invalidate(User);
                    }
                })
                .catch(() => {
                    mountedRef.current && showToast("Failed to update the name");
                });
        },
        [appDispatch, invalidate, mountedRef, showToast, userData],
    );

    const onNameChangeDebounced = useDebounceFn(onNameChange, { wait: 1000 });

    const updateUser = React.useCallback(
        (val: string) => {
            setName(val);
            onNameChangeDebounced(val);
        },
        [onNameChangeDebounced],
    );

    return (
        <Box
            style={[
                screenSize === ScreenSize.Large
                    ? {
                          padding: SpacingValue["space-x-large"],
                          marginTop: SpacingValue["space-xx-large"],
                      }
                    : {
                          padding: SpacingValue["space-medium"],
                          marginTop: SpacingValue["space-medium"],
                      },
                styles.contentBorderRadius,
                containerColor,
            ]}
            flex={1}
            direction={screenSize === ScreenSize.Large ? "row" : "column"}>
            <Box
                flex={1 / 2}
                alignItems="center"
                justifyContent="center"
                mr={SpacingValue["space-x-large"]}
                mb={SpacingValue["space-x-large"]}>
                <AccountProfilePictureIcon userName={name ?? ""} />
            </Box>

            <Stack flex={2} direction="column" spacing={28}>
                <Input label="Name" value={name} onChangeText={updateUser} />
                <Input label="Email" defaultValue={userData?.email} disabled />
                <Input label="Phone" defaultValue={userData?.mobile} disabled />
            </Stack>
        </Box>
    );
};

const styles = StyleSheet.create({
    contentBorderRadius: { borderRadius: 16 },
});
