export const TITLE = "Key highlights";

export const TEST_IDS = {
    PRESSABLE: "product-details-pressable",
    TITLE: "product-details-title",
    CHEVRON: "product-details-chevron",
    TABLE: "product-details-table",
    ROW: "product-details-row",
};

export const BORDER_RADIUS_VALUE = 12;
