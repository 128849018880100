export const RBI_TNC_MODAL_BODY_COPIES = {
    TITLE: "Securely save your card ",
    SUBTITLE:
        "As per the latest RBI guidelines, your card information will be saved with the card network (Visa/Mastercard/RuPay/DC)",
    CARD_PROTECTED: {
        TEXT: "Protect your card from unauthorised access",
        IMAGE_ID: "minis/card_protected",
    },
    CREDIT_CARD: {
        TEXT: "No need to enter your card details every time",
        IMAGE_ID: "minis/credit_cards",
    },
    PRIMARY_BTN: "Ok, Got it",
};
