import { ReturnResolutionType } from "@minis-consumer/interfaces/return-order";

export const ARD_EVENTS_MAPPING = {
    SHARE_BTN: "share-btn",
};

export const RETURN_ORDER_LABELS = {
    REJECT: "Reject",
    STATUS: "status",
    ACCEPT: "Accept",
    REQUEST: "request",
    RESOLUTION: "Resolution",
    LEARN_MORE: "Learn more.",
    VIEW_ON_CHAT: "View on chat",
    FROM_ORDER_ID: "From order #",
    EXCHANGE_SENT: "Exchange Sent",
    CLOSED_REQUEST: "Closed request:",
    AMOUNT_REFUNDED: "Amount Refunded",
    REJECTED_RETURN: "Rejected Return",
    REASON_TO_REJECT: "Reason to reject",
    ADD_MORE_DETAILS: "Add more details",
    ACCEPTED_REQUEST: "Accepted request:",
    REJECTED_REQUEST: "Rejected request:",
    SENT_AN_EXCHANGE: "Sent an exchange by",
    TRACKING_URL: "Tracking URL/Contact No.",
    ADDITIONAL_DETAILS: "Additional details",
    RETURN_EXCHANGE_TITLE: "Return/Exchange",
    HOW_DID_YOU_RESOLVE: "How did you resolve?",
    PHONE_NUMBER_COPIED: "Phone Number Copied!",
    PROCEED_TO_CLOSE: "Proceed to close request",
    REASON_FOR_REJECTION: "Reason for rejection",
    TRACK_NUMBER_COPIED: "Tracking number copied",
    DELIVERY_PARTNER_NAME: "Delivery partner/name",
    SUBMIT_AND_CLOSE_REQUEST: "Submit & close request",
    CUSTOMER_UPI_LINK: "Customer’s UPI-linked phone no",
    ACCEPTING_RETURN_REQUEST: "Accepting return request",
    DISCUSS_WITH_CUSTOMER: "Like to discuss with customer?",
    RETURN_EXCHANGE_FROM_ORDER: "Return/Exchange from Order",
    ADD_A_SCREENSHOT: "Add a screenshot of the refund transaction",
    REJECT_REASONS: "Your reason will be shared with customer via chat",
    ARRANGE_PICKUP_OPTIONS: "Arrange return pickup options(if applicable)",
    FOR_EXHCANGE_DISCUSS_WITH_CUSTOMER:
        "For exchange or items damaged, discuss with customer via chat.",
    REFUND_THE_AMOUNT_LABEL:
        "Refund the amount to customer via UPI if the items are in good condition.",
    COUPON_USED: "Coupon used: ",
};

export const ORDER_RETURN_REJECTION_LABELS = {
    OTHER_ISSUES: "Other issues",
    SIZE_OR_FIT_ISSUES: "Size or fit issues",
    DAMAGED_THE_PRODUCT: "Damaged the product",
    USED_IT_BEYOND_REPAIR: "Used it beyond repair",
};

export const ORDER_RETURN_CLOSE_RESOLUTIONS: {
    [key in ReturnResolutionType]: string;
} = {
    REFUND: "Amount refunded to customer",
    EXCHANGE: "Sent an exchange",
    OTHER: "Other resolutions",
};

export const RETURN_ORDER_STATUS_LABELS = {
    UNDER_REVIEW: "Under review",
    REJECTED_AND_CLOSED: "Rejected & Closed",
    REFEUNDED_AND_CLOSED: "Refunded & closed",
    EXCHANGED_AND_CLOSED: "Exchanged & closed",
    ACCEPTED_AND_IN_PROGRESS: "Accepted & in progress",
    OTHER_AND_CLOSED: "Closed",
};

export const DATE_FORMAT = "dd MMM, yyyy, hh:mm a";

export const SELLER_MSG_TEXT = "You have a note from {0}...";
export const BRAND_IMAGE_FALLBACK = "minis/brand_image_fallback";
export const ORDER_DETAILS_DEFAULT_COLOR = "#FF5200";
