import React from "react";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { CdnImage } from "@swiggy-private/react-native-ui";
import { Button, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Container, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { CommonRouteList, MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { MinisNavigatorId, StoreNavigatorId } from "@minis-consumer/constants/navigation";

import { SectionTitle } from "../section-title";

type StoreNavigation = NativeStackNavigationProp<RouteList, "Account", string>;
type MinisNavigation = NativeStackNavigationProp<MinisRouteList, "Account", string>;

const ListEmpty: React.FC = () => {
    const imageWidth = 140;
    const imageHeight = imageWidth * 0.8;

    const navigation =
        useNavigation<NativeStackNavigationProp<CommonRouteList, "Account", string>>();

    const onPress = React.useCallback(() => {
        const storeNavigation: StoreNavigation | null =
            navigation.getId() === StoreNavigatorId
                ? (navigation as StoreNavigation)
                : (navigation.getParent(StoreNavigatorId) as StoreNavigation);

        if (storeNavigation != null) {
            storeNavigation.navigate("Home", { screen: "Shop" });

            return;
        }

        const minisNavigation: MinisNavigation | null =
            navigation.getId() === MinisNavigatorId
                ? (navigation as MinisNavigation)
                : (navigation.getParent(MinisNavigatorId) as MinisNavigation);

        if (minisNavigation != null) {
            minisNavigation.navigate("MinisHome", { screen: "Explore" });
        }
    }, [navigation]);

    const [containerFluid, disableContainerGutter] = useSelectScreen({
        default: [true, true],
        lg: [false, false],
    });

    return (
        <Container fluid={containerFluid} disableGutters={disableContainerGutter}>
            <SectionTitle title="Your orders" container={false} />

            <Box flex={1} justifyContent="center" alignItems="center">
                <Stack spacing={48}>
                    <Stack spacing={28}>
                        <Box alignItems="center">
                            <CdnImage
                                isImageKitEnabled
                                id="minis/empty_consumer_orders"
                                height={imageHeight}
                                width={imageWidth}
                            />
                        </Box>

                        <Stack spacing={SpacingValue["space-x-small"]}>
                            <Text
                                category="h5"
                                color="color-basic-60"
                                style={styles.textAlignCenter}>
                                Nothing here yet
                            </Text>

                            <Text
                                category="b2"
                                weight="medium"
                                color="color-basic-45"
                                style={styles.textAlignCenter}>
                                {"All your orders will be\navailable here"}
                            </Text>
                        </Stack>
                    </Stack>

                    <Button color="primary" onPress={onPress}>
                        Explore our Ministore
                    </Button>
                </Stack>
            </Box>
        </Container>
    );
};

export const ListEmptyComponent = React.memo(ListEmpty);

const styles = StyleSheet.create({
    textAlignCenter: {
        textAlign: "center",
    },
});
