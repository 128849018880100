export const DISPLAY_COPIES = {
    MORE_HELP_TEXT: "Need more help with your product?",
    QUERIES: "For any queries, please chat with us",
    EXCHANGE: "Return or Exchange",
    OUT_OF_TIME: "Return window closed. Please chat with us for any clarifications.",
    POSSIBLE_TILL: "Return/Exchange maybe possible till",

    SUBMIT_RETURN_TEXT:
        "Thank you for submitting your return request. We've received your details and are getting to work on it.",
    DONE: "Done",
    REQUEST_RAISED: "Return request raised on",
};
