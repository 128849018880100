import { Dispatch, Reducer, ReducerAction, ReducerState, useReducer } from "react";

import { getInitialPaymentState } from "../contexts/payment-context";
import { paymentReducer } from "../reducers";
import { PaymentAction } from "../reducers/action-types";
import { PaymentState } from "../reducers/types";

export function usePaymentReducer(): [
    ReducerState<Reducer<PaymentState, PaymentAction>>,
    Dispatch<ReducerAction<Reducer<PaymentState, PaymentAction>>>,
] {
    return useReducer(paymentReducer, undefined, getInitialPaymentState);
}
