import React from "react";
import { StyleSheet, TextStyle, ViewStyle } from "react-native";

import { Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";

type AddAddressCardItemComponentProps = {
    onAddNewAddressPress: () => void;
    cartId?: string;
};

const AddAddressCardItemComponent: React.FC<AddAddressCardItemComponentProps> = ({
    onAddNewAddressPress,
    cartId,
}) => {
    const { value: theme } = useTheme();

    const containerStyles: ViewStyle = {
        borderColor: theme["color-basic-15"],
    };

    const CtaStyles: ViewStyle = {
        backgroundColor: theme["color-primary-25"],
        borderColor: theme["color-primary-400"],
    };

    const CtaTextStyles: TextStyle = { color: theme["color-primary-400"] };

    useMount(() => {
        Analytics.impressionEvent({
            category: "add-address-btn",
            label: cartId,
        });
    });

    return (
        <Box
            flex={1}
            pv={SpacingValue["space-medium"]}
            ph={SpacingValue["space-medium"]}
            justifyContent="space-between"
            style={[styles.container, containerStyles]}>
            <Stack spacing={SpacingValue["space-x-small"]} direction="row" alignItems="center">
                <SvgIcon icon={"Location"} height={16} width={16} color="color-basic-100" />
                <Text category="b1" weight="bold" color="high">
                    Add new address
                </Text>
            </Stack>

            <Box direction="row" mt={SpacingValue["space-x-large"]}>
                <Button
                    onPress={onAddNewAddressPress}
                    color="primary"
                    size="small"
                    style={CtaStyles}
                    textStyle={CtaTextStyles}>
                    Add new
                </Button>
            </Box>
        </Box>
    );
};

export const AddAddressCardItem = React.memo(AddAddressCardItemComponent);

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderRadius: 10,
        borderStyle: "dashed",
    },
});
