import React from "react";
import { useController } from "@rest-hooks/react";

import { useMount } from "@swiggy-private/react-hooks";

import { FetchVacationDays } from "@minis-consumer/resources/schedule-management";
import { SlotSelectionScreenLoader } from "@minis-consumer/routes/slot-selection/components/loading";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { SlotSelectionForm } from "@minis-consumer/routes/slot-selection/components/form-new";
import { ISlotSelectionFormComponent } from "@minis-consumer/routes/slot-selection/helpers";

const SlotSelectionFormWrapperComponent: React.FC<ISlotSelectionFormComponent> = (props) => {
    const storeInfo = useStoreInfo();
    const { fetch } = useController();
    const [vacationDays, setVacationDays] = React.useState<number[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const getVacationDays = React.useCallback(async () => {
        setIsLoading(true);
        const response = await fetch(FetchVacationDays, {
            storeId: storeInfo.storeId,
        });

        if (response) {
            setVacationDays(response.unavailableDays);
        }

        setIsLoading(false);
    }, [fetch, storeInfo.storeId]);

    useMount(() => {
        getVacationDays();
    });

    if (isLoading) {
        return <SlotSelectionScreenLoader />;
    }

    return <SlotSelectionForm {...props} vacationDays={vacationDays} />;
};

export const SlotSelectionWrapper = React.memo(SlotSelectionFormWrapperComponent);
