import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Input, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";

import { CouponInputPlaceholder } from "../placeholder/input";

interface CouponInputProps {
    onApplyCoupon: (code: string, eventName: string) => void;
    loading: boolean;
    cartId?: string;
}

const CouponInputComponent: React.FC<CouponInputProps> = ({ cartId, onApplyCoupon, loading }) => {
    const [couponCode, setCouponCode] = React.useState("");

    const { value: theme } = useTheme();

    const paddingValue = useSelectScreen({
        lg: SpacingValue["space-large"],
        default: SpacingValue["space-medium"],
    });

    const borderRadius = useSelectScreen({
        lg: 0,
        default: SpacingValue["space-medium"],
    });

    const onChangeText = React.useCallback((text: string) => {
        setCouponCode(text?.toUpperCase());
    }, []);

    const isCtaDisabled = React.useMemo(() => !couponCode?.trim()?.length, [couponCode]);

    const onPress = React.useCallback(() => {
        onApplyCoupon(couponCode, "enter-coupon-apply-btn");
    }, [couponCode, onApplyCoupon]);

    useMount(() => {
        Analytics.impressionEvent({
            category: "enter-coupon-apply-btn",
            label: cartId,
        });
    });

    return (
        <Box
            pv={paddingValue}
            ph={paddingValue}
            style={{
                backgroundColor: theme["color-background-primary"],
                borderBottomLeftRadius: borderRadius,
                borderBottomRightRadius: borderRadius,
            }}>
            {loading ? (
                <CouponInputPlaceholder />
            ) : (
                <Input
                    autoCapitalize="characters"
                    placeholder="Enter Coupon Code"
                    value={couponCode}
                    accessoryRight={
                        <AccessoryRight onApplyCoupon={onPress} isCtaDisabled={isCtaDisabled} />
                    }
                    onChangeText={onChangeText}
                />
            )}
        </Box>
    );
};

interface AccessoryRightProps {
    onApplyCoupon: () => void;
    isCtaDisabled: boolean;
}

const APPLY_COUPON_TEXT = "APPLY";

const AccessoryRight: React.FC<AccessoryRightProps> = (props) => {
    return (
        <Pressable
            disabled={props.isCtaDisabled}
            onPress={props.onApplyCoupon}
            style={styles.accessoryRight}>
            <Text
                category="btn4"
                weight="bold"
                color={props.isCtaDisabled ? "color-basic-15" : "color-primary"}>
                {APPLY_COUPON_TEXT}
            </Text>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    accessoryRight: {
        width: "auto",
    },
});

export const CouponInput = React.memo(CouponInputComponent);
