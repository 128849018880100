/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from "react";
import { useTheme } from "@swiggy-private/rn-dls";
import { FocusAwareStatusBar } from "@swiggy-private/react-native-ui";

import { PaymentProceedRouteList } from "@minis-consumer/interfaces/route";
import { createNavigator } from "@minis-consumer/helpers/navigation";
import { useScreenOptions } from "@minis-consumer/hooks/use-screen-options";

const Stack = createNavigator<PaymentProceedRouteList>();

export const PaymentProceed: React.FC = () => {
    const screenOptions = useScreenOptions<PaymentProceedRouteList>({
        headerShown: false,
        animationEnabled: true,
    });

    const { value: theme } = useTheme();

    return (
        <>
            <Stack.Navigator initialRouteName="PaymentVerify" screenOptions={screenOptions}>
                {/* Only for UPI, Card/Netbanking will be webviews */}
                <Stack.Screen
                    name="PaymentTimer"
                    getComponent={() => require("./routes/timer").PaymentTimer}
                    options={{ title: "", headerShown: true }}
                />

                <Stack.Screen
                    name="PaymentVerify"
                    getComponent={() => require("./routes/verify").ProceedVerify}
                    options={{ title: "", headerShown: false }}
                />

                <Stack.Screen
                    name="PaymentSuccess"
                    getComponent={() => require("./routes/success").ProceedSuccess}
                    options={{ title: "", headerShown: false }}
                />
            </Stack.Navigator>

            <FocusAwareStatusBar
                translucent={true}
                backgroundColor={theme["color-background-primary"]}
                barStyle="dark-content"
            />
        </>
    );
};
