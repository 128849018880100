import * as React from "react";
import { StyleSheet, ViewStyle, View } from "react-native";
import type { LayoutChangeEvent } from "react-native";
import { useSuspense } from "@rest-hooks/react";

import { SpacingValue, Surface, SurfaceProps, useTheme } from "@swiggy-private/rn-dls";
import { FocusAwareStatusBar } from "@swiggy-private/react-native-ui";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { CatalogView } from "@minis-consumer/resources/catalog";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { ShopFooter } from "@minis-consumer/components/shop-footer";

import { StoreClosedContent } from "@minis-consumer/components/store-closed";
import { BLAZE_EVENTS, endCustomEvent } from "@minis-consumer/includes/blaze";
import {
    META_PIXEL_CONTENT_NAME,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { ICatalogSectionsPos } from "@minis-consumer/interfaces/catalog";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";

import { ShopCatalog } from "../../components/catalog";
import { CatalogLoader } from "../../components/loader";

import { ShopFrontCoupons } from "../../components/shopfront-coupons";
import { MyLinks } from "../components/my-links";
import { ShopFront } from "../components/shopfront";
import { AboutSection } from "../components/about-section";
import { InstagramScrollWidget } from "../../components/instagram-scroll-widget";

import { TABS } from "../../constants";

type ShopViewProps = Omit<SurfaceProps, "children"> & {
    poweredMinisPressHandler: VoidFunction;
    setNavbarLayout?: (e: LayoutChangeEvent) => void;
    setDeliveryInfoOffset: (offset: number) => void;
    catalogMeasureRef: React.RefObject<ICatalogSectionsPos>;
    storyId?: string;
    viewType?: TABS;
    setSelectedViewType?: (viewType: TABS) => void;
    viewTypeParam?: string;
};

const WithCatalog: React.FC<React.PropsWithChildren<{ storeSlug: string }>> = ({
    children,
    storeSlug,
}) => {
    useSuspense(CatalogView, { storeSlug });
    return <>{children}</>;
};

export const ShopView = React.forwardRef<View, ShopViewProps>(({ style, ...props }, ref) => {
    return <ShopContent {...props} handlerRef={ref} />;
});

const ShopContent: React.FC<ShopViewProps & { handlerRef: React.ForwardedRef<View> }> = ({
    style,
    handlerRef,
    setNavbarLayout,
    setDeliveryInfoOffset,
    poweredMinisPressHandler,
    catalogMeasureRef,
    storyId,
    viewType,
    setSelectedViewType,
    viewTypeParam,
    ...props
}) => {
    const storeInfo = useStoreInfo();
    const isStoreEnabled = storeInfo?.enabled !== false;

    const { value: theme } = useTheme();

    const grayBlockStyle = {
        height: SpacingValue["space-x-small"],
        backgroundColor: theme["color-basic-15"],
    };

    useMount(() => {
        endCustomEvent(BLAZE_EVENTS.LOAD_TIME.STORE_FRONT);
        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.VIEW_CONTENT, {
            content_name: META_PIXEL_CONTENT_NAME.STORE_VIEW,
        });
    });

    const [borderTopLeftRadius, borderTopRightRadius] = useSelectScreen({
        lg: [16, 16, isStoreEnabled ? 0 : 16],
        md: [16, 16, 0],
        default: [0, 0, 0],
    }) as Array<ViewStyle["borderTopLeftRadius"] | ViewStyle["borderTopRightRadius"]>;

    const toggleViewType = (viewTypeSelected: any): void => {
        setSelectedViewType?.(viewTypeSelected);
    };

    React.useEffect(() => {
        if (viewTypeParam === "links") {
            toggleViewType("links");
        }
    }, [viewTypeParam]);

    return (
        <>
            <Surface
                style={[
                    styles.content,
                    style,
                    {
                        borderTopLeftRadius,
                        borderTopRightRadius,
                    },
                ]}
                {...props}>
                <ShopFront
                    setDeliveryInfoOffset={setDeliveryInfoOffset}
                    storyId={storyId}
                    poweredMinisPressHandler={poweredMinisPressHandler}
                />

                <Box style={grayBlockStyle} />

                <AsyncComponent fallback={CatalogLoader}>
                    <MyLinks toggleViewType={toggleViewType} />
                </AsyncComponent>

                <Box style={grayBlockStyle} />

                {isStoreEnabled ? (
                    <AsyncComponent fallback={CatalogLoader}>
                        <WithCatalog storeSlug={storeInfo.slug}>
                            <>
                                <Box mb={SpacingValue["space-x-large"]} />

                                <ShopFrontCoupons />

                                <ShopCatalog
                                    catalogMeasureRef={catalogMeasureRef}
                                    layout={storeInfo.layoutConfig?.listing ?? "LIST"}
                                    showAddToCart={
                                        !storeInfo.userMetadata?.userExperiments?.isSfXpEnabled
                                    }
                                />

                                <Box
                                    style={[
                                        { backgroundColor: theme["color-basic-15"] },
                                        styles.separator,
                                    ]}
                                />
                            </>
                        </WithCatalog>
                    </AsyncComponent>
                ) : (
                    <StoreClosedContent flex={1} />
                )}
            </Surface>

            <InstagramScrollWidget />

            <AboutSection />
            <Box mb={SpacingValue["space-medium"]} />

            {isStoreEnabled ? <ShopFooter /> : null}

            <FocusAwareStatusBar
                translucent={true}
                backgroundColor="transparent"
                barStyle="dark-content"
            />
        </>
    );
};

if (__DEV__) {
    ShopView.displayName = "ShopView";
}

const styles = StyleSheet.create({
    content: {
        flex: 1,
        elevation: 0,
        paddingBottom: SpacingValue["space-medium"],
    },
    separator: {
        height: SpacingValue["space-medium"] / 2,
    },
    catalogContainerStyle: {
        marginTop: SpacingValue["space-x-large"],
    },
});
