import { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet } from "react-native";

import { FocusAwareStatusBar, useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { ActivityIndicator, SpacingValue, Surface, SurfaceProps } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { AsyncComponent } from "@minis-consumer/components/async-component";
import { ShopFooter } from "@minis-consumer/components/shop-footer";
import { compactTitle } from "@minis-consumer/helpers/title";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { HomeRouteList } from "@minis-consumer/interfaces/route";
import { useGetConfigInfo } from "@minis-consumer/hooks/use-get-config-info";

import { AboutTabs } from "../components/about/tabs";
import { HeaderRight } from "../components/header-right";

type Navigation = BottomTabScreenProps<HomeRouteList, "About">["navigation"];

const AboutTabsLoader: React.FC = () => (
    <Box flex={1}>
        <ActivityIndicator />
    </Box>
);

export const AboutView: React.FC<Omit<SurfaceProps, "children">> = ({ style, ...props }) => {
    const navigation = useNavigation() as Navigation;
    const storeInfo = useStoreInfo();
    const isStoreEnabled = storeInfo.enabled !== false;

    const border = useSelectScreen({
        lg: SpacingValue["space-medium"],
        default: 0,
    });

    const contentStyle = {
        borderTopLeftRadius: border,
        borderTopRightRadius: border,
    };

    const onBackPress = React.useCallback(() => {
        navigation.canGoBack() && navigation.goBack();
    }, [navigation]);

    const isNewServicesEnabled = useGetConfigInfo()?.isNewServicesEnabled;

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
                <SvgIcon
                    icon="ArrowLeft"
                    height={20}
                    width={20}
                    style={styles.headerBack}
                    onPress={onBackPress}
                />
            ),
            headerRight: () => (
                <HeaderRight
                    storeId={storeInfo?.storeId}
                    isStoreEnabled={isStoreEnabled}
                    canShowShareIcon
                />
            ),
            headerTitle: compactTitle(storeInfo.name),
            headerTitleAlign: "left",
            headerTitleAllowFontScaling: false,
            headerLeftContainerStyle: {
                flexGrow: 0,
            },
            headerTitleContainerStyle: {
                flex: 1,
            },
            tabBarStyle: {
                display: isNewServicesEnabled ? "none" : "flex",
            },
        });
    }, [navigation, isStoreEnabled, storeInfo.name, storeInfo.storeId]);

    return (
        <>
            <Surface style={[styles.content, style, contentStyle]} {...props}>
                {isStoreEnabled ? (
                    <AsyncComponent fallback={AboutTabsLoader}>
                        <AboutTabs />
                    </AsyncComponent>
                ) : (
                    <AboutTabs />
                )}
                <FocusAwareStatusBar
                    translucent={true}
                    backgroundColor="transparent"
                    barStyle="dark-content"
                />
            </Surface>

            {isStoreEnabled ? <ShopFooter /> : null}
        </>
    );
};

const styles = StyleSheet.create({
    content: {
        flex: 1,
        elevation: 0,
    },
    headerBack: {
        marginLeft: SpacingValue["space-medium"],
    },
});
