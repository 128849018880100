import { useCallback } from "react";
import { NavigationProp, useNavigation } from "@react-navigation/core";

import { MinisNavigatorId, StoreNavigatorId } from "@minis-consumer/constants/navigation";
import { MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";

export const useNavigateToOrderWithReset = (): ((
    orderId: string,
    paymentFailed?: boolean,
) => void) => {
    const navigation =
        useNavigation<NavigationProp<RouteList | MinisRouteList, "Payment", string>>();

    const storeNavigation = navigation.getParent(StoreNavigatorId) as NavigationProp<
        RouteList,
        "Payment"
    > | null;

    const minisNavigation = navigation.getParent(MinisNavigatorId) as NavigationProp<
        MinisRouteList,
        "Payment"
    > | null;

    return useCallback(
        (orderId: string, paymentFailed?: boolean) => {
            if (storeNavigation != null) {
                storeNavigation.reset({
                    index: 0,
                    routes: [
                        {
                            key: "order-" + orderId,
                            name: "Order",
                            params: {
                                id: orderId,
                                paymentFailed: paymentFailed ?? undefined,
                            },
                        },
                    ],
                });

                return;
            }

            if (minisNavigation != null) {
                minisNavigation.reset({
                    index: 0,
                    routes: [
                        {
                            key: "order-" + orderId,
                            name: "Order",
                            params: {
                                id: orderId,
                                paymentFailed: paymentFailed ?? undefined,
                            },
                        },
                    ],
                });
            }
        },
        [minisNavigation, storeNavigation],
    );
};
