import React from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { TEST_IDS_SF } from "@minis-consumer/constants/test-ids/storefront";

import { MESSAGE, ICON_SIZE } from "./constants";

interface IProps {
    style?: StyleProp<ViewStyle>;
}

const ShopOnlineStatusComponent: React.FC<IProps> = ({ style }) => {
    const storeInfo = useStoreInfo();

    const shopOnline = React.useMemo(() => storeInfo.settings.online, [storeInfo.settings.online]);

    if (shopOnline) {
        return null;
    }

    return (
        <Stack
            style={[styles.storeOnlineContainer, style]}
            alignItems="center"
            direction="row"
            spacing={SpacingValue["space-x-small"]}
            testID={TEST_IDS_SF.SHOP_OFFLINE}>
            <SvgIcon
                icon="InfoFilled"
                color="color-critical-400"
                width={ICON_SIZE}
                height={ICON_SIZE}
            />

            <Text category="b2" color="color-critical-400" weight="medium">
                {MESSAGE}
            </Text>
        </Stack>
    );
};

if (__DEV__) {
    ShopOnlineStatusComponent.displayName = "ShopOnlineStatusComponent";
}

export const ShopOnlineStatus = React.memo(ShopOnlineStatusComponent);

const styles = StyleSheet.create({
    storeOnlineContainer: {
        marginBottom: SpacingValue["space-xx-small"],
    },
});
