import * as React from "react";
import { ImageStyle, Platform, Pressable, StyleSheet, ViewStyle } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, Tooltip, TooltipPlacement, useTheme } from "@swiggy-private/rn-dls";
import {
    ISellerBadge,
    StarSellerBadge,
    useStarSeller,
} from "@swiggy-private/connect-business-commons";
import { useMount } from "@swiggy-private/react-hooks";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { Analytics, Event } from "@minis-consumer/analytics";
import { Kebab } from "@minis-consumer/components/kebab";
import { getStoreBrandImageUrl } from "@minis-consumer/helpers/store";
import { useShopNavigation } from "@minis-consumer/hooks/use-shop-navigation";

import { DEFAULTS } from "../../constants";
import { ANALYTICS_COPIES, COPIES } from "../../../../constants";

interface StoreInfoProps {
    id: string;
    name: string;
    slug: string;
    isTooltipVisible: boolean;
    cartId: string;

    badges?: ISellerBadge[];
    imgId?: string;
    cartUpdatedAt?: string | null;
    onDismissTooltip?: VoidFunction;
    onDeleteCart?: VoidFunction;
}

const StoreInfoComponent: React.FC<StoreInfoProps> = ({
    id,
    name,
    slug,
    badges,
    imgId,
    cartUpdatedAt,
    isTooltipVisible,
    cartId,
    onDismissTooltip,
    onDeleteCart,
}) => {
    const { value: theme } = useTheme();
    const goToStore = useShopNavigation();
    const isStarSeller = useStarSeller(badges);

    const storeAnalyticsPayload = React.useMemo(() => {
        return {
            category: ANALYTICS_COPIES.STORE_DETAILS_CATEGORY,
            label: cartId,
            context: id,
        };
    }, [cartId, id]) as Event;

    const url = React.useMemo(
        () =>
            imgId
                ? getStoreBrandImageUrl(
                      imgId,
                      {
                          height: DEFAULTS.BRAND_IMAGE_SIZE + StyleSheet.hairlineWidth,
                          width: DEFAULTS.BRAND_IMAGE_SIZE + StyleSheet.hairlineWidth,
                          crop: "thumb",
                      },
                      true,
                  )
                : undefined,
        [imgId],
    );

    const analyticsData = React.useMemo(() => {
        return {
            category: ANALYTICS_COPIES.CLEAR_CART_BTN,
            label: cartId,
        };
    }, [cartId]);

    const onDropdownPress = React.useCallback(() => {
        Analytics.clickEvent(analyticsData);

        onDeleteCart?.();
    }, [onDeleteCart, analyticsData]);

    const goToStoreLink = React.useCallback(() => {
        Analytics.clickEvent(storeAnalyticsPayload);

        goToStore(slug);
    }, [storeAnalyticsPayload, goToStore, slug]);

    const storeName = React.useMemo(() => {
        const trimmedVal = name.trim();

        return trimmedVal.length > DEFAULTS.STORE_NAME_CHAR_LTH
            ? trimmedVal.slice(0, DEFAULTS.STORE_NAME_CHAR_LTH).trim() + "..."
            : trimmedVal;
    }, [name]);

    useMount(() => {
        Analytics.impressionEvent(storeAnalyticsPayload);

        const t = setTimeout(() => onDismissTooltip?.(), DEFAULTS.TOOLTIP_SHOW_DURATION);

        return () => clearTimeout(t);
    });

    const extraShopLogoStyle = {
        borderColor: theme["color-basic-15"],
    } as ImageStyle;

    const hitSlop = {
        top: 6,
        right: -16,
        bottom: 6,
        left: 16,
    };

    const dropdownStyle = {
        marginTop:
            Platform.OS === "android"
                ? 2 * SpacingValue["space-x-large"] + SpacingValue["space-xx-small"]
                : SpacingValue["space-x-large"],
        marginLeft: -SpacingValue["space-x-small"],
    } as ViewStyle;

    const tooltipStyle = {
        bottom: Platform.OS === "android" ? -14 : 14,
    } as ViewStyle;

    return (
        <Stack direction="row" justifyContent="space-between" style={styles.root} flex={1}>
            <Pressable onPress={goToStoreLink} hitSlop={hitSlop} style={styles.pressable}>
                <Stack direction="row" alignItems="center" flex={1}>
                    <Box style={[styles.img, extraShopLogoStyle]} testID="store-logo">
                        <CdnImage
                            isImageKitEnabled
                            id={url ?? DEFAULTS.FALLBACK_IMG}
                            width={DEFAULTS.BRAND_IMAGE_SIZE}
                            height={DEFAULTS.BRAND_IMAGE_SIZE}
                            resizeMode="contain"
                        />
                    </Box>

                    <Stack
                        flex={1}
                        flexShrink={1}
                        justifyContent="center"
                        style={styles.storeInfoWrapper}
                        spacing={SpacingValue["space-xxx-small"]}>
                        <Stack direction="row" alignItems="center">
                            <Text
                                category="h5"
                                weight="bold"
                                color="color-basic-100"
                                numberOfLines={1}
                                style={styles.storeName}>
                                {storeName}
                            </Text>
                            {isStarSeller ? (
                                <Box
                                    mr={SpacingValue["space-medium"]}
                                    ml={SpacingValue["space-xx-small"]}>
                                    <StarSellerBadge
                                        iconSize={DEFAULTS.STAR_SELLER_BADGE_ICON_SIZE}
                                        outerGradientStyle={styles.badgeGradient}
                                        rootContainerStyle={styles.badgeRootContainer}
                                    />
                                </Box>
                            ) : null}
                        </Stack>
                        {cartUpdatedAt ? (
                            <Text category="b2" color="color-basic-60" weight="regular">
                                {cartUpdatedAt}
                            </Text>
                        ) : null}
                    </Stack>
                </Stack>
            </Pressable>

            <Tooltip
                style={tooltipStyle}
                visible={isTooltipVisible}
                onClose={onDismissTooltip}
                placement={TooltipPlacement.LEFT}
                message={COPIES.CLEAR_CART_TOOLTIP_TEXT}>
                <Kebab
                    dropdownIcon="Bin"
                    dropdownText={DEFAULTS.CART_KEBAB_DROPDOWN_TEXT}
                    onDropdownItemPress={onDropdownPress}
                    style={styles.kebab}
                    analytics={analyticsData}
                    dropdownStyle={dropdownStyle}
                />
            </Tooltip>
        </Stack>
    );
};

const styles = StyleSheet.create({
    root: {
        marginBottom: SpacingValue["space-medium"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    storeInfoWrapper: {
        marginLeft: SpacingValue["space-medium"],
    },
    badgeGradient: {
        padding: 0,
    },
    badgeRootContainer: {
        paddingVertical: SpacingValue["space-xx-small"],
        paddingHorizontal: SpacingValue["space-xx-small"],
    },
    kebab: {
        marginRight: -3 * SpacingValue["space-xxx-small"],
    },
    img: {
        borderRadius: 8,
        borderWidth: 1,
        overflow: "hidden",
    },
    storeName: {
        flexShrink: 1,
    },
    pressable: {
        flex: 1,
    },
});

export const StoreInfo = React.memo(StoreInfoComponent);
