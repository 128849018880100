import React, { ComponentPropsWithoutRef } from "react";
import { Platform, StyleSheet, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { BottomSheet } from "@swiggy-private/react-native-ui";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Dialog, SpacingValue, Surface } from "@swiggy-private/rn-dls";

import { Card } from "@minis-consumer/components/card";

/**
 * A Wrapper for Bottomsheet containing custom styles.
 *
 * @param {*} props
 * @return {*} Returns BottomSheet Component with styles and JSX.element as child
 */
const BottomSheetPaymentWrapper: React.FC<
    ComponentPropsWithoutRef<typeof Dialog> & {
        contentHeight?: number;
        contentContainerStyle?: ViewStyle;
    }
> = (props) => {
    const insets = useSafeAreaInsets();

    const surfaceElevationStyle = Platform.OS === "ios" ? { elevation: 8 } : { elevation: 14 };

    const bodyContentHeight = props.contentHeight ? { height: props.contentHeight } : {};

    const bottomPaddingInsets: ViewStyle = {
        paddingBottom: insets.bottom,
    };

    const containerStyle = useSelectScreen({
        lg: {
            borderRadius: 16,
        },
        default: {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            ...bottomPaddingInsets,
        },
    });

    const cardStyle = useSelectScreen({
        lg: {
            padding: SpacingValue["space-x-large"],
            elevation: 0,
        },
        default: {
            padding: SpacingValue["space-medium"],
            elevation: 0,
        },
    });

    const dialogStyle = useSelectScreen({
        lg: {
            borderRadius: 16,
        },
        default: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
        },
    });

    return (
        <BottomSheet
            animate
            showBackdrop
            disableHardwareBackClose
            {...props}
            style={[styles.dialog, dialogStyle]}>
            <Surface
                style={StyleSheet.flatten([
                    styles.surface,
                    surfaceElevationStyle,
                    containerStyle,
                    bodyContentHeight,
                    props?.contentContainerStyle,
                ])}>
                <Card
                    style={StyleSheet.flatten([
                        containerStyle,
                        cardStyle,
                        bodyContentHeight,
                        styles.fullWidth,
                    ])}>
                    {props?.children}
                </Card>
            </Surface>
        </BottomSheet>
    );
};

export const BottomSheetWrapper = React.memo(BottomSheetPaymentWrapper);

const styles = StyleSheet.create({
    surface: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    dialog: {
        zIndex: 1,
    },
    fullWidth: {
        width: "100%",
    },
});
