import * as React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { AsyncComponent } from "@minis-consumer/components/async-component";
import { RouteList } from "@minis-consumer/interfaces/route";
import { StoreProvider } from "@minis-consumer/containers/store-container";
import { getSlugFromPath } from "@minis-consumer/helpers/get-slug";
import { useGetConfigInfo } from "@minis-consumer/hooks/use-get-config-info";

import ProductViewPlaceholder from "./components/placeholder";
import { ProductScreenContent } from "./components/product-screen-content";
import { FloatingChat } from "../../components/floating-chat";

export const ProductScreen: React.FC<NativeStackScreenProps<RouteList, "Product">> = (props) => {
    //Fallback for deeplink from web
    const [chatImage, setChatImage] = React.useState("");
    const storeIdValue = React.useRef("");

    const slug = props.route.params.slug || getSlugFromPath();

    const isNewServicesEnabled = useGetConfigInfo()?.isNewServicesEnabled;

    const showFloatingChat = (image: string, storeId: string): void => {
        setChatImage(image);
        storeIdValue.current = storeId;
    };

    return (
        <AsyncComponent fallback={ProductViewPlaceholder}>
            <StoreProvider slug={slug}>
                <ProductScreenContent {...props} onMountCallback={showFloatingChat} />

                {isNewServicesEnabled && chatImage ? (
                    <FloatingChat
                        storeId={storeIdValue.current}
                        storeImage={chatImage}
                        disableSnackBarOffset
                    />
                ) : null}
            </StoreProvider>
        </AsyncComponent>
    );
};
