import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

import { Card } from "@minis-consumer/components/card";
import { Divider } from "@minis-consumer/components/divider";
import { CartCoupon, CartAppliedCoupon } from "@minis-consumer/interfaces/coupon";
import { Analytics } from "@minis-consumer/analytics";
import { useCartView, useCelebrationModal } from "@minis-consumer/hooks/use-cart";
import { CelebrationModalDialog } from "@minis-consumer/components/coupon-celebration-modal";
import { useSignedIn } from "@minis-consumer/hooks/use-user";

import { getCouponCardData } from "./helpers";
import { CouponCardHeader } from "./components/header";
import { CouponCardFooter } from "./components/footer";

export interface CouponComponentProps {
    onViewAllCoupons: () => void;
    onApply: () => void;
    onRemove: () => void;
    storeId: string;

    cartId?: string;
    bestCoupon?: CartCoupon;
    appliedCoupon?: CartAppliedCoupon;
    couponsAvailable?: boolean;
    cartSyncing?: boolean;
}

const CouponCardComponent: React.FC<CouponComponentProps> = (props) => {
    const isSignedIn = useSignedIn();
    const couponCardData = React.useMemo(
        () => getCouponCardData({ ...props, isSignedIn }),
        [props, isSignedIn],
    );
    const cart = useCartView(props.storeId);

    const [showCelebrationModal, setShowCelebrationModal] = React.useState(false);
    const { isCelebrationModalActive, activateOrDeactivateCelebrationModal } = useCelebrationModal(
        props.storeId,
    );

    const fireImpressionEvent = React.useCallback(
        (eventName: string, couponCode?: string) => {
            Analytics.impressionEvent({
                category: eventName,
                label: `cartId: ${props.cartId}`,
                context: couponCode ? `couponCode: ${couponCode}` : "",
            });
        },
        [props.cartId],
    );

    const hideCelebrationDialog = React.useCallback(() => {
        activateOrDeactivateCelebrationModal(false);
        setShowCelebrationModal(false);
    }, [activateOrDeactivateCelebrationModal]);

    React.useEffect(() => {
        isCelebrationModalActive &&
            !props.cartSyncing &&
            cart?.cartViewData?.appliedCoupon?.applied &&
            setShowCelebrationModal(isCelebrationModalActive[props.storeId]);
    }, [
        isCelebrationModalActive,
        props.cartSyncing,
        cart?.cartViewData?.appliedCoupon?.applied,
        props.storeId,
    ]);

    useMount(() => {
        if (!couponCardData) {
            return;
        }

        if (!props.appliedCoupon?.applied) {
            props.bestCoupon &&
                fireImpressionEvent("apply-coupon-btn", props.bestCoupon.coupon.code);
            isSignedIn && props.couponsAvailable && fireImpressionEvent("view-coupons-btn");
            return;
        }

        fireImpressionEvent("remove-coupon-btn", props.appliedCoupon.code);
    });

    /** If no coupon is applied, best coupon isn't there and user isn't logged in */
    if (!props.appliedCoupon?.applied && !props.bestCoupon && !isSignedIn) {
        return null;
    }

    return (
        <>
            <Card style={styles.p0}>
                <Stack divider={<Divider />}>
                    <CouponCardHeader {...couponCardData} />

                    {couponCardData.footerText ? <CouponCardFooter {...couponCardData} /> : null}
                </Stack>
            </Card>

            <CelebrationModalDialog
                showDialog={showCelebrationModal}
                closeDialog={hideCelebrationDialog}
                storeId={props.storeId}
            />
        </>
    );
};

const styles = StyleSheet.create({
    mt16: {
        marginTop: SpacingValue["space-medium"],
    },
    p0: {
        padding: 0,
    },
});

export const CouponCard = React.memo(CouponCardComponent);
