import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { Card } from "@minis-consumer/components/card";
import { Slate } from "@minis-consumer/components/slate";
import { Divider } from "@minis-consumer/components/divider";

const CouponLoadingComponent: React.FC = () => {
    return (
        <Card style={styles.ph0}>
            <Stack divider={<Divider style={styles.mt16} />}>
                <Box direction="row" style={styles.ph16} alignItems="center">
                    <Slate height={30} width={30} borderRadius={50} />
                    <Box
                        direction="row"
                        justifyContent="space-between"
                        flex={1}
                        ml={SpacingValue["space-xx-small"]}
                        alignItems="center">
                        <Box>
                            <Slate height={16} width={100} borderRadius={8} />
                            <Slate
                                height={16}
                                width={60}
                                borderRadius={8}
                                marginTop={SpacingValue["space-xxx-small"]}
                            />
                        </Box>
                        <Slate height={16} width={60} borderRadius={8} />
                    </Box>
                </Box>

                <Box
                    style={styles.ph16}
                    mt={SpacingValue["space-medium"]}
                    direction="row"
                    justifyContent="space-between">
                    <Slate height={16} width={180} borderRadius={8} />
                    <Slate height={16} width={16} borderRadius={4} />
                </Box>
            </Stack>
        </Card>
    );
};

const styles = StyleSheet.create({
    mt16: {
        marginTop: SpacingValue["space-medium"],
    },
    ph16: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
    ph0: {
        paddingHorizontal: 0,
    },
});

export const CouponLoader = React.memo(CouponLoadingComponent);
