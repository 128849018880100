export const ADDRESS_BOTTOM_SHEET_STATE = {
    NO_ADDRESS: {
        TITLE: "Add an address",
        DESCRIPTION: "For delivery of this order",
        CTA_TEXT: "Add new address",
        HIDE_CHEVRON_ICON: true,
        SHOW_ERROR_MESSAGE: false,
    },
    MULTIPLE_ADDRESSES: {
        TITLE: "Select delivery address",
        DESCRIPTION: "",
        CTA_TEXT: "Select address",
        HIDE_CHEVRON_ICON: false,
        SHOW_ERROR_MESSAGE: false,
    },
    UNSERVICEABLE_ADDRESS: {
        TITLE: "Select another address",
        DESCRIPTION: "For delivery of this order",
        CTA_TEXT: "Select another address",
        HIDE_CHEVRON_ICON: false,
        SHOW_ERROR_MESSAGE: true,
    },
    SERVICEABLE_ADDRESS: {
        CTA_TEXT: "Proceed to payment",
        HIDE_CHEVRON_ICON: false,
        SHOW_ERROR_MESSAGE: false,
    },
};
