import React from "react";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

const SectionTitleComponent: React.FC<{ title: string }> = ({ title }) => {
    const titleCategory = useSelectScreen({
        lg: "h5" as const,
        default: "b1" as const,
    });

    return (
        <Box mt={SpacingValue["space-xx-small"]}>
            <Text category={titleCategory} weight="bold" color="high">
                {title}
            </Text>
        </Box>
    );
};

export const SectionTitle = React.memo(SectionTitleComponent);
