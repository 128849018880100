import React from "react";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { CartBill } from "@minis-consumer/interfaces/cart";
import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";

interface Props {
    billTotal: CartBill["billTotal"];
}

const FinalAmountComponent: React.FC<Props> = ({ billTotal }) => {
    const getFormattedNumber = React.useCallback(
        (num: number) => formatNumberWithIndianSystem(num, { showRupeeSymbol: true }),
        [],
    );

    const formattedBillTotal = React.useMemo(
        () => getFormattedNumber(billTotal),
        [billTotal, getFormattedNumber],
    );

    return (
        <Stack direction="row" spacing={SpacingValue["space-medium"]}>
            <Box flex={1}>
                <Text category="b1" color="high" weight="bold">
                    To Pay
                </Text>
            </Box>

            <Text category="h5" color="high" weight="bold">
                {formattedBillTotal}
            </Text>
        </Stack>
    );
};

export const FinalAmount = React.memo(FinalAmountComponent);
