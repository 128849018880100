import React, { memo } from "react";

import { ReturnOrder, ReturnOrderSubStatusesEnum } from "@minis-consumer/interfaces/return-order";

import { ReturnOrderCloseDetails } from "../../close-return-details";
import { ReturnOrderRejectDetails } from "../../rejection-details";

interface Props {
    returnOrder: ReturnOrder;
}

const ReturnResolutionComponent: React.FC<Props> = ({ returnOrder }) => {
    if (!returnOrder) {
        return null;
    }
    const { status, statusUpdatedAt, resolutionDetails } = returnOrder;

    const { reason = "", details } = resolutionDetails || {};

    if (status === ReturnOrderSubStatusesEnum.REJECTED) {
        return (
            <ReturnOrderRejectDetails
                reason={reason}
                additionalDetails={details}
                rejectedAt={statusUpdatedAt}
                showHeaderText={false}
            />
        );
    }

    if (status === ReturnOrderSubStatusesEnum.CLOSED) {
        return (
            <ReturnOrderCloseDetails
                subStatus={status}
                closedAtEpoch={statusUpdatedAt}
                resolutionDetails={resolutionDetails}
                showHeaderText={false}
            />
        );
    }

    return null;
};

export const ReturnResolution = memo(ReturnResolutionComponent);
