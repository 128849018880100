import React, { memo, useCallback, useState } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { DatePickerProps } from "react-native-date-picker";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import getUnixTime from "date-fns/getUnixTime";
import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import startOfDay from "date-fns/startOfDay";
import isBefore from "date-fns/isBefore";
import endOfDay from "date-fns/endOfDay";
import fromUnixTime from "date-fns/fromUnixTime";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";

const DatePickerNew = React.lazy(() => import("react-datepicker"));
import "react-datepicker/dist/react-datepicker.css";

import { Stack, Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Button, Input, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { convertToReadableDateAndTime, getSlotFromDateAndTime } from "@minis-consumer/helpers/date";
import { ProductSlotsData } from "@minis-consumer/interfaces/catalog";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";

import { LABELS, DEFAULT_DATES, TEST_IDS } from "../../constants";
import { AvailabilityMessage } from "../availability-message";
import { HeaderLeft } from "../header-left";

interface ISlotSelectionFormComponent {
    onProceed: (sD: number, sT: number) => void;
    productId: string;

    slotData?: ProductSlotsData;
    selectedSlotInEpoch?: number;
}

const SlotSelectionFormComponent: React.FC<ISlotSelectionFormComponent> = ({
    onProceed,
    slotData,
    productId,
    selectedSlotInEpoch = 0,
}) => {
    const { value: theme } = useTheme();

    const selectedSlotFromProp = selectedSlotInEpoch ? fromUnixTime(selectedSlotInEpoch) : null;

    const [selectedTime, setSelectedTime] = useState<Date | null>(selectedSlotFromProp);
    const [selectedDate, setSelectedDate] = useState<Date | null>(selectedSlotFromProp);
    const isDesktop = useIsDesktop();

    const getSelectedDateWithStartOfDay = React.useCallback(() => {
        if (!selectedSlotInEpoch) {
            return 0;
        }

        const hours = getHours(selectedSlotInEpoch * 1000) * 60 * 60;
        const minutes = getMinutes(selectedSlotInEpoch * 1000) * 60;

        return selectedSlotInEpoch - (hours + minutes);
    }, [selectedSlotInEpoch]);
    const [unixSelectedDate, setUnixSelectedDate] = useState(getSelectedDateWithStartOfDay());

    const [unixSelectedTime, setUnixSelectedTime] = useState(selectedSlotInEpoch);

    const { availabilityMessage = "" } = slotData ?? {};

    const onDateChange = useCallback((dateString: Date | null) => {
        setSelectedDate(dateString);
        dateString && setUnixSelectedDate(getUnixTime(dateString));
    }, []);

    const onTimeChange = useCallback((dateString: Date | null) => {
        setSelectedTime(dateString);
        dateString && setUnixSelectedTime(getUnixTime(dateString));
    }, []);

    const onPressHandler = useCallback(() => {
        onProceed?.(unixSelectedDate, unixSelectedTime);
    }, [onProceed, unixSelectedDate, unixSelectedTime]);

    const containerStyles: ViewStyle = useSelectScreen({
        lg: {
            backgroundColor: theme["color-basic-0"],
        },
        md: {
            backgroundColor: theme["color-basic-5"],
        },
        default: {
            backgroundColor: theme["color-basic-5"],
        },
    });

    const contentContainerStyles: ViewStyle = {
        backgroundColor: theme["color-basic-0"],
    };

    const minuteInterval = DEFAULT_DATES.MIN_INTERVAL as DatePickerProps["minuteInterval"];

    /**
     * Since we are using a single datepicker component to set both
     * date and time, we need to keep validating the selected slot based
     * on the selected date as all slots shall be available for future dates
     */

    const getMinTime = React.useCallback(() => {
        if (!minuteInterval) {
            return new Date();
        }

        if (isAfter(unixSelectedDate, new Date())) {
            return startOfDay(new Date());
        }

        if (isSameDay(unixSelectedDate * 1000, new Date())) {
            const coeff = 1000 * 60 * minuteInterval;
            const date = new Date();
            const rounded = new Date(Math.ceil(date.getTime() / coeff) * coeff);

            return rounded;
        }

        return startOfDay(unixSelectedDate ? unixSelectedDate : new Date());
    }, [unixSelectedDate, minuteInterval]);

    const calculateMaxTime = endOfDay(DEFAULT_DATES.MAX_DATE);

    const isPastSlotSelected = React.useMemo(() => {
        const slotStartDateInEpoch = getSlotFromDateAndTime(
            unixSelectedDate * 1000,
            unixSelectedTime * 1000,
        );

        const minTime = getMinTime();

        return slotStartDateInEpoch ? isBefore(slotStartDateInEpoch, getUnixTime(minTime)) : false;
    }, [getMinTime, unixSelectedDate, unixSelectedTime]);

    const isWayPastMaxSlotSelected = React.useMemo(() => {
        const slotStartDateInEpoch = getSlotFromDateAndTime(unixSelectedDate, unixSelectedTime);

        return slotStartDateInEpoch ? isAfter(slotStartDateInEpoch, DEFAULT_DATES.MAX_DATE) : false;
    }, [unixSelectedDate, unixSelectedTime]);

    const errorMessage = React.useMemo(
        () =>
            isPastSlotSelected
                ? LABELS.PAST_SLOT_ERROR_MESSAGE
                : isWayPastMaxSlotSelected
                ? LABELS.FUTURE_SLOT_ERROR_MESSAGE
                : "",
        [isPastSlotSelected, isWayPastMaxSlotSelected],
    );

    const infoContainerStyles: ViewStyle = {
        backgroundColor: errorMessage ? theme["color-critical-25"] : theme["color-positive-25"],
    };

    const isDisabled =
        !selectedDate || !selectedTime || isPastSlotSelected || isWayPastMaxSlotSelected;

    const formattedDate = React.useMemo(
        () =>
            unixSelectedDate ? convertToReadableDateAndTime(unixSelectedDate, "dd/MM/yyyy") : "",
        [unixSelectedDate],
    );

    const formattedTime = React.useMemo(
        () => (unixSelectedTime ? convertToReadableDateAndTime(unixSelectedTime, "hh:mm a") : ""),
        [unixSelectedTime],
    );

    const selectedSlot =
        selectedTime && selectedDate
            ? `${convertToReadableDateAndTime(
                  unixSelectedTime,
                  "hh:mm a",
              )}, ${convertToReadableDateAndTime(unixSelectedDate, "eee, d MMM")}`
            : "";

    const contStyle: ViewStyle | undefined = useSelectScreen({
        lg: { width: 500, alignSelf: "center" },
        md: { width: 500, alignSelf: "center" },
        default: undefined,
    });

    const bgColor: ViewStyle["backgroundColor"] = useSelectScreen({
        lg: theme["color-background-secondary"],
        md: theme["color-background-secondary"],
        default: undefined,
    });

    if (!slotData?.duration) {
        return null;
    }

    return (
        <Stack
            flex={1}
            style={[
                styles.mainContainer,
                {
                    borderTopColor: theme["color-basic-15"],
                    backgroundColor: bgColor,
                },
            ]}>
            <Stack flex={1} style={contStyle}>
                {isDesktop ? (
                    <Box pv={20} style={{ backgroundColor: theme["color-basic-0"] }}>
                        <HeaderLeft productId={productId} />
                    </Box>
                ) : null}
                <AvailabilityMessage availabilityMessage={availabilityMessage ?? ""} />

                <Stack flex={1} justifyContent="space-between" style={containerStyles}>
                    <AsyncComponent>
                        <Stack
                            spacing={SpacingValue["space-x-large"]}
                            style={[styles.contentContainer, contentContainerStyles]}>
                            <DatePickerNew
                                selected={selectedDate}
                                onChange={onDateChange}
                                minDate={DEFAULT_DATES.MIN_DATE}
                                maxDate={DEFAULT_DATES.MAX_DATE}
                                placeholderText="22/04/2024"
                                customInput={
                                    <Stack spacing={SpacingValue["space-medium"]}>
                                        <Text category="b2" color="low" weight="medium">
                                            {LABELS.PICK_YOUR_PREFERRED_DATE}
                                        </Text>

                                        <Input
                                            disabled
                                            maxLength={10}
                                            style={styles.input}
                                            value={formattedDate}
                                            placeholder={LABELS.DATE_PLACEHOLDER}
                                            keyboardType="numeric"
                                            testID={TEST_IDS.DATE_INPUT}
                                            accessoryRight={
                                                <SvgIcon
                                                    width={16}
                                                    height={16}
                                                    icon="Calendar"
                                                    color="color-basic-60"
                                                />
                                            }
                                        />
                                    </Stack>
                                }
                            />

                            <DatePickerNew
                                selected={selectedTime}
                                onChange={onTimeChange}
                                minTime={getMinTime()}
                                maxTime={calculateMaxTime}
                                timeIntervals={minuteInterval}
                                placeholderText="10:20"
                                customInput={
                                    <Stack spacing={SpacingValue["space-medium"]}>
                                        <Text category="b2" color="low" weight="medium">
                                            {LABELS.SELECT_YOUR_PREFERRED_TIME}
                                        </Text>

                                        <Input
                                            disabled
                                            maxLength={10}
                                            style={styles.input}
                                            value={formattedTime}
                                            placeholder={LABELS.TIME_PLACEHOLDER}
                                            keyboardType="numeric"
                                            testID={TEST_IDS.TIME_INPUT}
                                            accessoryRight={
                                                <SvgIcon
                                                    width={16}
                                                    height={16}
                                                    icon="Calendar"
                                                    color="color-basic-60"
                                                />
                                            }
                                        />
                                    </Stack>
                                }
                                showTimeSelect
                                showTimeSelectOnly
                                dateFormat="h:mm aa"
                            />
                        </Stack>
                    </AsyncComponent>

                    <Stack>
                        {!isDisabled || errorMessage ? (
                            <Stack
                                style={[styles.infoContainer, infoContainerStyles]}
                                justifyContent="center">
                                <Text
                                    category="b3"
                                    color={errorMessage ? "color-critical" : "high"}>
                                    {errorMessage || `Selected slot : ${selectedSlot}`}
                                </Text>
                            </Stack>
                        ) : null}

                        <Stack style={[styles.buttonContainer, contentContainerStyles]}>
                            <Button onPress={onPressHandler} disabled={isDisabled}>
                                {LABELS.PROCEED}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        borderTopWidth: 1,
    },
    contentContainer: {
        borderBottomLeftRadius: 24,
        borderBottomRightRadius: 24,
        paddingVertical: SpacingValue["space-large"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    buttonContainer: {
        paddingTop: SpacingValue["space-small"],
        paddingBottom: SpacingValue["space-large"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    infoContainer: {
        paddingVertical: SpacingValue["space-x-small"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    input: {
        opacity: 1,
    },
});

export const SlotSelectionForm = memo(SlotSelectionFormComponent);
