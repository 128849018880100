import React from "react";

import { CartBill } from "@minis-consumer/interfaces/cart";
import { BillItem } from "@minis-consumer/components/bill-item";

const CartTotalComponent: React.FC<{ finalAmount: CartBill["itemTotal"] }> = ({ finalAmount }) => {
    return <BillItem title="Cart total" finalAmount={finalAmount} />;
};

export const CartTotal = React.memo(CartTotalComponent);
