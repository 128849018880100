import React from "react";
import { Pressable, StyleSheet, useWindowDimensions } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import {
    Box,
    Display,
    ScreenSize,
    Stack,
    useScreenSize,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { Divider } from "@minis-consumer/components/divider";
import { StoreClosedContent as StoreClosedContentLargeScreen } from "@minis-consumer/components/store-closed";
import { CANCELLATION_POLICIES } from "@minis-consumer/constants";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { StoreSettings } from "@minis-consumer/interfaces/store";
import {
    META_PIXEL_CONTENT_CATEGORY,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { getReturnSettingInfoText } from "@minis-consumer/routes/shop/helpers";

import { ShopDeliveryDetails } from "../../delivery";
import { AboutHeader } from "../header";

type ReturnPolicyParam = {
    policy: StoreSettings["returnPolicy"];
    returnSettings?: StoreSettings["returnSettings"];
};

const STORE_DELETED_TEXT =
    "This store is not available anymore.\nIt has been permenantly closed by the seller.";

export const Overview: React.FC = () => {
    const { settings, enabled: isStoreEnabled } = useStoreInfo();
    const { returnPolicy, returnSettings } = settings || {};

    const containerStyle = useSelectScreen({
        lg: {
            paddingHorizontal: SpacingValue["space-x-large"],
            paddingBottom: SpacingValue["space-x-large"],
        },
        default: {
            paddingHorizontal: SpacingValue["space-medium"],
            paddingBottom: SpacingValue["space-x-large"],
        },
    });

    return (
        <Box style={containerStyle}>
            {isStoreEnabled !== false ? (
                <Stack
                    spacing={{
                        default: SpacingValue["space-x-large"],
                    }}>
                    <AboutHeader />
                    <Divider />
                    <ShopDeliveryDetails fontCategory="b1" />
                    {returnPolicy ? (
                        <ReturnPolicy policy={returnPolicy} returnSettings={returnSettings} />
                    ) : null}
                    <CancellationPolicy />
                    <Divider />
                    <StoreInfo />
                </Stack>
            ) : (
                <Stack
                    spacing={{
                        default: SpacingValue["space-x-large"],
                    }}>
                    <AboutHeader />

                    <Display gt={ScreenSize.Medium}>
                        <StoreClosedContentLargeScreen flex={1} />
                    </Display>

                    <Display lt={ScreenSize.Normal}>
                        <Stack spacing={SpacingValue["space-x-large"]}>
                            <Divider />
                            <Text category="b2" color="color-basic-60">
                                {STORE_DELETED_TEXT}
                            </Text>
                            <Divider />
                        </Stack>
                    </Display>
                </Stack>
            )}
        </Box>
    );
};

const CancellationPolicy: React.FC = () => {
    return (
        <Stack
            style={styles.cancellationPolicy}
            spacing={{
                default: SpacingValue["space-xx-small"],
                lg: SpacingValue["space-x-small"],
            }}>
            <Text category="h4" weight="bold">
                Cancellation policy
            </Text>

            {CANCELLATION_POLICIES.map((item) => (
                <Stack direction="row" spacing={SpacingValue["space-x-small"]} key={item}>
                    <SvgIcon
                        icon="CircleFilled"
                        color="color-primary"
                        width={6}
                        height={6}
                        style={styles.cancellationPolicyIcon}
                    />
                    <Text category="b2" color="color-basic-60">
                        {item}
                    </Text>
                </Stack>
            ))}
        </Stack>
    );
};

const ReturnPolicy: React.FC<ReturnPolicyParam> = ({ policy, returnSettings }) => {
    const screenSize = useScreenSize();

    const characterThreshold = screenSize === ScreenSize.Large ? 250 : 150;
    const policyLength = policy ? policy.length : 0;

    const [showFullPolicy, setShowFullPolicy] = React.useState(() =>
        policyLength < characterThreshold ? true : false,
    );

    const toggleShowPolicy = React.useCallback(() => {
        setShowFullPolicy((show) => !show);
    }, []);

    const ReturnSettingInfoText = React.useMemo(() => {
        if (!returnSettings) return null;
        const { enabled, duration, unit } = returnSettings;
        return (
            <Stack direction="row" spacing={SpacingValue["space-x-small"]} alignItems="center">
                <SvgIcon icon="Timer" color="color-basic-60" width={16} height={16} />
                <Text category="b1" color="color-basic-60">
                    {getReturnSettingInfoText(enabled, duration, unit)}
                </Text>
            </Stack>
        );
    }, [returnSettings]);

    const ReturnPolicyText = React.useMemo(() => {
        if (!policy) return null;

        const policyText = showFullPolicy
            ? policy
            : policy?.slice(0, characterThreshold - 1) + "...";

        const policyVisibleLabel = showFullPolicy ? "Hide full policy" : "See full policy";

        return (
            <>
                <Stack direction="row" spacing={SpacingValue["space-x-small"]} alignItems="center">
                    <Text category="b1" color="color-basic-60">
                        {policyText}
                    </Text>
                </Stack>

                {policyLength > characterThreshold ? (
                    <Pressable onPress={toggleShowPolicy}>
                        <Text category="btn5" color="primary">
                            {policyVisibleLabel}
                        </Text>
                    </Pressable>
                ) : null}
            </>
        );
    }, [policy, showFullPolicy, policyLength, characterThreshold, toggleShowPolicy]);

    if (!policyLength && !returnSettings) {
        return null;
    }

    return (
        <Stack
            style={styles.cancellationPolicy}
            spacing={{
                default: SpacingValue["space-x-small"],
                lg: SpacingValue["space-x-small"],
            }}>
            <Text category="h4" weight="bold">
                Returns, refund and exchange policy
            </Text>

            {ReturnSettingInfoText}
            {ReturnPolicyText}
        </Stack>
    );
};

const StoreInfo: React.FC = () => {
    const { width: windowWidth } = useWindowDimensions();
    const { settings, address } = useStoreInfo();
    const openUrl = useLinking();

    const addressStyle = useSelectScreen({
        lg: {
            maxWidth: 250,
        },
        default: {
            maxWidth: windowWidth - 64,
        },
    });

    const connectWithSeller = React.useCallback(
        (type: "mobile" | "email") => {
            if (type === "mobile") {
                fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.CONTACT, {
                    content_name: settings.mobile,
                    content_category: META_PIXEL_CONTENT_CATEGORY.MOBILE,
                });
                openUrl(`tel:${settings.mobile}`);
            } else if (type === "email") {
                fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.CONTACT, {
                    content_name: settings.email,
                    content_category: META_PIXEL_CONTENT_CATEGORY.EMAIL,
                });
                openUrl(`tel:${settings.email}`);
            }
        },
        [openUrl, settings.mobile, settings.email],
    );

    return (
        <Stack
            style={styles.storeInfoContainer}
            spacing={{
                default: SpacingValue["space-small"],
            }}>
            <Text category="h4" weight="bold">
                Store info
            </Text>

            {address?.address ? (
                <Stack
                    direction="row"
                    spacing={SpacingValue["space-x-small"]}
                    alignItems="flex-start">
                    <SvgIcon icon="Location" color="color-basic-60" width={16} height={16} />
                    <Text category="b2" color="color-basic-60" style={addressStyle} selectable>
                        {address?.address}
                    </Text>
                </Stack>
            ) : null}

            {settings.mobile ? (
                <Stack
                    direction="row"
                    spacing={SpacingValue["space-x-small"]}
                    alignItems="flex-start">
                    <SvgIcon icon="Phone" color="color-basic-60" width={16} height={16} />
                    <Text
                        category="b2"
                        color="color-basic-60"
                        suppressHighlighting
                        onPress={() => connectWithSeller("mobile")}>
                        {settings.mobile}
                    </Text>
                </Stack>
            ) : null}

            {settings.email ? (
                <Stack
                    direction="row"
                    spacing={SpacingValue["space-x-small"]}
                    alignItems="flex-start">
                    <Text color="color-basic-60" category="b1">
                        @
                    </Text>
                    <Text
                        category="b2"
                        color="color-basic-60"
                        suppressHighlighting
                        onPress={() => connectWithSeller("email")}>
                        {settings.email}
                    </Text>
                </Stack>
            ) : null}

            {settings.workingHours ? (
                <Stack
                    direction="row"
                    spacing={SpacingValue["space-x-small"]}
                    alignItems="flex-start">
                    <SvgIcon icon="Timer" color="color-basic-60" width={16} height={16} />
                    <Text category="b2" color="color-basic-60">
                        {settings.workingHours}
                    </Text>
                </Stack>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    cancellationPolicy: {
        width: "90%",
    },
    storeInfoContainer: {
        paddingBottom: SpacingValue["space-large"],
    },
    cancellationPolicyIcon: {
        marginTop: SpacingValue["space-x-small"],
    },
});
