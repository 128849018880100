import { useShowCounter } from "@minis-consumer/hooks/use-show-counter";

const COUNTER_KEY_NAME = "abandoned-carts-listing-empty-screen";
const MAX_COUNTER = 1;

interface ReturnType {
    isFirstTime: boolean;
    onIncrementFirstTimeCounter: VoidFunction;
}

export const useFirstTimeEmptyAbandonedCartCounter = (): ReturnType => {
    const [isFirstTime, onIncrementFirstTimeCounter] = useShowCounter(
        COUNTER_KEY_NAME,
        MAX_COUNTER,
    );

    return { isFirstTime, onIncrementFirstTimeCounter };
};
